import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import MetaTags from "react-meta-tags";
import {
  Input,
  InputGroup,
  Loader,
  Timeline,
  Placeholder,
  Progress,
  Toggle,
  Radio,
  RadioGroup,
  Form,
} from "rsuite";
import { useEffect } from "react";
import { copyToClipboard } from "../../functions/copyToClipboard";
import { MotionValue } from "framer-motion";
import _ from "lodash";
import { stages, facebook_dynamic_vars } from "../../common/data/TagStages";
import CustomUtmStage from "./modals/CustomUtmStage.modal";
import { toast } from "react-toastify";
import { CopyBlock, dracula } from "react-code-blocks";
import { CustomTooltip } from "../../components/Common/Tooltip";

const styles = {
  width: "100%",
  marginBottom: 10,
};

const addonStyles = {
  minWidth: 80,
};

function generateUTMStringForwarder(utm_tag) {
  return `<script>
  function getURLParameter(e){return decodeURIComponent((RegExp("[?|&]"+e+"=([^&;]+?)(&|#|;|$)")
  .exec(location.search)||[null,""])[1].replace(/\+/g,"%20"))||null}var tag=getURLParameter("utm_source");
  function addUTMTags(e){var t=e.getAttribute("href");t+="?utm_source="+tag,e.setAttribute("href",t)}
  for(var links=document.querySelectorAll(".pass-utm a"),i=0;i<links.length;i++)addUTMTags(links[i]);
</script>`;
}

const UTMStringGenerator = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [utmString, setUtmString] = React.useState("");
  const [isAddingCustomStage, setIsAddingCustomStage] = React.useState(false);
  const [customStage, setCustomStage] = React.useState({
    key: "",
    label: "",
    color: "black",
    icon: "mdi mdi-card-plus",
    value: "",
  });
  const [customStages, setCustomStages] = React.useState(
    stages.map((s) => ({ ...s, value: "" }))
  );

  const [isDynamicVariablesEnabled, setIsDynamicVariablesEnabled] =
    React.useState(false);
  const [dynamicVariableType, setDynamicVariableType] = React.useState("name");

  function generateUtmString() {
    setLoading(true);

    let text = `utm_source=`;

    for (const stage in customStages) {
      if (customStages[stage].value && customStages[stage].value) {
        text += `[${customStages[stage].key}_${customStages[
          stage
        ].value.toLowerCase()}]-`;
      }
    }
    text = text.slice(-1) == "-" ? text.substring(0, text.length - 1) : text;

    if (text === `utm_source=`) {
      toast.error("Você precisa adicionar pelo menos um campo");
      setLoading(false);
    } else {
      setTimeout(() => {
        setUtmString(text);
        window.scrollTo(0, document.body.scrollHeight);

        setCopied(false);
        setLoading(false);
      }, 500);
    }

    return;
  }

  function addExtraStage() {
    if (customStages.map((o) => o.key).includes(customStage.key)) {
      return toast.error("Você já adicionou esse campo");
    }

    if (customStage.key === "" || customStage.label === "") {
      toast.error(
        "Todos os campos devem ser preenchidos devem conter apenas letras e números."
      );
    } else {
      setCustomStages([...customStages, customStage]);
      setCustomStage({
        key: "",
        label: "",
        color: "black",
        icon: "mdi mdi-card-plus",
        value: "",
      });
      setIsAddingCustomStage(false);
    }
  }

  useEffect(() => {
    if (customStages.some((v) => Boolean(v.value.match(/[-_ A-Z]/)))) {
      let fixed_strings = customStages.map((o) => ({
        ...o,
        value: o.value.replace(/[-_ ]/, "").toLowerCase(),
      }));
      setCustomStages(fixed_strings);
      console.log("fixed strings");
    }
  }, [customStages]);

  useEffect(() => {
    if (isDynamicVariablesEnabled) {
      var stages_with_dynamic_vars = customStages.map((s) => {
        const dv_value = facebook_dynamic_vars.find(
          (dv) => dv.foreign_key === s.key
        );
        return {
          ...s,
          value: dv_value ? dv_value[dynamicVariableType] : "",
        };
      });
      setCustomStages(stages_with_dynamic_vars);
    } else {
      var stages_without_dynamic_vars = customStages.map((s) => ({
        ...s,
        value: "",
      }));
      setCustomStages(stages_without_dynamic_vars);
    }
  }, [isDynamicVariablesEnabled, dynamicVariableType]);

  var notEmptyValues = customStages.filter((s) => s.value !== "").length;
  var tag_quality_percent =
    Math.ceil(100 / customStages.length) * notEmptyValues;

  var extra_fields_count = Math.abs(customStages.length - 5) || 0;

  return (
    <>
      <MetaTags>
        <title>Gerador de UTMs Metrito | Metrito</title>
      </MetaTags>
      <div className="page-content ">
        <Container>
          {/* <Card>
            <CardBody>
              <Row>
                <Col
                  lg={4}
                  className="d-flex align-items-center justify-content-center py-4"
                >
                  <iframe
                    width="350"
                    height="200"
                    src="https://www.youtube.com/embed/66rmwEsmn8g?controls=0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    style={{ borderRadius: 10 }}
                  ></iframe>
                </Col>
                <Col lg={8} className="ps-4 pe-5">
                  <h5>
                    Como funcionam as UTMs no Metrito e como utilizá-las da
                    melhor forma
                  </h5>

                  <p>
                    No Metrito, nós desenvolvemos uma forma nova de acompanhar
                    todas as vendas geradas por suas campanhas. Nós usamos
                    apenas um campo UTM (utm_source) para identificar a origem
                    de cada venda.
                  </p>
                  <p>
                    Para que o nosso algoritmo entenda exatamente a origem da
                    venda e possa lhe entregar informações precisas, é
                    necessário que você siga as seguintes regras:
                  </p>

                  <ul>
                    <li>
                      As tags devem obedecer a nomenclatura padrão do Metrito
                    </li>
                    <li>
                      As tags devem ser usadas apenas no campo <b>utm_source</b>
                    </li>
                    <li>Cada anúncio deve ter um código único</li>
                  </ul>
                  <p>
                    Assista ao vídeo para saber mais sobre nosso rastreio. 😜
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card> */}

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>
                    <i className="mdi mdi-link-plus me-2" />
                    Gerador de Tags Metrito
                  </CardTitle>
                  <form>
                    <Row className="my-3">
                      <Col lg={4} className="d-flex flex-column">
                        {customStages.map((stage, index) => {
                          return (
                            <Col lg={8} sm={12}>
                              <span
                                className="text-muted"
                                style={{ fontSize: 12 }}
                              >
                                {stage.label}
                              </span>
                              <InputGroup style={styles}>
                                <InputGroup.Addon style={addonStyles}>
                                  <i className={`${stage.icon} me-2`} />
                                  {stage.key}
                                </InputGroup.Addon>
                                <Input
                                  value={customStages[index].value}
                                  onChange={(value) => {
                                    var newArr = [...customStages];
                                    newArr[index].value = value;
                                    setCustomStages(newArr);
                                  }}
                                />
                              </InputGroup>
                            </Col>
                          );
                        })}

                        <Col lg={8} sm={12}>
                          <CustomUtmStage
                            customStage={customStage}
                            setCustomStage={setCustomStage}
                            addExtraStage={addExtraStage}
                            isAddingCustomStage={isAddingCustomStage}
                            setIsAddingCustomStage={setIsAddingCustomStage}
                          />
                        </Col>
                      </Col>

                      <Col lg={4} className="d-none d-md-block">
                        <Timeline>
                          {customStages.map((stage, index) => {
                            return (
                              <Timeline.Item>
                                <div className="d-flex flex-column">
                                  <span
                                    className="text-muted"
                                    style={{ fontSize: 12 }}
                                  >
                                    {stage.label}
                                  </span>
                                  {stage.value !== "" ? (
                                    <b className="my-2">{stage.value}</b>
                                  ) : (
                                    <Placeholder.Paragraph rows={1} />
                                  )}
                                </div>
                              </Timeline.Item>
                            );
                          })}
                        </Timeline>
                      </Col>

                      <Col lg={4}>
                        <div className="d-flex flex-column justify-content-center pe-4 py-3">
                          <h6>Configurações da Tag Metrito</h6>

                          <div className="d-flex flex-column">
                            <span className="text-muted">
                              Variáveis dinâmicas (Facebook Ads)
                              <CustomTooltip
                                text={
                                  <span>
                                    {" "}
                                    As variáveis dinâmicas são um recurso do
                                    Facebook Ads para facilitar o rastreamento
                                    de tags UTMs. Ao invés de gerar uma tag para
                                    cada anúncio, apenas ative essa opção e o id
                                    do anúncio será adicionado automaticamente a
                                    sua tag. Saiba mais sobre variáveis
                                    dinâmicas{" "}
                                    <a
                                      href="https://www.facebook.com/business/help/2360940870872492"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-success"
                                    >
                                      clicando nesse link
                                    </a>
                                  </span>
                                }
                              />
                            </span>
                            <div>
                              <Toggle
                                className="mt-1"
                                checked={isDynamicVariablesEnabled}
                                onChange={setIsDynamicVariablesEnabled}
                              />
                              {isDynamicVariablesEnabled ? (
                                <small className="text-success ms-2">
                                  <b>Ativadas</b>
                                </small>
                              ) : (
                                <small className="text-dark ms-2">
                                  <b>Desativadas</b>
                                </small>
                              )}
                            </div>

                            {isDynamicVariablesEnabled && (
                              <div>
                                <span className="text-muted">
                                  Tipo de retorno da variável dinâmica
                                  <CustomTooltip
                                    text={
                                      <span>
                                        Com as variáveis dinâmicas ativadas,
                                        você pode escolher se quer que seja
                                        retornado no código UTM o ID da
                                        campanha/conjunto/anúncio ou o nome da
                                        campanha/conjunto/anúncio. Saiba mais
                                        sobre variáveis{" "}
                                        <a
                                          href="https://www.facebook.com/business/help/2360940870872492"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-success"
                                        >
                                          clicando nesse link
                                        </a>
                                      </span>
                                    }
                                  />
                                </span>
                                <Form.Group controlId="radioList">
                                  <RadioGroup
                                    name="radioList"
                                    value={dynamicVariableType}
                                    onChange={(v) => setDynamicVariableType(v)}
                                  >
                                    <Radio value="name">Nome</Radio>
                                    <Radio value="id">ID</Radio>
                                  </RadioGroup>
                                </Form.Group>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="d-flex flex-column justify-content-center pe-4 py-3">
                          <h6>Qualidade da Tag Metrito</h6>
                          <p className="text-muted">
                            Insira todos os escopos do seu anúncio para que a
                            tag funcione da melhor forma possível, assim você
                            saberá exatamente de onde veio a venda! 😉
                          </p>
                          <div
                            style={{
                              width: 130,
                              display: "inline-block",
                              marginTop: 20,
                            }}
                          >
                            <Progress.Circle
                              percent={tag_quality_percent}
                              {...(tag_quality_percent > 100
                                ? { status: "success" }
                                : { showInfo: true })}
                            />
                          </div>

                          {/* {tag_quality_percent > 100 && (
                            <div
                              style={{
                                marginTop: 50,
                                padding: 20,
                                border: "1px dashed #d4d4d4",
                                borderRadius: 10,
                              }}
                            >
                              <span>
                                Parabéns, sua tag está 100% pronta! Agora é só
                                adicionar nos seus anúncios e esperar as
                                vendinhas cairem haha! 😜🥳🚀
                              </span>
                            </div>
                          )} */}
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary d-flex align-items-center"
                        onClick={() => generateUtmString()}
                        disabled={loading}
                      >
                        {loading && <Loader inverse className="me-2"></Loader>}
                        Gerar tags UTM
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {loading ? (
            <Card>
              <CardBody className="d-flex align-items-center">
                <Loader />
                <b className="text-muted ms-2">Gerando código...</b>
              </CardBody>
            </Card>
          ) : !loading && utmString !== "" ? (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle>Resultado</CardTitle>
                    <p className="text-muted">
                      <b className="text-danger">Atenção: </b>a tag deve ser
                      utilizada seguindo exatamente a nomenclatura acima, caso
                      contrário nosso algoritmo não consegue entender a origem
                      real da venda.
                    </p>

                    <InputGroup style={styles} className="my-3">
                      <Input value={utmString} />

                      <InputGroup.Addon
                        style={addonStyles}
                        className="btn btn-success"
                        onClick={() => {
                          copyToClipboard(null, utmString);
                          setCopied(true);
                        }}
                      >
                        {copied ? (
                          <span>
                            <i className="mdi mdi-check text-light me-2" />
                            Copiado
                          </span>
                        ) : (
                          <span>
                            <i className="mdi mdi-content-copy me-2" /> Copiar
                            tags UTM
                          </span>
                        )}
                      </InputGroup.Addon>
                    </InputGroup>

                    <div className="my-3">
                      <CardTitle>Código de passagem de UTM</CardTitle>
                      <p className="text-muted">
                        Cole este código no seu site para que ele passe as tags
                        UTM para as páginas seguintes e você não perca o seu
                        rastreio. <br></br>Você também deve adicionar a classe
                        "pass-utm" nos links que você deseja que passem as tags
                        UTM.
                      </p>
                      <CopyBlock
                        text={generateUTMStringForwarder(
                          utmString.replace("utm_source=", "")
                        )}
                        language={"html"}
                        showLineNumbers={false}
                        wrapLines
                        theme={dracula}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default UTMStringGenerator;
