import React from "react";
import { useParams } from "react-router-dom";

import ConnectFacebookAdsAdAccount from "../Facebook/FacebookAds.connect";
import GoogleAdsConnect from "./GoogleAds.connect";

// import images
import logoVector from "../../assets/images/metrito-vector.svg";

const ExternalConnect = () => {
  const { dataSource, userId, brandId, token } = useParams();

  const connectors = [
    {
      value: "google",
      component: (
        <GoogleAdsConnect
          external={true}
          userId={userId}
          brandId={brandId}
          token={token}
        />
      ),
    },
    {
      value: "facebook",
      component: (
        <ConnectFacebookAdsAdAccount
          external={true}
          userId={userId}
          brandId={brandId}
          token={token}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="d-flex justify-content-center align-items-center w-100 p-3 bg-white">
        <img src={logoVector} alt="" height="25" />
      </div>
      <>
        {
          connectors.find((connector) => connector.value === dataSource)
            .component
        }
      </>
    </React.Fragment>
  );
};

export default ExternalConnect;
