import { Card } from "reactstrap";

const AppmaxWarning = (props) => (
  <Card className="w-100 mb-5 p-3 px-4" style={{ backgroundColor: "#233145" }}>
    <div className="d-flex flex-row align-items-center">
      <div className="me-lg-4">
        <i
          className="mdi mdi-shield-alert m-0 text-white d-none d-lg-block"
          style={{ fontSize: 40 }}
        />
      </div>
      <div>
        {props.step === 1 ? (
          <>
            <h5 style={{ color: "#FFDA29" }}>Atenção!</h5>
            <span className="text-white me-5">
              Por razões de segurança da sua compra e para verificar a
              autenticidade da operação, a{" "}
              <img
                src="https://admin.appmax.com.br/assets/admin/img/logo_appmax_menu.svg"
                alt="appmax"
                className="mx-2 mb-1"
                style={{ maxHeight: 15 }}
              />
              poderá entrar em contato por ligação de voz. Verifique se o seu
              número
              {props.user ? (
                <b className="mx-2">
                  ({" "}
                  {props.user.tel_number?.replace(/\(/g, "").replace(/\)/g, "")}{" "}
                  )
                </b>
              ) : (
                <i className="mdi mdi-loading mdi-spin" />
              )}
              está correto e{" "}
              <b style={{ color: "#FFDA29" }}>fique por perto do seu celular</b>{" "}
              para que tudo ocorra perfeitamente. 😉
            </span>
          </>
        ) : (
          <>
            <h5 style={{ color: "#FFDA29" }}>Talvez ainda não seja o fim!</h5>
            <span className="text-white">
              Sua compra foi realizada porém por motivos de segurança essa
              operação pode passar por uma análise manual feita pela{" "}
              <img
                src="https://admin.appmax.com.br/assets/admin/img/logo_appmax_menu.svg"
                alt="appmax"
                className="mx-2 mb-1"
                style={{ maxHeight: 15 }}
              />
              e eles podem ligar em breve para o número de telefone cadastrado{" "}
              {props.user ? (
                <b className="mx-2">
                  ({" "}
                  {props.user.tel_number?.replace(/\(/g, "").replace(/\)/g, "")}{" "}
                  )
                </b>
              ) : (
                <i className="mdi mdi-loading mdi-spin" />
              )}{" "}
              para verificar a compra.{" "}
              <b style={{ color: "#FFDA29" }}>
                Por favor fique por perto do celular!
              </b>
            </span>
          </>
        )}
      </div>
    </div>
  </Card>
);

export default AppmaxWarning;
