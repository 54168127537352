import React, { useState, useEffect } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
  Modal,
  Col,
  Row,
  Label,
} from "reactstrap";
import { IntegrationHeader } from "./IntegrationHeader";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { toast } from "react-toastify";

import { datePresets } from "../../common/data/datePresets";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as rdrLocales from "react-date-range/dist/locale";
import moment from "moment";
import api from "../../services/api";

import { setAdAccounts } from "../../store/analytics/actions.js";
import {
  fbSetAdaccount,
  fbSetCampaigns,
  fbSetLoading,
  fbSetMetrics,
} from "../../store/facebook/actions";
import { helper } from "../../functions/multiselect-v1";
import { connect } from "react-redux";
import { WhiteLoader } from "../Builder/WhiteLoader";

const customStyles = {
  dropdownButton: (styles) => ({
    ...styles,
    width: "100%",
    borderRadius: 10,
    paddingLeft: 20,
    paddingRight: 10,
    boxShadow: "none",
    border: "1px solid #ced4da",
  }),
  container: () => ({
    borderRadius: 10,
    border: "1px solid #ced4da",
    width: "100%",
    transition: "all 1000ms",
  }),
  menu: () => ({ width: "100%" }),
};

const formatOptionLabel = ({ value, label, status }) => (
  <span>
    {status === "ACTIVE" ? (
      <i
        className="spinner-grow text-success mx-3 ms-0 align-middle"
        style={{ height: 10, width: 10, margin: "auto" }}
      />
    ) : (
      <i
        className="mdi mdi-checkbox-blank-circle mx-3 ms-0 mt-1 align-middle"
        style={{ color: "#DBDDE0", fontSize: 10 }}
      />
    )}
    {label}
  </span>
);

const CustomInput = (props) => {
  let searchParams = new URLSearchParams(window.location.search);

  const [isLoadingAdAccounts, setLoadingAdAccounts] = useState(false);
  const [isLoadingCampaigns, setLoadingCampaigns] = useState(false);

  const [adAccounts, setAdAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  const [loadingStages, setLoadingStages] = useState({
    fb_account_insights: false,
    fb_campaigns: false,
  });

  var loading_progress = Math.abs(
    (100 / Object.keys(loadingStages).length) *
      Object.values(loadingStages).filter((o) => o === true).length
  );

  const clean_loading_stages = () => {
    setLoadingStages({
      fb_account_insights: false,
      fb_campaigns: false,
    });
  };

  //Date states
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [datePreset, setDatePreset] = useState(datePresets[0]);
  const [dateParam, setDateParam] = useState(
    "date_preset=" + datePresets[0].preset
  );
  const [dateText, setDateText] = useState(datePresets[0].name);

  const [modal_xlarge, setmodal_xlarge] = useState(false);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  function loadAdAccounts(brandId) {
    setLoadingAdAccounts(true);
    if (brandId) {
      api
        .get("/api/ad-accounts/list/" + brandId)
        .then((res) => {
          const facebook_ads_accounts = res.data.filter(
            (account) => account.data_source === "FA"
          );

          const newArr = [
            {
              label: "Contas de Anúncio",
              options: facebook_ads_accounts.map((o) => {
                return {
                  label: o.name,
                  value: o.ds_id,
                  token: o.token,
                };
              }),
            },
          ];
          console.log(newArr);
          setAdAccounts(newArr);
          setLoadingAdAccounts(false);
          return newArr;
        })
        .then((res) => {
          if (searchParams.get("onboarding") === "true") {
            window.localStorage.removeItem("onboardingBrandID");
            props.setSelectedAdAccounts(res[0].options);
            loadInsightsData(res[0].options);
            loadCampaigns(res[0].options);
          }
        })
        .catch((err) => {
          setLoadingAdAccounts(false);
        });
    }
  }

  function loadCampaigns(adaccounts) {
    setLoadingCampaigns(true);
    api
      .post("/api/fb/campaign", {
        adAccountList: adaccounts,
      })
      .then((res) => {
        setCampaigns([
          {
            label: "Campanhas",
            options: [].concat.apply([], res.data),
          },
        ]);
        setLoadingCampaigns(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingCampaigns(false);
      });
  }

  function loadInsightsData(onboardingAdAccountList) {
    props.populateFB([], {});
    props.setLoadingInsights(true);
    if (props.selectedAdAccounts && props.selectedCampaigns.length <= 0) {
      console.log("Only Ad Accounts Insights");
      console.log("Ad Accounts: ", props.selectedAdAccounts);
      console.log("Date Param: ", dateParam);

      api
        .post("/api/fb/adaccount/insights", {
          date_preset: dateParam,
          adAccountList: onboardingAdAccountList?.length
            ? onboardingAdAccountList
            : props.selectedAdAccounts,
        })
        .then((res) => {
          console.log(res.data);
          var fb_metrics =
            helper(
              [].concat.apply(
                [],
                res.data.map((o) => o.metrics)
              ),
              false
            ) || [];

          var fb_summary =
            helper(
              res.data.map((o) => o.summary),
              true
            ) || [];

          props.populateFB(fb_metrics, fb_summary);
          console.log("Metrics population finished");

          setLoadingStages((prevState) => ({
            ...prevState,
            fb_account_insights: true,
          }));
        })
        .catch((err) => {
          console.log(err);
          setLoadingStages((prevState) => ({
            ...prevState,
            fb_account_insights: true,
          }));
        });
    } else if (
      props.selectedAdAccounts &&
      props.selectedCampaigns.length >= 1
    ) {
      console.log("Ad Accounts + Campaigns Insights");

      api
        .post("/api/fb/campaign/insights", {
          date_preset: dateParam,
          campaignList: props.selectedCampaigns,
        })
        .then((res) => {
          var fb_metrics = helper([].concat.apply([], res.data), false) || [];
          var fb_summary = helper([].concat.apply([], res.data), true) || [];

          props.populateFB(fb_metrics, fb_summary);
          console.log("Metrics population finished");

          setLoadingStages((prevState) => ({
            ...prevState,
            fb_account_insights: true,
          }));
        })
        .catch((err) => {
          console.log(err);

          setLoadingStages((prevState) => ({
            ...prevState,
            fb_account_insights: true,
          }));
        });
    }
  }

  //Faz o Fetch das Campanhas na API do Facebook (server side)
  function getCampaignsTable(act, token) {
    //Toast loading
    const id = toast.loading("Carregando Campanhas...", {
      theme: "dark",
    });

    api
      .post("/api/fb/campaign/table", {
        adAccountList: props.selectedAdAccounts,
        date_preset: dateParam,
      })
      .then((res) => {
        props.populateCampaigns(res.data.flat());

        setLoadingStages((prevState) => ({
          ...prevState,
          fb_campaigns: true,
        }));

        toast.update(id, {
          render: "Campanhas carregadas!",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);

        setLoadingStages((prevState) => ({
          ...prevState,
          fb_campaigns: true,
        }));

        //Toast error
        toast.update(id, {
          render: "Problema na requisição! (Campanhas)",
          type: "error",
          isLoading: false,
          theme: "dark",
          autoClose: 3000,
        });
      });
  }

  useEffect(() => {
    loadAdAccounts(props.dashboardBrandId);
  }, [props.dashboardBrandId]);

  useEffect(() => {
    if (props.selectedAdAccounts.length <= 0) {
      props.setSelectedCampaigns([]);
    }
  }, [props.selectedAdAccounts]);

  useEffect(() => {
    if (loading_progress === 100) {
      setTimeout(() => {
        props.setLoadingInsights(false);
      }, 1000);
      loading_progress = 0;
    }
  }, [loadingStages]);

  return (
    <React.Fragment>
      <WhiteLoader
        isVisible={props.loadingInsights}
        progress={loading_progress}
      />

      {props.displayType === 1 ? (
        <div
          className="d-flex flex-row justify-content-end"
          style={{
            border: "2px dashed light",
            borderRadius: 10,
            padding: 10,
          }}
        >
          <button
            className="btn btn-primary w-100 h-100"
            style={{ maxWidth: 270, minWidth: 200 }}
            type="button"
            data-toggle="modal"
            data-target=".bs-example-modal-xl"
            onClick={() => {
              tog_xlarge();
            }}
          >
            <span className="d-flex align-items-center">
              <i className="bx bxl-facebook-circle me-2 fs-2" />
              <span className="fs-6">Selecionar Contas de Anúncio</span>
            </span>
          </button>
          {/* <span>{`${props.selectedAdAccounts.length} contas`}</span>
          <span>{`${props.selectedCampaigns.length} campanhas`}</span> */}
        </div>
      ) : (
        <button
          className="btn btn-primary mt-3 w-100"
          type="button"
          data-toggle="modal"
          data-target=".bs-example-modal-xl"
          onClick={() => {
            tog_xlarge();
          }}
        >
          <i className="bx bx-select-multiple me-2" />
          Selecionar Contas de Anúncio
        </button>
      )}

      <Modal
        className="mt-5"
        size="xl"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_xlarge();
        }}
      >
        <div className="modal-header">
          <IntegrationHeader
            platform="Facebook"
            icon="bx bxl-facebook-circle"
            color="#3267ED"
          />
          <button
            onClick={() => {
              setmodal_xlarge(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Defina o intervalo, e as contas de anúncio no qual deseja buscar os
            dados.
          </p>

          <Row>
            <Col lg={3} className="my-1">
              <Label className="text-muted ms-2 mb-0" style={{ fontSize: 12 }}>
                Intervalo
              </Label>
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn btn-light w-100"
                  type="button"
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid #C3C9CE",
                  }}
                >
                  <span className="text-black float-start text-truncate">
                    {dateText}
                  </span>
                  <i className="mdi mdi-chevron-down text-dark float-end"></i>
                </DropdownToggle>
                <DropdownMenu className="mt-2">
                  <div className="d-flex justify-content-start">
                    <div
                      className="d-none d-md-block"
                      style={{ flexDirection: "column" }}
                    >
                      {datePresets.map((d) => (
                        <DropdownItem
                          key={d.name}
                          onClick={() => {
                            setDatePreset(d);
                            setDateParam("date_preset=" + d.preset);
                            setDateText(d.name);
                            setState([
                              {
                                startDate: new Date(),
                                endDate: new Date(),
                                key: "selection",
                              },
                            ]);
                          }}
                        >
                          <span className="text-muted">{d.name}</span>
                        </DropdownItem>
                      ))}
                    </div>

                    <DateRangePicker
                      onChange={(item) => {
                        setState([item.selection]);
                        setDateParam(
                          "&time_range[since]=" +
                            moment([item.selection][0]?.startDate).format(
                              "YYYY-MM-DD"
                            ) +
                            "&time_range[until]=" +
                            moment([item.selection][0]?.endDate).format(
                              "YYYY-MM-DD"
                            )
                        );
                        setDateText("Personalizado");
                      }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={state}
                      direction="vertical"
                      locale={rdrLocales.pt}
                      maxDate={new Date()}
                      minDate={(() => {
                        const currentDate = new Date();
                        currentDate.setFullYear(currentDate.getFullYear() - 1);
                        return currentDate;
                      })()}
                      inputRanges={[]}
                      staticRanges={[]}
                    />
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>

            <Col lg={9} className="my-1">
              <Label className="text-muted ms-2 mb-0" style={{ fontSize: 12 }}>
                Contas de Anúncio
              </Label>
              <ReactMultiSelectCheckboxes
                styles={customStyles}
                options={adAccounts}
                value={props.selectedAdAccounts}
                placeholderButtonLabel="Selecione as contas de anúncio"
                placeholder="Pesquisar Contas"
                noOptionsMessage={() => "Nenhuma opção encontrada"}
                loadingMessage={() => "Carregando opções..."}
                isLoading={isLoadingAdAccounts}
                onChange={(e) => {
                  props.setSelectedAdAccounts(e);
                  props.setAdAccounts(e);
                  {
                    /* loadCampaigns(e); */
                  }
                }}
                getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                  if (value?.length === 0 || value?.length === undefined) {
                    return "Selecione as Contas de Anúncio";
                  } else if (value?.length === 1) {
                    return value[0].label;
                  } else {
                    const adAccountsQuantity = `${value.length} contas selecionadas`;
                    return adAccountsQuantity;
                  }
                }}
              />
            </Col>
            {/* <Col lg={6} className="my-1">
              <ReactMultiSelectCheckboxes
                styles={customStyles}
                options={campaigns}
                value={props.selectedCampaigns}
                formatOptionLabel={formatOptionLabel}
                placeholder="Pesquisar Campanhas"
                noOptionsMessage={() => "Nenhuma opção encontrada"}
                loadingMessage={() => "Carregando opções..."}
                isLoading={isLoadingCampaigns}
                onChange={(e) => {
                  console.log(e);
                  props.setSelectedCampaigns(e);
                }}
                getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                  if (value?.length === 0 || value?.length === undefined) {
                    return "Selecione as Campanhas";
                  } else if (value?.length === 1) {
                    return value[0].label;
                  } else {
                    const campaignsQuantity = `${value.length} campanhas selecionadas`;
                    return campaignsQuantity;
                  }
                }}
              />
            </Col> */}
          </Row>
          {/* <Row>
            <p className="mt-2 text-muted">
              <span className="text-danger">Atenção:</span> caso deseje os dados
              a nível de Conta de Anúncios, ou seja, sem selecionar campanhas
              específicas, não é necessário selecionar todas as campanhas,
              apenas as contas de anúncio que deseja obter os dados. Isso
              aumenta a performance da aplicação e diminui o tempo de
              carregamento dos dados.
            </p>
          </Row> */}
          <Row>
            <Col lg={12}>
              <div>
                <button
                  className="btn btn-primary mt-4 w-100"
                  onClick={() => {
                    loadInsightsData();
                    getCampaignsTable();
                    clean_loading_stages();
                    tog_xlarge();
                  }}
                  disabled={props.selectedAdAccounts?.length ? false : true}
                >
                  <i
                    className="mdi mdi-database-import-outline me-2 align-middle"
                    style={{ fontSize: 20 }}
                  />
                  aplicar e buscar dados
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (store) => ({
  fbMetrics: store.Facebook.metrics,
  fbSummary: store.Facebook.summary,
  campaigns: store.Facebook.campaigns,
});

const mapDispatchToProps = (dispatch) => ({
  setAdAccounts: (ad_acccounts) => dispatch(setAdAccounts(ad_acccounts)),
  populateFB: (metrics, summary) => dispatch(fbSetMetrics(metrics, summary)),
  populateCampaigns: (campaigns) => dispatch(fbSetCampaigns(campaigns)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomInput);
