import React from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { metricsArray } from "../../common/data/metrics";
import { data_sources } from "../../common/data/data_sources";
const data_formats = [
  {
    icon: "bx bx-data",
    label: "Dados Agregados",
    value: "aggregated",
    description:
      "Dados agregados em um único objeto referente ao período selecionado.",
  },
  {
    icon: "bx bx-bar-chart-square",
    label: "Dados Granulares",
    value: "granular",
    description:
      "Dados segmentados por dia e referente ao período selecionado.",
  },
];

const data_levels = [
  {
    icon: "mdi mdi-folder-account-outline",
    label: "Conta de Anúncio",
    value: "account",
    description: "Dados a nível de conta de anúncio.",
  },
  {
    icon: "mdi mdi-folder-table-outline",
    label: "Campanha",
    value: "campaign",
    description: "Dados a nível de campanha.",
  },
];

const DevelopersPage = () => {
  return (
    <>
      <MetaTags>
        <title>Developers | Metrito</title>
      </MetaTags>

      <div className="topbar">
        <div className="wrapper">
          <div className="topbar-wrapper">
            <a rel="noopener noreferrer" className="link">
              <img height="40" src="" alt="Swagger UI" />
            </a>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <Container>
          <Row className="my-4">
            <Col lg={12}>
              <h4 className="mb-4">Fontes de dados</h4>
              {data_sources.map((ds, index) => (
                <Card key={index} style={{ marginBottom: 20 }}>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <div className="d-flex align-items-center">
                          <i
                            className={`mx-1 ${ds.icon} fs-2`}
                            style={{ color: ds.color }}
                          />
                          <b className="fs-6 ms-3">{ds.label}</b>
                        </div>
                      </Col>
                      <Col
                        lg={6}
                        className="d-flex flex-column justify-content-center"
                      >
                        <small className="text-muted">data_source_id</small>
                        <span>{ds.api_code}</span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </Col>

            <hr className="divider mt-4" />

            <Col lg={12}>
              <h4 className="m-4">Níveis dos dados</h4>
              {data_levels.map((ds, index) => (
                <Card key={index} style={{ marginBottom: 20 }}>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="d-flex align-items-center">
                          <i
                            className={`mx-1 ${ds.icon} fs-2`}
                            style={{ color: ds.color }}
                          />
                          <b className="fs-6 ms-3">{ds.label}</b>
                        </div>
                      </Col>
                      <Col
                        lg={4}
                        className="d-flex flex-column justify-content-center"
                      >
                        <small className="text-muted">campo</small>
                        <span>{ds.value}</span>
                      </Col>
                      <Col
                        lg={4}
                        className="d-flex flex-column justify-content-center"
                      >
                        <small className="text-muted">descrição</small>
                        <span>{ds.description}</span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </Col>

            <hr className="divider mt-4" />

            <Col lg={12}>
              <h4 className="m-4">Formatos dos dados</h4>
              {data_formats.map((ds, index) => (
                <Card key={index} style={{ marginBottom: 20 }}>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="d-flex align-items-center">
                          <i
                            className={`mx-1 ${ds.icon} fs-2`}
                            style={{ color: ds.color }}
                          />
                          <b className="fs-6 ms-3">{ds.label}</b>
                        </div>
                      </Col>
                      <Col
                        lg={4}
                        className="d-flex flex-column justify-content-center"
                      >
                        <small className="text-muted">campo</small>
                        <span>{ds.value}</span>
                      </Col>
                      <Col
                        lg={4}
                        className="d-flex flex-column justify-content-center"
                      >
                        <small className="text-muted">descrição</small>
                        <span>{ds.description}</span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </Col>
          </Row>
          <hr className="divider" />
          <Row
            className="d-flex align-items-center w-100 m-0 mb-4"
            style={{
              position: "sticky",
              top: 52,
              left: 0,
              right: 0,
              zIndex: 1,
              borderRadius: 10,
              padding: "10px 0px",
              backgroundColor: "#ffffff10",
              backdropFilter: "blur(20px)",
            }}
          >
            <Col lg={4}>
              <span className="d-block">Métrica</span>
              <small className="text-muted">
                + Fontes de dados que a métrica utiliza
              </small>
            </Col>
            <Col lg={4}>
              <span>Campo</span>
            </Col>
            <Col lg={4}>
              <span>Descrição</span>
            </Col>
          </Row>
          {metricsArray.map((metric, index) => {
            const enabled_data_sources = data_sources.filter((ds) =>
              metric.data_sources.map((o) => o.code).includes(ds.code)
            );
            return (
              <Card key={index} style={{ marginBottom: 20 }}>
                <CardBody>
                  <Row>
                    <Col lg={4}>
                      <h6>{metric.label}</h6>
                      <span>
                        {enabled_data_sources.map((ds) => (
                          <i className={`text-muted mx-1 ${ds.icon}`} />
                        ))}
                      </span>
                    </Col>
                    <Col lg={4}>
                      <span>{metric.value}</span>
                    </Col>
                    <Col lg={4}>
                      <span>{metric.description}</span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            );
          })}
        </Container>
      </div>
    </>
  );
};

export default DevelopersPage;
