import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, ButtonToolbar, Input, Modal } from "rsuite";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";

const CreateNewAPITokenModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const [tokenName, setTokenName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpen = (value) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setTokenName("");
    }, 500);
  };

  function handleCreateNewToken() {
    setLoading(true);
    api
      .post(`/api/v1/user/tokens/create/${getIdUsuario()}`, {
        name: tokenName,
      })
      .then((res) => {
        toast.success("Token criado com sucesso!");

        setTimeout(() => {
          props.loadUserTokens();
          handleClose();
          setLoading(false);
        }, 500);
      })
      .then((res) => {})
      .catch((err) => {
        toast.error("Erro ao criar token");
        props.loadUserTokens();
        console.log(err);
      });
  }

  return (
    <>
      <ButtonToolbar>
        <Button
          onClick={() => handleOpen("xs")}
          style={{ minWidth: 200, borderRadius: 10 }}
          color="green"
          appearance="primary"
        >
          Criar Token
        </Button>
      </ButtonToolbar>
      <Modal size="xs" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Criar novo token de acesso da API</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="m-1">
            <small>Nome do token</small>
            <Input
              value={tokenName}
              onChange={(v) => setTokenName(v)}
              placeholder="Dê um nome para identificar melhor seu token..."
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} CreateNewAPITokenModalearance="subtle">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleCreateNewToken();
            }}
            CreateNewAPITokenModalearance="primary"
            color="green"
            appearance="primary"
            loading={loading}
          >
            Criar token
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateNewAPITokenModal;
