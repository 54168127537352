import React, { useState } from "react";
import { Card, Col, Row, CardBody, Alert, Modal } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import { Link } from "react-router-dom";
import { getIdUsuario } from "../../services/auth";

//Toast
import { toast } from "react-toastify";

//services
import api from "../../services/api";

import { useEffect } from "react";

const privacyOptions = [
  {
    label: "Público",
    value: "public",
    description: "Todos poderão ver e usar seu template",
    icon: "bx bx-world",
  },
  {
    label: "Privado",
    value: "private",
    description: "Apenas você poderá ver e usar seu template",
    icon: "bx bxs-lock-alt",
  },
];

const SaveAsTemplate = (props) => {
  const [modal_xlarge, setmodal_xlarge] = useState(props.isModalOpen);
  const [regStatus, setRegStatus] = useState("");
  const [selectedPrivacyOption, setSelectedPrivacyOption] = useState(
    privacyOptions[0]
  );

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_xlarge() {
    setSelectedPrivacyOption(privacyOptions[0]);
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  async function handleSubmit(event) {
    //Toast loading
    const id = toast.loading("Realizando operação...");

    const data = {
      name: event.target.name.value,
      description: "",
      creator: getIdUsuario(),
      layout: props.layout,
      privacy: selectedPrivacyOption.value,
      version: 3,
    };

    const create = await api
      .post("/api/layouts", data)
      .then((res) => {
        //GTM - Created Template Event
        window.dataLayer.push({ event: "created_template" });

        //Toast success
        toast.update(id, {
          render: "Template criado com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });

        setTimeout(() => tog_xlarge(), 100);

        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        //Toast success
        toast.update(id, {
          render: "Erro na operação",
          type: "warning",
          isLoading: false,
          autoClose: 1500,
        });
      });
  }

  return (
    <React.Fragment>
      <button
        className="btn btn-light text-muted border border-muted mx-2 d-none d-md-block"
        onClick={() => {
          tog_xlarge();
        }}
        disabled={!props.isSaved}
      >
        <i className="bx bxs-layout align-middle mx-2 fs-5"></i>
        <span className="d-none d-lg-inline">Salvar como template</span>
      </button>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_xlarge();
        }}
      >
        <Row className="g-0">
          <Col lg={12}>
            <div className="p-lg-5 p-4">
              <div>
                <h5>Salvar Template</h5>
              </div>

              <div className="mt-4 pt-3">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={handleSubmit}
                >
                  {regStatus === "success" ? (
                    <Alert color="success">Template criado com sucesso!</Alert>
                  ) : null}

                  {regStatus === "error" ? (
                    <Alert color="danger">Erro na operação</Alert>
                  ) : null}

                  <div className="mb-3">
                    <AvField
                      name="name"
                      label="Nome"
                      type="text"
                      required
                      placeholder="Insira o nome do template"
                      value=""
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Por favor, digite um nome",
                          pattern: {
                            value: "^[A-Za-z0-9]+$",
                            errorMessage:
                              "Seu nome deve ser formado apenas de letras e números",
                          },
                        },
                      }}
                    />
                  </div>

                  <Row>
                    {privacyOptions.map((option, key) => (
                      <Col lg={6} md={4} sm={6} xs={12} key={key}>
                        <div
                          className={`privacy-selector ${
                            option.value === selectedPrivacyOption.value
                              ? "privacy-selected"
                              : null
                          } p-2 d-flex flex-row align-items-center`}
                          onClick={() => {
                            setSelectedPrivacyOption(option);
                          }}
                        >
                          <div className="d-flex">
                            <i
                              className={`${option.icon} text-dark mx-2 fs-3 align-top`}
                            />
                          </div>
                          <div>
                            <span
                              style={{
                                fontSize: 14,
                                display: "block",
                                fontWeight: 600,
                              }}
                              className="mb-0 text-dark"
                            >
                              {option.label}
                            </span>
                            <span
                              className="text-muted"
                              style={{ fontSize: 11 }}
                            >
                              {option.description}
                            </span>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <div className="mt-3 text-end">
                    <button
                      className="btn btn-primary w-sm waves-effect waves-light"
                      type="submit"
                    >
                      Salvar Template
                    </button>
                  </div>
                </AvForm>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default SaveAsTemplate;
