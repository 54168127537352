import React from "react";
import Dante from "Dante2";
import { useEffect } from "react";
import { convertFromRaw } from "draft-js";

const FreeText = (props) => {
  if (props.widget.content) {
    let danteProps = {
      content: JSON.parse(props.widget.content),
      read_only: true,
      tooltips: [],
    };

    return (
      <div className="p-4">
        {Object.keys(props.widget.content).length > 0 && (
          <Dante {...danteProps} />
        )}
      </div>
    );
  } else {
    return (
      <>
        <div
          className="p-4"
          style={{ position: "absolute", width: "100%", height: "100%" }}
        >
          <h5>{props.widget.title}</h5>
          <p className="text-dark">{props.widget.description}</p>
        </div>
      </>
    );
  }
};

export default FreeText;
