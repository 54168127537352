export const widgets_list = [
  {
    id: 1,
    label: "Status da Conta",
    icon: "mdi mdi-clipboard-pulse-outline",
    hasTitle: false,
    hasMetric: false,
    h: 2,
    w: 12,
    minW: 12,
    maxW: 12,
    minH: 2,
    maxH: 2,
  },
  {
    id: 2,
    label: "Cards",
    icon: "mdi mdi-card-text-outline",
    hasTitle: true,
    hasMetric: true,
    isCards: false,
    h: 1,
    w: 3,
    minW: 2,
    maxW: 4,
    minH: 1,
    maxH: 1,
  },
  {
    id: 3,
    label: "Campanhas",
    icon: "mdi mdi-table",
    hasTitle: false,
    hasMetric: false,
    h: 3,
    w: 12,
    minW: 12,
    maxW: 12,
    minH: 2,
    maxH: null,
  },
  {
    id: 4,
    label: "Analytics",
    icon: "mdi mdi-chart-box-outline",
    hasTitle: true,
    hasMetric: false,
    h: 3,
    w: 12,
    minW: 4,
    maxW: 12,
    minH: 3,
    maxH: 3,
    hasLines: true,
    lines: [],
  },
  {
    id: 5,
    label: "Gráfico de Colunas",
    icon: "mdi mdi-chart-bar",
    hasTitle: true,
    hasMetric: true,
    h: 2,
    w: 4,
    minW: 4,
    maxW: 12,
    minH: 2,
    maxH: 2,
    hasLines: true,
    lines: [],
  },
  // {
  //   id: 6,
  //   title: "Gráfico Horizontal",
  //   icon: " bx bx-bar-chart bx-rotate-90 mb-1",
  //   hasTitle: true,
  //   hasMetric: true,
  //   h: 2,
  //   w: 4,
  //   minW: 4,
  //   maxW: 12,
  //   minH: 2,
  //   maxH: 2,
  //   hasLines: true,
  //   lines: [],
  // },
  {
    id: 7,
    label: "Gráfico de Linhas",
    icon: "mdi mdi-chart-line",
    hasTitle: true,
    hasMetric: true,
    h: 2,
    w: 4,
    minW: 4,
    maxW: 12,
    minH: 2,
    maxH: 2,
    hasLines: true,
    lines: [],
  },
  {
    id: 8,
    label: "Gráfico de Área",
    icon: "mdi mdi-chart-areaspline-variant",
    hasTitle: true,
    hasMetric: true,
    h: 2,
    w: 4,
    minW: 4,
    maxW: 12,
    minH: 2,
    maxH: 2,
    hasLines: true,
    lines: [],
  },
  {
    id: 10,
    label: "Texto",
    icon: "mdi mdi mdi-form-textarea",
    hasTitle: false,
    hasMetric: false,
    isCards: false,
    hasDescription: false,
    hasContent: true,
    h: 3,
    w: 4,
    minW: 1,
    maxW: Infinity,
    minH: 1,
    maxH: Infinity,
  },
];
