import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { CustomTooltip } from "../../components/Common/Tooltip.js";

import Preloader from "../Utility/Preloader";

//Toast
import { toast } from "react-toastify";

import moment from "moment";
import { Link } from "react-router-dom";

//services
import api from "../../services/api";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import noAccountsImage from "../../assets/images/no-accounts.png";
import CustomAlert from "../../components/CustomAlert.js";

const AllDashboards = () => {
  const [brands, setBrands] = useState([]);
  const [dashboards, setdashboards] = useState([]);
  const [loading, setLoading] = useState(true);

  //Confirm Dialog
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [dynamic_description, setdynamic_description] = useState("");
  const [selectedDashboard, setSelectedDashboard] = useState({});
  const [selectedBrandId, setSelectedBrandId] = useState("");

  //Faz o Fetch das Brands do Usuário
  async function loadBrandList() {
    const response = await api
      .get("/api/v1/brands/")
      .then((res) => {
        if (res.data !== 0) {
          setdashboards(res.data.flatMap((brand) => brand.dashboards));
          setBrands(res.data);
        } else {
          console.log("Sem Empresas para exibir!");

          toast.info("Você ainda não adicionou Empresas.");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Não foi possível carregar suas Empresas.");
        setLoading(false);
      });
  }

  async function handleDelete(id) {
    console.log("Apagando dashboard " + id);
    const result = await api.delete("/api/dashboards/" + id);
    if (result.status === 200) {
      console.log("Dashboard deletado com sucesso");
    } else {
      console.log("Erro ao deletar Dashboard");
    }
  }

  useEffect(() => {
    loadBrandList();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboards | Metrito</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Dashboards" />

          {loading ? (
            <Preloader />
          ) : !loading && dashboards.length === 0 ? (
            <>
              <div className="my-5 pt-sm-5">
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className="text-center">
                        <div>
                          <Row className="justify-content-center">
                            <Col sm={5}>
                              <div>
                                <img
                                  src={noAccountsImage}
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <h4 className="text-uppercase mt-4">
                          Ops! Você ainda não criou nenhum Dashboard...
                        </h4>
                        <p className="text-muted">
                          Clique no botão abaixo e crie um Dashboard com nossos
                          templates!
                        </p>
                        <div className="mt-5">
                          <Link
                            className="btn btn-primary waves-effect waves-light"
                            to="/dashboards/create"
                          >
                            Criar novo Dashboard
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col lg={12}>
                  {brands
                    .filter((brand) => brand.dashboards.length)
                    .map((brand, key) => (
                      <Card className="wide-brand-card" key={key}>
                        <CardBody>
                          <div className="d-flex flex-row justify-content-between align-items-center">
                            <h4 className="mb-0 ms-2">{brand.name}</h4>
                            <UncontrolledDropdown>
                              <DropdownToggle role="button" tag="div">
                                <i className="bx bx-dots-vertical-rounded text-muted i-btn fs-4 me-2"></i>
                              </DropdownToggle>
                              <DropdownMenu className="text-muted dropdown-menu-end">
                                <DropdownItem
                                  className="text-dark"
                                  onClick={() =>
                                    (window.location.href =
                                      "/brands/" + brand._id)
                                  }
                                >
                                  <i className="bx bx-briefcase me-2" />
                                  Detalhes da empresa
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <div className="my-3">
                            {brand.dashboards?.map((dashboard, key) => (
                              <Card
                                key={key}
                                className="wide-touchable-component p-3"
                                onClick={() => {
                                  window.open(
                                    "/report/v2/" +
                                      brand._id +
                                      "/" +
                                      dashboard._id
                                  );
                                }}
                              >
                                <Row>
                                  <Col
                                    lg={3}
                                    className="d-flex flex-row align-items-center"
                                  >
                                    <div className="me-3">
                                      <i className="bx bxs-dashboard fs-2 text-dark" />
                                    </div>
                                    <div>
                                      <Label
                                        className="text-muted mb-0"
                                        style={{ fontSize: 12 }}
                                      >
                                        nome
                                      </Label>
                                      <h6 className="text-truncate">
                                        {dashboard.name}
                                      </h6>
                                    </div>
                                  </Col>
                                  <Col
                                    lg={2}
                                    className="d-flex align-items-center"
                                  >
                                    <div>
                                      <Label
                                        className="text-muted mb-0"
                                        style={{ fontSize: 12 }}
                                      >
                                        visualizações
                                      </Label>
                                      <span
                                        className="d-block text-dark"
                                        style={{ fontWeight: 600 }}
                                      >
                                        <i className="mdi mdi-eye-check-outline fs-6 me-2 text-dark" />
                                        {dashboard.views || "0"}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col
                                    lg={3}
                                    className="d-flex align-items-center"
                                  >
                                    <div>
                                      <Label
                                        className="text-muted mb-0"
                                        style={{ fontSize: 12 }}
                                      >
                                        última visualização
                                      </Label>
                                      <span className="d-block text-dark">
                                        {dashboard.last_view
                                          ? moment(dashboard.last_view).format(
                                              "DD/MM/YYYY - HH:mm"
                                            )
                                          : "-"}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col
                                    lg={2}
                                    className="d-flex align-items-center"
                                  >
                                    <div>
                                      <Label
                                        className="text-muted mb-0"
                                        style={{ fontSize: 12 }}
                                      >
                                        versão do dashboard
                                      </Label>
                                      <span className="d-block text-dark">
                                        {dashboard.version
                                          ? `v${dashboard.version}`
                                          : "v1"}
                                      </span>
                                    </div>
                                  </Col>

                                  <Col
                                    lg={2}
                                    className="d-flex flex-row align-items-center justify-content-end px-4"
                                  >
                                    {dashboard.version !== 3 ? (
                                      <>
                                        <span
                                          className="badge px-3 py-1 text-truncate flex-shrink-1 text-dark"
                                          style={{
                                            backgroundColor: "transparent",
                                            border: "1px dashed #9599ad",
                                          }}
                                        >
                                          Versão descontinuada
                                        </span>
                                        <span className="me-2">
                                          <CustomTooltip
                                            onClick={(e) => e.preventDefault()}
                                            placement="topEnd"
                                            text="Essa versão de Dashboard foi descontinuada devido à nova atualização. Porém uma nova versão de Dashboards melhorados está disponível, crie um novo Dashboard e aproveite as novas visualizações dos widgets e conecte contas do Facebook e Google Ads simultaneamente! 🚀 🔥"
                                          />
                                        </span>
                                      </>
                                    ) : null}
                                    {dashboard.version === 3 ? (
                                      <i
                                        role="button"
                                        className="bx bxs-pencil fs-4 float-end i-btn mx-3 ms-3"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          window.open(
                                            "/builder/v2/" + dashboard._id
                                          );
                                        }}
                                      />
                                    ) : null}
                                    <i
                                      role="button"
                                      className="bx bxs-trash fs-4 float-end i-btn"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectedDashboard(dashboard);
                                        setSelectedBrandId(brand._id);
                                        setconfirm_alert(true);
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Card>
                            ))}
                          </div>
                        </CardBody>
                      </Card>
                    ))}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>

      {confirm_alert ? (
        <CustomAlert
          title="Tem certeza?"
          warning
          showCancel
          confirmButtonText="Sim, quero apagar!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false);
            setsuccess_dlg(true);
            setdynamic_title("Dashboard Removido");
            setdynamic_description("Seu Dashboard foi removido.");

            handleDelete(selectedDashboard._id);
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Seus dados e snapshots serão apagados de forma irreversível!
        </CustomAlert>
      ) : null}
      {success_dlg ? (
        <CustomAlert
          success
          title={dynamic_title}
          confirmBtnStyle={{ color: "white" }}
          onConfirm={() => {
            setsuccess_dlg(false);
            window.location.reload();
          }}
        >
          {dynamic_description}
        </CustomAlert>
      ) : null}
    </React.Fragment>
  );
};

export default AllDashboards;
