export const metrics = [
  {
    reach: "6980",
    impressions: "7064",
    spend: "8.78",
    clicks: "11",
    cpc: "0.798182",
    ctr: "0.155719",
    cpp: "1.25788",
    cpm: "1.242922",
    frequency: "1.012034",
    inline_link_clicks: "10",
    website_ctr: [{ action_type: "link_click", value: "0.141563" }],
    actions: [
      { action_type: "link_click", value: "10" },
      { action_type: "post_engagement", value: "10" },
      { action_type: "page_engagement", value: "10" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "0.878" },
      { action_type: "post_engagement", value: "0.878" },
      { action_type: "page_engagement", value: "0.878" },
    ],
    date_start: "2022-01-19",
    date_stop: "2022-01-19",
  },
  {
    reach: "11784",
    impressions: "11965",
    spend: "11.78",
    clicks: "40",
    cpc: "0.2945",
    ctr: "0.334308",
    cpp: "0.999661",
    cpm: "0.984538",
    frequency: "1.01536",
    inline_link_clicks: "25",
    website_ctr: [{ action_type: "link_click", value: "0.208943" }],
    actions: [
      { action_type: "link_click", value: "25" },
      { action_type: "post_engagement", value: "25" },
      { action_type: "page_engagement", value: "25" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "0.4712" },
      { action_type: "post_engagement", value: "0.4712" },
      { action_type: "page_engagement", value: "0.4712" },
    ],
    date_start: "2022-01-20",
    date_stop: "2022-01-20",
  },
  {
    reach: "3372",
    impressions: "4281",
    spend: "66.57",
    clicks: "26",
    cpc: "2.560385",
    ctr: "0.607335",
    cpp: "19.741993",
    cpm: "15.550105",
    frequency: "1.269573",
    inline_link_clicks: "8",
    website_ctr: [{ action_type: "link_click", value: "0.186872" }],
    actions: [
      { action_type: "link_click", value: "8" },
      { action_type: "post_reaction", value: "2" },
      { action_type: "video_view", value: "309" },
      { action_type: "landing_page_view", value: "2" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "2" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "post_engagement", value: "319" },
      { action_type: "page_engagement", value: "319" },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "initiate_checkout", value: "3" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "8.32125" },
      { action_type: "video_view", value: "0.215437" },
      { action_type: "landing_page_view", value: "33.285" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "33.285" },
      { action_type: "post_engagement", value: "0.208683" },
      { action_type: "page_engagement", value: "0.208683" },
      { action_type: "omni_initiated_checkout", value: "22.19" },
      { action_type: "initiate_checkout", value: "22.19" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "initiate_checkout", value: "3" },
    ],
    date_start: "2022-01-21",
    date_stop: "2022-01-21",
  },
  {
    reach: "19684",
    impressions: "22179",
    spend: "109.17",
    clicks: "45",
    cpc: "2.426",
    ctr: "0.202895",
    cpp: "5.546129",
    cpm: "4.922224",
    frequency: "1.126753",
    inline_link_clicks: "16",
    website_ctr: [{ action_type: "link_click", value: "0.07214" }],
    actions: [
      { action_type: "link_click", value: "16" },
      { action_type: "post", value: "1" },
      { action_type: "post_reaction", value: "5" },
      { action_type: "video_view", value: "5351" },
      { action_type: "landing_page_view", value: "5" },
      { action_type: "post_engagement", value: "5373" },
      { action_type: "page_engagement", value: "5373" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "6.823125" },
      { action_type: "video_view", value: "0.020402" },
      { action_type: "landing_page_view", value: "21.834" },
      { action_type: "post_engagement", value: "0.020318" },
      { action_type: "page_engagement", value: "0.020318" },
    ],
    date_start: "2022-01-22",
    date_stop: "2022-01-22",
  },
  {
    reach: "22848",
    impressions: "25603",
    spend: "90.18",
    clicks: "40",
    cpc: "2.2545",
    ctr: "0.156232",
    cpp: "3.946954",
    cpm: "3.522243",
    frequency: "1.120579",
    inline_link_clicks: "19",
    website_ctr: [{ action_type: "link_click", value: "0.07421" }],
    actions: [
      { action_type: "link_click", value: "19" },
      { action_type: "post_reaction", value: "3" },
      { action_type: "video_view", value: "6290" },
      { action_type: "landing_page_view", value: "3" },
      { action_type: "post_engagement", value: "6312" },
      { action_type: "page_engagement", value: "6312" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "4.746316" },
      { action_type: "video_view", value: "0.014337" },
      { action_type: "landing_page_view", value: "30.06" },
      { action_type: "post_engagement", value: "0.014287" },
      { action_type: "page_engagement", value: "0.014287" },
    ],
    date_start: "2022-01-23",
    date_stop: "2022-01-23",
  },
  {
    reach: "10419",
    impressions: "10621",
    spend: "42.22",
    clicks: "9",
    cpc: "4.691111",
    ctr: "0.084738",
    cpp: "4.052212",
    cpm: "3.975144",
    frequency: "1.019388",
    inline_link_clicks: "5",
    website_ctr: [{ action_type: "link_click", value: "0.047077" }],
    actions: [
      { action_type: "link_click", value: "5" },
      { action_type: "post", value: "1" },
      { action_type: "post_reaction", value: "1" },
      { action_type: "video_view", value: "2911" },
      { action_type: "landing_page_view", value: "2" },
      { action_type: "post_engagement", value: "2918" },
      { action_type: "page_engagement", value: "2918" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "8.444" },
      { action_type: "video_view", value: "0.014504" },
      { action_type: "landing_page_view", value: "21.11" },
      { action_type: "post_engagement", value: "0.014469" },
      { action_type: "page_engagement", value: "0.014469" },
    ],
    date_start: "2022-01-24",
    date_stop: "2022-01-24",
  },
  {
    reach: "17361",
    impressions: "19232",
    spend: "101.99",
    clicks: "157",
    cpc: "0.649618",
    ctr: "0.816348",
    cpp: "5.874662",
    cpm: "5.303141",
    frequency: "1.10777",
    inline_link_clicks: "91",
    website_ctr: [{ action_type: "link_click", value: "0.47317" }],
    actions: [
      { action_type: "onsite_conversion.post_save", value: "1" },
      { action_type: "link_click", value: "91" },
      { action_type: "post", value: "3" },
      { action_type: "post_reaction", value: "31" },
      { action_type: "landing_page_view", value: "75" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "6" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "9",
      },
      { action_type: "post_engagement", value: "126" },
      { action_type: "page_engagement", value: "126" },
      { action_type: "omni_initiated_checkout", value: "9" },
      { action_type: "initiate_checkout", value: "9" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "101.99" },
      { action_type: "link_click", value: "1.120769" },
      { action_type: "landing_page_view", value: "1.359867" },
      {
        action_type: "offsite_conversion.fb_pixel_custom",
        value: "16.998333",
      },
      { action_type: "post_engagement", value: "0.809444" },
      { action_type: "page_engagement", value: "0.809444" },
      { action_type: "omni_initiated_checkout", value: "11.332222" },
      { action_type: "initiate_checkout", value: "11.332222" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "9",
      },
      { action_type: "omni_initiated_checkout", value: "9" },
      { action_type: "initiate_checkout", value: "9" },
    ],
    date_start: "2022-01-27",
    date_stop: "2022-01-27",
  },
  {
    reach: "7243",
    impressions: "7960",
    spend: "89.17",
    clicks: "111",
    cpc: "0.803333",
    ctr: "1.394472",
    cpp: "12.311197",
    cpm: "11.202261",
    frequency: "1.098992",
    inline_link_clicks: "73",
    website_ctr: [{ action_type: "link_click", value: "0.917085" }],
    actions: [
      { action_type: "comment", value: "2" },
      { action_type: "onsite_conversion.post_save", value: "2" },
      { action_type: "link_click", value: "73" },
      { action_type: "post", value: "2" },
      { action_type: "post_reaction", value: "23" },
      { action_type: "landing_page_view", value: "69" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "10" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "5",
      },
      { action_type: "post_engagement", value: "102" },
      { action_type: "page_engagement", value: "102" },
      { action_type: "omni_initiated_checkout", value: "5" },
      { action_type: "initiate_checkout", value: "5" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "44.585" },
      { action_type: "link_click", value: "1.221507" },
      { action_type: "landing_page_view", value: "1.292319" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "8.917" },
      { action_type: "post_engagement", value: "0.874216" },
      { action_type: "page_engagement", value: "0.874216" },
      { action_type: "omni_initiated_checkout", value: "17.834" },
      { action_type: "initiate_checkout", value: "17.834" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "5",
      },
      { action_type: "omni_initiated_checkout", value: "5" },
      { action_type: "initiate_checkout", value: "5" },
    ],
    date_start: "2022-01-28",
    date_stop: "2022-01-28",
  },
  {
    reach: "5704",
    impressions: "6106",
    spend: "46.16",
    clicks: "46",
    cpc: "1.003478",
    ctr: "0.753357",
    cpp: "8.092567",
    cpm: "7.559777",
    frequency: "1.070477",
    inline_link_clicks: "37",
    website_ctr: [{ action_type: "link_click", value: "0.605961" }],
    actions: [
      { action_type: "comment", value: "1" },
      { action_type: "onsite_conversion.post_save", value: "2" },
      { action_type: "link_click", value: "37" },
      { action_type: "post_reaction", value: "10" },
      { action_type: "landing_page_view", value: "32" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "5" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "5",
      },
      { action_type: "post_engagement", value: "50" },
      { action_type: "page_engagement", value: "50" },
      { action_type: "omni_initiated_checkout", value: "5" },
      { action_type: "initiate_checkout", value: "5" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "23.08" },
      { action_type: "link_click", value: "1.247568" },
      { action_type: "landing_page_view", value: "1.4425" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "9.232" },
      { action_type: "post_engagement", value: "0.9232" },
      { action_type: "page_engagement", value: "0.9232" },
      { action_type: "omni_initiated_checkout", value: "9.232" },
      { action_type: "initiate_checkout", value: "9.232" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "5",
      },
      { action_type: "omni_initiated_checkout", value: "5" },
      { action_type: "initiate_checkout", value: "5" },
    ],
    date_start: "2022-01-29",
    date_stop: "2022-01-29",
  },
  {
    reach: "5438",
    impressions: "5767",
    spend: "40.78",
    clicks: "48",
    cpc: "0.849583",
    ctr: "0.832322",
    cpp: "7.499081",
    cpm: "7.071268",
    frequency: "1.0605",
    inline_link_clicks: "33",
    website_ctr: [{ action_type: "link_click", value: "0.572221" }],
    actions: [
      { action_type: "comment", value: "2" },
      { action_type: "link_click", value: "33" },
      { action_type: "post_reaction", value: "8" },
      { action_type: "landing_page_view", value: "25" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "2" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "8",
      },
      { action_type: "post_engagement", value: "43" },
      { action_type: "page_engagement", value: "43" },
      { action_type: "omni_initiated_checkout", value: "8" },
      { action_type: "initiate_checkout", value: "8" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "1.235758" },
      { action_type: "landing_page_view", value: "1.6312" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "20.39" },
      { action_type: "post_engagement", value: "0.948372" },
      { action_type: "page_engagement", value: "0.948372" },
      { action_type: "omni_initiated_checkout", value: "5.0975" },
      { action_type: "initiate_checkout", value: "5.0975" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "8",
      },
      { action_type: "omni_initiated_checkout", value: "8" },
      { action_type: "initiate_checkout", value: "8" },
    ],
    date_start: "2022-01-30",
    date_stop: "2022-01-30",
  },
  {
    reach: "998",
    impressions: "1027",
    spend: "8.28",
    clicks: "8",
    cpc: "1.035",
    ctr: "0.778968",
    cpp: "8.296593",
    cpm: "8.062317",
    frequency: "1.029058",
    inline_link_clicks: "5",
    website_ctr: [{ action_type: "link_click", value: "0.486855" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1" },
      { action_type: "link_click", value: "5" },
      { action_type: "post_reaction", value: "4" },
      { action_type: "landing_page_view", value: "5" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "1",
      },
      { action_type: "post_engagement", value: "9" },
      { action_type: "page_engagement", value: "9" },
      { action_type: "omni_initiated_checkout", value: "1" },
      { action_type: "omni_purchase", value: "1" },
      { action_type: "initiate_checkout", value: "1" },
      { action_type: "purchase", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "1.656" },
      { action_type: "landing_page_view", value: "1.656" },
      { action_type: "post_engagement", value: "0.92" },
      { action_type: "page_engagement", value: "0.92" },
      { action_type: "omni_initiated_checkout", value: "8.28" },
      { action_type: "omni_purchase", value: "8.28" },
      { action_type: "initiate_checkout", value: "8.28" },
      { action_type: "purchase", value: "8.28" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "347" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "1",
      },
      { action_type: "omni_initiated_checkout", value: "1" },
      { action_type: "omni_purchase", value: "347" },
      { action_type: "initiate_checkout", value: "1" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "41.908213",
      },
    ],
    date_start: "2022-01-31",
    date_stop: "2022-01-31",
  },
  {
    reach: "8668",
    impressions: "11667",
    spend: "143.9",
    clicks: "56",
    cpc: "2.569643",
    ctr: "0.479986",
    cpp: "16.601292",
    cpm: "12.333933",
    frequency: "1.345985",
    inline_link_clicks: "32",
    website_ctr: [{ action_type: "link_click", value: "0.274278" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "3" },
      { action_type: "link_click", value: "32" },
      { action_type: "post", value: "5" },
      { action_type: "post_reaction", value: "6" },
      { action_type: "landing_page_view", value: "18" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "2" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "post_engagement", value: "43" },
      { action_type: "page_engagement", value: "43" },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "omni_purchase", value: "3" },
      { action_type: "initiate_checkout", value: "4" },
      { action_type: "purchase", value: "3" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "4.496875" },
      { action_type: "landing_page_view", value: "7.994444" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "71.95" },
      { action_type: "post_engagement", value: "3.346512" },
      { action_type: "page_engagement", value: "3.346512" },
      { action_type: "omni_initiated_checkout", value: "35.975" },
      { action_type: "omni_purchase", value: "47.966667" },
      { action_type: "initiate_checkout", value: "35.975" },
      { action_type: "purchase", value: "47.966667" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "1742.8",
      },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "omni_purchase", value: "1742.8" },
      { action_type: "initiate_checkout", value: "4" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "12.111188",
      },
    ],
    date_start: "2022-02-01",
    date_stop: "2022-02-01",
  },
  {
    reach: "4910",
    impressions: "6095",
    spend: "83.87",
    clicks: "46",
    cpc: "1.823261",
    ctr: "0.754717",
    cpp: "17.081466",
    cpm: "13.760459",
    frequency: "1.241344",
    inline_link_clicks: "24",
    website_ctr: [{ action_type: "link_click", value: "0.393765" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "3" },
      { action_type: "link_click", value: "24" },
      { action_type: "post", value: "1" },
      { action_type: "post_reaction", value: "6" },
      { action_type: "landing_page_view", value: "15" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "5" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "post_engagement", value: "31" },
      { action_type: "page_engagement", value: "31" },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "omni_purchase", value: "3" },
      { action_type: "initiate_checkout", value: "4" },
      { action_type: "purchase", value: "3" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "3.494583" },
      { action_type: "landing_page_view", value: "5.591333" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "16.774" },
      { action_type: "post_engagement", value: "2.705484" },
      { action_type: "page_engagement", value: "2.705484" },
      { action_type: "omni_initiated_checkout", value: "20.9675" },
      { action_type: "omni_purchase", value: "27.956667" },
      { action_type: "initiate_checkout", value: "20.9675" },
      { action_type: "purchase", value: "27.956667" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1941" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "omni_purchase", value: "1941" },
      { action_type: "initiate_checkout", value: "4" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "23.142959",
      },
    ],
    date_start: "2022-02-02",
    date_stop: "2022-02-02",
  },
  {
    reach: "22064",
    impressions: "29275",
    spend: "356.04",
    clicks: "111",
    cpc: "3.207568",
    ctr: "0.379163",
    cpp: "16.136693",
    cpm: "12.161913",
    frequency: "1.326822",
    inline_link_clicks: "57",
    website_ctr: [{ action_type: "link_click", value: "0.194705" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1" },
      { action_type: "comment", value: "2" },
      { action_type: "onsite_conversion.post_save", value: "1" },
      { action_type: "link_click", value: "57" },
      { action_type: "post", value: "3" },
      { action_type: "post_reaction", value: "6" },
      { action_type: "landing_page_view", value: "31" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "8" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "6",
      },
      { action_type: "post_engagement", value: "69" },
      { action_type: "page_engagement", value: "69" },
      { action_type: "omni_initiated_checkout", value: "6" },
      { action_type: "omni_purchase", value: "1" },
      { action_type: "initiate_checkout", value: "6" },
      { action_type: "purchase", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "356.04" },
      { action_type: "link_click", value: "6.246316" },
      { action_type: "landing_page_view", value: "11.485161" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "44.505" },
      { action_type: "post_engagement", value: "5.16" },
      { action_type: "page_engagement", value: "5.16" },
      { action_type: "omni_initiated_checkout", value: "59.34" },
      { action_type: "omni_purchase", value: "356.04" },
      { action_type: "initiate_checkout", value: "59.34" },
      { action_type: "purchase", value: "356.04" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "647" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "6",
      },
      { action_type: "omni_initiated_checkout", value: "6" },
      { action_type: "omni_purchase", value: "647" },
      { action_type: "initiate_checkout", value: "6" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "1.817212",
      },
    ],
    date_start: "2022-02-03",
    date_stop: "2022-02-03",
  },
  {
    reach: "23816",
    impressions: "28453",
    spend: "435.07",
    clicks: "172",
    cpc: "2.529477",
    ctr: "0.604506",
    cpp: "18.267971",
    cpm: "15.29083",
    frequency: "1.194701",
    inline_link_clicks: "90",
    website_ctr: [{ action_type: "link_click", value: "0.316311" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "2" },
      { action_type: "comment", value: "1" },
      { action_type: "onsite_conversion.post_save", value: "2" },
      { action_type: "link_click", value: "90" },
      { action_type: "post", value: "4" },
      { action_type: "post_reaction", value: "17" },
      { action_type: "landing_page_view", value: "47" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "7" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "post_engagement", value: "114" },
      { action_type: "page_engagement", value: "114" },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "omni_purchase", value: "2" },
      { action_type: "initiate_checkout", value: "4" },
      { action_type: "purchase", value: "2" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "217.535" },
      { action_type: "link_click", value: "4.834111" },
      { action_type: "landing_page_view", value: "9.256809" },
      {
        action_type: "offsite_conversion.fb_pixel_custom",
        value: "62.152857",
      },
      { action_type: "post_engagement", value: "3.816404" },
      { action_type: "page_engagement", value: "3.816404" },
      { action_type: "omni_initiated_checkout", value: "108.7675" },
      { action_type: "omni_purchase", value: "217.535" },
      { action_type: "initiate_checkout", value: "108.7675" },
      { action_type: "purchase", value: "217.535" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "1144.9",
      },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "omni_purchase", value: "1144.9" },
      { action_type: "initiate_checkout", value: "4" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "2.631531",
      },
    ],
    date_start: "2022-02-04",
    date_stop: "2022-02-04",
  },
  {
    reach: "17292",
    impressions: "19771",
    spend: "372.61",
    clicks: "155",
    cpc: "2.403935",
    ctr: "0.783977",
    cpp: "21.548115",
    cpm: "18.84629",
    frequency: "1.143361",
    inline_link_clicks: "80",
    website_ctr: [{ action_type: "link_click", value: "0.404633" }],
    actions: [
      { action_type: "comment", value: "2" },
      { action_type: "onsite_conversion.post_save", value: "2" },
      { action_type: "link_click", value: "80" },
      { action_type: "post", value: "1" },
      { action_type: "post_reaction", value: "19" },
      { action_type: "landing_page_view", value: "32" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "3" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "7",
      },
      { action_type: "post_engagement", value: "104" },
      { action_type: "page_engagement", value: "104" },
      { action_type: "omni_initiated_checkout", value: "7" },
      { action_type: "initiate_checkout", value: "7" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "186.305" },
      { action_type: "link_click", value: "4.657625" },
      { action_type: "landing_page_view", value: "11.644063" },
      {
        action_type: "offsite_conversion.fb_pixel_custom",
        value: "124.203333",
      },
      { action_type: "post_engagement", value: "3.582788" },
      { action_type: "page_engagement", value: "3.582788" },
      { action_type: "omni_initiated_checkout", value: "53.23" },
      { action_type: "initiate_checkout", value: "53.23" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "7",
      },
      { action_type: "omni_initiated_checkout", value: "7" },
      { action_type: "initiate_checkout", value: "7" },
    ],
    date_start: "2022-02-05",
    date_stop: "2022-02-05",
  },
  {
    reach: "6746",
    impressions: "8346",
    spend: "180.23",
    clicks: "72",
    cpc: "2.503194",
    ctr: "0.862689",
    cpp: "26.716573",
    cpm: "21.594776",
    frequency: "1.237178",
    inline_link_clicks: "32",
    website_ctr: [{ action_type: "link_click", value: "0.383417" }],
    actions: [
      { action_type: "link_click", value: "32" },
      { action_type: "post", value: "1" },
      { action_type: "post_reaction", value: "4" },
      { action_type: "landing_page_view", value: "22" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "3" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "post_engagement", value: "37" },
      { action_type: "page_engagement", value: "37" },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "initiate_checkout", value: "3" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "5.632188" },
      { action_type: "landing_page_view", value: "8.192273" },
      {
        action_type: "offsite_conversion.fb_pixel_custom",
        value: "60.076667",
      },
      { action_type: "post_engagement", value: "4.871081" },
      { action_type: "page_engagement", value: "4.871081" },
      { action_type: "omni_initiated_checkout", value: "60.076667" },
      { action_type: "initiate_checkout", value: "60.076667" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "initiate_checkout", value: "3" },
    ],
    date_start: "2022-02-06",
    date_stop: "2022-02-06",
  },
  {
    reach: "5420",
    impressions: "6939",
    spend: "119.19",
    clicks: "32",
    cpc: "3.724688",
    ctr: "0.461162",
    cpp: "21.990775",
    cpm: "17.176827",
    frequency: "1.280258",
    inline_link_clicks: "9",
    website_ctr: [{ action_type: "link_click", value: "0.129702" }],
    actions: [
      { action_type: "link_click", value: "9" },
      { action_type: "post", value: "2" },
      { action_type: "post_reaction", value: "2" },
      { action_type: "landing_page_view", value: "6" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "3" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "post_engagement", value: "13" },
      { action_type: "page_engagement", value: "13" },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "initiate_checkout", value: "4" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "13.243333" },
      { action_type: "landing_page_view", value: "19.865" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "39.73" },
      { action_type: "post_engagement", value: "9.168462" },
      { action_type: "page_engagement", value: "9.168462" },
      { action_type: "omni_initiated_checkout", value: "29.7975" },
      { action_type: "initiate_checkout", value: "29.7975" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "initiate_checkout", value: "4" },
    ],
    date_start: "2022-02-07",
    date_stop: "2022-02-07",
  },
  {
    reach: "5117",
    impressions: "6492",
    spend: "109.55",
    clicks: "44",
    cpc: "2.489773",
    ctr: "0.677757",
    cpp: "21.409029",
    cpm: "16.874615",
    frequency: "1.268712",
    inline_link_clicks: "20",
    website_ctr: [{ action_type: "link_click", value: "0.308071" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1" },
      { action_type: "comment", value: "2" },
      { action_type: "onsite_conversion.post_save", value: "1" },
      { action_type: "link_click", value: "20" },
      { action_type: "post_reaction", value: "4" },
      { action_type: "landing_page_view", value: "9" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "2" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "2",
      },
      { action_type: "post_engagement", value: "27" },
      { action_type: "page_engagement", value: "27" },
      { action_type: "omni_initiated_checkout", value: "2" },
      { action_type: "omni_purchase", value: "1" },
      { action_type: "initiate_checkout", value: "2" },
      { action_type: "purchase", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "109.55" },
      { action_type: "link_click", value: "5.4775" },
      { action_type: "landing_page_view", value: "12.172222" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "54.775" },
      { action_type: "post_engagement", value: "4.057407" },
      { action_type: "page_engagement", value: "4.057407" },
      { action_type: "omni_initiated_checkout", value: "54.775" },
      { action_type: "omni_purchase", value: "109.55" },
      { action_type: "initiate_checkout", value: "54.775" },
      { action_type: "purchase", value: "109.55" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "347" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "2",
      },
      { action_type: "omni_initiated_checkout", value: "2" },
      { action_type: "omni_purchase", value: "347" },
      { action_type: "initiate_checkout", value: "2" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "3.167503",
      },
    ],
    date_start: "2022-02-08",
    date_stop: "2022-02-08",
  },
  {
    reach: "6454",
    impressions: "8746",
    spend: "201.19",
    clicks: "89",
    cpc: "2.260562",
    ctr: "1.017608",
    cpp: "31.172916",
    cpm: "23.003659",
    frequency: "1.355129",
    inline_link_clicks: "29",
    website_ctr: [{ action_type: "link_click", value: "0.33158" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1" },
      { action_type: "comment", value: "3" },
      { action_type: "onsite_conversion.post_save", value: "1" },
      { action_type: "link_click", value: "29" },
      { action_type: "post", value: "1" },
      { action_type: "post_reaction", value: "6" },
      { action_type: "landing_page_view", value: "12" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "3" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "6",
      },
      { action_type: "post_engagement", value: "40" },
      { action_type: "page_engagement", value: "40" },
      { action_type: "omni_initiated_checkout", value: "6" },
      { action_type: "omni_purchase", value: "1" },
      { action_type: "initiate_checkout", value: "6" },
      { action_type: "purchase", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "201.19" },
      { action_type: "link_click", value: "6.937586" },
      { action_type: "landing_page_view", value: "16.765833" },
      {
        action_type: "offsite_conversion.fb_pixel_custom",
        value: "67.063333",
      },
      { action_type: "post_engagement", value: "5.02975" },
      { action_type: "page_engagement", value: "5.02975" },
      { action_type: "omni_initiated_checkout", value: "33.531667" },
      { action_type: "omni_purchase", value: "201.19" },
      { action_type: "initiate_checkout", value: "33.531667" },
      { action_type: "purchase", value: "201.19" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "647" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "6",
      },
      { action_type: "omni_initiated_checkout", value: "6" },
      { action_type: "omni_purchase", value: "647" },
      { action_type: "initiate_checkout", value: "6" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "3.215866",
      },
    ],
    date_start: "2022-02-09",
    date_stop: "2022-02-09",
  },
  {
    reach: "2700",
    impressions: "3663",
    spend: "99.13",
    clicks: "43",
    cpc: "2.305349",
    ctr: "1.173901",
    cpp: "36.714815",
    cpm: "27.062517",
    frequency: "1.356667",
    inline_link_clicks: "19",
    website_ctr: [{ action_type: "link_click", value: "0.518701" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "2" },
      { action_type: "onsite_conversion.post_save", value: "1" },
      { action_type: "link_click", value: "19" },
      { action_type: "landing_page_view", value: "9" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "2" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "5",
      },
      { action_type: "post_engagement", value: "20" },
      { action_type: "page_engagement", value: "20" },
      { action_type: "omni_initiated_checkout", value: "5" },
      { action_type: "omni_purchase", value: "2" },
      { action_type: "initiate_checkout", value: "5" },
      { action_type: "purchase", value: "2" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "99.13" },
      { action_type: "link_click", value: "5.217368" },
      { action_type: "landing_page_view", value: "11.014444" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "49.565" },
      { action_type: "post_engagement", value: "4.9565" },
      { action_type: "page_engagement", value: "4.9565" },
      { action_type: "omni_initiated_checkout", value: "19.826" },
      { action_type: "omni_purchase", value: "49.565" },
      { action_type: "initiate_checkout", value: "19.826" },
      { action_type: "purchase", value: "49.565" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "1289.15",
      },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "5",
      },
      { action_type: "omni_initiated_checkout", value: "5" },
      { action_type: "omni_purchase", value: "1289.15" },
      { action_type: "initiate_checkout", value: "5" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "13.00464",
      },
    ],
    date_start: "2022-02-10",
    date_stop: "2022-02-10",
  },
  {
    reach: "2827",
    impressions: "3660",
    spend: "103.13",
    clicks: "35",
    cpc: "2.946571",
    ctr: "0.956284",
    cpp: "36.480368",
    cpm: "28.177596",
    frequency: "1.294659",
    inline_link_clicks: "15",
    website_ctr: [{ action_type: "link_click", value: "0.409836" }],
    actions: [
      { action_type: "link_click", value: "15" },
      { action_type: "post_reaction", value: "2" },
      { action_type: "landing_page_view", value: "6" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "post_engagement", value: "17" },
      { action_type: "page_engagement", value: "17" },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "initiate_checkout", value: "4" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "6.875333" },
      { action_type: "landing_page_view", value: "17.188333" },
      { action_type: "post_engagement", value: "6.066471" },
      { action_type: "page_engagement", value: "6.066471" },
      { action_type: "omni_initiated_checkout", value: "25.7825" },
      { action_type: "initiate_checkout", value: "25.7825" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "initiate_checkout", value: "4" },
    ],
    date_start: "2022-02-11",
    date_stop: "2022-02-11",
  },
  {
    reach: "6042",
    impressions: "6784",
    spend: "145.11",
    clicks: "47",
    cpc: "3.087447",
    ctr: "0.692807",
    cpp: "24.016882",
    cpm: "21.390035",
    frequency: "1.122807",
    inline_link_clicks: "24",
    website_ctr: [{ action_type: "link_click", value: "0.353774" }],
    actions: [
      { action_type: "onsite_conversion.post_save", value: "1" },
      { action_type: "link_click", value: "24" },
      { action_type: "post_reaction", value: "1" },
      { action_type: "landing_page_view", value: "7" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "2" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "post_engagement", value: "26" },
      { action_type: "page_engagement", value: "26" },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "initiate_checkout", value: "3" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "145.11" },
      { action_type: "link_click", value: "6.04625" },
      { action_type: "landing_page_view", value: "20.73" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "72.555" },
      { action_type: "post_engagement", value: "5.581154" },
      { action_type: "page_engagement", value: "5.581154" },
      { action_type: "omni_initiated_checkout", value: "48.37" },
      { action_type: "initiate_checkout", value: "48.37" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "initiate_checkout", value: "3" },
    ],
    date_start: "2022-02-12",
    date_stop: "2022-02-12",
  },
  {
    reach: "4509",
    impressions: "5259",
    spend: "115.85",
    clicks: "50",
    cpc: "2.317",
    ctr: "0.950751",
    cpp: "25.693058",
    cpm: "22.028903",
    frequency: "1.166334",
    inline_link_clicks: "22",
    website_ctr: [{ action_type: "link_click", value: "0.41833" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1" },
      { action_type: "comment", value: "1" },
      { action_type: "onsite_conversion.post_save", value: "3" },
      { action_type: "link_click", value: "22" },
      { action_type: "post", value: "1" },
      { action_type: "post_reaction", value: "4" },
      { action_type: "landing_page_view", value: "13" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "6",
      },
      { action_type: "post_engagement", value: "31" },
      { action_type: "page_engagement", value: "31" },
      { action_type: "omni_initiated_checkout", value: "6" },
      { action_type: "omni_purchase", value: "1" },
      { action_type: "initiate_checkout", value: "6" },
      { action_type: "purchase", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "38.616667" },
      { action_type: "link_click", value: "5.265909" },
      { action_type: "landing_page_view", value: "8.911538" },
      { action_type: "post_engagement", value: "3.737097" },
      { action_type: "page_engagement", value: "3.737097" },
      { action_type: "omni_initiated_checkout", value: "19.308333" },
      { action_type: "omni_purchase", value: "115.85" },
      { action_type: "initiate_checkout", value: "19.308333" },
      { action_type: "purchase", value: "115.85" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "347" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "6",
      },
      { action_type: "omni_initiated_checkout", value: "6" },
      { action_type: "omni_purchase", value: "347" },
      { action_type: "initiate_checkout", value: "6" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "2.995252",
      },
    ],
    date_start: "2022-02-13",
    date_stop: "2022-02-13",
  },
  {
    reach: "2961",
    impressions: "3506",
    spend: "61.44",
    clicks: "23",
    cpc: "2.671304",
    ctr: "0.656018",
    cpp: "20.749747",
    cpm: "17.524244",
    frequency: "1.184059",
    inline_link_clicks: "8",
    website_ctr: [{ action_type: "link_click", value: "0.22818" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "3" },
      { action_type: "onsite_conversion.post_save", value: "1" },
      { action_type: "link_click", value: "8" },
      { action_type: "post", value: "1" },
      { action_type: "landing_page_view", value: "3" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "1" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "2",
      },
      { action_type: "post_engagement", value: "10" },
      { action_type: "page_engagement", value: "10" },
      { action_type: "omni_initiated_checkout", value: "2" },
      { action_type: "omni_purchase", value: "3" },
      { action_type: "initiate_checkout", value: "2" },
      { action_type: "purchase", value: "3" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "61.44" },
      { action_type: "link_click", value: "7.68" },
      { action_type: "landing_page_view", value: "20.48" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "61.44" },
      { action_type: "post_engagement", value: "6.144" },
      { action_type: "page_engagement", value: "6.144" },
      { action_type: "omni_initiated_checkout", value: "30.72" },
      { action_type: "omni_purchase", value: "20.48" },
      { action_type: "initiate_checkout", value: "30.72" },
      { action_type: "purchase", value: "20.48" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "1191.9",
      },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "2",
      },
      { action_type: "omni_initiated_checkout", value: "2" },
      { action_type: "omni_purchase", value: "1191.9" },
      { action_type: "initiate_checkout", value: "2" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "19.399414",
      },
    ],
    date_start: "2022-02-14",
    date_stop: "2022-02-14",
  },
  {
    reach: "3719",
    impressions: "4464",
    spend: "60.34",
    clicks: "24",
    cpc: "2.514167",
    ctr: "0.537634",
    cpp: "16.224792",
    cpm: "13.517025",
    frequency: "1.200323",
    inline_link_clicks: "7",
    website_ctr: [{ action_type: "link_click", value: "0.15681" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1" },
      { action_type: "link_click", value: "7" },
      { action_type: "post_reaction", value: "1" },
      { action_type: "landing_page_view", value: "1" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "post_engagement", value: "8" },
      { action_type: "page_engagement", value: "8" },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "omni_purchase", value: "1" },
      { action_type: "initiate_checkout", value: "3" },
      { action_type: "purchase", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "8.62" },
      { action_type: "landing_page_view", value: "60.34" },
      { action_type: "post_engagement", value: "7.5425" },
      { action_type: "page_engagement", value: "7.5425" },
      { action_type: "omni_initiated_checkout", value: "20.113333" },
      { action_type: "omni_purchase", value: "60.34" },
      { action_type: "initiate_checkout", value: "20.113333" },
      { action_type: "purchase", value: "60.34" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "647" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "omni_purchase", value: "647" },
      { action_type: "initiate_checkout", value: "3" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "10.722572",
      },
    ],
    date_start: "2022-02-15",
    date_stop: "2022-02-15",
  },
  {
    reach: "3356",
    impressions: "4101",
    spend: "56.82",
    clicks: "22",
    cpc: "2.582727",
    ctr: "0.536455",
    cpp: "16.93087",
    cpm: "13.855157",
    frequency: "1.22199",
    inline_link_clicks: "10",
    website_ctr: [{ action_type: "link_click", value: "0.243843" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1" },
      { action_type: "link_click", value: "10" },
      { action_type: "post_reaction", value: "2" },
      { action_type: "landing_page_view", value: "4" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "5",
      },
      { action_type: "post_engagement", value: "12" },
      { action_type: "page_engagement", value: "12" },
      { action_type: "omni_initiated_checkout", value: "5" },
      { action_type: "omni_purchase", value: "1" },
      { action_type: "initiate_checkout", value: "5" },
      { action_type: "purchase", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "5.682" },
      { action_type: "landing_page_view", value: "14.205" },
      { action_type: "post_engagement", value: "4.735" },
      { action_type: "page_engagement", value: "4.735" },
      { action_type: "omni_initiated_checkout", value: "11.364" },
      { action_type: "omni_purchase", value: "56.82" },
      { action_type: "initiate_checkout", value: "11.364" },
      { action_type: "purchase", value: "56.82" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "247" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "5",
      },
      { action_type: "omni_initiated_checkout", value: "5" },
      { action_type: "omni_purchase", value: "247" },
      { action_type: "initiate_checkout", value: "5" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "4.347061",
      },
    ],
    date_start: "2022-02-16",
    date_stop: "2022-02-16",
  },
  {
    reach: "2656",
    impressions: "3301",
    spend: "59.04",
    clicks: "22",
    cpc: "2.683636",
    ctr: "0.666465",
    cpp: "22.228916",
    cpm: "17.885489",
    frequency: "1.242846",
    inline_link_clicks: "7",
    website_ctr: [{ action_type: "link_click", value: "0.212057" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "2" },
      { action_type: "link_click", value: "7" },
      { action_type: "post_reaction", value: "1" },
      { action_type: "landing_page_view", value: "5" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "2" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "post_engagement", value: "8" },
      { action_type: "page_engagement", value: "8" },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "omni_purchase", value: "2" },
      { action_type: "initiate_checkout", value: "3" },
      { action_type: "purchase", value: "2" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "8.434286" },
      { action_type: "landing_page_view", value: "11.808" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "29.52" },
      { action_type: "post_engagement", value: "7.38" },
      { action_type: "page_engagement", value: "7.38" },
      { action_type: "omni_initiated_checkout", value: "19.68" },
      { action_type: "omni_purchase", value: "29.52" },
      { action_type: "initiate_checkout", value: "19.68" },
      { action_type: "purchase", value: "29.52" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1294" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "omni_purchase", value: "1294" },
      { action_type: "initiate_checkout", value: "3" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "21.917344",
      },
    ],
    date_start: "2022-02-17",
    date_stop: "2022-02-17",
  },
  {
    reach: "3650",
    impressions: "5441",
    spend: "137.55",
    clicks: "33",
    cpc: "4.168182",
    ctr: "0.606506",
    cpp: "37.684932",
    cpm: "25.280279",
    frequency: "1.490685",
    inline_link_clicks: "16",
    website_ctr: [{ action_type: "link_click", value: "0.294064" }],
    actions: [
      { action_type: "comment", value: "1" },
      { action_type: "link_click", value: "16" },
      { action_type: "post", value: "1" },
      { action_type: "post_reaction", value: "1" },
      { action_type: "landing_page_view", value: "12" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "1" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "2",
      },
      { action_type: "post_engagement", value: "19" },
      { action_type: "page_engagement", value: "19" },
      { action_type: "omni_initiated_checkout", value: "2" },
      { action_type: "initiate_checkout", value: "2" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "8.596875" },
      { action_type: "landing_page_view", value: "11.4625" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "137.55" },
      { action_type: "post_engagement", value: "7.239474" },
      { action_type: "page_engagement", value: "7.239474" },
      { action_type: "omni_initiated_checkout", value: "68.775" },
      { action_type: "initiate_checkout", value: "68.775" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "2",
      },
      { action_type: "omni_initiated_checkout", value: "2" },
      { action_type: "initiate_checkout", value: "2" },
    ],
    date_start: "2022-02-18",
    date_stop: "2022-02-18",
  },
  {
    reach: "2673",
    impressions: "3770",
    spend: "79.94",
    clicks: "24",
    cpc: "3.330833",
    ctr: "0.636605",
    cpp: "29.906472",
    cpm: "21.204244",
    frequency: "1.4104",
    inline_link_clicks: "10",
    website_ctr: [{ action_type: "link_click", value: "0.265252" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1" },
      { action_type: "comment", value: "1" },
      { action_type: "link_click", value: "10" },
      { action_type: "post_reaction", value: "2" },
      { action_type: "landing_page_view", value: "2" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "1",
      },
      { action_type: "post_engagement", value: "13" },
      { action_type: "page_engagement", value: "13" },
      { action_type: "omni_initiated_checkout", value: "1" },
      { action_type: "omni_purchase", value: "1" },
      { action_type: "initiate_checkout", value: "1" },
      { action_type: "purchase", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "7.994" },
      { action_type: "landing_page_view", value: "39.97" },
      { action_type: "post_engagement", value: "6.149231" },
      { action_type: "page_engagement", value: "6.149231" },
      { action_type: "omni_initiated_checkout", value: "79.94" },
      { action_type: "omni_purchase", value: "79.94" },
      { action_type: "initiate_checkout", value: "79.94" },
      { action_type: "purchase", value: "79.94" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "597.9" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "1",
      },
      { action_type: "omni_initiated_checkout", value: "1" },
      { action_type: "omni_purchase", value: "597.9" },
      { action_type: "initiate_checkout", value: "1" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "7.47936",
      },
    ],
    date_start: "2022-02-19",
    date_stop: "2022-02-19",
  },
  {
    reach: "3145",
    impressions: "4109",
    spend: "76.23",
    clicks: "35",
    cpc: "2.178",
    ctr: "0.851789",
    cpp: "24.238474",
    cpm: "18.551959",
    frequency: "1.306518",
    inline_link_clicks: "17",
    website_ctr: [{ action_type: "link_click", value: "0.413726" }],
    actions: [
      { action_type: "onsite_conversion.post_save", value: "1" },
      { action_type: "link_click", value: "17" },
      { action_type: "post_reaction", value: "1" },
      { action_type: "landing_page_view", value: "4" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "1",
      },
      { action_type: "post_engagement", value: "19" },
      { action_type: "page_engagement", value: "19" },
      { action_type: "omni_initiated_checkout", value: "1" },
      { action_type: "initiate_checkout", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "onsite_conversion.post_save", value: "76.23" },
      { action_type: "link_click", value: "4.484118" },
      { action_type: "landing_page_view", value: "19.0575" },
      { action_type: "post_engagement", value: "4.012105" },
      { action_type: "page_engagement", value: "4.012105" },
      { action_type: "omni_initiated_checkout", value: "76.23" },
      { action_type: "initiate_checkout", value: "76.23" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "1",
      },
      { action_type: "omni_initiated_checkout", value: "1" },
      { action_type: "initiate_checkout", value: "1" },
    ],
    date_start: "2022-02-20",
    date_stop: "2022-02-20",
  },
  {
    reach: "3397",
    impressions: "4224",
    spend: "62.42",
    clicks: "19",
    cpc: "3.285263",
    ctr: "0.449811",
    cpp: "18.375037",
    cpm: "14.777462",
    frequency: "1.24345",
    inline_link_clicks: "7",
    website_ctr: [{ action_type: "link_click", value: "0.16572" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "2" },
      { action_type: "link_click", value: "7" },
      { action_type: "post", value: "1" },
      { action_type: "post_reaction", value: "1" },
      { action_type: "landing_page_view", value: "2" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "post_engagement", value: "9" },
      { action_type: "page_engagement", value: "9" },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "omni_purchase", value: "2" },
      { action_type: "initiate_checkout", value: "3" },
      { action_type: "purchase", value: "2" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "8.917143" },
      { action_type: "landing_page_view", value: "31.21" },
      { action_type: "post_engagement", value: "6.935556" },
      { action_type: "page_engagement", value: "6.935556" },
      { action_type: "omni_initiated_checkout", value: "20.806667" },
      { action_type: "omni_purchase", value: "31.21" },
      { action_type: "initiate_checkout", value: "20.806667" },
      { action_type: "purchase", value: "31.21" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "1344.9",
      },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "3",
      },
      { action_type: "omni_initiated_checkout", value: "3" },
      { action_type: "omni_purchase", value: "1344.9" },
      { action_type: "initiate_checkout", value: "3" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "21.545979",
      },
    ],
    date_start: "2022-02-21",
    date_stop: "2022-02-21",
  },
  {
    reach: "2584",
    impressions: "3335",
    spend: "58.64",
    clicks: "23",
    cpc: "2.549565",
    ctr: "0.689655",
    cpp: "22.693498",
    cpm: "17.583208",
    frequency: "1.290635",
    inline_link_clicks: "10",
    website_ctr: [{ action_type: "link_click", value: "0.29985" }],
    actions: [
      { action_type: "comment", value: "2" },
      { action_type: "link_click", value: "10" },
      { action_type: "post_reaction", value: "3" },
      { action_type: "landing_page_view", value: "3" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "5",
      },
      { action_type: "post_engagement", value: "15" },
      { action_type: "page_engagement", value: "15" },
      { action_type: "omni_initiated_checkout", value: "5" },
      { action_type: "initiate_checkout", value: "5" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "5.864" },
      { action_type: "landing_page_view", value: "19.546667" },
      { action_type: "post_engagement", value: "3.909333" },
      { action_type: "page_engagement", value: "3.909333" },
      { action_type: "omni_initiated_checkout", value: "11.728" },
      { action_type: "initiate_checkout", value: "11.728" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "5",
      },
      { action_type: "omni_initiated_checkout", value: "5" },
      { action_type: "initiate_checkout", value: "5" },
    ],
    date_start: "2022-02-22",
    date_stop: "2022-02-22",
  },
  {
    reach: "2571",
    impressions: "3207",
    spend: "57.86",
    clicks: "32",
    cpc: "1.808125",
    ctr: "0.997817",
    cpp: "22.504862",
    cpm: "18.041784",
    frequency: "1.247375",
    inline_link_clicks: "10",
    website_ctr: [{ action_type: "link_click", value: "0.311818" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "2" },
      { action_type: "comment", value: "1" },
      { action_type: "link_click", value: "10" },
      { action_type: "post_reaction", value: "3" },
      { action_type: "landing_page_view", value: "4" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "2",
      },
      { action_type: "post_engagement", value: "14" },
      { action_type: "page_engagement", value: "14" },
      { action_type: "omni_initiated_checkout", value: "2" },
      { action_type: "omni_purchase", value: "2" },
      { action_type: "initiate_checkout", value: "2" },
      { action_type: "purchase", value: "2" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "5.786" },
      { action_type: "landing_page_view", value: "14.465" },
      { action_type: "post_engagement", value: "4.132857" },
      { action_type: "page_engagement", value: "4.132857" },
      { action_type: "omni_initiated_checkout", value: "28.93" },
      { action_type: "omni_purchase", value: "28.93" },
      { action_type: "initiate_checkout", value: "28.93" },
      { action_type: "purchase", value: "28.93" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "444.9" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "2",
      },
      { action_type: "omni_initiated_checkout", value: "2" },
      { action_type: "omni_purchase", value: "444.9" },
      { action_type: "initiate_checkout", value: "2" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "7.68925",
      },
    ],
    date_start: "2022-02-23",
    date_stop: "2022-02-23",
  },
  {
    reach: "3464",
    impressions: "4385",
    spend: "60.72",
    clicks: "25",
    cpc: "2.4288",
    ctr: "0.570125",
    cpp: "17.528868",
    cpm: "13.847206",
    frequency: "1.265878",
    inline_link_clicks: "10",
    website_ctr: [{ action_type: "link_click", value: "0.22805" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "4" },
      { action_type: "link_click", value: "10" },
      { action_type: "post_reaction", value: "1" },
      { action_type: "landing_page_view", value: "4" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "1" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "post_engagement", value: "11" },
      { action_type: "page_engagement", value: "11" },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "omni_purchase", value: "4" },
      { action_type: "initiate_checkout", value: "4" },
      { action_type: "purchase", value: "4" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "6.072" },
      { action_type: "landing_page_view", value: "15.18" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "60.72" },
      { action_type: "post_engagement", value: "5.52" },
      { action_type: "page_engagement", value: "5.52" },
      { action_type: "omni_initiated_checkout", value: "15.18" },
      { action_type: "omni_purchase", value: "15.18" },
      { action_type: "initiate_checkout", value: "15.18" },
      { action_type: "purchase", value: "15.18" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "865.1" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "4",
      },
      { action_type: "omni_initiated_checkout", value: "4" },
      { action_type: "omni_purchase", value: "865.1" },
      { action_type: "initiate_checkout", value: "4" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "14.247365",
      },
    ],
    date_start: "2022-02-24",
    date_stop: "2022-02-24",
  },
  {
    reach: "2044",
    impressions: "2457",
    spend: "32.62",
    clicks: "10",
    cpc: "3.262",
    ctr: "0.407",
    cpp: "15.958904",
    cpm: "13.276353",
    frequency: "1.202055",
    inline_link_clicks: "5",
    website_ctr: [{ action_type: "link_click", value: "0.2035" }],
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1" },
      { action_type: "link_click", value: "5" },
      { action_type: "landing_page_view", value: "1" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "1" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "2",
      },
      { action_type: "post_engagement", value: "5" },
      { action_type: "page_engagement", value: "5" },
      { action_type: "omni_initiated_checkout", value: "2" },
      { action_type: "omni_purchase", value: "1" },
      { action_type: "initiate_checkout", value: "2" },
      { action_type: "purchase", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "link_click", value: "6.524" },
      { action_type: "landing_page_view", value: "32.62" },
      { action_type: "offsite_conversion.fb_pixel_custom", value: "32.62" },
      { action_type: "post_engagement", value: "6.524" },
      { action_type: "page_engagement", value: "6.524" },
      { action_type: "omni_initiated_checkout", value: "16.31" },
      { action_type: "omni_purchase", value: "32.62" },
      { action_type: "initiate_checkout", value: "16.31" },
      { action_type: "purchase", value: "32.62" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "297.9" },
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "2",
      },
      { action_type: "omni_initiated_checkout", value: "2" },
      { action_type: "omni_purchase", value: "297.9" },
      { action_type: "initiate_checkout", value: "2" },
    ],
    website_purchase_roas: [
      {
        action_type: "offsite_conversion.fb_pixel_purchase",
        value: "9.132434",
      },
    ],
    date_start: "2022-02-25",
    date_stop: "2022-02-25",
  },
  {
    reach: "0",
    impressions: "0",
    spend: "0",
    clicks: "0",
    frequency: "0",
    inline_link_clicks: "0",
    actions: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "1",
      },
      { action_type: "omni_initiated_checkout", value: "1" },
      { action_type: "initiate_checkout", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "omni_initiated_checkout", value: "0" },
      { action_type: "initiate_checkout", value: "0" },
    ],
    action_values: [
      {
        action_type: "offsite_conversion.fb_pixel_initiate_checkout",
        value: "1",
      },
      { action_type: "omni_initiated_checkout", value: "1" },
      { action_type: "initiate_checkout", value: "1" },
    ],
    date_start: "2022-02-27",
    date_stop: "2022-02-27",
  },
  {
    reach: "0",
    impressions: "0",
    spend: "0",
    clicks: "0",
    frequency: "0",
    inline_link_clicks: "0",
    actions: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "1" },
      { action_type: "omni_purchase", value: "1" },
      { action_type: "purchase", value: "1" },
    ],
    cost_per_action_type: [
      { action_type: "omni_purchase", value: "0" },
      { action_type: "purchase", value: "0" },
    ],
    action_values: [
      { action_type: "offsite_conversion.fb_pixel_purchase", value: "797" },
      { action_type: "omni_purchase", value: "797" },
    ],
    date_start: "2022-03-02",
    date_stop: "2022-03-02",
  },
];

export const summary = {
  reach: "214611",
  impressions: "313255",
  spend: "3883.57",
  clicks: "1785",
  cpc: "2.175669",
  ctr: "0.569823",
  cpp: "18.095857",
  cpm: "12.397472",
  frequency: "1.459641",
  inline_link_clicks: "892",
  website_ctr: [{ action_type: "link_click", value: "0.284752" }],
  actions: [
    { action_type: "offsite_conversion.fb_pixel_purchase", value: "33" },
    { action_type: "video_view", value: "14861" },
    { action_type: "comment", value: "21" },
    { action_type: "onsite_conversion.post_save", value: "19" },
    { action_type: "link_click", value: "892" },
    { action_type: "post", value: "29" },
    { action_type: "post_reaction", value: "180" },
    { action_type: "landing_page_view", value: "490" },
    { action_type: "offsite_conversion.fb_pixel_custom", value: "71" },
    {
      action_type: "offsite_conversion.fb_pixel_initiate_checkout",
      value: "123",
    },
    { action_type: "post_engagement", value: "16002" },
    { action_type: "page_engagement", value: "16002" },
    { action_type: "omni_purchase", value: "33" },
    { action_type: "omni_initiated_checkout", value: "123" },
    { action_type: "purchase", value: "33" },
    { action_type: "initiate_checkout", value: "123" },
  ],
  cost_per_action_type: [
    { action_type: "video_view", value: "0.261326" },
    { action_type: "onsite_conversion.post_save", value: "204.398421" },
    { action_type: "link_click", value: "4.353778" },
    { action_type: "landing_page_view", value: "7.925653" },
    { action_type: "offsite_conversion.fb_pixel_custom", value: "54.698169" },
    { action_type: "post_engagement", value: "0.242693" },
    { action_type: "page_engagement", value: "0.242693" },
    { action_type: "omni_purchase", value: "117.683939" },
    { action_type: "omni_initiated_checkout", value: "31.57374" },
    { action_type: "purchase", value: "117.683939" },
    { action_type: "initiate_checkout", value: "31.57374" },
  ],
  action_values: [
    {
      action_type: "offsite_conversion.fb_pixel_purchase",
      value: "16180.45",
    },
    {
      action_type: "offsite_conversion.fb_pixel_initiate_checkout",
      value: "123",
    },
    { action_type: "omni_purchase", value: "16180.45" },
    { action_type: "omni_initiated_checkout", value: "123" },
    { action_type: "initiate_checkout", value: "123" },
  ],
  website_purchase_roas: [
    {
      action_type: "offsite_conversion.fb_pixel_purchase",
      value: "4.166386",
    },
  ],
  date_start: "2021-07-23",
  date_stop: "2022-03-12",
};

export const campaigns = [
  {
    name: "SUA CAMPANHA",
    objective: "REACH",
    status: "ACTIVE",
    configured_status: "ACTIVE",
    effective_status: "ACTIVE",
    created_time: "2022-04-12T11:14:24-0300",
    updated_time: "2022-04-12T11:14:24-0300",
    start_time: "2022-04-12T11:14:24-0300",
    insights: {
      data: [
        {
          reach: "1000",
          impressions: "274",
          spend: "100",
          clicks: "1200",
          ctr: "0",
          cpp: "21.538462",
          cpm: "20.437956",
          frequency: "1.053846",
          website_ctr: [
            {
              action_type: "link_click",
              value: "2",
            },
          ],
          actions: [
            {
              action_type: "link_click",
              value: "120",
            },
          ],
          cost_per_action_type: [
            {
              action_type: "link_click",
              value: "0.84",
            },
          ],
          date_start: "2022-03-14",
          date_stop: "2022-04-12",
        },
      ],
    },
  },
  {
    name: "OUTRA CAMPANHA",
    objective: "REACH",
    status: "PAUSED",
    configured_status: "PAUSED",
    effective_status: "PAUSED",
    created_time: "2022-04-12T11:14:24-0300",
    updated_time: "2022-04-12T11:14:24-0300",
    start_time: "2022-04-12T11:14:24-0300",
    insights: {
      data: [
        {
          reach: "1000",
          impressions: "274",
          spend: "100",
          clicks: "1200",
          ctr: "0",
          cpp: "21.538462",
          cpm: "20.437956",
          frequency: "1.053846",
          website_ctr: [
            {
              action_type: "link_click",
              value: "2",
            },
          ],
          actions: [
            {
              action_type: "link_click",
              value: "120",
            },
          ],
          cost_per_action_type: [
            {
              action_type: "link_click",
              value: "0.84",
            },
          ],
          date_start: "2022-03-14",
          date_stop: "2022-04-12",
        },
      ],
    },
  },
  {
    name: "MAIS UMA CAMPANHA",
    objective: "REACH",
    status: "PAUSED",
    configured_status: "PAUSED",
    effective_status: "PAUSED",
    created_time: "2022-04-12T11:14:24-0300",
    updated_time: "2022-04-12T11:14:24-0300",
    start_time: "2022-04-12T11:14:24-0300",
    insights: {
      data: [
        {
          reach: "1000",
          impressions: "274",
          spend: "100",
          clicks: "1200",
          ctr: "0",
          cpp: "21.538462",
          cpm: "20.437956",
          frequency: "1.053846",
          website_ctr: [
            {
              action_type: "link_click",
              value: "2",
            },
          ],
          actions: [
            {
              action_type: "link_click",
              value: "120",
            },
          ],
          cost_per_action_type: [
            {
              action_type: "link_click",
              value: "0.84",
            },
          ],
          date_start: "2022-03-14",
          date_stop: "2022-04-12",
        },
      ],
    },
  },
];
