import React, { useState } from "react";
import { gateways } from "../../common/data/data_sources";
import { Table, Tag } from "rsuite";
import { braip_mock, appmax_mock } from "../../common/data/mock";
import { order_status_list } from "../../common/data/order_statuses";
import { payment_methods } from "../../common/data/payment_methods";
import SaleDetails from "./Sale.details";
import moment from "moment";
import { detectMobileBrowser } from "../../functions/detectMobileBrowser";
import $ from "jquery";
import { images, useCardBrand } from "react-card-brand";

import CardBrandSvg from "./CardBrandSvg";

const { Column, HeaderCell, Cell } = Table;

const all_columns = [
  {
    key: "identification.data_source",
    label: "Gateway de pagamento",
    fixed: false,
    width: 100,
    resizeble: true,
  },
  {
    key: "transaction.value",
    label: "Valor da transação",
    fixed: false,
    width: 120,
    resizeble: true,
  },
  {
    key: "transaction.payment_type",
    label: "Método de pagamento",
    fixed: false,
    width: 50,
    resizeble: true,
  },
  {
    key: "transaction.status",
    label: "Status",
    fixed: false,
    width: 170,
    resizeble: true,
  },
  {
    key: "customer.email",
    label: "E-mail do cliente",
    fixed: false,
    width: 250,
    resizeble: true,
  },
  // {
  //   key: "transaction.freight",
  //   label: "Frete",
  //   fixed: false,
  //   width: 100,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.freight_type",
  //   label: "Tipo de frete",
  //   fixed: false,
  //   width: 120,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.created_date",
  //   label: "Data de criação",
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  {
    key: "transaction.updated_date",
    label: "Última atualização",
    fixed: false,
    width: 200,
    resizeble: true,
  },
  // {
  //   key: "identification.order_id",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },

  // {
  //   key: "transaction.discount_value",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },

  // {
  //   key: "transaction.payment_line",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.payment_bar_code",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.payment_url",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.installments",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.pix_qrcode",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.pix_emv",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.pix_ref",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.pix_expiration_date",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.pix_creation_date",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.pix_url",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "transaction.is_upsell",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // // {
  // //   key: "product",
  // //   label: null,
  // //   fixed: false,
  // //   width: 200,
  // //   resizeble: true,
  // // },
  // {
  //   key: "producer.name",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "producer.document",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "producer.email",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "producer.tel",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "customer.id",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "customer.name",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },

  // {
  //   key: "customer.telephone",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "customer.document",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  // {
  //   key: "comission.co_production_commission",
  //   label: null,
  //   fixed: false,
  //   width: 200,
  //   resizeble: true,
  // },
  {
    key: "metadata.affiliate_id",
    label: "ID de afiliado",
    fixed: false,
    width: 200,
    resizeble: true,
  },
  {
    key: "metadata.utm_source",
    label: "utm_source",
    fixed: false,
    width: 200,
    resizeble: true,
  },
  {
    key: "metadata.utm_medium",
    label: "utm_medium",
    fixed: false,
    width: 200,
    resizeble: true,
  },
  {
    key: "metadata.utm_campaign",
    label: "utm_campaign",
    fixed: false,
    width: 200,
    resizeble: true,
  },
];

function isLessThan24HourAgo(date) {
  // 👇️                    hour  min  sec  milliseconds
  const twentyFourHrInMs = 24 * 60 * 60 * 1000;

  const twentyFourHoursAgo = Date.now() - twentyFourHrInMs;

  return date > twentyFourHoursAgo;
}

const flatten = (object, prefix = "") => {
  return Object.keys(object).reduce((prev, element) => {
    // if (element === "products") {
    //   return prev;
    // }

    return object[element] &&
      element !== "product" &&
      typeof object[element] == "object" &&
      !Array.isArray(element)
      ? {
          ...prev,
          ...flatten(object[element], `${prefix}${element}.`),
        }
      : {
          ...prev,
          ...{ [`${prefix}${element}`]: object[element] },
        };
  }, {});
};

const PaymentMethodCell = ({ rowData, dataKey, ...props }) => {
  const payment_method = payment_methods.find(
    (method) => method.code === rowData[dataKey]
  );
  return (
    <Cell {...props}>
      <span className="mx-2">
        {rowData["transaction.payment_card_brand"] ? (
          <>
            <CardBrandSvg
              cardBrand={rowData[
                "transaction.payment_card_brand"
              ].toLowerCase()}
            />
          </>
        ) : (
          <img
            src={payment_method?.icon || "mdi mdi-error"}
            style={{ maxHeight: 20 }}
          />
        )}
      </span>
    </Cell>
  );
};

const DataSourceCell = ({ rowData, dataKey, ...props }) => {
  const data_source = gateways.find(
    (gateway) => gateway.code === rowData[dataKey]
  );
  return (
    <Cell {...props}>
      <Tag
        size="sm"
        style={{
          backgroundColor: `${
            data_source?.color ? `${data_source?.color}E6` : "gray"
          }`,
        }}
      >
        <img
          src={data_source?.icon_url}
          style={{ height: 10 }}
          alt={data_source?.label}
        />
      </Tag>
    </Cell>
  );
};

const CustomCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>
    <span className="mx-2">{rowData[dataKey]}</span>
  </Cell>
);

const ValueCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>
    <b className="mx-2">
      {parseFloat(rowData[dataKey] / 100).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
        maximumFractionDigits: 2,
      })}
    </b>
  </Cell>
);

const DateCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>
    {isLessThan24HourAgo(new Date(rowData[dataKey])) ? (
      <>
        <span className="mx-2">
          <i className="mdi mdi-clock-time-four-outline me-1 text-muted" />
          {rowData[dataKey]}
          {/* {moment(rowData[dataKey]).fromNow()} */}
        </span>
      </>
    ) : (
      <>
        <span className="mx-2">
          <i className="mdi mdi-calendar-blank me-1 text-muted" />
          {moment.utc(rowData[dataKey]).format("DD/MM")}
        </span>
        <span className="mx-2">
          <i className="mdi mdi-clock-time-four-outline me-1 text-muted" />
          {moment.utc(rowData[dataKey]).format("HH:mm")}
        </span>
      </>
    )}
  </Cell>
);

const StatusCell = ({ rowData, dataKey, ...props }) => {
  const status =
    order_status_list.find((s) => s.code === rowData[dataKey]) ||
    order_status_list[0];
  return (
    <Cell {...props}>
      <span className="mx-2">
        <Tag
          style={{
            backgroundColor: `${status.color || "#000000"}CC`,
            color: "#fff",
          }}
          size="sm"
        >
          {status.label || "-"}
          <i className={`${status.icon || null} ms-1`} />
        </Tag>
      </span>
    </Cell>
  );
};

const SalesTable = (props) => {
  const [open, setOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState({});
  const [columnKeys, setColumnKeys] = React.useState(
    all_columns.map((column) => column.key)
  );
  const [sortColumn, setSortColumn] = React.useState(null);
  const [sortType, setSortType] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = useState("");
  const filteredColumns =
    search.length > 0
      ? all_columns.filter((o) =>
          o.label
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.toLowerCase().replace(/ /g, ""))
        )
      : all_columns;

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 400);
  };

  const columns = all_columns.filter((column) =>
    columnKeys.some((key) => key === column.key)
  );

  const data = props.data?.map((o) => flatten(o)) || [];
  console.log(data);

  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  return (
    <div style={{ height: "75vh" }}>
      <Table
        virtualized={getData().length > 100}
        id="campaigns-datatable"
        fillHeight
        rowHeight={35}
        style={{ width: "100%" }}
        className="d-flex flex-column"
        data={getData()}
        loading={props.loading}
        onRowClick={(rowData) => {
          if (!detectMobileBrowser()) {
            const order_data = props.data.find(
              (o) =>
                o.identification.order_id === rowData["identification.order_id"]
            );
            setSelectedOrder(order_data);
            setOpen(true);
          }
        }}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        {columns.map((column) => {
          const { key, label, ...rest } = column;
          return (
            <Column {...rest} key={key} sortable>
              <HeaderCell>{column.label}</HeaderCell>
              {key.includes("data_source") ? (
                <DataSourceCell dataKey={key} />
              ) : key.includes("value") || key === "transaction.freight" ? (
                <ValueCell dataKey={key} />
              ) : key.includes("date") ? (
                <DateCell dataKey={key} />
              ) : key.includes("status") ? (
                <StatusCell dataKey={key} />
              ) : key.includes("payment_type") ? (
                <PaymentMethodCell dataKey={key} />
              ) : (
                <CustomCell dataKey={key} />
              )}
            </Column>
          );
        })}
      </Table>
      <SaleDetails orderData={selectedOrder} open={open} setOpen={setOpen} />
    </div>
  );
};

export default SalesTable;
