export const campaign_status_list = [
  //FA
  {
    code: "ACTIVE",
    label: "Ativa",
    color: "#32CB82",
  },
  {
    code: "PAUSED",
    label: "Pausada",
    color: "#9599ad",
  },
  {
    code: "DELETED",
    label: "Deletada",
    color: "#f45130",
  },
  {
    code: "ARCHIVED",
    label: "Arquivada",
    color: "#564ab1",
  },
  {
    code: "IN_PROCESS",
    label: "Em processamento",
    color: "#1e55eb",
  },
  {
    code: "WITH_ISSUES",
    label: "Com problemas",
    color: "#fe9d02",
  },
  //AW
  {
    code: "UNSPECIFIED",
    label: "Não especificado",
    color: "#9599ad",
  },
  {
    code: "UNKNOWN",
    label: "Desconhecido",
    color: "#9599ad",
  },
];
