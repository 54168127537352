import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import api from "../../services/api";
import { changeLayout } from "../../store/actions";

//Toast
import { toast } from "react-toastify";

const Permissions = (props) => {
  async function grantAdminPermission(event) {
    //Toast loading
    const id = toast.loading("Verificando dados...");
    console.log(
      "Tornando o usuário de ID: " + event.target.id.value + " administrador..."
    );
    await api
      .put("/api/admin/grant-admin", { _id: event.target.id.value })
      .then((res) => {
        //Toast success
        toast.update(id, {
          render: "Permissão concedida com sucesso",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.log(err);
        //Toast success
        toast.update(id, {
          render: "Erro ao realizar operação",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      });
  }

  async function alertzy() {
    api
      .post("/api/alertzy", {
        group: "tests",
        title: "Novo Usuário",
        message: "Novo cadastro no aplicativo",
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    props.changeLayout("vertical");
  }, []);
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <h4>Dar permissão de administrador</h4>
                <p className="text-danger mt-3">
                  Atenção, ao dar permissão de administrador para um usuário o
                  mesmo poderá acessar as áreas restritas, portanto cuidado ao
                  realizar esta ação.
                </p>
                <div className="mt-4 pt-3">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={grantAdminPermission}
                  >
                    <div className="mb-3">
                      <AvField
                        name="id"
                        label="ID do usuário"
                        type="text"
                        placeholder="ID do usuário que deseja tornar administrador (Cuidado ao realizar essa ação!)"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Por favor, digite o ID do usuário",
                          },
                        }}
                      />
                    </div>

                    <div className="mt-3 text-end">
                      <button
                        className="btn btn-danger w-sm waves-effect waves-light btn-100"
                        type="submit"
                      >
                        <span className="text-white">Tornar Administrador</span>
                      </button>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* <Col lg={6}>
            <Card>
              <CardBody>
                <h4>Clique para enviar uma notificação Push</h4>
                <button
                  className="btn btn-primary mt-5"
                  onClick={() => alertzy()}
                >
                  Testar Alertzy
                </button>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeLayout: (layout) => dispatch(changeLayout(layout)),
});

export default connect(null, mapDispatchToProps)(Permissions);
