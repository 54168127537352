import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { CardBody, CardTitle } from "reactstrap";
import { defineMetric } from "../functions/metricFormatter";

const ColumnChart = (props) => {
  const [metric, setMetric] = useState();

  var series = [
    {
      name: props.selected.title,
      data: metric || [],
    },
  ];

  var options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    annotations: {
      yaxis:
        props.selected?.lines?.map((line) => ({
          y: line.limit,
          borderColor: line.color || "#000",
          strokeDashArray: 5,
          label: {
            borderColor: line.color || "#000",
            style: {
              color: "#fff",
              background: line.color || "#000",
            },
            text: line.text,
          },
        })) || [],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "90%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: props.metrics.map((a) =>
      moment(a.date_stop).toLocaleString("pt-BR")
    ),
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#0050ff"],
    xaxis: {
      type: "datetime",
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    yaxis: {
      labels: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
  };

  useEffect(() => {
    setMetric(defineMetric(props.metrics, props.selected.type));
  }, [props]);

  return (
    <React.Fragment>
      <CardBody style={{ height: "100%" }}>
        <CardTitle className="mb-2">{props.selected.title}</CardTitle>

        <ReactApexChart
          options={options}
          series={series}
          type={options.chart.type}
          width="100%"
          height="255"
        />
      </CardBody>
    </React.Fragment>
  );
};

export default ColumnChart;
