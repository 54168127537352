export function defineMetric(metrics, selectedType) {
  if (selectedType.includes(":")) {
    const metricString = selectedType
      .replace("$", "")
      .replace("%", "")
      .split(":");
    return metrics.map((a) =>
      parseFloat(
        a[metricString[0]]?.find((c) => c.action_type === metricString[1])
          ?.value || 0
      )
    );
  } else {
    return metrics.map((a) =>
      parseFloat(a[selectedType.replace("$", "").replace("%", "")] || 0)
    );
  }
}
