import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Nav } from "rsuite";
import { checkMetritoTags } from "../../../functions/checkMetritoTags";

const Navbar = ({ active, onSelect, ...props }) => {
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      style={{ width: "100%", borderRadius: 10 }}
      className="d-flex justify-content-between"
    >
      <Nav.Item
        eventKey="ALL"
        icon={<i className="mdi mdi-check" />}
        style={{ borderRadius: "10px 0px 0px 0px" }}
      >
        Todos
      </Nav.Item>
      <Nav.Item eventKey="SRC">Fonte</Nav.Item>
      <Nav.Item eventKey="PF">Perfil</Nav.Item>
      <Nav.Item eventKey="CA">CA</Nav.Item>
      <Nav.Item eventKey="CP">Campanha</Nav.Item>
      <Nav.Item eventKey="CJ">Conjunto</Nav.Item>
      <Nav.Item eventKey="AD" style={{ borderRadius: "0px 10px 0px 0px" }}>
        Anúncio
      </Nav.Item>
    </Nav>
  );
};

const SalesPieChart = (props) => {
  const series =
    Object.entries(props.data)?.map((entry) => entry[1] && entry[1].length) ||
    [];
  const options = {
    labels: Object.keys(props.data),

    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: {
      show: false,
      position: "right",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
      formatter: function (y) {
        if (typeof y !== "undefined") {
          return "teste";
        }
        return y;
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
  };

  return (
    <Card className="d-flex justify-items-center" style={{ height: 350 }}>
      {checkMetritoTags(props.data) && (
        <Navbar
          appearance="subtle"
          active={props.active}
          onSelect={props.setActive}
        />
      )}

      <CardBody>
        {/* <CardTitle className="mb-4">Fontes de dados</CardTitle> */}

        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          height="250"
          className="apex-charts"
          dir="ltr"
        />
      </CardBody>
    </Card>
  );
};

export default SalesPieChart;
