import React from "react";
import ReactApexChart from "react-apexcharts";

const AdminPieChart = (props) => {
  const series = Object.values(props.data);
  const options = {
    labels: Object.keys(props.data),
    colors: props.colors,
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    legend: {
      show: true,
      position: "left",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 0,
    },
    dataLabels: {
      enabled: true,
      offsetX: 10,
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          return y;
        },
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      style={{ marginLeft: -20 }}
      options={options}
      series={series}
      type="pie"
      height="174"
      className="apex-charts"
      dir="ltr"
    />
  );
};

export default AdminPieChart;
