import {
  subDays,
  subMonths,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
} from "date-fns";

export const get_preset = (preset) => {
  switch (preset) {
    case "today":
      return {
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
      };
    case "yesterday":
      return {
        start: startOfDay(subDays(new Date(), 1)),
        end: endOfDay(subDays(new Date(), 1)),
      };
    case "last_7d":
      return {
        start: startOfDay(subDays(new Date(), 7)),
        end: endOfDay(new Date()),
      };
    case "last_14d":
      return {
        start: startOfDay(subDays(new Date(), 14)),
        end: endOfDay(new Date()),
      };
    case "last_30d":
      return {
        start: startOfDay(subDays(new Date(), 30)),
        end: endOfDay(new Date()),
      };
    case "last_90d":
      return {
        start: startOfDay(subDays(new Date(), 90)),
        end: endOfDay(new Date()),
      };
    case "this_week_sun_today":
      return {
        start: startOfDay(subDays(new Date(), new Date().getDay())),
        end: endOfDay(new Date()),
      };
    case "last_week_sun_sat":
      return {
        start: startOfDay(subDays(new Date(), new Date().getDay() + 7)),
        end: endOfDay(subDays(new Date(), new Date().getDay() + 1)),
      };
    case "this_month":
      return {
        start: startOfDay(startOfMonth(new Date(), 0)),
        end: endOfDay(new Date()),
      };
    case "last_month":
      return {
        start: startOfDay(startOfMonth(subMonths(new Date(), 1), 0)),
        end: endOfDay(endOfMonth(subMonths(new Date(), 1), 0)),
      };
    case "last_90d":
      return {
        start: startOfDay(subDays(new Date(), 90)),
        end: endOfDay(new Date()),
      };
    case "this_year":
      return {
        start: startOfDay(new Date(new Date().getFullYear(), 0, 1)),
        end: endOfDay(new Date()),
      };

    default:
      return {
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
      };
  }
};

export const date_presets = [
  {
    code: "today",
    label: "Hoje",
  },
  {
    code: "yesterday",
    label: "Ontem",
  },
  {
    code: "last_7d",
    label: "Últimos 7 dias",
  },
  {
    code: "last_14d",
    label: "Últimos 14 dias",
  },
  {
    code: "last_30d",
    label: "Últimos 30 dias",
  },
  {
    code: "this_week_sun_today",
    label: "Esta semana",
  },
  {
    code: "last_week_sun_sat",
    label: "Semana passada",
  },
  {
    code: "this_month",
    label: "Este mês",
  },
  {
    code: "last_month",
    label: "Mês passado",
  },
  {
    code: "last_90d",
    label: "Últimos 90 dias",
  },
  {
    code: "this_year",
    label: "Este ano",
  },
];
