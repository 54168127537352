const INIT_STATE = {
  user: {},
  isLoadingUser: false,
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_LOADING_USER":
      return {
        ...state,
        isLoadingUser: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
      break;
    case "LOGOUT":
      return {
        ...state,
      };
      break;
    default:
      return state;
      break;
  }
};

export default User;
