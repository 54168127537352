export function blockOrNot(param) {
  switch (param) {
    case 1:
      return { label: "ativa", color: "success" };
      break;
    case 2:
      return { label: "bloqueada", color: "danger" };
      break;
    case 3:
      return { label: "problemático", color: "warning" };
      break;
    case 7:
      return { label: "revisão de risco pendente", color: "warning" };
      break;
    case 8:
      return { label: "acordo pendente", color: "dark" };
      break;
    case 9:
      return { label: "em período de carência", color: "danger" };
      break;
    case 100:
      return { label: "encerramento pendente", color: "warning" };
      break;
    case 101:
      return { label: "fechada", color: "dark" };
      break;
    default:
      return { label: "-", color: "muted" };
  }
}
