import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import LeftSidebarProfile from "./LeftSidebarProfile";
import ProfileTab from "./ProfileTab";

//services
import api from "../../services/api";
import {
  getIdUsuario,
  getNomeUsuario,
  setCreatedAt,
} from "../../services/auth";

const Profile = (props) => {
  const [userInfo, setUserInfo] = useState({});
  const [plans, setPlans] = useState([]);
  const [userPlan, setUserPlan] = useState({});

  function loadUserData() {
    api
      .get("/api/usuarios.details/" + getIdUsuario())
      .then((response) => {
        setUserInfo(response.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function loadPlans() {
    api
      .get("/api/plans/")
      .then((response) => {
        setPlans(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setUserPlan(
      plans.find(
        (plan) => plan?.gateway_product_id === userInfo.subscription?.plan?.sku
      ) ||
        plans.find(
          (plan) =>
            plan?.gateway_product_id ===
            userInfo.subscription?.items?.find((item) =>
              item.plan.nickname.includes("PL")
            ).plan.nickname
        ) ||
        {}
    );
  }, [userInfo, plans]);

  useEffect(() => {
    loadUserData();
    loadPlans();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Perfil | Metrito</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Páginas" breadcrumbItem="Perfil" />

          <Row className="mb-4">
            <Col xl={4}>
              <LeftSidebarProfile userInfo={userInfo} userPlan={userPlan} />
            </Col>
            <Col xl={8}>
              <ProfileTab userInfo={userInfo} userPlan={userPlan} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
