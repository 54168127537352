export const order_status_list = [
  {
    code: "unknown",
    label: "Desconhecido",
    color: "red",
    icon: "mdi mdi-timer-sand",
  },
  {
    code: "pending",
    label: "Pendente",
    color: "#fe9d02",
    icon: "mdi mdi-timer-sand",
  },
  {
    code: "approved",
    label: "Aprovado",
    color: "#00ba60",
    icon: "mdi mdi-check",
  },
  {
    code: "paid",
    label: "Pago",
    color: "#00ba60",
    icon: "mdi mdi-check",
  },
  {
    code: "processing",
    label: "Processando",
    color: "#549FFD",
    icon: "mdi mdi-progress-clock",
  },
  {
    code: "mediation",
    label: "Mediação",
    color: null,
  },
  {
    code: "rejected",
    label: "Rejeitado",
    color: "#FF3938",
    icon: "mdi mdi-close",
  },
  {
    code: "failed",
    label: "Falhou",
    color: "#FF3938",
    icon: "mdi mdi-close",
  },
  {
    code: "recusado_por_risco",
    label: "Recusado por risco",
    color: "#FF3938",
    icon: "mdi mdi-close",
  },
  {
    code: "canceled",
    label: "Cancelado",
    color: "#A3ABB6",
    icon: "mdi mdi-close",
  },
  {
    code: "refunded",
    label: "Estornado",
    color: "#A3ABB6",
    icon: "mdi mdi-keyboard-backspace",
  },
  {
    code: "authorized",
    label: "Autorizado",
    color: "#00ba60",
    icon: "mdi mdi-check",
  },
  {
    code: "charged_back",
    label: "Chargeback",
    color: "#FF3938",
    icon: "mdi mdi-keyboard-backspace",
  },
  {
    code: "completed",
    label: "Completo",
    color: "#00ba60",
    icon: "mdi mdi-check",
  },
  {
    code: "finished",
    label: "Finalizada",
    color: "#00ba60",
    icon: "mdi mdi-check",
  },
  {
    code: "checkout_error",
    label: "Erro de Checkout",
    color: "#FF3938",
    icon: "mdi mdi-close",
  },
  {
    code: "pre_checkout",
    label: "Pré-checkout",
    color: "#fe9d02",
    icon: "mdi mdi-store-outline",
  },
  {
    code: "expired",
    label: "Expirado",
    color: "#FF3938",
    icon: "mdi mdi-timer-sand mdi-rotate-180",
  },
  {
    code: "review",
    label: "Em análise",
    color: "#00386B",
    icon: "mdi mdi-radar",
  },
  {
    code: "pending_refund",
    label: "Estorno pendente",
    color: "#A3ABB6",
    icon: "mdi mdi-keyboard-backspace",
  },
  {
    code: "partially_paid",
    label: "Parcialmente pago",
    color: "#fe9d02",
    icon: "mdi mdi-cash-minus",
  },
  {
    code: "payment_past_due",
    label: "Pagamento atrasado",
    color: "#fe9d02",
    icon: "mdi mdi-cash-minus",
  },

  {
    code: "chargeback_in_dispute",
    label: "Chargeback em disputa",
    color: "#fe9d02",
    icon: "mdi mdi-timer-sand",
  },
  {
    code: "chargeback_in_tratative",
    label: "Chargeback em tratativa",
    color: "#fe9d02",
    icon: "mdi mdi-timer-sand",
  },
  {
    code: "chargeback_lost",
    label: "Chargeback perdido",
    color: "#FF3938",
    icon: "mdi mdi-close",
  },
  {
    code: "chargeback_won",
    label: "Chargeback ganho",
    color: "#00ba60",
    icon: "mdi mdi-check",
  },
  {
    code: "integrated",
    label: "Integrado",
    color: "#00ba60",
    icon: "mdi mdi-check",
  },
  {
    code: "pending_integration",
    label: "Integração pendente",
    color: "#549FFD",
    icon: "mdi mdi-progress-clock",
  },
  {
    code: "pending_integration_in_review",
    label: "Integração pendente em análise",
    color: "#549FFD",
    icon: "mdi mdi-progress-clock",
  },
  {
    code: "on_carriage",
    label: "Em transporte",
    color: "#549FFD",
    icon: "mdi mdi-truck-delivery",
  },
  {
    code: "delivered",
    label: "Entregue",
    color: "#00ba60",
    icon: "mdi mdi-check",
  },
  {
    code: "shipment_exception",
    label: "Problema no envio",
    color: "#FF3938",
    icon: "mdi mdi-close",
  },
  {
    code: "created",
    label: "Pedido criado",
    color: "#fe9d02",
    icon: "mdi mdi-timer-sand",
  },
  {
    code: "handling_products",
    label: "Manipulando produtos",
    color: "#fe9d02",
    icon: "mdi mdi-timer-sand",
  },
  {
    code: "ready_for_shipping",
    label: "Pronto para envio",
    color: "#fe9d02",
    icon: "mdi mdi-timer-sand",
  },
];
