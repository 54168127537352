import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import $ from "jquery";
import { Badge } from "rsuite";

import { connect } from "react-redux";
import { changeTopbarTheme } from "../../store/actions";

const Navbar = (props) => {
  const [brands, setbrands] = useState(false);
  const [facebook, setfacebook] = useState(false);
  const [dashboards, setdashboards] = useState(false);
  const [gateways, setGateways] = useState(false);
  const [tools, setTools] = useState(false);

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="topnav" id="topnav" style={{ padding: 0 }}>
        <div className="container-fluid">
          <div className="d-flex flex-row justify-content-between">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu flex-grow-1"
              id="navigation"
            >
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault();
                        setbrands(!brands);
                      }}
                    >
                      <i className="bx bx-collection me-2"></i>
                      Empresas <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname("dropdown-menu", {
                        show: brands,
                      })}
                      style={{ marginTop: 2 }}
                    >
                      <Link
                        to="/home"
                        className="dropdown-item"
                        key="t-brands-list"
                      >
                        Minhas Empresas
                      </Link>
                      <Link
                        to="/brands/create"
                        className="dropdown-item"
                        key="t-brands-register"
                      >
                        Nova Empresa
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault();
                        setfacebook(!facebook);
                      }}
                    >
                      <i className="mdi mdi-connection me-2"></i>
                      Conexões
                      <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname("dropdown-menu", { show: facebook })}
                    >
                      <Link
                        to="/connections"
                        className="dropdown-item"
                        key="t-facebook-list"
                      >
                        Contas de Anúncio
                      </Link>
                      <Link
                        to="/facebook/connect"
                        className="dropdown-item"
                        key="t-facebook-register"
                      >
                        Conectar Facebook Ads
                      </Link>
                      <Link
                        to="/googleads/connect"
                        className="dropdown-item"
                        key="t-google-ads-register"
                      >
                        Conectar Google Ads
                        <span className="badge bg-danger ms-1">beta</span>
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none d-flex align-items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        setGateways(!gateways);
                      }}
                    >
                      <i className="mdi mdi-cash-multiple me-2"></i>
                      Gateways de pagamento
                      <Badge
                        className="ms-2"
                        style={{ backgroundColor: "#00cc47" }}
                      />
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", { show: gateways })}
                    >
                      <Link
                        to="/gateways/select"
                        className="dropdown-item"
                        key="t-facebook-list"
                      >
                        Painel de vendas
                        <span className="badge bg-danger ms-2">beta</span>
                        <span className="badge bg-success ms-1">novo!</span>
                      </Link>

                      <Link
                        to="/gateways/connect"
                        className="dropdown-item"
                        key="t-google-ads-register"
                      >
                        Conectar Gateway de Pagamento
                        <span className="badge bg-success ms-1">novo!</span>
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none d-flex align-items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        setdashboards(!dashboards);
                      }}
                    >
                      <i className="bx bx-bar-chart-square me-2"></i>
                      Dashboards
                      <div className="arrow-down"></div>
                    </Link>

                    <div
                      className={classname("dropdown-menu", {
                        show: dashboards,
                      })}
                      style={{ marginTop: 2 }}
                    >
                      <Link
                        to="/dashboards"
                        className="dropdown-item dropdown-toggle"
                        key="t-dashboards-list"
                      >
                        Meus Dashboards
                      </Link>
                      <Link
                        to="/dashboards/create"
                        className="dropdown-item dropdown-toggle"
                        key="t-dashboards-register"
                      >
                        Novo Dashboard
                      </Link>

                      <Link
                        to="/layouts"
                        className="dropdown-item dropdown-toggle"
                        key="t-dashboards-templates"
                      >
                        Templates
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none d-flex align-items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        setTools(!tools);
                      }}
                    >
                      <i className="mdi mdi-tools me-2"></i>
                      MetriTools
                      <Badge
                        className="ms-2"
                        style={{ backgroundColor: "#00cc47" }}
                      />
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", { show: tools })}
                    >
                      <Link
                        to="/gateways/utm-generator"
                        className="dropdown-item"
                        key="t-google-ads-register"
                      >
                        Gerador de UTM Tags Metrito
                        <span className="badge bg-success ms-1">novo!</span>
                      </Link>

                      <Link
                        to="/tools/redirect-link-generator"
                        className="dropdown-item"
                        key="t-tools-list"
                      >
                        Gerador de link de redirecionamento
                        <span className="badge bg-success ms-1">novo!</span>
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item ">
                    <Link
                      className="nav-link d-flex align-items-center"
                      to="/enterprise/metrito-ai"
                    >
                      <i className="bx bx-bolt-circle me-2"></i>
                      <span>Metrito AI</span>
                      <span className="badge bg-danger ms-2">beta</span>
                      <Badge
                        className="ms-2"
                        style={{ backgroundColor: "#00cc47" }}
                      />
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/plans">
                      <i className="bx bx-rocket me-2"></i>
                      <span>Planos</span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </nav>

            {/* <div className="d-flex flex-column justify-content-center">
              <button className="btn btn-gold">
                <i className="bx bx-rocket me-2" />
                Fazer Upgrade
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (store) => ({
  leftMenu: store.Layout.leftMenu,
});

export default withRouter(connect(mapStatetoProps)(Navbar));
