import React, { useState } from "react";
import { Card, CardBody, Col, Label, Modal, Row, Table } from "reactstrap";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Toast
import { toast } from "react-toastify";

import { Placeholder } from "rsuite";

//services
import api from "../../services/api";

import { useParams } from "react-router";
import { CustomTooltip } from "../../components/Common/Tooltip.js";
import CustomAlert from "../../components/CustomAlert";

function DashboardsTable(props) {
  const { brandId } = useParams();

  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [dynamic_description, setdynamic_description] = useState("");
  const [selectedDashboard, setSelectedDashboard] = useState({});

  const [modal_center, setmodal_center] = useState(false);

  async function handleDelete(id) {
    const result = await api.delete("/api/dashboards/" + id);
    if (result.status === 200) {
      console.log("Dashboard deletado com sucesso");
    } else {
      console.log("Erro ao deletar Dashboard");
    }
  }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      {confirm_alert ? (
        <CustomAlert
          title="Tem certeza?"
          warning
          showCancel
          confirmButtonText="Sim, quero apagar!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          cancelBtnText="Cancelar"
          onConfirm={() => {
            handleDelete(selectedDashboard._id);
            setconfirm_alert(false);
            setsuccess_dlg(true);
            setdynamic_title("Dashboard Removido");
            setdynamic_description("Seu Dashboard foi deletado.");
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Seu Dashboard será apagado definitivamente!
        </CustomAlert>
      ) : null}
      {success_dlg ? (
        <CustomAlert
          success
          title={dynamic_title}
          confirmBtnStyle={{ color: "white" }}
          onConfirm={() => {
            setsuccess_dlg(false);
            window.location.reload();
          }}
        >
          {dynamic_description}
        </CustomAlert>
      ) : null}

      {/* Tabela Bootstrap */}
      <Card>
        <CardBody>
          <Row>
            <Col lg={8} md={6} sm={12} className="d-flex flex-row ps-3">
              <div className="me-3 pt-1">
                <i className="bx bxs-dashboard fs-1 text-dark" />
              </div>
              <div>
                <h4 className="card-title">Dashboards</h4>
                <p className="card-title-desc">Todos os Dashboards vinculados a esta empresa.</p>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <button
                type="button"
                className="btn btn-success waves-effect waves-light float-end w-100 text-truncate mb-3 m-sm-0"
                onClick={() => {
                  window.location.href = `/dashboards/create?brand=${brandId}`;
                }}
              >
                <i className="fas fa-plus text-white" style={{ marginRight: 10 }} />
                <span className="text-white">Novo Dashboard</span>
              </button>
            </Col>
          </Row>

          {props.loading ? (
            <Placeholder.Grid rows={5} columns={4} active />
          ) : (
            <div className="table-responsive border rounded">
              <Table className="table  table-hover mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Nome</th>
                    {/* <th>Descrição</th> */}
                    <th className="text-center">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {props.dashboards.map((dashboard) => (
                    <tr
                      style={{
                        verticalAlign: "middle",
                        height: 60,
                      }}
                      key={dashboard._id}
                      onClick={() => {
                        window.open("/report/v2/" + brandId + "/" + dashboard._id);
                      }}
                    >
                      <td className="text-truncate">{dashboard.name}</td>
                      {/* <td style={{ width: 10 }}>
                      <div
                        style={{
                          maxWidth: 320,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          lineClamp: 1,
                        }}
                      >
                        <span>{dashboard.description}</span>
                      </div>
                    </td> */}
                      <td>
                        <div className="d-flex justify-content-end">
                          {dashboard.version !== 3 ? (
                            <>
                              <span
                                className="badge px-3 py-1 text-truncate flex-shrink-1 text-dark"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "1px dashed #9599ad",
                                }}
                              >
                                Versão descontinuada
                              </span>
                              <span className="me-2">
                                <CustomTooltip
                                  onClick={(e) => e.preventDefault()}
                                  placement="topEnd"
                                  text="Essa versão de Dashboard foi descontinuada devido à nova atualização. Porém uma nova versão de Dashboards melhorados está disponível, crie um novo Dashboard e aproveite as novas visualizações dos widgets e conecte contas do Facebook e Google Ads simultaneamente! 🚀 🔥"
                                />
                              </span>
                            </>
                          ) : null}
                          {dashboard.version === 3 ? (
                            <i
                              role="button"
                              className="bx bxs-pencil fs-4 float-end i-btn mx-3 ms-3"
                              onClick={(event) => {
                                event.stopPropagation();
                                window.open("/builder/v2/" + dashboard._id);
                              }}
                            />
                          ) : null}
                          <i
                            role="button"
                            className="bx bx-link-external fs-4 float-end i-btn mx-3"
                            onClick={(event) => {
                              event.stopPropagation();
                              window.open("/report/v2/" + brandId + "/" + dashboard._id);
                            }}
                          />
                          <i
                            role="button"
                            className="bx bx-link-alt fs-4 float-end mx-3 i-btn"
                            data-toggle="modal"
                            data-target=".bs-example-modal-center"
                            onClick={(event) => {
                              event.stopPropagation();
                              setSelectedDashboard(dashboard);
                              tog_center();
                            }}
                          />
                          <i
                            role="button"
                            className="bx bxs-trash fs-4 float-end i-btn mx-3"
                            onClick={(event) => {
                              event.stopPropagation();
                              setSelectedDashboard(dashboard);
                              setconfirm_alert(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Link de Compartilhamento</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label for="example-text-input" className="form-label">
              Link
            </Label>
            <input
              className="form-control"
              type="text"
              defaultValue={"https://v1.metrito.com/report/v2/" + brandId + "/" + selectedDashboard._id}
              id="copy-input"
            />
          </div>

          <div className="d-flex">
            <div className="button-items">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={() => {
                  navigator.clipboard.writeText("https://v1.metrito.com/report/v2/" + brandId + "/" + selectedDashboard._id);
                  //Toast Texto Copiado
                  toast.success("Link Copiado!");
                  tog_center();
                }}
              >
                <i className="bx bx-copy me-2" style={{ fontSize: 15 }}></i>
                Copiar para Área de Transferência
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default DashboardsTable;
