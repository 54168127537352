export function setUser(user) {
  return {
    type: "SET_USER",
    payload: user,
  };
}

export function logoutUser() {
  return {
    type: "LOGOUT",
  };
}

export function setLoadingUser(isLoading) {
  return {
    type: "SET_LOADING_USER",
    payload: isLoading,
  };
}
