import React, { useState } from "react";
import { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Button, Input, Progress, Uploader, Loader } from "rsuite";

import Navbar from "../../components/HorizontalLayout/Navbar";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";
import AIHeader from "./Header";
import MetaTags from "react-meta-tags";

const likelihoods = [
  {
    key: "adult",
    value: "Adulto",
  },
  {
    key: "spoof",
    value: "Enganoso",
  },
  {
    key: "medical",
    value: "Médico",
  },
  {
    key: "violence",
    value: "Violência",
  },
  {
    key: "racy",
    value: "Provocativo",
  },
];

const likelihoods_ratings = [
  {
    key: "UNKNOWN",
    value: "Desconhecido",
    score: 0,
    progress_color: "#34C2FE",
  },
  {
    key: "VERY_UNLIKELY",
    value: "Muito improvável",
    score: 1,
    progress_color: "#4CAF4F",
  },
  {
    key: "UNLIKELY",
    value: "Improvável",
    score: 2,
    progress_color: "#34C2FE",
  },
  {
    key: "POSSIBLE",
    value: "Possível",
    score: 3,
    progress_color: "#FFC006",
  },
  {
    key: "LIKELY",
    value: "Provável",
    score: 4,
    progress_color: "#f26e09",
  },
  {
    key: "VERY_LIKELY",
    value: "Muito provável",
    score: 5,
    progress_color: "#F44335",
  },
];

const ImageVerification = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [safeSearchAnnotation, setSafeSearchAnnotation] = useState({});
  const [textAnnotations, setTextAnnotations] = useState({});
  const [loadingUser, setLoadingUser] = useState(false);
  const [user, setUser] = useState({});

  const onImageChange = (files) => {
    if (files.length > 0) {
      console.log(files[0].blobFile);
      setImage(files[0].blobFile);
    }
  };

  function verifyImage() {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", image);

    const safeSearchAnnotation = api
      .post("/api/v1/enterprise/cognitive/verify-image", formData)
      .then((res) => {
        console.log(res.data);
        setSafeSearchAnnotation(res.data.safe_search_annotation);
        setTextAnnotations(res.data.texts[0]);
        setLoading(false);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function loadUserData() {
    setLoadingUser(true);
    api
      .get("/api/usuarios.details/" + getIdUsuario())
      .then((response) => {
        setUser(response.data[0]);
        setLoadingUser(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingUser(false);
      });
  }

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Vision AI | Metrito</title>
      </MetaTags>

      <div
        className="w-100"
        style={{ height: 8, backgroundColor: "#0594FB" }}
      ></div>
      <div className="page-content ">
        <Container fluid>
          <AIHeader
            title="Vision"
            description=" Verifique imagens e criativos com recursos de detecção de
              conteúdos e padrões específicos que podem fazer com que o seu
              anúncio seja ou não reprovado."
            icon_url="https://cdn-icons-png.flaticon.com/512/4773/4773887.png"
            credits={user.ai_credits}
            loading={loadingUser}
          />
          <Row className="d-flex justify-content-center">
            <Col lg={4}>
              <Card>
                <CardBody>
                  {image ? (
                    <>
                      <div
                        style={{ border: "1px solid #ddd", borderRadius: 10 }}
                      >
                        <button
                          className="btn d-flex align-items-center justify-content-center text-dark card"
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: "50%",
                            position: "absolute",
                            top: 5,
                            right: 5,
                            fontSize: 18,
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                            zIndex: 2,
                          }}
                          onClick={() => {
                            setImage("");
                            setSafeSearchAnnotation({});
                            setTextAnnotations({});
                          }}
                        >
                          <i className="mdi mdi-close" />
                        </button>

                        <div style={{ position: "relative" }}>
                          {loading && (
                            <div
                              style={{
                                position: "absolute",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                zIndex: 1,
                                backgroundColor: "white",
                                opacity: 0.7,
                                borderRadius: 10,
                              }}
                              className="d-flex align-items-center justify-content-center"
                            >
                              <Loader size="lg" />
                            </div>
                          )}
                          <img
                            src={
                              !image
                                ? "https://cdn-icons-png.flaticon.com/512/4671/4671171.png"
                                : URL.createObjectURL(image)
                            }
                            alt="Imagem da empresa"
                            style={{
                              minWidth: "100%",
                              borderRadius: 10,
                              backgroundColor: "#fff",
                              maxWidth: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <Button
                          appearance="default"
                          className="mt-3 px-5 w-100 d-flex align-items-center justify-content-center mx-1"
                          loading={loading}
                          disabled={loading}
                          style={{
                            fontWeight: 600,
                            backgroundColor: "#00CC47",
                            color: "#fff",
                            fontSize: 16,
                            padding: 15,
                          }}
                          onClick={() => verifyImage()}
                        >
                          <i className="bx bx-bolt-circle me-2 fs-5"></i>
                          {loading
                            ? "Verificando..."
                            : Object.keys(safeSearchAnnotation).length ||
                              Object.keys(textAnnotations).length
                            ? "Repetir verificação"
                            : "Verificar imagem"}
                        </Button>
                        {Object.keys(safeSearchAnnotation).length ||
                        Object.keys(textAnnotations).length ? (
                          <Button
                            appearance="default"
                            className="mt-3 px-5 w-100 d-flex align-items-center justify-content-center mx-1 bg-primary"
                            loading={loading}
                            disabled={loading}
                            style={{
                              fontWeight: 600,
                              backgroundColor: "#00CC47",
                              color: "#fff",
                              fontSize: 16,
                              padding: 15,
                            }}
                            onClick={() => {
                              setImage("");
                              setSafeSearchAnnotation({});
                              setTextAnnotations({});
                            }}
                          >
                            <i className="bx bx-upload me-2 fs-5"></i>
                            Nova imagem
                          </Button>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <Uploader
                      accept="image/png, image/jpeg"
                      onChange={(file) => {
                        onImageChange(file);
                      }}
                      autoUpload={false}
                      action={window.location.origin}
                      draggable
                    >
                      <div
                        style={{
                          height: 200,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 10,
                          cursor: "pointer",
                        }}
                      >
                        <i className="mdi mdi-file-image-outline fs-4" />
                        <b>Clique ou arraste arquivos aqui</b>
                      </div>
                    </Uploader>
                  )}
                </CardBody>
              </Card>
            </Col>

            {Object.keys(safeSearchAnnotation).length > 0 && (
              <Col lg={4}>
                {/* {textAnnotations?.description && (
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div className="mb-4">
                          <h4 className="card-title">Text Annotations</h4>
                          <small className="text-muted ">
                            Língua:{" "}
                            {textAnnotations?.locale
                              ? textAnnotations.locale
                              : ""}
                          </small>
                        </div>

                        <Input
                          className="mt-1"
                          as="textarea"
                          rows={5}
                          value={textAnnotations.description}
                          style={{
                            minWidth: "100%",
                            borderRadius: 10,
                            padding: 20,
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        ></Input>
                      </CardBody>
                    </Card>
                  </Col>
                )} */}

                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">
                        Resultados do Vision Safe Search
                      </h4>

                      <div>
                        {safeSearchAnnotation &&
                          Object.keys(safeSearchAnnotation).length > 0 &&
                          Object.entries(safeSearchAnnotation).map((entry) => {
                            const likelihood = likelihoods.find(
                              (l) => l.key === entry[0]
                            );
                            const rating = likelihoods_ratings.find(
                              (l) => l.key === entry[1]
                            );

                            return (
                              <div
                                className="d-flex flex-column my-2 p-3"
                                style={{
                                  backgroundColor: "#F5F5F6",
                                  borderRadius: 10,
                                }}
                              >
                                <b className="mb-2">
                                  {likelihood.value}{" "}
                                  <small
                                    className="ms-2"
                                    style={{ color: rating.progress_color }}
                                  >
                                    {rating.value}
                                  </small>
                                </b>
                                <div className="d-flex flex-row">
                                  <small style={{ whiteSpace: "nowrap" }}>
                                    {likelihoods_ratings[1].value}
                                  </small>
                                  <Progress.Line
                                    className="mx-2 p-1"
                                    percent={Math.floor(100 / 5) * rating.score}
                                    showInfo={false}
                                    strokeColor={rating.progress_color}
                                  />
                                  <small style={{ whiteSpace: "nowrap" }}>
                                    {likelihoods_ratings[5].value}
                                  </small>
                                </div>
                              </div>
                            );
                          })}
                        <small className="text-muted">
                          Os valores de retorno possíveis são:{" "}
                          {likelihoods_ratings.map((l) => l.value).join(", ")}.
                          <br />
                          Quanto maior a probabilidade maiores as chances do seu
                          criativo ser reprovado.
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ImageVerification;
