import classnames from "classnames";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
//Import Breadcrumb
import { getImageURL } from "../../functions/getImageURL";

import { toast } from "react-toastify";
import { data_sources } from "../../common/data/data_sources";
import { checkBrandingPack } from "../../functions/checkBrandingPack";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";

const HomePage = () => {
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState({});

  const [brands, setBrands] = useState([]);
  const [activeBrands, setActiveBrands] = useState(brands);

  const [clients, setClients] = useState([]);
  const [products, setProducts] = useState([]);

  const [activeTab, setActiveTab] = useState("uncategorized");
  const toggle1 = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (user.branding_pack) {
      checkBrandingPack(user.branding_pack);
    }
  }, [activeTab]);

  function getUser() {
    setLoading(true);
    api
      .get("/api/usuarios.details/" + getIdUsuario())
      .then((response) => {
        setUser(response.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function generatePlaceholders() {
    var placeholders = [];
    for (var i = 0; i < 4; i++) {
      placeholders.push(
        <Col lg={3} md={6} sm={12} key={i}>
          <Card
            className="float-start"
            style={{
              width: "100%",
            }}
          >
            <CardBody>
              <h5 className="card-title placeholder-glow">
                <span
                  className="placeholder col-12 p-5 mb-3"
                  style={{ borderRadius: 5, height: 160 }}
                ></span>
              </h5>

              <h5 className="card-title placeholder-glow mb-4">
                <span className="placeholder col-6"></span>
              </h5>

              <h5 className="card-title placeholder-glow">
                <span
                  className="placeholder col-12"
                  style={{ borderRadius: 5, height: 40 }}
                ></span>
              </h5>
              <h5 className="card-title placeholder-glow">
                <span
                  className="placeholder col-12"
                  style={{ borderRadius: 5, height: 40 }}
                ></span>
              </h5>
            </CardBody>
          </Card>
        </Col>
      );
    }

    return placeholders;
  }

  //Faz o Fetch das Brands do Usuário
  async function getBrands() {
    setLoading(true);
    const response = await api
      .get("/api/v1/brands/")
      .then((res) => {
        if (res.data !== 0) {
          var brands = res.data
            .map((brand) => ({
              ...brand,
              ds_ocurrence:
                transformObjectToArray(
                  getDataSourceAndItsOcurrencesInArray(brand.ad_accounts)
                ) || [],
            }))
            .reverse();

          brands = brands.sort((a, b) =>
            a.ad_accounts.length < b.ad_accounts.length ? 1 : -1
          );

          setBrands(brands);
          setActiveBrands(brands);

          setTimeout(() => {
            setLoading(false);
          }, 200);
        } else {
          setBrands(res.data);

          toast.info("Você ainda não adicionou Empresas.");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Não foi possível carregar suas Empresas.");
        setLoading(false);
      });
  }

  function getDataSourceAndItsOcurrencesInArray(array) {
    var occurrences = {};
    for (var i = 0, j = array.length; i < j; i++) {
      occurrences[array[i].data_source] =
        (occurrences[array[i].data_source] || 0) + 1;
    }
    return occurrences;
  }

  function transformObjectToArray(object) {
    var array = [];
    for (var key in object) {
      array.push({ data_source: key, count: object[key] });
    }
    return array;
  }

  useEffect(() => {
    getUser();
    getBrands();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Início | Metrito</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={6}>
              {loading ? (
                <Row className="d-flex flex-row align-items-center">
                  <Col lg={1}>
                    <p className="card-text placeholder-glow ">
                      <span
                        className="placeholder"
                        style={{ width: 75, height: 75, borderRadius: 40 }}
                      ></span>
                    </p>
                  </Col>
                  <Col lg={11}>
                    <p className="card-text placeholder-glow ms-4">
                      <span className="placeholder col-8 py-2"></span>
                      <span className="placeholder col-6 py-1 mt-3"></span>
                    </p>
                  </Col>
                </Row>
              ) : (
                <div className="d-flex flex-row">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minWidth: 80 }}
                  >
                    <div className="profile-pic-box">
                      <img
                        src={getImageURL(user.profile_pic)}
                        alt=""
                        className="avatar-md img-thumbnail rounded-circle"
                        style={{ objectFit: "cover" }}
                      />
                      {user.beta === true ? (
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/3314/3314467.png"
                          alt="Parabéns, você é um usuário BETA!"
                          style={{
                            width: 25,
                            position: "absolute",
                            bottom: 0,
                            left: 60,
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-center ms-2">
                    <h3 className="mb-1">{`Bem-vindo(a) de volta, ${
                      user.name?.split(" ")[0] || ""
                    }!`}</h3>
                    <span className="text-muted">
                      Ficamos muito felizes de te ter por aqui!
                    </span>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <hr className="divider mt-5 mb-3" />
          <Row>
            <Col lg={12}>
              <div className="d-flex justify-content-between align-items-center">
                <Nav pills>
                  <NavItem className="waves-effect waves-light">
                    <NavLink
                      className={classnames({
                        active: activeTab === "uncategorized",
                      })}
                      onClick={() => {
                        toggle1("uncategorized");
                        setActiveBrands(brands);
                      }}
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <span>Todas</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="waves-effect waves-light">
                    <NavLink
                      className={classnames({
                        active: activeTab === "clients",
                      })}
                      onClick={() => {
                        toggle1("clients");
                        setActiveBrands(
                          brands.filter(
                            (brand) => brand.category === "client"
                          ) || []
                        );
                      }}
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <span>
                        <i className="mdi mdi-account-group-outline me-2" />
                        Clientes
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="waves-effect waves-light">
                    <NavLink
                      className={classnames({
                        active: activeTab === "products",
                      })}
                      onClick={() => {
                        toggle1("products");
                        setActiveBrands(
                          brands.filter(
                            (brand) => brand.category === "product"
                          ) || []
                        );
                      }}
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <span>
                        <i className="mdi mdi-cube-outline me-2" />
                        Produtos
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <Link
                  to={"/brands/create"}
                  className="btn btn-success px-5 d-none d-md-block"
                >
                  <i className="fas fa-plus text-white me-2" />
                  Adicionar
                </Link>
              </div>
            </Col>
          </Row>

          {loading ? (
            <Row className="mt-4">{generatePlaceholders()}</Row>
          ) : !loading && user && brands.length === 0 ? (
            <div className="d-flex flex-row my-5" style={{ maxWidth: 800 }}>
              <img
                src="https://cdn-icons-png.flaticon.com/512/7486/7486760.png"
                alt="Nenhum template encontrado"
                style={{ maxWidth: 180 }}
                className="m-2"
              />
              <div className="d-flex flex-column justify-content-center">
                <h5 className="text-dark text-start mx-3">
                  Como assim? Você não adicionou nenhuma empresa?
                </h5>
                <span className="align-middle fs-6 mx-3 text-muted">
                  Clique no botão abaixo e adicione uma empresa! É bem simples e
                  ela pode ser classificada como um{" "}
                  <b>Cliente da sua agência</b> ou um <b>Produto</b>. 😄
                </span>
                <div>
                  <Link to="/brands/create" className="btn btn-success m-3">
                    Adicionar Produto/Cliente
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <Row className="mt-4">
              {activeBrands.map((brand) => (
                <Col xl={3} lg={4} md={6}>
                  {/* <div
                    className="p-2 mx-3 d-flex flex-row justify-content-around text-dark"
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      backgroundColor: "#D4D5DC",
                      position: "absolute",
                      top: -35,
                      width: "80%",
                      zIndex: 1,
                      boxShadow: "0px 0px 5px #00000012",
                    }}
                  >
                    <span>Editar</span>
                    <span>Excluir</span>
                  </div> */}
                  <Link to={`/brands/${brand._id}`}>
                    <Card className="brand-card">
                      <CardBody>
                        <img
                          src={getImageURL(
                            brand.logo || "brand_placeholder.png"
                          )}
                          alt="image"
                          className="w-100"
                          style={{
                            borderRadius: 10,
                            backgroundColor: "#fff",
                            height: 160,
                            objectFit: "contain",
                          }}
                        />
                        <div className="d-flex flex-row align-items-center my-3 m-2">
                          <h4 className="mb-0 me-2">{brand.name}</h4>
                          {brand.category === "product" ? (
                            <i className="mdi mdi-cube-outline text-muted" />
                          ) : brand.category === "client" ? (
                            <i className="bx bx-building text-muted" />
                          ) : null}
                        </div>

                        <Row
                          id="brand-item-row"
                          className="card my-2 mx-0 shadow-sm d-flex flex-row align-items-center py-2"
                          style={{ border: "none" }}
                        >
                          <Col
                            lg={10}
                            className="d-flex flex-row justify-content-between w-100"
                          >
                            <div className="d-flex align-items-center ms-2 text-truncate">
                              <i className="mdi mdi-connection text-muted fs-5 me-2" />
                              <h6 className="text-muted mb-0 text-truncate">
                                Integrações
                              </h6>
                            </div>

                            <div className="d-flex flex-row">
                              {brand.ad_accounts.length > 0 ? (
                                <>
                                  {brand.ds_ocurrence
                                    .sort((a, b) =>
                                      a.data_source.localeCompare(b.data_source)
                                    )
                                    .map((data_source) => {
                                      const this_ds = data_sources.find(
                                        (ds) =>
                                          ds.code === data_source.data_source
                                      );
                                      return (
                                        <div
                                          className="d-flex flex-row align-items-center px-2 ms-1"
                                          style={{
                                            borderRadius: 5,
                                            backgroundColor: this_ds.color,
                                          }}
                                        >
                                          <i
                                            className={`${this_ds.icon} text-white fs-4`}
                                          />
                                          <h5
                                            className="mb-0 ms-1 text-white"
                                            style={{ marginTop: 2 }}
                                          >
                                            {data_source.count}
                                          </h5>
                                        </div>
                                      );
                                    })}
                                </>
                              ) : (
                                <div
                                  className="d-flex flex-row align-items-center px-2 ms-1 bg-light"
                                  style={{
                                    borderRadius: 5,
                                  }}
                                >
                                  <i
                                    className={`mdi mdi-plus text-dark fs-4`}
                                  />
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row
                          id="brand-item-row"
                          className="card my-1 mx-0 shadow-sm d-flex flex-row align-items-center py-2"
                          style={{ border: "none" }}
                        >
                          <Col
                            lg={10}
                            className="d-flex flex-row justify-content-between w-100"
                          >
                            <div className="d-flex align-items-center ms-2">
                              <i className="bx bxs-dashboard text-muted fs-5 me-2" />
                              <h6 className="text-muted mb-0">Dashboards</h6>
                            </div>
                            <div
                              className="d-flex flex-row align-items-center p-1 px-2"
                              style={{
                                borderRadius: 5,
                                backgroundColor: "#272c36",
                              }}
                            >
                              <i className="bx bxs-dashboard text-white fs-4" />
                              <h5
                                className="mb-0 ms-1 text-white"
                                style={{ marginTop: 2 }}
                              >
                                {brand.dashboards.length}
                              </h5>
                            </div>
                          </Col>
                          <Col lg={2}></Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default HomePage;
