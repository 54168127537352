import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { IntegrationHeader } from "./IntegrationHeader";
import { connect } from "react-redux";
import { fbSetMetrics, fbSetCampaigns } from "../../store/facebook/actions";
import { changeLayoutMode, changeTopbarTheme } from "../../store/actions";
import { setDashboard } from "../../store/dashboard/actions";
import { metrics, summary, campaigns } from "../../common/data/facebook";
import { AvForm, AvField } from "availity-reactstrap-validation";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import { CirclePicker, TwitterPicker } from "react-color";
import { SelectPicker, Loader } from "rsuite";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Collapse,
  CardBody,
  Label,
  Card,
  FormGroup,
  Alert,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownToggle,
} from "reactstrap";
import Select from "react-select";
import { metricsArray, cardsMetrics } from "../../common/data/metrics";
import { data_sources } from "../../common/data/data_sources";
import { Link } from "react-router-dom";

import classnames from "classnames";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./overriden-placeholder.css";

import shortid from "shortid";
import QRCode from "react-qr-code";

import $, { data } from "jquery";

import CustomInput from "./CustomInput";
import InsightsComponent from "../../components/InsightsComponent";
import SaveAsTemplate from "./SaveAsTemplate";
import api from "../../services/api";
import { MetaTags } from "react-meta-tags";
import { defineComponent } from "../../functions/defineComponent";
import { toast } from "react-toastify";
import { element } from "prop-types";
import Helmet from "react-helmet";
import jsPDF from "jspdf";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { getIdUsuario } from "../../services/auth";
import { WhiteLoader } from "./WhiteLoader";
import DashboardRenderer from "../Dashboard/DashboardRenderer";
import { useMemo } from "react";
import classNames from "classnames";
import { widgets_list } from "../../common/data/widgets_list";
import { CustomTooltip } from "../../components/Common/Tooltip";
import TextEditor from "../../widgets/TextEditor";
import logoLight from "../../assets/images/logo-light.png";
import logoSmLight from "../../assets/images/logo-sm-light.png";
import logoSm from "../../assets/images/logo-sm.svg";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const scrollToBottom = () => {
  const scrollHeight = document.documentElement.scrollHeight;
  const height = document.documentElement.clientHeight;
  const maxScrollTop = scrollHeight - height;
  window.scrollTo({
    top: maxScrollTop > 0 ? maxScrollTop : 0,
    behavior: "smooth",
  });
};

const Builder = (props) => {
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [isLoadingInsights, setLoadingInsights] = useState(false);
  const [isLoadingDashboardDefaultData, setLoadingDashboardDefaultData] =
    useState(true);

  //Widgets title and description
  const [dashboard, setDashboard] = useState({});
  const [dashboardTitle, setDashboardTitle] = useState("");
  const [dashboardBrandId, setDashboardBrandId] = useState("");

  const [isEditingDashboardTitle, setIsEditingDashboardTitle] = useState(false);
  const [customActiveTab, setCustomActiveTab] = useState(2);
  const [isWidgetDetailsOpen, setIsWidgetDetailsOpen] = useState(false);

  const [widget, setWidget] = useState({});
  const [isUpdatingWidget, setIsUpdatingWidget] = useState(false);
  const [nowUpdatingWidgetId, setNowUpdatingWidgetId] = useState(null);
  const [customConvSubmetric, setCustomConvSubmetric] = useState("conversions");

  const [layouts, setLayouts] = useState([]);
  const [widgetArray, setWidgetArray] = useState([]);
  const [loadingCustomMetrics, setLoadingCustomMetrics] = useState(false);
  const [allMetricsList, setAllMetricsList] = useState(metricsArray);
  const debouncedDashboardTitle = useDebounce(dashboardTitle, 1000);
  const debouncedWidgetArray = useDebounce(layouts, 1000);
  const { dashboardId } = useParams();

  const print_ref = useRef(null);
  const metricPickerRef = useRef(null);

  const handleDownload = () => {
    document.querySelector("*").style.pointerEvents = "none";
    html2canvas(print_ref.current).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");

      /*
      Here are the numbers (paper width and height) that I found to work. 
      It still creates a little overlap part between the pages, but good enough for me.
      if you can find an official number from jsPDF, use them.
      */
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("metrito" + ".pdf");
      document.querySelector("*").style.pointerEvents = "auto";
      setIsDownloadingPDF(false);
    });
  };

  const loadDashboardDefaultData = () => {
    console.log("Carregando dados do dashboard...");
    api
      .post("/api/dashboards/details/" + dashboardId, {
        owner_id: getIdUsuario(),
      })
      .then((res) => {
        props.setDashboard(res.data);
        setDashboard(res.data);
        setDashboardTitle(res.data.name);
        setLayouts(res.data.layout);
        setWidgetArray(res.data.layout);
        // console.log(res.data.layout);
        setDashboardBrandId(res.data.brandId);
        setLoadingDashboardDefaultData(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingDashboardDefaultData(false);
      });
  };

  useEffect(() => {
    loadDashboardDefaultData();

    $("html").css("overflow", "visible");
    $("#main-content").css("overflow", "visible");
    $("body").css("overflow", "visible");

    document.getElementById("topnav").style.display = "none";
    document.getElementById("footer").style.display = "none";
    document.getElementById("navbar-cog-icon").style.opacity = 0;

    props.changeLayoutMode("light");
    props.changeTopbarTheme("dark");

    // props.populateFB(metrics, summary);
    // props.populateCampaigns(campaigns);
  }, []);

  function checkTitle() {
    if (widget.hasMetric === true) {
      return widget.metric;
    } else if (widget.id === 4) {
      return "Análise";
    } else if (widget.id === 10) {
      return widget.title;
    } else {
      return "";
    }
  }

  const handleModify = (l, layout) => {
    setIsSaved(false);

    const tempArray = l;

    tempArray.map((position) => {
      var tempObject = widgetArray.find((o) => o.i === position.i);
      position.metric = tempObject.metric;
      position.title = tempObject.title;
      position.lines = tempObject.lines;
      position.description = tempObject.description;
      position.content = tempObject.content;
    });

    setLayouts(tempArray);
  };

  const handleAdd = (object) => {
    const max = Math.max(...layouts.map((o) => o.y));

    const modified_array = [
      ...widgetArray,
      {
        i: `${object.id}-${shortid.generate()}`,
        x: 0,
        y: max == -Infinity ? 0 : max,
        w: object.w,
        h: object.h,
        minW: object.minW,
        minH: object.minH,
        maxH: object.maxH,
        metric: object.metric?.includes("custom_conversions")
          ? object.metric + "." + customConvSubmetric
          : object.metric,
        title: object.title || "",
        lines: object.lines || [],
        description: object.description || "",
        content: object.content || null,
        custom_metrics: object.custom_metrics || [],
      },
    ];
    setWidgetArray(modified_array);
    console.log(modified_array);
  };

  const handleDelete = (key) => {
    setIsSaved(false);
    setIsWidgetDetailsOpen(false);
    setIsUpdatingWidget(false);
    setNowUpdatingWidgetId(null);
    $("#metrics_selection").fadeOut(100);

    if (key == nowUpdatingWidgetId) {
      setIsUpdatingWidget(false);
    }

    const tempArray = widgetArray.slice();
    const index = tempArray.indexOf(tempArray.find((data) => data.i === key));
    tempArray.splice(index, 1);
    setLayouts(tempArray);
    setWidgetArray(tempArray);
  };

  const handleEditWidget = async (widget, metric) => {
    const widget_metric = widget.metric?.includes("custom_conversions")
      ? widget.metric.split(".").slice(0, -1).join(".")
      : widget.metric;
    const widget_subumetric = widget.metric?.includes("custom_conversions")
      ? widget.metric.slice(
          widget.metric.lastIndexOf(".") + 1,
          widget.metric.length
        )
      : null;

    console.log(widget_metric, widget_subumetric, allMetricsList);
    console.log({
      ...widgets_list.find(
        (component) => component.id == parseInt(widget.i.split("-")[0])
      ),
      ...widget,
      metric: widget_metric,
    });

    setCustomActiveTab(2);
    setIsUpdatingWidget(true);
    setNowUpdatingWidgetId(widget.i);
    setWidget({
      ...widgets_list.find(
        (component) => component.id == parseInt(widget.i.split("-")[0])
      ),
      ...widget,
      metric: widget_metric,
    });

    if (widget_metric?.includes("custom_conversions")) {
      setCustomConvSubmetric(widget_subumetric);
    }

    var allMetrics = metricsArray;
    $("#metrics_selection").fadeIn(100);
  };

  const updateWidget = (options) => {
    setIsSaved(false);

    var tempArray = [...widgetArray];
    //Find index of specific object using findIndex method.
    var objIndex = tempArray.findIndex((obj) => obj.i == nowUpdatingWidgetId);

    // // Log object to Console.
    // console.log("Before update: ", tempArray[objIndex]);

    //Update object's name property.
    tempArray[objIndex].title = widget.title;
    tempArray[objIndex].metric = widget.metric?.includes("custom_conversions")
      ? widget.metric + "." + customConvSubmetric
      : widget.metric;
    tempArray[objIndex].lines = widget.lines;
    tempArray[objIndex].description = widget.description;
    tempArray[objIndex].content = widget.content;
    tempArray[objIndex].custom_metrics = widget.custom_metrics;

    // // Log object to console again.
    // console.log("After update: ", tempArray[objIndex]);

    setLayouts(tempArray);
    setWidgetArray(tempArray);
  };

  const handleDuplicateWidget = (widget) => {
    var newWidget = { ...widget };
    var max = Math.max(...layouts.map((o) => o.y));

    console.log(widget);

    newWidget.i = `${newWidget.i.split("-")[0]}-${shortid.generate()}`;
    newWidget.x = 0;
    newWidget.y = max;

    // console.log(widget, newWidget);

    setWidgetArray([...widgetArray, newWidget]);
  };

  //Metric selection changes
  function handleChange(e) {
    // setMetricOption(e);
  }

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) setCustomActiveTab(tab);
  };

  const capture = (captureId, name) => {
    const captureElement = document.querySelector("#" + captureId);
    html2canvas(captureElement)
      .then((canvas) => {
        canvas.style.display = "none";
        document.body.appendChild(canvas);
        return canvas;
      })
      .then((canvas) => {
        const image = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        const a = document.createElement("a");
        a.setAttribute("download", `${name}.png`);
        a.setAttribute("href", image);
        a.click();
        canvas.remove();
      });
  };

  const exportToPDF = async () => {
    const input = document.getElementById("a4");

    console.log(input);

    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF("p", "mm", "a4");
    //   var width = pdf.internal.pageSize.getWidth();
    //   var height = pdf.internal.pageSize.getHeight();
    //   pdf.addImage(imgData, "JPG", 0, 0, width, height);
    //   // pdf.output('dataurlnewwindow');
    //   pdf.save("download.pdf");
    // });
  };

  const exportToJSON = (exportObj, exportName) => {
    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const getAllMetrics = async () => {
    setLoadingCustomMetrics(true);
    const response = await api
      .get(`/api/v2/custom-conversions/${dashboardBrandId}`)
      .then((response) => {
        const custom_conversions_metrics = response.data.map((metric) => {
          return {
            label: metric.name,
            description:
              metric.description == null
                ? "No description available"
                : metric.description,
            value: `custom_conversions.${metric.data_source}.${metric.account_id}.${metric.id}`,
            display_type: "NUMBER",
            merge_operation: "SUM",
            better_when: "HIGH",
            data_sources: [
              {
                code: metric.data_source,
                metric: `custom_conversions.${metric.data_source}.${metric.account_id}.${metric.id}`,
              },
            ],
            isEnabled: true,
            role: "Conversões Personalizadas",
          };
        });

        setAllMetricsList([...metricsArray, ...custom_conversions_metrics]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingCustomMetrics(false);
      });
  };

  // useDebounce Hook
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  // Effect for API call
  useEffect(
    () => {
      if (window.innerWidth >= 996 && !isLoadingDashboardDefaultData) {
        const data = {
          name: debouncedDashboardTitle,
          layout: debouncedWidgetArray,
        };
        api
          .put(`/api/dashboards/${dashboardId}`, data)
          .then((res) => {
            console.log("✓ Dashboard salvo!");
            // console.log(res.data);
            setIsSaved(true);
          })
          .catch((err) => {
            console.log(err);
            setIsSaved("error");
          });
      }
    },
    [debouncedWidgetArray, debouncedDashboardTitle] // Only call effect if debounced search term changes
  );

  $("#metric-selector").mouseup(function (e) {
    var container = $("#metric-selector");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.hide();
    }
  });

  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  const mainWidgetsArea = useMemo(
    () => (
      <ResponsiveReactGridLayout
        layout={layouts}
        onLayoutChange={handleModify}
        breakpoints={{
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        }}
        preventCollision={false}
        cols={{ lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 }}
        autoSize={true}
        margin={{
          lg: [20, 20],
          md: [20, 20],
          sm: [20, 20],
          xs: [20, 20],
          xxs: [20, 20],
        }}
        compactType="vertical"
      >
        {widgetArray?.map((widget, index) => {
          const comp = defineComponent(
            { ...props, selectedAdAccounts, dashboard },
            {
              ...widget,
              metric: !widget.metric?.includes("custom_conversion")
                ? widget.metric?.toLowerCase()
                : widget.metric,
            }
          );
          const el = {
            x: widget.x,
            y: widget.y,
            w: widget.w,
            h: widget.h,
            i: widget.i.split("-")[0],
            minW: 2,
            maxW: Infinity,
            minH: widget.minH || Infinity,
            maxH: widget.maxH || Infinity,
            static: false,
          };
          return (
            <div
              id={`widget-${widget.i.split("-")[1]}`}
              className="card widget"
              key={widget.i}
              data-grid={el}
            >
              <div className="widget-controls justify-content-between px-3 align-items-center fs-5">
                <i
                  role="button"
                  className="bx bx-cog text-white i-btn"
                  onClick={() => {
                    handleEditWidget(widget, widget.metric);
                  }}
                />
                <i
                  role="button"
                  className="bx bx-duplicate text-white i-btn"
                  onClick={() => {
                    handleDuplicateWidget(widgetArray[index], widget.metric);
                  }}
                />
                <i
                  role="button"
                  className="bx bx-cloud-download text-white i-btn"
                  onClick={() => {
                    capture(
                      `widget-${widget.i.split("-")[1]}`,
                      `metrito-${widget.title.replace(" ", "-").toLowerCase()}`
                    );
                  }}
                />
                <i
                  role="button"
                  className="bx bx-trash-alt text-white i-btn"
                  onClick={() => {
                    handleDelete(widget.i);
                  }}
                />
              </div>

              <div
                style={
                  widget.i.split("-")[0] == 10
                    ? { overflowY: "scroll" }
                    : widget.i.split("-")[0] == 3
                    ? { overflow: "hidden", paddingBottom: 30 }
                    : { overflow: "hidden", paddingBottom: 0 }
                }
              >
                {comp}
              </div>
            </div>
          );
        })}
      </ResponsiveReactGridLayout>
    ),
    [widgetArray, allMetricsList, props]
  );

  const handlePrint = () => {
    const print_window = window.open("", "", "height=400,width=800");
    print_window.document.write(`
      <html>
        <head>
          ${document.getElementsByTagName("head")[0].innerHTML}
        </head>
        <body>
          ${document.getElementById("print-div").innerHTML}
        </body>
      </html>
    `);

    print_window.document.close();
    print_window.focus();
    print_window.print();
    print_window.close();
  };

  useEffect(() => {
    console.log("selectedAdAccounts", selectedAdAccounts.length);

    if (selectedAdAccounts.length > 0) {
      console.log("Fetching metrics...");
      getAllMetrics();
    }
  }, [selectedAdAccounts]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up after the effect:
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <WhiteLoader isVisible={isLoadingInsights} />
      <MetaTags>
        <title>{`Builder - ${dashboardTitle}`}</title>
      </MetaTags>
      <Helmet></Helmet>

      <div className="topbar-container">
        <div id="builder-topbar" className="builder-topbar">
          <div
            id="dashboard-name"
            className="d-flex align-items-center"
            style={{ display: "inline", height: 50 }}
          >
            <Link to="/dashboards">
              <button className="btn btn-transparent">
                <i className="bx bx-left-arrow-alt text-muted fs-2 ms-3 mt-1"></i>
              </button>
            </Link>
            {isEditingDashboardTitle ? (
              <input
                className="form-control"
                type="text"
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  padding: 3,
                  paddingLeft: 7,
                  border: "1px dashed #bebebe",
                  width: "auto",
                }}
                value={dashboardTitle}
                onChange={(e) => {
                  setIsSaved(false);
                  setDashboardTitle(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key == "Enter" && e.target.value.length >= 1) {
                    setIsEditingDashboardTitle(false);
                  }
                }}
              />
            ) : (
              <h5
                className="my-0 ms-2 text-gray"
                onDoubleClick={() => setIsEditingDashboardTitle(true)}
              >
                {dashboardTitle != "" ? (
                  <span className="text-truncate w-100">{dashboardTitle}</span>
                ) : (
                  <i className="bx bx-loader bx-spin" />
                )}
              </h5>
            )}
            {!isEditingDashboardTitle ? (
              <i
                role="button"
                className="bx bxs-pencil text-muted ms-3 i-btn"
                style={{ fontSize: 13 }}
                onClick={() => setIsEditingDashboardTitle(true)}
              ></i>
            ) : (
              <button
                className="btn btn-outline-transparent text-success p-1 ms-2"
                onClick={() => {
                  if (dashboardTitle.length >= 1) {
                    setIsEditingDashboardTitle(false);
                  }
                }}
              >
                Salvar
              </button>
            )}
          </div>
          <div id="dashboard-controls" className="d-flex align-items-center">
            {loadingCustomMetrics ? (
              <div className="d-flex align-items-center">
                <i className="bx bx-loader bx-spin text-muted me-2" />
                <small className="text-muted">
                  Carregando métricas personalizadas...
                </small>
              </div>
            ) : null}
            <div className="d-none d-md-block">
              {isSaved === "error" ? (
                <span
                  style={{
                    fontSize: 13,
                    color: "red",
                    fontWeight: 600,
                    marginRight: 10,
                    transition: "all 500ms",
                  }}
                >
                  <i className="bx bx-error-circle fs-5 align-middle me-1 mb-1" />
                  Erro ao salvar
                </span>
              ) : isSaved ? (
                <span
                  style={{
                    fontSize: 13,
                    color: "#01c157",
                    fontWeight: 600,
                    marginRight: 10,
                    transition: "all 500ms",
                  }}
                >
                  <i className="bx bx-check fs-5 align-middle me-1 mb-1" />{" "}
                  Salvo
                </span>
              ) : (
                <span
                  className="text-muted"
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    marginRight: 10,
                    transition: "all 500ms",
                  }}
                >
                  <i className="bx bx-loader bx-spin fs-6 align-middle me-1 mb-1 text-muted" />{" "}
                  Salvando
                </span>
              )}
            </div>
            <button
              className="btn btn-light text-muted border border-muted mx-2 d-none d-md-block"
              style={{ maxHeight: 38 }}
              disabled={!isSaved}
              onClick={() =>
                window.open(
                  `${window.location.origin}/report/v2/${dashboardBrandId}/${dashboardId}`
                )
              }
            >
              <i className="mdi mdi-eye fs-6 align-middle mx-2 fs-5"></i>
              <span className="d-none d-xl-inline">Visualizar</span>
            </button>
            <SaveAsTemplate isSaved={isSaved} layout={layouts} />
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn btn-light mx-2 d-none d-md-block"
                type="button"
                disabled={!isSaved}
              >
                <span className="text-muted">
                  <i className="bx bxs-cloud-download align-middle mx-2 fs-5" />
                  <span className="d-none d-lg-inline">Exportar</span>
                </span>
                <i className="mdi mdi-chevron-down ms-1 text-muted" />
              </DropdownToggle>
              <DropdownMenu className="mt-2">
                <DropdownItem
                  className="text-muted"
                  onClick={() => {
                    setIsDownloadingPDF(true);
                    setTimeout(() => {
                      handleDownload();
                    }, 100);
                  }}
                >
                  <i className="bx bxs-file-pdf text-danger fs-5 align-middle me-1" />
                  Exportar para PDF
                </DropdownItem>

                {/* <DropdownItem
                  className="text-muted"
                  onClick={() => {
                    const data = {
                      template_owner: getIdUsuario(),
                      layout: layouts,
                      date_exported: new Date(),
                    };
                    exportToJSON(
                      data,
                      `metrito-${dashboardTitle.replace(" ", "-")}`
                    );
                  }}
                >
                  <i className="bx bxs-file-json text-info fs-5 align-middle me-1" />
                  Exportar template Metrito (.json)
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown>
              <DropdownToggle
                className="btn btn-light mx-2 me-4"
                type="button"
                disabled={!isSaved}
              >
                <span className=" text-muted">
                  <i className="bx bxs-share bx-flip-horizontal align-middle mx-2 fs-5" />
                  <span className="d-none d-lg-inline">Compartilhar</span>
                </span>
                <i className="mdi mdi-chevron-down ms-1 text-muted" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end mt-2">
                <div className="p-3 text-center" style={{ minWidth: 400 }}>
                  <i className="bx bx-link text-success fs-1 align-middle m-2" />
                  <h6>Link de Compartilhamento</h6>
                  <p className="text-muted">
                    Qualquer pessoa que tiver o link poderá ver o conteúdo desse
                    Dashboard. Utilize o para compartilhar os dados com clientes
                    e colaboradores!
                  </p>

                  <Label className="mt-4">
                    Escaneie para ver no seu celular
                  </Label>
                  <QRCode
                    value={`${window.location.origin}/report/v2/${dashboardBrandId}/${dashboardId}`}
                    className="mb-5 mt-1"
                    size={200}
                  />

                  <input
                    id="share-link"
                    className="form-control"
                    type="text"
                    readOnly
                    defaultValue={`${window.location.origin}/report/v2/${dashboardBrandId}/${dashboardId}`}
                  />
                  <button
                    className="btn btn-success w-100 mt-2"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        document.getElementById("share-link").value
                      );
                      toast.success("Link copiado!");
                    }}
                  >
                    <i className="mdi mdi-clipboard-outline me-2" />
                    Copiar Link!
                  </button>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>

            {isDownloadingPDF && (
              <button
                className="btn btn-success d-flex align-items-center me-4"
                disabled
              >
                <Loader inverse className="me-2" />
                Exportando documento...
              </button>
            )}
          </div>
        </div>
      </div>

      <div id="wrapper" className="d-flex justify-content-between">
        <div
          id="builder-sidebar"
          className="d-none d-lg-block"
          style={{
            backgroundColor: "white",
            width: 300,
            top: 110,
            bottom: 0,
            float: "right",
            borderRight: "1px solid #e5e5e5",
            zIndex: 0,
            position: "sticky",
            height: "90vh",
          }}
        >
          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", padding: 15 }}
                className={classnames({
                  active: customActiveTab === 1,
                })}
                onClick={() => {
                  toggleCustom(1);
                }}
              >
                <i className="bx bx-cog fs-5" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", padding: 15 }}
                className={classnames({
                  active: customActiveTab === 2,
                })}
                onClick={() => {
                  toggleCustom(2);
                }}
              >
                <i className="bx bxs-widget fs-5" />
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            id="tab-content"
            activeTab={customActiveTab}
            style={{
              top: 163,
              bottom: 0,
              overflowY: "scroll",
              height: "100%",
              width: 300,
              overflowX: "visible",
            }}
          >
            <TabPane id="about" role="tabpanel" tabId={1}>
              <h5 className="font-size-16 text-primary text-start m-3 ms-4">
                Configurações
              </h5>

              <div className="px-4">
                <AvForm className="needs-validation">
                  <FormGroup className="mb-3  w-100">
                    <Label
                      htmlFor="validationCustom01"
                      className="text-muted mb-1"
                      style={{ fontSize: 13 }}
                    >
                      Título do Dashboard
                    </Label>
                    <AvField
                      name="firstname"
                      placeholder={dashboardTitle}
                      value={dashboardTitle}
                      onChange={(event) => {
                        setIsSaved(false);
                        setDashboardTitle(event.target.value);
                      }}
                      type="text"
                      errorMessage="Insira um nome para o dashboard"
                      className="form-control  w-100"
                      validate={{ required: { value: true } }}
                      id="validationCustom01"
                    />
                  </FormGroup>
                  {/* <div
                    className="form-check form-switch form-switch-md m-1"
                    dir="ltr" 
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="SwitchCheckSizemd-1"
                      defaultChecked
                      onChange={(e) => toggleCompact()}
                    />
                    <Label
                      className="form-check-label"
                      for="SwitchCheckSizemd-1"
                    >
                      Compactação
                    </Label>
                  </div> */}
                </AvForm>

                <hr className="divider" />

                {/* <IntegrationHeader
                  platform="Facebook"
                  icon="bx bxl-facebook-circle"
                  color="#3267ED"
                /> */}
                {/* 
                <CustomInput
                  dashboard={dashboard}
                  setLoadingInsights={setLoadingInsights}
                  dashboardBrandId={dashboardBrandId}
                  selectedAdAccounts={selectedAdAccounts}
                  setSelectedAdAccounts={setSelectedAdAccounts}
                  selectedCampaigns={selectedCampaigns}
                  setSelectedCampaigns={setSelectedCampaigns}
                /> */}
              </div>
            </TabPane>
            <TabPane id="plan" role="tabpanel" tabId={2}>
              <h5 className="font-size-16 m-3 ms-4 text-primary text-start">
                Widgets
              </h5>

              <div
                className="d-flex flex-row flex-wrap overflow-hidden justify-content-center "
                style={{
                  backgroundColor: "white",
                  width: 300,
                  borderRight: "1px solid #e5e5e5",
                }}
              >
                {widgets_list.map((c) => (
                  <div
                    className="widget-option p-4"
                    role="button"
                    key={c.id}
                    onClick={() => {
                      setWidget(c);
                      $("#metrics_selection").fadeIn(100);
                      setIsWidgetDetailsOpen(true);
                    }}
                  >
                    <i
                      style={{ fontSize: 15, fontSize: 20 }}
                      className={c.icon}
                    ></i>
                    <span className="text-dark" style={{ fontSize: 13 }}>
                      {c.label}
                    </span>
                  </div>
                ))}
              </div>

              <div className="widget-details">
                <Card
                  id="metrics_selection"
                  className="shadow-sm"
                  style={{
                    height: "100%",
                    width: 300,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    display: "none",
                    transitionDuration: 1,
                    padding: 20,
                    border: 0,
                    borderRadius: 0,
                  }}
                >
                  <div
                    className="widget-option w-100 mx-0 mb-3"
                    role="button"
                    disabled
                  >
                    <i
                      style={{ fontSize: 15, fontSize: 20 }}
                      className={widget.icon}
                    ></i>
                    <span className="text-dark">
                      {widget.title || widget.label}
                    </span>
                  </div>

                  {widget.hasTitle && (
                    <div className="mb-3">
                      <Label className="form-label">Título</Label>
                      <input
                        className="form-control"
                        type="text"
                        value={widget.title || ""}
                        onChange={(event) => {
                          setWidget({ ...widget, title: event.target.value });
                        }}
                        id="widget-title-input"
                      />
                    </div>
                  )}

                  {widget.hasMetric && (
                    <div className="mb-3" style={{ position: "relative" }}>
                      <Label>
                        Selecione uma métrica
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <CustomTooltip text="Ao lado de cada métrica você poderá ver os ícones das fontes de dados no qual essa métrica pode ser extraída. Por exemplo: Se a métrica que você selecionou apresenta o ícone apenas do Google Ads quer dizer que essa métrica só está presente nessa fonte de dados." />

                      <div ref={metricPickerRef}>
                        <SelectPicker
                          container={() => metricPickerRef.current}
                          className="builder-metric-picker"
                          cleanable={false}
                          data={allMetricsList}
                          value={widget.metric}
                          groupBy="role"
                          placeholder="Pesquisar métrica"
                          style={{ width: "100%" }}
                          renderMenuItem={(label, item) => {
                            const enabled_data_sources = data_sources.filter(
                              (ds) =>
                                item.data_sources
                                  .map((o) => o.code)
                                  .includes(ds.code)
                            );

                            return (
                              <div className="d-flex justify-content-between">
                                <span>
                                  <i className="rs-icon rs-icon-user" /> {label}
                                </span>
                                <span>
                                  {enabled_data_sources.map((ds) => (
                                    <i
                                      className={`text-muted mx-1 ${ds.icon}`}
                                    />
                                  ))}
                                </span>
                              </div>
                            );
                          }}
                          renderMenuGroup={(label, item) => {
                            return (
                              <div>
                                <i className="rs-icon rs-icon-group" /> {label}{" "}
                                - ({item.children.length})
                              </div>
                            );
                          }}
                          renderValue={(value, item, selectedElement) => {
                            console.log("item: ", item);
                            return (
                              <div>
                                <span style={{ color: "#575757" }}>
                                  <i className="rs-icon rs-icon-user" /> Métrica
                                  :
                                </span>{" "}
                                {item?.label}
                              </div>
                            );
                          }}
                          onChange={(event) => {
                            const metric = allMetricsList.find(
                              (o) => o.value === event
                            );
                            setWidget({
                              ...widget,
                              title: metric.label,
                              description: metric.description,
                              metric: event,
                              custom_metrics: [metric] || [],
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {widget.hasMetric &&
                    widget.id !== 2 &&
                    widget.metric?.includes("custom_conversion") && (
                      <div className="mb-3" style={{ position: "relative" }}>
                        <Label>
                          Selecione a dimensão{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <CustomTooltip text="Cada conversão personalizada pode ter 3 tipos de métricas: podemos ver sua quantidade (ex.: houveram 10 vendas), seu valor: (ex.: cada venda foi de R$ 100,00) e seu custo (ex.: Cada venda custou R$ 40,00)" />

                        <div ref={metricPickerRef}>
                          <SelectPicker
                            container={() => metricPickerRef.current}
                            className="builder-metric-picker w-100"
                            cleanable={false}
                            data={[
                              { label: "Quantidade", value: "conversions" },
                              { label: "Valor", value: "conversions_value" },
                              { label: "Custo", value: "conversions_cost" },
                            ]}
                            value={widget.customConvSubmetric}
                            placeholder="Selecione o tipo de métrica"
                            onChange={(event) => {
                              setCustomConvSubmetric(event);
                            }}
                            value={customConvSubmetric}
                          />
                        </div>
                      </div>
                    )}

                  {widget.hasLines && (
                    <div className="mb-3">
                      <Label>Linhas</Label>
                      {widget.lines?.map((limit, index) => (
                        <React.Fragment key={index}>
                          <hr className="divider mt-0" />

                          <div className="mb-3">
                            <div className="d-flex flex-row">
                              <input
                                className="form-control mb-1"
                                type="text"
                                placeholder="Título da linha"
                                value={limit.text}
                                onChange={(e) => {
                                  var tempArray = [...widget.lines];
                                  tempArray[index].text = e.target.value;

                                  setWidget({
                                    ...widget,
                                    lines: tempArray,
                                  });
                                }}
                              />
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn btn-light ms-1"
                                  type="button"
                                  style={{
                                    backgroundColor:
                                      widget?.lines[index]?.color,
                                  }}
                                >
                                  <i
                                    className={`bx bxs-color-fill text-${
                                      widget.lines[index].color
                                        ? "white"
                                        : "dark"
                                    } fs-5`}
                                  />
                                </DropdownToggle>
                                <DropdownMenu className="p-3 dropdown-menu-end">
                                  <CirclePicker
                                    defaultValue="#000"
                                    onChange={(e) => {
                                      var tempArray = [...widget.lines];
                                      tempArray[index].color = e.hex;

                                      setWidget({
                                        ...widget,
                                        lines: tempArray,
                                      });
                                    }}
                                  />
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>

                            <div className="d-flex flex-row">
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Insira um valor"
                                value={limit.limit || ""}
                                onChange={(e) => {
                                  var tempArray = [...widget.lines];
                                  tempArray[index].limit = e.target.value;

                                  setWidget({
                                    ...widget,
                                    lines: tempArray,
                                  });
                                }}
                              />
                              <button
                                className="btn btn-light ms-1"
                                onClick={() => {
                                  var tempArray = [...widget.lines];
                                  tempArray.splice(index, 1);

                                  setWidget({
                                    ...widget,
                                    lines: tempArray,
                                  });
                                }}
                              >
                                <i className="bx bx-trash-alt text-dark i-btn fs-5" />
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                      <div
                        role="button"
                        className="w-100 text-center p-2 add-line-button"
                        onClick={(e) => {
                          setWidget({
                            ...widget,
                            lines: [...(widget.lines || []), { limit: 0 }],
                          });
                        }}
                      >
                        Adicionar linha
                      </div>
                    </div>
                  )}

                  {widget.hasDescription && (
                    <div className="mb-3">
                      <Label>Texto</Label>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={widget.description || ""}
                        onChange={(event) => {
                          setWidget({
                            ...widget,
                            description: event.target.value,
                          });
                        }}
                      />
                    </div>
                  )}

                  {widget.hasContent && (
                    <TextEditor widget={widget} setWidget={setWidget} />
                  )}

                  <div
                    id="builder-buttons"
                    className="d-flex flex-row justify-content-between"
                  >
                    <button
                      id="builder-step-3"
                      className="btn btn-light me-1 w-100"
                      onClick={() => {
                        setIsWidgetDetailsOpen(false);
                        setNowUpdatingWidgetId(null);
                        $("#metrics_selection").fadeOut(100);
                        setTimeout(() => {
                          setIsUpdatingWidget(false);
                          setWidget({});
                        }, 100);
                      }}
                    >
                      Voltar
                    </button>

                    {!isUpdatingWidget ? (
                      <button
                        className="btn btn-primary ms-1 w-100"
                        disabled={widget.hasMetric && !widget.metric}
                        onClick={async () => {
                          await handleAdd(widget);

                          $("#metrics_selection").fadeOut(100);
                          setTimeout(() => {
                            setIsUpdatingWidget(false);
                            setWidget({});
                          }, 200);
                        }}
                      >
                        Adicionar
                      </button>
                    ) : (
                      <button
                        className="btn btn-dark ms-1 w-100"
                        onClick={async () => {
                          await updateWidget(widget.id);
                        }}
                      >
                        Atualizar
                      </button>
                    )}
                  </div>
                </Card>
              </div>
            </TabPane>
          </TabContent>
        </div>

        <div
          className="w-100 px-0"
          style={{ minHeight: "100vh", marginTop: 110, overflowX: "hidden" }}
        >
          <div
            className={classNames({ "pt-4": !selectedAdAccounts.length })}
            style={{
              backgroundColor: "#fff",
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
            }}
          >
            {!props.ad_accounts.length ? (
              <Alert color="info" className="mb-3">
                Atenção: Você deve selecionar uma ou mais contas de anúncios e
                então clicar em "aplicar" para que os dados sejam carregados.
              </Alert>
            ) : null}

            <InsightsComponent
              displayType={1}
              dashboard={dashboard}
              dashboardWidgets={[]}
              setLoadingInsights={setLoadingInsights}
              dashboardBrandId={dashboardBrandId}
              selectedAdAccounts={selectedAdAccounts}
              setSelectedAdAccounts={setSelectedAdAccounts}
              selectedCampaigns={selectedCampaigns}
              setSelectedCampaigns={setSelectedCampaigns}
            />
          </div>

          <Alert
            color="info"
            className="m-3  d-xs-block d-sm-block d-md-block d-lg-none d-xl-none"
          >
            Atenção, algumas funcionalidades do Builder requerem uma tela maior
            para que funcionem totalmente
          </Alert>

          <div ref={print_ref}>
            <>
              {isDownloadingPDF && (
                <div className="p-4">
                  <div
                    style={{
                      height: 60,
                      backgroundColor: "#1e55eb",
                      width: "100%",
                      borderRadius: 10,
                    }}
                    className="shadow-sm"
                  >
                    <div className="navbar-header">
                      <div>
                        <div className="logo logo-dark">
                          <span className="logo-sm">
                            <img
                              className="report-logo"
                              src={logoSm}
                              alt=""
                              height="35"
                            />
                          </span>
                          <span className="logo-lg">
                            <img
                              className="report-logo"
                              src={logoLight}
                              alt=""
                              height="35"
                            />
                          </span>
                        </div>

                        <div className="logo logo-light">
                          <span className="logo-sm">
                            <img
                              className="report-logo"
                              src={logoSmLight}
                              alt=""
                              height="35"
                            />
                          </span>
                          <span className="logo-lg">
                            <img
                              className="report-logo"
                              src={logoLight}
                              alt=""
                              height="35"
                            />
                          </span>
                        </div>
                      </div>
                      <div>
                        <span
                          className="text-white"
                          style={{ fontSize: 20, fontWeight: 700 }}
                        >
                          {dashboard.name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
            {widgetArray?.length != 0 ? (
              <Row>
                <Col>
                  {windowWidth && windowWidth >= 997 ? (
                    mainWidgetsArea
                  ) : (
                    <DashboardRenderer
                      dashboard={dashboard}
                      layouts={layouts}
                      onLayoutChange={handleModify}
                      widgetArray={widgetArray}
                      dashboardVersion={dashboard.version || 1}
                      // isDraggable={true}
                    />
                  )}
                </Col>
              </Row>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ minHeight: "70vh" }}
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/7486/7486792.png"
                  alt="Adicione um Widget"
                  style={{ maxWidth: "140px", display: "block" }}
                />
                <span className="text-muted text-center mt-2">
                  Adicione um Widget <br /> utilizando o menu ao lado!
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (store) => ({
  ad_accounts: store.Analytics.ad_accounts,
  account_specific: store.Analytics.account_specific,
  account_general_aggregated: store.Analytics.account_general_aggregated,
  account_general_granular: store.Analytics.account_general_granular,
  account_by_data_source_aggregated:
    store.Analytics.account_by_data_source_aggregated,
  account_by_data_source_granular:
    store.Analytics.account_by_data_source_granular,
});

const mapDispatchToProps = (dispatch) => ({
  changeTopbarTheme: (theme) => dispatch(changeTopbarTheme(theme)),
  changeLayoutMode: (layoutMode) => dispatch(changeLayoutMode(layoutMode)),
  setDashboard: (dashboard) => dispatch(setDashboard(dashboard)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Builder);
