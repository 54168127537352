import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

export default function BrandNotFound() {
  return (
    <Row className="d-flex justify-content-center text-center p-5">
      <Col lg={10}>
        <img
          src="https://cdn.dribbble.com/users/343356/screenshots/11162207/drawing-computer-tools-img_10993-1600x1200_4x.jpg"
          alt="Você precisa criar uma empresa"
          style={{ maxWidth: 300, marginBottom: 30 }}
        />
        <h4>Ops! Parece que você ainda não criou uma empresa...</h4>
        <p>
          Para adicionar Dashboards é necessário que antes, crie pelo menos uma
          empresa.
        </p>
        <Link to={"/brands/create"}>
          <button className="btn btn-success">Criar Empresa</button>
        </Link>
      </Col>
    </Row>
  );
}