import { subDays } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { connect } from "react-redux";
import { Card, Col, Container, Row } from "reactstrap";
import { Input, InputPicker, Loader, Table } from "rsuite";
import { getImageURL } from "../../functions/getImageURL";
import api from "../../services/api";
import { changeLayout, changeSidebarType } from "../../store/actions";
import UserModal from "./UserDetails.modal";
import CsvDownloadButton from "react-json-to-csv";
import $ from "jquery";
import Excel from "exceljs";

const { Column, HeaderCell, Cell } = Table;

const picker_options = [
  {
    label: "Todos os usuários",
    value: "all",
  },
  {
    label: "Pagantes",
    value: "subscribers",
  },
  {
    label: "Pagantes Ativos",
    value: "subscribers_active",
  },
  {
    label: "Pagantes Atrasados/Incompletos",
    value: "subscribers_past_due_incomplete",
  },
  {
    label: "Pagantes Cancelados",
    value: "subscribers_canceled",
  },
  {
    label: "Trial Ativo",
    value: "trial_active",
  },
  {
    label: "Trial Expirado",
    value: "trial_expired",
  },
  {
    label: "Banidos",
    value: "banned",
  },
];

const handleExport = (data, format) => {
  if (format === "xlsx") {
    /* create a workbook and add a worksheet to it */
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    /* add the headers to the worksheet */
    worksheet.columns = Object.keys(data[0]).map((c) => ({
      header: c,
      key: c,
    }));

    /* add the data to the worksheet */
    worksheet.addRows(data);

    /* generate the XLSX file */
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "data.xlsx";
      link.click();
    });
  } else if (format === "csv") {
    /* generate the CSV file */
    const headers = Object.keys(data[0]);
    const csvData = [headers.join(",")]
      .concat(data.map((row) => headers.map((header) => row[header]).join(",")))
      .join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.csv";
    link.click();
  }
};

function usersFilterByType(type, data) {
  switch (type) {
    case "all":
      return data;
    case "subscribers":
      return data.filter(
        (user) => user.subscription && user.subscription.status !== "ok"
      );
    case "subscribers_active":
      return data.filter(
        (user) => user.subscription && user.subscription.status === "active"
      );
    case "subscribers_past_due_incomplete":
      return data.filter(
        (user) =>
          user.subscription &&
          (user.subscription.status === "past_due" ||
            user.subscription.status === "incomplete" ||
            user.subscription.status === "incomplete_expired")
      );
    case "subscribers_canceled":
      return data.filter(
        (user) => user.subscription && user.subscription.status === "canceled"
      );
    case "trial_active":
      return data.filter(
        (user) =>
          user.trial &&
          (new Date(user.trial_expires_at).getTime() > new Date().getTime() ||
            user.trial_expires_at === null)
      );
    case "trial_expired":
      return data.filter(
        (user) =>
          user.trial &&
          new Date(user.trial_expires_at).getTime() < new Date().getTime()
      );
    case "banned":
      return data.filter((user) => user.is_banned);

    default:
      return data;
  }
}

const NameCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div className="h-100 d-flex align-items-center ps-2">
      <span style={rowData.is_banned ? { color: "red" } : null}>
        {rowData[dataKey]}
      </span>
    </div>
  </Cell>
);

const ImageCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div className="h-100 d-flex align-items-center ps-2">
      <img
        className="avatar-sm rounded-circle"
        src={getImageURL(rowData[dataKey] || "profile_placeholder.png")}
        width="40"
      />
    </div>
  </Cell>
);

const DateCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div className="h-100 d-flex align-items-center ps-2">
      <span>{rowData[dataKey] ? moment(rowData[dataKey]).fromNow() : "-"}</span>
    </div>
  </Cell>
);

const SubscriptionCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div className="h-100 d-flex align-items-center ps-2">
      <span>
        {rowData[dataKey]?.items && rowData[dataKey]?.items[0].plan.nickname}
      </span>
    </div>
  </Cell>
);

const UsersPage = (props) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [users, setUsers] = useState([]);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const [selectedFilter, setSelectedFilter] = React.useState("all");

  const filteredData =
    search.length > 0
      ? usersFilterByType(selectedFilter, users).filter(
          (o) =>
            String(o.name)
              .toLowerCase()
              .replace(/ /g, "")
              .includes(search.toLowerCase().replace(/ /g, "")) ||
            String(o.email)
              .toLowerCase()
              .replace(/ /g, "")
              .includes(search.toLowerCase().replace(/ /g, "")) ||
            String(o.tel_number)
              .toLowerCase()
              .replace(/ /g, "")
              .includes(search.toLowerCase().replace(/ /g, ""))
        )
      : usersFilterByType(selectedFilter, users);

  const getData = () => {
    if (sortColumn && sortType) {
      return filteredData.sort((a, b) => {
        let x = a[sortColumn] || null;
        let y = b[sortColumn] || null;

        if (sortColumn === "createdAt" || sortColumn === "last_active_at") {
          x = new Date(x).getTime();
          y = new Date(y).getTime();
        } else {
          if (typeof x === "string") {
            x = x.charCodeAt();
          }
          if (typeof y === "string") {
            y = y.charCodeAt();
          }
        }

        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return filteredData;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 200);
  };

  async function getUsers() {
    setLoading(true);
    await api
      .get("/api/usuarios")
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    $("#export-to-csv-button").html("Exportar CSV");
    props.changeLayout("vertical");
    props.changeSidebarType("small");

    getUsers();
  }, []);

  useEffect(() => {
    if (selectedUser._id) {
      // update only this user in the users array
      const newUsers = users.map((user) => {
        if (user._id === selectedUser._id) {
          return selectedUser;
        }
        return user;
      });
      setUsers(newUsers);
      console.log("updated user", selectedUser);
    }
  }, [selectedUser]);

  return (
    <>
      <MetaTags>
        <title>Usuários - Admin</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12} style={{ height: "80vh" }}>
              <Card className="h-100 w-100">
                <div className="p-3 d-flex justify-content-between">
                  <Input
                    style={{ maxWidth: 500 }}
                    value={search}
                    onChange={(v) => {
                      setSearch(v);
                    }}
                    placeholder="Pesquisar usuário..."
                  />
                  <div>
                    {loading ? (
                      <Loader />
                    ) : (
                      <span className="me-2 align-middle text-muted">
                        {getData().length.toLocaleString("pt-BR")} resultados
                      </span>
                    )}
                    <InputPicker
                      cleanable={false}
                      defaultValue={picker_options[0].value}
                      data={picker_options}
                      value={selectedFilter}
                      onChange={(v) => setSelectedFilter(v)}
                      style={{ width: 224 }}
                    />
                    <button
                      className="btn btn-dark mx-1"
                      onClick={() => handleExport(filteredData, "xlsx")}
                    >
                      Exportar pra XLS
                    </button>
                    <button
                      className="btn btn-success mx-1"
                      onClick={() => handleExport(filteredData, "csv")}
                    >
                      Exportar pra CSV
                    </button>
                  </div>
                </div>

                <div className="h-100">
                  <Table
                    fillHeight
                    style={{ borderRadius: 10, paddingBottom: 100 }}
                    virtualized
                    height={400}
                    data={getData()}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    loading={loading}
                    onRowClick={(rowData) => {
                      setSelectedUser(rowData);
                    }}
                  >
                    <Column sortable resizable width={60} align="center" fixed>
                      <HeaderCell>Id</HeaderCell>
                      <ImageCell dataKey="profile_pic" />
                    </Column>

                    <Column sortable flexGrow={1} width={150}>
                      <HeaderCell>Nome</HeaderCell>
                      <NameCell dataKey="name" />
                    </Column>

                    <Column sortable flexGrow={1} width={100}>
                      <HeaderCell>E-mail</HeaderCell>
                      <Cell dataKey="email" />
                    </Column>

                    <Column sortable flexGrow={1} width={100}>
                      <HeaderCell>Telefone</HeaderCell>
                      <Cell dataKey="tel_number" />
                    </Column>

                    <Column sortable flexGrow={1} width={150}>
                      <HeaderCell>Criado em</HeaderCell>
                      <DateCell dataKey="createdAt" />
                    </Column>

                    <Column sortable flexGrow={1} width={300}>
                      <HeaderCell>Última atividade</HeaderCell>
                      <DateCell dataKey="last_active_at" />
                    </Column>

                    <Column sortable flexGrow={1} width={300}>
                      <HeaderCell>Plano</HeaderCell>
                      <SubscriptionCell dataKey="subscription" />
                    </Column>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <UserModal
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeLayout: (layout) => dispatch(changeLayout(layout)),
  changeSidebarType: (sidebarType) => dispatch(changeSidebarType(sidebarType)),
});

export default connect(null, mapDispatchToProps)(UsersPage);
