import { API_SUCCESS, API_FAIL } from "./actionTypes";

const INIT_STATE = {
  dashboard: {},
  loading: false, //ex true
  widgets: [],
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_DASHBOARD":
      return {
        ...state,
        dashboard: action.payload,
      };
      break;

    default:
      return state;
      break;
  }
};

export default Dashboard;
