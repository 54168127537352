import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { Drawer, ButtonToolbar, Button, Placeholder, Tag } from "rsuite";
import { order_status_list } from "../../common/data/order_statuses";
import { payment_methods } from "../../common/data/payment_methods";
import InputMask from "react-input-mask";
import { copyToClipboard } from "../../functions/copyToClipboard";
import QRCode from "react-qr-code";
import { Card } from "reactstrap";
import CardBrandSvg from "./CardBrandSvg";

const goToWhatsapp = (phone) => {
  var message = encodeURIComponent(
    "Olá, gostaria de falar com você sobre a compra de um de nossos produtos! "
  );
  phone = document
    .getElementById("customer-tel")
    .innerText.replace(/[^\d]/g, "");
  window.open(
    `https://api.whatsapp.com/send?phone=55${phone}&text=${message}`,
    "_blank"
  );
};
const maskPhone = (value) => {

  if (!value) return "Não informado";
  
  if (value.length === 13) {
    value = value.substring(2);
  }

  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/g, "($1) $2")
    .replace(/(\d)(\d{4})$/, "$1-$2");
};

const StatusTag = ({ orderData, status }) => {
  return (
    <span className="mx-3">
      <Tag
        style={{
          backgroundColor: `${status.color || "#000000"}CC`,
          color: "#fff",
        }}
        size="sm"
      >
        {status.label || "-"}
        <i className={`${status.icon || null} ms-1`} />
      </Tag>
    </span>
  );
};

const SaleDetails = (props) => {
  var order = props.orderData;

  const status =
    order_status_list.find((s) => s.code === order.transaction?.status) || {};

  const payment_method =
    payment_methods.find(
      (method) => method.code === order.transaction?.payment_type
    ) || {};

  return (
    <>
      {Object.keys(props.orderData).length && (
        <Drawer
          open={props.open}
          onClose={() => props.setOpen(false)}
          size="md"
        >
          <Drawer.Header>
            <Drawer.Title>
              <b> Pedido </b>
              <b className="text-primary"> {order.identification.order_id}</b>
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <b className="text-muted" style={{ fontSize: 14 }}>
              <i className="mdi mdi-account-cash me-2" />
              VENDA
            </b>
            <div className="d-flex align-items-center">
              <b style={{ fontSize: 32 }}>
                {(order.transaction.value / 100).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                  maximumFractionDigits: 2,
                })}
              </b>
              <StatusTag orderData={order} status={status} />
            </div>
            <hr className="divider" />
            <div>
              <div
                className="d-flex flex-row w-100 mt-1"
                style={{ fontWeight: 400 }}
              >
                <div className="d-flex flex-column justify-content-start mx-2">
                  <span className="text-muted mb-1">Última atualização</span>
                  <span>
                    {moment(order.transaction.updated_date).format(
                      "DD MMMM, HH:mm"
                    )}
                  </span>
                </div>
                <div className="vl"></div>
                <div className="d-flex flex-column justify-content-start mx-2">
                  <span className="text-muted mb-1">Forma de pagamento</span>
                  <span>
                    {order.transaction.payment_card_brand ? (
                      <div className="d-flex align-items-center">
                        <CardBrandSvg
                          cardBrand={order.transaction.payment_card_brand.toLowerCase()}
                        />
                        <span className="ms-2">{payment_method.label}</span>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <img
                          src={payment_method?.icon || "mdi mdi-error"}
                          style={{ maxHeight: 15 }}
                        />
                        <span className="ms-2">{payment_method.label}</span>
                      </div>
                    )}
                  </span>
                </div>
                <div className="vl"></div>
                <div
                  className="d-flex flex-column justify-content-start mx-2"
                  style={{ maxWidth: 300 }}
                >
                  <span className="text-muted mb-1">Cliente</span>
                  <span>{order.customer.name}</span>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <b className="text-muted" style={{ fontSize: 14 }}>
                <i className="mdi mdi-cube-outline me-2" />
                PRODUTOS
              </b>

              <div className="mt-2">
                {order.product.bundles
                  .flatMap((bundles) => bundles.products)
                  .map((item, index) => (
                    <Card className="p-3 d-flex flex-row justify-content-between align-items-center">
                      <div className="d-flex flex-column">
                        <b>{item.name}</b>
                        <span className="text-muted">
                          {item.description || "Plano sem descrição"}
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="text-muted">Quantidade</span>
                        <b>x {item.quantity}</b>
                      </div>
                      <div className="d-flex flex-column">
                        <b className="text-primary fs-5">
                          {(item.price * item.quantity).toLocaleString(
                            "pt-br",
                            {
                              style: "currency",
                              currency: "BRL",
                              maximumFractionDigits: 2,
                            }
                          )}
                        </b>
                      </div>
                    </Card>
                  ))}
              </div>
            </div>

            <hr className="divider" />

            <div className="mt-4">
              <b className="text-muted" style={{ fontSize: 14 }}>
                <i className="mdi mdi-account me-2" />
                CLIENTE
              </b>
              <div
                className="d-flex flex-column w-100 mt-1"
                style={{ fontWeight: 400 }}
              >
                <div className="d-flex flex-column justify-content-between">
                  <div className="my-1 mx-2 d-flex justify-content-between">
                    <b>Nome</b>
                    <span>{order.customer.name}</span>
                  </div>

                  <div className="my-1 mx-2 d-flex justify-content-between">
                    <b>E-mail</b>
                    <span
                      id="customer-email"
                      className="copy-to-clipboard text-primary"
                      onClick={() => copyToClipboard("customer-email")}
                    >
                      {order.customer.email}
                    </span>
                  </div>

                  <div className="my-1 mx-2 d-flex justify-content-between">
                    <b>Telefone</b>
                    <div>
                      <Button
                        size="xs"
                        className="me-2"
                        onClick={() => goToWhatsapp("customer-tel")}
                      >
                        <i className="mdi mdi-whatsapp text-success me-1" />
                        Whatsapp
                      </Button>
                      <span
                        id="customer-tel"
                        className="copy-to-clipboard text-primary"
                        onClick={() => copyToClipboard("customer-tel")}
                      >
                        {maskPhone(order.customer.telephone)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="divider" />

            <div className="mt-4">
              <b className="text-muted" style={{ fontSize: 14 }}>
                <i className="mdi mdi-cash-register me-2" />
                TRANSAÇÃO
              </b>
              <div
                className="d-flex flex-column w-100 mt-1"
                style={{ fontWeight: 400 }}
              >
                <div className="d-flex flex-column justify-content-between">
                  <div className="my-1 mx-2 d-flex justify-content-between">
                    <b>Criação</b>
                    <span>
                      {moment(order.transaction.created_at).format(
                        "DD MMMM, HH:mm"
                      )}
                    </span>
                  </div>

                  <div className="my-1 mx-2 d-flex justify-content-between">
                    <b>Última atualização</b>
                    <span>
                      {moment(order.transaction.updated_at).format(
                        "DD MMMM, HH:mm"
                      )}
                    </span>
                  </div>

                  <div className="my-1 mx-2 d-flex justify-content-between">
                    <b>Status</b>
                    <span>{status.label}</span>
                  </div>

                  <div className="my-1 mx-2 d-flex justify-content-between">
                    <b>Valor</b>
                    <span>
                      {(order.transaction.value / 100).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </div>

                  <div className="my-1 mx-2 d-flex justify-content-between">
                    <b>Desconto</b>
                    <span>
                      {(
                        order.transaction.discount_value / 100 || 0
                      ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </div>

                  <div className="my-1 mx-2 d-flex justify-content-between">
                    <b>Forma de pagamento</b>
                    <span>{payment_method.label}</span>
                  </div>

                  {order.transaction.billet_url && (
                    <div className="my-1 mx-2 d-flex justify-content-between">
                      <b>Linha digitável</b>
                      <span
                        id="payment-line"
                        className="copy-to-clipboard text-primary"
                        onClick={() => {
                          copyToClipboard("payment-line");
                        }}
                      >
                        {order.transaction.payment_line}
                      </span>
                    </div>
                  )}

                  {order.transaction.billet_url && (
                    <div className="mt-5 mx-2 d-flex justify-content-between">
                      <div
                        style={{
                          width: "100%",
                          minHeight: 700,
                          border: "1px solid #f1f1f1",
                          borderRadius: 5,
                        }}
                        className="shadow-sm"
                      >
                        <iframe
                          src={`${order.transaction.billet_url}#view=fitH`}
                          title="testPdf"
                          height="100%"
                          width="100%"
                        />
                      </div>
                    </div>
                  )}

                  {order.transaction.pix_emv && (
                    <div className="my-1 mx-2 d-flex justify-content-between">
                      <b>PIX copia e cola</b>
                      <span
                        id="pix-url"
                        className="copy-to-clipboard text-end"
                        onClick={() => {
                          copyToClipboard("pix-url");
                        }}
                      >
                        {order.transaction.pix_emv}
                      </span>
                    </div>
                  )}

                  {order.transaction.pix_emv && (
                    <div className="my-2 mx-2 d-flex justify-content-between">
                      <b>PIX QR Code</b>
                      <QRCode value={order.transaction.pix_emv} size={128} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Drawer.Body>
        </Drawer>
      )}
    </>
  );
};

export default SaleDetails;
