import React, { useState } from "react";
import { Col, Container, Modal } from "reactstrap";

import { toast } from "react-toastify";
import { getIdUsuario, getToken } from "../../services/auth";

const ExternalAdAccountConnectModal = (props) => {
  const [modal, setModal] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_xlarge() {
    setModal(!modal);
    removeBodyCss();
  }

  return (
    <React.Fragment>
      {!/external/.test(window.location.href) ? (
        <Col lg={6} sm={12} className="my-2">
          <button
            className="btn btn-light w-100"
            onClick={() => {
              tog_xlarge();
            }}
          >
            <i className="bx bx-user-plus fs-2 me-3 align-middle"></i>
            <span className="align-middle">Conectar de outra forma</span>
          </button>
        </Col>
      ) : null}

      <Container>
        <Modal
          isOpen={modal}
          toggle={() => {
            tog_xlarge();
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Conectar de forma externa</h5>
            <button
              type="button"
              onClick={() => {
                setModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              <b className="text-warning">Atenção:</b> esse método dever ser
              utilizado quando a conta de anúncios que está tentando conectar
              não está no perfil aberto nesse navegador, por exemplo, se você
              utiliza diversos perfis para anunciar e não deseja abrir o metrito
              em cada um deles.
            </p>
            <button
              className="btn btn-success w-100"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/external-connection/${
                    props.dataSource
                  }/${getIdUsuario()}/${props.brandId}/${getToken()}`
                );
                toast.success("Link copiado!");
              }}
            >
              <i className="bx bxs-magic-wand me-1" /> Copiar link mágico
            </button>
            <p className="text-muted m-2 text-center" style={{ fontSize: 12 }}>
              Abra este link no navegador no qual o perfil que deseja conectar
              as contas de anúncio esteja logado.
            </p>
          </div>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default ExternalAdAccountConnectModal;
