import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Modal, Row } from "reactstrap";
import animationData from "../../assets/lotties/ok_lottie.json";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";
import Preloader from "../Utility/Preloader";
const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ReduceAdAccountsModal = (props) => {
  const [user, setUser] = useState({});
  const [adaccounts, setAdaccounts] = useState([]);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const [quantityToRemove, setQuantityToRemove] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    props.setVisible(!props.visible);
    removeBodyCss();
  }

  function loadUserData() {
    api
      .get("/api/usuarios.details/" + getIdUsuario())
      .then((response) => {
        setUser(response.data[0]);
        setAdaccounts(
          response.data[0].brands.flatMap((brand) =>
            brand.ad_accounts.map((adaccount) => ({
              ...adaccount,
              brand: brand,
            }))
          )
        );

        setQuantityToRemove(
          response.data[0].brands.flatMap((brand) => brand.ad_accounts).length -
            response.data[0].account_limits?.ad_accounts || 0
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function handleDelete() {
    setButtonLoading(true);
    var promiseArray = selectedAdAccounts.map(async (adaccount) => {
      const result = await api.delete(
        "/api/ad-account/remove/" + adaccount.brand._id,
        {
          data: { ds_id: adaccount.ds_id },
        }
      );
      if (result.status === 200) {
        console.log("Conta deletada com sucesso");
      } else {
        console.log("Erro ao deletar conta");
      }
    });

    Promise.all(promiseArray)
      .then((res) => {
        setButtonLoading(false);
        setSuccess(true);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(true);
        toast.error(
          "Erro ao realizar operação, por favor tente novamente ou acione o suporte."
        );
      });
  }

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <>
      <Modal
        id="reduce-adaccounts-modal"
        size="lg"
        className="modal-dialog-centered"
        isOpen={props.visible}
        toggle={() => {
          tog_xlarge();
        }}
      >
        {!adaccounts ? (
          <Preloader />
        ) : !success ? (
          <div className="modal-body text-center p-5 text-muted">
            <div>
              <i
                className="mdi mdi-alert text-warning"
                style={{ fontSize: 70 }}
              />
              <h2>Limite de Contas de Anúncio excedido!</h2>
              <p className="text-muted">
                O seu número de contas de anúncio conectadas na plataforma
                ultrapassa o limite contratado, por favor remova a quantidade
                necessária de contas ou{" "}
                <a href="/checkout?type=adaccounts&"></a> para que o serviço
                seja normalizado
              </p>

              <Card className="mt-5">
                <CardBody>
                  <h5 className="mb-3">
                    Selecione as Contas de Anúncio que deseja remover (
                    {`${selectedAdAccounts.length}/${quantityToRemove}`})
                  </h5>
                  <hr className="divider" />
                  <div
                    style={{
                      maxHeight: 500,
                      overflowY: "scroll",
                      padding: 10,
                    }}
                  >
                    {adaccounts?.map((adAccount, key) => {
                      var isSelected = selectedAdAccounts.some(
                        (act) => act.ds_id === adAccount.ds_id
                      );
                      return (
                        <Card
                          key={key}
                          className={classNames(
                            { "removed-card": isSelected },
                            "text-start reduce-adacccounts-card"
                          )}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (isSelected) {
                              var newArr = [...selectedAdAccounts];
                              var index = newArr.findIndex(
                                (o) => o.ds_id === adAccount.ds_id
                              );

                              newArr.splice(index, 1);

                              setSelectedAdAccounts(newArr);
                            } else {
                              setSelectedAdAccounts([
                                ...selectedAdAccounts,
                                adAccount,
                              ]);
                            }
                          }}
                        >
                          <CardBody className="d-flex flex-row justify-content-between align-items-center">
                            <b className="text-dark fs-5">{adAccount.name}</b>
                            <i
                              className={classNames(
                                {
                                  "bx-circle": !isSelected,
                                  "bx bxs-minus-circle text-danger": isSelected,
                                },
                                "bx ms-2 align-middle fs-3"
                              )}
                            />
                          </CardBody>
                        </Card>
                      );
                    })}
                  </div>
                  <hr className="divider" />
                  <Row>
                    <Col lg={6}>
                      <button
                        className="btn btn-danger w-100 fs-5"
                        disabled={
                          quantityToRemove > selectedAdAccounts.length ||
                          buttonLoading
                        }
                        onClick={() => handleDelete()}
                      >
                        Remover {selectedAdAccounts.length} Contas
                        {buttonLoading ? (
                          <i className="mdi mdi-loading mdi-spin ms-2 align-middle" />
                        ) : null}
                      </button>
                    </Col>
                    <Col lg={6}>
                      <Link
                        to="/plans"
                        className="btn btn-success w-100 fs-5 shadow-sm"
                      >
                        <b>Fazer Upgrade</b>
                        <i className="mdi mdi-arrow-right-circle ms-2 align-middle" />
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center text-center align-items-center w-100 p-5">
            <Lottie
              options={defaultOptions}
              height={"auto"}
              width={200}
              style={{ margin: "none" }}
            />
            <h2>Tudo Certo!</h2>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ReduceAdAccountsModal;
