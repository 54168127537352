import React from "react";

import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { CardBody } from "reactstrap";
import { data_sources } from "../common/data/data_sources";
import { CustomTooltip } from "../components/Common/Tooltip.js";
import { formatByDisplayType } from "../functions/formatByDisplayType";

const AreaChart = (props) => {
  var series = [
    {
      name: props.selected.title,
      data: props.metrics.map((o) => o[props.selected.metric] || null) || [],
    },
  ];

  if (props.metrics_by_data_source) {
    series = [
      ...series,
      ...props.metrics_by_data_source.map((o) => ({
        name: data_sources.find((ds) => ds.code === o.data_source).label,
        data: o.data.map((o) => o[props.selected.metric] || null) || [],
      })),
    ];
  }

  var options = {
    chart: {
      height: 290,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    annotations: {
      yaxis:
        props.selected.lines?.map((line) => ({
          y: line.limit,
          borderColor: line.color || "#000",
          strokeDashArray: 5,
          label: {
            borderColor: line.color || "#000",
            style: {
              color: "#fff",
              background: line.color || "#000",
            },
            text: line.text,
          },
        })) || [],
    },
    labels: props.metrics.map((a) => moment(a.date).toLocaleString("pt-BR")),
    colors: ["#6A7885", "#0050ff", "#0ab39c"],
    markers: {
      size: 5, // this property allows you to set the size of the markers
      hover: {
        size: 7,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    xaxis: {
      type: "datetime",
      tickPlacement: "on",
    },
    yaxis: {
      labels: {
        formatter: function (y) {
          return formatByDisplayType(y, props.selected.metric);
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          return formatByDisplayType(y, props.selected.metric);
        },
      },
    },
  };

  return (
    <React.Fragment>
      <CardBody>
        <div>
          <p className="text-muted text-uppercase fw-semibold d-flex flex-row mb-2">
            <span className="float-start text-truncate align-middle">{props.selected.title}</span>

            <CustomTooltip placement="topStart" text={props.selected.description} />
          </p>
        </div>

        <ReactApexChart options={options} series={series} type="area" height="250" />
      </CardBody>
    </React.Fragment>
  );
};

export default AreaChart;
