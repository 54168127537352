import React from "react";
import InputMask from "react-input-mask";
import { Card, CardBody, Input, Label } from "reactstrap";
import BlurLoader from "./BlurLoader";
import PlansModal from "./plans.modal";

const PlansRightbar = (props) => {
  return (
    <React.Fragment>
      <Card>
        {props.loadingPlans ? (
          <BlurLoader text={""} />
        ) : props.isLoading ? (
          <BlurLoader text={"Processando dados..."} />
        ) : null}
        <CardBody className="d-flex flex-column">
          <h5>Plano Selecionado (mensal)</h5>
          <div className="w-100 text-center mt-4">
            <img
              src={props.selectedPlan.icon_url}
              alt="plano"
              style={{ maxHeight: 100 }}
            />
            <h5 className="mt-2">{props.selectedPlan.name}</h5>

            {props.discount > 0 && props.coupom_code === "ESPECIAL50OFF" ? (
              <div>
                <span className="text-muted">
                  {(
                    props.selectedPlan.price +
                      props.rangeValue *
                        props.selectedPlan.extra_account_price || 0
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    maximumFractionDigits: 2,
                  })}
                </span>

                <span className="text-success">
                  {" - "}
                  50% OFF (
                  {props.discount.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    maximumFractionDigits: 2,
                  })}
                  )
                </span>

                <h3 id="cart-total" className="text-success mt-2">
                  {(
                    (props.selectedPlan.price +
                      props.rangeValue *
                        props.selectedPlan.extra_account_price || 0) -
                    props.discount
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    maximumFractionDigits: 2,
                  })}
                </h3>
              </div>
            ) : props.discount > 0 &&
              props.coupom_code === "ANDRE60ESPECIAL" ? (
              <div>
                <span className="text-muted">
                  {(
                    props.selectedPlan.price +
                      props.rangeValue *
                        props.selectedPlan.extra_account_price || 0
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    maximumFractionDigits: 2,
                  })}
                </span>

                <span className="text-success">
                  {" - "}
                  60% OFF (
                  {props.discount.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    maximumFractionDigits: 2,
                  })}
                  )
                </span>

                <h3 id="cart-total" className="text-success mt-2">
                  {(
                    (props.selectedPlan.price +
                      props.rangeValue *
                        props.selectedPlan.extra_account_price || 0) -
                    props.discount
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    maximumFractionDigits: 2,
                  })}
                </h3>
              </div>
            ) : (
              <h3 id="cart-total" className="text-success">
                {(
                  props.selectedPlan.price +
                    props.rangeValue * props.selectedPlan.extra_account_price ||
                  0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                  maximumFractionDigits: 2,
                })}
              </h3>
            )}

            {props.rangeValue != 0 ? (
              <span className="text-muted">
                {`Plano + ${props.rangeValue} contas extras`}
              </span>
            ) : null}
          </div>
          <hr className="divider" />
          <div className="d-flex flex-column text-start p-2">
            <span className="my-1">
              <i className=" fas fa-plug text-primary me-2" />
              {props.selectedPlan.ad_accounts + Number(props.rangeValue)} contas
              de anúncio
            </span>
            {props.selectedPlan.features?.map((feature, key) => (
              <span key={key} className="my-1">
                <i className="bx bxs-check-circle text-success me-2 mt-1" />
                {feature.label}
              </span>
            ))}

            <div
              className="my-3 p-3"
              style={{ border: "2px dashed red", borderRadius: 10 }}
            >
              <Label className="form-label fw-bold">
                Contas de Anúncio Extras
              </Label>

              <div>
                <span>{`+ ${props.rangeValue} contas`}</span>
                <span
                  className={`ms-2 text-${
                    props.rangeValue != 0 ? "success" : "muted"
                  }`}
                >
                  {`(total: + ${(
                    props.rangeValue * props.selectedPlan.extra_account_price
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    maximumFractionDigits: 2,
                  })})`}
                </span>
              </div>

              <div className="d-flex flex-row align-items-center mt-3">
                <i className=" fas fa-plug text-primary me-2" />
                <b>Adicionar</b>
                <input
                  type="number"
                  className="form-control mx-3"
                  style={{ maxWidth: 100, maxHeight: 30 }}
                  value={props.quantity}
                  mask="9999"
                  maskchar=""
                  min={1}
                  tag={[Input, InputMask]}
                  value={props.rangeValue}
                  required
                  onChange={(e) => props.setRangeValue(Number(e.target.value))}
                />
                <b className="text-truncate">Contas de Anúncio Extras</b>
              </div>
            </div>

            <PlansModal
              user={props.user}
              loading={props.isLoading}
              loadingPlans={props.loadingPlans}
              plans={props.plans}
              selectedPlan={props.selectedPlan}
              setSelectedPlan={props.setSelectedPlan}
            />

            <hr className="divider" />

            {props.discount > 0 && props.coupom_code === "ESPECIAL50OFF" ? (
              <div
                className="bg-dark w-100 p-2 d-flex text-center justify-content-center"
                style={{ borderRadius: 10, outline: "2px solid #40E678" }}
              >
                <span>
                  Cupom Especial <br /> 50% OFF Vitalício
                </span>
              </div>
            ) : props.discount > 0 &&
              props.coupom_code === "ANDRE60ESPECIAL" ? (
              <div
                className="bg-gold w-100 p-2 d-flex text-center justify-content-center"
                style={{ borderRadius: 10, outline: "2px solid #40E678" }}
              >
                <span>Cupom Especial André Nunes</span>
              </div>
            ) : (
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tem um cupom?"
                  value={props.coupom_code}
                  onChange={(e) => props.setCoupom_code(e.target.value)}
                />
                <button
                  className="btn btn-primary"
                  type="button"
                  id="inputGroupFileAddon03"
                  onClick={() => props.applyCoupom(props.coupom_code)}
                >
                  <i className="mdi mdi-ticket-percent me-1" />
                  Aplicar
                </button>
              </div>
            )}

            <hr className="divider" />

            <div className="text-start d-flex flex-row align-items-center">
              <img
                src="https://super-descontos-especiais.com.br/wp-content/uploads/2022/04/selo-de-qualidade_1.png"
                alt="garantia"
                style={{ maxHeight: 100 }}
              />

              <div className="mt-2 mx-3">
                <h5 className="text-warning">Risco Zero!</h5>
                <h6>
                  Caso não se sinta satisfeito, devolvemos 100% do seu dinheiro,
                  sem burocracia.
                </h6>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PlansRightbar;
