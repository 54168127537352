export const brandPacks = [
  {
    key: "V4company",
    label: "V4 Company",
    header: "#FFFFFF",
    topbar: "#e40e18",
    button_primary: "#e40e18",
    navbar_text_inverse: false,
    logo_url: "https://metrito-public.s3.sa-east-1.amazonaws.com/logo-v4.png",
    logo_url_dark_theme: "https://metrito-public.s3.sa-east-1.amazonaws.com/logo-v4-white.png",
  },
  {
    key: "blackrat",
    label: "Black Rat",
    header: "#FFFFFF",
    topbar: "#000000",
    button_primary: "#1BD760",
    navbar_text_inverse: false,
    logo_url: "https://metrito-public.s3.sa-east-1.amazonaws.com/metrito-blackrat-black.png",
    logo_url_dark_theme: "https://metrito-public.s3.sa-east-1.amazonaws.com/metrito-blackrat-white.png",
    description: `Ao se cadastrar utilizando o link da BlackRat, você terá acesso a funcionalidades especiais e recursos adicionais que não estarão disponíveis para outros usuários. Esses benefícios foram especialmente criados para você, que foi indicado por essa plataforma parceira.`,
  },
  {
    key: "iagopeixoto",
    label: "Iago Peixoto",
    header: "#FFFFFF",
    topbar: "#000000",
    button_primary: "#edaf00",
    navbar_text_inverse: false,
    logo_url: "https://metrito-public.s3.sa-east-1.amazonaws.com/metrito-black-vector.svg",
    logo_url_dark_theme: "https://metrito-public.s3.sa-east-1.amazonaws.com/metrito-white-vector.svg",
    custom_image_url: "https://metrito-public.s3.sa-east-1.amazonaws.com/iago_peixoto_sorrindo.JPG",
    use_custom_image: true,
    description: `Ao se cadastrar utilizando o link do Iago Peixoto, você terá acesso a funcionalidades especiais e recursos adicionais que não estarão disponíveis para outros usuários. Esses benefícios foram especialmente criados para você, que foi indicado por essa plataforma parceira.`,
  },
  {
    key: "HEXA",
    label: "O HEXA VEM!",
    header: "#FFFFFF",
    topbar: "#1e55eb",
    button_primary: "#1e55eb",
    navbar_text_inverse: false,
    logo_url: "https://metrito-public.s3.sa-east-1.amazonaws.com/Metrito+x+Selec%CC%A7a%CC%83o.png",
    logo_url_dark_theme: "https://metrito-public.s3.sa-east-1.amazonaws.com/Metrito+x+Selec%CC%A7a%CC%83o+(light).png",
  },
  {
    key: "SUSPENDED_ACCOUNT",
    label: "Conta suspensa",
    header: "#e2022f",
    topbar: "#e2022f",
    button_primary: "#e2022f",
    navbar_text_inverse: true,
    logo_url: "https://metrito-public.s3.sa-east-1.amazonaws.com/metrito-white-vector.svg",
    logo_url_dark_theme: "https://metrito-public.s3.sa-east-1.amazonaws.com/metrito-white-vector.svg",
  },
];
