import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Card } from "reactstrap";
import { Avatar, Button } from "rsuite";
import { account_status_list } from "../common/data/account_statuses";
import { data_sources } from "../common/data/data_sources";
import { fb_payment_types } from "../common/data/fb_ads_payment_types";
import api from "../services/api";
import AccountNameWithAliasSupport from "../components/Common/AccountNameWithAliasSupport";
import { getFacebookPaymentType } from "../common/data/fb_ads_payment_types";
import CardBrandSvg from "../pages/Gateways/CardBrandSvg";

const StatusCard = (props) => {
  const [adAccountsData, setAdAccountsData] = useState([]);
  const { brandId } = useParams();
  const [status, setStatus] = useState("all");

  const statusOrder = { 2: 1, 3: 2, 1: 3, error: 4 };

  const sortByStatus = (arr) =>
    arr.sort((a, b) => {
      const aStatus = statusOrder[a.account_status];
      const bStatus = statusOrder[b.account_status];
      return aStatus - bStatus;
    });

  const filterByStatus = (arr) =>
    arr.filter((adAccount) =>
      status !== "all" ? adAccount.account_status === status : adAccount
    );

  const filterAndSort = (arr) => {
    const filtered = filterByStatus(arr);
    return sortByStatus(filtered);
  };

  const thereIsAccounts = (arr) =>
    filterAndSort(arr).length > 0 ? true : false;

  useEffect(() => {
    const fb_ad_accounts = props.ad_accounts.filter(
      (account) => account.data_source === "FA"
    );

    api
      .post("/api/v1/enterprise/fa/account/status", {
        brand_id: brandId || props.brandId,
        ad_accounts: fb_ad_accounts,
      })
      .then((res) => {
        setAdAccountsData(
          res.data.map((account) => ({ ...account, data_source: "FA" }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.ad_accounts]);

  var selected_data_sources = [
    ...new Set(adAccountsData.map((item) => item.data_source)),
  ];

  return (
    <React.Fragment>
      {adAccountsData.length >= 1 ? (
        <div className="d-flex flex-row p-0">
          <Card
            className="d-none d-md-flex flex-column p-3 justify-content-between"
            style={{
              minWidth: 190,
              height: 318,
              border: "none",
              borderRadius: "10px 0px 0px 10px",
              zIndex: 10,
              boxShadow: "10px 0px 10px #00000008",
            }}
          >
            <div>
              <div className="d-flex align-items-center">
                {selected_data_sources.map((o) => (
                  <i
                    className={
                      data_sources.find((i) => i.code === o)?.icon || ""
                    }
                    style={{
                      color:
                        data_sources.find((i) => i.code === o)?.color || "#000",
                      fontSize: 20,
                      marginInlineEnd: 10,
                    }}
                  ></i>
                ))}

                <b
                  className="text-muted fs-6 d-block text-truncate"
                  onClick={() => {
                    console.log(selected_data_sources);
                  }}
                >
                  Contas de Anúncio
                </b>
              </div>
              <b
                style={{
                  fontSize: 40,
                  color:
                    status === 1
                      ? "#4caf50"
                      : status === 3
                      ? "#fa8900"
                      : status === 2
                      ? "#f44336"
                      : status === "error"
                      ? "lightgray"
                      : "",
                }}
              >
                {filterByStatus(adAccountsData).length}
              </b>
              <b className="ps-2">
                <small
                  style={{
                    color:
                      status === 1
                        ? "#4caf50"
                        : status === 3
                        ? "#fa8900"
                        : status === 2
                        ? "#f44336"
                        : status === "error"
                        ? "lightgray"
                        : "",
                  }}
                >
                  {status === 1
                    ? "ATIVAS"
                    : status === 3
                    ? "PROBLEMÁTICAS"
                    : status === 2
                    ? "BLOQUEADAS"
                    : status === "error"
                    ? "TOKEN EXPIRADO"
                    : ""}
                </small>
              </b>
            </div>
            <div>
              <b
                className="text-muted d-block text-truncate"
                style={{ fontSize: 12 }}
              >
                Saldo total
              </b>
              <b style={{ fontSize: 12 }}>
                {adAccountsData
                  .reduce((a, b) => a + (b.balance_details?.balance || 0), 0)
                  .toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    maximumFractionDigits: 2,
                  })}
              </b>
            </div>

            <div>
              <b
                className="text-muted d-block text-truncate"
                style={{ fontSize: 12 }}
              >
                Valor a pagar
              </b>
              <b style={{ fontSize: 12 }}>
                {(
                  adAccountsData.reduce(
                    (a, b) => a + Number(b.balance || 0),
                    0
                  ) / 100
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                  maximumFractionDigits: 2,
                })}
              </b>
            </div>

            <div
              className="d-flex flex-column justify-content-around py-2"
              style={{ fontSize: 12 }}
            >
              <Button
                as={"button"}
                color="green"
                appearance="subtle"
                size="xs"
                active={status === 1 ? true : false}
                onClick={() => setStatus((prev) => (prev === 1 ? "all" : 1))}
                style={{
                  display: "flex",
                  justifyContent: "left",
                  maxWidth: "fit-content",
                  color: status === 1 ? "white" : "#a5e0a4",
                  backgroundColor: status !== 1 ? "unset" : "",
                  background: status !== 1 ? "none" : "",
                }}
              >
                <b className="pe-1">
                  {adAccountsData.filter((o) => o.account_status === 1).length}
                </b>
                <span
                // className="text-success ms-2"
                >
                  Ativas
                </span>
              </Button>
              <Button
                as={"button"}
                color="orange"
                appearance="subtle"
                size="xs"
                active={status === 3 ? true : false}
                onClick={() => setStatus((prev) => (prev === 3 ? "all" : 3))}
                style={{
                  display: "flex",
                  justifyContent: "left",
                  maxWidth: "fit-content",
                  color: status === 3 ? "white" : "#fcc690",
                  backgroundColor: status !== 3 ? "unset" : "",
                  background: status !== 3 ? "none " : "",
                }}
              >
                <b className="pe-1">
                  {adAccountsData.filter((o) => o.account_status === 3).length}
                </b>
                <span
                // className="text-secondary ms-2"
                >
                  Problemáticas
                </span>
              </Button>
              <Button
                as={"button"}
                color="red"
                appearance="subtle"
                size="xs"
                active={status === 2 ? true : false}
                onClick={() => setStatus((prev) => (prev === 2 ? "all" : 2))}
                style={{
                  display: "flex",
                  justifyContent: "left",
                  maxWidth: "fit-content",
                  color: status === 2 ? "white" : "#faa9a7",
                  backgroundColor: status !== 2 ? "unset" : "",
                  background: status !== 2 ? "none" : "",
                }}
              >
                <b className="pe-1">
                  {adAccountsData.filter((o) => o.account_status === 2).length}
                </b>
                <span
                // className="text-danger ms-2"
                >
                  Bloqueadas
                </span>
              </Button>
              <Button
                as={"button"}
                appearance="subtle"
                size="xs"
                active={status === "error" ? true : false}
                onClick={() =>
                  setStatus((prev) => (prev === "error" ? "all" : "error"))
                }
                style={{
                  display: "flex",
                  justifyContent: "left",
                  maxWidth: "fit-content",
                  backgroundColor: status !== "error" ? "unset" : "",
                  background: status !== "error" ? "none" : "",
                }}
              >
                <b className="pe-1">
                  {adAccountsData.filter((o) => o.status === "error").length}
                </b>
                <span
                // className="text-muted ms-2"
                >
                  Token Expirado
                </span>
              </Button>
            </div>
          </Card>

          <div
            className="d-flex flex-row align-items-center px-3 py-0 w-100"
            style={{ overflowX: "scroll", height: 318 }}
          >
            {thereIsAccounts(adAccountsData) &&
              filterAndSort(adAccountsData).map((adAccount, key) => {
                const status = account_status_list.find(
                  (o) => o.number === adAccount.account_status
                );
                const payment_type = getFacebookPaymentType(
                  adAccount.funding_source_details?.type || 0
                );

                if (!!adAccount.account_status) {
                  return (
                    <Card
                      key={key}
                      style={{
                        minWidth: 300,
                        height: 250,
                        borderRadius: 10,
                      }}
                      className="d-flex flex-column justify-content-start p-3 mx-2 shadow-sm my-auto"
                    >
                      <div
                        id="card-header"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div className="d-flex align-items-center">
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              height: 20,
                              width: 20,
                              backgroundColor: status.color,
                              filter: "brightness(1.1)",
                              borderRadius: "50%",
                            }}
                          >
                            <div
                              style={{
                                height: 10,
                                width: 10,
                                backgroundColor: status.color,
                                filter: "brightness(0.85)",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </div>
                          <div className="d-flex flex-column ms-3">
                            <AccountNameWithAliasSupport
                              account={adAccount}
                              titleSize={6}
                            />
                            <span
                              className="text-muted"
                              style={{ fontSize: 12 }}
                            >
                              {status.label}
                            </span>
                          </div>
                        </div>
                      </div>

                      <hr className="divider my-2" />

                      <div className="mt-2">
                        <div style={{ fontSize: 13 }}>
                          <b className="me-2">Total já gasto:</b>
                          <span>
                            {Number(
                              adAccount.amount_spent / 100
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                        <div style={{ fontSize: 13 }}>
                          <b className="me-2">A pagar:</b>
                          <span>
                            {Number(adAccount.balance / 100).toLocaleString(
                              "pt-BR",
                              {
                                style: "currency",
                                currency: "BRL",
                                maximumFractionDigits: 2,
                              }
                            )}
                          </span>
                        </div>
                        <div style={{ fontSize: 13 }}>
                          <b className="me-2">Saldo:</b>
                          <span>
                            {Number(
                              adAccount.balance_details?.balance
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                        <div style={{ fontSize: 13 }} className="d-flex">
                          <b className="me-2">Pagamento:</b>
                          {payment_type.number === 1 &&
                          adAccount.funding_source_details.display_string ? (
                            <div className="d-flex align-items-center">
                              <CardBrandSvg
                                cardBrand={
                                  adAccount.funding_source_details.display_string.split(
                                    " "
                                  )[0]
                                }
                              />
                              <span className="ms-2">
                                *{" "}
                                {adAccount.funding_source_details.display_string
                                  .split(" ")[1]
                                  .replace("*", "")}
                              </span>
                            </div>
                          ) : (
                            <span className="text-truncate">
                              <i
                                className={`${payment_type.icon} text-muted me-1`}
                              />
                              {payment_type.label}
                            </span>
                          )}
                        </div>

                        {/* <div
                        className="mt-3 px-3 d-flex align-items-center"
                        style={{
                          border: "1px dashed lightgray",
                          borderRadius: 5,
                        }}
                      >
                        <i
                          className={`${payment_type.icon} text-primary me-3 fs-2`}
                        />
                        <b>
                          {payment_type.number === 1 ? (
                            <>
                              <span
                                className="text-muted d-block"
                                style={{ fontSize: 12 }}
                              >
                                {payment_type.label}
                              </span>
                              <b>
                                {
                                  adAccount.funding_source_details
                                    ?.display_string
                                }
                              </b>
                            </>
                          ) : (
                            payment_type.label
                          )}
                        </b>
                      </div> */}

                        <hr className="divider my-2" />

                        <div
                          className="d-flex align-items-center"
                          style={{ padding: 5 }}
                        >
                          <Avatar
                            size="sm"
                            circle
                            src={adAccount.profile.picture.data.url}
                            alt="@SevenOutman"
                            className="fb-profile-picture"
                          />
                          <div className="d-flex flex-column ms-2">
                            <span
                              className="text-muted"
                              style={{ fontSize: 12 }}
                            >
                              Perfil
                            </span>
                            <b className="fb-profile-name">
                              {adAccount.profile.name}
                            </b>
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                } else {
                  return (
                    <Card
                      key={key}
                      style={{
                        maxWidth: 400,
                        minWidth: 400,
                        height: 250,
                        borderRadius: 10,
                      }}
                      className="d-flex flex-column justify-content-start p-3 mx-2 shadow-sm my-auto"
                    >
                      <div
                        id="card-header"
                        className="d-flex align-items-center"
                      >
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            height: 20,
                            width: 20,
                            backgroundColor: "lightgray",
                            borderRadius: "50%",
                          }}
                        >
                          <div
                            style={{
                              height: 10,
                              width: 10,
                              backgroundColor: "gray",
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                        <div className="d-flex flex-column ms-3">
                          <b className="fs-5" style={{ fontWeight: 1000 }}>
                            {adAccount.name}
                          </b>
                          <span className="text-muted" style={{ fontSize: 12 }}>
                            O Token de conexão ao Facebook Expirou
                          </span>
                        </div>
                      </div>

                      <div
                        className="bg-light my-4 mx-2 p-3 px-2 text-center"
                        style={{
                          borderRadius: 10,
                          outline: "2px solid #020202",
                        }}
                      >
                        <h6>Token de conexão expirado</h6>
                        <span style={{ fontSize: 13 }}>
                          O Facebook costuma revogar tokens periodicamente por
                          questões de segurança, portanto nós o convidamos a{" "}
                          <b>reconectar essa conta na aba”conexões”</b>.{" "}
                          <br></br> Obrigado pela compreensão!
                        </span>
                      </div>
                    </Card>
                  );
                }
              })}
            {!thereIsAccounts(adAccountsData) && (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  height: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                Não foi encontrada nenhuma conta com status "
                {status === 1
                  ? "Ativa"
                  : status === 3
                  ? "Problemática"
                  : status === 2
                  ? "Bloqueada"
                  : status === "error"
                  ? "Token expirado"
                  : ""}
                "
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center text-muted"
          style={{
            height: 318,
            border: /builder/.test(window.location.href)
              ? "3px solid #F2A23B"
              : "none",
            borderRadius: 10,
          }}
        >
          <img
            src="https://cdn-icons-png.flaticon.com/512/4865/4865066.png"
            alt="selecione contas de anúncio"
            className="mb-3"
            style={{ maxWidth: 50, filter: `opacity(0.3) hue-rotate(90deg)` }}
          />
          <span className="align-middle">
            Você precisa selecionar Contas de Anúncio
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ad_accounts: state.Analytics.ad_accounts,
});

export default connect(mapStateToProps)(StatusCard);
