import SalesAnalytics from "../widgetsV2/SalesAnalytics";
import ColumnChart from "../widgetsV2/ColumnChart";
import BarChart from "../widgetsV2/BarChart";
import DonutChart from "../widgetsV2/DonutChart";
import CampaignsTable from "../widgetsV2/CampaignsTable";
import MetricCards from "../widgetsV2/MetricsCards";
import LineChart from "../widgetsV2/LineChart";
import AreaChart from "../widgetsV2/AreaChart";
import AccountStatus from "../widgetsV2/AccountStatus";
import FreeText from "../widgetsV2/FreeText";

export const defineComponentV2 = (props, param) => {
  switch (parseInt(param.i)) {
    case 1:
      return <AccountStatus ad_accounts={props.ad_accounts} />;
      break;
    case 2:
      return (
        <MetricCards
          metrics={props.metrics}
          summary={props.summary}
          widget={param}
        />
      );
      break;
    case 3:
      return <CampaignsTable campaigns={props.campaigns} />;
      break;

    case 4:
      return (
        <SalesAnalytics
          maxMetric={12000}
          metrics={props.metrics}
          summary={props.summary}
          selected={param}
        />
      );
      break;
    case 5:
      return <ColumnChart metrics={props.metrics} selected={param} />;
      break;
    case 6:
      return (
        <BarChart
          summary={props.summary}
          metrics={props.metrics}
          selected={param}
        />
      );
      break;
    case 7:
      return <LineChart metrics={props.metrics} selected={param} />;
      break;
    case 8:
      return (
        <AreaChart
          metrics={props.metrics}
          summary={props.summary}
          selected={param}
        />
      );
      break;
    case 9:
      return (
        <DonutChart
          metrics={props.metrics}
          summary={props.summary}
          selected={param}
        />
      );
      break;
    case 10:
      return <FreeText widget={param} />;
    default:
      return <h1>Erro ao carregar widget.</h1>;
      break;
  }
};
