const INITIAL_STATE = {
  brands: [],
  recentlyCreatedBrand: {},
};

function BrandsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_BRANDS":
      return {
        ...state,
        brands: action.brands,
      };
      break;
    case "SET_RECENT_BRAND":
      return {
        ...state,
        recentlyCreatedBrand: action.brand,
      };
      break;
    default:
      return state;
      break;
  }
}

export default BrandsReducer;
