import React, { useState } from "react";
import { CardBody, Col } from "reactstrap";
// import SalesAnalyticsChart from "../AllCharts/SalesAnalyticsChart";
import AnalyticsChart from "../widgetsV2/AnalyticsChart";

const metricsList = [
  {
    id: 1,
    name: "Investimento",
    value: "spend",
  },
  {
    id: 2,
    name: "CPM",
    value: "cpm",
  },
  {
    id: 3,
    name: "Impressões",
    value: "impressions",
  },
];

const SalesAnalytics = (props) => {
  const [selectedMetric, setSelectedMetric] = useState(metricsList[0]);

  return (
    <React.Fragment>
      <Col xl={12} lg={12}>
        <CardBody>
          {/* <div className="float-end">
              <Dropdown isOpen={menu} toggle={() => setMenu(!menu)}>
                <DropdownToggle className="dropdown-toggle text-reset" tag="a">
                  <span className="fw-semibold">Filtrar por:</span>{" "}
                  <span className="text-muted">
                    {selectedMetric.name}
                    <i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-end">
                  {metricsList.map((m) => (
                    <DropdownItem
                      key={m.id}
                      onClick={() => {
                        setSelectedMetric(m);
                      }}
                    >
                      {m.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div> */}

          <h4 className="card-title mb-4">{props.selected.title}</h4>

          <div className="mt-1 scroll-horizontal">
            <ul
              className="list-inline main-chart mb-0 scrollbar-hidden"
              style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <li className="list-inline-item chart-border-left me-0 border-0">
                <h3 className="text-primary">
                  R$&nbsp;
                  <span data-plugin="counterup">
                    {parseFloat(props.summary?.spend || 0).toLocaleString(
                      "pt-BR"
                    )}
                  </span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    Investimento Total
                  </span>
                </h3>
              </li>

              <li className="list-inline-item chart-border-left me-0 border-0">
                <h3 className="text-primary">
                  R$&nbsp;
                  <span data-plugin="counterup">
                    {parseFloat(
                      props.summary?.action_values?.find(
                        (a) =>
                          a.action_type ===
                          "offsite_conversion.fb_pixel_purchase"
                      )?.value || 0
                    ).toLocaleString("pt-BR") || 0}
                  </span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    Retorno Total
                  </span>
                </h3>
              </li>

              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">
                    {parseFloat(
                      props.summary?.website_purchase_roas?.find(
                        (a) =>
                          a.action_type ===
                          "offsite_conversion.fb_pixel_purchase"
                      )?.value || 0
                    ).toFixed(2)}
                  </span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    ROAS
                  </span>
                </h3>
              </li>

              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">
                    {parseInt(
                      props.summary?.actions?.find(
                        (a) =>
                          a.action_type ===
                          "offsite_conversion.fb_pixel_initiate_checkout"
                      )?.value || 0
                    ).toLocaleString("pt-BR")}
                  </span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    Início de Finalização de Compra
                  </span>
                </h3>
              </li>
              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">
                    {(props.summary?.actions &&
                      parseInt(
                        props.summary?.actions?.find(
                          (a) =>
                            a.action_type ===
                            "offsite_conversion.fb_pixel_purchase"
                        )?.value || 0
                      )) ||
                      0}
                  </span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    Compra
                  </span>
                </h3>
              </li>
            </ul>
          </div>

          <div className="mt-3">
            <AnalyticsChart
              selectedMetric={selectedMetric}
              maxMetric={props.maxMetric}
              metrics={props.metrics}
              metric={props.metric}
              lines={props.selected.lines}
            />
          </div>
        </CardBody>
      </Col>
    </React.Fragment>
  );
};

export default SalesAnalytics;
