import React from "react";

import ReactApexChart from "react-apexcharts";

const AdminLineChart = (props) => {
  const series = [
    {
      name: "Transações",
      data: Object.values(props.data)
        .map((o) =>
          [].concat
            .apply([], o)
            .filter((o) => o.status === "succeeded")
            .map((o) => o.amount_captured)
            .reduce((a, b) => a + b, 0)
        )
        .reverse(),
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "straight",
      colors: ["#1e55eb"],
    },
    grid: {
      row: {
        colors: ["#ebebeb50", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      labels: { show: true },
      categories: Object.keys(props.data).sort(
        (a, b) => new Date(a) - new Date(b)
      ),
    },
    yaxis: {
      min: 0,
      labels: { show: true },

      labels: {
        formatter: function (y) {
          return (y / 100).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
    },

    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          return (y / 100).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="240"
      style={{ marginTop: -20, marginLeft: -20, marginBottom: -20, padding: 0 }}
    />
  );
};

export default AdminLineChart;
