import { Tooltip, Whisper } from "rsuite";

export const CustomTooltip = ({ placement, text }) => (
  <Whisper
    trigger="click"
    placement={placement}
    controlId={`control-id-${placement}`}
    speaker={<Tooltip className="ms-0">{text}</Tooltip>}
    onClick={(e) => e.stopPropagation()}
  >
    <span>
      <i
        id="Popoverright"
        className="bx bxs-help-circle mx-2 mb-1 align-middle i-btn fs-5 mb-0"
        role="button"
      />
    </span>
  </Whisper>
);
