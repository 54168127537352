export function fbSetLoading(loading) {
  return {
    type: "SET_LOADING",
    loading,
  };
}

export function fbSetAdaccount(adaccount) {
  return {
    type: "SET_ADACCOUNT",
    adaccount,
  };
}

export function fbSetMetrics(metrics, summary) {
  return {
    type: "SET_METRICS",
    metrics,
    summary,
  };
}

export function fbSetCampaigns(campaigns) {
  return {
    type: "SET_CAMPAIGNS",
    campaigns,
  };
}

export function fbSetActiveAdAccount(activeAdAccount) {
  return {
    type: "SET_ACTIVE_ADACCOUNT",
    activeAdAccount,
  };
}

export function fbClean() {
  return {
    type: "CLEAN_FB",
  };
}
