import React, { useState } from "react";
import MetaTags from "react-meta-tags";

import { Row, Col, Alert, Container, Card } from "reactstrap";

//Toast
import { toast } from "react-toastify";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logoIcon from "../../assets/images/logo-sm.svg";
import logoVector from "../../assets/images/metrito-vector.svg";

import api from "../../services/api";
import {
  getIdUsuario,
  getNomeUsuario,
  login,
  setCreatedAt,
  setIdUsuario,
  setNomeUsuario,
  setProfilePictureURL,
} from "../../services/auth";
import { Loader } from "rsuite";
import $ from "jquery";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/ok_lottie.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Login = (props) => {
  $("html").css("height", "100%");
  $("body").css("height", "100%");

  const [loading, setLoading] = useState(false);
  const [LoginSuccess, setLoginSuccess] = useState(false);
  const [userData, setUserData] = useState({});

  // handleSubmit - Login
  async function handleSubmit(event) {
    setLoading(true);

    //Toast loading
    const id = toast.loading("Verificando dados...");

    event.preventDefault();
    const email = event.target.email.value;
    const senha = event.target.password.value;

    await api
      .post("/api/usuarios/login", { email, senha })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 1) {
            //GTM - Login Event
            window.dataLayer.push({ event: "login", email: email });

            setProfilePictureURL(res.data.profile_pic);

            //Toast success
            toast.update(id, {
              render: "Logado com sucesso!",
              type: "success",
              isLoading: false,
            });

            // ... localstorage
            login(res.data.token);
            setIdUsuario(res.data.id_client);
            setNomeUsuario(res.data.user_name);
            setCreatedAt(new Date(res.data.createdAt).getTime());
            setUserData(res.data);

            if (res.data.branding_pack) {
              window.localStorage.setItem(
                "branding_pack",
                res.data.branding_pack
              );
            }

            setLoginSuccess(true);

            setTimeout(() => {
              api
                .put("/api/usuarios/last-login/" + res.data.id_client)
                .then((res2) => {
                  console.log("Last login updated");

                  if (res.data.onboarding) {
                    console.log("onboarding");
                    window.location.href = "/onboarding";
                  } else {
                    if (res.data.admin) {
                      console.log("admin");
                      window.location.href = "/admin/panel";
                    } else {
                      console.log("home");
                      window.location.href = "/home";
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }, 1500);

            setLoading(false);
          } else if (res.data.status === 2) {
            //Toast error
            toast.update(id, {
              render: "Usuário ou senha inválidos.",
              type: "warning",
              isLoading: false,
              autoClose: 2000,
            });

            setLoading(false);
          }
        } else {
          toast.update(id, {
            render: "Erro no servidor (x500)",
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });

          setLoading(false);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  $("body").addClass("");

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Metrito</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-3ewvariant h2"></i>
        </Link>
      </div>

      <Container fluid style={{ height: "100%", width: "100%" }}>
        <Row className="d-flex justify-content-center align-items-center h-100">
          <Col lg={4}></Col>
          <Col lg={4} md={6}>
            <div className="text-center mb-5">
              <Link to="/" className="auth-logo">
                <img
                  src={logoVector}
                  alt=""
                  height="40"
                  className="auth-logo-dark d-none d-md-block"
                />
                <img
                  src={logoIcon}
                  alt=""
                  height="38"
                  className="auth-logo-light d-block d-md-none"
                />
              </Link>
              <p className="font-size-15 text-primary mt-3">
                Análise, Clareza e <b>Crescimento</b>.
              </p>
            </div>
            <Card
              style={{
                maxWidth: 800,
                boxShadow: "0px 0px 30px 10px #eaeff7d1",
              }}
            >
              <Row>
                <Col lg={12}>
                  {LoginSuccess ? (
                    <div className="d-flex flex-column justify-content-center text-center align-items-center w-100 p-5">
                      <Lottie
                        options={defaultOptions}
                        height={"auto"}
                        width={200}
                        style={{ margin: "none" }}
                      />
                      <h4>
                        Bem-vindo(a) de volta,{" "}
                        {userData.user_name.split(" ")[0]}!
                      </h4>
                    </div>
                  ) : (
                    <div className="p-lg-5 p-4">
                      <div>
                        <h5>Login na Plataforma</h5>
                        <p className="text-muted">
                          Entre agora na sua conta Metrito.
                        </p>
                      </div>
                      <div className="mt-4 pt-3">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={handleSubmit}
                        >
                          {props.error && typeof props.error === "string" ? (
                            <Alert color="danger">{props.error}</Alert>
                          ) : null}

                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="E-mail"
                              value=""
                              className="form-control"
                              placeholder="Seu e-mail"
                              type="email"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Por favor, digite seu E-mail",
                                },
                                email: true,
                              }}
                            />
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                Esqueceu a senha?
                              </Link>
                            </div>
                            <AvField
                              name="password"
                              label="Senha"
                              value=""
                              type="password"
                              placeholder="Sua senha"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Por favor, digite sua senha",
                                },
                              }}
                            />
                          </div>

                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Lembrar de mim
                            </label>
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-success w-100 waves-effect waves-light btn-100 px-5 d-flex align-items-center justify-content-center"
                              type="submit"
                              disabled={loading}
                            >
                              {loading && (
                                <Loader inverse className="me-2"></Loader>
                              )}
                              <b className="text-white">Entrar</b>
                            </button>
                          </div>
                          <div className="mt-4 text-center">
                            <p className="mb-0">
                              Ainda não tem uma conta ?{" "}
                              <a
                                href="/register"
                                className="fw-medium text-primary"
                              >
                                {" "}
                                <b> Cadastre-se grátis agora!</b>
                              </a>{" "}
                            </p>
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Card>
            <div className="mt-5 text-center text-dark">
              <p>
                © {new Date().getFullYear()} <b>metrito.</b>
              </p>
            </div>
          </Col>
          <Col lg={4}></Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Login;
