import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Placeholder,
} from "reactstrap";
import { Modal, ButtonToolbar, Button } from "rsuite";
import api from "../../services/api";
import RemindIcon from "@rsuite/icons/legacy/Remind";
import { gateways } from "../../common/data/data_sources";
import { motion } from "framer-motion";

const GatewayConnections = (props) => {
  const [selectedGatewayConnection, setSelectedGatewayConnection] =
    useState(null);
  const [removing, setRemoving] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function removeGatewayConnection(gatewayConnectionId) {
    console.log(
      `Removing GatewayConnection ${gatewayConnectionId} from brand ${props.brand._id}`
    );
    api
      .delete(`/api/gateway/remove/${props.brand._id}/${gatewayConnectionId}`)
      .then((res) => {
        toast.success("Gateway removido com sucesso!");
        console.log("Gateway connection removed");
        console.log(res.data);
        props.loadBrandData();
        handleClose();
      })
      .catch((err) => {
        toast.error("Erro ao remover gateway!");
        console.log(err);
      });
  }

  return (
    <>
      <Card>
        <CardBody>
          <div>
            <div className="d-flex flex-row ps-3 justify-content-between align-items-center">
              <div className="d-flex flex-row">
                <div className="me-3 pt-1">
                  <i className="bx bx-dollar-circle fs-1 text-dark" />
                </div>
                <div>
                  <h4 className="card-title">Gateways de Pagamento</h4>
                  <p className="card-title-desc">
                    Todas gateways de pagamento vinculadas a esta empresa.
                  </p>
                </div>
              </div>
              <div>
                <motion.button
                  className="btn btn-primary p-2 px-5"
                  whileHover={{
                    scale: 1.05,
                    x: -10,
                    transition: { duration: 0.1 },
                  }}
                  onClick={() =>
                    window.open("/gateways/panel/" + props.brand._id)
                  }
                >
                  Painel de vendas
                  <i className="mdi mdi-arrow-right ms-2" />
                </motion.button>
              </div>
            </div>
            <div className="d-flex flex-row w-100">
              {props.loading ? (
                <div className="d-flex">
                  <Card style={{ width: 300 }} className="me-2">
                    <CardBody>
                      <Placeholder.Paragraph graph="circle" />
                    </CardBody>
                  </Card>
                  <Card style={{ width: 300 }} className="me-2">
                    <CardBody>
                      <Placeholder.Paragraph graph="circle" />
                    </CardBody>
                  </Card>
                  <Card style={{ width: 300 }} className="me-2">
                    <CardBody>
                      <Placeholder.Paragraph graph="circle" />
                    </CardBody>
                  </Card>
                </div>
              ) : (
                <div
                  className="d-flex flex-row w-100"
                  style={{ overflowX: "scroll" }}
                >
                  <motion.div
                    className="card"
                    style={{
                      minWidth: 300,
                      margin: 5,
                      height: 124,
                    }}
                    whileHover={{
                      scale: 0.975,
                      transition: { duration: 0.2 },
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      (window.location.href =
                        "/gateways/connect?brand=" + props.brand._id)
                    }
                  >
                    <CardBody
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        padding: 10,
                      }}
                    >
                      <i className="text-muted bx bx-plus-circle fs-4 me-2"></i>
                      <b className="text-muted">Nova Conexão</b>
                    </CardBody>
                  </motion.div>
                  {props.brand.gateways &&
                    props.brand.gateways.map((gateway) => {
                      const data_source = gateways.find(
                        (item) => item.code === gateway.data_source
                      );
                      return (
                        <motion.div
                          className="card"
                          style={{
                            minWidth: 300,
                            margin: 5,
                          }}
                          whileHover={{
                            scale: 0.975,
                            transition: { duration: 0.2 },
                          }}
                        >
                          <CardBody className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex-column w-100">
                              <div className="d-flex justify-content-between mb-3 w-100">
                                <img
                                  src={data_source.icon_url}
                                  alt={data_source.label}
                                  style={{ height: 20 }}
                                  className="me-3"
                                />
                                <UncontrolledDropdown>
                                  <DropdownToggle role="button" tag="div">
                                    <i className="bx bx-dots-vertical-rounded i-btn fs-3 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="text-muted dropdown-menu-end">
                                    <DropdownItem
                                      className="text-dark"
                                      onClick={() => {
                                        setSelectedGatewayConnection(gateway);
                                        handleOpen();
                                      }}
                                    >
                                      <i className="mdi mdi-close-circle-outline me-2" />
                                      Remover conexão
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                              <div className="d-flex flex-column">
                                <b className="text-truncate">{gateway.name}</b>
                                <span
                                  className="text-muted"
                                  style={{ fontSize: 12 }}
                                >
                                  {data_source.label}
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </motion.div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      <Modal
        backdrop="static"
        role="alertdialog"
        open={open}
        onClose={handleClose}
        size="xs"
      >
        <Modal.Body>
          <div className="text-center w-100 mb-4">
            <RemindIcon style={{ color: "#ffb300", fontSize: 30 }} />
          </div>
          Quando uma conexão é desativada você não poderá mais ver os dados
          referentes a esta conta no aplicativo. Você tem certeza quer
          prosseguir?
        </Modal.Body>
        <Modal.Footer>
          <Button
            loading={removing}
            onClick={() => {
              removeGatewayConnection(selectedGatewayConnection._id);
            }}
            appearance="primary"
          >
            Sim
          </Button>
          <Button disabled={removing} onClick={handleClose} appearance="subtle">
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GatewayConnections;
