export const programs = [
  {
    name: "BlackRat",
    description: "benefícios",
    alias: "blackrat",
    icon: "https://hotmart.s3.amazonaws.com/product_pictures/ff7408c2-36c5-4c01-a488-2faa724b11f6/semfundo.png",
    bgColor: "#000",
    color: "#fff",
  },
  {
    name: "Alunos do Iago Peixoto",
    description: "benefícios",
    alias: "iagopeixoto",
    icon: "https://hotmart.s3.amazonaws.com/product_pictures/ff7408c2-36c5-4c01-a488-2faa724b11f6/semfundo.png",
    bgColor: "#000",
    color: "#fff",
  },
];
