import React, { useState } from "react";
import InputMask from "react-input-mask";
import { Alert, Col, Input, Modal, Row } from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

import { getIdUsuario } from "../../services/auth";

//Toast
import { toast } from "react-toastify";

//services
import api from "../../services/api";

import { getImageURL } from "../../functions/getImageURL";

import UploadFileModal from "../../components/Common/UploadFile.modal";

const EditUserModal = (props) => {
  const [modal_xlarge, setmodal_xlarge] = useState(props.isModalOpen);
  const [regStatus, setRegStatus] = useState("");

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  async function handleSubmit(event) {
    try {
      //Toast loading
      const id = toast.loading("Realizando operação...");

      const currentData = {
        _id: getIdUsuario(),
        name: props.userInfo.name,
        email: props.userInfo.email,
        tel_number: props.userInfo.tel_number,
        address: props.userInfo.address,
        password: "",
      };

      const newData = {
        _id: getIdUsuario(),
        name: event.target.name.value,
        email: event.target.email.value,
        tel_number: event.target.tel.value,
        address: event.target.address.value,
      };

      if (event.target.password.value.replace(/\s/g, "").length > 0) {
        newData.password = event.target.password.value;
      }

      // prepare data to be sent to the server
      const dataToSend = { _id: newData._id };

      for (const key in currentData) {
        if (currentData[key] !== newData[key]) {
          dataToSend[key] = newData[key];
        }
      }

      const response = await api.put("/api/usuarios", dataToSend);

      if (response.status === 200) {
        //Toast success
        toast.update(id, {
          render: "Dados atualizados!",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });

        setTimeout(() => tog_xlarge(), 1000);

        window.location.reload();
      } else {
        //Toast success
        toast.update(id, {
          render: "Erro na operação",
          type: "warning",
          isLoading: false,
          autoClose: 1000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <React.Fragment>
      <Col lg={12}>
        <i
          role="button"
          className="bx bxs-pencil fs-4 float-end i-btn"
          onClick={() => {
            tog_xlarge();
          }}
        />
        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={modal_xlarge}
          toggle={() => {
            tog_xlarge();
          }}
        >
          <Row className="g-0">
            <Col lg={12}>
              <div className="p-lg-5 p-4">
                <div>
                  <h5>Atualizar Informações</h5>
                </div>

                <div className="mt-4 d-flex flex-row align-items-center w-100">
                  <div className="d-flex justify-content-center">
                    <img
                      src={getImageURL(props.userInfo.profile_pic)}
                      alt=""
                      className="avatar-md img-thumbnail rounded-circle"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div>
                    <UploadFileModal
                      button_text={"Editar Foto de Perfil"}
                      image_uri={props.userInfo.profile_pic}
                    />
                  </div>
                </div>

                <div className="pt-3">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={handleSubmit}
                  >
                    {regStatus === "success" ? (
                      <Alert color="success">
                        Usuário atualizado com sucesso
                      </Alert>
                    ) : null}

                    {regStatus === "error" ? (
                      <Alert color="danger">Erro ao atualizar usuário</Alert>
                    ) : null}

                    <div className="mb-3">
                      <AvField
                        name="name"
                        label="Nome"
                        type="text"
                        required
                        placeholder="Insira seu nome"
                        value={props.userInfo.name}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Por favor, digite seu Nome",
                            pattern: {
                              value: "^[A-Za-z0-9]+$",
                              errorMessage:
                                "Seu nome deve ser formado apenas de letras e números",
                            },
                          },
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        id="email"
                        name="email"
                        label="E-mail"
                        className="form-control"
                        placeholder="Inisira seu email"
                        type="email"
                        autoComplete="email"
                        readOnly
                        value={props.userInfo.email}
                        required
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Por favor, digite seu E-mail",
                          },
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="tel"
                        label="Número de Telefone"
                        placeholder="Insira seu número de telefone"
                        mask="(99) 99999-9999"
                        maskChar=""
                        tag={[Input, InputMask]}
                        value={props.userInfo.tel_number}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Por favor, digite seu telefone.",
                          },
                          minLength: {
                            value: 15,
                            errorMessage: "Por favor, digite um número válido.",
                          },
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="document"
                        label="Documento de Identificação"
                        type="text"
                        readOnly
                        placeholder="Insira seu número de CPF ou CNPJ (Apenas números)"
                        value={props.userInfo.payment_info?.document ?? "-"}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="address"
                        label="Endereço"
                        type="address"
                        placeholder="Insira seu endereço"
                        value={props.userInfo.address}
                      />
                    </div>

                    <hr className="divider" />

                    <h5>Redefinir Senha</h5>

                    <div className="mb-3 mt-4">
                      <AvField
                        name="password"
                        label="Senha"
                        type="password"
                        placeholder="Insira uma nova senha"
                        validate={{
                          required: {
                            value: false,
                            errorMessage: "Por favor, digite uma senha",
                          },
                          minLength: {
                            value: 8,
                            errorMessage:
                              "Por favor, digite uma senha com mais de 8 caracteres",
                          },
                        }}
                      />
                      <AvField
                        name="confirmationPassword"
                        label="Repita a Senha"
                        type="password"
                        autoComplete="off"
                        validate={{
                          required: {
                            value: false,
                            errorMessage: "Por favor, repita a senha",
                          },
                          match: { value: "password" },
                        }}
                      />
                    </div>

                    <div className="mt-3 text-end">
                      <button
                        className="btn btn-primary w-sm waves-effect waves-light"
                        type="submit"
                      >
                        Atualizar Dados
                      </button>
                    </div>
                  </AvForm>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      </Col>
    </React.Fragment>
  );
};

export default EditUserModal;
