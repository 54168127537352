import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Label } from "reactstrap";
import { Form, Button, ButtonToolbar, Panel, Uploader, Input, InputGroup } from "rsuite";
import { connect } from "react-redux";
import { changeLayout, changeSidebarType } from "../../store/actions";
import { MetaTags } from "react-meta-tags";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { getIdUsuario } from "../../services/auth";
import api from "../../services/api";
import { copyToClipboard } from "../../functions/copyToClipboard";
import { parseTextWithURLs } from "../../functions/parseTextWithURLs";

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

const CreateWAMessages = (props) => {
  const [webhookURL, setWebhookURL] = useState("https://v1.metrito.com/api/v2/crm/bitrix/webhook");
  const [messages, setMessages] = useState([]);
  const [formValue, setFormValue] = useState({ key: "", messages: [] });

  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleChange = (value) => {
    setFormValue(value);
  };

  const handleFormValueChange = (field, value, index) => {
    const updatedMessages = [...formValue.messages];
    updatedMessages[index][field] = value;
    setFormValue({ ...formValue, messages: updatedMessages });
  };

  const handleAddMessage = () => {
    setFormValue({
      ...formValue,
      messages: [
        ...formValue.messages,
        {
          text: "",
          media: "",
        },
      ],
    });
  };

  const createFileObject = (blobFile) => {
    if (!blobFile) return [];

    const fileObj = {
      name: blobFile.name,
      status: "finished",
      url: URL.createObjectURL(blobFile),
      blobFile: blobFile,
    };

    return [fileObj];
  };

  const handleSubmit = async () => {
    // Create a new form data instance
    let formData = new FormData();

    // Append key and creator to the form data
    formData.append("key", `WA_MSG_${formValue.key}`);
    formData.append("creator", getIdUsuario()); // replace with actual creator name

    // Iterate through messages and append files and texts
    for (let i = 0; i < formValue.messages.length; i++) {
      formData.append(`messages[${i}].text`, formValue.messages[i].text);

      if (formValue.messages[i].media instanceof Blob) {
        formData.append(`messages[${i}].media`, formValue.messages[i].media, `media-${i}`);
      }
    }

    // Send a POST request to your server with the form data
    const response = await api
      .post("/api/v2/funnel/wa_messages", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        fetchMessages();
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  };

  const fetchMessages = async () => {
    const response = await api
      .get("/api/v2/funnel/wa_messages")
      .then((response) => {
        console.log(response.data);
        setMessages(response.data);
      })
      .catch((error) => {
        console.log(error.response?.data || error.message);
      });
  };

  useEffect(() => {
    fetchMessages();

    props.changeLayout("vertical");
    props.changeSidebarType("small");
  }, []);

  const default_bitrix_wa_params = `?stage={{Fase (texto)}}&contactId={{Contato: ID do item CRM}}&dealId={{ID do item CRM}}&event=SEND_WA_MESSAGE&messageId=`;

  return (
    <React.Fragment>
      <MetaTags>
        <title>Criar nova mensagem | Metrito</title>
      </MetaTags>

      <div className="page-content">
        <Container>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <h5>Configuração</h5>
                  <hr className="divider" />
                  <Input
                    value={webhookURL}
                    placeholder="URL de destino do Webhook"
                    onChange={(value) => setWebhookURL(`${value}/api/v2/crm/bitrix/webhook`)}
                  />
                  <Input
                    className="mt-3"
                    as="textarea"
                    rows={3}
                    placeholder="Parâmetros de URL (não mudar)"
                    readOnly
                    defaultValue={default_bitrix_wa_params}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h5>
                    <i className="mdi mdi-whatsapp text-success me-2" />
                    Criar nova mensagem de WhatsApp
                  </h5>
                  <div>
                    <Form fluid onChange={handleChange} formValue={formValue}>
                      <Form.Group>
                        <Label>Key</Label>
                        <InputGroup
                          style={{
                            width: 300,
                            marginBottom: 10,
                          }}
                        >
                          <InputGroup.Addon>WA_MSG_</InputGroup.Addon>
                          <Form.Control name="key" />
                        </InputGroup>
                      </Form.Group>

                      <hr className="divider" />

                      <h6>Mensagens</h6>
                      {formValue.messages.map((_, index) => (
                        <Card key={index} bordered className="my-4 p-4 shadow-sm" style={{ backgroundColor: "#a7e2c330" }}>
                          <Form.Group className="d-flex flex-column">
                            <Label>Mídia</Label>
                            {/* <img
                              src={
                                window.URL.createObjectURL(
                                  fileList.length && fileList[0]?.blobFile
                                ) ||
                                "https://cdn-icons-png.flaticon.com/512/4671/4671171.png"
                              }
                              alt=""
                            /> */}

                            <Uploader
                              draggable
                              multiple
                              listType="picture"
                              accept="image/png, image/jpeg"
                              autoUpload={false}
                              onChange={(files) => {
                                if (files.length > 0) {
                                  handleFormValueChange("media", files[0].blobFile, index);
                                } else {
                                  handleFormValueChange("media", "", index);
                                }
                              }}
                              files={fileList[index] || []}
                            >
                              <button>
                                <CameraRetroIcon />
                              </button>
                            </Uploader>
                          </Form.Group>
                          <Form.Group>
                            <Label>Texto</Label>
                            <Form.Control
                              accepter={Textarea}
                              rows={5}
                              name={`messages[${index}].text`}
                              onChange={(value) => handleFormValueChange("text", value, index)}
                            />
                          </Form.Group>
                        </Card>
                      ))}

                      <ButtonToolbar>
                        <Button appearance="primary" onClick={handleAddMessage}>
                          Adicionar mensagem
                        </Button>

                        <Button appearance="primary" onClick={handleSubmit}>
                          Criar
                        </Button>
                      </ButtonToolbar>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <h5>Mensagens</h5>

                  <hr className="divider" />

                  <div className="d-flex flex-column" style={{ height: "70vh", overflowY: "scroll" }}>
                    {messages.map((message, index) => (
                      <Card key={index} bordered className="my-2 p-4 shadow-sm">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                          <span onClick={() => copyToClipboard(null, message.key)}>
                            Código: <b className="copy-to-clipboard">{message.key}</b>
                          </span>

                          <Button
                            size="xs"
                            onClick={() => {
                              copyToClipboard(null, `${webhookURL}${default_bitrix_wa_params}${message.key}`);
                            }}
                          >
                            <i className="mdi mdi-clipboard me-2" /> Copiar código Bitrix
                          </Button>
                        </div>

                        <div className="d-flex flex-column w-100">
                          {message.messages.map((message, index) => (
                            <div key={index} className="d-flex flex-row dashed-border w-100" style={{ backgroundColor: "#a7e2c330" }}>
                              {message.mediaURI && (
                                <img
                                  src={message.mediaURI}
                                  alt=""
                                  style={{
                                    maxWidth: "80px",
                                    objectFit: "contain",
                                    borderRadius: 5,
                                    marginRight: 15,
                                  }}
                                />
                              )}

                              <span style={{ whiteSpace: "pre-wrap" }}>{parseTextWithURLs(message.text)}</span>
                            </div>
                          ))}
                        </div>
                      </Card>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeLayout: (layout) => dispatch(changeLayout(layout)),
  changeSidebarType: (sidebarType) => dispatch(changeSidebarType(sidebarType)),
});

export default connect(null, mapDispatchToProps)(CreateWAMessages);
