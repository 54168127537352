import _ from "lodash";
import api from "./api";
import { toast } from "react-toastify";

export const getGatewaysData = async (gateway_accounts, date_range) => {
  var data = [];

  // date_range = {
  //   since: "YYYY-MM-DD",
  //   until: "YYYY-MM-DD",
  // };

  if (gateway_accounts?.length > 0) {
    console.log(`Fetching data from ${gateway_accounts.length} gateways...`);

    var grouped = _.groupBy(gateway_accounts, "data_source");

    var result = _.map(grouped, function (value, key) {
      return { data_source: key, accounts: value };
    });

    const promiseArray = result.map((item) => {
      return api
        .post(`/api/${item.data_source.toLowerCase()}/orders`, {
          accounts: item.accounts,
          date_range,
        })
        .then((res) => {
          return [...data, { data_source: item.data_source, data: res.data }];
        })
        .catch((err) => {
          toast.error(err.response.data.message, { autoClose: 6000 });

          return err;
        });
    });

    console.time("getGatewaysData");

    const final_result = await Promise.all(promiseArray).then((res) => {
      if (res[0]?.response?.status) {
        console.log(res[0]?.response?.status);
        return { success: false, error: res[0].response.status };
      } else {
        var flatten_data = res
          .flatMap((item) =>
            item.flatMap((account) =>
              account.data.flatMap((account) => account)
            )
          )
          .flatMap((item) =>
            item.data.map((i) => ({ account: item.account, ...i }))
          );

        return flatten_data;
      }
    });

    console.timeEnd("getGatewaysData");

    // console.log("FINAL RESULT: ", final_result)

    return final_result;
  }
};
