import React from "react";

import ReactApexChart from "react-apexcharts";

const AdminAreaChart = (props) => {
  const series = [
    {
      name: props.title,
      data: Object.values(props.data).map((o) => o.length),
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "straight",
      colors: ["#1e55eb"],
    },
    fill: {
      colors: ["#1e55eb"],
    },
    grid: {
      row: {
        colors: ["#ebebeb50", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      labels: { show: true },
      categories: Object.keys(props.data).sort(
        (a, b) => new Date(a) - new Date(b)
      ),
    },
    yaxis: {
      labels: { show: true },

      labels: {
        formatter: function (y) {
          return y;
        },
      },
    },

    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          return y;
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="240"
      style={{ marginTop: -20, marginLeft: -20, marginBottom: -20, padding: 0 }}
    />
  );
};

export default AdminAreaChart;
