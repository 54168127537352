import { endOfDay, startOfDay, subMonths, addMonths } from "date-fns";
import { motion, useAnimation } from "framer-motion";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";
import { MetaTags } from "react-meta-tags";
import { useParams } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Message,
  Tag,
  TagGroup,
  Toggle,
  Whisper,
  Tooltip,
  InputPicker,
} from "rsuite";
import { gateways } from "../../common/data/data_sources";
import { detectMobileBrowser } from "../../functions/detectMobileBrowser";
import { getImageURL } from "../../functions/getImageURL";
import api from "../../services/api";
import SalesTable from "./SalesTable";
import { getGatewaysData } from "../../services/getGatewaysData";

//charts
import SalesCard from "./charts/SalesCard";
import SalesDonutChart from "./charts/SalesDonutChart";
import SalesPieChart from "./charts/SalesPieChart";
import UTMsTable from "./charts/UTMsTable";
import SalesBlurLoader from "./SalesBlurLoader";

import { stages } from "../../common/data/TagStages";

//modal
import { checkMetritoTags } from "../../functions/checkMetritoTags";
import NoMetritoTagsFound from "./modals/NoMetritoTagsFound.modal";

//mock sales data
import { mock_sales } from "../../functions/mock_sales";

function filterOrdersByActiveTag(data, tag) {
  if (tag === "ALL") {
    return _.groupBy(data, (item) => item.metadata.utm_source);
  } else {
    return _.groupBy(data, (item) => {
      var utm_string = item.metadata.utm_source;

      if (utm_string === null) {
        return "Sem Tag";
      }

      var search_index = utm_string
        .split("-")
        .findIndex((o) => o.includes(tag));
      return utm_string
        .split("-")
        .slice(0, search_index + 1)
        .join("-");
    });
  }
}

const SalesPanel = () => {
  const pickerAnchorRef = useRef(null);
  const blur_animation = useAnimation();
  const filter_animation = useAnimation();

  const { brandId } = useParams();

  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState({});
  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const brand_animation = useAnimation();

  //data
  const [mockedDataActive, setMockedDataActive] = useState(false);

  const [rootData, setRootData] = useState([]);

  const [data_source, set_data_source] = useState("ALL");

  const data_source_list = [
    {
      label: "Todas",
      value: "ALL",
    },
    ...[
      ...new Set(rootData.map((item) => item.identification.data_source)),
    ].map((item) => ({
      label: gateways.find((i) => i.code === item).label,
      value: item,
    })),
  ];

  const aggregatedData =
    data_source === "ALL"
      ? rootData
      : rootData.filter(
          (item) => item.identification.data_source === data_source
        );

  const [granularData, setGranularData] = useState([]);
  const [aggregatedByDataSource, setAggregatedByDataSource] = useState([]);
  const [granularByDataSource, setGranularByDataSource] = useState([]);

  const [groupedByUTMSource, setGroupedByUTMSource] = useState([]);
  const [groupedByUTMCampaign, setGroupedByUTMCampaign] = useState([]);

  const [active, setActive] = React.useState("ALL");
  const [filterString, setFilterString] = useState(null);
  const [filters, setFilters] = useState([]);

  const [reqError, setReqError] = useState(false);

  function containsAllSubstrings(string, substrings) {
    return substrings.some(
      (substring) => string !== null && string.includes(substring)
    );
  }

  useEffect(() => {
    if (filters.length) {
      setFilterString(filters.join("-"));
    } else {
      filter_animation.start({
        y: 200,
      });
      setTimeout(() => {
        setFilterString(null);
      }, 1000);
    }
  }, [filters]);

  var filtered_data = filters.length
    ? aggregatedData.filter((item) =>
        containsAllSubstrings(item.metadata.utm_source, filters)
      )
    : filterString !== null
    ? _.filter(aggregatedData, (item) =>
        item.metadata.utm_source?.includes(filterString)
      )
    : aggregatedData;

  function loadBrandData() {
    api
      .get("/api/v1/brands/details/" + brandId)
      .then((res) => {
        setBrand(res.data);

        brand_animation.start({
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.5,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function loadGatewaysData(date_range) {
    setLoading(true);

    var data = mockedDataActive
      ? await mock_sales()
      : await getGatewaysData(brand.gateways, date_range);

    if (data?.success && data?.success === false) {
      setLoading(false);
      setReqError(true);
      return;
    }

    const aggregated_data = Array.isArray(data) ? data : [];
    const aggregated_by_data_source = _.groupBy(
      data,
      (item) => item.identification.data_source
    );
    const granular_data = _.groupBy(data, (item) =>
      moment(item.transaction.created_date).format("YYYY-MM-DD")
    );
    const granular_by_data_source = Object.entries(
      aggregated_by_data_source
    ).map((entry) => ({
      [entry[0]]: _.groupBy(entry[1], (item) =>
        moment(item.transaction.created_date).format("YYYY-MM-DD")
      ),
    }));

    const grouped_by_UTM_source = _.groupBy(
      data,
      (item) => item.metadata.utm_source
    );

    const grouped_by_UTM_campaign = _.groupBy(
      data,
      (item) => item.metadata.utm_campaign
    );

    setRootData(aggregated_data);
    setAggregatedByDataSource(aggregated_by_data_source);
    setGranularData(granular_data);
    setGranularByDataSource(granular_by_data_source);

    setGroupedByUTMSource(grouped_by_UTM_source);
    setGroupedByUTMCampaign(grouped_by_UTM_campaign);

    setLoading(false);
  }

  useEffect(() => {
    loadBrandData();
  }, []);

  useEffect(() => {
    if (brand.gateways?.length > 0 && aggregatedData.length === 0) {
      loadGatewaysData({
        since: moment(new Date()).format("YYYY-MM-DD"),
        until: moment(new Date()).format("YYYY-MM-DD"),
      });
    } else if (brand.gateways?.length === 0) {
      setLoading(false);
      setMockedDataActive(true);
    }
  }, [brand]);

  useEffect(() => {
    filterOrdersByActiveTag(aggregatedData, active);
  }, [active]);

  detectMobileBrowser() &&
    console.log("Mobile Browser Detected") &&
    $("body").addClass("scrollbar-hidden");

  useEffect(() => {
    if (filterString !== null) {
      filter_animation.start({ y: 0 });
    }
  }, [filterString]);

  function getFieldsFromUTMString(utm_string, with_value) {
    if (with_value) {
      return utm_string.split("-");
    } else {
      return utm_string
        .split("-")
        .map((item) => item.split("_")[0].replace(/[\[\]]/, ""));
    }
  }

  var all_fields =
    aggregatedData?.map(
      (item) =>
        item.metadata.utm_source !== null &&
        checkMetritoTags(aggregatedData) &&
        getFieldsFromUTMString(item.metadata.utm_source, false)
    ) || [];

  var all_fields_with_value = aggregatedData.map(
    (item) =>
      (item.metadata.utm_source !== null &&
        checkMetritoTags(aggregatedData) &&
        getFieldsFromUTMString(item.metadata.utm_source, true)) ||
      []
  );
  var unique_fields = _.uniq(_.flatten(all_fields));
  var unique_fields_with_value = _.uniq(_.flatten(all_fields_with_value));

  useEffect(() => {
    setFilterString(null);
    setFilters([]);

    if (!loading) {
      loadGatewaysData({
        since: moment(datePickerState[0].startDate).format("YYYY-MM-DD"),
        until: moment(datePickerState[0].endDate).format("YYYY-MM-DD"),
      });
    }
  }, [mockedDataActive]);

  return (
    <>
      <MetaTags>
        <title>Metrito - Painel de Vendas</title>
      </MetaTags>

      {brand._id && (
        <Row
          className="sales-panel-topbar"
          style={{
            width: "100%",
            minHeight: 70,
            padding: 10,
            zIndex: 10,
            position: "fixed",
            top: !detectMobileBrowser() ? 108 : 60,
            left: 10,
            right: 0,
            paddingLeft: 30,
            paddingRight: 30,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <motion.Col
            className="d-flex col-lg-2 justify-content-end"
            initial={{ opacity: 0, x: -50 }}
            animate={brand_animation}
          >
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 20,
                padding: 5,
                boxShadow: "0px 0px 20px #0000000e",
              }}
            >
              <img
                src={getImageURL(brand.logo || "brand_placeholder.png")}
                alt={brand.name}
                style={{
                  height: 30,
                  borderRadius: 10,
                }}
              />
            </div>
            <div className="d-flex flex-column justify-content-center text-truncate">
              <h5 className="mb-0">{brand.name}</h5>
              <span className="text-muted">{brand.description}</span>
            </div>
          </motion.Col>

          <Col lg={6}>
            <div
              className="d-flex flex-row-reverse align-items-center scrollbar-hidden mt-2 mt-sm-0"
              style={{ overflowX: "scroll" }}
            >
              {brand.gateways?.map((gateway) => {
                const gateway_data = gateways.find(
                  (g) => g.code === gateway.data_source
                );
                return (
                  <div
                    className="d-flex flex-row card align-items-center justify-content-center p-2 m-0"
                    style={{
                      transform: "scale(0.9)",
                      minWidth: 100,
                      flexShrink: 0,
                      minWidth: 0,
                    }}
                  >
                    <img
                      className="gateway-option-icon me-3"
                      src={gateway_data.icon_url}
                      alt={gateway.name}
                      style={{
                        maxHeight: 20,
                        maxWidth: 60,
                        objectFit: "contain",
                      }}
                    />
                    <div className="d-flex flex-column">
                      <span className="text-muted" style={{ fontSize: 12 }}>
                        {gateway_data.label}
                      </span>
                      <b style={{ whiteSpace: "nowrap" }}>{gateway.name}</b>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>

          <Col
            lg={4}
            className="d-flex flex-row align-items-center justify-content-between"
          >
            {data_source_list.length > 2 && (
              <div
                className="d-flex align-items-center my-2 my-sm-0 justify-content-end flex-grow-1 flex-sm-grow-0 flex-sm-shrink-1"
                style={{ position: "relative" }}
                ref={pickerAnchorRef}
              >
                <InputPicker
                  id="filter_input_picker"
                  // cleanable={false}
                  defaultValue={"ALL"}
                  className={"flex-fill"}
                  style={{ minWidth: 100 }}
                  container={() => pickerAnchorRef.current}
                  data={data_source_list}
                  onChange={set_data_source}
                  value={data_source}
                  // renderValue={(value, item, selectedElement) => {
                  //   const campaign_status = statuses.find(
                  //     (o) => o.code === value
                  //   );

                  //   return (
                  //     <div className="d-flex align-items-center">
                  //       {value === "ALL" ? (
                  //         <img
                  //           src="https://static-00.iconduck.com/assets.00/wait-13-icon-256x256-cz2iwps4.png"
                  //           alt="Todas os status"
                  //           className="mx-2"
                  //           style={{ width: 12 }}
                  //         />
                  //       ) : (
                  //         <i
                  //           className="mdi mdi-circle mx-2 "
                  //           style={{
                  //             color: campaign_status?.color || "#000",
                  //             fontSize: 12,
                  //           }}
                  //         />
                  //       )}

                  //       <b className="text-muted">{item.label}</b>
                  //     </div>
                  //   );
                  // }}
                  // renderMenuItem={(label, item) => {
                  //   const campaign_status = statuses.find(
                  //     (o) => o.code === item.value
                  //   );

                  //   return (
                  //     <div className="d-flex align-items-center">
                  //       {item.value === "ALL" ? (
                  //         <img
                  //           src="https://static-00.iconduck.com/assets.00/wait-13-icon-256x256-cz2iwps4.png"
                  //           alt="Todas os status"
                  //           className="mx-2"
                  //           style={{ width: 12 }}
                  //         />
                  //       ) : (
                  //         <i
                  //           className="mdi mdi-circle mx-2 "
                  //           style={{
                  //             color: campaign_status?.color || "#000",
                  //             fontSize: 12,
                  //           }}
                  //         />
                  //       )}

                  //       <b className="text-muted">{item.label}</b>
                  //     </div>
                  //   );
                  // }}
                />
              </div>
            )}

            <UncontrolledDropdown className="mt-3 mt-sm-0 w-100 mx-2">
              <DropdownToggle
                className="btn btn-light w-100"
                type="button"
                disabled={mockedDataActive || loading}
              >
                <span className="float-start text-truncate text-dark">
                  {datePickerState[0].startDate.toLocaleDateString("pt-BR")} -{" "}
                  {datePickerState[0].endDate.toLocaleDateString("pt-BR")}
                </span>
                <i className="mdi mdi-chevron-down text-dark float-end"></i>
              </DropdownToggle>
              <DropdownMenu className="mt-2 dropdown-menu-end">
                <div className="d-flex justify-content-start">
                  <div
                    className="d-flex flex-column"
                    style={{ flexDirection: "column" }}
                  >
                    <DateRangePicker
                      className="float-start"
                      onChange={(item) => setDatePickerState([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={datePickerState}
                      direction="vertical"
                      locale={rdrLocales.pt}
                    />
                    <button
                      className="btn btn-primary px-5 mx-3 mb-2"
                      onClick={() =>
                        loadGatewaysData({
                          since: moment(datePickerState[0].startDate).format(
                            "YYYY-MM-DD"
                          ),
                          until: moment(datePickerState[0].endDate).format(
                            "YYYY-MM-DD"
                          ),
                        })
                      }
                    >
                      Aplicar
                    </button>
                  </div>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>

            {checkMetritoTags(aggregatedData) && (
              <UncontrolledDropdown className="mt-3 mt-sm-0 w-100 mx-2">
                <DropdownToggle className="btn btn-light w-100" type="button">
                  <span className="float-start text-truncate text-dark">
                    Filtros
                  </span>
                  <i className="mdi mdi-chevron-down text-dark float-end"></i>
                </DropdownToggle>
                <DropdownMenu className="mt-2 dropdown-menu-end">
                  <div className="d-flex justify-content-start">
                    {unique_fields.map((field) => {
                      const stage = stages.find(
                        (stage) =>
                          typeof field === "string" && field.includes(stage.key)
                      ) || {
                        key: "TESTE",
                        color: "black",
                        icon: "mdi mdi-card-plus",
                      };
                      return (
                        <div
                          className="d-flex flex-column px-3"
                          style={{
                            borderRight: "1px solid #0000001A",
                            minWidth: 150,
                          }}
                        >
                          <Tag
                            className="button"
                            size="md"
                            style={{
                              backgroundColor: stage?.color || "#f7f7fa",
                              color: stage ? "#ffffff" : "#575757",
                            }}
                          >
                            {field && (
                              <>
                                <i className={`${stage.icon} me-2`} />
                                {field}
                              </>
                            )}
                          </Tag>
                          <hr className="divider my-2" />
                          {unique_fields_with_value
                            .filter((o) => {
                              return (
                                o.split("_")[0].replace(/[\[\]]/, "") === field
                              );
                            })
                            .map((value) => (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={value}
                                  id={value}
                                  checked={filters.includes(value)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFilters([...filters, e.target.value]);
                                    } else {
                                      setFilters(
                                        filters.filter(
                                          (f) => f !== e.target.value
                                        )
                                      );
                                    }
                                  }}
                                />
                                <label className="form-check-label" for={value}>
                                  {value.split("_")[1]?.replace(/[\[\]]/, "") ||
                                    value}
                                </label>
                              </div>
                            ))}
                        </div>
                      );
                    })}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}

            <Whisper
              placement="left"
              speaker={
                <Tooltip>
                  Ative essa opção para mostrar dados de teste. Recomendamos
                  utilizar essa função apenas para entender como o painel de
                  vendas funciona com as Tags Metrito
                </Tooltip>
              }
            >
              <span>
                <Toggle
                  className="m-0"
                  disabled={loading}
                  checkedChildren="Teste"
                  unCheckedChildren="Real"
                  checked={mockedDataActive}
                  onChange={setMockedDataActive}
                />
              </span>
            </Whisper>
          </Col>
        </Row>
      )}

      <div
        className="page-content"
        style={{ marginTop: !detectMobileBrowser() ? 80 : 200 }}
      >
        {!loading && !brand._id ? (
          <Container fluid>
            <Row
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ minHeight: "50vh" }}
            >
              <Col
                lg={6}
                md={8}
                sm={12}
                className="d-flex flex-column align-items-center justify-content-center text-center"
              >
                <i className="mdi mdi-alert text-secondary fs-1" />
                <h1>Ops... Houve um problema!</h1>
                <p className="text-muted fs-5 mt-3">
                  Provavelmente houve algum problema com os nossos servidores ou
                  a empresa que está tentando acessar o painel não existe.
                </p>
                <p className="text-muted fs-5">
                  Sinta-se a vontade para entrar diretamente em contato
                  diretamente com o nosso suporte!
                </p>
                <a className="btn btn-info" href="/home">
                  Voltar para empresas
                </a>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container fluid style={{ maxWidth: "100%" }}>
            {mockedDataActive && (
              <Message showIcon type="info" className="mb-3 rs-theme-dark">
                Atenção: os dados exibidos nessa página são apenas para fins de
                teste. Para que os dados sejam exibidos corretamente, é
                necessário que você adicione a Tag Metrito no{" "}
                <code style={{ color: "yellow" }}>utm_source</code> dos links de
                suas campanhas. Para mais informações, acesse a nosso{" "}
                <a href="/gateways/utm-generator" style={{ color: "yellow" }}>
                  gerador de Tags
                </a>
                .
              </Message>
            )}

            {!loading && !checkMetritoTags(aggregatedData) && (
              <Row>
                <Col lg={12}>
                  <Message
                    showIcon
                    type="info"
                    header="Nenhuma Tag Metrito encontrada"
                    className="rs-theme-dark mb-3"
                  >
                    <b>Atenção:</b> não foi detectado nenhuma{" "}
                    <a
                      href="/gateways/utm-generator"
                      style={{ color: "yellow" }}
                    >
                      Tag Metrito
                    </a>{" "}
                    nos códigos UTM de suas campanhas. Para que os dados sejam
                    exibidos corretamente, é necessário que você adicione a Tag
                    Metrito no{" "}
                    <code style={{ color: "yellow" }}>utm_source</code> dos seus
                    anúncios.
                  </Message>
                </Col>
              </Row>
            )}

            <SalesCard data={filtered_data} />
            <Row>
              <Col lg={4}>
                <SalesDonutChart
                  paymentMethod={"credit_card"}
                  data={filtered_data}
                />
              </Col>
              <Col lg={4}>
                <SalesDonutChart
                  paymentMethod={"billet"}
                  data={filtered_data}
                />
              </Col>
              <Col lg={4}>
                <SalesDonutChart paymentMethod={"pix"} data={filtered_data} />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <SalesPieChart
                  active={active}
                  setActive={setActive}
                  data={filterOrdersByActiveTag(filtered_data, active)}
                />
              </Col>
              <Col lg={6}>
                <UTMsTable
                  filterString={filterString}
                  setFilterString={setFilterString}
                  data={filterOrdersByActiveTag(aggregatedData, active)}
                />
              </Col>
            </Row>

            <Row>
              {/* <Col lg={12}>
                <SalesAreaChart data={granularData} />
              </Col> */}
              <Col lg={12}>
                <Card className="p-2">
                  {!loading && (
                    <SalesTable loading={loading} data={filtered_data} />
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>

      <SalesBlurLoader loading={loading} />

      <NoMetritoTagsFound
        open={!loading && !checkMetritoTags(aggregatedData) && !reqError}
      />

      {filterString !== null && (
        <motion.Row
          style={{
            position: "fixed",
            bottom: 20,
            width: "100%",
            zIndex: 1000,
          }}
          className="d-flex justify-content-center"
          initial={{ y: 100 }}
          animate={filter_animation}
        >
          <Col
            lg={8}
            style={{
              height: 100,
              minWidth: "auto",
              maxWidth: "100%",

              borderRadius: 10,
              boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.10)",
              padding: 10,
              boxSizing: "border-box",
            }}
            className="d-flex flex-row align-items-center card"
          >
            <TagGroup
              className="d-flex scrollbar-hidden align-items-center m-0 p-0"
              style={{ overflow: "scroll" }}
            >
              <Tag
                className="btn btn-light d-flex align-items-center"
                size="lg"
                style={{
                  minHeight: 60,
                  minWidth: "auto",
                  maxWidth: 1000,
                  backdropFilter: "brightness(0.8)",
                  borderRadius: 10,
                  display: "inline-block",
                  marginTop: 0,
                }}
                color={"black"}
                onClick={() => {
                  filter_animation.start({
                    y: 200,
                  });
                  setTimeout(() => {
                    setFilterString(null);
                    setFilters([]);
                  }, 200);
                }}
              >
                <i className={`fa fa-times me-2`} />
                <span className="text-truncate">Limpar Filtro</span>
              </Tag>
              {filterString.split("-").map((item, index) => {
                const stage = stages.find((stage) => item.includes(stage.key));

                return (
                  <Tag
                    size="lg"
                    style={{
                      minHeight: 60,
                      minWidth: "auto",
                      maxWidth: 1000,
                      backdropFilter: "brightness(0.8)",
                      borderRadius: 10,
                      display: "inline-block",
                      marginTop: 0,
                      backgroundColor: stage?.color || "#f7f7fa",
                      color: stage ? "#ffffff" : "#575757",
                    }}
                  >
                    {item && (
                      <>
                        <div className="d-flex">
                          <i
                            className={`${
                              stage?.icon || "mdi mdi-card-plus"
                            } me-2`}
                          />
                          <span className="text-truncate">
                            {stage?.label || "Campo extra"}
                          </span>
                        </div>
                        <b className="d-inline-block">
                          {item.split("_").length === 2
                            ? item
                                .split("_")
                                .slice(1, item.split("_").length)
                                .join("")
                                .replace(/[\[\]]/, "")
                            : item}
                        </b>
                      </>
                    )}
                  </Tag>
                );
              })}
            </TagGroup>
          </Col>
        </motion.Row>
      )}
    </>
  );
};

export default SalesPanel;
