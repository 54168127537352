import moment from "moment";
import "moment/locale/pt"; // without this line it didn't work
import React from "react";
import { Card } from "reactstrap";
import { Table, Tag } from "rsuite";
moment.locale("pt");

const { Column, HeaderCell, Cell } = Table;

const picker_options = [
  {
    label: "Bem sucedidas",
    value: "successful",
  },
  {
    label: "Mal sucedidas",
    value: "unsuccessful",
  },
];

const CurrencyCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell {...props}>
      {rowData.status === "succeeded" ? (
        <b>
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(rowData[dataKey] / 100)}
        </b>
      ) : (
        <b className="text-muted">
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(rowData[dataKey] / 100)}
        </b>
      )}
    </Cell>
  );
};

const DescriptionCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell {...props}>
      {rowData.description === "Subscription creation" ? (
        <Tag style={{ backgroundColor: "#625AFA", color: "white" }}>
          Criação
        </Tag>
      ) : rowData.description === "Subscription update" ? (
        <Tag style={{ backgroundColor: "#35308e", color: "white" }}>
          Renovação
        </Tag>
      ) : (
        <Tag>{rowData.description}</Tag>
      )}
    </Cell>
  );
};

const StatusCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell {...props}>
      {rowData.status === "succeeded" ? (
        <Tag
          style={{
            color: "#006908",
            backgroundColor: "#D6F7C2",
            boxShadow: "0px 0px 5px #f1f1f1",
            fontWeight: "bold",
          }}
        >
          Concluído
          <i className="mdi mdi-check ms-1" />
        </Tag>
      ) : (
        <Tag
          style={{
            color: "#DF1B40",
            backgroundColor: "#FFE7F1",
            boxShadow: "0px 0px 5px #f1f1f1",
            fontWeight: "bold",
          }}
        >
          Falhou
          <i className="mdi mdi-close ms-1" />
        </Tag>
      )}
    </Cell>
  );
};

const DateCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell {...props}>
      <span>
        {moment(new Date(rowData[dataKey] * 1000))
          .lang("pt")
          .format("DD MMM, HH:mm")}
      </span>
    </Cell>
  );
};

const ChargesTable = ({ charges }) => {
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [selectedFilter, setSelectedFilter] = React.useState("all");

  const filteredData =
    search.length > 0
      ? charges.filter(
          (o) =>
            o.name
              .toLowerCase()
              .replace(/ /g, "")
              .includes(search.toLowerCase().replace(/ /g, "")) ||
            o.email
              .toLowerCase()
              .replace(/ /g, "")
              .includes(search.toLowerCase().replace(/ /g, ""))
        )
      : charges;

  const getData = () => {
    if (sortColumn && sortType) {
      return filteredData.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return filteredData;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  return (
    <Card>
      <Table
        fillHeight
        style={{ borderRadius: 10, paddingBottom: 100 }}
        virtualized
        height={400}
        data={getData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
        onRowClick={(rowData) => {
          console.log(rowData);
        }}
      >
        <Column resizable sortable width={125}>
          <HeaderCell>Valor</HeaderCell>
          <CurrencyCell dataKey="amount" />
        </Column>

        <Column resizable sortable width={125}>
          <HeaderCell>Status</HeaderCell>
          <StatusCell dataKey="amount" />
        </Column>

        <Column resizable sortable width={300}>
          <HeaderCell>E-mail</HeaderCell>
          <Cell dataKey="customer.email" />
        </Column>

        {/* <Column resizable sortable flexGrow={1} width={100}>
          <HeaderCell>Status</HeaderCell>
          <StatusCell dataKey="status" />
        </Column> */}

        <Column resizable sortable width={200}>
          <HeaderCell>Descrição</HeaderCell>
          <DescriptionCell dataKey="description" />
        </Column>

        <Column resizable sortable width={200}>
          <HeaderCell>Data</HeaderCell>
          <DateCell dataKey="created" />
        </Column>

        <Column resizable sortable width={200}>
          <HeaderCell>Razão de falha</HeaderCell>
          <Cell dataKey="outcome.reason" />
        </Column>
      </Table>
    </Card>
  );
};

export default ChargesTable;
