export const metricsArray = [
  {
    label: "Alcance",
    description:
      "O número de pessoas que viram seus anúncios pelo menos uma vez. O alcance é diferente das impressões, que podem incluir várias visualizações de seus anúncios pelas mesmas pessoas.",
    value: "reach",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "reach",
      },
    ],
    isEnabled: true,
    role: "Performance",
  },
  {
    label: "Impressões",
    description: "O número de vezes que seus anúncios foram exibidos na tela.",
    value: "impressions",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "impressions",
      },
      {
        code: "AW",
        metric: "impressions",
      },
    ],
    isEnabled: true,
    role: "Performance",
  },
  {
    label: "Frequência",
    description:
      "O número de vezes que seus anúncios foram exibidos na tela para o mesmo usuário.",
    value: "frequency",
    display_type: "NUMBER",
    merge_operation: "AVG",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "frequency",
      },
    ],
    isEnabled: true,
    role: "Performance",
  },
  {
    label: "CTR (todos)",
    description:
      "A porcentagem de vezes que as pessoas viram seu anúncio e clicaram (todas).",
    value: "ctr",
    display_type: "PERCENTAGE",
    merge_operation: "AVG",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "ctr",
      },
      {
        code: "AW",
        metric: "ctr",
      },
    ],
    isEnabled: true,
    role: "Performance",
  },
  {
    label: "CTR (link)",
    description:
      "A porcentagem de vezes que as pessoas viram seu anúncio e clicaram no link.",
    value: "ctr_link",
    display_type: "PERCENTAGE",
    merge_operation: "AVG",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "website_ctr",
      },
      {
        code: "AW",
        metric: "ctr",
      },
    ],
    isEnabled: true,
    role: "Performance",
  },
  {
    label: "Visualizaçao de conteúdo",
    description: "O número de vezes que o usuário visualizou o conteúdo.",
    value: "view_content",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Cliques (todos)",
    description: "O número de cliques em seus anúncios.",
    value: "clicks",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "clicks",
      },
      {
        code: "AW",
        metric: "clicks",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Cliques no link",
    description: "O número de cliques no link em seus anúncios.",
    value: "clicks_link",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
      {
        code: "AW",
        metric: "clicks",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Visualizações da página de destino ",
    description:
      "O número de vezes que o usuário visualizou a página de destino.",
    value: "landing_page_views",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Engajamento com a página ",
    description: "O número de engagamentos com a página.",
    value: "page_engagement",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Curtidas na página",
    description: "O número de curtidas na página.",
    value: "likes",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Comentários",
    description: "O número de comentários na página.",
    value: "comments",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Engajamento com a publicação",
    description: "O número de engajamentos com a publicação.",
    value: "post_engagement",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Conversas iniciadas",
    description:
      "A quantidade de novas conversas iniciadas provenientes do seu anúncio.",
    value: "new_messaging_conversation",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Cadastros ",
    description: "A quantidade de cadastros.",
    value: "complete_registration",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Cadastros no Facebook",
    description: "A quantidade de cadastros.",
    value: "complete_registration_on_facebook",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Início período de teste ",
    description: "O número de usuários que iniciaram o período de teste.",
    value: "start_trial_total",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "conversions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Assinaturas",
    description: "A quantidade de assinaturas.",
    value: "subscribe_total",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "conversions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Cancelamento de assinatura",
    description: "A quantidade de assinaturas canceladas.",
    value: "cancel_subscription_total",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Ativações de aplicativo ",
    description: "O número de ativações do aplicativo.",
    value: "activate_app",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Adições ao carrinho ",
    description: "A quantidade de adições ao carrinho.",
    value: "add_to_cart",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Início de finalização de compra ",
    description:
      "O número de vezes em que um usuário iniciou a finalização de uma compra, como entrar numa página de checkout, por exemplo.",
    value: "initiate_checkout",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Compras ",
    description: "Número de compras provenientes dos anúncios.",
    value: "purchase",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "actions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Contatos (total)",
    description: "Número de contatos provenientes dos anúncios.",
    value: "contact_total",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "conversions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Conversões",
    description:
      "Número de conversões provenientes dos anúncios. Esta métrica se aplica apenas ao Google Ads.",
    value: "conversions",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "AW",
        metric: "conversions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Engajamentos",
    description:
      "Número de engajamentos com o anúncio. Esta métrica se aplica apenas ao Google Ads.",
    value: "engagements",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "AW",
        metric: "engagements",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Interações",
    description:
      "Número de interações com o anúncio. Esta métrica se aplica apenas ao Google Ads.",
    value: "interactions",
    display_type: "NUMBER",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "AW",
        metric: "interactions",
      },
    ],
    isEnabled: true,
    role: "Ações",
  },
  {
    label: "Custo por mil impressões",
    description: "O custo médio de 1.000 impressões.",
    value: "cpm",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cpm",
      },
      {
        code: "AW",
        metric: "average_cpm",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por mil pessoas alcançadas",
    description: "O custo médio para atingir 1.000 pessoas.",
    value: "cpp",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cpp",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por visualizaçao de conteúdo",
    description: "O custo por visualização do conteúdo.",
    value: "cost_per_view_content",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por contatos (total)",
    description: "O custo por contato gerado.",
    value: "cost_per_contact_total",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "CPC",
    description: "O custo médio de cada clique (todos).",
    value: "cpc",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cpc",
      },
      {
        code: "AW",
        metric: "average_cpc",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "CPC (link)",
    description: "O custo médio de cada clique (link).",
    value: "cpc_link",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
      {
        code: "AW",
        metric: "average_cpc",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por visualização da página de destino",
    description: "O custo estimado por cada visualização da página de destino.",
    value: "cost_per_landing_page_view",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por engajamento com a página",
    description: "O custo estimado por engajamento com a página.",
    value: "cost_per_page_engagement",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por curtida na página ",
    description: "O custo estimado por curtida na página.",
    value: "cost_per_like",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por comentário",
    description: "O custo estimado por comentário.",
    value: "cost_per_comment",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por engajamento com a publicação ",
    description: "O custo estimado por engajamento com a publicação.",
    value: "cost_per_post_engagement",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
      {
        code: "AW",
        metric: "average_cpe",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por conversa iniciada",
    description: "O custo médio por cada conversa iniciada.",
    value: "cost_per_new_messaging_conversation",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por cadastro ",
    description: "O custo médio por cadastro.",
    value: "cost_per_complete_registration",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por cadastro no Facebook",
    description: "O custo médio por cadastro.",
    value: "cost_per_complete_registration_on_facebook",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por início de período de teste ",
    description: "O custo médio por usuário que inicia o período de teste.",
    value: "cost_per_start_trial",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_conversion",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por assinatura",
    description: "O custo médio por assinatura.",
    value: "cost_per_subscribe",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_conversion",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por ativações de aplicativo",
    description: "O custo médio por ativação de aplicativo.",
    value: "cost_per_activate_app",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por adição ao carrinho",
    description: "O custo por cada adição ao carrinho.",
    value: "cost_per_add_to_cart",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por início de finalização de compra",
    description: "O custo estimado por cada início de finalização de compra.",
    value: "cost_per_initiate_checkout",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por compra",
    description: "O custo estimado por compra realizada.",
    value: "cost_per_purchase",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "FA",
        metric: "cost_per_action_type",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por conversão",
    description:
      "O custo por conversão. Esta métrica se aplica apenas ao Google Ads.",
    value: "cost_per_conversion",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "AW",
        metric: "cost_per_conversion",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Custo por interação",
    description:
      "O custo por interação com o anúncio. Esta métrica se aplica apenas ao Google Ads.",
    value: "average_cost",
    display_type: "CURRENCY",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "AW",
        metric: "average_cost",
      },
    ],
    isEnabled: true,
    role: "Custo",
  },
  {
    label: "Valor Gasto",
    value: "amount_spent",
    description:
      "A quantia total estimada de dinheiro que você gastou em sua campanha, conjunto de anúncios ou anúncio durante a programação. ",
    display_type: "CURRENCY",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "spend",
      },
      {
        code: "AW",
        metric: "cost_micros",
      },
    ],
    isEnabled: true,
    role: "Valor",
  },
  {
    label: "Valor de conversões",
    description: "O valor somado de todas as conversões.",
    value: "conversions_value",
    display_type: "CURRENCY",
    merge_operation: "SUM",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "action_values",
      },
      {
        code: "AW",
        metric: "conversions_value",
      },
    ],
    isEnabled: true,
    role: "Valor",
  },
  {
    label: "ROAS",
    description:
      "O retorno total do investimento em publicidade (ROAS) das compras no site. Isso se baseia no valor de todas as conversões registradas pelo pixel do Facebook em seu site e atribuídas aos seus anúncios.",
    value: "purchase_roas",
    display_type: "NUMBER",
    merge_operation: "AVG",
    better_when: "HIGH",
    data_sources: [
      {
        code: "FA",
        metric: "website_purchase_roas",
      },
    ],
    isEnabled: true,
    role: "Porcentagem",
  },
  {
    label: "Porcentagem absoluta de impressões superiores",
    description:
      "A porcentagem de suas impressões de anúncios que são exibidas como o primeiro anúncio acima dos resultados da pesquisa orgânica.",
    value: "absolute_top_impression_percentage",
    display_type: "PERCENTAGE",
    merge_operation: "AVG",
    better_when: "HIGH",
    data_sources: [
      {
        code: "AW",
        metric: "absolute_top_impression_percentage",
      },
    ],
    isEnabled: true,
    role: "Porcentagem",
  },
  {
    label: "Taxa de engajamento",
    description:
      "Com que frequência as pessoas se envolvem com seu anúncio depois que ele é exibido. Esse é o número de expansões de anúncios dividido pelo número de vezes que seu anúncio é exibido.",
    value: "engagement_rate",
    display_type: "PERCENTAGE",
    merge_operation: "AVG",
    better_when: "HIGH",
    data_sources: [
      {
        code: "AW",
        metric: "engagement_rate",
      },
    ],
    isEnabled: true,
    role: "Porcentagem",
  },
  {
    label: "Razão de conversões x interações",
    description:
      "Conversões de interações divididas pelo número de interações com anúncios (como cliques para anúncios de texto ou visualizações para anúncios em vídeo). Se você usar lances com base em conversão, suas estratégias de lances serão otimizadas para essas conversões.",
    value: "conversions_from_interactions_rate",
    display_type: "NUMBER",
    merge_operation: "AVG",
    better_when: "HIGH",
    data_sources: [
      {
        code: "AW",
        metric: "conversions_from_interactions_rate",
      },
    ],
    isEnabled: true,
    role: "Porcentagem",
  },
  {
    label: "Taxa de interação",
    description:
      "Com que frequência as pessoas interagem com seu anúncio depois que ele é exibido. Esse é o número de interações dividido pelo número de vezes que seu anúncio é exibido.",
    value: "interaction_rate",
    display_type: "PERCENTAGE",
    merge_operation: "AVG",
    better_when: "HIGH",
    data_sources: [
      {
        code: "AW",
        metric: "interaction_rate",
      },
    ],
    isEnabled: true,
    role: "Porcentagem",
  },
  {
    label: "Taxa de cliques inválidos",
    description:
      "A porcentagem de cliques filtrados do seu número total de cliques (filtrados + cliques não filtrados) durante o período do relatório.",
    value: "invalid_click_rate",
    display_type: "PERCENTAGE",
    merge_operation: "AVG",
    better_when: "LOW",
    data_sources: [
      {
        code: "AW",
        metric: "invalid_click_rate",
      },
    ],
    isEnabled: true,
    role: "Porcentagem",
  },
  {
    label: "Taxa de visualização de vídeo",
    description:
      "O número de visualizações que seu anúncio em vídeo TrueView recebe dividido pelo número de impressões, incluindo impressões em miniatura para anúncios TrueView em Display.",
    value: "video_view_rate",
    display_type: "PERCENTAGE",
    merge_operation: "AVG",
    better_when: "HIGH",
    data_sources: [
      {
        code: "AW",
        metric: "video_view_rate",
      },
    ],
    isEnabled: true,
    role: "Vídeo",
  },
];
