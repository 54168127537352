import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Row, Card } from "reactstrap";

import SalesAnalytics from "../pages/Dashboard/SalesAnalytics";
import ColumnChart from "./ColumnChart";
import BarChart from "./BarChart";
import DonutChart from "./DonutChart";
import CampaignsTable from "./CampaignsTable";
import MetricCards from "./MetricsCards";
import AccountStatus from "./AccountStatus";
import LineChart from "./LineChart";
import AreaChart from "./AreaChart";

import { metrics, summary, campaigns } from "../common/data/facebook";

import { fbSetMetrics, fbSetCampaigns } from "../store/facebook/actions";

const Widgets = (props) => {
  const defineComponent = (param) => {
    switch (param.id) {
      case 1:
        return <AccountStatus account={props.account} />;

      case 2:
        return (
          <MetricCards
            metrics={props.metrics}
            summary={props.summary}
            widget={param}
          />
        );

      case 3:
        return <CampaignsTable campaigns={props.campaigns} />;

      case 4:
        return (
          <Card>
            <SalesAnalytics
              maxMetric={12000}
              metrics={props.metrics}
              summary={props.summary}
              selected={param}
              metric="website_ctr"
            />
          </Card>
        );

      case 5:
        return <ColumnChart metrics={props.metrics} selected={param} />;

      case 6:
        return (
          <BarChart
            summary={props.summary}
            metrics={props.metrics}
            selected={param}
          />
        );

      case 7:
        return <LineChart metrics={props.metrics} selected={param} />;

      case 8:
        return (
          <AreaChart
            metrics={props.metrics}
            summary={props.summary}
            selected={param}
          />
        );

      case 9:
        return (
          <DonutChart
            metrics={props.metrics}
            summary={props.summary}
            selected={param}
          />
        );
      default:
        return <h1>Erro ao carregar widget. (v1)</h1>;
    }
  };

  useEffect(() => {
    if (props.editingMode === true) {
      console.log("Modo de Edição");
      props.populateFB(metrics, summary);
      props.populateCampaigns(campaigns);
    }
  });

  return (
    <div>
      <section id="dashboard">
        <Row lg={12} xl={12}>
          {props.layout.map((l, key) => {
            const comp = defineComponent(l);

            return <React.Fragment key={key}>{comp}</React.Fragment>;
          })}
        </Row>
      </section>
    </div>
  );
};

const mapStateToProps = (store) => ({
  account: store.Facebook.adaccount,
  metrics: store.Facebook.metrics,
  summary: store.Facebook.summary,
  campaigns: store.Facebook.campaigns,
});

const mapDispatchToProps = (dispatch) => ({
  populateFB: (metrics, summary) => dispatch(fbSetMetrics(metrics, summary)),
  populateCampaigns: (campaigns) => dispatch(fbSetCampaigns(campaigns)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Widgets);
