import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Toggle } from "rsuite";
import { DropdownItem } from "reactstrap";
import api from "../../services/api";

const ChangeAdAccountAlias = (props) => {
  const [saving, setSaving] = useState(false);
  const [accountAlias, setAccountAlias] = useState();
  const [useAccountAlias, setUseAccountAlias] = useState(false);

  const cleanState = () => {
    setAccountAlias("");
    setUseAccountAlias(false);
  };

  function handleSave() {
    setSaving(true);
    api
      .put(`/api/ad-account/update/${props.brandId}`, {
        data_source: props.account.data_source,
        ds_id: props.account.ds_id,
        account_alias: accountAlias,
        account_use_alias: useAccountAlias,
      })
      .then((res) => {
        props.handleClose();
        props.handleUpdate();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSaving(false);
        cleanState();
      });
  }

  useEffect(() => {
    setAccountAlias(props.account.account_alias || "");
    setUseAccountAlias(props.account.account_use_alias || false);
  }, [props]);

  return (
    <Modal
      size={"sm"}
      open={props.open}
      onClose={() => {
        props.handleClose();
        cleanState();
      }}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Editar apelido da conta</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        <div className="d-flex flex-column w-100 align-items-center">
          <div className="d-flex align-items-center me-3">
            <i
              className={props.dataSource?.icon || "mdi mdi-spin mdi-loading"}
              style={{
                color: props.dataSource?.color || "lightgray",
                fontSize: 35,
                marginInline: 5,
              }}
            />
            <div className="ms-2">
              <h6 className="m-0">
                {useAccountAlias
                  ? accountAlias || props.account.name
                  : props.account.name}
              </h6>
              <small className="text-muted">{props.account.id}</small>
            </div>
          </div>
          <div className="w-100">
            <small className="text-muted">Apelido da Conta de Anúncios</small>
            <div className="d-flex align-items-center w-100">
              <Input
                size="sm"
                placeholder={props.account.name}
                className="w-100"
                value={accountAlias}
                onChange={(value) => {
                  setAccountAlias(value);

                  if (value.length > 0) {
                    setUseAccountAlias(true);
                  } else {
                    setUseAccountAlias(false);
                  }
                }}
              />
              <Toggle
                defaultChecked={false}
                checked={useAccountAlias}
                onChange={(value) => setUseAccountAlias(value)}
                className="m-0 ms-3"
              />
            </div>
          </div>

          {/* {props.account.profile && (
            <div className="w-100">
              <small className="text-muted">Apelido do Perfil</small>
              <div className="d-flex align-items-center w-100">
                <Input
                  size="sm"
                  placeholder={props.account.profile.name}
                  className="w-100"
                />
                <Toggle defaultChecked={false} className="m-0 ms-3" />
              </div>
            </div>
          )} */}
        </div>
      </Modal.Body>
      <Modal.Footer className="mt-5">
        <Button
          onClick={props.handleClose}
          appearance="subtle"
          disabled={saving}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          appearance="primary"
          className="px-4"
          loading={saving}
          color="green"
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeAdAccountAlias;
