import axios from "axios";
import { getIdUsuario, getToken } from "../services/auth.js";

const localhost_URI = "http://localhost:4000";

const api = axios.create(
  getToken()
    ? {
        baseURL:
          window.location.hostname == "localhost"
            ? localhost_URI
            : window.location.origin,
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Identification: getIdUsuario(),
        },
      }
    : {
        baseURL:
          window.location.hostname == "localhost"
            ? localhost_URI
            : window.location.origin,
      }
);

export default api;
