import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

export default function CustomAlert({ children, ...props }) {
  return (
    <SweetAlert
      style={{
        ...props?.style,
        borderRadius: "18px"
      }}
      confirmBtnStyle={{
        color:
          !props?.confirmBtnStyle &&
          !props?.confirmBtnBsStyle &&
          !props?.confirmBtnCssClass
            ? "white"
            : "",
      }}
      {...props}
    >
      {children}
    </SweetAlert>
  );
}
