import api from "./api";
import { metricsArray } from "../common/data/metrics";

export const applyMultiselect = async (data, mode) => {
  return await api
    .post("/api/multiselect", { data, mode, metrics_list: metricsArray })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
