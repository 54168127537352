import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { CardBody } from "reactstrap";
import { data_sources } from "../common/data/data_sources";
import { CustomTooltip } from "../components/Common/Tooltip.js";
import { formatByDisplayType } from "../functions/formatByDisplayType";

const ColumnChart = (props) => {
  var series = props.metrics_by_data_source.map((o) => ({
    name: data_sources.find((ds) => ds.code === o.data_source).label,
    data: o.data.map((o) => o[props.selected.metric] || null) || [],
  }));

  var options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    annotations: {
      yaxis:
        props.selected?.lines?.map((line) => ({
          y: line.limit,
          borderColor: line.color || "#000",
          strokeDashArray: 5,
          label: {
            borderColor: line.color || "#000",
            style: {
              color: "#fff",
              background: line.color || "#000",
            },
            text: line.text,
          },
        })) || [],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "90%",
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: props.metrics.map((a) => moment(a.date).toLocaleString("pt-BR")),
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#0050ff", "#0ab39c"],
    xaxis: {
      type: "datetime",
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    yaxis: {
      labels: {
        formatter: function (y) {
          return formatByDisplayType(y, props.selected.metric);
        },
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          return formatByDisplayType(y, props.selected.metric);
        },
      },
    },
  };

  return (
    <React.Fragment>
      <CardBody style={{ height: "100%" }}>
        <div>
          <p className="text-muted text-uppercase fw-semibold d-flex flex-row mb-2">
            <span className="float-start text-truncate align-middle">
              {props.selected.title}
            </span>

            <CustomTooltip
              placement="topStart"
              text={props.selected.description}
            />
          </p>
        </div>
        <ReactApexChart
          key={JSON.stringify(props)}
          options={options}
          series={series}
          type={options.chart.type}
          width="100%"
          height="255"
        />
      </CardBody>
    </React.Fragment>
  );
};

export default ColumnChart;
