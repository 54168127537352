import React from "react";
import { Modal } from "reactstrap";

const NoAdAccountsLeftModal = (props) => {
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    props.setVisible(!props.visible);
    removeBodyCss();
  }

  return (
    <>
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={props.visible}
        toggle={() => {
          tog_xlarge();
        }}
      >
        <div className="modal-body text-center p-5">
          <img
            src="https://i.pinimg.com/originals/95/19/2b/95192bdb70eb7e581db3e9af2032eac6.gif"
            alt="triste"
            style={{ maxHeight: 200, borderRadius: 10 }}
            className="my-4"
          />
          <h4 className="mb-4">Ops.. Sem contas de anúncio restantes!</h4>
          <p>
            Seu plano + Contas Extras contratadas dão direito ao total de{" "}
            <b className="text-primary">{props.ad_accounts_limit}</b> contas de
            anúncio.
          </p>
          <p className="mb-3">
            Atualmente você tem{" "}
            <b className="text-primary">{props.connected_ad_accounts}</b> contas
            de anúncio conectadas no Metrito.
          </p>

          <h5>
            Mas você pode adicionar uma Conta de Anúncios Extra sem problemas!
          </h5>
          <div className="w-100 d-flex justify-content-center pt-2 mt-4">
            <button
              className="btn btn-success px-5 py-2 fs-5"
              onClick={() =>
                (window.location.href = `/checkout?type=adaccounts&extra_accounts=1`)
              }
            >
              <i className="mdi mdi-plus-circle me-2" />
              <b>Adicionar Conta Extra</b>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NoAdAccountsLeftModal;
