import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import classnames from "classnames";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import InputMask from "react-input-mask";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, FormGroup, Input, Label, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import animationData from "../../assets/lotties/confetti_lottie.json";
import successCheckData from "../../assets/lotties/success_check_lottie.json";
import api from "../../services/api";
import { getIdUsuario, getNomeUsuario } from "../../services/auth";
import Preloader from "../Utility/Preloader";
import AdAccountsRightbar from "./AdAccountsRightbar.js";
import AppmaxWarning from "./AppmaxWarning";
import BlurLoader from "./BlurLoader";
import { CreditCardLoader } from "./CreditCardLoader";
import PlansRightbar from "./PlansRightbar";

// Array de siglas dos estados do Brasil
const siglas = [
  "",
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MS",
  "MT",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

const url_params = new URL(window.location.href).searchParams;
const product_id_param = new URL(window.location.href).searchParams.get("product");

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const confetti_options = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const success_check_options = {
  loop: false,
  autoplay: true,
  animationData: successCheckData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Payment = (props) => {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [processingPayment, setProcessingPayment] = useState(false);

  const [ipData, setIpData] = useState({});
  const [userData, setUserData] = useState({});

  const [customer, setCustomer] = useState({});
  const [card, setCard] = useState({
    name: "",
    number: "",
    expiry: "",
    cvv: "",
  });
  const [cardPreview, setCardPreview] = useState({});

  const [issuer, setIssuer] = useState("");

  const [loadingPlans, setLoadingPlans] = useState(true);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});

  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [rangeValue, setRangeValue] = useState(0);

  const [discount, setDiscount] = useState(0);
  const [coupom_code, setCoupom_code] = useState();
  const [coupomSuccess, setCoupomSuccess] = useState(false);

  function toggleTab(tab, type) {
    setactiveTab(tab);
  }

  async function loadUserData() {
    const response = await api
      .get("/api/usuarios.details/" + getIdUsuario())
      .then((response) => {
        setUserData(response.data[0]);

        if (response.data[0].payment_info?.customer_id) {
          setCardPreview({
            status: true,
            issuer: response.data[0].payment_info.card.issuer,
          });
          setCard({
            number: `**** **** **** ${response.data[0].payment_info.card.last_digits}`,
            name: "-------------",
            cvv: "",
            expiry: "",
          });
          setRangeValue(url_params.get("extra_accounts") || response.data[0].subscription?.extra_ad_accounts.quantity || 0);
          window.localStorage.setItem("customer_id", response.data[0].payment_info.customer_id);
          setactiveTab(2);
        } else {
          var user = response.data[0];
          var nameLength = user.name.split(" ").length;
          setCustomer({
            firstname: user.name.split(" ")[0],
            lastname: user.name.split(" ")[nameLength - 1],
            email: user.email,
            telephone: user.tel_number,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  async function handleCustomerSubmit(e) {
    setLoading(true);

    // toggleTab(activeTab + 1, "plus");
    // setLoading(false);

    try {
      const data = {
        firstname: e.target.firstname.value,
        lastname: e.target.lastname.value,
        email: e.target.email.value,
        telephone: e.target.telephone.value,
        postcode: e.target.postcode.value,
        address_street: e.target.address_street.value,
        address_street_number: e.target.address_street_number.value,
        address_street_complement: e.target.address_street_complement.value,
        address_street_district: e.target.address_street_district.value,
        address_city: e.target.address_city.value,
        address_state: e.target.address_state.value,
        ip: ipData.ip || "127.0.0.1",
        products: [],
        tracking: [],
      };

      setCustomer(data);

      const customer = await api.post(`/api/gateway/customer/${getIdUsuario()}`, data);

      // console.log(customer.data);

      if (customer.data.appmax_response.success == true) {
        console.log("💳 Appmax -> Created Customer");

        window.localStorage.setItem("customer_id", customer.data.appmax_response.data.id);
        toggleTab(activeTab + 1, "plus");
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  }

  async function handleSubmitCard(e) {
    setLoading(true);
    setProcessingPayment(true);

    var order_data = {
      products:
        url_params?.get("type") === "adaccounts"
          ? [
              {
                sku: selectedPlan.fb_account_sku,
                name: "Conta Extra - Facebook",
                qty: Number(rangeValue),
                price: selectedPlan.extra_account_price,
              },
            ]
          : url_params?.get("type") === "plans" && rangeValue == 0
          ? [
              {
                sku: selectedPlan.gateway_product_id,
                name: selectedPlan.name,
                qty: 1,
                price: selectedPlan.price,
              },
            ]
          : [
              {
                sku: selectedPlan.gateway_product_id,
                name: selectedPlan.name,
                qty: 1,
                price: selectedPlan.price,
              },
              {
                sku: selectedPlan.fb_account_sku,
                name: "Conta Extra - Facebook",
                qty: Number(rangeValue),
                price: selectedPlan.extra_account_price,
              },
            ],
      customer_id: window.localStorage.getItem("customer_id"),
      plan: selectedPlan.gateway_product_id,
      coupom_code,
    };

    try {
      var payment_data = {
        cart: {
          order_id: window.localStorage.getItem("order_id"),
        },
        customer: {
          customer_id: window.localStorage.getItem("customer_id"),
        },
      };

      if (!cardPreview.status) {
        const card = {
          card: {
            name: e.target.card_name.value,
            number: e.target.card_number.value.replace(/ /g, ""),
            cvv: e.target.card_cvv.value,
            month: parseInt(e.target.card_date.value.split("/")[0]),
            year: parseInt(e.target.card_date.value.split("/")[1]),
            document: e.target.document.value,
            issuer: issuer,
            last_digits: e.target.card_number.value.split(" ")[e.target.card_number.value.split(" ").length - 1],
          },
        };

        const additional_payment_data = {
          cpf: e.target.document.value,
          cvv: e.target.card_cvv.value,
        };

        const tokenization = await api.post(`/api/gateway/tokenization/${getIdUsuario()}`, card);

        console.log("💳 Appmax -> Tokenized Card");
        // console.log(tokenization.data);

        payment_data = {
          ...payment_data,
          payment: {
            CreditCard: {
              token: tokenization.data.appmax_response.data.token,
              document_number: additional_payment_data.cpf,
              cvv: additional_payment_data.cvv,
              installments: 1,
              soft_descriptor: "METRITO",
            },
          },
        };
      } else {
        payment_data = {
          ...payment_data,
          payment: {
            CreditCard: {
              token: userData.payment_info.card.token,
              document_number: userData.payment_info.document,
              cvv: userData.payment_info.card.cvv,
              installments: 1,
              soft_descriptor: "METRITO",
            },
          },
        };
      }

      const order = await api.post("/api/gateway/order", { order: order_data });

      // console.log(order.data);

      order_data = {
        ...order_data,
        order_id: order.data.data.id,
        full_amount: order.data.data.full_payment_amount,
        plan: selectedPlan,
      };

      payment_data = {
        ...payment_data,
        cart: {
          order_id: order.data.data.id,
        },
        customer: {
          customer_id: order.data.data.customer_id,
        },
      };

      var data = { order: order_data, payment: payment_data };

      const credit_card_payment = await api.post(`/api/gateway/payment/credit-card/${getIdUsuario()}`, data);

      if (credit_card_payment.data.status === 200 && credit_card_payment.data.success == true) {
        console.log("💳 Appmax -> Successful Payment Transaction");

        //Purchase Facebook Ads
        window.fbq("track", "Purchase", {
          currency: "BRL",
          value: parseFloat(order.data.data.full_payment_amount),
        });

        //Purchase Google Ads
        window.gtag("event", "conversion", {
          send_to: "AW-10955773296/gQXNCOu_ztADEPCqj-go",
          value: parseFloat(order.data.data.full_payment_amount),
          currency: "BRL",
          transaction_id: order.data.data.id,
        });

        window.localStorage.removeItem("order_id");

        try {
          document.getElementById("trial-topbar").style.visibility = "hidden";
          document.getElementById("page-topbar").style.marginTop = "0px";
          document.getElementById("layout-wrapper").style.marginTop = "0px";
          document.getElementById("checkout-top").style.marginTop = "0px";
        } catch (error) {
          console.log(error);
        }

        $("#plan-card").hide();
        setPaymentSuccess(true);
      } else {
        console.log("💳 Appmax -> Error in Payment Transaction");
        // console.log(credit_card_payment.data.appmax_response);
        setPaymentSuccess(false);
      }

      setactiveTab(3);
      setLoading(false);
      setProcessingPayment(false);
    } catch (error) {
      console.log(error);
      setPaymentSuccess(false);
      setactiveTab(3);
      setLoading(false);
      setProcessingPayment(false);
    }
  }

  function fetchPlans() {
    api
      .get("/api/plans")
      .then((res) => {
        setPlans(res.data);

        setLoadingPlans(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function applyCoupom(coupom) {
    console.log("Verificando Cupom...");
    if (coupom === "ESPECIAL50OFF") {
      setCoupomSuccess(true);

      setDiscount((selectedPlan.price + rangeValue * selectedPlan.extra_account_price) * 0.5);
    } else {
      console.log("Cupom Inválido");
    }
  }
  useEffect(() => {
    setSelectedPlan(
      url_params.has("product")
        ? plans.find((plan) => plan.gateway_product_id === product_id_param)
        : plans.find((plan) => plan.gateway_product_id === userData.subscription?.plan.sku) || {}
    );
  }, [userData, plans]);

  useEffect(async () => {
    document.getElementById("topnav").style.display = "none";

    await loadUserData();
    await fetchPlans();

    axios
      .get("https://ipapi.co/json/")
      .then((res) => {
        console.log(res.data);
        setIpData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(coupom_code);
    if (coupomSuccess) {
      if (coupom_code === "ESPECIAL50OFF") {
        setDiscount((selectedPlan.price + rangeValue * selectedPlan.extra_account_price) * 0.5);
      }
    }
  }, [selectedPlan, rangeValue]);

  // useEffect(() => {
  //   //Initiate Checkout Google Ads
  //   window.gtag("event", "conversion", {
  //     send_to: "AW-10955773296/lFXsCIP5tdADEPCqj-go",
  //     value:
  //       url_params.get("type") === "plans"
  //         ? parseFloat(selectedPlan.price)
  //         : parseFloat(
  //             Number(url_params.get("extra_accounts")) *
  //               selectedPlan.extra_account_price
  //           ),
  //     currency: "BRL",
  //   });

  //   //Initiate Checkout Facebook Ads
  //   window.fbq("track", "InitiateCheckout", {
  //     value:
  //       url_params.get("type") === "plans"
  //         ? parseFloat(selectedPlan.price)
  //         : parseFloat(
  //             Number(url_params.get("extra_accounts")) *
  //               selectedPlan.extra_account_price
  //           ),
  //     currency: "BRL",
  //     content_name: "Checkout",
  //     content_category: url_params.get("type"),
  //     content_ids: selectedPlan.gateway_product_id,
  //     num_ids: 1,
  //   });
  // }, []);

  useEffect(() => {
    window.addEventListener("load", function (event) {
      console.log("Todos os recursos terminaram o carregamento!");

      //Initiate Checkout Google Ads
      window.gtag("event", "conversion", {
        send_to: "AW-10955773296/lFXsCIP5tdADEPCqj-go",
        value:
          url_params.get("type") === "plans"
            ? parseFloat(selectedPlan.price)
            : parseFloat(Number(url_params.get("extra_accounts")) * selectedPlan.extra_account_price),
        currency: "BRL",
      });

      //Initiate Checkout Facebook Ads
      window.fbq("track", "InitiateCheckout", {
        value:
          url_params.get("type") === "plans"
            ? parseFloat(selectedPlan.price)
            : parseFloat(Number(url_params.get("extra_accounts")) * selectedPlan.extra_account_price),
        currency: "BRL",
        content_name: "Checkout",
        content_category: url_params.get("type"),
        content_ids: selectedPlan.gateway_product_id,
        num_ids: 1,
      });
    });
  }, []);

  return (
    <>
      {activeTab === 3 && paymentSuccess ? (
        <div className="d-flex justify-content-center">
          <Lottie
            options={confetti_options}
            height={"100%"}
            width={"auto"}
            style={{
              margin: "none",
              position: "absolute",
              zIndex: 100,
              pointerEvents: "none",
            }}
          />
        </div>
      ) : null}

      <div
        style={{
          height: 5,
          top: 60,
          left: 0,
          right: 0,
          borderRadius: 0,
          position: "fixed",
        }}
        id="checkout-top"
        className="checkout-top"
      ></div>

      <div className="page-content">
        <CreditCardLoader isVisible={processingPayment} />
        <Container fluid>
          {!selectedPlan ? (
            <Preloader />
          ) : userData !== {} && url_params.has("type") && url_params.get("product") !== userData?.subscription?.plan.sku ? (
            <Row className="d-flex">
              <Col id="plan-card" lg={4} md={12} className="order-lg-last">
                {url_params.get("type") === "plans" ? (
                  <PlansRightbar
                    user={userData}
                    plans={plans}
                    loadingPlans={loadingPlans}
                    isLoading={isLoading}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                    rangeValue={rangeValue}
                    setRangeValue={setRangeValue}
                    coupom_code={coupom_code}
                    setCoupom_code={setCoupom_code}
                    applyCoupom={applyCoupom}
                    discount={discount}
                    coupom_code={coupom_code}
                  />
                ) : url_params.get("type") === "adaccounts" ? (
                  <AdAccountsRightbar isLoading={isLoading} user={userData} quantity={rangeValue} setQuantity={setRangeValue} />
                ) : null}
              </Col>
              <Col lg={paymentSuccess ? 12 : 8} md={12} className="order-lg-first">
                <Card>
                  {isLoading ? <BlurLoader text={"Processando dados, por favor aguarde..."} /> : null}

                  <CardBody>
                    <h4 className="card-title mb-4">Checkout</h4>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem className={(classnames({ current: activeTab <= 1 }), "m-2")}>
                            <NavLink
                              className={classnames({
                                current: 1 <= activeTab,
                              })}
                            >
                              <span className="number">1</span> Dados Gerais
                            </NavLink>
                          </NavItem>
                          <NavItem className={(classnames({ current: activeTab === 2 }), "m-2")} onClick={() => setactiveTab(2)}>
                            <NavLink
                              disabled={!(passedSteps || []).includes(2)}
                              className={classnames({
                                current: 2 <= activeTab,
                              })}
                            >
                              <span className="number">2</span>
                              Pagamento
                            </NavLink>
                          </NavItem>
                          <NavItem className={(classnames({ current: activeTab === 3 }), "m-2")}>
                            <NavLink
                              disabled={!(passedSteps || []).includes(3)}
                              className={classnames({
                                current: 3 <= activeTab,
                              })}
                            >
                              <span className="number">3</span> Confirmação
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix mt-4">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={1}>
                            <AvForm onValidSubmit={(e) => handleCustomerSubmit(e)}>
                              <Row>
                                <Label>
                                  <b>Dados pessoais</b>
                                </Label>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label>Nome</Label>
                                    <AvField
                                      name="firstname"
                                      type="text"
                                      className="form-control"
                                      value={customer.firstname}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: "Por favor, insira seu primeiro nome",
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label>Sobrenome</Label>
                                    <AvField
                                      name="lastname"
                                      type="text"
                                      className="form-control"
                                      value={customer.lastname}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: "Por favor, insira seu último nome",
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label>E-mail</Label>
                                    <AvField
                                      name="email"
                                      type="email"
                                      className="form-control"
                                      value={customer.email}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: "Por favor, insira seu email",
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label>Telefone</Label>
                                    <AvField
                                      name="telephone"
                                      type="text"
                                      className="form-control"
                                      value={customer.telephone}
                                      mask="(99) 99999-9999"
                                      maskchar=""
                                      tag={[Input, InputMask]}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: "Por favor, insira seu telefone",
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <hr className="divider" />
                              <Row>
                                <Label>
                                  <b>Endereço</b>
                                </Label>
                                <Col lg="8">
                                  <div className="mb-3">
                                    <Label>Rua</Label>
                                    <AvField
                                      name="address_street"
                                      type="text"
                                      className="form-control"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: "Por favor, insira a rua da sua residência",
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label>Número</Label>
                                    <AvField
                                      name="address_street_number"
                                      type="text"
                                      className="form-control"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: "Por favor, insira o número da sua residência",
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label>Bairro</Label>
                                    <AvField
                                      name="address_street_district"
                                      type="text"
                                      className="form-control"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: "Por favor, insira o bairro da sua residência",
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label>Complemento</Label>
                                    <AvField name="address_street_complement" type="text" className="form-control" />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label>Cidade</Label>
                                    <AvField
                                      name="address_city"
                                      type="text"
                                      className="form-control"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: "Por favor, insira o nome da cidade",
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label>Estado</Label>
                                    {/* With select and AvField */}
                                    <AvField
                                      type="select"
                                      name="address_state"
                                      helpMessage="Sigla do estado"
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: "Por favor, selecione seu estado",
                                        },
                                        minLength: {
                                          value: 2,
                                          errorMessage: "Por favor, digite um número válido.",
                                        },
                                      }}
                                    >
                                      {siglas.map((sigla, key) => (
                                        <option key={key} value={sigla}>
                                          {sigla}
                                        </option>
                                      ))}
                                    </AvField>
                                  </div>
                                </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label>CEP</Label>
                                    <AvField
                                      name="postcode"
                                      type="text"
                                      className="form-control"
                                      mask={"99999-999"}
                                      maskchar=""
                                      tag={[Input, InputMask]}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: "Por favor, insira o seu CEP",
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col lg={12}>
                                  <button className="btn btn-success fs-5 w-100 mt-4">
                                    <b>Prosseguir</b>
                                    <i className="bx bx-right-arrow-alt align-middle fs-4 ms-1" />
                                  </button>
                                </Col>
                              </Row>
                            </AvForm>
                          </TabPane>
                          <TabPane tabId={2}>
                            <AppmaxWarning user={userData} step={1} />
                            <div>
                              <Row>
                                <Col xxl={5} xl={12} lg={12} md={12} className="mb-4 text-center">
                                  <Cards
                                    placeholders={{
                                      name: "Nome do titular",
                                      expiry: "validade",
                                    }}
                                    cvc={card.cvv}
                                    expiry={card.expiry}
                                    focused={card.cvv}
                                    name={card.name}
                                    number={card.number}
                                    locale={{ valid: "válido até" }}
                                    preview={cardPreview.status}
                                    issuer={cardPreview.issuer}
                                    callback={(e) => setIssuer(e.issuer)}
                                  />
                                  <div className="mt-3">
                                    <span className="me-2">Compra segura por</span>
                                    <img
                                      src="https://raichu-uploads.s3.amazonaws.com/logo_null_plZLQd.png"
                                      alt="appmax"
                                      style={{ maxHeight: 15 }}
                                    />
                                  </div>
                                </Col>

                                {userData.payment_info && cardPreview.status === true ? (
                                  <Col xxl={7} xl={12} lg={12}>
                                    <div
                                      className="d-flex jusitfy-content-center align-items-center p-3 px-lg-5 text-center"
                                      style={{
                                        border: "2px dashed #ededed",
                                        borderRadius: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div className="text-center">
                                        <div className="mb-2">
                                          <i className="bx bx-credit-card text-dark display-6" />
                                        </div>
                                        <div>
                                          <h5>{`${userData.payment_info.card.issuer.toUpperCase()}  **** ${
                                            userData.payment_info.card.last_digits
                                          }`}</h5>
                                          <p className="text-muted">
                                            <b>Continue utilizando o cartão configurado na sua conta</b> ou mude os dados de pagamento.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <Row>
                                      <Col lg={4} className="d-flex align-items-center">
                                        <button
                                          className="btn btn-light fs-6 w-100 my-1"
                                          onClick={() => {
                                            setCardPreview({
                                              status: false,
                                              name: "",
                                            });
                                            setCard({
                                              name: "",
                                              number: "",
                                              expiry: "",
                                              cvv: "",
                                            });
                                          }}
                                        >
                                          <b className="text-muted">alterar cartão</b>
                                        </button>
                                      </Col>
                                      <Col lg={8}>
                                        <button className="btn btn-success fs-5 w-100 my-1" onClick={() => handleSubmitCard()}>
                                          <b>Comprar</b>
                                          <i className="bx bx-right-arrow-alt align-middle fs-4 ms-1" />
                                        </button>
                                      </Col>

                                      <p className="text-muted text-end mb-0 mt-2" style={{ fontSize: 12 }}>
                                        Todos os seus dados estão 100% seguros e protegidos.
                                      </p>
                                    </Row>
                                  </Col>
                                ) : (
                                  <Col xxl={7} xl={12} lg={12} className="px-lg-5">
                                    <AvForm className="needs-validation" onValidSubmit={(e) => handleSubmitCard(e)}>
                                      <Row>
                                        <Col lg={8} md={12}>
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">Nome do titular</Label>
                                            <AvField
                                              name="card_name"
                                              placeholder="Nome do titular do cartão"
                                              type="text"
                                              errorMessage="Insira o nome"
                                              className="form-control"
                                              onChange={(e) =>
                                                setCard({
                                                  ...card,
                                                  name: e.target.value,
                                                })
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg={4}>
                                          <Label htmlFor="validationCustom02">Data de Validade</Label>
                                          <AvField
                                            name="card_date"
                                            placeholder="mm/yy"
                                            mask={"99/99"}
                                            maskchar=""
                                            tag={[Input, InputMask]}
                                            errorMessage="Insira a validade"
                                            className="form-control"
                                            onChange={(e) =>
                                              setCard({
                                                ...card,
                                                expiry: e.target.value,
                                              })
                                            }
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </Col>
                                        <Col lg={8}>
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">Número do cartão</Label>
                                            <AvField
                                              name="card_number"
                                              placeholder="Número do cartão"
                                              type="text"
                                              errorMessage="Insira o número do cartão"
                                              className="form-control"
                                              mask={"9999 9999 9999 9999"}
                                              maskchar=""
                                              tag={[Input, InputMask]}
                                              onChange={(e) =>
                                                setCard({
                                                  ...card,
                                                  number: e.target.value,
                                                })
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg={4}>
                                          <FormGroup className="mb-3">
                                            <Label>CVV</Label>
                                            <AvField
                                              name="card_cvv"
                                              placeholder=""
                                              type="text"
                                              errorMessage="Insira o código de segurança"
                                              className="form-control"
                                              mask={"999"}
                                              maskchar=""
                                              tag={[Input, InputMask]}
                                              onChange={(e) =>
                                                setCard({
                                                  ...card,
                                                  cvv: e.target.value,
                                                })
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg={12}>
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">CPF</Label>
                                            <AvField
                                              name="document"
                                              placeholder="CPF do usuário"
                                              type="text"
                                              errorMessage="Insira o código de segurança"
                                              className="form-control"
                                              mask={"999.999.999-99"}
                                              maskchar=""
                                              tag={[Input, InputMask]}
                                              validate={{
                                                required: { value: true },
                                              }}
                                            />
                                            {/* <div className="mb-2">
                                              <div className="form-check form-check-inline">
                                                <input
                                                  className="form-check-input"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio1"
                                                  defaultValue="option1"
                                                  checked={
                                                    documentType === "CPF"
                                                  }
                                                  onChange={() =>
                                                    setDocumentType("CPF")
                                                  }
                                                />
                                                <Label
                                                  className="form-check-label"
                                                  for="inlineRadio1"
                                                >
                                                  Pessoa física (CPF)
                                                </Label>
                                              </div>
                                              <div className="form-check form-check-inline">
                                                <input
                                                  className="form-check-input"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio2"
                                                  defaultValue="option2"
                                                  checked={
                                                    documentType === "CNPJ"
                                                  }
                                                  onChange={() => {
                                                    setDocumentType("CNPJ");
                                                  }}
                                                />
                                                <Label
                                                  className="form-check-label"
                                                  for="inlineRadio2"
                                                >
                                                  Pessoa jurídica (CNPJ)
                                                </Label>
                                              </div>
                                            </div>

                                            {documentType === "CPF" ? (
                                              <AvField
                                                name="document"
                                                placeholder="CPF do titular"
                                                type="text"
                                                errorMessage="Insira o CPF do titular"
                                                className="form-control"
                                                mask={"999.999.999-99"}
                                                maskchar=""
                                                tag={[Input, InputMask]}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            ) : (
                                              <AvField
                                                name="document"
                                                placeholder="CNPJ"
                                                type="text"
                                                errorMessage="Insira o CNPJ"
                                                className="form-control"
                                                mask={"99.999.999/9999-99"}
                                                maskchar=""
                                                tag={[Input, InputMask]}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            )} */}
                                          </FormGroup>
                                        </Col>
                                        <Col lg={4} className="d-flex align-items-center">
                                          <button
                                            className="btn btn-light fs-6 w-100 my-1"
                                            onClick={() => {
                                              toggleTab(activeTab - 1, "minus");
                                            }}
                                          >
                                            <b className="text-muted">Voltar</b>
                                          </button>
                                        </Col>
                                        <Col lg={8}>
                                          <button className="btn btn-success fs-5 w-100 my-1">
                                            <b>Contratar</b>
                                            <i className="bx bx-right-arrow-alt align-middle fs-4 ms-1" />
                                          </button>
                                        </Col>

                                        <p className="text-muted text-end mb-0 mt-2" style={{ fontSize: 12 }}>
                                          Todos os seus dados estão 100% seguros e protegidos.
                                        </p>
                                      </Row>
                                    </AvForm>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div className="row justify-content-center">
                              {paymentSuccess ? <AppmaxWarning user={userData} step={2} /> : null}
                              <Col lg="6">
                                {paymentSuccess ? (
                                  <div className="text-center">
                                    <div className="mb-4">
                                      {/* <i className="mdi mdi-check-circle-outline text-success display-4" /> */}
                                      <Lottie options={success_check_options} height={150} width={150} />
                                    </div>
                                    <div>
                                      <h3>Pagamento Efetuado!</h3>
                                      <p className="text-muted fs-5">
                                        Parabéns pela decisão de crescer! Conte conosco para o que precisar,{" "}
                                        {getNomeUsuario().split(" ")[0].toLowerCase()}. 😉
                                      </p>
                                      <div className="dashed-border">
                                        <p className="text-muted fs-5 m-0">
                                          Os dados de confirmação da compra foram enviados para o email:
                                          <br />
                                          <b className="text-dark">{userData.email}</b>
                                        </p>
                                      </div>
                                    </div>
                                    <button
                                      className="btn btn-primary fs-5 px-5 py-2 my-4"
                                      onClick={() => {
                                        window.localStorage.setItem("trial", false);
                                        window.localStorage.setItem("trial_expires_at", null);

                                        window.location.href = "/brands";
                                      }}
                                    >
                                      Voltar para a Plataforma
                                    </button>
                                  </div>
                                ) : (
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-close-circle-outline text-danger display-4" />
                                    </div>
                                    <div>
                                      <h5>Ops.. Houve um problema no pagamento</h5>
                                      <p className="text-muted">Não se preocupe, nosso time entrará em contato para te ajudar! 😉</p>
                                      <Link
                                        className="btn btn-light fs-5 w-100"
                                        to="#"
                                        onClick={() => {
                                          setactiveTab(2);
                                        }}
                                      >
                                        <b>Tentar novamente</b>
                                      </Link>
                                    </div>
                                  </div>
                                )}
                              </Col>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row style={{ minHeight: "50vh" }}>
              <Col className="d-flex justify-content-center align-items-center text-center">
                <div>
                  <img src="https://cdn-icons-png.flaticon.com/512/7486/7486750.png" alt="broken" style={{ height: 150 }} />
                  <h3 className="text-dark mt-2">
                    Ops... <br />
                    Por favor, retorne para a{" "}
                    <a href="https://v1.metrito.com/plans">
                      página de planos <br />
                    </a>
                  </h3>
                  <Link className="btn btn-primary mt-5 shadow-lg" to="/plans" style={{ fontSize: 17 }}>
                    Voltar para Planos <br />
                  </Link>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};

export default Payment;
