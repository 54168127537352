function mergeSum(key) {
  const sum = Object.entries(
    key.reduce((a, { action_type, value }) => {
      a[action_type] = parseFloat(a[action_type] ?? 0) + parseFloat(value);
      return a;
    }, {})
  ).map(([action_type, value]) => ({
    action_type,
    value: value.toString(),
  }));

  return sum;
}

function mergeAverage(key) {
  const average = Object.entries(
    key.reduce((a, { action_type, value }) => {
      a[action_type] = parseFloat(a[action_type] ?? 0) + parseFloat(value);

      return a;
    }, {})
  ).map(([action_type, value]) => {
    return {
      action_type,
      value: String(
        value / key.filter((i) => i.action_type === action_type).length
      ),
    };
  });

  return average;
}

const merge = (data, mergedArrays, isSummary) => {
  const result = {};

  data?.forEach((basket) => {
    for (let [key, value] of Object.entries(basket)) {
      if (result[key]) {
        switch (key) {
          case "date_start":
            result[key] = value;
            break;
          case "date_stop":
            result[key] = value;
            break;
          case "actions":
            result[key] = result[key].concat(value);
            result[key] = mergeSum(result[key]);
            break;
          case "website_purchase_roas":
            result[key] = result[key].concat(value);
            result[key] = mergeAverage(result[key]);
            break;
          case "website_ctr":
            result[key] = result[key].concat(value);
            result[key] = mergeAverage(result[key]);
            break;
          case "cost_per_action_type":
            result[key] = result[key].concat(value);
            result[key] = mergeAverage(result[key]);
            break;
          case "action_values":
            result[key] = result[key].concat(value);
            result[key] = mergeSum(result[key]);
            break;
          default:
            result[key] = String(parseFloat(result[key]) + parseFloat(value));
            break;
        }
      } else {
        result[key] = value;
      }
    }
  });

  if (isSummary) {
    result["cpc"] = (result["cpc"] / mergedArrays.length || 0).toString();
    result["ctr"] = (result["ctr"] / mergedArrays.length || 0).toString();
    result["cpp"] = (mergedArrays.length || 0).toString();
    result["cpm"] = (result["cpm"] / mergedArrays.length || 0).toString();
    result["frequency"] = (
      result["frequency"] / mergedArrays.length || 0
    ).toString();
  } else {
    result["cpc"] = (
      result["cpc"] /
        mergedArrays.filter(
          (i) => i["cpc"] && i["date_start"] === result["date_start"]
        ).length || 0
    ).toString();
    result["ctr"] = (
      result["ctr"] /
        mergedArrays.filter(
          (i) => i["ctr"] && i["date_start"] === result["date_start"]
        ).length || 0
    ).toString();
    result["cpp"] = (
      result["cpp"] /
        mergedArrays.filter(
          (i) => i["cpp"] && i["date_start"] === result["date_start"]
        ).length || 0
    ).toString();
    result["cpm"] = (
      result["cpm"] /
        mergedArrays.filter(
          (i) => i["cpm"] && i["date_start"] === result["date_start"]
        ).length || 0
    ).toString();
    result["frequency"] = (
      result["frequency"] /
        mergedArrays.filter(
          (i) => i["frequency"] && i["date_start"] === result["date_start"]
        ).length || 0
    ).toString();
  }

  return result;
};

//allArrays is the array of arrays with all Ad Accounts insights data
export const helper = (mergedArrays, isSummary) => {
  var mergedLength = mergedArrays.length;
  console.log(mergedArrays);

  var iii = 1;

  const empty = [];

  if (isSummary) {
    return merge(mergedArrays, mergedArrays, true);
  } else if (!isSummary && mergedArrays.length > 0) {
    mergedArrays.forEach((obj) => {
      const filtered =
        mergedArrays.filter((o) => o.date_start === obj.date_start) || null;

      const newItem = merge(filtered, mergedArrays, false);

      if (!empty.some((e) => e.date_start === newItem.date_start)) {
        empty.push(newItem);
      }

      // console.log(iii + " / " + mergedLength);
      // iii++;
    });

    empty.sort(
      (a, b) =>
        new Date(a.date_start).getTime() - new Date(b.date_start).getTime()
    );

    return empty;
  }
};
