export const IntegrationHeader = (props) => {
  return (
    <div className="d-flex flex-row">
      <div className="float-start d-flex flex-column justify-content-center">
        <div className="avatar-sm mx-auto">
          <span className="avatar-title rounded-circle bg-light font-size-24">
            <i className={`${props.icon}`} style={{ color: props.color }}></i>
          </span>
        </div>
      </div>
      <div className="px-2 d-flex flex-column justify-content-center">
        <span className="d-block text-muted" style={{ fontSize: 11 }}>
          integração
        </span>
        <span className="d-block" style={{ fontSize: 16, fontWeight: 600 }}>
          {props.platform}
        </span>
      </div>
    </div>
  );
};
