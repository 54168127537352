import React from "react";
import { Col, UncontrolledAlert } from "reactstrap";

const StatusCard = (props) => {
  function blockOrNot(param) {
    switch (param) {
      case 1:
        return false;
        break;
      case 2:
        return true;
        break;
      default:
        return false;
    }
  }
  const widgetArray = [
    {
      id: 1,
      title: "Status da Conta",
      count: blockOrNot(props.account.account_status)
        ? "Conta Bloqueada"
        : "Conta Ativa",
      icon: "mdi mdi-cash-multiple text-primary",
      percentage: "2.65%",
      color: blockOrNot(props.account.account_status) ? "red" : "#29AC77",
      button: true,
    },
    {
      id: 2,
      title: "Campanhas Ativas",
      dollor: false,
      count: props.activeCampaigns,
      icon: "mdi mdi-cash-multiple text-primary",
      percentage: "2.65%",
      color: null,
      button: false,
    },
    {
      id: 3,
      title: "Gasto total na conta",
      dollor: true,
      count: (parseFloat(props.account.amount_spent) / 100).toLocaleString(
        "pt-BR",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      ),
      icon: "mdi mdi-cash-multiple text-primary",
      percentage: "2.65%",
      color: null,
      button: false,
    },
    {
      id: 4,
      title: "A pagar",
      dollor: true,
      count: parseFloat(props.account.balance / 100).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      icon: "mdi mdi-cash-multiple text-primary",
      percentage: "2.65%",
      color: null,
      button: false,
    },
  ];

  const list = [1];

  return (
    <React.Fragment>
      <Col md={12} xl={12}>
        {blockOrNot(props.account.account_status) ? (
          <UncontrolledAlert color="danger" role="alert">
            <i className="mdi mdi-block-helper me-2"></i>Conta de Anúncios
            Bloqueada!
          </UncontrolledAlert>
        ) : (
          <UncontrolledAlert
            color="success"
            role="alert"
            style={{
              border: "none",
              textAlign: "middle",
            }}
            className="text-dark card flex-row align-middle"
          >
            {/* <i className="mdi mdi-check-all me-2"></i>Conta de Anúncios Ativa */}
            <span
              className="spinner-grow text-success mx-3 ms-0 fs-6"
              style={{ height: 10, width: 10, margin: "auto" }}
              role="status"
            ></span>
            Conta de Anúncios Ativa
          </UncontrolledAlert>
        )}

        {/* <Card style={{ minHeight: 400 }}>
          <CardBody>
            <CardTitle className="mb-4">Resumo da Conta de Anúncios</CardTitle>

            <h6>
              Status da conta:
              <span style={{ fontWeight: 500, marginLeft: 10 }}>
                <i
                  className="bx bxs-circle me-1"
                  style={{
                    fontSize: 10,
                    color: blockOrNot(props.account.account_status).color,
                  }}
                ></i>
                
              </span>
            </h6>

            <h6>
              Total já gasto na conta:
              <span style={{ fontWeight: 500, marginLeft: 10 }}>
                {props.account.currency}{" "}
                {parseFloat(props.account.amount_spent).toLocaleString("pt-BR")}
              </span>
            </h6>

            <h6>
              Valor a pagar:
              <span style={{ fontWeight: 500, marginLeft: 10 }}>
                {props.account.currency}{" "}
                {parseFloat(props.account.balance).toLocaleString("pt-BR")}
              </span>
            </h6>

            <h6>
              Campanhas Ativas:
              <span style={{ fontWeight: 500, marginLeft: 10 }}>
                {props.activeCampaigns}
              </span>
            </h6>

            <h6>
              Lance máximo:
              <span style={{ fontWeight: 500, marginLeft: 10 }}>
                {props.account.currency}{" "}
                {parseFloat(
                  props.account?.max_bid?.data[0].max_bid
                ).toLocaleString("pt-BR")}
              </span>
            </h6>
          </CardBody>
        </Card> */}
      </Col>
    </React.Fragment>
  );
};

export default StatusCard;
