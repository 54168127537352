import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Loader } from "rsuite";

//Services
import api from "../../services/api";
// import Loader from "../../components/Common/Loader";

//Custom Layout
import { datePresets } from "../../common/data/datePresets";

import DashboardRenderer from "../Dashboard/DashboardRenderer";

//Widgets List
import moment from "moment";
import Widgets from "../../widgetsV1";

import InsightsComponent from "../../components/InsightsComponent";
import CustomInput from "../Builder/CustomInput";

import { setDashboard } from "../../store/dashboard/actions";

const options = [
  { label: "Thing 1", value: 1 },
  { label: "Thing 2", value: 2 },
];
const customStyles = {
  dropdownButton: (styles) => ({
    ...styles,
    width: 300,
    borderRadius: 10,
    paddingLeft: 20,
    paddingRight: 10,
    boxShadow: "none",
    border: "1px solid #ced4da",
  }),
  container: () => ({
    borderRadius: 10,
    border: "1px solid #ced4da",
    width: 300,
    transition: "all 1000ms",
  }),
};

const Dashboard = (props) => {
  const { dashboardId, brandId } = useParams();
  const [dashboardData, setDashboardData] = useState({});

  const [loading, setLoading] = useState(true);
  const [isLoadingInsights, setLoadingInsights] = useState(false);

  const [subscribemodal, setSubscribemodal] = useState(false);

  const [brand, setBrand] = useState({});

  const [datePreset, setDatePreset] = useState(datePresets[0]);
  const [timeRange, setTimeRange] = useState("");
  const [dateParam, setDateParam] = useState(
    "&date_preset=" + datePresets[0].preset
  );
  const [dateText, setDateText] = useState(datePresets[0].name);

  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  function tog_standard() {
    setSubscribemodal(!setSubscribemodal);
  }

  //Get the selected Brand data
  async function loadBrandData() {
    setLoading(true);
    const response = await api
      .get("/api/v1/brands/details/" + brandId)
      .then((res) => {
        setBrand(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  //Faz o Fetch do Status da Conta de Anúncios
  async function verifyAdaccount(id, token) {
    const response = await api
      .post("/api/fb/adaccount", {
        ds_id: id,
        token: token,
      })
      .then((res) => {
        props.setAdaccountStatus(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getDashboardData() {
    setLoading(true);
    const data = await api
      .post("/api/dashboards/details/" + dashboardId, { owner_id: brand.owner })
      .then((res) => {
        setDashboardData(res.data);
        props.setDashboard(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  async function viewCounter() {
    api
      .put(`/api/dashboards/view/${dashboardId}`)
      .then((res) => {
        // console.log(`${res.data.views} views`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    loadBrandData();
  }, []);

  useEffect(() => {
    if (brand.owner) {
      getDashboardData();
      viewCounter();
    }
  }, [brand]);

  return (
    <React.Fragment>
      <div
        className={props.className || "page-content"}
        style={{ minHeight: 1000 }}
      >
        <MetaTags>
          <title>Dashboard | Metrito</title>
        </MetaTags>

        <Container fluid style={{ marginTop: -33 }}>
          {loading ? (
            <div className="d-flex justify-content-center w-100 mt-5">
              <Loader className="me-2" /> Carregando dados do Dashboard...
            </div>
          ) : !loading && dashboardData?.success === false ? (
            <Row>
              <Col md={12}>
                <div className="text-center my-5 pt-sm-5">
                  <div>
                    <Row className="justify-content-center">
                      <Col sm={5}>
                        <div>
                          <img
                            src={
                              "https://cdn-icons-png.flaticon.com/512/7440/7440637.png"
                            }
                            alt=""
                            className="img-fluid mx-auto d-block mb-4"
                            style={{ maxHeight: 150 }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <h4 className="text-uppercase mt-4">
                    Encontramos um problema com a sua conta
                  </h4>
                  <p className="text-muted fs-5">
                    Sua conta está temporariamente desativada. Entre em contato
                    com o nosso suporte para reativá-la.
                  </p>
                  <div className="mt-5">
                    <a
                      className="btn btn-primary waves-effect waves-light px-5"
                      target={"_blank"}
                      href="https://api.whatsapp.com/send?phone=556297011897&text=Ol%C3%A1%2C%20gostaria%20de%20reativar%20minha%20conta%20%F0%9F%98%80"
                    >
                      <b className="fs-5">Reativar Acesso</b>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          ) : !loading &&
            dashboardData &&
            Object.keys(dashboardData).length > 0 &&
            brand &&
            !brand.error ? (
            <div>
              <Row
                className="border-0 card"
                style={{
                  marginBottom: 15,
                  marginTop: -20,
                  marginLeft: -30,
                  marginRight: -30,
                  padding: 10,
                  borderRadius: 0,
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <Row className="w-100">
                    <Col
                      lg={4}
                      xs={12}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div className="ms-3">
                        <h5 className="mt-2 align-middle mb-1">
                          {dashboardData.name}
                        </h5>
                        <span
                          className="text-muted"
                          style={{ fontSize: 12, marginLeft: 1 }}
                        >{`atualizado em ${moment(
                          dashboardData.updatedAt
                        ).format("DD/MM/YYYY - HH:mm")}`}</span>
                      </div>
                    </Col>

                    <Col
                      lg={8}
                      xs={12}
                      className="d-flex align-items-center justify-content-end"
                    >
                      {!dashboardData.version || dashboardData.version < 3 ? (
                        <CustomInput
                          dashboard={dashboardData}
                          displayType={1}
                          loadingInsights={isLoadingInsights}
                          setLoadingInsights={setLoadingInsights}
                          dashboardBrandId={dashboardData.brandId}
                          selectedAdAccounts={selectedAdAccounts}
                          setSelectedAdAccounts={setSelectedAdAccounts}
                          selectedCampaigns={selectedCampaigns}
                          setSelectedCampaigns={setSelectedCampaigns}
                        />
                      ) : (
                        <InsightsComponent
                          dashboard={dashboardData}
                          displayType={1}
                          setLoadingInsights={setLoadingInsights}
                          dashboardBrandId={dashboardData.brandId}
                          dashboardWidgets={dashboardData.layout}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Row>

              {dashboardData.version || dashboardData.version < 3 ? (
                <section id="new-dashboard">
                  <DashboardRenderer
                    dashboard={dashboardData}
                    layouts={dashboardData.layout}
                    widgetArray={dashboardData.layout}
                    selectedAdAccounts={selectedAdAccounts}
                    dashboardVersion={dashboardData.version}
                    {...props}
                  />
                </section>
              ) : (
                <section id="dashboard">
                  <Widgets layout={dashboardData.layout || []} />
                </section>
              )}
            </div>
          ) : !loading && (!dashboardData || brand.error) ? (
            <Row
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Col md={12}>
                <div className="text-center">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/7486/7486764.png"
                    alt=""
                    className="img-fluid mx-auto d-block"
                    style={{ width: 120 }}
                  />

                  <h4 className="text-uppercase mt-4">
                    Ops.. Este Dashboard não existe!
                  </h4>
                  <p className="text-muted">
                    Verifique se o dashboard que você está tentando acessar não
                    foi excluído ou se o link de compartilhamento está 100%
                    correto.
                  </p>
                  <div className="mt-5">
                    <Link
                      className="btn btn-primary waves-effect waves-light"
                      to="/dashboards"
                    >
                      Voltar para os Dashboards
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setDashboard: (id) => dispatch(setDashboard(id)),
});

export default connect(null, mapDispatchToProps)(Dashboard);
