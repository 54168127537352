import { Nav } from "rsuite";

const Navbar = ({ active, onSelect, ...props }) => {
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      className="d-flex justify-content-around mb-3"
      style={{ marginRight: -20, marginLeft: -20 }}
    >
      <Nav.Item eventKey="history" className="w-100">
        <i className="mdi mdi-history me-2" />
        Histórico
      </Nav.Item>
      <Nav.Item eventKey="saved" className="w-100">
        <i className="mdi mdi-bookmark me-2" />
        Salvos
      </Nav.Item>
    </Nav>
  );
};

export default Navbar;
