import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import api from "../../services/api";
import InfoModal from "../Connections/InfoModal";
import AdaccountTable from "../Facebook/AdaccountTable";
import EditBrandModal from "./EditBrandModal";

import DashboardsTable from "../Dashboard/DashboardsTable";

//Toast
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { useParams } from "react-router-dom";
import GatewayConnections from "../Gateways/GatewayConnections";
import { getImageURL } from "../../functions/getImageURL";

const PagesStarter = (props) => {
  const { brandId } = useParams();
  const [brand, setBrand] = useState({});
  const [adaccounts, setAdaccounts] = useState([]);
  const [dashboards, setDashboards] = useState([]);

  const [loading, setLoading] = useState(true);

  //Get the selected Brand data
  async function loadBrandData() {
    setLoading(true);
    api
      .get("/api/v1/brands/details/" + brandId)
      .then((result) => {
        setBrand(result.data);
        setAdaccounts(result.data.ad_accounts);
      })
      .catch((err) => {
        window.location.href = "/404";
      });

    setLoading(false);
  }

  //Faz o Fetch dos Dashboards do Usuário
  async function loadDashboards() {
    const response = await api
      .get("/api/dashboards/" + brandId)
      .then((res) => {
        if (res.data != 0) {
          setDashboards(res.data);
        } else {
          setDashboards(res.data);

          toast.info("Você ainda não adicionou Dashboards.");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Não foi possível carregar seus Dashboards.");
      });
  }

  async function handleDelete(id) {
    const result = await api.delete("/api/v1/brands/" + id);
    if (result.status === 200) {
      console.log("Conta deletada com sucesso");
    } else {
      console.log("Erro ao deletar conta");
    }
  }

  useEffect(() => {
    loadBrandData();
    loadDashboards();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Empresa | Metrito</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Início" breadcrumbItem="Home" />

          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col lg={6}>
                  <div className="d-flex flex-row justify-content-start px-4">
                    <img
                      src={getImageURL(brand.logo || "brand_placeholder.png")}
                      alt="image"
                      style={{
                        borderRadius: 10,
                        backgroundColor: "#fff",
                        height: 80,
                        objectFit: "contain",
                        width: 150,
                      }}
                    />
                    <div className="d-flex flex-column justify-content-center ms-3">
                      <h2>{brand.name}</h2>
                      <p className="text-muted mb-0">{brand.description}</p>
                    </div>
                  </div>
                </Col>
                <Col
                  lg={6}
                  className="d-flex flex-row align-items-center justify-content-end"
                >
                  <div>
                    {/* <Link to="#" className="btn btn-light px-5">
                      <i className="bx bxs-pencil me-2" />
                      Editar Empresa
                    </Link> */}

                    <EditBrandModal
                      brand={brand}
                      loadBrandData={loadBrandData}
                    />
                    {/* <button
                      className="btn btn-primary"
                      onClick={() => setTest(test + 1)}
                    >
                      Adicionar
                    </button> */}
                  </div>
                </Col>
              </Row>

              <GatewayConnections brand={brand} loadBrandData={loadBrandData} />

              <Row>
                {!loading && dashboards.length <= 0 ? <InfoModal /> : null}
                <Col lg={6}>
                  <DashboardsTable dashboards={dashboards} loading={loading} />
                </Col>

                <Col lg={6}>
                  <AdaccountTable adaccounts={adaccounts} loading={loading} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PagesStarter;
