import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { detectMobileBrowser } from "../../functions/detectMobileBrowser";
import moment from "moment";
import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
// reactstrap

// Import menuDropdown
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logoLight from "../../assets/images/metrito-white-vector.svg";
import logoSmLight from "../../assets/images/logo-sm-light.png";
import logoSm from "../../assets/images/logo-sm.svg";
import logoDark from "../../assets/images/metrito-vector.svg";

import { Card, CardBody } from "reactstrap";
import { Progress } from "rsuite";

import { changeTopbarTheme } from "../../store/actions";
import { getRemainingDays } from "../../functions/getRemainingDays";

const Header = (props) => {
  const [socialDrp, setsocialDrp] = useState(false);
  const [trialData, setTrialData] = useState({});

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  try {
    $(".insights_progress div div").css({
      height: "7px",
    });
  } catch (e) {
    console.log(e);
  }

  useEffect(() => {
    // props.changeTopbarTheme("dark");

    if (window.innerWidth <= 900) {
      props.changeTopbarTheme("dark");
    }
  });

  useEffect(() => {
    setTrialData(
      getRemainingDays(
        props.user.createdAt,
        props.user.trial_expires_at,
        moment().format("YYYY-MM-DD, HH:mm:ss")
      )
    );
  }, [props.user]);

  useEffect(() => {
    if (trialData.percentageRemaining >= 70) {
      $(".progress-stripes > div > div > div").css(
        "background",
        `repeating-linear-gradient( 45deg,#3e6ce0,#3e6ce0 10px,#1e55eb 10px, #1e55eb 20px)`
      );
    } else if (
      trialData.percentageRemaining < 70 &&
      trialData.percentageRemaining >= 40
    ) {
      $(".progress-stripes > div > div > div").css(
        "background",
        `repeating-linear-gradient( 45deg,#ffb649,#ffb649 10px,#fe9d02 10px, #fe9d02 20px)`
      );
    } else if (trialData.percentageRemaining < 40) {
      $(".progress-stripes > div > div > div").css(
        "background",
        `repeating-linear-gradient( 45deg,#e05e5e,#e05e5e 10px, #E13C3C 10px, #E13C3C 20px)`
      );
    }
  }, [trialData]);

  return (
    <>
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box d-flex align-items-center">
                <Link
                  to={
                    !/plans/.test(window.location.href) &&
                    !/pricing/.test(window.location.href) &&
                    !/onboarding/.test(window.location.href)
                      ? "/"
                      : "#"
                  }
                  className="logo logo-dark"
                >
                  <span className="logo-sm">
                    <img
                      className="header-logo"
                      src={logoSm}
                      alt=""
                      height="25"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      className="header-logo"
                      src={logoDark}
                      alt=""
                      height="25"
                    />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      className="header-logo"
                      src={logoSmLight}
                      alt=""
                      height="25"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      className="header-logo"
                      src={logoLight}
                      alt=""
                      height="25"
                    />
                  </span>
                </Link>

                {/metrito-ai/.test(window.location.href) && (
                  <span
                    className="ms-2 fs-3 text-primary"
                    style={{ marginTop: 6 }}
                  >
                    AI
                  </span>
                )}
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                data-toggle="collapse"
                onClick={() => {
                  props.toggleLeftmenu(!props.leftMenu);
                }}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>

              {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                />
                <span className="mdi mdi-magnify" />
              </div>
            </form> */}
            </div>

            <div className="d-flex">
              {/* <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                caret
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg">
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <a
                        className="dropdown-icon-item"
                        href="https://ev.braip.com/"
                        target="_blank"
                      >
                        <img
                          src="https://braip.com/wp-content/uploads/2022/01/logo-braip.svg"
                          alt="Braip"
                          target="_blank"
                          style={{ maxWidth: 50 }}
                        />
                        <span>Braip</span>
                      </a>
                    </Col>
                    <Col>
                      <a
                        className="dropdown-icon-item"
                        href="https://app-vlc.hotmart.com/"
                        target="_blank"
                      >
                        <img
                          src="https://logodownload.org/wp-content/uploads/2018/03/hotmart-logo.png"
                          alt="Hotmart"
                          target="_blank"
                        />
                        <span>Hotmart</span>
                      </a>
                    </Col>
                    <Col>
                      <a
                        className="dropdown-icon-item"
                        href="https://www.iugu.com/"
                        target="_blank"
                      >
                        <img
                          src="https://i.pinimg.com/originals/8f/09/38/8f09386a6659a77bb16247122759fd78.png"
                          alt="Iugu"
                          target="_blank"
                        />
                        <span>Iugu</span>
                      </a>
                    </Col>
                    
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}

              {/* <Dropdown
                className="d-none d-lg-inline-block ms-1"
                isOpen={socialDrp}
                toggle={() => {
                  setsocialDrp(!socialDrp);
                }}
              >
                <DropdownToggle
                  className="btn header-item noti-icon "
                  caret
                  tag="button"
                >
                  <i className="bx bx-help-circle" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg">
                  <div className="px-lg-2">
                    <Row className="no-gutters">
                      <Col>
                        <a
                          className="dropdown-icon-item"
                          href="https://metrito.com/conectando-contas-de-anuncio/"
                          target="_blank"
                        >
                          <i className="bx bxl-facebook-circle fs-1"></i>
                          <span>
                            Como conectar Contas de Anúncio <br></br> do
                            Facebook no Metrito
                          </span>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </Dropdown> */}

              {/* <NotificationDropdown /> */}

              {props.user.trial && !detectMobileBrowser() ? (
                <Card
                  className="m-1 mx-3"
                  style={{ border: "2px solid #e0dfed" }}
                >
                  <CardBody className="p-1 px-3 d-flex align-items-center">
                    {trialData?.remainingTimeUnix <= 0 ? (
                      <b className="text-danger d-flex align-items-center">
                        <i className="bx bx-error-circle me-3 fs-4"></i> Seu
                        período de teste expirou, faça<br></br> upgrade para
                        continuar usando o Metrito.
                      </b>
                    ) : (
                      <div>
                        <b>
                          Teste expira{" "}
                          {moment(props.user.trial_expires_at).fromNow()}
                        </b>
                        <Progress.Line
                          className="insights_progress m-0 p-0 mt-1 progress-stripes"
                          percent={trialData.percentageRemaining}
                          strokeColor={"#E13C3C"}
                          showInfo={false}
                          style={{
                            width: "100%",
                            maxWidth: 800,
                            marginTop: 20,
                          }}
                        />
                      </div>
                    )}
                    <Link
                      to="/plans"
                      className="btn btn-success btn-green text-truncate ms-3"
                    >
                      <i className="bx bx-arrow-up"></i>
                      Fazer Upgrade
                    </Link>
                  </CardBody>
                </Card>
              ) : null}

              <ProfileMenu />

              <div id="navbar-cog-icon" className="dropdown d-inline-block">
                <button
                  onClick={() => {
                    props.showRightSidebarAction(!props.showRightSidebar);
                  }}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle "
                >
                  <i className="bx bx-cog" />
                </button>
              </div>

              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  className="btn header-item noti-icon "
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen" />
                </button>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    </>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, changeTopbarTheme } =
    state.Layout;
  const { user, isLoadingUser } = state.User;

  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    changeTopbarTheme,
    user,
    isLoadingUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeTopbar: (topbarTheme) => dispatch(changeTopbarTheme(topbarTheme)),
});

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeTopbarTheme,
})(Header);
