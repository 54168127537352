import classnames from "classnames";
import React, { useState } from "react";

import { Handle, Position } from "react-flow-renderer";

export default function FacebookNode({ data }) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div
      className={classnames("", {
        "node-selected": isEditing,
        "node-unselected": !isEditing,
      })}
    >
      <div
        className="px-3 bg-primary d-flex justify-content-center align-items-center"
        style={{ width: 50, height: 50, borderRadius: "50%", padding: 0 }}
        onClick={() => setIsEditing(true)}
      >
        <div>
          <i
            className="mdi mdi-facebook text-white"
            style={{ fontSize: 30 }}
          ></i>
        </div>
        <Handle
          type="output"
          position={Position.Left}
          id="a"
          className={classnames("node-input-output", {
            "d-block": isEditing,
            "d-none": !isEditing,
          })}
          style={{ marginLeft: -3 }}
        />
        <Handle
          type="input"
          position={Position.Right}
          id="a"
          className={classnames(
            "node-input-output d-flex justify-content-center align-items-center",
            {
              "d-block": isEditing,
              "d-none": !isEditing,
            }
          )}
          style={{ marginRight: -3 }}
        >
          <i
            className="mdi mdi-arrow-right"
            style={{ fontSize: 10, color: "#fff" }}
          ></i>
        </Handle>
      </div>
    </div>
  );
}
