import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Input, Button, Loader } from "rsuite";
import OtpInput from "react-otp-input";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/ok_lottie.json";
import Logo from "../../assets/images/metrito-vector.svg";
import ChangePhoneNumberModal from "./Modals/ChangePhoneNumber";
import MetaTags from "react-meta-tags";

const lottieDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const CheckVerificationCode = () => {
  const [user, setUser] = useState({});
  const [otp, setOtp] = useState("");
  const [canSendCode, setCanSendCode] = useState(true);
  const [verificationCode, setVerificationCode] = useState({});
  const [waitingTime, setWaitingTime] = useState(0);
  const [isVerified, setIsVerified] = useState(false);
  const [checkingCode, setCheckingCode] = useState(false);
  const [sendingVerificationCode, setSendingVerificationCode] = useState(false);
  const [seenVerificationNotice, setSeenVerificationNotice] = useState(false);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleSubmit = () => {
    // Handle the submission of the verification code
    console.log("Verification code:", otp);
  };

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      handleSubmit();
    }
  }

  function sendVerificationCode() {
    setSendingVerificationCode(true);
    // Send the verification code to the user
    api
      .get(`/api/v1/user/verification-code.send/${getIdUsuario()}`)
      .then((response) => {
        console.log(response.data);
        console.log("Verification code sent");
        setWaitingTime(30);
        setCanSendCode(false);

        toast.success("Código de verificação enviado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSendingVerificationCode(false);
      });
  }

  function checkVerificationCode() {
    // Check if the verification code is correct
    setCheckingCode(true);
    api
      .post(`/api/v1/user/verification-code.check/${getIdUsuario()}`, {
        code: otp,
      })
      .then((response) => {
        console.log(response.data.message);
        console.log("Verification code sent");
        setIsVerified(true);

        setTimeout(() => {
          window.location.href = user.onboarding ? "/onboarding" : "/home";
        }, 2000);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response?.data?.message);
        setOtp("");
      })
      .finally(() => {
        setCheckingCode(false);
      });
  }

  function loadUserData() {
    api
      .get(`/api/usuarios.details/${getIdUsuario()}`)
      .then((response) => {
        setUser(response.data[0]);

        if (response.data[0].phone_verification?.verified) {
          setIsVerified(true);
          console.log("User is already verified");
          window.location.href = user.onboarding ? "/onboarding" : "/home";
        } else {
          console.log("User is not verified yet, sending verification code...");
          sendVerificationCode();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (!canSendCode) {
      for (let i = 0; i < waitingTime; i++) {
        setTimeout(() => {
          setWaitingTime(waitingTime - i);
        }, 1000 * i);
      }

      setTimeout(() => {
        setCanSendCode(true);
      }, waitingTime * 1000);
    }
  }, [canSendCode]);

  useEffect(() => {
    if (otp.length === 6) {
      checkVerificationCode();
    }
  }, [otp]);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      loadUserData();
    }
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Verificação de segurança</title>
      </MetaTags>

      <div className="page-content">
        <Container>
          {isVerified ? (
            <Row
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
              }}
            >
              <Col lg={12}>
                <div className="d-flex flex-column justify-content-center text-center align-items-center w-100 h-100 p-5">
                  <Lottie
                    options={lottieDefaultOptions}
                    height={"auto"}
                    width={200}
                    style={{ margin: "none" }}
                  />
                  <h4>Identidade verificada!</h4>
                  <span className="text-muted">
                    Caso não seja redirecionado em menos de 10 segundos, clique
                    no botão abaixo...
                  </span>
                  <Button className="btn btn-success d-flex align-items-center mt-4">
                    Ir para a plataforma
                    <i className="mdi mdi-arrow-right ms-2" />
                  </Button>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={12}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <img
                    src="https://arkesel.com/assets/img/block-img/otp-illustrations.png"
                    alt="whatsapp verification"
                    style={{ maxHeight: 200, margin: 50 }}
                  />

                  <div className="mb-5 text-center">
                    <h1>Verificação de Telefone</h1>
                    <span className="text-muted">
                      <br />
                      Caso precise de um novo código, ao clicar no botão "Enviar
                      Código" uma nova combinação de seis digitos será enviada
                      para seu WhatsApp, cole este código abaixo.
                    </span>
                  </div>

                  <div className="d-none d-lg-block">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      inputStyle={{
                        width: "5em",
                        height: "5rem",
                        margin: "0 1rem",
                        fontSize: "1.5rem",
                        borderRadius: 4,
                        border: "1px solid rgba(0,0,0,0.1)",
                      }}
                      renderInput={(props) => <input {...props} />}
                      disabled={checkingCode}
                    />
                  </div>

                  <div className="d-block d-lg-none">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      inputStyle={{
                        width: "40px",
                        height: "40px",
                        margin: 5,
                        fontSize: "18px",
                        borderRadius: 4,
                        border: "1px solid rgba(0,0,0,0.1)",
                      }}
                      renderInput={(props) => <input {...props} />}
                      disabled={checkingCode}
                    />
                  </div>

                  {sendingVerificationCode ? (
                    <div className="d-flex align-items-center mt-4">
                      <Loader className="me-3" />
                      <span className="text-muted fs-5">
                        Enviando código de verificação...
                      </span>
                    </div>
                  ) : (
                    <b className="text-success fs-5 mt-3">
                      Um código foi enviado para o WhatsApp do número{" "}
                      {user.tel_number}
                    </b>
                  )}

                  <div className="d-flex align-items-center justify-content-around">
                    <ChangePhoneNumberModal
                      user={user}
                      loadUserData={loadUserData}
                    />
                    <Button
                      className="px-4 m-4"
                      color="primary"
                      onClick={() => sendVerificationCode()}
                      disabled={!canSendCode || checkingCode || isVerified}
                      loading={checkingCode}
                    >
                      {canSendCode ? (
                        <span>Enviar novo código</span>
                      ) : (
                        <span>Reenviar código em {waitingTime} segundos</span>
                      )}
                    </Button>
                  </div>

                  {checkingCode && (
                    <div className="d-flex align-items-center mt-4">
                      <Loader className="me-3" />
                      <span className="text-muted fs-5">
                        Verificando código...
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          )}

          {!seenVerificationNotice && (
            <div
              id="blur_screen"
              style={{
                height: "100vh",
                width: "100vw",
                backdropFilter: "blur(25px)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 2,
              }}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <img
                src={Logo}
                alt="metrito"
                style={{ maxWidth: 150, position: "absolute", top: 20 }}
              />
              <Card className="shadow-sm p-3" style={{ maxWidth: "60%" }}>
                <CardBody className="d-flex flex-column ">
                  <h4 className="mb-4">
                    Só precisamos checar se você não é um robô 🤖
                  </h4>
                  <span style={{ fontSize: 20 }}>
                    Para aumentar a segurança dos dados e garantir a
                    consistência dos usuários em nossa plataforma, estamos
                    implementando uma medida que exige a verificação de um
                    número de celular para continuar utilizando nossos serviços
                    normalmente. Dessa forma, podemos garantir uma experiência
                    mais segura e confiável aos nossos usuários.
                    <br />
                    <br />
                    Agradecemos a compreensão e estamos à disposição para
                    esclarecer qualquer dúvida. 😉
                  </span>

                  <Button
                    appearance="primary"
                    color="green"
                    className="mt-4 fs-5"
                    onClick={() => setSeenVerificationNotice(true)}
                  >
                    Prosseguir para verificação
                  </Button>
                </CardBody>
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CheckVerificationCode;
