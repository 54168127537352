import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import logoSm from "../../assets/images/logo-sm.svg";
import api from "../../services/api";

export const TemplateCard = (props) => {
  async function handleDelete(id) {
    const deleteLayout = await api
      .delete("/api/layouts", { data: { _id: id } })
      .then((res) => {
        toast.success("Template apagado!");

        var tempLayouts = [...props.layouts];
        const index = tempLayouts.indexOf(
          tempLayouts.find((data) => data._id === id)
        );
        tempLayouts.splice(index, 1);

        props.setLayouts(tempLayouts);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function generatePlaceholders() {
    var placeholders = [];
    for (var i = 0; i < 3; i++) {
      placeholders.push(
        <Col lg={4} md={6} sm={12} key={i}>
          <Card
            className="float-start mx-2 p-0"
            style={{
              width: "100%",
            }}
          >
            <CardBody>
              <h5 className="card-title placeholder-glow">
                <span
                  className="placeholder col-2 p-4 mb-3"
                  style={{ borderRadius: 5 }}
                ></span>
              </h5>

              <h5 className="card-title placeholder-glow">
                <span className="placeholder col-6"></span>
              </h5>
              <p className="card-text placeholder-glow">
                <span className="placeholder col-7"></span>
                <span className="placeholder col-4"></span>
                <span className="placeholder col-4"></span>
                <span className="placeholder col-6"></span>
              </p>
            </CardBody>
          </Card>
        </Col>
      );
    }

    return placeholders;
  }
  return (
    <React.Fragment>
      {props.loadingTemplates ? (
        <Row>{generatePlaceholders()}</Row>
      ) : (
        <Col lg={4} md={6} sm={12}>
          <Card
            className={`float-start mx-2 ${
              props.isSelectable &&
              props.selectedLayout?._id != props.template._id
                ? "select-template"
                : null
            }`}
            onClick={() => {
              if (props.isSelectable) {
                props.setSelectedLayout(props.template);
              }
            }}
            style={{
              width: "100%",
              outline:
                props.selectedLayout?._id === props.template._id
                  ? "3px solid #00c444"
                  : "none",
              transition: "all 100ms",
            }}
          >
            <CardBody>
              <div
                style={{
                  width: "100%",
                  height: 65,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div
                  className="align-middle"
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    backgroundColor: `#${
                      props.template.icon_color || "fe9d02"
                    }14`,
                  }}
                >
                  <i
                    className={`${
                      props.template.icon || "bx bx-layout fs-3 text-secondary"
                    } fs-3`}
                  ></i>
                </div>
                {props.canDelete ? (
                  <div>
                    <UncontrolledDropdown>
                      <DropdownToggle role="button" tag="div">
                        <i className="bx bx-dots-vertical-rounded text-muted i-btn fs-4"></i>
                      </DropdownToggle>
                      <DropdownMenu className="text-muted">
                        {/* <DropdownItem>
                                <i className="mdi mdi-pencil" /> Editar
                              </DropdownItem> */}
                        <DropdownItem
                          className="text-dark"
                          onClick={() => handleDelete(props.template._id)}
                        >
                          <i className=" bx bx-trash-alt me-2" />
                          Apagar
                        </DropdownItem>
                        {/* <DropdownItem>
                                <i className="mdi mdi-share" /> Compartilhar
                              </DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="d-flex flex-row justify-content-between">
                  <h5
                    className="font-size-16 mt-0 d-inline-block text-truncate"
                    style={{ maxWidth: 300 }}
                  >
                    {props.template.name}
                  </h5>
                  {!props.template.version ? (
                    <span className="badge bg-danger my-1">descontinuado</span>
                  ) : null}
                  {props.template.privacy === "private" ? (
                    <i className="text-muted mt-1 bx bxs-lock-alt" />
                  ) : null}
                </div>
                {props.isMetritoDefault ? (
                  <p className="card-text text-muted" style={{ fontSize: 12 }}>
                    <img
                      src={logoSm}
                      alt="Template Metrito"
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: 10,
                      }}
                    />
                    Template Metrito
                  </p>
                ) : (
                  <p className="card-text text-muted" style={{ fontSize: 12 }}>
                    {props.template.createdAt ? (
                      `Criado 
                          ${moment(props.template.createdAt).fromNow()}`
                    ) : (
                      <br />
                    )}
                  </p>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </React.Fragment>
  );
};
