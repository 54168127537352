import React, { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { connect } from "react-redux";
import { defineComponent } from "../../functions/defineComponent";
import { defineComponentV2 } from "../../functions/defineComponentV2";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DashboardRenderer = (props) => {
  // console.log(`Dashboard version is ${props.dashboardVersion}`);  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isDraggable = props.isDraggable === undefined ? false : props.isDraggable;
  // const onLayoutChange = props.onLayoutChange === undefined ? undefined : onLayoutChange

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up after the effect:
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <ResponsiveReactGridLayout
        style={{ marginLeft: -30, marginRight: -30 }}
        layout={props.layouts}
        onLayoutChange={props.onLayoutChange}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        preventCollision={false}
        cols={{ lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 }}
        autoSize={true}
        isDraggable={isDraggable}
        isResizable={false}
        isDroppable={false}
        compactType={"vertical"}
        margin={{
          lg: [20, 20],
          md: [20, 20],
          sm: [20, 20],
          xs: [20, 20],
          xxs: [20, 20],
        }}
      >
        {props.widgetArray?.map((widget, index) => {
          var rendered_component;

          if (props.dashboardVersion === 3) {
            rendered_component = defineComponent(
              { ...props },
              {
                ...widget,
                metric: !widget.metric?.includes("custom_conversion")
                  ? widget.metric?.toLowerCase()
                  : widget.metric,
              }
            );
          } else {
            rendered_component = defineComponentV2(
              { ...props },
              {
                ...widget,
                metric: !widget.metric?.includes("custom_conversion")
                  ? widget.metric?.toLowerCase()
                  : widget.metric,
              }
            );
          }

          const el = {
            x: widget.x,
            y: widget.y,
            w: widget.w,
            h: widget.h,
            i: widget.i.split("-")[0],
            minW: 2,
            maxW: Infinity,
            minH: widget.minH || Infinity,
            maxH: widget.maxH || Infinity,
            static: false,
          };
          return (
            <div
              id={`widget-${widget.i.split("-")[1]}`}
              className="card"
              key={widget.i}
              data-grid={el}
              style={
                widget.i.split("-")[0] == 10
                  ? { overflowY: "scroll" }
                  : widget.i.split("-")[0] == 3
                  ? { overflow: "auto", paddingBottom: 30 }
                  : { overflow: "hidden", paddingBottom: 0 }
              }
            >
              {rendered_component}
            </div>
          );
        })}
      </ResponsiveReactGridLayout>
    </>
  );
};

const mapStateToProps = (store) => ({
  ad_accounts: store.Analytics.ad_accounts,
  account_specific: store.Analytics.account_specific,
  account_general_aggregated: store.Analytics.account_general_aggregated,
  account_general_granular: store.Analytics.account_general_granular,
  account_by_data_source_aggregated:
    store.Analytics.account_by_data_source_aggregated,
  account_by_data_source_granular:
    store.Analytics.account_by_data_source_granular,

  //Old
  metrics: store.Facebook.metrics,
  summary: store.Facebook.summary,
  loading: store.Facebook.loading,
  campaigns: store.Facebook.campaigns,
});

export default connect(mapStateToProps)(DashboardRenderer);
