export const category_options = [
  {
    label: "Cliente",
    value: "client",
    description:
      "Para agências e profissionais de tráfego para negócios locais",
    icon: "mdi mdi-account-outline",
  },
  {
    label: "Produto",
    value: "product",
    description: "Produtores e/ou afiliados",
    icon: "mdi mdi-cube-outline",
  },
  {
    label: "Outra",
    value: "uncategorized",
    description: "Outra categoria de produto ou serviço",
    icon: "mdi mdi-lightbulb-on-outline",
  },
];
