export const metritoLayouts = [
  {
    _id: "0",
    name: "Template em branco",
    description: "",
    creator: "metrito",
    icon: "bx bx-file-blank text-primary",
    icon_color: "2F61E2",
    layout: [],
    version: 3,
  },
  {
    _id: "1",
    name: "Gestão de Tráfego",
    description: "",
    creator: "metrito",
    icon: "bx bx-file-blank text-primary",
    icon_color: "2F61E2",
    layout: [
      {
        w: 12,
        h: 3,
        x: 0,
        y: 11,
        i: "3-6tRSM7yiX",
        minW: 2,
        maxW: null,
        minH: 2,
        maxH: null,
        moved: false,
        static: false,
        title: "",
        lines: [],
        description: "",
      },
      {
        w: 12,
        h: 3,
        x: 0,
        y: 6,
        i: "4-p2xd0sZGv",
        minW: 2,
        maxW: null,
        minH: 3,
        maxH: 3,
        moved: false,
        static: false,
        title: "Analise Geral",
        lines: [],
        description: "",
      },
      {
        w: 12,
        h: 2,
        x: 0,
        y: 3,
        i: "1-W2JWYuacs",
        minW: 2,
        maxW: null,
        minH: 2,
        maxH: 2,
        moved: false,
        static: false,
        title: "",
        lines: [],
        description: "",
      },
      {
        w: 3,
        h: 1,
        x: 0,
        y: 5,
        i: "2-nEpoyuIfB",
        minW: 2,
        maxW: null,
        minH: 1,
        maxH: 1,
        moved: false,
        static: false,
        metric: "amount_spent",
        title: "Valor Gasto",
        lines: [],
        description:
          "A quantia total estimada de dinheiro que você gastou em sua campanha, conjunto de anúncios ou anúncio durante a programação. ",
      },
      {
        w: 3,
        h: 1,
        x: 3,
        y: 5,
        i: "2-qiWP6Iyi0",
        minW: 2,
        maxW: null,
        minH: 1,
        maxH: 1,
        moved: false,
        static: false,
        metric: "purchase",
        title: "Compras ",
        lines: [],
        description: "Número de compras provenientes dos anúncios.",
      },
      {
        w: 3,
        h: 1,
        x: 6,
        y: 5,
        i: "2-V7nOuKQI9",
        minW: 2,
        maxW: null,
        minH: 1,
        maxH: 1,
        moved: false,
        static: false,
        metric: "cost_per_purchase",
        title: "Custo por compra",
        lines: [],
        description: "O custo estimado por compra realizada.",
      },
      {
        w: 3,
        h: 1,
        x: 9,
        y: 5,
        i: "2-0qtL63HS4",
        minW: 2,
        maxW: null,
        minH: 1,
        maxH: 1,
        moved: false,
        static: false,
        metric: "purchase_ROAS",
        title: "ROAS",
        lines: [],
        description:
          "O retorno total do investimento em publicidade (ROAS) das compras no site. Isso se baseia no valor de todas as conversões registradas pelo pixel do Facebook em seu site e atribuídas aos seus anúncios.",
      },
      {
        w: 6,
        h: 2,
        x: 0,
        y: 9,
        i: "8-m7Exlwzsy",
        minW: 2,
        maxW: null,
        minH: 2,
        maxH: 2,
        moved: false,
        static: false,
        metric: "initiate_checkout",
        title: "Início de finalização de compra ",
        lines: [],
        description:
          "O número de vezes em que um usuário iniciou a finalização de uma compra, como entrar numa página de checkout, por exemplo.",
      },
      {
        w: 6,
        h: 2,
        x: 6,
        y: 9,
        i: "8-P6K8B06S_",
        minW: 2,
        maxW: null,
        minH: 2,
        maxH: 2,
        moved: false,
        static: false,
        metric: "cost_per_initiate_checkout",
        title: "Custo por início de finalização de compra",
        lines: [],
        description:
          "O custo estimado por cada início de finalização de compra.",
      },
      {
        w: 12,
        h: 3,
        x: 0,
        y: 0,
        i: "10-xaNaWN9tU",
        minW: 2,
        maxW: null,
        minH: 1,
        maxH: null,
        moved: false,
        static: false,
        title: "",
        lines: [],
        description: "",
        content:
          '{"blocks":[{"key":"4e2ub","text":"Bem vindo ao Metrito!  🥳 😆 🚀","type":"header-two","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"c6hq9","text":"Estamos super felizes de te ter por aqui sabia?","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"76r4t","text":"Esperamos que você curta nossa plataforma e nos ajude a transformar o cenário da gestão de tráfego e visualização de dados no Brasil!","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"cim18","text":"Ah e não esquece: precisando de ajuda pode chamar a gente no botão de mensagem abaixo que respondemos bem rápido! 😅","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":115,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"5hfi4","text":"Um brinde a nós... 🥂","type":"header-three","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"aspect_ratio":{"width":476,"height":240,"ratio":50.42016806722689},"width":476,"caption":"type a caption (optional)","height":240,"forceUpload":false,"url":"blob:https://localhost:3000/2c4c0511-84bb-4d01-8987-dd4f95d4bb96","loading_progress":0,"selected":false,"loading":true,"file":null,"direction":"center"}}],"entityMap":{}}',
      },
    ],
    version: 3,
  },
];
