import Excel from "exceljs";

export const handleExport = (data, format, filename) => {
  if (data === undefined || data.length === 0) return;

  if (format === "xlsx") {
    /* create a workbook and add a worksheet to it */
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    /* add the headers to the worksheet */
    worksheet.columns = Object.keys(data[0]).map((c) => ({
      header: c,
      key: c,
    }));

    /* add the data to the worksheet */
    worksheet.addRows(data);

    /* generate the XLSX file */
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "data.xlsx";
      link.click();
    });
  } else if (format === "csv") {
    /* generate the CSV file */
    const headers = Object.keys(data[0]);
    const csvData = [headers.join(",")]
      .concat(data.map((row) => headers.map((header) => row[header]).join(",")))
      .join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.csv`;
    link.click();
  }
};
