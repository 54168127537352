const INITIAL_STATE = {
  loading: false,
  ad_accounts: [],
  account_specific: [],

  account_general_aggregated: [],
  account_general_granular: [],

  account_by_data_source_aggregated: [],
  account_by_data_source_granular: [],

  campaign_general_aggregated: [],
  campaign_general_granular: [],
};

function AnalyticsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: action.loading,
      };
      break;
    case "SET_AD_ACCOUNTS":
      return {
        ...state,
        ad_accounts: action.ad_accounts,
      };
      break;
    case "SET_ACCOUNT_SPECIFIC":
      return {
        ...state,
        ad_accounts: action.ad_accounts,
      };
      break;
    case "SET_ACCOUNT_GENERAL_AGGREGATED":
      return {
        ...state,
        account_general_aggregated: action.account_general_aggregated,
      };
      break;
    case "SET_ACCOUNT_GENERAL_GRANULAR":
      return {
        ...state,
        account_general_granular: action.account_general_granular,
      };
      break;
    case "SET_ACCOUNT_BY_DATA_SOURCE_AGGREGATED":
      return {
        ...state,
        account_by_data_source_aggregated:
          action.account_by_data_source_aggregated,
      };
      break;
    case "SET_ACCOUNT_BY_DATA_SOURCE_GRANULAR":
      return {
        ...state,
        account_by_data_source_granular: action.account_by_data_source_granular,
      };
      break;
    case "SET_CAMPAIGN_GENERAL_AGGREGATED":
      return {
        ...state,
        campaign_general_aggregated: action.campaign_general_aggregated,
      };
      break;
    case "SET_CAMPAIGN_GENERAL_GRANULAR":
      return {
        ...state,
        campaign_general_granular: action.campaign_general_granular,
      };
      break;
    case "FLUSH_DATA":
      return { ...state };
    default:
      return state;
      break;
  }
}

export default AnalyticsReducer;
