import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { defineMetric } from "../functions/metricFormatter";

const AnalyticsChart = (props) => {
  const [metric, setMetric] = useState();

  var series = [
    {
      name: "Investimento",
      data: props.metrics.map((m) => parseFloat(m.spend)) || [],
    },
    {
      name: "Vendas",
      data: defineMetric(props.metrics, "action_values:omni_purchase"),
    },
  ];

  var options = {
    chart: {
      height: 290,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    labels: props.metrics.map((a) =>
      moment(a.date_start).toLocaleString("pt-BR")
    ),
    colors: ["#008FFB", "#00E396", "#CED4DC"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    xaxis: {
      type: "datetime",
      tickPlacement: "on",
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return parseFloat(value).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  useEffect(() => {
    setMetric(defineMetric(props.metrics, "conversions", "contact_website"));
  }, [props.metrics]);

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type={options.chart.type}
        height={options.chart.height}
        dir="ltr"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default AnalyticsChart;
