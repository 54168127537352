import React from "react";
import { useEffect } from "react";
import {
  Modal,
  ButtonToolbar,
  Button,
  RadioGroup,
  Radio,
  Placeholder,
} from "rsuite";

const styles = {
  radioGroupLabel: {
    padding: "8px 12px",
    display: "inline-block",
    verticalAlign: "middle",
  },
};

const NoMetritoTagsFoundModal = (props) => {
  const [backdrop, setBackdrop] = React.useState("static");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (props.open) {
      handleOpen();
    }
  }, [props.open]);

  return (
    <>
      <Modal
        backdrop={backdrop}
        keyboard={false}
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="fs-5">
            <i className="mdi mdi-alert text-secondary me-2" />
            Atenção: Nenhuma Tag Metrito econtrada
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Não foi encontrada nenhuma Tag no padrão Metrito nos códigos UTM das
            suas vendas. Para aproveitar o máximo dessa funcionalidade do
            metrito e poder entender melhor suas vendas nós recomendamos
            fortemente que passe a utilizar nosso padrão UTM.
          </p>
          <b>
            Você pode criar Tags facilmente{" "}
            <a href="/gateways/utm-generator">clicando aqui</a>.
          </b>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NoMetritoTagsFoundModal;
