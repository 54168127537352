import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

const Preloader = () => {
  const preloaderDiv = document.getElementById("preloaderDiv");

  useEffect(() => {
    setTimeout(() => {
      if (preloaderDiv) {
        preloaderDiv.style.display = "none";
      }
    }, 100);
  });

  return (
    <React.Fragment>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="text-center">
                <div>
                  <Row className="justify-content-center">
                    <div>
                      <div
                        className="spinner-border text-primary m-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Preloader;
