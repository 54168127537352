import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" id="footer">
        <Container fluid={true}>
          <Row>
            <Col md={4}>
              <div className="text-start d-none d-sm-block">
                Desenvolvido com muito{" "}
                <i
                  className="mdi mdi-cards-heart"
                  style={{ color: "#F7024B" }}
                ></i>
              </div>
            </Col>
            <Col md={4} className="text-center">
              © {new Date().getFullYear()} metrito
            </Col>

            <Col lg={4}></Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
