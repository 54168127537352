import React, { useCallback, useEffect, useMemo, useState } from "react";

import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
} from "react-flow-renderer";
import "react-flow-renderer/dist/style.css";

import FacebookNode from "./FacebookNode";
import GoogleNode from "./GoogleNode";

const initialNodes = [
  {
    id: "1",
    type: "input",
    data: { label: "Input Node" },
    position: { x: 250, y: 25 },
  },

  {
    id: "2",
    // you can also pass a React component as a label
    data: { label: <div>Default Node</div> },
    position: { x: 100, y: 125 },
  },
  {
    id: "3",
    type: "output",
    data: { label: "Output Node" },
    position: { x: 250, y: 250 },
  },
  {
    id: "4",
    type: "facebook",
    data: {},
    position: { x: 300, y: 50 },
  },
];

const initialEdges = [
  { id: "e1-2", source: "1", target: "2" },
  { id: "e2-3", source: "2", target: "3", animated: true },
];

const Flow = () => {
  const nodeTypes = useMemo(
    () => ({ facebook: FacebookNode, google: GoogleNode }),
    []
  );

  window.addEventListener("DOMContentLoaded", async (event) => {
    // document.getElementById("topnav").style.display = "none";
  });

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const defaultEdgeOptions = { animated: true };

  useEffect(() => {
    console.log("Nodes: ", nodes);
    console.log("Edges: ", edges);
  }, [nodes, edges]);

  return (
    <React.Fragment>
      <div
        className="w-100 rainbow-wrapper"
        style={{ height: 4, marginTop: 0 }}
      ></div>
      <div>
        <div
          className="w-100 text-center bg-white d-flex align-items-center px-3"
          style={{ height: 50, marginTop: 0 }}
        >
          <button
            className="btn btn-primary mx-1"
            onClick={() =>
              setNodes([
                ...nodes,
                {
                  id: String(Date.now()),
                  type: "facebook",
                  data: {},
                  position: { x: 350, y: 350 },
                },
              ])
            }
          >
            Facebook Ads
          </button>
          <button
            className="btn btn-success mx-1"
            onClick={() =>
              setNodes([
                ...nodes,
                {
                  id: String(Date.now()),
                  type: "google",
                  data: {},
                  position: { x: 350, y: 350 },
                },
              ])
            }
          >
            Google Ads
          </button>
        </div>
        <div style={{ width: "100vw", height: "100vh" }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            fitView
          >
            <Background />
          </ReactFlow>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Flow;
