import React from "react";
import { images, useCardBrand } from "react-card-brand";
import { payment_methods } from "../../common/data/payment_methods";

const CardBrandSvg = ({ cardBrand }) => {
  const { getSvgProps } = useCardBrand();
  const payment_method = payment_methods.find(
    (method) => method.code === "credit_card"
  );
  return (
    <>
      {cardBrand ? (
        <>
          <svg
            {...getSvgProps({
              type: cardBrand.toLowerCase(),
              images,
            })}
          />
        </>
      ) : (
        <img
          src={payment_method?.icon || "mdi mdi-error"}
          style={{ maxHeight: 20 }}
        />
      )}
    </>
  );
};

export default CardBrandSvg;
