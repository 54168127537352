import { startOfDay } from "date-fns";
import React from "react";
import ReactApexChart from "react-apexcharts";

const AdminColumnChart = (props) => {
  const series = [
    {
      name: "Novas assinaturas",
      data: Object.values(props.data[0]).map((o) => [
        startOfDay(new Date(o[0].created * 1000)).getTime(),
        o.length,
      ]),
    },
    {
      name: "Renovações",
      data: Object.values(props.data[1]).map((o) => [
        startOfDay(new Date(o[0].created * 1000)).getTime(),
        o.length,
      ]),
    },
  ];

  var options = {
    chart: {
      type: "bar",
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "90%",
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#0ab39c", "#0050ff"],
    xaxis: {
      type: "datetime",
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    // yaxis: {
    //   labels: {
    //     formatter: function (y) {
    //       return formatByDisplayType(y, props.selected.metric);
    //     },
    //   },
    // },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          return y;
        },
      },
    },
  };
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height="150"
      style={{ marginTop: -20, marginLeft: -20, marginBottom: -20, padding: 0 }}
    />
  );
};

export default AdminColumnChart;
