import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
  Modal,
} from "reactstrap";
import api from "../../services/api";
import Preloader from "../Utility/Preloader";
import { getIdUsuario } from "../../services/auth";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { InputMask } from "react-input-mask";
import { toast } from "react-toastify";
import moment from "moment";

const Profile = (props) => {
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [downgrading, setDowngrading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [userData, setUserData] = useState({});

  const [activeTab, setactiveTab] = useState("2");
  const [rangeValue, setRangeValue] = useState(0);
  const history = useHistory();

  const [modal_center, setmodal_center] = useState(false);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  async function loadUserData() {
    const response = await api
      .get("/api/usuarios.details/" + getIdUsuario())
      .then((response) => {
        setUserData(response.data[0]);
        // setSelectedPlan(response.data[0].subscription);
        setRangeValue(
          response.data[0].subscription?.extra_ad_accounts.quantity || 0
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchPlans() {
    try {
      const plans_response = await api.get("/api/plans");
      setPlans(plans_response.data.reverse());

      const user_response = await api.get(
        "/api/usuarios.details/" + getIdUsuario()
      );
      setSelectedPlan(
        plans_response.data.find(
          (plan) =>
            plan.gateway_product_id ===
            user_response.data[0].subscription?.plan.sku
        ) ?? {}
      );

      setLoadingPlans(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function downgradeAdAccounts() {
    setDowngrading(true);

    api
      .post(`/api/subscription/reduce-extra-fb/${getIdUsuario()}`, {
        quantity_to_reduce:
          userData.subscription.extra_ad_accounts.quantity - rangeValue,
        price: selectedPlan.extra_account_price,
      })
      .then((res) => {
        toast.success("Conta atualizada com sucesso!", { autoClose: 2000 });
        setDowngrading(false);
        setTimeout(() => {
          tog_center();
        }, 200);
        loadUserData();
      })
      .catch((err) => {
        console.log(err);
        setDowngrading(false);
      });
  }

  useEffect(() => {
    if (
      userData.trial === true &&
      moment(userData.trial_expires_at) < new Date()
    ) {
      document.getElementById("topnav").style.display = "none";
    }
    loadUserData();
    fetchPlans();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planos | Metrito</title>
        </MetaTags>
        <Container>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Pages" breadcrumbItem="Pricing" />
          <Row className="justify-content-center">
            <Col lg={5}>
              <div className="text-center my-3">
                <h1 className="mb-3">Temos um plano pra você 🎉</h1>
                <p className="text-muted mb-4">
                  Desde o Gestor iniciante, até as grandes agências. Nós temos
                  planos que se encaixam perfeitamente com você!{" "}
                  <span className="text-primary">Escolha já o seu!</span>
                </p>
              </div>
            </Col>
          </Row>

          {!loadingPlans ? (
            <>
              <Row className="d-flex flex-row justify-content-center">
                {plans.map((plan, key) => (
                  <Col lg={4} key={key}>
                    <Card
                      style={{
                        minHeight: 450,
                      }}
                      className={`${plan.name ? "shadow-sm" : "shadow-lg"}`}
                    >
                      <CardBody>
                        <h5 className="text-center mb-4">{plan.name}</h5>
                        <div className="w-100 d-flex justify-content-center text-center">
                          <img
                            src={plan.icon_url}
                            alt="plan"
                            style={{ maxHeight: 70 }}
                          />
                        </div>
                        <h5
                          className={`text-${
                            plan.name === "Plano Black Diamond"
                              ? "dark"
                              : "success"
                          } text-center mt-3 mb-0`}
                        >
                          {(
                            plan.price +
                            rangeValue * plan.extra_account_price
                          ).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                            maximumFractionDigits: 2,
                          })}
                        </h5>
                        <div className="w-100 text-center">
                          <span className="text-muted" style={{ fontSize: 12 }}>
                            por mês
                          </span>
                        </div>

                        <hr className="divider" />
                        <div className="d-flex flex-column mx-3">
                          <span>
                            <i className="bx bxl-facebook-circle text-primary me-2" />
                            {`${
                              plan.ad_accounts + rangeValue
                            } contas de anúncio`}
                          </span>
                          {plan.features.map((feature, key) => (
                            <span key={key}>
                              <i className="bx bxs-check-circle text-success me-2 mt-1" />
                              {feature.label}
                            </span>
                          ))}

                          {selectedPlan &&
                          plan.name === selectedPlan.name &&
                          rangeValue <=
                            userData.subscription?.extra_ad_accounts
                              .quantity ? (
                            <button
                              className="btn btn-dark"
                              style={{
                                position: "absolute",
                                bottom: 15,
                                right: 15,
                                left: 15,
                                padding: "10px 45px",
                              }}
                            >
                              <b>Plano Contratado</b>
                            </button>
                          ) : plan.name === selectedPlan?.name &&
                            rangeValue >
                              userData.subscription?.extra_ad_accounts
                                .quantity ? (
                            <button
                              className="btn btn-primary"
                              style={{
                                position: "absolute",
                                bottom: 15,
                                right: 15,
                                left: 15,
                                padding: "10px 45px",
                              }}
                              onClick={async () => {
                                window.location.href = `/checkout?type=adaccounts&extra_accounts=${
                                  rangeValue -
                                  userData.subscription.extra_ad_accounts
                                    .quantity
                                }`;
                              }}
                            >
                              <b>
                                Aumentar Contas Extras{" "}
                                <i className="bx bx-right-arrow-alt align-middle fs-4" />
                              </b>
                            </button>
                          ) : (
                            <button
                              className={`btn btn-${
                                plan.price > selectedPlan?.price
                                  ? "gold"
                                  : "success"
                              } fs-6 mt-4`}
                              style={{
                                position: "absolute",
                                bottom: 15,
                                right: 15,
                                left: 15,
                                padding: "10px 45px",
                              }}
                              onClick={() => {
                                window.location.href = `/checkout?product=${plan.gateway_product_id}&type=plans&extra_accounts=${rangeValue}`;
                              }}
                            >
                              <b className={`fs-6 mt-4`}>
                                {plan.price > selectedPlan?.price
                                  ? "Fazer Upgrade"
                                  : "Contratar"}
                              </b>
                            </button>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col className="mt-4">
                  <Card className="shadow-sm">
                    <CardBody>
                      <h5>🚀 Contas de Anúncio Extras (Facebook Ads)</h5>
                      {rangeValue >
                      userData.subscription?.extra_ad_accounts.quantity ? (
                        <b className="text-success">
                          Aumentar número de contas extras para {rangeValue}
                        </b>
                      ) : rangeValue <
                        userData.subscription?.extra_ad_accounts.quantity ? (
                        <b className="text-danger">
                          Diminuir número de contas extras para {rangeValue}
                        </b>
                      ) : (
                        <b>
                          Você atuamente possui{" "}
                          <b>
                            {userData.subscription?.extra_ad_accounts.quantity}
                          </b>{" "}
                          contas de anúncio extras
                        </b>
                      )}
                      <div className="d-flex flex-row align-items-center">
                        <button
                          className="btn btn-dark d-flex justify-content-center align-items-center"
                          style={{ height: 30, width: 50 }}
                          onClick={() =>
                            setRangeValue(rangeValue >= 1 ? rangeValue - 1 : 0)
                          }
                        >
                          <i className="bx bx-minus" />
                        </button>

                        <div
                          id="ad-accounts-range"
                          className="d-flex justify-content-center w-100"
                        >
                          <input
                            type="range"
                            className="form-range mt-4 mb-4 mx-3"
                            min="0"
                            max="50"
                            value={rangeValue}
                            onChange={(e) => {
                              setRangeValue(Number(e.target.value));
                            }}
                            disabled={loadingPlans}
                          />
                        </div>
                        <input
                          type="number"
                          className="form-control mt-4 mb-4 mx-3"
                          style={{ maxWidth: 100 }}
                          value={rangeValue}
                          mask="9999"
                          maskchar=""
                          min={1}
                          tag={[Input, InputMask]}
                          required
                          onChange={(e) =>
                            setRangeValue(Number(e.target.value))
                          }
                        />

                        <button
                          className="btn btn-success d-flex justify-content-center align-items-center"
                          style={{ height: 30, width: 50 }}
                          onClick={() => setRangeValue(rangeValue + 1)}
                        >
                          <i className="bx bx-plus" />
                        </button>
                      </div>

                      {rangeValue <
                      userData.subscription?.extra_ad_accounts.quantity ? (
                        <div>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              tog_center();
                            }}
                          >
                            Reduzir quantidade de Contas de Anúncios Extras
                          </button>
                        </div>
                      ) : null}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Preloader />
          )}
          <Modal
            isOpen={modal_center}
            toggle={() => {
              tog_center();
            }}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0 text-danger">Atenção</h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_center(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                {`Ao realizar essa ação, seu número de contas de anúncio extras será reduzido para ${rangeValue} contas.`}
              </p>
              <p>
                Esta alteração surtirá efeito a partir da próxima cobrança
                automática.
              </p>
              <b>Você tem certeza que deseja prosseguir com essa redução?</b>

              <div className="text-center">
                <button
                  className="btn btn-warning mt-4"
                  onClick={() => downgradeAdAccounts()}
                >
                  Sim eu tenho certeza e desejo continuar.
                </button>
              </div>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
