export const braip_mock = [
  {
    identification: { data_source: "BRAIP", order_id: "venzvky27" },
    transaction: {
      status: "canceled",
      created_date: "2021-06-03 20:33:50",
      updated_date: "2021-06-09 06:31:49",
      value: 79700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venzvky27",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plamrler",
          name: "PLANO VALOR CHEIO",
          quantity: 1,
          price: 79700,
          products: [
            {
              id: "pro04mog",
              name: "Método Estratégias Digitais",
              quantity: 1,
              price: 797,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Raphael Aparecido Alves Da Cunha",
      email: "djpinas@hotmail.com",
      telephone: "55 62994259756",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 33345,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venqd09xw" },
    transaction: {
      status: "canceled",
      created_date: "2021-06-07 20:22:20",
      updated_date: "2021-06-13 06:32:42",
      value: 39700,
      discount_value: null,
      freight: null,
      freight_type: "PAC",
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venqd09xw",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plake801",
          name: "PAGUE 3 LEVE 5 V2",
          quantity: 5,
          price: 39700,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 5, price: 397 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "Isabel Aparecida Anselmo Moreira",
      email: "i.anselmo@hotmail.com",
      telephone: "55 62981758998",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 19904,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venvddj3n" },
    transaction: {
      status: "canceled",
      created_date: "2021-06-11 10:21:49",
      updated_date: "2021-06-22 08:10:01",
      value: 39700,
      discount_value: null,
      freight: null,
      freight_type: "PAC",
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000001898697901019186510000039700",
      payment_bar_code: "03391865100000397009058725700000019869790101",
      payment_url: "https://ev.braip.com/checkout/boleto/venvddj3n",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plake801",
          name: "POPZEN 5 POTES",
          quantity: 5,
          price: 39700,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 5, price: 397 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "Carmem parecida vargas",
      email: "CarmemVargas@gmail.com",
      telephone: "55 5198283785",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 19904,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venpjq39g" },
    transaction: {
      status: "approved",
      created_date: "2021-06-12 10:06:10",
      updated_date: "2021-07-12 10:10:36",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: "PAC",
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venpjq39g",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plagl60g",
          name: "POPZEN 3 POTES",
          quantity: 3,
          price: 29700,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 3, price: 297 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "Analia Rodrigues de Lara",
      email: "naia.lara123@hotmail.com",
      telephone: "55 69984376778",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 14877,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venl4wydj" },
    transaction: {
      status: "approved",
      created_date: "2021-06-12 19:29:20",
      updated_date: "2021-06-14 16:30:37",
      value: 39700,
      discount_value: null,
      freight: null,
      freight_type: "PAC",
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venl4wydj",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plake801",
          name: "POPZEN 5 POTES",
          quantity: 5,
          price: 39700,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 5, price: 397 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "Luzanira da Silva Ferreira",
      email: "Lucastvv23@gmail.com",
      telephone: "55 9492918386",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 19904,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven4o42mk" },
    transaction: {
      status: "canceled",
      created_date: "2021-06-12 20:08:00",
      updated_date: "2021-06-22 08:13:43",
      value: 39700,
      discount_value: null,
      freight: null,
      freight_type: "PAC",
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000001899225301011386520000039700",
      payment_bar_code: "03393865200000397009058725700000019922530101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven4o42mk",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plake801",
          name: "POPZEN 5 POTES",
          quantity: 5,
          price: 39700,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 5, price: 397 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "Sônia sossai",
      email: "soniatradicao@hotmail.com",
      telephone: "55 93981235955",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 19904,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venzv13gz" },
    transaction: {
      status: "canceled",
      created_date: "2021-06-13 13:53:16",
      updated_date: "2021-06-22 08:13:43",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: "PAC",
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000001899388801013386530000019700",
      payment_bar_code: "03393865300000197009058725700000019938880101",
      payment_url: "https://ev.braip.com/checkout/boleto/venzv13gz",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla20jlx",
          name: "POPZEN 1 POTE",
          quantity: 1,
          price: 19700,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 1, price: 197 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "Maria Luiza de Oliveira",
      email: "luimagalhaes@bol.com.br",
      telephone: "55 11968393630",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 9850,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venw0zzn2" },
    transaction: {
      status: "canceled",
      created_date: "2021-06-13 19:57:21",
      updated_date: "2021-06-29 08:00:28",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: "PAC",
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002601158001014186580000029700",
      payment_bar_code: "03391865800000297009058725700000020115800101",
      payment_url: "https://ev.braip.com/checkout/boleto/venw0zzn2",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plaw6q62",
          name: "COMPRE 3 LEVE 4 POPZEN RMKT",
          quantity: 4,
          price: 29700,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 4, price: 297 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "Dalva Pereira Gomes",
      email: "dalva.plg67@gmail.com",
      telephone: "55 87988461140",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 11020,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venvdygko" },
    transaction: {
      status: "canceled",
      created_date: "2021-06-13 22:53:09",
      updated_date: "2021-06-29 08:00:28",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: "PAC",
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002601236901011186580000029700",
      payment_bar_code: "03391865800000297009058725700000020123690101",
      payment_url: "https://ev.braip.com/checkout/boleto/venvdygko",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plagl60g",
          name: "POPZEN 3 POTES",
          quantity: 3,
          price: 29700,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 3, price: 297 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "IGOR ANTONIO (teste)",
      email: "TI@GOOGLE.COM",
      telephone: "55 38999495329",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 14877,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venrd2g2z" },
    transaction: {
      status: "approved",
      created_date: "2021-06-14 12:35:56",
      updated_date: "2021-06-15 13:30:37",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: "PAC",
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venrd2g2z",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla20jlx",
          name: "POPZEN 1 POTE",
          quantity: 1,
          price: 19700,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 1, price: 197 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "Selma de Andrade Leal",
      email: "lealandrade023@gmail.com",
      telephone: "55 96984204970",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 9850,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venw0n2xk" },
    transaction: {
      status: "approved",
      created_date: "2021-06-14 16:48:27",
      updated_date: "2021-07-14 16:50:39",
      value: 23420,
      discount_value: 2490,
      freight: null,
      freight_type: "PAC",
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venw0n2xk",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla40k0k",
          name: "COMPRE 2 LEVE 3 10%OFF",
          quantity: 3,
          price: 22410,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 3, price: 224.1 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "MARIA LAURA DE SOUSA SOARES E SILVA",
      email: "marialaura2@hotmail.com",
      telephone: "55 71999828787",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 11212,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven51pnyd" },
    transaction: {
      status: "approved",
      created_date: "2021-06-15 19:57:09",
      updated_date: "2021-06-21 10:30:39",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: "PAC",
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002600461401010886550000029700",
      payment_bar_code: "03398865500000297009058725700000020046140101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven51pnyd",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plagl60g",
          name: "POPZEN 3 POTES",
          quantity: 3,
          price: 29700,
          products: [
            { id: "pro0x2zv", name: "POPZEN CAPS", quantity: 3, price: 297 },
          ],
        },
      ],
    },
    producer: {
      name: "B2C DIGITAL",
      document: "38414940000108",
      email: null,
      tel: "61986511614",
    },
    customer: {
      id: null,
      name: "Sandra",
      email: "sandra.abrantes@hotmail.com",
      telephone: "55 33991919999",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 14877,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven9nmkr4" },
    transaction: {
      status: "approved",
      created_date: "2021-07-02 01:52:32",
      updated_date: "2021-08-01 02:00:33",
      value: 35784,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven9nmkr4",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla1rn1n",
          name: "OFERTA ESPECIAL. MED",
          quantity: 1,
          price: 29700,
          products: [
            {
              id: "pro04mog",
              name: "Método Estratégias Digitais",
              quantity: 1,
              price: 297,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Douglas Menani Nogueira",
      email: "douglasnogueiramenani@gmail.com",
      telephone: "55 67984813011",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 22040,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven0l04nd" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 10:55:42",
      updated_date: "2021-07-14 11:25:23",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven0l04nd",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plaz0x0l",
          name: "OFERTA ESPECIAL",
          quantity: 1,
          price: 19700,
          products: [
            {
              id: "pro04mog",
              name: "Método Estratégias Digitais",
              quantity: 1,
              price: 197,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Alexsandro Rocha",
      email: "fatimalucas131296@gmail.com",
      telephone: "55 81989753342",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 14592,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven9nxvwp" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:00:24",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613838301011386790000009700",
      payment_bar_code: "03393867900000097009058725700000021383830101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven9nxvwp",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Nilson lima",
      email: "nilsonl2elite@gmail.com",
      telephone: "55 85988421893",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven4glw3k" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:00:35",
      updated_date: "2021-07-14 00:05:45",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven4glw3k",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Missilene Morais",
      email: "missylenemorais@gmail.com",
      telephone: "55 85991322473",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venn35xlw" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:01:31",
      updated_date: "2021-07-14 00:05:44",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venn35xlw",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Francisco gleison de souza de freitas",
      email: "gleisonjhulhus@gmail.com",
      telephone: "55 85985395869",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venn35x9p" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:04:52",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613841201018686790000009700",
      payment_bar_code: "03396867900000097009058725700000021384120101",
      payment_url: "https://ev.braip.com/checkout/boleto/venn35x9p",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Amanda Carolyne Serrão Ribeiro",
      email: "carolyneamanda454@gmail.com",
      telephone: "55 98988846969",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venqy32y7" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:06:06",
      updated_date: "2021-07-08 21:06:09",
      value: 10436,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venqy32y7",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "José Kaique dos Santos Reis",
      email: "kaique_dossanto@hotmail.com",
      telephone: "55 11983977841",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venk5o093" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:14:05",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613848301019686790000009700",
      payment_bar_code: "03396867900000097009058725700000021384830101",
      payment_url: "https://ev.braip.com/checkout/boleto/venk5o093",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Lorena ponte do nascimento",
      email: "lorenagui185@gmail.com",
      telephone: "55 88994997182",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven10p1nr" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 21:16:02",
      updated_date: "2021-08-07 21:20:37",
      value: 10892,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven10p1nr",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Djair de Jesus Silva",
      email: "djairsilva017@gmail.com",
      telephone: "55 11957939952",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven7nomgl" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:16:22",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613849801017286790000009700",
      payment_bar_code: "03392867900000097009058725700000021384980101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven7nomgl",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Romário costa moura",
      email: "romarionavesa22@gmail.com",
      telephone: "55 85996530713",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venm2x1g1" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:17:38",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613850701015886790000009700",
      payment_bar_code: "03398867900000097009058725700000021385070101",
      payment_url: "https://ev.braip.com/checkout/boleto/venm2x1g1",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Alecssandro Mateus Oliveira Teixeira",
      email: "alecssandromateus@gmail.com",
      telephone: "55 91991701380",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veno7wdkp" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:18:41",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613851201015986790000009700",
      payment_bar_code: "03399867900000097009058725700000021385120101",
      payment_url: "https://ev.braip.com/checkout/boleto/veno7wdkp",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Fabricio Ferreira de Santana",
      email: "galegofabricio4@gmail.com",
      telephone: "55 81995091173",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven10p1od" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:20:14",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613852101016886790000009700",
      payment_bar_code: "03398867900000097009058725700000021385210101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven10p1od",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "João Vitor Pereira da Silva",
      email: "destakerefrigeracao186@gmail.com",
      telephone: "55 8598172671",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veno7woov" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:27:08",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613857001013186790000009700",
      payment_bar_code: "03391867900000097009058725700000021385700101",
      payment_url: "https://ev.braip.com/checkout/boleto/veno7woov",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Laylla Lanna",
      email: "laylalanna3@gmail.com",
      telephone: "55 85991964074",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venw4n2p2" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:28:36",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613858601019286790000009700",
      payment_bar_code: "03392867900000097009058725700000021385860101",
      payment_url: "https://ev.braip.com/checkout/boleto/venw4n2p2",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Raphael marques da hora",
      email: "raphaeldahora2021@gmail.com",
      telephone: "55 85996067096",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven10pxyl" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:32:36",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613860101016386790000009700",
      payment_bar_code: "03393867900000097009058725700000021386010101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven10pxyl",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Taylane Galdino Oliveira",
      email: "taylanegaldino2206@gmail.com",
      telephone: "55 85994143982",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven7nod2n" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:35:31",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613862001016686790000009700",
      payment_bar_code: "03396867900000097009058725700000021386200101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven7nod2n",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Leandro nico Rodrigues De Castro",
      email: "leandronicolas701@gmail.com",
      telephone: "55 85992305430",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venjxr5x5" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 21:38:20",
      updated_date: "2021-08-07 21:40:37",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venjxr5x5",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Rafael de Sousa Nunes",
      email: "rafapv55@hotmail.com",
      telephone: "55 85997927969",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veno7wox4" },
    transaction: {
      status: "rejected",
      created_date: "2021-07-08 21:39:19",
      updated_date: "2021-07-19 12:27:00",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613864601011186790000009700",
      payment_bar_code: "03391867900000097009058725700000021386460101",
      payment_url: "https://ev.braip.com/checkout/boleto/veno7wox4",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Francisco Renato Sousa da Cruz",
      email: "renatosousa526@gmail.com",
      telephone: "55 85985541834",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venrz75q9" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 21:45:24",
      updated_date: "2021-07-09 21:50:39",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venrz75q9",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "João Gabriel Santana Santos",
      email: "vaqueiroloko47@gmail.com",
      telephone: "55 73981772094",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venggro4y" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 21:56:31",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613875001011186790000009700",
      payment_bar_code: "03391867900000097009058725700000021387500101",
      payment_url: "https://ev.braip.com/checkout/boleto/venggro4y",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Warlison Carvalho",
      email: "warllyssonkarvalho69@gmail.com",
      telephone: "55 98991537232",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven10plzd" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:02:04",
      updated_date: "2021-07-08 22:02:31",
      value: 10287,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven10plzd",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Luan bandeira",
      email: "luan_bandeira30@outlook.com",
      telephone: "55 8499671164",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven3n0mmg" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:05:45",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613879501016786790000009700",
      payment_bar_code: "03397867900000097009058725700000021387950101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven3n0mmg",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Andrea Raiane Diniz meneses",
      email: "cauaandrea@gmail.com",
      telephone: "55 85985070296",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven3n0mql" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:08:54",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613881301017186790000009700",
      payment_bar_code: "03391867900000097009058725700000021388130101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven3n0mql",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Emerson Pereira de menezes",
      email: "em9088033@gmail.com",
      telephone: "55 71991487173",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venqy3vy7" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:15:06",
      updated_date: "2021-07-08 22:15:27",
      value: 10287,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venqy3vy7",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Raphael marques da hora",
      email: "raphaeldahora2021@gmail.com",
      telephone: "55 85996067096",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veno7wzx4" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 22:15:49",
      updated_date: "2021-08-07 22:20:35",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/veno7wzx4",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Genival Junior",
      email: "juniorlacraia21@gmail.com",
      telephone: "55 08699544454",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven10pl9q" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:19:29",
      updated_date: "2021-07-14 00:01:01",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven10pl9q",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Alan Campos santos",
      email: "alancamopossantoa08@gmail.com",
      telephone: "55 9492831186",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venm2xq7m" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 22:22:05",
      updated_date: "2021-08-07 22:30:35",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venm2xq7m",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Márcio da Silva Costa",
      email: "marciosilvasme@gmail.com",
      telephone: "55 85982113608",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venqy3v7p" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:23:26",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613891901012186790000009700",
      payment_bar_code: "03391867900000097009058725700000021389190101",
      payment_url: "https://ev.braip.com/checkout/boleto/venqy3v7p",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Nadia Maria Correia lima",
      email: "nadiamarialimak7@gmail.com",
      telephone: "55 85989360363",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven7noq0l" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 22:31:58",
      updated_date: "2021-07-09 22:40:36",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven7noq0l",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Priscilla nascimento dos Santos",
      email: "danillosantana@icloud.com",
      telephone: "55 55799996346",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venm2xqv1" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:34:15",
      updated_date: "2021-07-13 23:55:52",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venm2xqv1",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Pedro Rafael Maciel Cavalcante",
      email: "pedrorafael0922@gmail.com",
      telephone: "55 85981792832",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venw4nvll" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:38:21",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613899601010786790000009700",
      payment_bar_code: "03397867900000097009058725700000021389960101",
      payment_url: "https://ev.braip.com/checkout/boleto/venw4nvll",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Bruna carolina santos",
      email: "bruna22@gmail.com",
      telephone: "55 79998480963",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venv4m7vy" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:39:09",
      updated_date: "2021-07-08 22:45:55",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venv4m7vy",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Vanderson dos Santos Rodrigues",
      email: "vandersonr554@gmail.com",
      telephone: "55 08599165378",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven9nxdvn" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:44:19",
      updated_date: "2021-07-14 00:05:34",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven9nxdvn",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Silas da Conceição Oliveira",
      email: "Silasoliveira99569@gmail.com",
      telephone: "55 86981074453",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venlw1goj" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:44:30",
      updated_date: "2021-07-08 22:49:28",
      value: 10138,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venlw1goj",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Lindomarcos Pinheiro braga",
      email: "lindomarcosbragaa@gmail.com",
      telephone: "55 55859850643",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venk5o4ro" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:46:29",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613905001015286790000009700",
      payment_bar_code: "03392867900000097009058725700000021390500101",
      payment_url: "https://ev.braip.com/checkout/boleto/venk5o4ro",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Wellington lima Batista",
      email: "wellingtonliverpool@gmail.com",
      telephone: "55 85988221810",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven2ldymm" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:47:30",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613904801019586790000009700",
      payment_bar_code: "03395867900000097009058725700000021390480101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven2ldymm",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Eric kevin lemos de carvalho",
      email: "keric8787@gmail.com",
      telephone: "55 85998375016",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venjxr43p" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:48:32",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613905501014586790000009700",
      payment_bar_code: "03395867900000097009058725700000021390550101",
      payment_url: "https://ev.braip.com/checkout/boleto/venjxr43p",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Daniel leite ribeiro",
      email: "dan13.tuf@gmail.com",
      telephone: "55 85981304540",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veny4177r" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:49:35",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613906001014686790000009700",
      payment_bar_code: "03396867900000097009058725700000021390600101",
      payment_url: "https://ev.braip.com/checkout/boleto/veny4177r",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Estefania gomes feijão",
      email: "Estefaniagomesf25@gmail.com",
      telephone: "55 85987510927",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venpq2ynd" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 22:52:35",
      updated_date: "2021-08-07 23:00:35",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venpq2ynd",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Wennas Silva",
      email: "Wennassilva90@gmail.com",
      telephone: "55 94984162509",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veno7wq4o" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 22:53:14",
      updated_date: "2021-08-07 23:00:34",
      value: 7200,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/veno7wq4o",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plaeg9ew",
          name: "30%OFF - CARTÃO DE CRÉDITO",
          quantity: 1,
          price: 6790,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 67.9,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Estefania gomes feijão",
      email: "Estefaniagomesf25@gmail.com",
      telephone: "55 85987510927",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 3111,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven0ldg7k" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 22:56:48",
      updated_date: "2021-07-09 23:00:37",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven0ldg7k",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Ana Carla Freitas",
      email: "anacarla.freitas26@gmail.com",
      telephone: "55 85989635722",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venlw1gn9" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 22:56:56",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613910701013986790000009700",
      payment_bar_code: "03399867900000097009058725700000021391070101",
      payment_url: "https://ev.braip.com/checkout/boleto/venlw1gn9",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Vitoria Stefane da Silva Marçal",
      email: "Stefanevitoria734@gmail.com",
      telephone: "55 8599208070",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venrz7j7k" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 23:03:07",
      updated_date: "2021-07-09 23:10:35",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venrz7j7k",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Alessandro dos Santos Sousa",
      email: "alessandrosousa_3@outlook.com",
      telephone: "55 9499292786",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venjxr4w5" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 23:07:45",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613916601019686790000009700",
      payment_bar_code: "03396867900000097009058725700000021391660101",
      payment_url: "https://ev.braip.com/checkout/boleto/venjxr4w5",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Alves",
      email: "joycyalves24@gmail.com",
      telephone: "55 88998408292",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venz4xr2n" },
    transaction: {
      status: "rejected",
      created_date: "2021-07-08 23:09:01",
      updated_date: "2021-07-13 23:06:40",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venz4xr2n",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Adontino dos Santos Neto",
      email: "netosantos262@gmail.com",
      telephone: "55 63992853676",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven4glyzy" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 23:09:46",
      updated_date: "2021-07-08 23:10:40",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven4glyzy",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Vitória de Oliveira do Nascimento",
      email: "vitoria2001oliveira2016@gmail.com",
      telephone: "55 85997885226",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venjxr41z" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 23:12:30",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613918401012486790000009700",
      payment_bar_code: "03394867900000097009058725700000021391840101",
      payment_url: "https://ev.braip.com/checkout/boleto/venjxr41z",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Antonio marcones da Silva Martins",
      email: "marconesmartins214@gmail.com",
      telephone: "55 85997544498",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venn35dmw" },
    transaction: {
      status: "approved",
      created_date: "2021-07-08 23:51:26",
      updated_date: "2021-07-16 08:31:20",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613932601019786790000009700",
      payment_bar_code: "03397867900000097009058725700000021393260101",
      payment_url: "https://ev.braip.com/checkout/boleto/venn35dmw",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Bruno Aleixo de Sa",
      email: "aleixo.drive@gmail.com",
      telephone: "55 11945866431",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven3n0qnl" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-08 23:57:48",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613934401012586790000009700",
      payment_bar_code: "03395867900000097009058725700000021393440101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven3n0qnl",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "George Fernando Ribeiro de Sousa",
      email: "nando86ge@gmail.com",
      telephone: "55 89994733743",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venx4owdv" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 00:10:13",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613940101010186790000009700",
      payment_bar_code: "03391867900000097009058725700000021394010101",
      payment_url: "https://ev.braip.com/checkout/boleto/venx4owdv",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Jhonatan Trajano do nascimento",
      email: "trajanojhonatan26@gmail.com",
      telephone: "55 86994820969",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venx4ow7q" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 00:23:07",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613943401011486790000009700",
      payment_bar_code: "03394867900000097009058725700000021394340101",
      payment_url: "https://ev.braip.com/checkout/boleto/venx4ow7q",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Carlos Antônio dos Freitas dos rezes",
      email: "carlosantoniodosreisviana@gmail.com",
      telephone: "55 8899453119",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven4gl32p" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 00:45:14",
      updated_date: "2021-07-10 00:50:24",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven4gl32p",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Renan Fabricio Pipermo",
      email: "renanfabriciopipermo@icloud.com",
      telephone: "55 11973435883",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venx4ojj0" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 01:22:32",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613954801018986790000009700",
      payment_bar_code: "03399867900000097009058725700000021395480101",
      payment_url: "https://ev.braip.com/checkout/boleto/venx4ojj0",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Francisco Elton Fernandes da silva",
      email: "eltonissac07@gmail.com",
      telephone: "55 85985008246",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven9nx0np" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 01:47:49",
      updated_date: "2021-07-09 01:47:51",
      value: 11688,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven9nx0np",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Matheus de Sousa Araújo",
      email: "meurecebidos@gmail.com",
      telephone: "55 85989645208",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venn35lv4" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 03:04:35",
      updated_date: "2021-08-08 03:10:22",
      value: 10138,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venn35lv4",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Nayara Sthefany Lima Silva",
      email: "nayaralima2022@gmail.com",
      telephone: "55 84986877409",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veny41j5x" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 03:37:34",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002613966201017186790000009700",
      payment_bar_code: "03391867900000097009058725700000021396620101",
      payment_url: "https://ev.braip.com/checkout/boleto/veny41j5x",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Mara cibelly santos de oliveira",
      email: "cibellyoliveira272203@gmail.com",
      telephone: "55 84987785542",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven10pqld" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 05:57:31",
      updated_date: "2021-07-15 00:00:07",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven10pqld",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Daniel Carlos de Paula",
      email: "danielsalluz@gmail.com",
      telephone: "55 85988635902",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venx4or40" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 06:36:51",
      updated_date: "2021-07-10 06:40:27",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venx4or40",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Ardenalle Vimerson Rocha Dos Santos",
      email: "Ardenallessantos@gmail.com",
      telephone: "55 9498444822",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven3n01r9" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 07:10:02",
      updated_date: "2021-08-08 07:20:29",
      value: 10287,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven3n01r9",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Francisco jeury Cavalcante Araújo",
      email: "jeurycavalcante27@gmail.com",
      telephone: "55 8598780895",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venv4mdrn" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 07:57:33",
      updated_date: "2021-07-15 00:01:06",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venv4mdrn",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Ighor breno Marques maciel Sarmento",
      email: "ycarobruno00@gmail.com",
      telephone: "55 85987284505",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veno7w7vx" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 08:33:43",
      updated_date: "2021-07-14 23:55:10",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/veno7w7vx",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Carlos Albano da Silva",
      email: "carlos.junior23256@gmail.com",
      telephone: "55 11952416300",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veno7w7wo" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 09:02:26",
      updated_date: "2021-08-08 09:10:31",
      value: 10287,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/veno7w7wo",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "LUIZ CARLOS BEZERRA DA SILVA",
      email: "luizyasmim9@gmail.com",
      telephone: "55 85987445058",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venw4nz42" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 09:43:01",
      updated_date: "2021-07-15 00:05:59",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venw4nz42",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Domingos Lourenço Pereira",
      email: "pauloodavid21@gmail.com",
      telephone: "55 85988484342",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venk5ooww" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 10:11:03",
      updated_date: "2021-07-20 07:45:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002614071101019386790000009700",
      payment_bar_code: "03393867900000097009058725700000021407110101",
      payment_url: "https://ev.braip.com/checkout/boleto/venk5ooww",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Carmeline da Costa Sousa",
      email: "Dudacarmeline@gmail.com",
      telephone: "55 85991273136",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venrz77x1" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 10:35:01",
      updated_date: "2021-07-15 00:00:47",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venrz77x1",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "José Samuel da Silva",
      email: "samumotobike@gmail.com",
      telephone: "55 85985012019",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venjxrrd7" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 10:40:00",
      updated_date: "2021-07-15 00:00:26",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venjxrrd7",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "José Samuel da Silva",
      email: "samumotobike@gmail.com",
      telephone: "55 85985012019",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venggrkwg" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 12:29:21",
      updated_date: "2021-07-09 12:30:40",
      value: 10138,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venggrkwg",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Joelia Rodrigues Teles",
      email: "joeliateles1992@gmail.com",
      telephone: "55 85987921999",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venjxromy" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 12:37:00",
      updated_date: "2021-07-10 12:50:36",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venjxromy",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "José Jonathan Marques Carneiro",
      email: "extmore@gmail.com",
      telephone: "55 85991898671",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venggrk0y" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 12:39:02",
      updated_date: "2021-07-09 12:39:04",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venggrk0y",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Lindomarcos Pinheiro braga",
      email: "lindomarcosbragaa@gmail.com",
      telephone: "55 85985064397",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venqy373x" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 12:43:24",
      updated_date: "2021-07-09 13:17:35",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venqy373x",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Lindomarcos Pinheiro braga",
      email: "lindomarcosbragaa@gmail.com",
      telephone: "55 85985064397",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venk5o1jo" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 12:46:00",
      updated_date: "2021-08-08 12:50:34",
      value: 10287,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venk5o1jo",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Rodney Emanoel carvalho de lima",
      email: "rodneylindo329@gmail.com",
      telephone: "55 91984069011",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venlw1x3v" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 12:47:37",
      updated_date: "2021-07-09 12:47:40",
      value: 10287,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venlw1x3v",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Luizi da Costa",
      email: "paidafaccao745@gmail.com",
      telephone: "55 85992265810",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven4glzny" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 13:00:01",
      updated_date: "2021-07-20 07:50:11",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002614158001017186790000009700",
      payment_bar_code: "03391867900000097009058725700000021415800101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven4glzny",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Fabricio lima araujo",
      email: "fabriciope614@gmail.com",
      telephone: "55 85985043800",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veno7w294" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 13:00:07",
      updated_date: "2021-07-20 07:50:11",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002614158101015586790000009700",
      payment_bar_code: "03395867900000097009058725700000021415810101",
      payment_url: "https://ev.braip.com/checkout/boleto/veno7w294",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Dayane Felix Pereira",
      email: "dayfelix0505@gmail.com",
      telephone: "55 85991454403",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven3n0r3j" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 13:06:59",
      updated_date: "2021-07-09 13:07:03",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven3n0r3j",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Eduardo Felipe Silva França",
      email: "francadudu07@gmail.com",
      telephone: "55 85981832803",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven5ngrx0" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 13:10:36",
      updated_date: "2021-07-09 13:10:38",
      value: 10138,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven5ngrx0",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Carlos Alexandre Dos santos Souza",
      email: "lessa9079@gmail.com",
      telephone: "55 82981212499",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venlw15r4" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 13:17:46",
      updated_date: "2021-07-10 13:20:35",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venlw15r4",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Keila Graziele Barros Rodrigues",
      email: "keilagraziele0808@gmail.com",
      telephone: "55 85986692671",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veny41vwr" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 13:29:34",
      updated_date: "2021-07-15 00:10:57",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/veny41vwr",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Francisco Elvis Gomes da Silva",
      email: "elvis.horizontino25@gmail.com",
      telephone: "55 85991919466",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven0ldrjr" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 13:34:49",
      updated_date: "2021-07-10 13:40:35",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven0ldrjr",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Pablo Henrique Santos Costa",
      email: "pablohzd1@gmail.com",
      telephone: "55 98984282631",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven0ld39r" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 12:46:15",
      updated_date: "2021-07-09 13:37:18",
      value: 10138,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven0ld39r",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Igor Santos Sousa",
      email: "netasantos3129@gmail.com",
      telephone: "55 99982594411",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven5ngrvo" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 13:35:59",
      updated_date: "2021-07-15 00:00:55",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven5ngrvo",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "María Michelle pinto silva",
      email: "mariamichellepinto@gmail.com",
      telephone: "55 85991793911",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venk5oxdx" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 13:42:02",
      updated_date: "2021-07-20 07:50:11",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002614178501012986790000009700",
      payment_bar_code: "03399867900000097009058725700000021417850101",
      payment_url: "https://ev.braip.com/checkout/boleto/venk5oxdx",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Nilton Carlos da Silva",
      email: "niltoncarlos-nil@hotmail.com",
      telephone: "55 85997071704",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venv4mqvv" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 13:44:37",
      updated_date: "2021-08-08 13:50:33",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venv4mqvv",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Marcicleia Silva Santos",
      email: "Marcicleiasantos802@gmail.com",
      telephone: "55 99996460163",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven2ldj47" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 14:03:17",
      updated_date: "2021-07-20 07:50:11",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002614188801014586790000009700",
      payment_bar_code: "03395867900000097009058725700000021418880101",
      payment_url: "https://ev.braip.com/checkout/boleto/ven2ldj47",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Maria Andressa Rufino da Silva",
      email: "andressacostta2509@icloud.com",
      telephone: "55 8599203539",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven10pmyp" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 14:43:26",
      updated_date: "2021-07-09 15:01:16",
      value: 10740,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven10pmyp",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "ARINALDO PEREIRA Lopes",
      email: "pereiraizaias859@gmail.com",
      telephone: "55 88999038134",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venqy3pnp" },
    transaction: {
      status: "rejected",
      created_date: "2021-07-09 14:43:44",
      updated_date: "2021-07-16 17:21:51",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venqy3pnp",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Ítalo Cheslon Silva de moraes",
      email: "italo.cheslon@hotmail.com",
      telephone: "55 85981512910",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven0ldk2k" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 15:15:47",
      updated_date: "2021-07-09 15:15:50",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven0ldk2k",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "João Vitor Brandão coelho",
      email: "gabrielvitorr722@gmail.com",
      telephone: "55 85988081361",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venk5oyqw" },
    transaction: {
      status: "rejected",
      created_date: "2021-07-09 15:29:00",
      updated_date: "2021-07-09 18:11:47",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venk5oyqw",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Gabriel Silva de Moraes",
      email: "gabriel.sd.morais48@gmail.com",
      telephone: "55 85988709170",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venpq20p7" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 17:40:10",
      updated_date: "2021-08-08 17:40:34",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venpq20p7",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plaz0x0l",
          name: "OFERTA ESPECIAL",
          quantity: 1,
          price: 19700,
          products: [
            {
              id: "pro04mog",
              name: "Método Estratégias Digitais",
              quantity: 1,
              price: 197,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Matheus Emiliano Alves De Oliveira",
      email: "matheusemiliano88@gmail.com",
      telephone: "55 81992277262",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 14592,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven4gln55" },
    transaction: {
      status: "approved",
      created_date: "2021-07-09 17:45:09",
      updated_date: "2021-08-08 17:50:35",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 2,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven4gln55",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Gabriel da Silva rodrigues",
      email: "gabrielrodriguezrj@gmail.com",
      telephone: "55 21980493034",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven9nlym4" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 17:59:28",
      updated_date: "2021-07-15 00:00:24",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven9nlym4",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Antônio rosenildo Araujo Medeiros",
      email: "antoniorosenildo92@gmail.com",
      telephone: "55 85986929015",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "vend4jg7l" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 19:33:15",
      updated_date: "2021-07-14 23:56:10",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/vend4jg7l",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Vickson Rodrigues dos santos",
      email: "Vicksonsantos726@gmail.com",
      telephone: "55 88999686747",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven7n9z2v" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 19:58:17",
      updated_date: "2021-07-15 00:00:15",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven7n9z2v",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "João Linhares Silva",
      email: "jlinhares484@gmail.com",
      telephone: "55 85997292626",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venggjoy1" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-09 22:02:06",
      updated_date: "2021-07-20 07:50:11",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002614383601011186790000009700",
      payment_bar_code: "03391867900000097009058725700000021438360101",
      payment_url: "https://ev.braip.com/checkout/boleto/venggjoy1",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "ISANA Acacio De Sousa",
      email: "elivandarocha38@gmail.com",
      telephone: "55 85985934303",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venqyqdkx" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-13 14:53:05",
      updated_date: "2021-07-22 07:52:58",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002616173501012986830000009700",
      payment_bar_code: "03399868300000097009058725700000021617350101",
      payment_url: "https://ev.braip.com/checkout/boleto/venqyqdkx",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Estefania gomes feijão",
      email: "gomesestefania41@gmail.com",
      telephone: "55 85987510927",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "vengg9yl1" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-13 21:34:44",
      updated_date: "2021-07-18 23:55:08",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/vengg9yl1",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "pla919qq",
          name: "SCORE SERASA OFICIAL",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "prolo7wn",
              name: "SCORE BOM SERASA",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Domingos Lourenço Pereira",
      email: "pauloodavid21@gmail.com",
      telephone: "55 85988484342",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venpqlg9v" },
    transaction: {
      status: "approved",
      created_date: "2021-07-15 08:13:30",
      updated_date: "2021-07-22 15:21:06",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002617176501017886860000019700",
      payment_bar_code: "03398868600000197009058725700000021717650101",
      payment_url: "https://ev.braip.com/checkout/boleto/venpqlg9v",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plaz0x0l",
          name: "OFERTA ESPECIAL",
          quantity: 1,
          price: 19700,
          products: [
            {
              id: "pro04mog",
              name: "Método Estratégias Digitais",
              quantity: 1,
              price: 197,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Elias de souza",
      email: "eliass.spindola16@gmail.com",
      telephone: "55 47991602301",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 14592,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venggyk4g" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-15 15:18:29",
      updated_date: "2021-07-21 00:00:52",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venggyk4g",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plaz0x0l",
          name: "OFERTA ESPECIAL",
          quantity: 1,
          price: 19700,
          products: [
            {
              id: "pro04mog",
              name: "Método Estratégias Digitais",
              quantity: 1,
              price: 197,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Isadora",
      email: "isadoraloise126@gmail.com",
      telephone: "55 46999833529",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 14592,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "veno703vx" },
    transaction: {
      status: "approved",
      created_date: "2021-07-15 15:20:50",
      updated_date: "2021-07-16 16:20:41",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/veno703vx",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plaz0x0l",
          name: "OFERTA ESPECIAL",
          quantity: 1,
          price: 19700,
          products: [
            {
              id: "pro04mog",
              name: "Método Estratégias Digitais",
              quantity: 1,
              price: 197,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JEAN HÉLVIO VIEIRA DA SILVA",
      document: "35567018000154",
      email: null,
      tel: "61996639402",
    },
    customer: {
      id: null,
      name: "Isadora",
      email: "isadoraloise126@gmail.com",
      telephone: "55 46999833529",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 14592,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venggzj5y" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-18 22:51:44",
      updated_date: "2021-07-23 23:55:25",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venggzj5y",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plajx2qg",
          name: "PLANO 1-AFILIADOS 97,00",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "propr13x",
              name: "RECEITAS ASIATICAS",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "GEORGE MARCEL OLIVEIRA SANTOS 10943312604",
      document: "37229491000157",
      email: null,
      tel: "38999495329",
    },
    customer: {
      id: null,
      name: "teste teste",
      email: "teste@gmail.com",
      telephone: "55 66998877669",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venjxkjz7" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-18 23:02:08",
      updated_date: "2021-07-24 00:05:33",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venjxkjz7",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plajx2qg",
          name: "PLANO 1-AFILIADOS 97,00",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "propr13x",
              name: "RECEITAS ASIATICAS",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "GEORGE MARCEL OLIVEIRA SANTOS 10943312604",
      document: "37229491000157",
      email: null,
      tel: "38999495329",
    },
    customer: {
      id: null,
      name: "teste teste",
      email: "teste@gmail.com",
      telephone: "55 66998877669",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "vend47j9q" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-18 23:04:53",
      updated_date: "2021-07-24 00:05:21",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/vend47j9q",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plajx2qg",
          name: "PLANO 1-AFILIADOS 97,00",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "propr13x",
              name: "RECEITAS ASIATICAS",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "GEORGE MARCEL OLIVEIRA SANTOS 10943312604",
      document: "37229491000157",
      email: null,
      tel: "38999495329",
    },
    customer: {
      id: null,
      name: "teste teste",
      email: "teste@gmail.com",
      telephone: "55 66998877669",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venm2m0r7" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-19 22:32:11",
      updated_date: "2021-07-24 23:55:24",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venm2m0r7",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plajx2qg",
          name: "PLANO 1-AFILIADOS 97,00",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "propr13x",
              name: "RECEITAS ASIATICAS",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "GEORGE MARCEL OLIVEIRA SANTOS 10943312604",
      document: "37229491000157",
      email: null,
      tel: "38999495329",
    },
    customer: {
      id: null,
      name: "teste teste",
      email: "teste@gmail.com",
      telephone: "55 66998877669",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "ven5ndjdv" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-19 22:54:44",
      updated_date: "2021-07-24 23:55:49",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/ven5ndjdv",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plaglkp8",
          name: "Plano George e Yannya",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "propr13x",
              name: "RECEITAS ASIATICAS",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "GEORGE MARCEL OLIVEIRA SANTOS 10943312604",
      document: "37229491000157",
      email: null,
      tel: "38999495329",
    },
    customer: {
      id: null,
      name: "Maria Vita Alves Silva (TESTE GEORGE)",
      email: "TESTE@TESTE.COM.BR",
      telephone: "55 35997375544",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 8930,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venqyro4k" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-19 22:56:54",
      updated_date: "2021-07-25 00:00:34",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venqyro4k",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plaglkp8",
          name: "Plano George e Yannya",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "propr13x",
              name: "RECEITAS ASIATICAS",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "GEORGE MARCEL OLIVEIRA SANTOS 10943312604",
      document: "37229491000157",
      email: null,
      tel: "38999495329",
    },
    customer: {
      id: null,
      name: "Maria Vita Alves Silva (TESTE GEORGE)",
      email: "TESTE@TESTE.COM.BR",
      telephone: "55 35997375544",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 0,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venqyrozk" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-19 22:58:42",
      updated_date: "2021-07-25 00:00:49",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venqyrozk",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plajx2qg",
          name: "PLANO 1-AFILIADOS 97,00",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "propr13x",
              name: "RECEITAS ASIATICAS",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "GEORGE MARCEL OLIVEIRA SANTOS 10943312604",
      document: "37229491000157",
      email: null,
      tel: "38999495329",
    },
    customer: {
      id: null,
      name: "Maria Vita Alves Silva (TESTE GEORGE)",
      email: "TESTE@TESTE.COM.BR",
      telephone: "55 35997375544",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 4465,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venm2m9kn" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-19 23:02:21",
      updated_date: "2021-07-25 00:05:27",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venm2m9kn",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plajx2qg",
          name: "PLANO 1-AFILIADOS 97,00",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "propr13x",
              name: "RECEITAS ASIATICAS",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "GEORGE MARCEL OLIVEIRA SANTOS 10943312604",
      document: "37229491000157",
      email: null,
      tel: "38999495329",
    },
    customer: {
      id: null,
      name: "Maria Vita Alves Silva (TESTE GEORGE)",
      email: "TESTE@TESTE.COM.BR",
      telephone: "55 35997375544",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 0,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venw4q3r2" },
    transaction: {
      status: "canceled",
      created_date: "2021-07-19 23:10:38",
      updated_date: "2021-07-24 23:56:10",
      value: 9700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: 5,
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: "https://ev.braip.com/checkout/boleto/venw4q3r2",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "planxq8z",
          name: "PLANO 1-AFILIADOS 97,00",
          quantity: 1,
          price: 9700,
          products: [
            {
              id: "propr13x",
              name: "RECEITAS ASIATICAS",
              quantity: 1,
              price: 97,
            },
          ],
        },
      ],
    },
    producer: {
      name: "GEORGE MARCEL OLIVEIRA SANTOS 10943312604",
      document: "37229491000157",
      email: null,
      tel: "38999495329",
    },
    customer: {
      id: null,
      name: "Maria Vita Alves Silva (TESTE GEORGE)",
      email: "TESTE@TESTE.COM.BR",
      telephone: "55 35997375544",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 0,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "BRAIP", order_id: "venq3rw2l" },
    transaction: {
      status: "approved",
      created_date: "2021-08-24 12:52:03",
      updated_date: "2021-09-10 13:51:56",
      value: 42699,
      discount_value: null,
      freight: 2999,
      freight_type: "PAC",
      payment_type: 1,
      payment_card_brand: null,
      payment_line: "03399058792570000002647718801013287250000042699",
      payment_bar_code: "03392872500000426999058725700000024771880101",
      payment_url: "https://ev.braip.com/checkout/boleto/venq3rw2l",
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: "plaqq01l",
          name: "Lurônic Caps - 5 + 1 Pote Extra",
          quantity: 1,
          price: 42699,
          products: [
            {
              id: "proj64q2",
              name: "LURÔNIC - Ácido Hialurônico",
              quantity: 1,
              price: 426.99,
            },
          ],
        },
      ],
    },
    producer: {
      name: "JH PRODUTOS NATURAIS LTDA",
      document: "42816795000150",
      email: null,
      tel: null,
    },
    customer: {
      id: null,
      name: "Mirian",
      email: "miriankrupka@gmail.com",
      telephone: "55 42999742502",
      document: null,
    },
    comission: {
      comissions_list: [
        {
          type: "Afiliado",
          document: "43477415000163",
          name: "ADVERA TECNOLOGIA LTDA",
          email: "matheus@advera.com.br",
          value: 16494,
        },
      ],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
];

export const appmax_mock = [
  {
    identification: { data_source: "APPMX", order_id: 20509598 },
    transaction: {
      status: "refunded",
      created_date: "2022-04-16 15:07:14",
      updated_date: "2022-04-16 15:07:17",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29399250,
      name: "Jayson dos santos",
      email: "jayson.inf@gmail.com",
      telephone: "55629968363",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20621921 },
    transaction: {
      status: "refunded",
      created_date: "2022-04-18 23:19:09",
      updated_date: "2022-04-18 23:19:12",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29399250,
      name: "Jayson dos santos",
      email: "jayson.inf@gmail.com",
      telephone: "55629968363",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20622077 },
    transaction: {
      status: "refunded",
      created_date: "2022-04-18 23:24:17",
      updated_date: "2022-04-18 23:24:20",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29337108,
      name: "Alexandre Lazzarin Domingos",
      email: "xandy.lazzarin@gmail.com",
      telephone: "51997860282",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20647909 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-19 14:28:35",
      updated_date: "2022-04-19 14:28:38",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29572089,
      name: "Arlete Silva Bayma",
      email: "arletesilvabayma@gmail.com",
      telephone: "63999127594",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20650817 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-19 15:28:32",
      updated_date: "2022-04-19 15:30:59",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACyklEQVR4Xu2X0W3kMAwFpUak/ru4UqRGpJuhkKyzBwT3ETM/FrK2V5oADPke6ZT9/fpT3nfe1gOc9QBnPcBZ/weMUurcc69WVyt9rtb3dDMRmPyMynmf09PJr1Q3M4FRBYyrtNL6aH25kw70uADVFan6BcDc7FG5Emof/WwmAjI9ZIJuFsfeYz8NCNG+r39U/b5+FnAtRFvQCUE26tU8dGUBimW7P5qRDZVb/Z4IkJaNWYLZSqehFWzcE4GtWnGLB65Fpgi4ZQJTgVIqWimmsWh8mo95AHIhrChTUTTCbs1EgOBIk83DSFnGiZt7IrDVLZG2qmxsXnYwI00EhgIhRceyOvjkLBVgjpUYbDwuW1q09Vc17wciK05Ur9WkUa9rsRIAEgRRbGLNYFdzrrZXohIAdjoNJETLrrOkmK9MYIRNqpiB4p1BM78W635gR6ksk2MkgtM7l057PxC9gzu1Kgfr23BzgRl2jdt0tNHSTF8iMCzPqY5ddBinO69q3g8YpCFpIM5CMkb75a+4GeC0RIF0i0UaR76ZAF8+hkmzl6nXOS8DJQGYVGsfjVQrxcsOVTN9mUD1MzRNVyzrKOgS5O0A/SMqhF6OdhczFv1kAs6vmOjmyj5GiojyUs37AQp1Oql3tSNWL8VKALAqjqlmrPuuR4ZoZddq3g9E+wylopRw8o4BlwoMHz3pPJGggXTmlxfO24H4788qdRNmK6lRwJoIUCVEO9QJJm4exAvHZ5AJAMcmZdrMEAoGarx8Xt8GE4ACwbtm6GRqmWFDSQXMzzY6FRtZsn2UVMAVOTJJ0b1w8NbDeYB2XbauZnTNyYZ7KF0iMBWLIeoVDeNs2ZdiZQDMUgSicWwixtkNfCYDyz3ShWmnL1wquKYDWIf4wjgKZiYDFkt9LBPVjo1OU0kDLAwa4dI1MuIl6Fb0cRrw3XqAsx7grAc46weAv7g+7EqMl6WiAAAAAElFTkSuQmCC",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/b31b33cc-6785-4813-88c9-11c90d581c695204000053039865802BR5906APPMAX6009SAO PAULO62070503***630456E1",
      pix_ref: "20650817a8a3661b6feb53ccc72cbca0462",
      pix_expiration_date: "2022-04-19 15:58:33",
      pix_creation_date: "2022-04-19 15:28:33",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29575990,
      name: "Maricelis Gaube Bastiani Gaube bastiani",
      email: "maricelisbastiani@gmail.com",
      telephone: "55996897125",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20658725 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-19 18:34:29",
      updated_date: "2022-04-19 18:48:17",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACvUlEQVR4Xu2YS3LcMAxEyYsQ979FjgJehEw/0JmRZ+HKIupsRH9GAt9UYYBGU3bbP69f7TPysR7grAc46wHO+jsgW+sZU1f60cUasSdBI6DwnBmrBeEhNFsn6ASyC+ja1LaAHLE6QTOQY+gXWfZVpfoPwJ6rbxIl18g4QSOg77np1QTUNq8VtwFKS936WASNQK2UVjrFqtfBJssFZGTfirFHp1CumMunuB/QdazYS+PbN5M7EJC6ZgT+qJYN1qJimiQrsCiNnGPLN7iLijQnIJmoKiUWAYIOAmwDJl80qRPVIs+kfz4gy7kRiMyLu+oemfoA7SzOEDTLdS/lXpt1P5CDnU52zAuWVrb+apYBoCRKKjTEjK2qResuzTIASZFKLCmhqE2Dc3VcCnU/oLKcGCaqDKtR69IsB1Ax1QmGBy1lWDkbgcVtYCAcI5Uc7xmvJA1AEuWxl+3CEJACRoBxVUrZKzlRvZ663s0yADxYIVLyZGTIc+JjRoCjjAxX1HYiGbJ9JWkAZrWmM8Sna1Tt2iwDkJxmTK9GmDMevWKoTgAL218aoVSy0y3g2qzbARVIoyvz4ExDKHStxscINBm3nipiE2ZsMDTufcCuhz31SlkmPqYSTazdCFAiCqXsxPHHhzAiRgChsMUpr7FlaicG//4UBkACoU+EQzTCYXydAAWRh2pnsKeSDRnpZXgNAP+c4Dg/1lXXehfyMQIoFjs/tWJD8/x9eO8Gyj7KOxhhLgfavRipAZBmO2mxUyOTnCpWgBINGqbXUyXso1kBVlk4RSr3+jjcDQDjimGgm1kPOziYWmcEpr5n9amhWoYHM30XygGoSl+Do3yDPIPEpxnQ3CTlomGJtUvB3Q7wqIVWNDgIhjF2AjAhfSjBSm9yoHx3+7uBakyJNWiZJrnjp8yxDfhpPcBZD3DWA5z1D4Df9Wf1bugRrj8AAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/ee7183e6-4b61-44f3-bf56-a0c548c13f2d5204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304ECD9",
      pix_ref: "20658725cd00f17e3eb581b2ea5ea751805",
      pix_expiration_date: "2022-04-19 19:04:30",
      pix_creation_date: "2022-04-19 18:34:30",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29584966,
      name: "Elinalva dos Santos Loura ",
      email: "elinalva843@gmail.com",
      telephone: "99985367156",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20667482 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-19 21:44:19",
      updated_date: "2022-04-19 21:44:23",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29596188,
      name: "DOROTI ALVES DA SILVA SCANAVACA DOROTI A SCANAVACA",
      email: "dorotiscanavaca@hotmail.com",
      telephone: "19982089808",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20691339 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-20 12:19:32",
      updated_date: "2022-04-20 12:19:34",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29625403,
      name: "Maria leci Loures Dias Leci",
      email: "lecidias64@gmail.com",
      telephone: "32999161805",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20695646 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-20 13:45:27",
      updated_date: "2022-04-20 13:45:30",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29630798,
      name: "Raquel Aparecida de Souza g Raquel Daniela ",
      email: "raquelaparecidadesouzagomesdan@gmail.com",
      telephone: "16991997220",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20697192 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-20 14:19:39",
      updated_date: "2022-04-20 14:19:42",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29631686,
      name: "Ana Cláudia Leal Mendes Cláudia Leal Mendes Leal",
      email: "anamendes52@yahoo.com.br",
      telephone: "38999357914",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20708987 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-20 19:18:24",
      updated_date: "2022-04-20 19:18:27",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29645600,
      name: "Maria de Lourdes Fernandes Míssio Míssio",
      email: "simonemissio@hotmail.com",
      telephone: "19991371640",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20722380 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-21 06:47:49",
      updated_date: "2022-04-21 06:49:57",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACwElEQVR4Xu2YS3LjMAxEyYsQ979FjkJchJx+kMdSvEhlMerZiHH5Qz1XddBoUEnbP6+v9rnzsR7gWA9wrAc41u+A2VqfY4zYuVfruXjHphFIPeboq0XOvlekvtLZdAKz5wy0tjbaiDlidTbtQD2pPl0qVar/AKw2+p5d16U1JNkNwKhIkZk7ly7zWvs2QBd7fi42jUAtbalhu0QO+TW4yHIBNMvSz1TLShkvitG4/Bb3A0qumlQuaXpskjvUK3w2Ahoe6hHNr9jlk7jFVHMCao5YGqSVnChwUTMngErM2kRXr3kgZ3gdAD6FpHKRhc4Zp1kOgP2FObOKpW6pseYE2IYhu1JGghkqVkDjQpJaTS3Vh5FWY/1tlgWgQToFI76T/EBeRN4O8LGComE6pA3feGcFMCfokkVxsoxa13TfD0ziwkkS9CozlTRfzHIApBa3pDJmiSM74xR5P0BlmKCa3zQuGF+aVqD08UAcJ2rtXNy0AFn73GHlwXK/8TbLAbAlRRroXJ60DGpPkfcDk4kliwhw2Sbkm1kGYL8mxjHK661kfjtx7geoSrUrWjc3Wjtx0AgoOFLFZfxiiAT9cnXzdkACGxXS2YZlACoXVfMBRIXbHG75RRBeFeoSXgMgWzhG1bs6yPKF9YtZDkDymvpWauVSklqwSyUNQK+m/SuQ9l3E1wmUL0wRElNlG0xWL6D+0D6jgz9+eGxkdyPAJKdfNucIPcvOuphlANQwFEizIxCb5JgesgLHrd2iT0ZFZnKqWAFKhCjJY5hWfGYNNh/AUmYlsb+mF5/IsA+ouE7KhLrBySbLZJ0RSDRyoJMVnnTnqW+chXIABCaxjCES6AyEpxlY7JFXZph6WCpJkxfQkRK7+kRYVv9YAZggO6+BTv9KaBgBjNG9lp6C3uUfFohub5EG4Kf1AMd6gGM9wLH+AfAHs0QdzaBnYKsAAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/f172f786-1615-4cb8-aea8-472060cb625d5204000053039865802BR5906APPMAX6009SAO PAULO62070503***630434CE",
      pix_ref: "2072238061266a70fbb203d4d19e8fda87f",
      pix_expiration_date: "2022-04-21 07:17:50",
      pix_creation_date: "2022-04-21 06:47:50",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29664104,
      name: "Aline Juliana Silva Bomfá ",
      email: "alinebomfa1407@gmail.com",
      telephone: "31996324870",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20745253 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-21 15:05:20",
      updated_date: "2022-04-21 15:05:22",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29692415,
      name: "Ingelore  Knoth8",
      email: "ingeknoth21@gmail.com",
      telephone: "47991433505",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20745289 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-21 15:06:07",
      updated_date: "2022-04-21 15:06:09",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29692432,
      name: "Elizangela Marques Policarpo",
      email: "elizapolicarpo@hotmail.com",
      telephone: "27998934614",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20745465 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-21 15:10:02",
      updated_date: "2022-04-21 15:10:04",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29692610,
      name: "Ingelore  Knoth",
      email: "ingeknoth21@gmail.com",
      telephone: "47991433505",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20754585 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-21 18:32:10",
      updated_date: "2022-04-21 18:32:12",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29703737,
      name: "Jéssica Sanches",
      email: "jess.sanches@hotmail.com",
      telephone: "18981044447",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "Backredirect",
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20758636 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-21 19:55:43",
      updated_date: "2022-04-21 19:55:46",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29651201,
      name: "Rosemeire Martinelli ",
      email: "martinelli.davoli@gmail.com",
      telephone: "19997243658",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20773094 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 06:54:09",
      updated_date: "2022-04-22 06:54:11",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Elo",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29727614,
      name: "Lidiana  Sousa Santos",
      email: "lidiana10souza@hotmail.com",
      telephone: "11974070187",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20776423 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 08:28:40",
      updated_date: "2022-04-22 08:28:42",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29731772,
      name: "Elaine Marta de Almeida Almeida ",
      email: "elainemartaalmeida@gmail.com",
      telephone: "34999732152",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20778594 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 09:10:57",
      updated_date: "2022-04-22 09:11:00",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29734195,
      name: "Maria Sonia  Lima dos Santos ",
      email: "mariasonialimas@hotmail.com",
      telephone: "11988358060",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20779309 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 09:24:00",
      updated_date: "2022-04-22 09:24:02",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29734147,
      name: "Marta Terezinha de Jesus Jesus ",
      email: "martaterezinhamorais@gmail.com",
      telephone: "41997715254",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20780649 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 09:48:49",
      updated_date: "2022-04-22 09:48:51",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29737333,
      name: "Cristina Hitomi Ueda Feliciano ",
      email: "cristina_hueda@yahoo.com.br",
      telephone: "11975478882",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20781933 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 10:14:35",
      updated_date: "2022-04-22 10:14:39",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29739115,
      name: "Rosana  Trindade",
      email: "rosanavtrindade@gmail.com.br",
      telephone: "11999903331",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20784622 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 11:04:52",
      updated_date: "2022-04-22 11:04:55",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29741579,
      name: "Dilza Maciel Pereira ",
      email: "dilzamaciel@hotmail.com",
      telephone: "35999377093",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "Backredirect",
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20786799 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 11:44:51",
      updated_date: "2022-04-22 11:44:54",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29743895,
      name: "Valdirene Oliveira ",
      email: "vall.faria44@gmail.com",
      telephone: "14994525733",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20787032 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 11:49:15",
      updated_date: "2022-04-22 11:49:19",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29744904,
      name: "Valdirene Oliveira ",
      email: "vall.faria44@gmail.com",
      telephone: "14997525733",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20790536 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 12:58:25",
      updated_date: "2022-04-22 12:58:28",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29748834,
      name: "Shirlei Pastore Silva Silva",
      email: "shirlei_teus@hotmail.com",
      telephone: "41996420801",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20791382 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 13:15:18",
      updated_date: "2022-04-22 13:15:21",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29749665,
      name: "Maria Ivoneuda  Macena Mendes ",
      email: "andreluiz_silva@yahoo.com",
      telephone: "92988085777",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20791428 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 13:16:17",
      updated_date: "2022-04-22 13:16:21",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29749695,
      name: "Rejane  Carla Coutinho ",
      email: "atelierejanecoutinho@gmail.com",
      telephone: "67999588054",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20792689 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 13:39:54",
      updated_date: "2022-04-22 13:39:57",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29750475,
      name: "Antonia Gorete Alves pereira Alves pereira ",
      email: "aantoniagorete@gmail.com",
      telephone: "62981231545",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20795467 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 14:35:26",
      updated_date: "2022-04-22 14:35:29",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29754363,
      name: "Zilma  Claudino da Silva",
      email: "jaineclaudinos@gmail.com",
      telephone: "47999537081",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20801944 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 17:09:36",
      updated_date: "2022-04-22 17:09:38",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29761906,
      name: "Valdilene Andrade",
      email: "fioricarmini@hotmail.com",
      telephone: "61996300900",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20806583 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 19:12:20",
      updated_date: "2022-04-22 19:12:22",
      value: 39700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2787830,
          name: "5 Potes Biotina Hair ",
          description: "Biotina Hair",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335694,
              sku: "BIO1",
              name: "biotina hair ",
              description: "Kit com 5 Potes",
              price: "79.40",
              quantity: 5,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29768126,
      name: "Natasha  SOUZA",
      email: "simone21souza.melo@gmail.com",
      telephone: "19983124694",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20813749 },
    transaction: {
      status: "refunded",
      created_date: "2022-04-22 22:08:46",
      updated_date: "2022-04-22 22:08:49",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29776564,
      name: "jayson v santos",
      email: "jnetgo@yahoo.com.br",
      telephone: "62996836339",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20817172 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-23 00:08:42",
      updated_date: "2022-04-23 00:08:44",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29781299,
      name: "Marlene  Brum da Silva",
      email: "mbrumdasilva@yahoo.com",
      telephone: "21996570677",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "Backredirect",
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20709499 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-20 19:31:07",
      updated_date: "2022-04-23 07:28:39",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202272102175189640000029700",
      payment_bar_code: "00190000090339518700202272102175189640000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/626089abea16c564636a5d0f",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29646898,
      name: "Maria Solange Messias Oliveira ",
      email: "sollmesss@gmail.com",
      telephone: "21988046211",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "Backredirect",
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20709797 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-20 19:38:03",
      updated_date: "2022-04-23 07:28:45",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202272190170789640000029700",
      payment_bar_code: "00190000090339518700202272190170789640000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/62608b4bb48bed1b976a8ffa",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29647112,
      name: "Maria Ieda Ramos Santana Snatnaa ",
      email: "iedasantanaacorretora@hotmail.com",
      telephone: "75991293538",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20732765 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-21 10:51:18",
      updated_date: "2022-04-23 07:36:22",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202278670175689650000029700",
      payment_bar_code: "00190000090339518700202278670175689650000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/62616156d7168e638d40334d",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29677456,
      name: "Layana MM",
      email: "laymassariol@hotmail.com",
      telephone: "33998012588",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20844226 },
    transaction: {
      status: "refunded",
      created_date: "2022-04-23 15:49:45",
      updated_date: "2022-04-23 15:49:48",
      value: 39400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "48.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29776564,
      name: "jayson v santos",
      email: "jnetgo@yahoo.com.br",
      telephone: "62996836339",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20858014 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-23 20:53:11",
      updated_date: "2022-04-23 20:53:15",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29827569,
      name: "Erlane Maria  Chaves de Sousa",
      email: "erlaneoliveira2015@hotmail.com",
      telephone: "98982995695",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20858976 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-23 21:13:56",
      updated_date: "2022-04-23 21:13:59",
      value: 39400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "48.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29831740,
      name: "Silvana Aparecida Almeida Girotto Lima ",
      email: "silvana.girotto@hotmail.com",
      telephone: "18997852233",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20905388 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-24 19:27:04",
      updated_date: "2022-04-24 19:27:07",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29886520,
      name: "Lia Mara da Silva Garcia Silva Garcia",
      email: "liamara.garciasilva@gmail.com",
      telephone: "51989247758",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20919359 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-25 06:52:43",
      updated_date: "2022-04-25 06:52:46",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29906140,
      name: "Claudemir Andrade da Silva ",
      email: "lairsilva123@gmail.com",
      telephone: "15149961515",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20919645 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-25 07:09:47",
      updated_date: "2022-04-25 07:09:49",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29906317,
      name: "Eleuza Braga",
      email: "eleuzabraga@gmail.com",
      telephone: "61995307619",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20926107 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-25 09:53:45",
      updated_date: "2022-04-25 09:53:47",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29914367,
      name: "Lucas Braga",
      email: "lucasbragapaes1998@gmail.com",
      telephone: "61999428003",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20765856 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-21 22:23:13",
      updated_date: "2022-04-26 07:32:39",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202286184177189650000029700",
      payment_bar_code: "00190000090339518700202286184177189650000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/62620381df522f07ae6826d9",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29717833,
      name: "Jaide Borges",
      email: "jaideborges251@gmail.com",
      telephone: "48999408505",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20806944 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-22 19:21:00",
      updated_date: "2022-04-26 07:39:34",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202296635176189660000029700",
      payment_bar_code: "00190000090339518700202296635176189660000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/62632a4c3147453eeb48fda3",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29768411,
      name: "Rosenilda Soares de Souza Soares de Souza ",
      email: "bell.souza1985@gmail.com",
      telephone: "19971330495",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20823290 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-23 08:35:10",
      updated_date: "2022-04-26 07:45:05",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202301143174489670000029700",
      payment_bar_code: "00190000090339518700202301143174489670000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/6263e46f8efd7234d405642e",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29789837,
      name: "WaldeliceMaciel Oliveira MacielOliveira",
      email: "waldelicemacieloliveira1@gmil.com",
      telephone: "32988438186",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20918562 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-25 05:32:54",
      updated_date: "2022-04-26 08:17:58",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202324303177989690000029700",
      payment_bar_code: "00190000090339518700202324303177989690000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/62665cb6f39ece0bf718ec93",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29905385,
      name: "Darcí B Decker Decker",
      email: "darci@kiferro.com.br",
      telephone: "47991964935",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20930882 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-25 11:25:33",
      updated_date: "2022-04-26 08:23:12",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202327818171189690000029700",
      payment_bar_code: "00190000090339518700202327818171189690000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/6266af5e542072707d20d477",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29920024,
      name: "Narlette  Jean Paul ",
      email: "narlettejeanpaul@gmail.com",
      telephone: "65993088002",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20935374 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-25 12:53:29",
      updated_date: "2022-04-26 08:24:59",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202328918178889690000029700",
      payment_bar_code: "00190000090339518700202328918178889690000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/6266c3f91967ec2ff766afbb",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29924936,
      name: "Nádia Maciel",
      email: "nadianmaciel@hotmail.com",
      telephone: "18997126763",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20972566 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-26 08:32:54",
      updated_date: "2022-04-26 08:32:56",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29966786,
      name: "Maria Andelia Andélia Trindade",
      email: "maria.andelia@gmail.com",
      telephone: "71996605801",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20984941 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-26 12:37:45",
      updated_date: "2022-04-26 12:38:46",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACxElEQVR4Xu2WQW4kIRAE4SPw/1/4KfAR2IjC8rT7YO3BU740kntmICyVKrOSLvvn9VHuO7f1AGc9wFkPcNb/AaOUOvreq9XFl7la39PNRGBuf1f2J588+ZfqZiYw6qS20UopDWC0vqqb2UBph6LYaNUfAHuhzaico1JHumwgmKFN5p6LYz9jPw3Qn/O+7q6+n/8y4FqdsdmrUmTTvx66soDRRwhVtS3fdG6VSwRWYVB8WF4AeGWiWiIwzC4GthggyLbkRmupgBvOa0yPNfNHzzKBZW6EYLqX/DrIa3gTAByzaEvUaqMslgl+iZUAUFQ0qG9cKxIJZqV5gFucxeyynGB7lgpQHRLRp9HtmYoNf17UfDswjQx+epvxMSLUv4n1foCyXNUpxrtT90x0ywS2eWWnTC/dAm2/MgEuE2eXCFGjZpQR5t/UfDswfLvyHiNDdCvFWWr7KjIBCLF0CTfKp2T7WDgPCI+2pkMozqsN84KkAhgVeXhuU3RYZ1xviYD55awuL3U8o2Ws9qvIBICJRSM0o1dFkUbcLhc13w9g0K5YfTvEER6UeblQEgBaMs7gFAPEFy1UM1MTAUalxKxQZdypXb+8xEoADDHKs0W+eAqs07U8wDFFrh4DVGN4adRleBMALpMZuWGi1pOoVPsSKwHQsDTJb93y6BD8RawEwI1tenhE2zSO45sMKJht8ixSdV6HNwPwLtEpTg2HBiutMtjSgG2fIMK0USiqrYtYCQCzSmpt83TaJ6tmfK+dfD/Aq51pXj2JkRneKqnAiOw+LzinS8ZHSQVc9iiaFOk1uVdiitKAGFf90qzOMDPBkC4RCK8UrxNAHyHcRawMwIE5g0O93Tq7hc9kYOtSM8QM46LVwTUdKC0CfJocU8fkAjIdf9Adr9ZthFBoTwRCmDBr1zKYt37Och7w03qAsx7grAc46xeAf1JC2gKZOsZNAAAAAElFTkSuQmCC",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/2e44968a-36e0-4a7b-9543-c5983499c9a15204000053039865802BR5906APPMAX6009SAO PAULO62070503***63045A8C",
      pix_ref: "20984941aad5ae57469aa0b6d36e2c7649d",
      pix_expiration_date: "2022-04-26 13:07:46",
      pix_creation_date: "2022-04-26 12:37:46",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29980365,
      name: "Roseli Souza Souza",
      email: "roseliotacilio0406@gmail.com",
      telephone: "15998197726",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20986323 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-26 13:08:08",
      updated_date: "2022-04-26 13:08:12",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29981665,
      name: "Ivone  Ap Marques de Souza",
      email: "ivonemarques59@hotmail.com",
      telephone: "11980562301",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21022905 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-27 10:53:46",
      updated_date: "2022-04-27 10:53:49",
      value: 39400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "48.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30023779,
      name: "Mirtes  Alves",
      email: "mislenealvesdosreis@hotmail.com",
      telephone: "11953442844",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "Backredirect",
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21024434 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-27 11:28:31",
      updated_date: "2022-04-27 11:28:33",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30025179,
      name: "Cassia  Yoshizawa ",
      email: "cassiayukoyoshizawa@gmail.com",
      telephone: "18996191609",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21028703 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-27 13:06:37",
      updated_date: "2022-04-27 13:06:39",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30029768,
      name: "Nelci  Fiuza de Souza ",
      email: "nnelcisouza@gmail.com",
      telephone: "47997720585",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21031226 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-27 14:03:29",
      updated_date: "2022-04-27 14:08:44",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACxElEQVR4Xu2XS24kIRBE4SLk/W8xRyEvAhMvsdy4F9YspmLVqNyF4bUUzk+A2/59/GnvK2/jA5zxAc74AGf8GzBb67m6ZrkX0xE7WTQCqSdn17o+94rUVzqLTmD2nIHW1kYbMUeszqIbGA1K8elSefS6gdVG37NrX1pDkt3AJllaz8ydS9u8a90GTIr2fbBoBGqoaJUnBUs1o/plk+ECZtAvo6qGTFG5Yq6/wgC0oRFrIK9kqlZSWbMCwcKep12yIjb1FSOgPZQFNtpCH/wMpj5gkiYSpkJFXx5E0fIBdGzDOTovNQ06Z7ySZQDKNhvJUYa6JH/ZmhNgJFVSrbJVt1kxswKUKOdYUjwVrLL172QZALJDvxAl6ZxRXXRn83mgmladoiKRjXXIwNCsgGyUOE18QworUdXHPiBpmU7tkiMJlEKq55Ws5wHp0So23kuhxNE7LyN1AP3UibLUmOiqpcYhZj5AW5i59srPqmbEXdl8HpAs3JMQ1RudiY8ZAVazfLO26aNa+RZpAChVlYwCRst0Wjh+Jut5QNfcUdKQVacLMu8T53kA68bMSVMlbfBeV7IcQFCpdI+unSqUFdTLlc3nAZpG1k2IuGABaL6twOYAKw+hgap5Fagfzfs4oLzQsKhr3PohV7+T9TwgbYVwx0OeIiSpd7IMAPIkarOl36pw4nZaAxBkix2VDfcd9XLezWsAdp1nHWE61fCSTgal1QcoSJJJzegZgw2pvpLlAIKgyDU4YalVbnuLoBkBpguV3HspncmpYgW0k+ffH+q3cpccb06AgXsFQSr3ejvcDQDtung4T1W3HQ/TzAkkGidF0jARAQMzfQXKAchJvxpHegOdgfA0A+Wc9CseFhwsmpkBxWWQq42tUzC0sRMgWWoXIIx0YyFlKj6AxLDXYaRMZq7ANfrYBvw2PsAZH+CMD3DGfwD+Ava14b7ZOLmtAAAAAElFTkSuQmCC",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/14b2a41f-98b6-46a1-b65c-c8c56ea077c65204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304C44C",
      pix_ref: "210312267ae85158f4a1d28091e84b8c468",
      pix_expiration_date: "2022-04-27 14:33:30",
      pix_creation_date: "2022-04-27 14:03:30",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30032449,
      name: "Lucineide leoncio da silva Leoncio da silva",
      email: "leonciolu@yahoo.com.br",
      telephone: "61981403555",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21037831 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-27 16:57:43",
      updated_date: "2022-04-27 16:57:46",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30039331,
      name: "Sônia Regina  Esteves",
      email: "soniaesilva2005@hotmail.com",
      telephone: "27999022828",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21051352 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-27 23:34:42",
      updated_date: "2022-04-27 23:34:44",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30054617,
      name: "Leila Maria  Silva Campos Machado ",
      email: "letileilela@yahoo.com.br",
      telephone: "11973533014",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21052884 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-28 01:23:11",
      updated_date: "2022-04-28 01:23:16",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30056644,
      name: "Angélica Araujo Santos Araujo Santos",
      email: "angel.araujos@hotmail.com",
      telephone: "79998102433",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20902061 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-24 18:16:45",
      updated_date: "2022-04-28 07:25:08",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202319707176289680000029700",
      payment_bar_code: "00190000090339518700202319707176289680000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/6265be3d620cf079242e2065",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29884371,
      name: "OSMARINA Sousa",
      email: "sousa_osmarina@yahoo.com",
      telephone: "88994859382",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20907312 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-24 20:07:55",
      updated_date: "2022-04-28 07:25:27",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202320861178189680000029700",
      payment_bar_code: "00190000090339518700202320861178189680000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/6265d84b913b8f236207d6d3",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29890077,
      name: "Fabiana Brito ",
      email: "fabianabb1@hotmail.com",
      telephone: "32991363074",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21021736 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-27 10:26:04",
      updated_date: "2022-04-28 07:38:35",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202351088170989710000029700",
      payment_bar_code: "00190000090339518700202351088170989710000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/6269446d5c539634ce588ed6",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30022404,
      name: "Luzia Santos Santos",
      email: "lusantos3045@gmail.com",
      telephone: "11996700589",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21075635 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-28 15:20:52",
      updated_date: "2022-04-28 15:20:55",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30082117,
      name: "Maria vilma Andrade lara",
      email: "vivianmcosta@yahoo.com.br",
      telephone: "37988385058",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21077438 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-28 16:02:45",
      updated_date: "2022-04-28 16:02:48",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30083779,
      name: "Verena Korman",
      email: "verenakorman@gmail.com",
      telephone: "31991303618",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21082843 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-28 18:40:26",
      updated_date: "2022-04-28 18:40:28",
      value: 39400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "48.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30089568,
      name: "Suely  de Almeida Castro ",
      email: "suelyacastro@hotmail.com",
      telephone: "21999798874",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "Backredirect",
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21047539 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-27 21:30:30",
      updated_date: "2022-04-29 07:35:41",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202357173174889710000029700",
      payment_bar_code: "00190000090339518700202357173174889710000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/6269e0265e9ba0161900d178",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30049795,
      name: "Inês  Kirst",
      email: "eletricapinheirinho@hotmail.com",
      telephone: "65999880268",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "Backredirect",
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21102326 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-29 09:31:43",
      updated_date: "2022-04-29 09:31:45",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2787905,
          name: "1 Pote Biotina Hair",
          description: ".",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335760,
              sku: "BIO1",
              name: "1 Pote Biotina Hair",
              description: "1 Pote ",
              price: "197.00",
              quantity: 1,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30112556,
      name: "Isabel Reis",
      email: "isacrist.1984@gmail.com",
      telephone: "47996759895",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "Backredirect",
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21101728 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-29 09:18:18",
      updated_date: "2022-04-29 09:32:43",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACwElEQVR4Xu2XS5IcIQwF4SJw/1v4KHARcKaY6K7uxYQXLq2KmE8XpCNkvfdUTNm/rz/le+drPcBZD3DWA5z1b8AopY7W+TT3qnsuPk83E4HJ12iV/Tk9nfyT6mYmMOqcUWsprbROwau6mQ2sxl6nP3X1qDQfWHXUPSrn1NpHj81MQMb2oBV24djfsZ8GUBad+lpuJgIuSmvoRLOKv5uHriyAJnG0THBRKZ0Lc/lf3A8s48pT7Jlc/LuxcU8ERiEmXeBotuzYaDHWsgBOMKqZ1a17lc43PcsFSGxrhncq2zwI3coDmFlaxPl1ImSdVp0IINSMybENz9At41SaB8zj2B7OtW3h3ItYCQDDI9QhNubFZsVYf4mVAAyr8YdWrT99+xLrdsDwOjVmqKV/2GglE7AhWNUtB8kModZVzfuB5RNblIZW/hwM8w81bwe2oZm+0GpU2K17nitoFsBLzRcaTNO4zDEyxE4u4PgIw/ohPFO1USLA+CatIZhTdFinO2817wdGdOdYlw/vW18mwMSiOSFaUSSQT7HuBwwK2cGkpMbgRJnXF0oGcORSJlvFPDt7iUAI5VsFhHd8Nco66FVkAkA5VthsUQyvZbt2KuDAqmY1ehXhpVGX8CYAVESF2tX7ln98gNWLWBlAFOUjmUEnOsQo+xTrboCjM0c92gY56r4E534AjzrQkcdLhfZpBOkS3hyAZ1TStHoHuSy7JgKhznGsKnnAVe8q1v2Al5yoa/TjHm97y6YlAl6y+MYngEZm+FZJBaJFQ5Nsh+mPeUoq4GKT9kSKx7lnRIrSAOPqwEAijjGMM2yGdGnA1CuNEs2KgeGB3r0blQGM0CkkQyLr7BY+k4GYnPQK69KhCBImTgaKFy29QnA0zEwG+Ap/cNYszxFCoT0RCGHW647hbYvHYo7TgN/WA5z1AGc9wFn/AfgLt2ourYTtfNUAAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/a4701092-ea4c-4bd7-b7fc-5bd9971915505204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304D07B",
      pix_ref: "2110172824aed0ff002dbc159962dfe4f63",
      pix_expiration_date: "2022-04-29 09:48:20",
      pix_creation_date: "2022-04-29 09:18:20",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30112146,
      name: "Dolores Moreno Cunha Dolores Moreno Cunha ",
      email: "doloresmorenojcc@gmail.com",
      telephone: "11958422908",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21109901 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-29 12:31:11",
      updated_date: "2022-04-29 12:31:14",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Elo",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788031,
          name: "1 Pote + 1 Extra Grátis",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335851,
              sku: "BIO1",
              name: "Pote Extra Biotina Hair",
              description: "3 Potes + 1 Pote Extra Grátis",
              price: "98.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30120697,
      name: "Maria  Lucia Mendonça",
      email: "marialuciamendonca@gmail.com",
      telephone: "19998461422",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21119283 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-29 16:42:35",
      updated_date: "2022-04-29 16:42:39",
      value: 39400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "48.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30093126,
      name: "Aline Decker",
      email: "alinedecker157@gmail.com",
      telephone: "51991571379",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "ActiveCampaign",
      utm_medium: "email",
      utm_campaign: "E-mail 01 - Compra Realizada",
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21125863 },
    transaction: {
      status: "chargeback_lost",
      created_date: "2022-04-29 20:07:12",
      updated_date: "2022-04-29 20:07:14",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30138433,
      name: "Gilda de Souza Sousa ",
      email: "gigi55rp@gmail.com",
      telephone: "16999994452",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21129100 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-29 21:41:56",
      updated_date: "2022-04-29 21:41:59",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30142370,
      name: "REGINA MARIA SCHWINGEL",
      email: "reginabeninca49@gmail.com",
      telephone: "42999732840",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 20972661 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-26 08:35:04",
      updated_date: "2022-04-30 07:05:55",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202338321173189700000029700",
      payment_bar_code: "00190000090339518700202338321173189700000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/6267d8e8ae4bee773c69a275",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29966876,
      name: "Tania Antunes de Oliveira Antunes de Oliveira ",
      email: "tania.camilli@gmail.com",
      telephone: "47991124383",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21102663 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-29 09:39:38",
      updated_date: "2022-04-30 07:20:45",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Boleto",
      payment_card_brand: null,
      payment_line: "00190000090339518700202372048179789730000029700",
      payment_bar_code: "00190000090339518700202372048179789730000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/626bdc8b02288a1fdf17e86a",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 29930403,
      name: "Sandra Regina da Silva  Silva ",
      email: "sandratumel@hotmail.com11",
      telephone: "11966130696",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21141785 },
    transaction: {
      status: "chargeback_in_tratative",
      created_date: "2022-04-30 09:58:43",
      updated_date: "2022-04-30 09:58:45",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30158180,
      name: "Miralva Alves da silva Santos ",
      email: "keren_alves@hotmail.com",
      telephone: "67999562344",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "Backredirect",
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21143669 },
    transaction: {
      status: "refunded",
      created_date: "2022-04-30 10:44:02",
      updated_date: "2022-04-30 10:44:04",
      value: 39400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "48.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30160803,
      name: "Carmélia  P. Santos ",
      email: "scarmelia191@gmail.com",
      telephone: "31983426049",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "ActiveCampaign",
      utm_medium: "email",
      utm_campaign: "E-mail 01 - Compra Realizada",
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21144374 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-30 10:59:54",
      updated_date: "2022-04-30 10:59:57",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30161097,
      name: "Poliana Paula Geraldo Nascimento",
      email: "polianapgn29@gmail.com",
      telephone: "35987138266",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21151171 },
    transaction: {
      status: "chargeback_won",
      created_date: "2022-04-30 13:46:03",
      updated_date: "2022-04-30 13:46:06",
      value: 39400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "48.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30169225,
      name: "MARLY APARECIDA PICAZIO GALLEANI GALLEANI",
      email: "marlypicazio@gmail.com",
      telephone: "16997219463",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21156258 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-30 16:04:16",
      updated_date: "2022-04-30 16:04:18",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30174777,
      name: "Ana Cristina  Carvalho Fernandes",
      email: "crisanacf@gmail.com",
      telephone: "55859962862",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21167101 },
    transaction: {
      status: "integrated",
      created_date: "2022-04-30 21:26:50",
      updated_date: "2022-04-30 21:26:52",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30156790,
      name: "Maria Aparecida Soares Branco",
      email: "cidasbranco@gmail.com",
      telephone: "31996695664",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: "ActiveCampaign",
      utm_medium: "email",
      utm_campaign: "E-mail 01 - Compra Realizada",
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21176368 },
    transaction: {
      status: "integrated",
      created_date: "2022-05-01 08:35:43",
      updated_date: "2022-05-01 08:35:46",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30200171,
      name: "Rosicléa Oliveira  Amorim",
      email: "rosiclea26@hotmail.com",
      telephone: "95991725383",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21176895 },
    transaction: {
      status: "integrated",
      created_date: "2022-05-01 08:50:43",
      updated_date: "2022-05-01 08:50:46",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30201077,
      name: "Lara Vitória Machado Souza  Machado ",
      email: "laravitoriams7@gmail.com",
      telephone: "28999436099",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21182050 },
    transaction: {
      status: "integrated",
      created_date: "2022-05-01 10:46:39",
      updated_date: "2022-05-01 10:48:20",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "Pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACyUlEQVR4Xu2XwXHdMAxEyUaA/rtIKUAjZPaBsSX74MkhQi7ifCvf1PMMZrELMmP/vH6N7zvf1guc9QJnvcBZfwfEGDP3MrO5M3cu851sNgKpT3iu4amHeepPJpudQEzVRq1jmIAwX5PNZiAmD5c+czlS/QdAAs0taugzPLw2OwF9UuX5sYte82/ttwGBab8vNhsB1jJ1a69JbNQv4yWrCwgPuYQ+GZUFzp1wjYDMog2VSHkFyCvKsXcCyqrssZgdsqq+iAuzTkDuQCqNDXXMVTM/0qwTWIOqSiK5ZpFkkCu8DQASaUuv2dWizvCrWQ0ALqVOMWpQ4JY4lfYBcocnLrGKrBRL/dG9Wc8DGejEKKdEWpc11q9mPQ8wvlWVuhPEVr8kz6tZDQCO4Vj34GRXm4wUV+FtAFNc55jUobrNWaKKb81qAGQUFcYEgeIpwb6k+3kAxDTBcQsRUnFkxz6LbADUF6VG101eF4Z/sHAj4HnqI7VQ0m3i4k7ASGty1WKKBnVin89mNQDsJmnBsxodZZx9O9wbAGkibeoUkV3UJCFfmtUAMMddxtWPn1sGZa6rWR1AueM8kYoMCZhXup8Hgik6OEKYJTLKcvxyNasBqJP1GIarBsBRrRFAJY40Bmqe8O6KUSOwShztc5Dpy2SccOlrBEoYdWrXOFOZKGa3ZjUAS8qwU6+wD8bxu1DPA7xlhARukWzJZL2HtwHYFKjuGBet80G9kq4LyLrZBT75Uygb924+D8gv1agMx8Ea6wqytGsF9PVMUs2PikxwqrQCkugIxCAvlRgfoxVgZaLPx/Tit0pRG0Bc9VIA1alYZlhW69qA3CWUV1Z4hDFML6E6AAJzgqN6uV/UdUeq9QI1OZkhCxjzKkLNAJlxfXOmGYbJZgDGKW0oPiov8c592j8PVGPqcHeCXP8ZqCOmEfhpvcBZL3DWC5z1D4Df/NXOSmUhEvwAAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/0dd09718-6bcd-4bce-946d-6758f83b3ee45204000053039865802BR5906APPMAX6009SAO PAULO62070503***63044DA7",
      pix_ref: "2118205080954b7a1467d701832676e95ae",
      pix_expiration_date: "2022-05-01 11:16:40",
      pix_creation_date: "2022-05-01 10:46:40",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30207838,
      name: "Lianara  Luviza",
      email: "carloseduardo_maciel@yahoo.com.br",
      telephone: "67992631799",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21182693 },
    transaction: {
      status: "integrated",
      created_date: "2022-05-01 11:00:29",
      updated_date: "2022-05-01 11:00:31",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30208138,
      name: "Eliane  Alves de Lima Ferraz",
      email: "ferrazeliane42@gmail.com",
      telephone: "62981473092",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21184786 },
    transaction: {
      status: "integrated",
      created_date: "2022-05-01 11:47:29",
      updated_date: "2022-05-01 11:47:32",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30211115,
      name: "Elsa Maria da Silva  Domingos ",
      email: "elsasilvadomingos@gmail.com",
      telephone: "48996270062",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21186087 },
    transaction: {
      status: "integrated",
      created_date: "2022-05-01 12:17:09",
      updated_date: "2022-05-01 12:17:11",
      value: 39400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "48.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30212429,
      name: "lucia franco salles salles",
      email: "luciafsalles@hotmail.com",
      telephone: "7133515934",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21194663 },
    transaction: {
      status: "integrated",
      created_date: "2022-05-01 15:45:21",
      updated_date: "2022-05-01 15:45:24",
      value: 39400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "48.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30223703,
      name: "Meire Tereza  Frois ",
      email: "meirefrois1@gmail.com",
      telephone: "31997799776",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21198457 },
    transaction: {
      status: "integrated",
      created_date: "2022-05-01 17:30:54",
      updated_date: "2022-05-01 17:30:56",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30228082,
      name: "Dorinalva  Rocha Teixeira ",
      email: "dorinalvarocha@yahoo.com.br",
      telephone: "11954245514",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    identification: { data_source: "APPMX", order_id: 21208854 },
    transaction: {
      status: "integrated",
      created_date: "2022-05-01 22:29:56",
      updated_date: "2022-05-01 22:29:58",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "CreditCard",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 30241643,
      name: "Marizete  Da Silva Vicentino",
      email: "major.cone@gmail.com",
      telephone: "27997457060",
      document: null,
    },
    comission: { comissions_list: [], co_production_commission: null },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
];
