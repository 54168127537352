import $ from "jquery";
import React, { useEffect } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/credit_card_lottie.json";

export const CreditCardLoader = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (props.isVisible) {
      $("html, body").css({
        overflow: "hidden",
        height: "100%",
      });
    } else {
      $("html, body").css({
        overflow: "auto",
        height: "auto",
      });
    }
  }, [props.isVisible]);
  return (
    <React.Fragment>
      <div
        className="flex-column justify-content-center align-items-center text-center"
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: "#ffffffCC",
          backdropFilter: "blur(15px)",
          zIndex: 1000,
          display: props.isVisible ? "flex" : "none",
        }}
      >
        <Lottie options={defaultOptions} height={100} width={100} />
        <span className="d-block text-muted mt-4" style={{ fontSize: 12 }}>
          <h5 className="text-black">
            Processando dados... <br /> por favor não saia ou feche esta página
          </h5>
        </span>
      </div>
    </React.Fragment>
  );
};
