import PropTypes from "prop-types";
import React from "react";
import { Row, Col, Alert, Container, Card, CardBody } from "reactstrap";
import MetaTags from "react-meta-tags";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";

//import images
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import { useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Preloader from "../Utility/Preloader";
import $ from "jquery";

const ResetPassword = (props) => {
  const [verifying, setVerifying] = useState(true);
  const [tokenStatus, setTokenStatus] = useState(false);

  const { _id, token } = useParams();

  function fade() {
    $("#newpassword").fadeOut(200);
    setTimeout(() => {
      $("#password-ok").fadeIn(200);
    }, 300);
  }

  function handleValidSubmit(event) {
    const data = { password: event.target.password.value };

    api
      .put(`/api/reset-password/${_id}/${token}`, data)
      .then((res) => {
        fade();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  async function checkToken() {
    setVerifying(true);
    await api
      .get(`/api/reset-password-check/${_id}/${token}`)
      .then((res) => {
        setVerifying(false);

        if (res.data.verified) {
          setTokenStatus(true);
        } else {
          toast.error("Seu link expirou!", { autoClose: false });
        }
        return res.data.verified;
      })
      .catch((err) => {
        setVerifying(false);
        console.log(err);
      });
  }
  useEffect(() => {
    checkToken();
  }, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Nova Senha | Metrito - Clareza Nos Dados</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="text-center mb-5">
                <Link to="/dashboard" className="auth-logo">
                  <img
                    src={logoDark}
                    alt=""
                    height="28"
                    className="auth-logo-dark"
                  />
                  <img
                    src={logoLight}
                    alt=""
                    height="28"
                    className="auth-logo-light"
                  />
                </Link>
                <p className="font-size-15 text-muted mt-3">
                  Clareza, Análise e <b>Crescimento</b>.
                </p>
              </div>
              <Row className="g-0 d-flex justify-content-center">
                <Col lg={6}>
                  <Card className="overflow-hidden">
                    {verifying ? (
                      <Preloader />
                    ) : !verifying && tokenStatus ? (
                      <div>
                        <div id="newpassword" className="p-lg-5 p-4">
                          <div>
                            <h5>Nova Senha</h5>
                            <p className="text-muted">Digite sua nova senha.</p>
                          </div>
                          <div className="mt-4 pt-3">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={(e) => handleValidSubmit(e)}
                            >
                              <div className="mb-3">
                                <AvField
                                  name="password"
                                  label="Nova senha"
                                  className="form-control"
                                  placeholder="Digite sua nova senha"
                                  type="password"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage:
                                        "Por favor, digite uma senha",
                                    },
                                  }}
                                />
                                <AvField
                                  name="confirmationPassword"
                                  label="Repita a Senha"
                                  placeholder="Repita sua nova senha"
                                  type="password"
                                  autoComplete="off"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Por favor, repita a senha",
                                    },
                                    match: { value: "password" },
                                  }}
                                />
                              </div>
                              <div className="mt-4 text-end">
                                <button
                                  className="btn btn-primary w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Redefinir Senha
                                </button>
                              </div>
                            </AvForm>
                          </div>
                        </div>
                        <div
                          className="text-center p-5"
                          id="password-ok"
                          style={{ display: "none" }}
                        >
                          <CardBody>
                            <i className="bx bx-check-circle fs-1 text-success mb-2"></i>
                            <h4>Tudo certo!</h4>
                            <p>Agora é só fazer login com sua nova senha.</p>
                            <Link to="/login">
                              <button className="btn btn-success mt-3">
                                Fazer Login
                              </button>
                            </Link>
                          </CardBody>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center p-5">
                        <CardBody>
                          <i className="bx bxs-hourglass-bottom fs-1 text-danger mb-2"></i>
                          <h4>Seu link expirou...</h4>
                          <p>
                            Por favor, gere outro link único para redefinir sua
                            senha.
                          </p>
                        </CardBody>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>

              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} <b>Metrito</b>.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
