import SalesAnalytics from "../pages/Dashboard/SalesAnalytics";
import ColumnChart from "../widgets/ColumnChart";
import BarChart from "../widgets/BarChart";
import DonutChart from "../widgets/DonutChart";
import CampaignsTable from "../widgets/CampaignsTable";
import MetricCards from "../widgets/MetricsCards";
import LineChart from "../widgets/LineChart";
import AreaChart from "../widgets/AreaChart";
import AccountStatus from "../widgets/AccountStatus";
import FreeText from "../widgets/FreeText";

export const defineComponent = (props, param) => {
  switch (parseInt(param.i)) {
    case 1:
      return (
        <AccountStatus
          adAccountList={props.selectedAdAccounts}
          brandId={props.dashboard.brandId}
        />
      );
      break;
    case 2:
      return (
        <MetricCards
          metrics={props.account_general_aggregated}
          metrics_by_data_source={props.account_by_data_source_aggregated}
          widget={param}
        />
      );
      break;
    case 3:
      return <CampaignsTable campaigns={props.campaigns} />;
      break;

    case 4:
      return (
        <SalesAnalytics
          maxMetric={12000}
          metrics={props.account_general_granular}
          summary={props.account_general_aggregated}
          selected={param}
        />
      );
      break;
    case 5:
      return (
        <ColumnChart
          metrics={props.account_general_granular}
          metrics_by_data_source={props.account_by_data_source_granular}
          selected={param}
        />
      );
      break;
    case 6:
      return (
        <BarChart metrics={props.account_general_granular} selected={param} />
      );
      break;
    case 7:
      return (
        <LineChart
          metrics={props.account_general_granular}
          metrics_by_data_source={props.account_by_data_source_granular}
          selected={param}
        />
      );
      break;
    case 8:
      return (
        <AreaChart
          metrics={props.account_general_granular}
          metrics_by_data_source={props.account_by_data_source_granular}
          summary={props.summary}
          selected={param}
        />
      );
      break;
    case 9:
      return (
        <DonutChart
          metrics={props.metrics}
          summary={props.summary}
          selected={param}
        />
      );
      break;
    case 10:
      return <FreeText widget={param} />;
    default:
      return <h1>Erro ao carregar widget. (v3)</h1>;
      break;
  }
};
