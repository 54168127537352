import { metricsArray } from "../common/data/metrics";

export function formatByDisplayType(value, metric) {
  const display_type = metricsArray.find(
    (o) => o.value === metric
  )?.display_type;

  switch (display_type) {
    case "CURRENCY":
      return Number.isNaN(value)
        ? "-"
        : value?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }) || "-";
      break;
    case "PERCENTAGE":
      return Number.isNaN(value)
        ? "-"
        : value?.toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
          }) || "-";
      break;
    case "NUMBER":
      return Number.isNaN(value) ? "-" : value?.toLocaleString("pt-BR") || "-";
      break;
    default:
      return value;
  }
}
