import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

//Import Breadcrumb
import api from "../../services/api";
import { changeLayout } from "../../store/actions";

//Charts
import AdminAreaChart from "./AreaChart";
import PieChart from "./PieChart";

import axios from "axios";
import classNames from "classnames";
import { endOfDay, startOfMonth } from "date-fns";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";
import BlurLoader from "../Payment/BlurLoader";

const Admin = (props) => {
  const [users, setUsers] = useState([]);

  const [registeredToday, setRegisteredToday] = useState(0);
  const [usersGraph, setUsersGraph] = useState([]);

  const [brandsToday, setBrandsToday] = useState(0);
  const [brandsGraph, setBrandsGraph] = useState([]);

  const [dashboardsToday, setDashboardsToday] = useState(0);
  const [dashboardsGraph, setDashboardsGraph] = useState([]);

  const [appmaxData, setAppmaxData] = useState([]);
  const [loadingAppmax, setLoadingAppmax] = useState(true);
  const [stripeData, setStripeData] = useState([]);
  const [loadingStripe, setLoadingStripe] = useState(true);

  const [report, setReport] = useState({});
  const [loadingReport, setLoadingReport] = useState(true);

  const [pixSales, setPixSales] = useState([]);

  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const cards = [
    {
      title: "Cadastrados",
      count: report.all_users_count,
      count_today: registeredToday,
      dollor: false,
      icon: "bx bxs-group text-dark",
    },
    {
      title: "Empresas",
      count: report.all_brands_count,
      count_today: brandsToday,
      dollor: false,
      icon: "bx bxs-briefcase-alt text-dark",
    },
    {
      title: "Dashboards",
      count: report.all_dashboards_count,
      count_today: dashboardsToday,
      dollor: false,
      icon: "bx bxs-dashboard text-dark",
    },
    {
      title: "Contas FB",
      count: report.all_ad_accounts_count,
      count_today: 0,
      dollor: false,
      icon: "bx bxl-facebook-circle text-dark",
    },
    {
      title: "Usuários em Trial Ativo",
      count: report.active_trial_users,
      count_today: 0,
      dollor: false,
      icon: "mdi mdi-test-tube text-dark",
      percentage: report.percentage_trial_users?.with,
      color: "warning",
      upArrow: true,
    },
    {
      title: "Usuários com empresa",
      count: report.users_with_brands,
      count_today: 0,
      dollor: false,
      icon: "bx bxs-briefcase-alt text-dark",
      percentage: report.percentage_users_brands?.with,
      color: "warning",
      upArrow: true,
    },
    {
      title: "Usuários com C. de Anúncio",
      count: report.users_with_ad_accounts,
      count_today: 0,
      dollor: false,
      icon: "bx bxl-facebook-circle text-dark",
      percentage: report.percentage_users_ad_accounts?.with,
      color: "warning",
      upArrow: true,
    },
    {
      title: "Assinantes",
      count: report.active_paid_users,
      count_today: 0,
      dollor: false,
      icon: "mdi mdi-cart-check text-dark",
      percentage: report.percentage_active_paid_users?.with,
      color: "warning",
      upArrow: true,
    },
    {
      title: "Usuários em Trial Expirado",
      count: report.expired_trial_users,
      count_today: 0,
      dollor: false,
      icon: "mdi mdi-test-tube-off text-dark",
      percentage: report.percentage_trial_users?.without,
      color: "warning",
      upArrow: true,
    },
    {
      title: "Média de empresa p/ usuário",
      count: report.average_brands_per_user,
      count_today: 0,
      dollor: false,
      icon: "bx bx-calculator text-dark",
      color: "warning",
      upArrow: true,
    },
    {
      title: "Média de C. de Anúncio p/ usuário",
      count: report.average_ad_accounts_per_user,
      count_today: 0,
      dollor: false,
      icon: "bx bx-calculator text-dark",
      color: "warning",
      upArrow: true,
    },
    {
      title: "Ticket Médio",
      count: report.average_ticket,
      count_today: 0,
      dollor: false,
      icon: "mdi mdi-ticket-percent-outline text-dark",
      color: "warning",
      upArrow: true,
    },
  ];

  function graphMaker(list, param, entity) {
    const all = list.map((o) =>
      moment(o[param]).toISOString(true).substring(0, 10)
    );

    const noduplicates = [...new Set(all)];
    const count = {};

    for (const element of all) {
      if (count[element]) {
        count[element] += 1;
      } else {
        count[element] = 1;
      }
    }
    // you can always convert it into an array of objects, if you must
    var i = 0;
    var final = [];
    for (i in count) {
      if (count.hasOwnProperty(i)) {
        final.push({ date: i, counts: count[i] });
      }
    }

    if (entity === "users") {
      // final.splice(0, 5);
      return final;
    } else if (entity === "brands") {
      // final.splice(0, 8);
      return final;
    } else {
      return final;
    }
  }

  async function getUsers(time_interval) {
    console.log("searchingUsers");
    await api
      .post("/api/admin/users", { time_interval })
      .then((res) => {
        setUsers(res.data);
        setRegisteredToday(
          res.data.filter(
            (o) =>
              moment(o.createdAt).toISOString(true).substring(0, 10) ===
              new Date().toISOString().substring(0, 10)
          ).length
        );

        setUsersGraph(graphMaker(res.data, "createdAt", "users"));
      })
      .catch((err) => {
        console.log(err);
        setUsers([]);
      });
  }

  function getReport(time_interval) {
    setLoadingReport(true);
    api
      .post("/api/admin/report", { time_interval })
      .then((res) => {
        console.log(res.data);
        setReport(res.data);
        setLoadingReport(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingReport(false);
      });
  }

  async function getAppmaxData() {
    setLoadingAppmax(true);

    const time_interval = {
      since: moment(datePickerState[0].startDate).format("YYYY-MM-DD"),
      until: moment(endOfDay(datePickerState[0].endDate)).format("YYYY-MM-DD"),
    };

    api
      .post("/api/appmax/list", { time_interval })
      .then((res) => {
        console.log({
          list: res.data.data.map((o) => ({
            status: o.status,
            total: o.total,
          })),
          total: res.data.data?.reduce((accumulator, obj) => {
            return accumulator + obj.total;
          }, 0),
          canceled: res.data.data
            ?.filter((o) => o.status === "cancelado")
            .reduce((accumulator, obj) => {
              return accumulator + obj.total;
            }, 0),
          refund: res.data.data
            ?.filter((o) => o.status === "estornado")
            .reduce((accumulator, obj) => {
              return accumulator + obj.total;
            }, 0),
        });
        setAppmaxData(res.data);
        setLoadingAppmax(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingAppmax(false);
      });
  }

  async function getStripeData() {
    const time_interval = {
      since: moment(datePickerState[0].startDate).unix(),
      until: moment(endOfDay(datePickerState[0].endDate)).unix(),
    };

    setLoadingStripe(true);
    api
      .post("/api/stripe/list", { time_interval })
      .then((res) => {
        console.log(res.data);
        setStripeData(res.data);
        setLoadingStripe(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingStripe(false);
      });
  }

  async function getBrandsGraph(time_interval) {
    await api
      .post("/api/admin/brands", { time_interval })
      .then((res) => {
        setBrandsToday(
          res.data.filter(
            (o) =>
              moment(o.createdAt).toISOString(true).substring(0, 10) ===
              new Date().toISOString().substring(0, 10)
          ).length
        );

        const bg = graphMaker(res.data, "createdAt", "brands");

        setBrandsGraph(bg);
      })
      .catch((err) => {
        console.log(err);
        setUsers([]);
      });
  }

  async function getDashboardsGraph(time_interval) {
    await api
      .post("/api/admin/dashboards", { time_interval })
      .then((res) => {
        setDashboardsToday(
          res.data.filter(
            (o) =>
              o.createdAt &&
              moment(o.createdAt).toISOString(true).substring(0, 10) ===
                new Date().toISOString().substring(0, 10)
          ).length
        );

        const dashboards_graph = graphMaker(
          res.data,
          "createdAt",
          "dashboards"
        );
        const straneous_object = dashboards_graph.shift();

        setDashboardsGraph(dashboards_graph);
      })
      .catch((err) => {
        console.log(err);
        setUsers([]);
      });
  }

  async function getPixSales() {
    axios
      .get("https://api.npoint.io/e71b59c44d59d0c7e132")
      .then((res) => {
        console.log(res.data);

        setPixSales(
          res.data.pix_sales.filter(
            (o) =>
              o.date.split("-")[1] ===
              moment(datePickerState[0].startDate).format("MM")
          )
        );
      })
      .catch((err) => console.log(err));
  }

  async function loadAllData() {
    var mongo_time_interval = {
      since: moment(datePickerState[0].startDate).toISOString(),
      until: moment(endOfDay(datePickerState[0].endDate)).toISOString(),
    };

    getReport(mongo_time_interval);
    getAppmaxData();
    getStripeData();
    getPixSales();

    getUsers(mongo_time_interval);
    getBrandsGraph(mongo_time_interval);
    getDashboardsGraph(mongo_time_interval);
  }

  useEffect(() => {
    if (
      window.confirm(
        "O painel mudou admin mudou! Deseja ir para o novo painel?"
      ) == true
    ) {
      window.location.href = "/admin/panel";
    } else {
      alert("Atenção, as informações do painel podem estar desatualizadas!");
    }

    props.changeLayout("vertical");

    console.log(datePickerState);

    loadAllData();
  }, []);

  var appmax_income = !appmaxData.data?.length
    ? 0
    : appmaxData.data
        ?.filter((o) => o.status !== "cancelado")
        .reduce((accumulator, obj) => {
          return accumulator + obj.total;
        }, 0);

  var appmax_refund = !appmaxData.data?.length
    ? 0
    : appmaxData.data
        ?.filter((o) => o.status === "estornado")
        .reduce((accumulator, obj) => {
          return accumulator + obj.total;
        }, 0);

  var stripe_income = !stripeData.transactions
    ? 0
    : stripeData.sales?.reduce((accumulator, obj) => {
        return accumulator + obj.amount / 100;
      }, 0);

  var stripe_refund = !stripeData.refunds
    ? 0
    : stripeData?.refunds.reduce((accumulator, obj) => {
        return accumulator + obj.amount / 100;
      }, 0);

  var total_income = appmax_income + stripe_income;
  var total_refund = appmax_refund + stripe_refund;
  var liquid =
    total_income -
    total_refund +
    pixSales.reduce((accumulator, obj) => {
      return accumulator + obj.pix_value;
    }, 0);

  var goal =
    liquid < 6000 ? 6000 : liquid >= 6000 && liquid < 8000 ? 8000 : 10000;
  var goal_done_percentage = Math.floor((liquid / goal) * 100);

  var bonus = liquid < 6000 ? 0 : liquid >= 6000 && liquid < 8000 ? 200 : 400;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mb-3">
            <Col lg={10} md={9}>
              <b className="fs-4">Painel Administrativo</b>
            </Col>
            <Col lg={2} md={3}>
              <UncontrolledDropdown>
                <DropdownToggle className="btn btn-light w-100" type="button">
                  <span className="float-start text-truncate text-dark">
                    Intervalo
                  </span>
                  <i className="mdi mdi-chevron-down text-dark float-end"></i>
                </DropdownToggle>
                <DropdownMenu className="mt-2 dropdown-menu-end">
                  <div className="d-flex justify-content-start">
                    <div
                      className="d-flex flex-column"
                      style={{ flexDirection: "column" }}
                    >
                      <DateRangePicker
                        className="float-end"
                        onChange={(item) =>
                          setDatePickerState([item.selection])
                        }
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={datePickerState}
                        direction="horizontal"
                        locale={rdrLocales.pt}
                        maxDate={new Date()}
                      />
                      <button
                        className="btn btn-primary px-5 mx-3 mb-2"
                        onClick={() => loadAllData()}
                      >
                        Aplicar
                      </button>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card style={{ minHeight: 250 }}>
                <CardBody className="px-lg-5 pb-0 d-flex flex-column justify-content-center align-items-center">
                  <Row className="d-flex flex-row justify-content-between w-100">
                    <Col lg={6} sm={12}>
                      <b className="text-muted">Faturamento Líquido</b>
                      <h1>
                        {liquid?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </h1>
                      <b className="text-muted">Meta</b>
                      <h5>
                        {goal?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </h5>
                      <h6>
                        Bônus garantido:{" "}
                        <span className="text-success">
                          +{" "}
                          {bonus?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </h6>
                    </Col>
                    <Col
                      lg={6}
                      sm={12}
                      className="d-flex flex-column justify-content-center align-items-end"
                    >
                      <h4 className="text-success">
                        <i className="mdi mdi-cash-plus text-success me-2" />
                        {total_income?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </h4>
                      <h4 className="text-danger">
                        <i className="mdi mdi-cash-refund text-danger me-2" />
                        {total_refund?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </h4>
                    </Col>
                  </Row>

                  <div className="w-100">
                    <div className="progress mt-3" style={{ height: "20px" }}>
                      <div
                        className={classNames(
                          {
                            "bg-danger": goal_done_percentage < 20,
                            "bg-warning":
                              goal_done_percentage < 50 &&
                              goal_done_percentage > 20,
                            "bg-primary":
                              goal_done_percentage > 50 &&
                              goal_done_percentage < 80,
                            "bg-success": goal_done_percentage > 80,
                          },
                          "progress-bar"
                        )}
                        role="progressbar"
                        style={{
                          width: `${goal_done_percentage}%`,
                        }}
                      ></div>
                    </div>
                    <div className="w-100 text-center mt-2 mb-2">
                      <span className="text-muted">{`${goal_done_percentage}% da meta alcançada, continue firme!`}</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card style={{ height: 250 }}>
                <BlurLoader text={"Carregando Appmax"} active={loadingAppmax} />
                <CardBody className="d-flex flex-column justify-content-center">
                  <div className="w-100 mb-4">
                    <img
                      src="https://raichu-uploads.s3.amazonaws.com/logo_null_plZLQd.png"
                      alt="appmax"
                      style={{ maxHeight: 25 }}
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>
                      <b className="text-muted">Vendas</b>
                      <h1>{appmaxData.transaction_count || 0}</h1>

                      <b className="text-muted">Estornos</b>
                      <h1>
                        {appmaxData.data?.filter(
                          (o) => o.status === "estornado"
                        ).length || 0}
                      </h1>
                    </div>
                    <div>
                      <b className="text-muted">Faturamento Total</b>
                      <h5 className="text-success">
                        +{" "}
                        {appmax_income?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </h5>
                      <b className="text-muted">Estorno</b>
                      <h5 className="text-danger">
                        -{" "}
                        {appmax_refund?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </h5>
                      <b className="text-muted">Líquido</b>
                      <h5>
                        {(appmax_income - appmax_refund)?.toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: "BRL",
                            maximumFractionDigits: 2,
                          }
                        )}
                      </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card style={{ height: 250 }}>
                <BlurLoader text={"Carregando Stripe"} active={loadingStripe} />

                <CardBody className="d-flex flex-column justify-content-center">
                  <div className="w-100 mb-4">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png"
                      alt="appmax"
                      style={{
                        maxHeight: 25,
                        objectFit: "cover",
                        transform: "scale(1.5)",
                      }}
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>
                      <b className="text-muted">Vendas</b>
                      <h1>{stripeData.sales?.length || 0}</h1>

                      <b className="text-muted">Estornos</b>
                      <h1>{stripeData.refunds?.length || 0}</h1>
                    </div>
                    <div>
                      <b className="text-muted">Faturamento Total</b>
                      <h5 className="text-success">
                        +{" "}
                        {stripe_income?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </h5>
                      <b className="text-muted">Estorno</b>
                      <h5 className="text-danger">
                        -{" "}
                        {stripe_refund?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </h5>
                      <b className="text-muted">Líquido</b>
                      <h5>
                        {(stripe_income - stripe_refund)?.toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: "BRL",
                            maximumFractionDigits: 2,
                          }
                        )}
                      </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {cards.map((card, key) => (
              <Col md={6} xl={3} key={key}>
                <Card>
                  <CardBody>
                    <div className="float-end">
                      <div className="avatar-sm mx-auto mb-4">
                        <span className="avatar-title rounded-circle bg-light font-size-24">
                          <i className={card.icon}></i>
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className="text-muted text-uppercase fw-semibold">
                        {card.title}
                      </p>
                      <h4 className="mb-1 mt-1 d-flex flex-row align-items-center">
                        {!loadingReport ? (
                          <span className="counter-value">{card.count}</span>
                        ) : (
                          <div style={{ width: "70%" }}>
                            <p className="card-text placeholder-glow">
                              <span
                                className="placeholder w-100"
                                style={{ borderRadius: 5 }}
                              ></span>
                            </p>
                          </div>
                        )}

                        {card.count_today !== 0 ? (
                          <span className="badge badge-soft-success ms-2">
                            + <b>{card.count_today}</b> hoje
                          </span>
                        ) : null}
                        {card.percentage ? (
                          <span className="badge badge-soft-dark ms-2">
                            <b>{card.percentage}%</b>
                          </span>
                        ) : null}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <AdminAreaChart
              title="Cadastros"
              data={usersGraph}
              chartID={"users-chart"}
              group={"admin"}
            />
            <Col lg={6}>
              <AdminAreaChart
                title="Empresas"
                data={brandsGraph}
                chartID={"brands-chart"}
                group={"admin"}
              />
            </Col>
            <Col lg={6}>
              <AdminAreaChart
                title="Dashboards"
                data={dashboardsGraph}
                chartID={"brands-chart"}
                group={"admin"}
              />
            </Col>
            <PieChart
              title="Índice de Conexão de Contas de Anúncio (Facebook)"
              labels={[
                "Conectou Conta de Anúncios",
                "Não conectou nenhuma Conta de Anúncios",
              ]}
              data={[
                report.users_with_ad_accounts || 0,
                report.users_without_ad_accounts || 0,
              ]}
            />
            <PieChart
              title="Índice de criação de Empresas"
              labels={["Criou  empresa", "Não criou nenhuma empresa"]}
              data={[
                report.users_with_brands || 0,
                report.users_without_brands || 0,
              ]}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeLayout: (layout) => dispatch(changeLayout(layout)),
});

export default connect(null, mapDispatchToProps)(Admin);
