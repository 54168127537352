var sales = [
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34288154,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-02 00:22:10",
      updated_date: "2023-01-02 00:23:18",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACwUlEQVR4Xu2WQXIkIQwE4SPw/1/4KfAR2EwRO9Pjg2MPbu2lCbs9FumIslSSuuyfz1f5Hvl2HuCcBzjnAc75N2CUUkfrfJp7chafp8FEYPI16iZumCd/Ug1mAqOirHLJNQCCVzWYDIzSeKiyrm6q/gOwF7UZCFVrH/0EE4GtT0bpPPdcXPsz4mkAsqpm/TgGE4E4cxFZJAvP1NW89GQBow9cYqVasVI6F+byX9wPUJTVCPX1V2Z1kqyeCJCi0hhcRLTqDG4YyQNIVTM91Xt5WHOWCTCxXs1bmWbzIO/mTQAYXvsMjxUNLE0HX6qZAVgaizP81i2KfScqA9CzaIqW5ReKhnMvxUoAtimKFbLtF0dapO1VrAQgRLpRVw3bdLD9Uaz7ASpltTDLdnRN0zZHeycqAcC05mm61lQYhVrXat4P7FOmgBAn2kzZu5r3A8yM7RihZ0Ih4uwdmigP0LWGLZiGYbXo45EJ6FRW2PCH+dIzcO9i5QB6RllO0aHO6RxLBLZRJK0e12EZ1b5EJgBTlzBEFVqUOtyzl2IlAE4x7YJMX7D6aZ7LQkkAdIdT1GwN/Yrg6ZLPBIqTtGpaLINRrFq0TyJgWuqOFMXwcsfqn0SArJAs7kiQT5CtiVKBYxkzVHRMYPVSrAwAi3Q9yjdta9dO7dszAZNC/3avoEsU7iOTtwOGuZvRMZTNvTo/mvd+gOtIDMKa02zIAdREYPqmNc8eQagXzNRLsRKA5RCvZqorFgM3DNSumbwf8KOyYobYMsOtkgoMZ/eIueEwdZqSsJIKeFznkSTvbGFtnAicdq3xzh9inWF8ygSmGl3o9ooPTbwvxcoAbBhm+Xa5kiV0doXPZMDpUZwhS9jFQuqSAbqndZNj42iYmQwEgz8IhbxpF1+GeQIQhfG9AoaS8eYXv9rHacBP5wHOeYBzHuCcXwD+AG7yHkUgjqK3AAAAAElFTkSuQmCC",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/990a583d-cc33-40d8-bdb0-56696ca35b3d5204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304FAEA",
      pix_ref: "34288154cb8c7a71592b84ed46c8b28be4a",
      pix_expiration_date: "2023-01-02 00:52:12",
      pix_creation_date: "2023-01-02 00:22:12",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45770902,
      name: "Ana  Maria Amorim",
      email: "ana.amorim.37@gmail.com",
      telephone: "24993061234",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2050-C..1-C09-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34289167,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-02 01:24:59",
      updated_date: "2023-01-02 01:25:01",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45771987,
      name: "Matilde  Dias",
      email: "tide_dias@hotmail.com",
      telephone: "11998873521",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..1cp02-C09-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34316309,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-02 12:38:46",
      updated_date: "2023-01-02 12:38:48",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45801564,
      name: "Clarice Silva",
      email: "irisfarias.10@gmail.com",
      telephone: "21975875774",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34319166,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-02 13:10:42",
      updated_date: "2023-01-02 13:10:46",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45805519,
      name: "Ornelia gauer",
      email: "orneliagauer@gmail.com",
      telephone: "45991328562",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "1050-cp11-C14-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34321883,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-02 13:44:21",
      updated_date: "2023-01-02 13:44:23",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45808269,
      name: "Talita Santos",
      email: "talita.regis120@gmail.com",
      telephone: "11988223645",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "google",
      utm_medium: null,
      utm_campaign: "cp05-gp01",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34322275,
    },
    transaction: {
      status: "refunded",
      created_date: "2023-01-02 13:49:01",
      updated_date: "2023-01-02 13:49:03",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45808448,
      name: "Ana Carla  Martins",
      email: "alracmartins82@hotmail.com",
      telephone: "98970035428",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..1-C16-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34364679,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-02 23:15:47",
      updated_date: "2023-01-02 23:15:49",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45855669,
      name: "Rosania Matias",
      email: "grey0001h_martins@outlook.com",
      telephone: "62991916521",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34381492,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 08:55:49",
      updated_date: "2023-01-03 08:55:51",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45873635,
      name: "Cleusa Helena  Carvalho da Silva ",
      email: "cleoangelina_@outlook.com",
      telephone: "11962548859",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34383184,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 09:14:21",
      updated_date: "2023-01-03 09:14:23",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45876179,
      name: "Regina Cury Aude Serafini ",
      email: "regina.cury1@gmail.com",
      telephone: "16991490780",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34384489,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 09:28:02",
      updated_date: "2023-01-03 09:28:59",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACyUlEQVR4Xu2XQXIjIRAE4SPw/1/sU+AjsJmNVprQwbEHT5+GkOUxk4ood3UXo7J/Xn/K987XeoCzHuCsBzjr/4BRSh1979XqKnXO1fqebiYCk9f0fp+j7tUnH6luZgKjCqirtNL6aH1VN5OBUd1DZamoPHqzAWuzR+UdqR3rsoEdZrk791zc9nfspwH25/xe3139ff+XAdfSnb0oFj1TGaHXfhYwetyb3tMpOxfm8l/cDxgcbnfi48ikV8iRngnUE1w2yvBabrSWCdCti8IgkuLMvQp/W7NMYDs4KNvRvUg+SH0PTgaALRg2wiqHRp2jf8xKAGhZMtRKDS2zW8ZRmgd4zaa1MblUas1ygciOFie8rhXPl3ExKwFQXFjUT9uI7atZGQCiGoNSdQxt2sbOp1AZwGkVqrN0yLOk9LjMA1A5To7hkCQKYT9mJQAhkgRnZkBDnLPT3iITgG2f2rrhmBgjHC2cB3iUDx8yQ9zpGXaubt4OYM30QDtvZprejY9ZCYC7VslW4V60jGrfIhMA5rRYnJgWTRqnfVMBTrNulbRtOcXKvJ449wNxqJZoV2M9Zmh6yCcC6OPHI4QIt1GQbKK9RSYAr7oQGCgzvFanXD52JALoYXgsmNocXgp1Hd77gakwzVFpjTghUa9m3Q+YXrYrc9PNkGXF/DsRiJmxUm7Ts8Wa9Wuh7gfoWVMce3yooEDAFi0TwCditCqMr4FMMlLDwUSA5OzMrn2C0uYNHrkuZiUAdEokt6Zhl3kaX8pSAS2yQvYJVQPnA9fT/37AEikKeYbpdnhM90zANaI+/9JrTi4+ZiUAMa4qNdRtGDOMq0xg2itNn4ohUmKM9jVhEgCq9Boc9HZ1doXPZMD0KM6rGdY9WCLjkwEPd/xxcGyYmQzwmt7UJ+XZv55wiYDGeJiYZFjm+abo8haZAPy0HuCsBzjrAc76BeAveu4JpccrkhAAAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/919cb9be-35c8-40af-9b14-557dd9e6d8d15204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304B9EB",
      pix_ref: "34384489d6435cfb02a905342b866d870e4",
      pix_expiration_date: "2023-01-03 09:58:03",
      pix_creation_date: "2023-01-03 09:28:03",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45877248,
      name: "Eliana Ribeiro ",
      email: "elianaribeiro37@gmail.com",
      telephone: "12996083138",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2050-C..1-C09-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34384913,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 09:32:33",
      updated_date: "2023-01-03 09:32:36",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45877736,
      name: "Marisa Ribeiro de Lima",
      email: "rlmarisa@cohapar.pr.gov.br",
      telephone: "41988410128",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34385079,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 09:34:13",
      updated_date: "2023-01-03 09:34:16",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45877865,
      name: "Francisca  Medeiros dos Santos ",
      email: "franciscamedeirossantos0@gmail.com",
      telephone: "34996599830",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34390958,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 10:33:22",
      updated_date: "2023-01-03 10:36:24",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACuklEQVR4Xu2XQZLdIAwF4SJw/1vkKHARnG6RmvC9mMpirGzMAmPoX/VKTxL+5fp+/Cr3ndt4gT1eYI8X2OPfgFFKHX2ymqz7XK1f081EgKM5mYB4rC5W3cwERuVgqqu00vpofVU30wEmVCJvRaj+A7DQBcM5UvvosZkJXEbJnDFeq7uIzUTA/Jz3cc/q+/kPAw7sWfhUEdl4Ng8dWcDogwN9aiobZm6VywRKa+ZIyAuAXJm4lgk0U7XzjHKZEbHBjxIB3mgaheZF32CCXQSrZAIS+nPpF/rmRupX4SQARIfGZcYsj7fO0f+alQAM+7fDR7V56ZlK84AZ+0iMUrms4MvLJRPwLg2TcIcnBczr+HDzcYC+1XhzhqKfULnXh5vPA0TKOkGa91mVR3U7AvU8YIAMDcWjXXKlr9PN5wFqlxe0dRyyk6HQ7PkyKwMoBsarxGwJcdbO0UgTgG5oOG9aJtajn2UCuhU142N6tc2421IBykWBArBDndM+lgm4i6JlCZu8Xe643DOAbU0kbXE1dvpmApwsN6OPRQIr87hQEgBbBnbV0OrMPQ9wmvU4gDVmKsf7asEm8+Xo9s8DyFId5rhdBIjdlQqQrAar75KJ4kX4UbwJgP9D4273Ipvivh5mJQDqu8xUUKyyasUON58H0Fb9yI3tcM5aOgP1PGDxIokT6nd3VaWmAmaH7tA2uFj59owEUnUe4JK6cTJWHoyQnQn0naAmDnZFP6V8j0gmAMUsJV9dTdRFNzkT5nnA+NjBDdWOku2jpAKOqUzj5RlAvCcClitJE9/8NrL6p6VlAjNKJ8qGkDH55/A6zMoA7J5Tq2o0EXu6wk3kTMA+XqxXQkTlkj6ssgH2mrVj4ZgwMxmQ6UOBtA3kzejvn24+DIQxdK/h31CU0cztpy7TgO/GC+zxAnu8wB4/APwGMTsVIfMXhVIAAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/0a3df1b0-068c-4b71-b237-afcf9e64729b5204000053039865802BR5906APPMAX6009SAO PAULO62070503***63041814",
      pix_ref: "34390958f469bcf77a5319175e29ba935d9",
      pix_expiration_date: "2023-01-03 11:03:23",
      pix_creation_date: "2023-01-03 10:33:23",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2787905,
          name: "1 Pote Biotina Hair - PV",
          description: ".",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335760,
              sku: "BIO1",
              name: "1 Pote Biotina Hair",
              description: "1 Pote ",
              price: "197.00",
              quantity: 1,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45884052,
      name: "Sonia Camacho",
      email: "soniacamacho2020@outlook.com",
      telephone: "51984987401",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34395269,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 11:15:20",
      updated_date: "2023-01-03 11:15:23",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45889160,
      name: "THAIS  PIMENTEL ",
      email: "thaisufrrj@yahoo.com.br",
      telephone: "21993151690",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34410202,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 13:49:25",
      updated_date: "2023-01-03 13:49:27",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45905519,
      name: "Nilce  Oliveira ",
      email: "nicebiologiarj@hotmail.com",
      telephone: "21972680986",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34418645,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 15:26:18",
      updated_date: "2023-01-03 15:26:20",
      value: 63000,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 4698540,
          name: "10 Potes - Biotina Hair - PV",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335760,
              sku: "BIO1",
              name: "1 Pote Biotina Hair",
              description: "1 Pote ",
              price: "63.00",
              quantity: 10,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45914885,
      name: "REGIANE  LIMA",
      email: "limaregiane316@gmail.com",
      telephone: "41997820626",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34430737,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 18:08:12",
      updated_date: "2023-01-03 18:15:02",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACxUlEQVR4Xu2XS5IcIQxE4SLi/rfwUaSLwOQT86F7MeGFkTeFZ7qqxXOErEwJ3Nbv6097j7ytB9jrAfZ6gL3+DvDWenhf0/psfcW0sYJgIRD6CX344KFP/ZVOsBJw5di60mrNmg23Mcm6GoAI5afHHJTqPwBzSC3vO9fhI4OVgH5iKq7HAh08M14GKC1V6m0RLAT2iqnWybaRXsYmqwpwCTVVJZdllRkPtbEd/4r7gJQyIkPjYyYgryg4CoGcXhpcvtslknOzSsBzZBgCuZw789cwcR0QbKg62bzMr428NO9twJWhOtapj7bVuZMZ8iNWASBrYA8FFO0MrxxrR6HuA1KGtumqzRJFB1O5UkB7n3/ECW+caH6qeR/AqdSF04wBMhjqL2reB7ZBlJsa1pSbekhdbEeh7gMyKiJpkMo41A2h8pCrA9ggU6Um8/LphntOsW4DgU09B4hSy+ToHftOsgCYOJQZIqkwDCecIl4JMLZk1zzK9JKe6WnlOgDHYFIAsU6eCvyIVQCoKNKmc76yzfmaaX8nWQJII8yCWIgk5EWsAiBPtY5rJVTL4aE0jwOlAEAoXSjSIygl60g1xkodoMFJCM1on54HbbZPIdDxR44vJAPQO7O1EOC+yTClZXnt8EfzFgB4dBCXaNw8E+PKVQq0z7Lw38Cga4PEjyTvA7SuEsswrbyNcxTqPoA7gPLyjX3Ux3E2bwUwvo41RdseJZwwvRBQdiDUSlY1NmSgF7GuA0oyEwofWafFbY9WqgRaflWWnPC0jHOqlAKedjXmhu8qMT5aKcByZph9TS918KKH6wAlxAxfuJYDrjPDIqUrA2KRmllSNAzv6xCrAqBhduPQQ+Q5SDyKgZl3K+nDDBuYV2/FAD0zVvqkc7/QWy2w2CE1yUR6gXfmm5p3gRQmD3cYfbf8ymsZ8Nt6gL0eYK8H2OsfAB9o399mfRnAhAAAAABJRU5ErkJggg==",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/7204f96a-bb1e-4a5a-9f31-633041b0ce225204000053039865802BR5906APPMAX6009SAO PAULO62070503***63048639",
      pix_ref: "34430737a19601640e30b848229f30170ba",
      pix_expiration_date: "2023-01-03 18:38:14",
      pix_creation_date: "2023-01-03 18:08:14",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45927519,
      name: "Elza mendes de souza filha mendes",
      email: "elzafilha@gmal.com.br",
      telephone: "3899770426",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34436992,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 19:28:16",
      updated_date: "2023-01-03 19:28:19",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45935240,
      name: "Suelen Pereira",
      email: "su.pereirareis28@gmail.com",
      telephone: "31992351456",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34438463,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 19:47:13",
      updated_date: "2023-01-03 19:47:15",
      value: 39400,
      discount_value: 50,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45937278,
      name: "Rosangela Rodrigues",
      email: "rosangelaiot@yahoo.com.br",
      telephone: "67992303747",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34452538,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-03 22:41:06",
      updated_date: "2023-01-03 22:41:09",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Elo",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45952436,
      name: "Ivete Liliane dos Santos Batista  Batista",
      email: "ivetebatistakw@gmail.com",
      telephone: "19991407089",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..3-C11-abo-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34361797,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-02 22:31:56",
      updated_date: "2023-01-04 07:31:28",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "billet",
      payment_card_brand: null,
      payment_line: "00190000090339518700203665795179292210000029700",
      payment_bar_code: "00190000090339518700203665795179292210000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/63b3858cc2f76135e94b6ef9",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45819426,
      name: "Maria de Jesus Ribeiro  Ribeiro ",
      email: "mariajesusr1819@gmail.com98",
      telephone: "98981947056",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34472398,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 09:12:19",
      updated_date: "2023-01-04 09:12:20",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Elo",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45975076,
      name: "Rosemeire dos Santos ",
      email: "sanmertools@gmail.com",
      telephone: "11965109867",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34480533,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 10:32:05",
      updated_date: "2023-01-04 10:37:56",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACs0lEQVR4Xu2WW24cIRREYSOw/11kKbARSJ1iPA8nsvKRrq9GnnEbjqWrunWLLvvn9at83/m2buCsGzjrBs76N2CUUuecepqrVb76nmwGAR3pnH2w0fXRf3g/B4w6h05VV2mldT2vymYa6P4SVFd3vXlgNfVmVJ2r1D66N5MADPLQq7l0zG/vx4CHaT/XX1x9JeA1+lKfPDb63ThkpQDMsuzTRmUD51Z3Lwg0rb5XozwD8oqGuQeBR7e2JgarTis2+J8gsHXG8RFnFf0hsUoSIDholovdatpBpFYSYIPwwLQTyciQV7MSAIhK7LKOPrhlnEpzAJ1Sgsk4HlkqRbMoQIj3VegU84KDHevPZiUAz8qmvIdg6td7swIAOxuTTOZFbdLcEmhJYBGcsggJilZu1Hrv5vXA0HGxaXREgaqwvg9vAJBGrTnG65dZmZ32LDIASKjiYWl+4IYTKCMnAUnCSw6NOpf7dKomAV6spJE/jAx1+npLAo2Sml+3dfZ66wsCbpZaxGXKkzTrf3bzWkAV0RyZVNdrcXh0ci0JKDNVIz3iPkUzuoZqQUCTokKlDd6RUfjT45MDpJHCS6+5zzTvy6oFAVW2KZHsnGd4JdTn8F4OuLqOQpUAqUhX35t1PTCZHXqzHlbpxl5CBQCkIcjpEorJOL7gsoAMIqEaNRLtSrX5PrwBgGlRiZhWM9z4kXqyTxCQY5qEwSeSqXFApryaFQCWQ0ypwQThVd72kC4JFJc08EnzyAxulShgfcp5wTkqER8lCrC4zi2S0+vb5R4APK5U2qiOSSbB1LogoPJUGX0qBKnTBM2igGJ8nMFRvZ7kTuEzDGh6ZBYCHdipvmoakFJ92yeMMY7JAhvPqrSmK4XypvP9s5sXAzRGNerTsYzMW3nN4DEG/LRu4KwbOOsGzvoPwG/fRf5WX1iDDwAAAABJRU5ErkJggg==",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/19d5edbc-83d5-4346-b7ab-da88f7e881cf5204000053039865802BR5906APPMAX6009SAO PAULO62070503***630483B7",
      pix_ref: "34480533f3716113f7fa1ddd986882ddcfe",
      pix_expiration_date: "2023-01-04 11:02:07",
      pix_creation_date: "2023-01-04 10:32:07",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45944140,
      name: "Marluce  Barroso prestes ",
      email: "marlucedossantosbarroso@gmail.com",
      telephone: "91986242838",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "rcc",
      utm_medium: null,
      utm_campaign: "whats-su",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34501546,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 13:56:13",
      updated_date: "2023-01-04 13:56:15",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46007629,
      name: "Monica Aparecida  Poli Angeli ",
      email: "almopoli@gmail.com",
      telephone: "19991964345",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2050-C..1-C09-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34518449,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 17:19:49",
      updated_date: "2023-01-04 17:19:51",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46026956,
      name: "Geane Pereira Muniz",
      email: "geane.fisio@hotmail.com",
      telephone: "21999149555",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34518999,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 17:27:13",
      updated_date: "2023-01-04 17:27:15",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Amex",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46027215,
      name: "Véra Lúcia  Silva Moreira ",
      email: "veralurio@yshoo.com.br",
      telephone: "21981046474",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34523829,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 18:27:15",
      updated_date: "2023-01-04 18:27:18",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46032149,
      name: "ANAILDO  ARNALDO DA SILVA ",
      email: "anaildo74@gmail.com",
      telephone: "21982760321",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34525276,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 18:46:47",
      updated_date: "2023-01-04 18:46:49",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46032977,
      name: "Maria José Pinto Vieira Pinto Vieira",
      email: "alvespintomaria74@outllook.com",
      telephone: "11943383374",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..1-C16-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34528178,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 19:24:21",
      updated_date: "2023-01-04 19:24:23",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46036854,
      name: "Cristiane  Soares de Almeida ",
      email: "isabellyedm@gmail.com",
      telephone: "41996285833",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "rcc",
      utm_medium: null,
      utm_campaign: "whats-su",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34538475,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 21:29:10",
      updated_date: "2023-01-04 21:29:13",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Elo",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46047773,
      name: "Benedita Augusta de Oliveira Dias  Dias",
      email: "dita.balbinos@gmail.com",
      telephone: "14998439213",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "Jack1-cp08-C16-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34541140,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 22:03:10",
      updated_date: "2023-01-04 22:03:12",
      value: 63000,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 4698540,
          name: "10 Potes - Biotina Hair - PV",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335760,
              sku: "BIO1",
              name: "1 Pote Biotina Hair",
              description: "1 Pote ",
              price: "63.00",
              quantity: 10,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46051273,
      name: "Sonia  Osti",
      email: "ostisonia553@gmail.com",
      telephone: "17991540577",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34541662,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 22:10:04",
      updated_date: "2023-01-04 22:10:06",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46051662,
      name: "Rosa Maria  Moura Neves ",
      email: "rosamariamouraneves@yahoo.com.br",
      telephone: "13991775173",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..1-C16-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34542938,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 22:29:08",
      updated_date: "2023-01-04 22:30:57",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACt0lEQVR4Xu2XwXHdMAxEyUaI/rtIKUQjZPaB8VjSwZODtafP/NgU+DyzBhag3PbP6097Rh7rA5z1Ac76AGf9HzBb67PPMUZfo+9cI3YSNAKpz+xb8UxOUz/SCTqB2ZOQdLXRRswRqxM0A1KnWACuKKV+YE+VSRVT2VqLGSdoBPRJIYqm7KJjvlfcBuDPfK6nq5/nvwywlkzb5BPaplX/nOUCZky5ZJVlpWzi3A5nBNZQs/C4JK8AeUUuDiNQhsUxp12yOM00J0ChlKKBvpIa+q+AFQj0Tc4b+vIgypYP0PAq13CoY6VsMUO+i2UAMCwuZYyPzvCiZih1AnO0QBqtQu2ycuYEkCR5VR2wxo02b8V6H6jaSN0g/G+o36ppAHbNbuVKWqWNsrGzApKzanhUtxx4XYv1PnA0xmKcao6U1sP6ADUszaMy6V4BrdxpphiB0qhHbjMMI0z7jod8AM0a+JUtN2qi+Oao1wFlSgI7L5y0DDqTjnYCpa3jW44n34ncfou3AebWBMC0KtJkdy2WA9AUbfVn2KB98Gsy2ZwAfuEdE2V81TapoBHQrNinV86LhsqEX76L5QACv3CxE6ZtSBdhI3ASE1zr+EaIni/N6wAavaov9Z5RVevqo1uxXgdQUyrVM1EbsFsmXwfoVRllcyTf4J8KGQHappEnOoYrTvdqXpvXADAzyilR3uWjxNFORkB1mTU6yNXgQLKvxTIAetKdoneKqDztM1AviTIAbNG56w8R/ZvcKlag8tNqbjBMN83D9eYEWLnJz9f0elzuBoB2ZWAM7lNe/5lhWaWzAeWVMQaXSTUMd8u+FMsBTEmiZPKJSoTOQHiaAeZ4o1+ZYYF5tTMD6lVlp3zSeb/QzgvAhPyhLi55+Dcuw9wAVGEYoDBSpmHOI31sA35aH+CsD3DWBzjrF4C/7oEIPUyl2h8AAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/3279e3bc-970f-4fb9-8b99-927bf15d43525204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304408F",
      pix_ref: "34542938c5989ac5eeab36de0967b169e7f",
      pix_expiration_date: "2023-01-04 22:59:08",
      pix_creation_date: "2023-01-04 22:29:08",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2787905,
          name: "1 Pote Biotina Hair - PV",
          description: ".",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335760,
              sku: "BIO1",
              name: "1 Pote Biotina Hair",
              description: "1 Pote ",
              price: "197.00",
              quantity: 1,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46053714,
      name: "MARCOS ALEXANDRE VALANDRO",
      email: "diretoria@flashservicos.com.br",
      telephone: "47991015178",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "google",
      utm_medium: null,
      utm_campaign: "cp05-gp01",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34577204,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-05 11:30:51",
      updated_date: "2023-01-05 11:30:53",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46090948,
      name: "Ana Cristina  Duarte Dias ",
      email: "anaddias@yahoo.com.br",
      telephone: "71988516087",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..1-C16-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34594361,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-05 14:37:32",
      updated_date: "2023-01-05 14:38:29",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACvElEQVR4Xu2WQZLcIBAE4SPw/1/4KfAR5MzGu2L3sOGD1b4Ie2cklBNR0VXdqFw/r1/l+8639QJ7vcBeL7DX3wGjlDrqaq3VVUqfq/VrupkIzMt7iD6nTyc/qW5mAqPyoCOrlFZaH62v6mY24MfoITZK9R8AP65ReY7WPvreTARkKBK7gFSs+x37aQAPrdTX5WYisJc9syoim/n1oSsLGJ3WtUqjqWyY3CqXCFCUzh8DBHkBGCBcSwSWvcrt2O0yl9xoLRWo/h8Wx2ZZpHdRrJIJoAy3wiYvEByIcBqwjEsMD+pDoaY6Rz/Meh5wiH7Ypcyp7FCaByDTjpl/WtYOdqikAoSW06xRKeu1ZKNst1kZAAXqfDHB2B3OkuuLm88DMcQsDFOsa1Ozi/1NIuAeWxFaFIZR6zArAbj2MbZCoZNsOkwOsxIAC4M3DBBnSIizd9qnyATA3RqxaV6MmCfFmiUCNmuYpThP1Kniw6znASzSHr60Lcrlzm1WAuDwUmacaYwOI6PaT5EZACEpxIN+ZWGSPXyalQNgkFKJb4nhgczjQEkAwptlXDlP/bRwMVPzgOHIil5xoEOvbl5usxKACGq8ZUxfsAQYaMJ5AEHRJO0irdG8/Ohs3ueBaUzdNiTT4ITm26wEQKeWRvncqFixdpiVACDJSoVLxsfg2L6ZgPVB5bCHvXCqRtEyAbukkBcPFYLD8/hVTQTQR1wcHWjz8nKyn24mAD0CysMuPZ2ntO9dqAyAS8RRnmUL8294qqQClAiXTG0MU182dC0VcNG0u0g+A+DiNisBQJChveI83dlZRicTMCwUqgVlw4Rx5wBJAGiYsRsHvV2dXeEzGaB7CAv+UCI6t5jgmgz4stWvyEn1/YKrXECmKw1KedMD5RzmzwNhjO8Vwzpx3+LWPk4DflovsNcL7PUCe/0D4DfIu8e6GqFIdgAAAABJRU5ErkJggg==",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/0ca8a081-22ed-432e-84b4-f10a2aacb6c95204000053039865802BR5906APPMAX6009SAO PAULO62070503***63045057",
      pix_ref: "34594361f981eb148af797f20b9ee0dc1d8",
      pix_expiration_date: "2023-01-05 15:07:33",
      pix_creation_date: "2023-01-05 14:37:33",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 34198495,
      name: "Deise de Mello Ferraz Pagliarin",
      email: "deisepagliarin@gmail.com",
      telephone: "11984267477",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34608010,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-05 17:26:18",
      updated_date: "2023-01-05 17:26:23",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46124574,
      name: "Maria Aparecida Modos",
      email: "mariaaparecidamodos6543@gmail.com",
      telephone: "18997669416",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "rcc",
      utm_medium: null,
      utm_campaign: "whats-su",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34626137,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-05 21:23:56",
      updated_date: "2023-01-05 21:25:03",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACwklEQVR4Xu2WS44jIRAF4SJw/1vMUeAiMBGJZJe9aPViKmdTqD8uCEtPmfleUfbP60/53vlaD3DWA5z1AGf9Dhil1Mn/1eoedc/V+p5uJgIcztH6Kn2OyunkK37DL6UBowJUZJXSSuvC1c1kYC50DVSWurql+g/ABqBTnKO1j342EwF+5ordueOD/2M/DRgO7fdyMxGI5Q5zom3oV/PQlQWMjmW3wprKhpNb5RIB+qPI1tzTuY1ZsX2ZwF6VaVkNu7iYXzZ4SgRUOXWuUgGLv9QsE5hE1jxJDsDDQS7mvR+gO13PWp+olDohXyITALqEMOaFDoXSSDCVJgI+MTLWCmU6GOGXZiUAPjVKY3fompEWsf5qVgKgbawLItFLnoDtj2bdD9gb56W7bZt0MYGWCSyOw77mBgqjUeujm7cD5JWt4laBQm44oJTs0qwEwA8jXiVsn2HVO9ekvR3Yzql7vMgO1vmCNcsD6JKGaS+KmYF7NysBAJn4xO6YokOdjs/b3fcDQ3VsY16PY2RU2zMBEgvn4lnHhSaBfDQrAaA9PcxClPNW78c8692sDMD89G1Cf+wUFy26ZqZmAvHHnq3uoCw1a588YHvZLc0IXxFe1Iv0SAXm9nk6OWrTvBTq6u77AUOsuW2G19O1aGAmgEWQSWGGmK51fq6VvB/AJHHNOZNbHBztmwkwrcvkLDqG6bVg82reBMDxsD9GB12rQw5AP2UBcb3bRoeh3jzg/fLVzZsB4/NkR5/WaXvbM10zgeK4IsuTsMzwrZIKWCIizGE1TKlSTE8q4BrGhUXyrH+93BOAsKtD21THwJhhfMoEkKdjenjFP5TNmqUCVAlNCkVvV2dX+EwGtlOqX80wrzpMcE0HiK8+LBTYdGJyAX5Ued6vyDNC+keY3w5EY2JYrRPPjXtOeDkP+Gk9wFkPcNYDnPUPgL+eRBilz8mbegAAAABJRU5ErkJggg==",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/ea98fe84-a4d2-4c01-8863-b62b66c7ea445204000053039865802BR5906APPMAX6009SAO PAULO62070503***630446E0",
      pix_ref: "34626137f8d38e72f60b34f26e3b80c7661",
      pix_expiration_date: "2023-01-05 21:53:57",
      pix_creation_date: "2023-01-05 21:23:57",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46144015,
      name: "Lazarina  Sobreira",
      email: "lazarinasobreira@hotmail.com",
      telephone: "92988353370",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..3>cp01-C11-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34629759,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-05 22:12:34",
      updated_date: "2023-01-05 22:12:36",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46148364,
      name: "Maria  Barbosa ",
      email: "narceliaob@hotmail.com",
      telephone: "68999176511",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34631574,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-05 22:39:51",
      updated_date: "2023-01-05 22:41:17",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACwUlEQVR4Xu2WQXIrIQwFh4ug+9/iHwUuAukWTjLxIvUXsVYmLjvD9OKV9PTg2r+vf9fzztN6A2e9gbPewFn/B4zraqvHXLF623Pkg5uFwOQzeme/J+rr5mYl4ONqiLtiXM3Hld/FgAT1Wf3qV6zrU2cpsGIv2nVd2aMsXS3AB5Eg+IWuxR4tNwsB/Tmf17Orn9//MeCiP44O4nhFx+KxXwVg1NFG7CMt9I6DlFAVwH6sdOqeGHeqOLJpdcDWn9OBnRoXGOsQKF+GKQAYl06tjLFI+5BkJElUAg7McHNugxzr2DpkFwLsM7XqwjgEanZpnp8qAJswqMti8ec77BJqrgPWqdERmWMUdrAUoCuOjiKB8nwP9yqBJBgbx0W9WaefzXo9QFWa8xKZooFnkNxv3Xw9MFT2EBk2LfKALQVwjBK9YGTTiC8bdWtWBcCbR4iaIUIqjUJgKjFsmRfOoUbHZ3+JLACMj2GN8O7+9Iw7hYCyPMxpWROzYvarErBFMy+decmxTKTIbXgrANVpkLz0PobnR7NeD6CS6M4vrNMU6dN3swqArA3xYZuMUByEaME6QEF0S4UdpZTKDFn3br4cGBaoH7ds2bz4+VAIUKHU5TWr5fDC3Ye3AkAQ282mUR+71tM4dQCTs7WNXWvLiw6aDfRCwJmlNI33jCyW5ZqRaVYJKJRSoasbGzOP+/vwvh4wurAtbqFF2yzx2YIVAqFXZwaIM6PArXNqAeL80iKOThAm3Sz5LlQBkHbhaEUe+blPz26GKQCG6UVtEGrb/K8b51EIuPBHSt2qfD7cC4DhacqvtTFMMesywr4L9Xpg+vGqBWjJFNiM+EpgGOM5OCLjePgW5lWAU2N8mKBbIxOnxYBTK6Y0BkkPq7EO2LYIkeToOVIwjXUrBLIztMh5naboOjffVgj8tt7AWW/grDdw1h8AHyzMTSW1+ZsFAAAAAElFTkSuQmCC",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/c422ed1e-38df-4d6b-85d8-cc1dfb9e5bce5204000053039865802BR5906APPMAX6009SAO PAULO62070503***63047010",
      pix_ref: "34631574673e3e1b68619f6e6f505b7fd5d",
      pix_expiration_date: "2023-01-05 23:09:52",
      pix_creation_date: "2023-01-05 22:39:52",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46150604,
      name: "Nelma Teixeira  Teixeira",
      email: "teixeira.nelma@yahoo.com.br",
      telephone: "21980565691",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34634585,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-05 23:34:17",
      updated_date: "2023-01-05 23:36:49",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACz0lEQVR4Xu2XQY4jIQxF4SJw/1vMUeAi1LxnWh2SRWsWXZ5NoUQh8Eqy/P0NVa6fx5/yufIxHmCPB9jjAfb4N2CUUkddrbXKnz5X69d0MRGYfEad1+qTXwgeqS5mAuzO1UcrpbTSmPRV45FcYMRaJz91Rar+A2BuLrKEbKX00fdiIiDDDqvzMmPd31hPA6zP+Tk+q/pz/5cBx2p11YsPQqGXFoqRBYyOdSnXatkimZVb5RIBbEvLMFDWLJ1GrWilRIBBlH2VvnVaZIqA46EsAI+wUBQI/16Al3krmQAZIbCmeYOdG3mZNwFAn4Z1h/lhG+cue8ip5u2A7u1LcVCo2rzsYEaaB8QxFskKy+pgn0kF3JJRHUu2qNk4xEoAokooG8yrfY3XqA817wfoXXqX0EpjOtVtspgJoA3V2iNWcxVCrTexbgf2mU77pqnbuzjRaOaHWAmAtxpDhCS0CE7vtO8gE4BITRejewaGjYsXjjxAiaaCRXAebZP5eImVASgPghGgXTQy5sop1u2AQbIcE2umy82XeROAcIrtvDvxaHF2iJUAqJQ9C53wLCFGmOeJcz8QtYJf1UilPOcB6rdYGUAEyUb11gdFvDPskwd4rfgqlOGRMj1b+pUMIJXfcE6Yl983d98O4JG6r1ieK758jP0/EdC3IZFXXbFuKzvEygCKV7wVKvnvq3BeiUoApqfpsJO23ckw0Xwz7/0AiYrE7CZGwHLT8skD7GC7dRhfc8OVl1gJgJWyvdLtZ3xJ3DouexlA6V7tlnXSwjLDUyUVUKyvvmEzJUszzrlMwDEiPztdvI1MJoea9wMEVKNoPU99E7CHKV0iMC9DUyfSpGGcX4dYGYCG6WEc4vV+wXt5UbtcAPdQLOijje3qVHBNBqhZshN1Ur1fMMsFghk7QMOzfvvZ7e8HFCYONhsJkdHMDdppGvDTeIA9HmCPB9jjF4C/uV0T9TAM0VcAAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/a2b7ee22-f475-4632-965b-9b0f508d6d985204000053039865802BR5906APPMAX6009SAO PAULO62070503***63049D28",
      pix_ref: "3463458563413df818528557de15bbec08a",
      pix_expiration_date: "2023-01-06 00:04:19",
      pix_creation_date: "2023-01-05 23:34:19",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46153824,
      name: "Cláudia Batista dos Santos  Batista ",
      email: "batistaclaudia783@gmail.com",
      telephone: "38991289058",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34639803,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 03:30:14",
      updated_date: "2023-01-06 03:32:51",
      value: 39700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACv0lEQVR4Xu2YQXIjIRAE4SPw/1/sU+Aj4MzGYY10cOxhp/cyWLYlSEVUdFcXssv+ff0pnzsf6wHOeoCzHuCsvwNGKXXu0Vqre9U9V+t7upkITB5z7lW6v0bn23e4nweMykOtpbTS+mh9uZMOUKWJPuStbqn+A7Ds1Kico7WPHpuZwI5muTuxC8f+jv00YGjaz+VmIuCiUK30Mzb0q3noygJGH75GWFPZ0LlVLhGgNTvyw70pgFcmXUsEOMS1yGqnZ4tKVd6QCXDW+YoYxbmEGd/ULBNwUAa6tqNrfh2kWr8s4KzIL/s0I9cheyIwDQ9UNner4RWx9ipUBsDEmuTRLkp0nHtpVgLAgReKnULtMtIi1n+alQA4pmhEoA2DOaW7NOt+YCmQs862z3jdDbRMYNqcRmlApm6xUeu9m7cDQ4tqWs6HP8Gvw5sBhFVtE2mm1NANmQisuMvcOpca2MmzRIAgtVjKdI7DM3CvZmUA7Dgy01THO+qcdjATaKrbYRjOwjKqfYm8H8CxumTFtNgkkLdmJQDq0S3YhD89ms+ReblQMgDS3PTSIyjzg9ae3jGJAP3xY8UQAa1q1sQ/IhMAzMrhifAILz91dVMlDzgf9hxbRyaGdztJmQAGcXCtEH36xuqlWQnASa8Ro6O8b8GXQt0POKhO7XepfLYc30yAfR0y/PcESmsU7jq8CcA2N+mOpgVikKvvQmseEMmJSeLB5VrdWtdm3Q8wNkYIErtThOBm5V6FygC8SdjCJw4OX8badbrvB6yPZkHeOFUyPkoq4ArP4NWTXh+XewKAIDM80nx6u5lhM1qXBkwe3KZNKgYG51q7VIAYn2dw0NvV2RU+k4HlnvNqhulhHFyTAWem7/AJ2Az/pALbk2GO2TQato33927eDERjmJphkKCMMOdl8Wka8Nt6gLMe4KwHOOsfAF9IPBKNQL+xHwAAAABJRU5ErkJggg==",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/f827b372-9c4e-48dc-8020-7b38b2cea86d5204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304869C",
      pix_ref: "346398034916301ade89a7350c518d2a622",
      pix_expiration_date: "2023-01-06 04:00:16",
      pix_creation_date: "2023-01-06 03:30:16",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2787830,
          name: "5 Potes Biotina Hair - PV",
          description: "Biotina Hair",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335694,
              sku: "BIO1",
              name: "biotina hair ",
              description: "Kit com 5 Potes",
              price: "79.40",
              quantity: 5,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46160114,
      name: "Christiane Castilho",
      email: "veigadracena@hotmail.com",
      telephone: "18997949380",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34332053,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-02 15:55:12",
      updated_date: "2023-01-06 07:25:23",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "billet",
      payment_card_brand: null,
      payment_line: "00190000090339518700203661914170792210000029700",
      payment_bar_code: "00190000090339518700203661914170792210000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/63b328905705ef0cf10a37cc",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45818676,
      name: "Cleyde  Lacerda dos Santos Maranhão ",
      email: "irma.cleyde@gmail.com",
      telephone: "84981858677",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..1-C16-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34530918,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-04 20:00:06",
      updated_date: "2023-01-06 07:29:33",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "billet",
      payment_card_brand: null,
      payment_line: "00190000090339518700203689503179292230000029700",
      payment_bar_code: "00190000090339518700203689503179292230000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/63b604f6c652fa319a310e8d",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46040046,
      name: "Marta Elisa  Pifano",
      email: "elisa41pifano@gmail.com",
      telephone: "11971779267",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..1-C15-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34645509,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 07:51:42",
      updated_date: "2023-01-06 07:51:44",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46166445,
      name: "Ana Maria Alves  Alves ",
      email: "anamamtv@hotmail.com",
      telephone: "38999364952",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "Jack1-cp08-C16-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34649395,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 08:48:14",
      updated_date: "2023-01-06 08:48:17",
      value: 39700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2787830,
          name: "5 Potes Biotina Hair - PV",
          description: "Biotina Hair",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335694,
              sku: "BIO1",
              name: "biotina hair ",
              description: "Kit com 5 Potes",
              price: "79.40",
              quantity: 5,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46171407,
      name: "Yamili  Fonseca da Silva",
      email: "fsyamili@gmail.com",
      telephone: "21999879131",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34656064,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 10:05:25",
      updated_date: "2023-01-06 10:05:28",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Elo",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46178390,
      name: "Viviane  Brito conceicao",
      email: "vivianebrito3011@gmail.com",
      telephone: "11953533373",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..3-C11-abo-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34659656,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 10:46:36",
      updated_date: "2023-01-06 10:46:38",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 31476393,
      name: "Maria Simone  Jacomo",
      email: "simonearaujojanaildo@gmail.com",
      telephone: "62992185927",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34662505,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 11:18:55",
      updated_date: "2023-01-06 11:18:57",
      value: 63000,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 4698540,
          name: "10 Potes - Biotina Hair - PV",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335760,
              sku: "BIO1",
              name: "1 Pote Biotina Hair",
              description: "1 Pote ",
              price: "63.00",
              quantity: 10,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46185473,
      name: "Maria de Fatima  Pereira Paulo",
      email: "fcppaulo@hotmail.com",
      telephone: "84987147910",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34674044,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 13:40:19",
      updated_date: "2023-01-06 13:40:21",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46197643,
      name: "Beatriz Nataly  Da Cunha Gois ",
      email: "beatriznataly22@hotmail.com",
      telephone: "16991767247",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34701476,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 19:47:55",
      updated_date: "2023-01-06 19:47:57",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46227800,
      name: "Andréia Maria dias  Dias ",
      email: "andreiacontloira@hotmail.com",
      telephone: "31980207335",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34701805,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 19:53:35",
      updated_date: "2023-01-06 19:53:38",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46227947,
      name: "Denize  Oliveira ",
      email: "denize-alves@outlook.com",
      telephone: "11991494200",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..3>cp01-C11-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34705645,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 20:49:40",
      updated_date: "2023-01-06 20:49:42",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46232662,
      name: "Ana Maria de Almeida",
      email: "ana52almeidarj@gmail.com",
      telephone: "21989090881",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "Jack1-cp08-C16-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34706954,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 21:06:13",
      updated_date: "2023-01-06 21:06:15",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46233746,
      name: "Marlene Assunção Siqueira de Moura  Moura ",
      email: "lene_ass@hotmail.com",
      telephone: "65984547405",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..3-C11-abo-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34714622,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 23:02:16",
      updated_date: "2023-01-06 23:02:18",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 3580159,
          name: "3 Potes + 1 Extra Grátis - LTV",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335851,
              sku: "BIO1",
              name: "Pote Extra Biotina Hair",
              description: "3 Potes + 1 Pote Extra Grátis",
              price: "74.25",
              quantity: 4,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46242643,
      name: "Luiza  De Jesus Meira ",
      email: "dejesusluzia39@gmail.com",
      telephone: "31988449271",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34719337,
    },
    transaction: {
      status: "refunded",
      created_date: "2023-01-07 01:11:20",
      updated_date: "2023-01-07 01:11:23",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46248192,
      name: "Maria de Lourdes Nascimento da Trindade  Trindade ",
      email: "malu.trindade63@gmail.com",
      telephone: "67992194956",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34726974,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 07:52:59",
      updated_date: "2023-01-07 07:53:02",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Elo",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46257495,
      name: "Ana Aparecida  Reginatto ",
      email: "reginattodaiane@hotmail.com",
      telephone: "49999384502",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34583341,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-05 12:36:39",
      updated_date: "2023-01-07 07:58:05",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "billet",
      payment_card_brand: null,
      payment_line: "00190000090339518700203697126179592240000029700",
      payment_bar_code: "00190000090339518700203697126179592240000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/63b6ee87740ebc130d57f9ae",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46097695,
      name: "Abenildo  Carmo Mendonça ",
      email: "abemend@yahoo.com.br",
      telephone: "21971210809",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34732446,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 09:00:29",
      updated_date: "2023-01-07 09:00:31",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46263503,
      name: "Ignês L.Capeler",
      email: "ignescapeler771@gmail.comh",
      telephone: "21981124080",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34733643,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 09:14:49",
      updated_date: "2023-01-07 09:14:52",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46265090,
      name: "Lenir Pinter de Souza Garske Garske",
      email: "lenirpinter@yahoo.com.br",
      telephone: "51996100312",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34736224,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 09:42:51",
      updated_date: "2023-01-07 09:46:33",
      value: 33745,
      discount_value: 59.55,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACxElEQVR4Xu2XQW5bMQwFpYtI979FjyJdROoM5cbfLhB0kc9uLDixQ42BF/KRtMv+/vwq75G38wHO+QDnfIBz/g0YpdS5R2ut7jnqXK3zTDARmDvie3TCdXVe+Q7jeQC3/qCrtNL6aH3VeEsysBqxPypJ1X8A9lx1j8o9UvvoJ5gI8LBA3ae5uPY54mlAmPb9/OXq9/OzQBwj3WThmUrlHuEsYPTBRSta1krpXJjLf3E/gFlXtzQLeSFTA1G1RGCpCrs8rDqDY6ZlAqOFNn6bMfge7ymZAE3LyMAjNq/z6yBkKw/g0nZ1flUTNdUZBcwD+Ls0j1GRqJlK84Bhi0SobpRROBVfq3k/QOMMh3gdHeMuR1qM9a9iJQCDaWHzus2Iglm6S7ESAKplryrSeaaB+2SoZQJ6w12GbcyVY72Yr0xgu8Ic3yocNpHD5FKsBMD8OMO2e+WY1d5pXyJzAHO0LVgRwyrHwnmAqzxS0xXnRsW8IKmAwzOUdqeo6SIynsXKAaJ1dQ53YRnVXkXeDWiS6rM6o4Wx70s17wdCXpRs0bMxPJB5WSgJQIzPh0dMlQaeEcwDVg9F5EalGIXAjPbJA85ffrpDmcOLfLUe+UsDXCfFCHcuuGLe5kvz3g5wTbhVTcL3YcdJRe2zWAkAFy0S43LjkCEiL8W6HWB5KBCn4hRbWeP0S6ISgNhfcUOOHGnjJC0TUKILBWH0TTx414rBlgZgWeamPkFp88JP4M9iJQDhlG3CqJVe1T/0TyrAS+wx9EmLlhlulVSAG4VpVocpWXJ8lFTAMxwXJimmFx1sFyUCp11R2FQX3ln6OBNAnhfNZRIN42vLlwmQpaFf8AklUmdX+EwG4lOWM8QZRiO1SF0uwPcupqlecXJMHZMLyHSlVbnTRo+hkgVEYdikw6+hKGOYz9PLecB35wOc8wHO+QDn/ADwG2h46IrBcCfeAAAAAElFTkSuQmCC",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/6d46744d-a2b3-42ed-8b0b-fa3a016c60d25204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304CB59",
      pix_ref: "34736224cc41eddcb39aef74c752573558f",
      pix_expiration_date: "2023-01-07 10:12:52",
      pix_creation_date: "2023-01-07 09:42:52",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2787830,
          name: "5 Potes Biotina Hair - PV",
          description: "Biotina Hair",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335694,
              sku: "BIO1",
              name: "biotina hair ",
              description: "Kit com 5 Potes",
              price: "79.40",
              quantity: 5,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46266015,
      name: "Rosemeire Ferreira",
      email: "rosemeiref2802@gmail.com",
      telephone: "14996397418",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "rcc",
      utm_medium: null,
      utm_campaign: "whats-su",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34736815,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 09:49:15",
      updated_date: "2023-01-07 09:49:18",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46262209,
      name: "Jarilene  Felipe Ribeiro ",
      email: "jarilenefelipe@gmail.com",
      telephone: "71991122983",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34740871,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 10:32:51",
      updated_date: "2023-01-07 10:32:53",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46272933,
      name: "Teresa Motta",
      email: "teresamlnm@gmail.com",
      telephone: "55619998388",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34742054,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 10:45:50",
      updated_date: "2023-01-07 10:45:53",
      value: 39700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2787830,
          name: "5 Potes Biotina Hair - PV",
          description: "Biotina Hair",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335694,
              sku: "BIO1",
              name: "biotina hair ",
              description: "Kit com 5 Potes",
              price: "79.40",
              quantity: 5,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45790309,
      name: "Monique Borges Santos",
      email: "moniquebonequinhatransex2018@gmail.com",
      telephone: "38991217640",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34752288,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 12:41:48",
      updated_date: "2023-01-07 12:41:50",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46284942,
      name: "Leonia  Marinalva da rocha ",
      email: "leoniamarinalvadarocha@gmail.com",
      telephone: "16991450744",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34763777,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 15:04:23",
      updated_date: "2023-01-07 15:04:25",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46298180,
      name: "Maria Lúcia Braz De  Souza ",
      email: "lucinhabraz@gmail.com",
      telephone: "21984239132",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34764306,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 15:11:13",
      updated_date: "2023-01-07 15:11:15",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46298459,
      name: "Elisabeth  Reis Cerqueira ",
      email: "elisabecerqueira@gmail.com",
      telephone: "14997224514",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34767231,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 15:53:11",
      updated_date: "2023-01-07 15:53:13",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46302077,
      name: "ELAIR   CLEMENTE DOS SANTOS BÁRBARO ",
      email: "ejlbarbaro@gmail.com",
      telephone: "11995810344",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34772701,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 17:15:36",
      updated_date: "2023-01-07 17:15:38",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46308306,
      name: "Vera Regina  Campos Viana ",
      email: "vrviana3@gmail.com",
      telephone: "21999228954",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34784245,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 20:19:00",
      updated_date: "2023-01-07 20:19:04",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46320924,
      name: "Rosa Maria da Silva Ferrugem",
      email: "rosa.ferrugem@gmail.com",
      telephone: "51999332505",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34789729,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 21:45:04",
      updated_date: "2023-01-07 21:45:07",
      value: 39700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2787830,
          name: "5 Potes Biotina Hair - PV",
          description: "Biotina Hair",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335694,
              sku: "BIO1",
              name: "biotina hair ",
              description: "Kit com 5 Potes",
              price: "79.40",
              quantity: 5,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46326312,
      name: "Edilce  Santos ",
      email: "edilce631@gmail.com",
      telephone: "11968622023",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34792655,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 22:36:29",
      updated_date: "2023-01-07 22:37:37",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACzElEQVR4Xu2XS3IkIQwF4SJw/1vMUcRFCmfCuKPKC8csBq1KLne3RTrihb50mb/bn/LT88NeYNsLbHuBbf8GRCk1Smt9jnnVMS4/6UwEBg82rz6icjr4l6ozEwiVdWSV0krr0TpaQyYViNZ4QWWpaFVpPnDVqDPIGU/p0ZczE+CR6GbMiHXflz8NQFa1Yh6mMxFYFkbqqohs5Kt5qGUB0ekXMlSjqSys3CqXCZTO69WXz86lfk2f3jQAiY4w20W7iBSCWyagPGZGM1kUKjHjl5ilAgSG+JgmugdoI8JpwFgqHR7XbiF1Qn5EJgBkpnT3yaRqq8NrjbVboM4DBMn5/d2ydvB0u2QCHNC/6uvTIBU3WjyzeRygbfzTbVZVPK2fe7LOA4M4rTFiw5qm5l5tt0ieBxSHMIvW4LhLSr8e3X0eQJOTTIXqZKNRPY9sHgeMFB1chcouVnunfUQmAHotWAQWl1q1jZcnE9Br0yrO1bYUKzYNwDtc8BavLSM+nGOJwF+XdeNx+K7aj8gMwNSgKOwWkwTySFYC4PxAHaOcLcIXEOt13BdKAkDJVH92wKYXLdK3hkkigMwwNg5y09atl0eyTgPcL3CanOEFS4DicdnnAdOOJTjqG7t5CdSteVMAE7XUlX3NiJW8RzYPA2u5K9I7HjovI9buyToPUKhjfevQTc1SOC64W2edB6wPVhmh8lJBgGLBuUANI+QgdYz6WENozQPChcYZHwyYB8zUZ7JOAwQK5+UQGaJzfy97RPI4UKxSZHmyWibcKqmAIbJYkOcwdawSsJIKaCtG7Xt6McuUnQjYrg4MdypNw1p3gpG6RGBMpXFq3fjCzdNBkgrYMN2U0cVECZ1d4SMZmFapM8Q2ppFQSRHnAt6wHGJEi8kxrJhcwGShcipTedZvvw3zBGAlhqq1RFTGMB+umI/IBOA3e4FtL7DtBbb9B+AL/vIVXWpbpUEAAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/ccd92996-29e4-48aa-a417-3d91b30fc0845204000053039865802BR5906APPMAX6009SAO PAULO62070503***63046220",
      pix_ref: "34792655e8b423e625d2c756bd774c83154",
      pix_expiration_date: "2023-01-07 23:06:31",
      pix_creation_date: "2023-01-07 22:36:31",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46330264,
      name: "Josefina Miranda Isejima  Isejima ",
      email: "joisejima2014@gmail.com",
      telephone: "71999793344",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34822986,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-08 11:26:14",
      updated_date: "2023-01-08 11:26:19",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 43069149,
      name: "Ana Ferreira Wright da Silva",
      email: "anafwright@gmail.com",
      telephone: "61986042557",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34824097,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-08 11:37:20",
      updated_date: "2023-01-08 11:37:22",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46365099,
      name: "Ildemárcia Santos da Matta Silva",
      email: "marcia.santos.63@hotmail.com",
      telephone: "19999516226",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34825505,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-08 11:52:37",
      updated_date: "2023-01-08 11:52:39",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46367590,
      name: "Paula Prigol Rodrigues ",
      email: "lucianeprigol@gmail.com",
      telephone: "54991305304",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34839860,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-08 14:39:25",
      updated_date: "2023-01-08 14:39:26",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Hipercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46382872,
      name: "Iara  Lacerda",
      email: "iaraglessi@gmail.com",
      telephone: "51984141637",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34846326,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-08 16:04:36",
      updated_date: "2023-01-08 16:04:38",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46390580,
      name: "Raquel Prado Martins  Prado Martins ",
      email: "raqpradom@gmail.com",
      telephone: "34984037701",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34856805,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-08 18:42:10",
      updated_date: "2023-01-08 18:42:12",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46402562,
      name: "Magali Aparecida Seron  Luiz ",
      email: "magaliluiz545@gmail.com",
      telephone: "17997379953",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..3>cp01-C11-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34871171,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-08 22:15:27",
      updated_date: "2023-01-08 22:15:29",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46419943,
      name: "Helena Maria Piza  Fransiozi ",
      email: "helenampfransiozi@gmail.com",
      telephone: "19993714482",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34891519,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-09 09:33:37",
      updated_date: "2023-01-09 09:33:38",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46443160,
      name: "Geisa  Santos ",
      email: "geisapvsantos@gmail.com",
      telephone: "42998016669",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..3>cp01-C11-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34907992,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-09 12:56:55",
      updated_date: "2023-01-09 12:56:58",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46289063,
      name: "Maria das Graças Oliveira Dantas  Dantas",
      email: "milene.acari@hotmail.com",
      telephone: "84999535501",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "rcc",
      utm_medium: null,
      utm_campaign: "whats-su",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34914056,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-09 14:14:34",
      updated_date: "2023-01-09 14:14:36",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 3580159,
          name: "3 Potes + 1 Extra Grátis - LTV",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335851,
              sku: "BIO1",
              name: "Pote Extra Biotina Hair",
              description: "3 Potes + 1 Pote Extra Grátis",
              price: "74.25",
              quantity: 4,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 35084994,
      name: "Janice Hugen Schaffer ",
      email: "janihugen04@gmail.com",
      telephone: "47988091605",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "rcc",
      utm_medium: null,
      utm_campaign: "ltv-su",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34937090,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-09 19:51:19",
      updated_date: "2023-01-09 19:51:22",
      value: 19700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2787905,
          name: "1 Pote Biotina Hair - PV",
          description: ".",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335760,
              sku: "BIO1",
              name: "1 Pote Biotina Hair",
              description: "1 Pote ",
              price: "197.00",
              quantity: 1,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46495438,
      name: "Aurineide  Câmara Moura ",
      email: "aurineidecmoura@gmail.com",
      telephone: "85999570512",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34940680,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-09 20:38:52",
      updated_date: "2023-01-09 20:38:55",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46499282,
      name: "Marta  Calazans",
      email: "martaacalazans@yahoo.com.br",
      telephone: "11981250656",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2059-cp07-C15-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34952156,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-09 23:38:11",
      updated_date: "2023-01-09 23:38:13",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46513235,
      name: "Maria Lídiane de sousa Sousa",
      email: "smarialidiane287@gmail.com",
      telephone: "11962422036",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..3>cp01-C11-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34655433,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-06 09:58:51",
      updated_date: "2023-01-10 07:23:22",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "billet",
      payment_card_brand: null,
      payment_line: "00190000090339518700203707684175192250000029700",
      payment_bar_code: "00190000090339518700203707684175192250000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/63b81b0b87e06f0ac70f1782",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 3580159,
          name: "3 Potes + 1 Extra Grátis - LTV",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335851,
              sku: "BIO1",
              name: "Pote Extra Biotina Hair",
              description: "3 Potes + 1 Pote Extra Grátis",
              price: "74.25",
              quantity: 4,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 45159650,
      name: "Valquíria  Rodrigues Valle ",
      email: "valquiria.valle@hotmail.com",
      telephone: "62991010120",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34729023,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 08:20:42",
      updated_date: "2023-01-10 07:28:02",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "billet",
      payment_card_brand: null,
      payment_line: "00190000090339518700203717933174992260000029700",
      payment_bar_code: "00190000090339518700203717933174992260000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/63b9558bcd5f453b191ae287",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46259721,
      name: "Maria do Carmo de Jesus da Silva  Do Carmo ",
      email: "ecocardiomed@hotmail.com",
      telephone: "24992357633",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34792089,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-07 22:25:14",
      updated_date: "2023-01-10 07:34:20",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "billet",
      payment_card_brand: null,
      payment_line: "00190000090339518700203725615177292260000029700",
      payment_bar_code: "00190000090339518700203725615177292260000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/63ba1b7af22f6105094df869",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46329555,
      name: "Eleneide  Alves Ferreira ",
      email: "neideadilson83@gmail.com",
      telephone: "11967649790",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34808254,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-08 08:50:06",
      updated_date: "2023-01-10 07:35:38",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "billet",
      payment_card_brand: null,
      payment_line: "00190000090339518700203729118178992270000029700",
      payment_bar_code: "00190000090339518700203729118178992270000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/63baadee9cec204d835aa3ae",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46348397,
      name: "Katia Rehina Coelho montagna",
      email: "katiamontagna@gmail.com",
      telephone: "47991028410",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34868340,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-08 21:34:40",
      updated_date: "2023-01-10 07:41:10",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "billet",
      payment_card_brand: null,
      payment_line: "00190000090339518700203736706171192270000029700",
      payment_bar_code: "00190000090339518700203736706171192270000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/63bb6120b95e804c797e5861",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46416003,
      name: "Maria Inês zanella Matias",
      email: "alonsomatias@terra.com.br1",
      telephone: "19997089060",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34917300,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-09 14:57:30",
      updated_date: "2023-01-10 07:45:23",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "billet",
      payment_card_brand: null,
      payment_line: "00190000090339518700203744696174592280000029700",
      payment_bar_code: "00190000090339518700203744696174592280000029700",
      payment_url: null,
      billet_url:
        "https://boletos.appmax.com.br/boleto/63bc558aba6bd26c93389295",
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 3580159,
          name: "3 Potes + 1 Extra Grátis - LTV",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335851,
              sku: "BIO1",
              name: "Pote Extra Biotina Hair",
              description: "3 Potes + 1 Pote Extra Grátis",
              price: "74.25",
              quantity: 4,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46472782,
      name: "Márcia Aparecida Marques Pereira buranello  Buranello",
      email: "marciaburanello@hotmail.com",
      telephone: "43999458114",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "rcc",
      utm_medium: null,
      utm_campaign: "ltv-su",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34962929,
    },
    transaction: {
      status: "integrated",
      created_date: "2023-01-10 07:55:57",
      updated_date: "2023-01-10 07:56:00",
      value: 24699,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 5899933,
          name: "3 Potes Biotina Hair - Oferta Após o pith",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "82.33",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46525548,
      name: "Valdete Alves da Silveira Silva Silva",
      email: "valdetealvesdasilveira@gmail.com",
      telephone: "16982307586",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "oferta_pos_pith",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34963885,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 08:10:31",
      updated_date: "2023-01-10 08:13:09",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACzElEQVR4Xu2XUXIcIQxE4SJw/1vkKOIikH5NKmYnVa58GH2NbK9n4blKVrcEW9b38as8Vx7xAjteYMcL7Pg/IEqpI6qexixVL62vwWIiMPQ99Dq7MO0O/UllMROIOhTRSimttB6tT7JOBybFUpalKlcyzQem8lpRtS+VenQvZgILsaL0gWZT29buqeZVwKZ9xj+ufsbPAg55VjqpWPKMnMsmkQVEV790pMK2HaSJOf6L+4A2qVZnbacpr8jGLGcB7NbQfnPL0kNTCbdMQE07i94PskS0rh/VLBWgcZTnsns1vzZS/zZOCqAlzU/qo+3hPJX4keR1gKr0xfTUag3cEjvTPGApN3VM1W8y1isj7RArAVDPVucldQIW78YhVgKgkky+1DjSSvPEs/1DrOuAnp2XrKLTRDLRRIGAeQA56UQfnuN6Z6HmqeZ9QMmhF6bVVgNVyQ6xEgCbdTJA5FsSXPRzHJP2PhBObp9mxmSVYLxnAlod9IuT80T32ZYJYBYtKVMeKBcGji+xcgCPUbuXa4a5j9P/OrDF6gwzhUQKny5PNa8Cg1s3FwpNjkbjOM3jQMkAKJA9Ui2WLloL9b66+z7AJG114F3pJkqvw+2TB3iNQ4VMGV4cLn3lAgu1Bsc6udG8KtTRvBmAlvks6lQHHz6E1VPN+4AcqyqpLuxjFSrWDrESAJ8idiprwUT1AZcJ2Cb4FMiKycKf3X0d+HOEODGSDbjBSZsHqDadKc73gHY7n2LdB2gb3g05lzotbnsCU4FiIvBJc8vQSIdhEgDXh8smg9xVwjwlFSA4SZjoTBMMrIdDzftAuF0rEmlbyTLD9JQJDLzS0Km4ZBz0FSdnAhrjyonGUb6dPDuJj2TAty3ahxnWJ+Ns1mRgcsNa9knlfoF/UgGYLn+gE+nh335O+/uAhWGAipFkuvz6LX2cBnwXL7DjBXa8wI4fAH4DJMAj5ZPqKE4AAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/8e33e2fe-34f4-47f7-b19e-52795f80c7bb5204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304D907",
      pix_ref: "34963885c5aad55be7c2e7acbb31aef08e3",
      pix_expiration_date: "2023-01-10 08:40:32",
      pix_creation_date: "2023-01-10 08:10:32",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46526246,
      name: "Roseli  Soares Rodrigues ",
      email: "rose-manaus2011@hotmail.com",
      telephone: "92991679379",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "facebook",
      utm_medium: null,
      utm_campaign: "2052-C..3>cp01-C11-ges:AK",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34966389,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 08:43:35",
      updated_date: "2023-01-10 08:43:37",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46528974,
      name: "Maria Aparecida  Filgueira Lima ",
      email: "marafilgueiras@gmail.com",
      telephone: "11973011680",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34969736,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 09:23:05",
      updated_date: "2023-01-10 09:23:07",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46532843,
      name: "Nilza Claudia Garcia xisto  Claudia ",
      email: "beibes771@gmail.com",
      telephone: "11974610044",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34969592,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 09:21:19",
      updated_date: "2023-01-10 09:24:13",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACwklEQVR4Xu2WQXIbMQwEyY+Q//9FnkJ8hJtu0FbWPrhyyCIX0SrJJltVMGYG3Hb9vH617zvf1hs46w2c9QbO+jtgtdZX32OMzu8z9phXuFkIBK/F5pqx+rVngHU3K4HV89VYo40JvHOnGsg9qmx9Z6v+A3BtleqcU+tc82wWAjJqdUVcsTn2M/fLAMrq8X25WQjk2l3DmpyBXsNDVxWwpmcUtUZTKZ0Lc/svngccGGT2cu+UiVcC1UqBpmF3O3GJLbeGXysDlh3ZbpAbItSol2a1SgB7rI9JDsAgOQhsHUBMKcrhgVSGxjqZaq8iCwD3tpdaz2tFt6xTaR2AXERlZHZZVmrPSgEMo0TLtFKuiuVYf4lVAFzOcRNMt4xQDvWvaj4PWJ/iRE7RMENB7yoBhFGqsGGROELtu1jPA5ETbNIq3ev7GrbsJVYFkCMUr1Llh1mDUsefIp8HbFX3fTA9xbAKO6sSUCVbRWf48XrJim9iPQ9wilG2b07RxMM5VghwRJOmk9Tjlca57pf78wAPObgkzqcigXwRqwDYGd6cYjiGErPM24VSACgUhkGjpmBMso5qtq8OMLsyhGZzuWLZqV9uaj4PZEFLcRwlTSC7VgnkDLMrcHHCy5du4S0BqHFa5mjmB9WQ7otYzwOZEftCZqhz27FxV/N5QINsYxtqRIA1zrxP2ucB3Mr5tE1sap5hqaUAYq0zQVIwX1ZOrXUACEY998gyOaq2b2IVADv/QjGca58un/a2TSsE8tGOVnmSkaFfd8MUALQofMDpDvLskuOjlQKu7NH4nF7MMuNUCGRcl22yuvQOkiFdIRC8Vt7vgAbGGF03sSoAp6dV4hMkss5p4VEM5OR0hjjD8LAO7tWAyZl6xSCHjqkFZCb+2EpEefp33oZ5AaAwGV47RGUMcxqnk+uAn9YbOOsNnPUGzvoHwG+34OI2cizoXAAAAABJRU5ErkJggg==",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/14d7082d-0fe3-4b29-99c5-1389f82f7c8e5204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304A9C8",
      pix_ref: "34969592b2a4126b006146e4ea9c507d98f",
      pix_expiration_date: "2023-01-10 09:51:20",
      pix_creation_date: "2023-01-10 09:21:20",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 3580159,
          name: "3 Potes + 1 Extra Grátis - LTV",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335851,
              sku: "BIO1",
              name: "Pote Extra Biotina Hair",
              description: "3 Potes + 1 Pote Extra Grátis",
              price: "74.25",
              quantity: 4,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46495430,
      name: "Maria Sebastiana  Barros Taques ",
      email: "econms@yahoo.com.br",
      telephone: "92986140909",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "rcc",
      utm_medium: null,
      utm_campaign: "whats-su",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34970174,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 09:28:17",
      updated_date: "2023-01-10 09:28:19",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46533442,
      name: "Rosane  Alonso Hernandes",
      email: "rosanedengosa2009@hotmail.com",
      telephone: "13997022730",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34975839,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 10:34:54",
      updated_date: "2023-01-10 10:34:57",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 3580159,
          name: "3 Potes + 1 Extra Grátis - LTV",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335851,
              sku: "BIO1",
              name: "Pote Extra Biotina Hair",
              description: "3 Potes + 1 Pote Extra Grátis",
              price: "74.25",
              quantity: 4,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46539515,
      name: "Miriam  Henrique ",
      email: "miriam-henrique1@hotmail.com",
      telephone: "47999461391",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "rcc",
      utm_medium: null,
      utm_campaign: "ltv-su",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34979695,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 11:16:41",
      updated_date: "2023-01-10 11:16:43",
      value: 44400,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2788420,
          name: "3 Potes Biotina Hair + 2 Potes Upsell",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2336173,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
            {
              id: 2412778,
              sku: "BIO1",
              name: "Biotina hair 50% desconto",
              description: "",
              price: "73.50",
              quantity: 2,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46544153,
      name: "Angela  Madalena da Silva dos Santos ",
      email: "angelamadalenasantos@gmail.com",
      telephone: "51994085499",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34981180,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 11:32:37",
      updated_date: "2023-01-10 11:32:40",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Mastercard",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 3580159,
          name: "3 Potes + 1 Extra Grátis - LTV",
          description: "",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2335851,
              sku: "BIO1",
              name: "Pote Extra Biotina Hair",
              description: "3 Potes + 1 Pote Extra Grátis",
              price: "74.25",
              quantity: 4,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46545499,
      name: "SOLANGE FERREIRA  DE SOUZA SANTOS",
      email: "solangefssantos@hotmail.com",
      telephone: "31987649041",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: "rcc",
      utm_medium: null,
      utm_campaign: "ltv-su",
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34990212,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 13:18:23",
      updated_date: "2023-01-10 13:19:12",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACwElEQVR4Xu2WUY7bMAwFrYuI979FjyJdRO4MFay9KbDoR83+RMg6jjwLPJDvUT7On9ev433nbX2AvT7AXh9gr78DxnG00YO7uY4j5uJ+ulkIzNPf7RwxRztXTP6luVkJjDZHqPU4+tEDwau5WQxQIinFrizVfwDOc1GpxnO0BpKrAZmwV3PimfAmNwsBZFGpt+VmIeAiMb1ZLBpFv7oPXVXACPIyOobpKhs6t8kVAhiWp6hTXgJ4hd9RCOyZwZP4ytBiquVYqwNaapq2LLj4172tA4bl0SPpXubXRq7wVgAMzu7kaGtHSJ0jrmYVAMhCj0fZkNMtYystBLp1imwak8vWWbNSYFCWbmpmNs5i5Vi/mvU8gGENL0IztoP8eL2aVQEQ1VAU17BN3XO1X4UqAFBjajWtWj1Ljsgc1wFOzoyvCkkRJxob37r5OJCNmU7SlgpD60oWAhglYzK9EaNyVG1UAq+46pC5D3csBFIJ+GI1XmYxMup05+rm88CrT+Sl+Tgto9ovkQXAq1malm+D7N2f3XwSoE+ZVceY2A7P7UApAPQL9Wl6JK8cMABXswoAdjAssaVhhtj3HR30JbIC6M1TnQ2gjI3lcr5WApHh8ZD3CsL3PbzPA75xaxmlRmJG+d7N5wH9SbGUi1Rk5ky9NasAGFZGx/qIb41jfCsBjvZuvw7cgk8a4WWQ3tNdAJBchmiODqCWRQOwdFXA9LHjnE969vS4v3fzeWA5Rpuzgy296tvesmiFgIeJk8OuZWSGp0opsOvT9zjPKjk+jlLAlSPcIuX0ejvcC4Bhq6gQk4zHFMwZNrN1ZcDcvo3Mipdt4qtQFQBVQpPBQW+oMxQ+i4GV71Zrz7DQvNwVA2bGXlEoY6xjagE++oMxQoSRNz1QbsO8AMjGMMG1CC3zpcd5ao7LgJ/WB9jrA+z1Afb6B8BvmL/dSusR+lwAAAAASUVORK5CYII=",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/8ac0a09e-524d-4b10-a544-bdb6a5b541275204000053039865802BR5906APPMAX6009SAO PAULO62070503***6304159E",
      pix_ref: "3499021219112ae0337226c708e5501e952",
      pix_expiration_date: "2023-01-10 13:48:24",
      pix_creation_date: "2023-01-10 13:18:24",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46555795,
      name: "Renan  Catunda",
      email: "renancatunda.b@gmail.com",
      telephone: "85998477778",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 34995242,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 14:16:29",
      updated_date: "2023-01-10 14:16:31",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "credit_card",
      payment_card_brand: "Visa",
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode: null,
      pix_emv: null,
      pix_ref: null,
      pix_expiration_date: null,
      pix_creation_date: null,
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 46561147,
      name: "Marcia Araújo  Pupin",
      email: "marcialoidepupin@gmail.com",
      telephone: "16996235808",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
  {
    account: "Conta 1",
    identification: {
      data_source: "APPMX",
      order_id: 35001110,
    },
    transaction: {
      status: "approved",
      created_date: "2023-01-10 15:32:19",
      updated_date: "2023-01-10 15:33:21",
      value: 29700,
      discount_value: null,
      freight: null,
      freight_type: null,
      payment_type: "pix",
      payment_card_brand: null,
      payment_line: null,
      payment_bar_code: null,
      payment_url: null,
      billet_url: null,
      pix_qrcode:
        "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACtklEQVR4Xu2XQVLEMAwE7Y9Y//8FT7E+4jAtUyS7B4oDGS4xC2Tl3qpB0simHT+vj/YeeVsPsNcD7PUAe/0OmK31OVh9jX7kGnEkQSOQes04VovMPFakPtIJOoHZtYHW1kYbMUesTtAOEAvlp0ulUvUPAD+O2bUvraG0uQGYmE1RgUvb/K64DZAsZeptETQCrDUIrLJNK//s5QKUJL1XcWZRk87tcE5ALbJQhrwCaCBVzQkMzTDt0TGSmsBMNScAgsaQbfcw07dyZgWmLFspksLUINnIaV4DwABnhJErkETnjLNYBmDPz12zQGnVDKVGQEap7lCupAwH08heQLWq6kwkLhk4a6x/F8sAkCrGFqeZorOG+rVYBmCVaVd1bFCmwbk6zkQZAGUqsM2gadXDVaj1at67ASoTcouuN42+1YlW7FnN+4EqljJEt4zdrHin+tgFVLQjTQ+FxVcj+wC9QRMdkvtwT2p3Kdb9wKI8pfNgilbGkjlmBHjkSwZmu1oGtedfcT9QCtUeXDobRZq7fb2AmpXXwT2nhodkXg4UB6ArjY4UeqRTqabHrJnqAyZ7lRsGuUpXHYR9fABeLZlBuAFooBH2AWrROkM41qnbdtDFvAagOqYnGeroK4yIEajEMMN11e0oDkbZpVgGYHLLU5WCLfVsw0FxnbT3A4lb6FNpVPsoZWh9dffdgI5VbhgSlvzzw4vsSasRUEhh+gShbEw+cIq8H1iIVKFylnk4WQYtZAX0mOhkpywzOVWsgFKEsErVzhLNg5N8AKs8S5LYE/ByuBsA7LoYXQN1g5NNqEpnBBL/crLiFQxThbsOEAOAYYJSdekNdAbC0wwsYviVGRZMdT3ZAd2wqn2ZHEnHeIFiJG10itbLRgwVI0Bh1CMNpswrkJP2W6QB+Gk9wF4PsNcD7PUHwCdpCdyWyeLmyAAAAABJRU5ErkJggg==",
      pix_emv:
        "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/4c47eca3-c711-422d-9901-d1f198cc896e5204000053039865802BR5906APPMAX6009SAO PAULO62070503***630405A9",
      pix_ref: "35001110d9435757970a752e936b6bbed86",
      pix_expiration_date: "2023-01-10 16:02:20",
      pix_creation_date: "2023-01-10 15:32:20",
      pix_url: null,
      is_upsell: null,
    },
    product: {
      bundles: [
        {
          id: 2682084,
          name: "3 Potes Biotina Hair - PV",
          description: "Biotina Hair ",
          production_cost: "R$ 0,00",
          identifier: null,
          products: [
            {
              id: 2236193,
              sku: "BIO1",
              name: "Biotina Hair",
              description: "kit com 3 Potes",
              price: "99.00",
              quantity: 3,
              image: "https://dhl6xem5lrcqr.cloudfront.net/",
              external_id: 0,
            },
          ],
        },
      ],
    },
    producer: {
      name: "BIOTINA SAUDE E BELEZA LTDA",
      document: "44.569.714/0001-90",
      email: "contato@biotinahair.com.br",
      tel: null,
    },
    customer: {
      id: 36934899,
      name: "Raquel Barboza Vasconcelos",
      email: "quelbv@hotmail.com",
      telephone: "71987488584",
      document: null,
    },
    comission: {
      comissions_list: [],
      co_production_commission: null,
    },
    metadata: {
      affiliate_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
    },
  },
];

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

function divideArray(arr) {
  let chunks = [];
  let shuffled = arr.sort(() => 0.5 - Math.random());
  let i = 0;
  while (i < shuffled.length) {
    let chunkSize = Math.floor(Math.random() * (shuffled.length - i)) + 1;
    chunks.push(shuffled.slice(i, i + chunkSize));
    i += chunkSize;
  }
  return chunks;
}

function random_item(items) {
  return items[Math.floor(Math.random() * items.length)];
}

var gateways = ["BRAIP", "APPMX", "PAGME", "MONETIZZE"];
var names = ["lindiana", "matheus", "giovanna"];
var srcs = ["facebook", "google", "tiktok"];

export const mock_sales = async () => {
  console.log("Mocking sales...");

  const chunks = await divideArray(sales);
  const result = await chunks.map((o, index) => {
    const gateway = random_item(gateways);
    const src = random_item(srcs);
    const pf = getRandomNumber(1, 5);
    const ca = getRandomNumber(1, 5);
    const bm = getRandomNumber(1, 2);
    const cp = getRandomNumber(1, 3);
    const ad = getRandomNumber(10, 20);
    const gestor = random_item(names);

    return o.map((b) => ({
      ...b,
      // identification: {
      //   ...b.identification,∆
      //   data_source: gateway,
      // },
      product: {
        bundles: b.product.bundles.map((bundle) => ({
          ...bundle,
          name: `Nome do produto`,
          description: `Descrição do produto`,
          products: bundle.products.map((product) => ({
            ...product,
            name: `Nome do produto`,
            description: `Descrição do produto`,
          })),
        })),
      },
      customer: {
        ...b.customer,
        name: `Nome do cliente`,
        email: `*****${b.customer.email.slice(5)}`,
        telephone: `${b.customer.telephone.slice(3)}xxx`,
      },
      metadata: {
        ...b.metadata,
        utm_source: `[SRC_${src}]-[PF_00${pf}]-[CA_0${ca}]-[BM_00${bm}]-[CP_${cp}]-[CJ_aberto]-[AD_0${ad}]-[GESTOR_${gestor}]`,
        utm_campaign: null,
      },
    }));
  });

  return result.flatMap((o) => o);
};
