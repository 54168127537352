import { useEffect } from "react"
import { getEmailUsuario, getNomeUsuario } from "../services/auth"
import { Crisp } from 'crisp-sdk-web'

export default function CrispChat() {
    useEffect(() => {
        Crisp.configure('f9a42b03-3f21-41ef-b764-7c1611394ebb', {
            autoload: false,
        })
        if (!/login/.test(window.location.href)) {
            const email = getEmailUsuario()
            const name = getNomeUsuario()
            if (email && name) {
                Crisp.user.setEmail(email)
                Crisp.user.setNickname(name)
            }
        }
        Crisp.load()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return null
}