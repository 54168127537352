import React, { useState, useEffect } from "react";
import { BroadcastChannel } from "broadcast-channel";
import axios from "axios";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Button, Checkbox } from "rsuite";
import { copyToClipboard } from "../../functions/copyToClipboard";
import MetaTags from "react-meta-tags";
import api from "../../services/api";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";

const DebugFacebookAdsToken = () => {
  const appId = "764460408156536";
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [facebookConnectionStatus, setFacebookConnectionStatus] =
    useState(false);
  const [fbData, setFbData] = useState({});

  async function exchangeToken(short_token) {
    console.log("Exchanging token...");
    await api
      .get("/api/fb/exchange-token/" + short_token)
      .then((result) => {
        console.log("Exchanged token: ", result.data);
        setFbData(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Facebook get acces_token functions
  function initFB() {
    console.log("Initializing Facebook JS SDK");
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: appId,
        autoLogAppEvents: true,
        xfbml: true,
        cookies: true,
        version: "v16.0",
        oauth: true,
      });
    };
  }

  function facebookLogin() {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          exchangeToken(response.authResponse.accessToken);
          window.FB.api("/me", function (response) {
            console.log("Authenticated as " + response.name + ".");
          });
          setFacebookConnectionStatus(true);
        } else {
          console.log("User cancelled login or did not fully authorize.");
          toast.error("Usuário cancelou login com o Facebook");
          setFacebookConnectionStatus(false);
        }
      },
      {
        scope: "email,public_profile,ads_read,ads_management",
        return_scopes: true,
        display: "popup",
        location: "no",
      }
    );
  }

  useEffect(() => {
    initFB();
  }, []);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Facebook Ads Token Debugger | Metrito</title>
        <HelmetProvider>
          <Helmet>
            <script
              async
              defer
              crossorigin="anonymous"
              src="https://connect.facebook.net/en_US/sdk.js"
            ></script>
          </Helmet>
        </HelmetProvider>
      </MetaTags>
      <Container>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="w-100 mb-4">
                  <h4>Facebook Ads Token Debugger</h4>
                  <p className="text-muted">
                    Essa ferramenta deve ser utilizada para a extração e
                    verificação do Token retornado pelo Facebook Ads.
                  </p>
                </div>

                {fbData.access_token && (
                  <div
                    style={{
                      border: "2px dashed #00cc47",
                      borderRadius: 10,
                      padding: 20,
                      margin: 40,
                    }}
                  >
                    <b
                      className="copy-to-clipboard"
                      onClick={() => copyToClipboard(null, fbData.access_token)}
                    >
                      {fbData.access_token || "ERRO AO RECUPERAR TOKEN"}
                    </b>
                  </div>
                )}

                <div>
                  <Checkbox
                    checked={checked}
                    onClick={() => setChecked(!checked)}
                  >
                    <p className="text-muted">
                      Eu concordo em compartilhar os dados solicitados na atual
                      página (token do Facebook Ads) com a equipe de engenharia
                      da plataforma para fins de testes e desenvolvimento.
                    </p>
                  </Checkbox>

                  <button
                    className={`btn btn-primary w-100`}
                    onClick={() => facebookLogin()}
                    disabled={!checked}
                  >
                    <i className="bx bxl-facebook-circle fs-2 me-3 align-middle"></i>
                    <span className="align-middle">
                      Desconectar perfil do FB
                    </span>
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DebugFacebookAdsToken;
