import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import api from "../../services/api";
import { TemplateCard } from "../Dashboard/TemplateCard";

import { metritoLayouts } from "../../common/data/layouts";

import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getIdUsuario } from "../../services/auth";
const axios = require("axios");

const Layouts = () => {
  const [layouts, setLayouts] = useState([]);
  const [loading, setLoading] = useState([]);

  //Faz o Fetch dos layouts
  async function fetchLayouts(act, token) {
    setLoading(true);
    const fbData1 = await api
      .get("/api/layouts/" + getIdUsuario())
      .then((res) => {
        setLayouts(res.data.reverse());
        setLoading(false);
      })
      .catch(function (motivo) {
        // rejeição
        toast.error("Problema ao carregar Layouts.");
        console.log(motivo);
        setLoading(false);
      });
  }

  async function handleDelete(id) {
    const deleteLayout = await api
      .delete("/api/layouts", { data: { _id: id } })
      .then((res) => {
        toast.success("Template apagado!");
        fetchLayouts();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  async function getScreenshot(url) {
    axios
      .get("https://screenshot.abstractapi.com/v1/?api_key=f1cf7630589348e1bb2093a6e878d47a&url=https://v1.metrito.com/register")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function generatePlaceholders() {
    var placeholders = [];
    for (var i = 0; i < 4; i++) {
      placeholders.push(
        <Card
          className="float-start mx-2 p-0"
          style={{
            width: 350,
            height: 200,
          }}
        >
          <CardBody>
            <h5 className="card-title placeholder-glow">
              <span className="placeholder col-2 p-4 mb-3" style={{ borderRadius: 5 }}></span>
            </h5>

            <h5 className="card-title placeholder-glow">
              <span className="placeholder col-6"></span>
            </h5>
            <p className="card-text placeholder-glow">
              <span className="placeholder col-7"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-6"></span>
            </p>
          </CardBody>
        </Card>
      );
    }

    return placeholders;
  }

  useEffect(() => {
    fetchLayouts();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Templates | Metrito</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Layouts" breadcrumbItem="Templates de Dashboard" />

          <Row>
            <h5 className="ms-3 mb-3">Templates Prontos</h5>

            {metritoLayouts.map((template, key) => (
              <TemplateCard
                key={key}
                loadingTemplates={loading}
                template={template}
                isMetritoDefault={true}
                isSelectable={false}
                canDelete={false}
              />
            ))}
          </Row>

          <Row>
            <h5 className="m-3">Meus Templates</h5>
            {loading ? (
              <Col>{generatePlaceholders()}</Col>
            ) : (
              <React.Fragment>
                {layouts.map((template, key) => (
                  <TemplateCard
                    key={key}
                    loadingTemplates={loading}
                    template={template}
                    isMetritoDefault={false}
                    isSelectable={false}
                    layouts={layouts}
                    setLayouts={setLayouts}
                    canDelete={true}
                  />
                ))}
              </React.Fragment>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Layouts;
