import moment from "moment";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const AnalyticsChart = (props) => {
  var series = [
    {
      name: "Investimento",
      data: props.metrics.map((o) => o["amount_spent"]) || [],
    },
    {
      name: "Vendas",
      data: props.metrics.map((o) => o["conversions_value"]) || [],
    },
  ];

  var options = {
    chart: {
      height: 320,
      zIndex: 10,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    labels: props.metrics.map((a) => moment(a.date).toLocaleString("pt-BR")),
    colors: ["#0081f9", "#00E396", "#CED4DC"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    xaxis: {
      type: "datetime",
      tickPlacement: "on",
    },
    annotations: {
      yaxis:
        props.lines?.map((line) => ({
          y: line.limit,
          borderColor: line.color || "#000",
          strokeDashArray: 5,
          label: {
            borderColor: line.color || "#000",
            style: {
              color: "#fff",
              background: line.color || "#000",
            },
            text: line.text,
          },
        })) || [],
    },
    yaxis: [
      {
        title: {
          text: "investimento x retorno",
        },
        labels: {
          formatter: function (value) {
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
      },
    ],

    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type={options.chart.type}
        height={options.chart.height}
        dir="ltr"
      />
    </React.Fragment>
  );
};

export default AnalyticsChart;
