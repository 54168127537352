export const payment_methods = [
  {
    code: "credit_card",
    label: "Cartão de crédito",
    icon: "https://cdn-icons-png.flaticon.com/512/893/893081.png",
    BRAIP: 2,
    APPMX: "CreditCard",
    PAGME: "credit_card",
    MNTZZ: "Cartão de crédito",
  },
  {
    code: "billet",
    label: "Boleto",
    icon: "https://cdn-icons-png.flaticon.com/512/3481/3481266.png",
    BRAIP: 1,
    APPMX: "Boleto",
    PAGME: "boleto",
    MNTZZ: "Boleto",
  },
  {
    code: "pix",
    label: "Pix",
    icon: "https://user-images.githubusercontent.com/741969/99538099-3b7a5d00-298b-11eb-9f4f-c3d0cd4a5280.png",
    BRAIP: 5,
    APPMX: "Pix",
    PAGME: "pix",
    MNTZZ: "Pix",
  },
];
