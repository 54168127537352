import React, { useEffect } from "react";
import $ from "jquery";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import loadingAnimation from "../../assets/lotties/sales_panel_loading_lottie.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const SalesBlurLoader = (props) => {
  useEffect(() => {
    if (props.loading) {
      $("html, body").css({
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
    } else {
      $("html, body").css({
        overflow: "auto",
        height: "auto",
      });
      $(".footer").css({
        position: "relative",
        bottom: 0,
        left: 0,
        right: 0,
      });
    }
  }, [props.loading]);

  return (
    <>
      {props.loading && (
        <motion.div
          id="blur_screen"
          animate={{ height: "100vh", width: "100vw" }}
          style={{
            width: "100%",
            height: 1,
            backdropFilter: "blur(20px)",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <motion.div
            initial={{ opacity: 0, y: -10, scale: 0.975 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            className="d-flex flex-column justify-content-center align-items-center"
            transition={{
              duration: 0.8,
              ease: [0, 0.71, 0.2, 1],
              delay: 0.8,
            }}
          >
            <Lottie
              options={defaultOptions}
              style={{
                margin: "none",
                zIndex: 100,
                pointerEvents: "none",
                width: 100,
                height: "auto",
              }}
            />
            <b className="fs-5 text-muted mt-3">
              Carregando dados importantes...
            </b>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default SalesBlurLoader;
