import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getToken, logout } from "../../services/auth";
import api from "../../services/api";

const Logout = (props) => {
  async function confirmSair() {
    const res = await api.get("/api/usuarios/destroytoken", {
      headers: { token: getToken() },
    });
    if (res.status === 200) {
      logout();
      window.location.href = "/login";
      props.logoutUser(props.history);
    } else {
      alert("Não foi possível fazer o logout!");
    }
  }
  useEffect(() => {
    confirmSair();
  });

  return <></>;
};

export default Logout;
