import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import classnames from "classnames";

import moment from "moment";

//services
import { defineMetric } from "../functions/metricFormatter";

const CampaignsTable = (props) => {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignFilter, setCampaignFilter] = useState({
    title: "Todas",
    type: "all",
  });

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  function formatCampaignStatus(param) {
    switch (param) {
      case "PAUSED":
        return (
          <span>
            <i
              className="mdi mdi-checkbox-blank-circle"
              style={{ color: "#DBDDE0", fontSize: 10 }}
            ></i>{" "}
            &nbsp; Pausada
          </span>
        );
        break;
      case "ACTIVE":
        return (
          <span>
            {/* <i
              className="mdi mdi-checkbox-blank-circle"
              style={{ color: "#29AC77", fontSize: 10 }}
            ></i>{" "} */}
            <i
              className="spinner-grow text-success mx-3 ms-0 fs-6"
              style={{ height: 10, width: 10, margin: "auto" }}
            ></i>
            &nbsp; Ativa
          </span>
        );
        break;
      default:
        return "---";
    }
  }

  function formatObjective(objective) {
    const object = campaignObjectives.find((o) => o.value === objective);

    return (
      (
        <span className="mx-3">
          <i
            className={object?.icon || ""}
            style={{
              color: object?.color || "#000",
              fontSize: 17,
              marginInlineEnd: 10,
            }}
          ></i>
          {object?.name || "-"}
        </span>
      ) || <span></span>
    );
  }

  const campaignObjectives = [
    {
      value: "REACH",
      name: "Alcance",
      icon: "mdi mdi-account-group",
      color: "#707070",
    },
    {
      value: "MESSAGES",
      name: "Mensagens",
      icon: "mdi mdi-chat-processing",
      color: "#707070",
    },
    {
      value: "LINK_CLICKS",
      name: "Clique no Link",
      icon: "mdi mdi-cursor-default-click",
      color: "#707070",
    },
    {
      value: "CONVERSIONS",
      name: "Conversões",
      icon: "mdi mdi-cash-check",
      color: "#707070",
    },
    {
      value: "VIDEO_VIEWS",
      name: "Visualização de Vídeo",
      icon: "mdi mdi-video-check",
      color: "#707070",
    },
    {
      value: "POST_ENGAGEMENT",
      name: "Engajamento",
      icon: "bx bxs-like",
      color: "#707070",
    },
    {
      value: "LEAD_GENERATION",
      name: "Geração de Leads",
      icon: "mdi mdi-magnet-on",
      color: "#707070",
    },
    //Novos Objetivos
    {
      value: "OUTCOME_APP_PROMOTION",
      name: "Promoção de App (Novo)",
      icon: "mdi mdi-new-box",
      color: "#707070",
    },
    {
      value: "OUTCOME_AWARENESS",
      name: "Reconhecimento (Novo)",
      icon: "mdi mdi-new-box",
      color: "#707070",
    },
    {
      value: "OUTCOME_ENGAGEMENT",
      name: "Engajamento (Novo)",
      icon: "mdi mdi-new-box",
      color: "#707070",
    },
    {
      value: "OUTCOME_LEADS",
      name: "Leads (Novo)",
      icon: "mdi mdi-new-box",
      color: "#707070",
    },
    {
      value: "OUTCOME_SALES",
      name: "Conversões (Novo)",
      icon: "mdi mdi-new-box",
      color: "#707070",
    },
    {
      value: "OUTCOME_TRAFFIC",
      name: "Tráfego (Novo)",
      icon: "mdi mdi-new-box",
      color: "#707070",
    },
  ];

  function campaingFiltering(param) {
    switch (param) {
      case "all":
        setCampaigns(props.campaigns);
        break;
      case "active":
        setCampaigns(
          props.campaigns.length
            ? props.campaigns.filter((c) => c.effective_status === "ACTIVE")
            : setCampaigns([])
        );
        break;
      case "paused":
        setCampaigns(
          props.campaigns.length
            ? props.campaigns.filter((c) => c.effective_status === "PAUSED")
            : setCampaigns([])
        );
        break;
      default:
        setCampaigns(props.campagins);
        break;
    }
  }

  useEffect(() => {
    campaingFiltering(campaignFilter.type);
  }, [campaignFilter, props.campaigns]);

  return (
    <React.Fragment>
      <Nav tabs justified className="nav-tabs-custom" role="tablist">
        <NavItem>
          <NavLink
            to="#"
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            <span className="d-none d-sm-block my-2 ">
              <i className="bx bx-folder me-1"></i> Campanhas
            </span>
          </NavLink>
        </NavItem>
        {/* <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <i className="mdi mdi-clipboard-text-outline font-size-20"></i>
                    <span className="d-none d-sm-block">Conjuntos</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <i className="mdi mdi-clipboard-text-outline font-size-20"></i>
                    <span className="d-none d-sm-block">Anúncios</span>
                  </NavLink>
                </NavItem> */}
      </Nav>
      <div className="table-responsive">
        {!props.campaigns ? (
          <h6>Carregando...</h6>
        ) : (
          <TabContent activeTab={activeTab}>
            <TabPane id="about" role="tabpanel" tabId="1">
              <table
                className="table table-hover table-bordered align-middle table-centered table-nowrap m-0"
                style={{ bordeRadius: 100 }}
              >
                <thead className="table-light">
                  <tr>
                    <th>Nome da Campanha</th>
                    <th>Status</th>
                    <th>Objetivo</th>
                    <th>Valor gasto</th>
                    <th>Cliques no link</th>
                    <th>CPC (link)</th>
                    <th>CPM</th>
                    <th>CTR (link)</th>
                    <th>Criada</th>
                    <th>Iniciada</th>
                    <th>Atualizada</th>
                    {/* <th>Opções</th> */}
                  </tr>
                </thead>
                <tbody style={{ overflowY: "scroll" }}>
                  {campaigns?.map((campaign, key) => (
                    <tr key={key}>
                      <td>{campaign?.name || "erro"}</td>
                      <td>
                        {campaign?.status
                          ? formatCampaignStatus(campaign.status)
                          : "erro"}
                      </td>
                      <td>
                        <span>
                          {campaign?.objective
                            ? formatObjective(campaign.objective)
                            : "erro"}
                        </span>
                      </td>
                      <td>
                        {campaign?.insights
                          ? defineMetric(
                              campaign.insights.data,
                              "$spend"
                            )[0].toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : "-"}
                      </td>
                      <td>
                        {campaign?.insights
                          ? defineMetric(
                              campaign.insights.data,
                              "$actions:link_click"
                            )[0]
                          : "-"}
                      </td>
                      <td>
                        {campaign?.insights
                          ? defineMetric(
                              campaign.insights.data,
                              "$cost_per_action_type:link_click"
                            )[0].toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : "-"}
                      </td>
                      <td>
                        {campaign?.insights
                          ? defineMetric(
                              campaign.insights.data,
                              "$cpm"
                            )[0].toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : "-"}
                      </td>
                      <td>
                        {campaign?.insights
                          ? defineMetric(
                              campaign.insights.data,
                              "%website_ctr:link_click"
                            )[0].toFixed(2) + "%"
                          : "-"}
                      </td>

                      <td>
                        <span className="me-2">
                          <i
                            className="mdi mdi-calendar-clock"
                            style={{
                              color: "#0476B9",
                            }}
                          ></i>{" "}
                          {moment(campaign?.created_time).format("DD/MM/YYYY")}
                        </span>
                        <span>
                          {moment(campaign?.created_time).format("HH:mm")}
                        </span>
                      </td>
                      <td>
                        <span className="me-2">
                          <i
                            className="mdi mdi-calendar-check"
                            style={{ color: "#2CB57E" }}
                          ></i>{" "}
                          {moment(campaign?.start_time).format("DD/MM/YYYY")}
                        </span>
                        <span>
                          {moment(campaign?.start_time).format("HH:mm")}
                        </span>
                      </td>
                      <td>
                        <span className="me-2">
                          <i
                            className="mdi mdi-calendar-edit"
                            style={{ color: "#F2B34C" }}
                          ></i>{" "}
                          {moment(campaign?.updated_time).format("DD/MM/YYYY")}
                        </span>
                        <span>
                          {moment(campaign?.updated_time).format("HH:mm")}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TabPane>
            <TabPane id="tasks" role="tabpanel" tabId="2">
              <h5 className="font-size-16 mb-3">CONJUNTOS</h5>
            </TabPane>
            <TabPane id="tasks" role="tabpanel" tabId="3">
              <h5 className="font-size-16 mb-3">ANUNCIOS</h5>
            </TabPane>
          </TabContent>
        )}
      </div>
    </React.Fragment>
  );
};

export default CampaignsTable;
