import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";
import Preloader from "../Utility/Preloader";
//Import Breadcrumb
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { InputNumber, Loader, Placeholder, Button } from "rsuite";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classNames from "classnames";
import $ from "jquery";
import { toast } from "react-toastify";

const Plans = (props) => {
  const [loadingStripeSession, setLoadingStripeSession] = useState(false);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [userData, setUserData] = useState({});
  const [rangeValue, setRangeValue] = useState(0);
  const handleMinus = () => {
    if (rangeValue > 0) {
      setRangeValue(parseInt(rangeValue, 10) - 1);
    }
  };
  const handlePlus = () => {
    setRangeValue(parseInt(rangeValue, 10) + 1);
  };

  const history = useHistory();

  const [modal_center, setmodal_center] = useState(false);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function verifySubscriptionStatus(user_data) {
    if (
      user_data.subscription?.status === "canceled" ||
      user_data.subscription?.status === "incomplete" ||
      user_data.subscription?.status === "incomplete_expired"
    ) {
      return false;
    } else {
      return true;
    }
  }

  async function loadUserData() {
    const response = await api
      .get("/api/usuarios.details/" + getIdUsuario())
      .then((response) => {
        setUserData(response.data[0]);

        var extra_ad_accounts =
          response.data[0].subscription?.extra_ad_accounts_qty;

        console.log("extra_ad_accounts", extra_ad_accounts);

        if (extra_ad_accounts > 0) {
          setRangeValue(extra_ad_accounts);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function fetchPlans() {
    try {
      const plans_response = await api.get("/api/plans");
      setPlans(plans_response.data.sort((a, b) => a.price - b.price));

      setLoadingPlans(false);
    } catch (error) {
      console.log(error);
    }
  }

  function getCheckoutLink(plan, extra_accounts_qty, user) {
    var keys = [plan.gateway_product_id];

    // if (extra_accounts_qty > 0) {
    //   keys.push(plan.extra_account_key);
    // }

    setLoadingStripeSession(true);
    api
      .post("/create-checkout-session", {
        lookup_keys: keys,
        accounts_qty: plan.ad_accounts + extra_accounts_qty,
        user: user,
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200 && res.data.url) {
          window.dataLayer.push({
            event: "initiate_checkout",
            value:
              selectedPlan.price +
              extra_accounts_qty * selectedPlan.extra_account_price,
            currency: "BRL",
            content_name: "Checkout",
            content_category: "plan",
            content_ids: selectedPlan.gateway_product_id,
            num_ids: 1,
          });

          window.location.href = res.data.url;
        } else {
          toast.error("Erro ao criar sessão de pagamento");
          setLoadingStripeSession(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingStripeSession(false);
      });
  }

  function createPortalSession(customer_id) {
    setLoadingStripeSession(true);
    api
      .post("/create-portal-session", {
        customer_id,
      })
      .then((res) => {
        window.open(res.data.url);
        setLoadingStripeSession(false);
      })
      .catch((err) => {
        setLoadingStripeSession(false);
        console.log(err);
      });
  }

  useEffect(() => {
    if (
      userData.trial === true &&
      moment(userData.trial_expires_at) < new Date()
    ) {
      document.getElementById("topnav").style.display = "none";
    }
    loadUserData();
    fetchPlans();
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length && plans.length > 0) {
      if (userData.subscription) {
        setSelectedPlan(
          plans.find(
            (p) =>
              p.gateway_product_id ===
              userData.subscription.items.find((item) =>
                item.plan.nickname.includes("PL")
              ).plan.nickname
          )
        );
      } else {
        setSelectedPlan(plans.filter((plan) => plan.version === 2)[2]);
      }
    }
  }, [userData, plans]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planos | Metrito</title>
        </MetaTags>
        <Container>
          {plans.length === 0 || Object.keys(userData).length === 0 ? (
            <Row className="d-flex">
              <Placeholder.Paragraph
                style={{ marginTop: 30 }}
                className="d-flex justify-content-center"
              />

              {[1, 2, 3].map((item, key) => (
                <Col>
                  <Card>
                    <CardBody>
                      <Placeholder.Graph active className="mb-4" />
                      <Placeholder.Grid rows={5} columns={1} active />
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : !loadingPlans &&
            Object.keys(userData).length &&
            plans.length &&
            Object.keys(selectedPlan).length &&
            userData.subscription ? (
            <Row>
              <Col lg={4}>
                <Card
                  style={{
                    minHeight: 400,
                    boxShadow: "0px 10px 20px rgb(50 52 88 / 5%)",
                    margin: 10,
                    padding: 15,
                  }}
                >
                  {selectedPlan.gateway_product_id?.includes("PL003") && (
                    <div className="best-choice-appendix">Melhor Escolha</div>
                  )}
                  <CardBody className="d-flex flex-column justify-content-between w-100">
                    <div>
                      <h5 className="text-center mb-4">{selectedPlan.name}</h5>
                      <div className="w-100 d-flex justify-content-center text-center">
                        <img
                          src={selectedPlan.icon_url}
                          alt="plan"
                          style={{ maxHeight: 70 }}
                          className="mb-2"
                        />
                      </div>
                      <h4
                        className={`text-${
                          selectedPlan.name === "Plano Black Diamond"
                            ? "dark"
                            : "success"
                        } text-center mt-3 mb-0`}
                      >
                        {(
                          selectedPlan.price +
                          rangeValue * selectedPlan.extra_account_price
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </h4>
                      <div className="w-100 text-center">
                        <span className="text-muted" style={{ fontSize: 12 }}>
                          por mês
                        </span>
                      </div>

                      <hr className="divider" />
                      <div
                        className="d-flex flex-column mx-3"
                        style={{ fontSize: 16, minHeight: 200 }}
                      >
                        <span>
                          <i className="fas fa-plug text-primary me-2" />
                          {`${
                            selectedPlan.ad_accounts + rangeValue
                          } contas de anúncio`}
                          {rangeValue > 0 && (
                            <span
                              className="text-muted ms-2"
                              style={{ fontSize: 12 }}
                            >
                              ({selectedPlan.ad_accounts} + {rangeValue} extra)
                            </span>
                          )}
                        </span>
                        <span>
                          <img
                            className="me-2"
                            src="https://cdn-icons-png.flaticon.com/512/5219/5219370.png"
                            alt="ai-coins"
                            style={{
                              width: 15,
                              height: 15,
                              marginLeft: -1,
                            }}
                          />
                          {`${selectedPlan.ai_credits.toLocaleString(
                            "pt-BR"
                          )} créditos de IA`}
                        </span>
                        {selectedPlan.features.map((feature, key) => (
                          <span key={key}>
                            <i className="bx bxs-check-circle text-success me-2 mt-1" />
                            {feature.label}
                          </span>
                        ))}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={8} className="p-5">
                <h2>Você já faz parte do time!</h2>
                <span>
                  Você já tem um plano de assinatura ativo, fique a vontade para
                  conferir os recursos disponíveis e caso queira fazer um
                  upgrade ou um downgrade basta clicar em "gerenciar assinatura"
                </span>
                <div className="my-3">
                  <Button
                    loading={loadingStripeSession}
                    className="btn btn-primary"
                    onClick={() => {
                      createPortalSession(userData.customer.id);
                    }}
                  >
                    Gerenciar Assinatura
                  </Button>
                </div>
              </Col>
            </Row>
          ) : Object.keys(userData).length &&
            plans.length &&
            Object.keys(selectedPlan).length &&
            !userData.subscription ? (
            <>
              <Row className="justify-content-center mb-3">
                <Col lg={10}>
                  <div className="text-center my-3">
                    <h1 className="mb-3">
                      Aproveite o melhor do Metrito com nossos planos!
                    </h1>
                    <p className="text-muted mb-4">
                      Desde o Gestor iniciante, até as grandes agências. Nós
                      temos planos que se encaixam perfeitamente com você!
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="d-flex flex-row justify-content-center mb-2">
                {plans
                  .filter((o) => o.version === 2)
                  .map((plan, key) => (
                    <Col lg={4} key={key}>
                      <motion.div
                        whileHover={{
                          scale: 1.025,
                          transition: { duration: 0.2 },
                        }}
                      >
                        <Card
                          className={classNames(
                            {
                              "plan-option-selected":
                                selectedPlan.gateway_product_id ===
                                  plan.gateway_product_id &&
                                !plan.gateway_product_id?.includes("PL003"),
                              "border-purple":
                                selectedPlan.gateway_product_id ===
                                  plan.gateway_product_id &&
                                plan.gateway_product_id?.includes("PL003"),
                            },
                            "plan-option "
                          )}
                          style={{
                            minHeight: 400,
                            boxShadow: "0px 10px 20px rgb(50 52 88 / 5%)",
                          }}
                          onClick={() => setSelectedPlan(plan)}
                        >
                          {plan.gateway_product_id?.includes("PL003") && (
                            <div className="best-choice-appendix">
                              Melhor Escolha
                            </div>
                          )}
                          <CardBody className="d-flex flex-column justify-content-between w-100">
                            <div>
                              <h5 className="text-center mb-4">{plan.name}</h5>
                              <div className="w-100 d-flex justify-content-center text-center">
                                <img
                                  src={plan.icon_url}
                                  alt="plan"
                                  style={{ maxHeight: 70 }}
                                  className="mb-2"
                                />
                              </div>
                              <h4
                                className={`text-${
                                  plan.name === "Plano Black Diamond"
                                    ? "dark"
                                    : "success"
                                } text-center mt-3 mb-0`}
                              >
                                {(
                                  plan.price +
                                  rangeValue * plan.extra_account_price
                                ).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                  maximumFractionDigits: 2,
                                })}
                              </h4>
                              <div className="w-100 text-center">
                                <span
                                  className="text-muted"
                                  style={{ fontSize: 12 }}
                                >
                                  por mês
                                </span>
                              </div>

                              <hr className="divider" />
                              <div
                                className="d-flex flex-column mx-3"
                                style={{ fontSize: 16, minHeight: 200 }}
                              >
                                <span>
                                  <i className="fas fa-plug text-primary me-2" />
                                  {`${
                                    plan.ad_accounts + rangeValue
                                  } contas de anúncio`}
                                  {rangeValue > 0 && (
                                    <span
                                      className="text-muted ms-2"
                                      style={{ fontSize: 12 }}
                                    >
                                      ({plan.ad_accounts} + {rangeValue} extra)
                                    </span>
                                  )}
                                </span>
                                <span key={key}>
                                  <img
                                    className="me-2"
                                    src="https://cdn-icons-png.flaticon.com/512/5219/5219370.png"
                                    alt="ai-coins"
                                    style={{
                                      width: 15,
                                      height: 15,
                                      marginLeft: -1,
                                    }}
                                  />
                                  {`${plan.ai_credits.toLocaleString(
                                    "pt-BR"
                                  )} créditos de IA`}
                                </span>
                                {plan.features.map((feature, key) => (
                                  <span key={key}>
                                    <i className="bx bxs-check-circle text-success me-2 mt-1" />
                                    {feature.label}
                                  </span>
                                ))}
                              </div>
                            </div>

                            <div>
                              {Object.keys(selectedPlan).length &&
                              selectedPlan.gateway_product_id ===
                                plan.gateway_product_id &&
                              (!userData.subscription ||
                                !verifySubscriptionStatus(userData)) ? (
                                <div className="text-center mt-3">
                                  <b>
                                    <i className="fas fa-plug text-primary me-2" />{" "}
                                    Que tal contas de anúncio extras?
                                  </b>

                                  <motion.div
                                    className="p-0 d-flex align-items-center mt-2"
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.3 }}
                                  >
                                    <motion.div
                                      className="text-center me-2 d-flex align-items-center justify-content-center"
                                      style={{
                                        height: 40,
                                        width: 50,
                                        backgroundColor: "#f1f1f1",
                                        borderRadius: 5,
                                        cursor: "pointer",
                                      }}
                                      whileHover={{
                                        scale: 1.1,
                                        transition: { duration: 0.1 },
                                      }}
                                      whileTap={{ scale: 1 }}
                                      onClick={handleMinus}
                                    >
                                      <i className="mdi mdi-minus"></i>
                                    </motion.div>
                                    <InputNumber
                                      className={"custom-input-number"}
                                      value={rangeValue}
                                      onChange={(e) => setRangeValue(Number(e))}
                                    />
                                    <motion.div
                                      className="text-center ms-2 d-flex align-items-center justify-content-center"
                                      style={{
                                        height: 40,
                                        width: 50,
                                        backgroundColor: "#f1f1f1",
                                        borderRadius: 5,
                                        cursor: "pointer",
                                      }}
                                      whileHover={{
                                        scale: 1.1,
                                        transition: { duration: 0.1 },
                                      }}
                                      whileTap={{ scale: 1 }}
                                      onClick={handlePlus}
                                    >
                                      <i className="mdi mdi-plus"></i>
                                    </motion.div>
                                  </motion.div>
                                </div>
                              ) : null}
                            </div>
                          </CardBody>
                        </Card>
                      </motion.div>
                    </Col>
                  ))}

                <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-4 mb-2">
                  <button
                    className="btn btn-success"
                    style={{
                      fontSize: 24,
                      width: "100%",
                      maxWidth: 400,
                      padding: 15,
                    }}
                    disabled={loadingStripeSession}
                    onClick={() => {
                      setLoadingStripeSession(true);

                      if (
                        userData.subscription &&
                        verifySubscriptionStatus(userData)
                      ) {
                        createPortalSession(userData.customer.id);
                      } else {
                        getCheckoutLink(selectedPlan, rangeValue, userData);
                      }
                    }}
                  >
                    {loadingStripeSession ? (
                      <span>
                        <Loader inverse className="me-2" />
                        Quase lá...
                      </span>
                    ) : (
                      <span>Contratar Agora</span>
                    )}
                  </button>
                  <div className="my-2 d-flex flex-column align-items-center">
                    <small className="text-muted mb-1">
                      Compra 100% segura processada por Stripe
                    </small>
                    <img
                      src="https://www.lothianbuses.com/wp-content/uploads/2018/04/contactlessfaq_logos-copy.png"
                      alt="payment methods"
                      style={{
                        width: "100%",
                        maxWidth: 200,
                      }}
                    />
                  </div>
                  <b className="mb-3" style={{ fontStyle: "italic" }}>
                    <i className="mdi mdi-clock-fast me-2" />
                    Acesso liberado imediatamente após pagamento bem sucedido!
                  </b>
                  <b className="mb-3" style={{ fontStyle: "italic" }}>
                    <i className="mdi mdi-cash me-2" />
                    Garantia incondicional de 7 dias. Se não gostar, devolvemos
                    seu dinheiro!
                  </b>
                </div>
              </Row>
            </>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Plans;
