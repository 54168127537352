import { endOfDay, startOfMonth } from "date-fns";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";
import { MetaTags } from "react-meta-tags";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Loader, Message, Progress } from "rsuite";
import { changeLayout, changeSidebarType } from "../../store/actions";
import ChargesTable from "./ChargesTable";
import AdminAreaChart from "./charts/AreaChart";
import AdminColumnChart from "./charts/ColumnChart";
import DonutChart from "./charts/DonutChart";
import AdminLineChart from "./charts/LineChart";
import AdminPieChart from "./charts/PieChart";

import _ from "lodash";
import moment from "moment";
import api from "../../services/api";

const msToS = (ms) => Math.round(ms / 1000);

const percentage = (partialValue, totalValue, rounded) => {
  const result =
    (100 * Number(String(partialValue).replace(/[^0-9-]+/g, ""))) /
    Number(String(totalValue).replace(/[^0-9-]+/g, ""));

  if (rounded) {
    return Math.round(result);
  } else {
    return result.toFixed(2);
  }
};

const getNumber = (value) => Number(String(value).replace(/[^0-9-]+/g, ""));

const Panel = (props) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [financialReport, setFinancialReport] = useState({});
  const [report, setReport] = useState({});
  const [registeredToday, setRegisteredToday] = useState(0);
  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [goals, setGoals] = useState([
    {
      bonus: "R$ 200,00",
      gross_revenue: "R$ 15.000,00",
      net_revenue: "R$ 15.000,00",
      active_subscribers: 50,
      new_subscriptions: "R$ 5.000,00",
    },
    {
      bonus: "R$ 400,00",
      gross_revenue: "R$ 15.000,00",
      net_revenue: "R$ 15.000,00",
      active_subscribers: 50,
      new_subscriptions: "R$ 7.000,00",
    },
    {
      bonus: "R$ 600,00",
      gross_revenue: "R$ 15.000,00",
      net_revenue: "R$ 15.000,00",
      active_subscribers: 50,
      new_subscriptions: "R$ 9.000,00",
    },
    {
      bonus: "R$ 800,00",
      gross_revenue: "R$ 15.000,00",
      net_revenue: "R$ 15.000,00",
      active_subscribers: 50,
      new_subscriptions: "R$ 11.000,00",
    },
    {
      bonus: "R$ 1.000,00",
      gross_revenue: "R$ 15.000,00",
      net_revenue: "R$ 15.000,00",
      active_subscribers: 50,
      new_subscriptions: "R$ 13.000,00",
    },
    {
      bonus: "R$ 1.200,00",
      gross_revenue: "R$ 15.000,00",
      net_revenue: "R$ 15.000,00",
      active_subscribers: 50,
      new_subscriptions: "R$ 15.000,00",
    },
  ]);
  var active_goal =
    goals.find(
      (g) =>
        getNumber(g.new_subscriptions) >
        getNumber(
          financialReport.by_period?.total_amount_captured_by_description
            .subscription_creation
        )
    ) || goals[0];

  function getReport(time_interval) {
    setLoading(true);
    api
      .post("/api/admin/report", { time_interval })
      .then((res) => {
        console.log(res.data);
        setReport(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function getFinancialReport(time_interval) {
    setLoading(true);
    console.log(time_interval);

    api
      .post("/api/admin/report/treasury", { time_interval })
      .then((res) => {
        console.log(res.data);
        setFinancialReport(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  async function getUsers(time_interval) {
    setLoading(true);

    await api
      .post("/api/admin/users", { time_interval })
      .then((res) => {
        setUsers(res.data);
        setRegisteredToday(
          res.data?.filter(
            (o) =>
              moment(o.createdAt).toISOString(true).substring(0, 10) ===
              new Date().toISOString().substring(0, 10)
          ).length
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  async function loadAllData() {
    try {
      var mongo_time_interval = await {
        since: moment(datePickerState[0].startDate).toISOString(),
        until: moment(endOfDay(datePickerState[0].endDate)).toISOString(),
      };

      var stripe_time_interval = await {
        since: moment(datePickerState[0].startDate).unix(),
        until: moment(endOfDay(datePickerState[0].endDate)).unix(),
      };

      getFinancialReport(stripe_time_interval);
      getReport(mongo_time_interval);
      getUsers(mongo_time_interval);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    loadAllData();
    // $("body").css({ "background-color": "#ffffff" });

    props.changeLayout("vertical");
    props.changeSidebarType("small");
  }, []);

  var goals_percentage = {
    gross_revenue: percentage(
      financialReport.by_period?.total_amount_captured,
      active_goal.gross_revenue,
      true
    ),
    net_revenue: percentage(
      financialReport.by_period?.net_revenue,
      active_goal.net_revenue,
      true
    ),
    active_subscribers: percentage(
      financialReport.all_time?.total_subscriptions.active,
      active_goal.active_subscribers,
      true
    ),
    new_subscriptions: percentage(
      financialReport.by_period?.total_amount_captured_by_description
        .subscription_creation,
      active_goal.new_subscriptions,
      true
    ),
  };

  const cards = [
    {
      title: "Cadastrados",
      count: report.all_users_count,
      count_today: registeredToday,
      dollor: false,
      icon: "bx bxs-group text-dark",
    },
    {
      title: "Empresas",
      count: report.all_brands_count,
      count_today: 0,
      dollor: false,
      icon: "bx bxs-briefcase-alt text-dark",
    },
    {
      title: "Dashboards",
      count: report.all_dashboards_count,
      count_today: 0,
      dollor: false,
      icon: "bx bxs-dashboard text-dark",
    },
    {
      title: "C. de Anúncio",
      count: report.all_ad_accounts_count,
      count_today: 0,
      dollor: false,
      icon: "bx bxl-facebook-circle text-dark",
    },
    {
      title: "Usuários em Trial Ativo",
      count: report.active_trial_users,
      count_today: 0,
      dollor: false,
      icon: "mdi mdi-test-tube text-dark",
      percentage: report.percentage_trial_users?.with,
      color: "warning",
      upArrow: true,
    },
    {
      title: "Usuários com empresa",
      count: report.users_with_brands,
      count_today: 0,
      dollor: false,
      icon: "bx bxs-briefcase-alt text-dark",
      percentage: report.percentage_users_brands?.with,
      color: "warning",
      upArrow: true,
    },
    {
      title: "Usuários com C. de Anúncio",
      count: report.users_with_ad_accounts,
      count_today: 0,
      dollor: false,
      icon: "bx bxl-facebook-circle text-dark",
      percentage: report.percentage_users_ad_accounts?.with,
      color: "warning",
      upArrow: true,
    },
    {
      title: "Assinantes",
      count: financialReport.by_period?.subscriptions_created_by_period?.length,
      count_today: 0,
      dollor: false,
      icon: "mdi mdi-cart-check text-dark",
      percentage: percentage(
        financialReport.by_period?.subscriptions_created_by_period?.length,
        report.all_users_count,
        false
      ),
      color: "warning",
      upArrow: true,
    },
    {
      title: "Usuários em Trial Expirado",
      count: report.expired_trial_users,
      count_today: 0,
      dollor: false,
      icon: "mdi mdi-test-tube-off text-dark",
      percentage: report.percentage_trial_users?.without,
      color: "warning",
      upArrow: true,
    },
    {
      title: "Média de empresa p/ usuário",
      count: report.average_brands_per_user,
      count_today: 0,
      dollor: false,
      icon: "bx bx-calculator text-dark",
      color: "warning",
      upArrow: true,
    },
    {
      title: "Média de C. de Anúncio p/ usuário",
      count: report.average_ad_accounts_per_user,
      count_today: 0,
      dollor: false,
      icon: "bx bx-calculator text-dark",
      color: "warning",
      upArrow: true,
    },
    {
      title: "Ticket Médio",
      count: "-",
      count_today: 0,
      dollor: false,
      icon: "mdi mdi-ticket-percent-outline text-dark",
      color: "warning",
      upArrow: true,
    },
  ];

  return (
    <>
      <MetaTags>
        <title>Painel - Admin</title>
      </MetaTags>
      <div className="page-content">
        {!loading && Object.keys(financialReport).length > 0 ? (
          <Container fluid style={{ minHeight: "70vh" }}>
            <Row>
              <Col>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png"
                  alt="stripe-logo"
                  width={80}
                />
              </Col>
              <Col className="d-flex align-items-center justify-content-center d-none d-lg-block">
                <Message
                  showIcon
                  type="warning"
                  className="rs-theme-dark w-100"
                >
                  <b>Atenção: </b>Vamos correr todos atrás dessa meta, ela é
                  nosso foco e objetivo!!
                </Message>
              </Col>
              <Col className="d-flex justify-content-end">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn btn-light"
                    type="button"
                    style={{ minWidth: 200, border: "1px solid #d2d2db" }}
                  >
                    <span className="float-start text-truncate text-dark">
                      Intervalo
                    </span>
                    <i className="mdi mdi-chevron-down text-dark float-end"></i>
                  </DropdownToggle>
                  <DropdownMenu className="mt-2 dropdown-menu-end">
                    <div className="d-flex justify-content-start">
                      <div
                        className="d-flex flex-column"
                        style={{ flexDirection: "column" }}
                      >
                        <DateRangePicker
                          className="float-end"
                          onChange={(item) =>
                            setDatePickerState([item.selection])
                          }
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={datePickerState}
                          direction="horizontal"
                          locale={rdrLocales.pt}
                          maxDate={new Date()}
                        />
                        <button
                          className="btn btn-primary px-5 mx-3 mb-2"
                          onClick={() => {
                            setFinancialReport({});
                            loadAllData();
                          }}
                        >
                          Aplicar
                        </button>
                      </div>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
            <hr className="divider mx-2" />
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody style={{ paddingBottom: 0 }}>
                    <Row>
                      <Col lg={4}>
                        <div>
                          <div className="d-flex flex-column">
                            <span className="text-muted text-sm">
                              Faturamento bruto
                            </span>
                            <b className="fs-3 mb-1">
                              {financialReport.by_period?.total_amount_captured}
                            </b>
                          </div>
                          <div className="d-flex flex-column">
                            <span className="text-muted text-sm">
                              Faturamento líquido
                            </span>
                            <b className="fs-5 mb-3">
                              {financialReport.by_period?.net_revenue}
                            </b>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} className="d-flex justify-content-start">
                        <div className="text-start">
                          <div className="d-flex flex-column">
                            <span className="text-muted text-sm text-truncate">
                              Novas assinaturas
                            </span>
                            <b className="fs-5 mb-1">
                              {
                                financialReport.by_period
                                  ?.total_amount_captured_by_description
                                  .subscription_creation
                              }
                            </b>
                          </div>
                          <div className="d-flex flex-column">
                            <span className="text-muted text-sm">
                              Renovações
                            </span>
                            <b className="fs-5 mb-3">
                              {
                                financialReport.by_period
                                  ?.total_amount_captured_by_description
                                  .subscription_update
                              }
                            </b>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex flex-column">
                            <span className="text-muted text-sm text-truncate">
                              Novos assinantes
                            </span>
                            <b className="fs-5 mb-1">
                              {
                                financialReport.by_period?.charges_by_description.subscription_creation?.filter(
                                  (s) => s.status === "succeeded"
                                ).length
                              }
                            </b>
                          </div>
                          <div className="d-flex flex-column ">
                            <span className="text-muted text-sm text-truncate">
                              Total de vendas
                            </span>
                            <b className="fs-5 mb-1">
                              {
                                financialReport.by_period?.charges?.filter(
                                  (s) => s.status === "succeeded"
                                ).length
                              }
                            </b>
                          </div>
                        </div>
                        <DonutChart
                          data={[
                            financialReport.by_period
                              ?.total_amount_captured_by_description
                              .subscription_creation,
                            financialReport.by_period
                              ?.total_amount_captured_by_description
                              .subscription_update,
                          ]}
                        />
                      </Col>
                    </Row>
                    <AdminColumnChart
                      period={_.groupBy(
                        financialReport.by_period?.charges?.filter(
                          (s) => s?.status === "succeeded"
                        ) || [],
                        (ch) =>
                          new Date(ch.created * 1000)?.toLocaleDateString(
                            "en-US"
                          )
                      )}
                      data={[
                        _.groupBy(
                          financialReport.by_period?.charges_by_description.subscription_creation?.filter(
                            (s) => s?.status === "succeeded"
                          ) || [],
                          (ch) =>
                            new Date(ch.created * 1000)?.toLocaleDateString(
                              "en-US"
                            )
                        ),
                        _.groupBy(
                          financialReport.by_period?.charges_by_description.subscription_update?.filter(
                            (s) => s?.status === "succeeded"
                          ) || [],
                          (ch) =>
                            new Date(ch.created * 1000)?.toLocaleDateString(
                              "en-US"
                            )
                        ),
                      ]}
                    />
                  </CardBody>
                  <div>
                    <div className="d-flex justify-content-between pt-3">
                      <span className="text-muted text-sm px-3">
                        Meta de faturamento (apenas para novas assinaturas):{" "}
                        {active_goal.new_subscriptions}
                      </span>

                      {getNumber(
                        financialReport.by_period
                          ?.total_amount_captured_by_description
                          .subscription_creation
                      ) > getNumber(goals[0].new_subscriptions) ? (
                        <span className="text-muted text-sm px-3">
                          Bônus garantido:{" "}
                          <b className="text-success text-sm">
                            +{" "}
                            {
                              goals[
                                Math.max(
                                  goals.findIndex((ac) => ac === active_goal) -
                                    1,
                                  0
                                )
                              ].bonus
                            }
                          </b>
                        </span>
                      ) : null}

                      <span className="text-muted text-sm px-3">
                        {goals_percentage.new_subscriptions}% da meta concluída.
                      </span>
                    </div>

                    <Progress.Line
                      percent={goals_percentage.new_subscriptions}
                      showInfo={false}
                      status={
                        goals_percentage.new_subscriptions < 100
                          ? "active"
                          : "normal"
                      }
                      strokeColor={
                        goals_percentage.new_subscriptions < 30
                          ? "#F44335"
                          : goals_percentage.new_subscriptions >= 30 &&
                            goals_percentage.new_subscriptions < 60
                          ? "#ffc107"
                          : goals_percentage.new_subscriptions >= 60 &&
                            goals_percentage.new_subscriptions < 100
                          ? "#34C2FE"
                          : "#4CAF4F"
                      }
                    />
                  </div>
                </Card>
              </Col>
              <Col lg={4} style={{ height: 315 }}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <span className="text-muted text-sm">
                          Valor não capturado
                        </span>
                        <b className="fs-5 mb-1 text-danger">
                          -{" "}
                          {financialReport.by_period?.total_amount_not_captured}
                        </b>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="text-muted text-sm">
                          Taxas do Stripe
                        </span>
                        <b className="fs-5 mb-1" style={{ color: "#625AFA" }}>
                          - {financialReport.by_period?.total_stripe_fees}
                        </b>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <AdminPieChart
                      data={financialReport.all_time?.total_subscriptions || {}}
                      colors={[
                        "#34c38f",
                        "#556ee6",
                        "#f46a6a",
                        "#50a5f1",
                        "#f1b44c",
                      ]}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h5>Valor de cobranças</h5>
                    <AdminLineChart
                      data={_.groupBy(
                        financialReport.by_period?.charges || [],
                        (ch) =>
                          new Date(ch.created * 1000).toLocaleDateString(
                            "en-US"
                          )
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              {cards.map((card, key) => (
                <Col md={6} xl={3} key={key}>
                  <Card>
                    <CardBody>
                      <div className="float-end">
                        <div className="avatar-sm mx-auto mb-4">
                          <span className="avatar-title rounded-circle bg-light font-size-24">
                            <i className={card.icon}></i>
                          </span>
                        </div>
                      </div>
                      <div>
                        <p className="text-muted text-uppercase fw-semibold">
                          {card.title}
                        </p>
                        <h4 className="mb-1 mt-1 d-flex flex-row align-items-center">
                          {!loading ? (
                            <span className="counter-value">{card.count}</span>
                          ) : (
                            <div style={{ width: "70%" }}>
                              <p className="card-text placeholder-glow">
                                <span
                                  className="placeholder w-100"
                                  style={{ borderRadius: 5 }}
                                ></span>
                              </p>
                            </div>
                          )}

                          {card.count_today !== 0 ? (
                            <span className="badge badge-soft-success ms-2">
                              + <b>{card.count_today}</b> hoje
                            </span>
                          ) : null}
                          {card.percentage ? (
                            <span className="badge badge-soft-dark ms-2">
                              <b>{card.percentage}%</b>
                            </span>
                          ) : null}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h5>Entrada de usuários</h5>
                    <AdminAreaChart
                      title={"Usuários"}
                      data={_.groupBy(users, (user) =>
                        new Date(user.createdAt).toLocaleDateString("pt-BR")
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <ChargesTable charges={financialReport.by_period.charges} />
              </Col>
              <Col lg={4}>
                <Card>
                  <CardBody>
                    <h5>Taxa de aprovação</h5>
                    <AdminPieChart
                      data={{
                        successful: financialReport.by_period?.charges.filter(
                          (ch) => ch.status === "succeeded"
                        ).length,
                        failed: financialReport.by_period?.charges.filter(
                          (ch) => ch.status === "failed"
                        ).length,
                      }}
                      colors={["#34c38f", "#f46a6a"]}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <Loader className="me-2" />{" "}
            <span className="text-gray" style={{ fontSize: 12 }}>
              Carregando dados do painel...
            </span>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeLayout: (layout) => dispatch(changeLayout(layout)),
  changeSidebarType: (sidebarType) => dispatch(changeSidebarType(sidebarType)),
});

export default connect(null, mapDispatchToProps)(Panel);
