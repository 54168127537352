import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/white_loader_lottie.json";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
import { Progress } from "rsuite";
import { data_sources } from "../../common/data/data_sources";
import styled from "styled-components";

// styles
const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1s infinite;
    content: "·";
    width: 1em;
    text-align: left;
    font-size: 18px;
    font-weight: 900;
  }
  @keyframes ellipsis {
    0% {
      content: "·";
    }
    33% {
      content: "··";
    }
    66% {
      content: "···";
    }
  }
`;

export const WhiteLoader = (props) => {
  const [phrase, setPhrase] = useState("");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const status = props.progress === 100 ? "success" : null;
  const color = props.progress === 100 ? "#526c93" : "#3385ff";

  $(".insights_progress div div").css({
    height: "7px",
  });

  $(".insights_progress div div div").css({
    backgroundColor: "#425c84",
  });

  useEffect(() => {
    $("#quote").hide();

    if (props.isVisible) {
      // axios
      //   .get("https://positive-vibes-api.herokuapp.com/quotes/random")
      //   .then((res) => {
      //     setPhrase(res.data.data);
      //     $("#quote").fadeIn(500);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      $("html, body").css({
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      });
      $("#main-widgets").css("marginTop", 0);
    } else {
      setPhrase("");
      $("html, body").css({
        overflow: "auto",
        height: "auto",
      });
      $("#main-widgets").css("marginTop", 110);
    }
  }, [props.isVisible]);
  return (
    <div
      className="insights-loader-screen flex-column justify-content-center align-items-center text-center"
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "#ffffff",
        zIndex: 1000,
        display: props.isVisible ? "flex" : "none",
        marginTop: -45,
      }}
    >
      <div
        className="w-100 d-flex flex-column justify-content-center align-items-center"
        style={{ position: "relative" }}
      >
        <Lottie options={defaultOptions} height={100} width={400} />
        <span className="d-block text-muted mt-4" style={{ fontSize: 12 }}>
          Carregando dados... Isso pode levar até 1 minuto
        </span>
        <Progress.Line
          className="insights_progress"
          percent={props.progress}
          strokeColor={color}
          status={"active"}
          style={{ width: "40%", maxWidth: 800, marginTop: 20 }}
        />

        {/* <div
          className="d-flex flex-column justify-content-between align-items-center mt-5 w-100"
          style={{
            maxWidth: 600,
            position: "absolute",
            top: 200,
          }}
        >
          {props.reqs_progress &&
            Object.keys(props.reqs_progress)
              .filter((o) => !o.includes("CLICKHOUSE"))
              .map((key) => {
                const req = props.reqs_progress[key];
                const req_data = req.req_data;
                const data_source =
                  data_sources.find((o) => o.code === req_data?.data_source) ||
                  {};

                if (req_data) {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ width: "60%", marginTop: 10 }}
                    >
                      <div className="d-flex align-items-center text-truncate">
                        <span className="float-end me-2">
                          <img
                            src={data_source?.icon_url || ""}
                            alt="platform"
                            style={{ width: 12, height: 12 }}
                          />
                        </span>

                        <span
                          className="d-block text-muted text-truncate me-2"
                          style={{ fontSize: 12 }}
                        >
                          {req_data.name}
                        </span>
                      </div>

                      {req.total_reqs === req.done_reqs ? (
                        <span
                          className="d-block text-muted"
                          style={{ fontSize: 12 }}
                        >
                          <i
                            className="mdi mdi-check-bold"
                            style={{ color: "#52c41a" }}
                          />
                        </span>
                      ) : (
                        <Dots>{props.children}</Dots>
                      )}
                    </div>
                  );
                }
              })}
        </div> */}
      </div>
    </div>
  );
};
