import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { Button, Input, Loader, Panel, Tag } from "rsuite";
import { getImageURL } from "../../functions/getImageURL";
import Preloader from "../../pages/Utility/Preloader";

//Services
import api from "../../services/api";
import { getIdUsuario, getToken } from "../../services/auth";
import { io } from "socket.io-client";

import { toast } from "react-toastify";

import ExternalAdAccountConnectModal from "../Facebook/ExternalAdAccountConnectModal";

//Import Breadcrumb
import { BroadcastChannel } from "broadcast-channel";
import $ from "jquery";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import NoAdAccountsLeftModal from "../Facebook/NoAdAccountsLeft.modal";
import BrandPicker from "../../components/BrandPicker";
import BrandNotFound from "../Utility/BrandNotFound";
import CustomAlert from "../../components/CustomAlert";

const ConnectGoogleAdsAdAccount = (props) => {
  const google_client_id =
    "402494766896-63imgi256phlpi066l6fgtki588eaeri.apps.googleusercontent.com";

  const queryParams = new URLSearchParams(window.location.search);

  const socketRef = useRef();
  const toastRef = useRef();

  const [regStatus, setRegStatus] = useState("");
  const [regDynamicText, setRegDynamicText] = useState("");
  const [success_msg, setsuccess_msg] = useState(false);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [verifying, setVerifying] = useState(false);
  const [adaccounts, setAdaccounts] = useState([]);
  const [adaccountsLimit, setAdaccountsLimit] = useState(0);
  const [alreadyConnectedAddacounts, setAlreadyConnectedAddacounts] = useState(
    []
  );

  const [googleAuthResponse, setGoogleAuthResponse] = useState({});

  const [loadingAdAccounts, setLoadingAdAccounts] = useState(false);
  const [googleUser, setGoogleUser] = useState({});
  const [googleAccounts, setGoogleAccounts] = useState([]);

  const [noAccountsLeft, setNoAccountsLeft] = useState(false);

  const [search, setSearch] = useState("");

  const filteredAdAccounts =
    search.length > 0
      ? googleAccounts.filter((o) =>
          o.descriptive_name
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.toLowerCase().replace(/ /g, ""))
        )
      : googleAccounts;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${props.token || getToken()}`,
  };

  function loadUserData() {
    // setLoading(true);
    api
      .get(`/api/usuarios.details/${props.userId || getIdUsuario()}`, {
        headers,
      })
      .then((response) => {
        // console.log(response.data[0]);
        setUser(response.data[0]);
        setBrands(response.data[0].brands);

        // console.log(
        //   "Already connected Ad Accounts: ",
        //   response.data[0].brands.flatMap((brand) => brand.ad_accounts)
        // );

        setAlreadyConnectedAddacounts(
          response.data[0].brands.flatMap((brand) => brand.ad_accounts) || []
        );

        setAdaccountsLimit(response.data[0].account_limits?.ad_accounts || 0);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function fetchGoogleAccounts(refresh_token) {
    setLoadingAdAccounts(true);

    socketRef.current.emit("aw_resources", {
      refresh_token: refresh_token,
    });
  }

  function fetchGoogleResources(refresh_token) {
    // console.log("fetching accounts...");
    const id = toast.loading("Buscando contas de anúncio...");

    setLoadingAdAccounts(true);
    api
      .post("/api/googleads/accounts", {
        refresh_token,
      })
      .then((res) => {
        // console.log(res.data);
        setGoogleAccounts(res.data);
        setLoadingAdAccounts(false);

        //Toast success
        toast.update(id, {
          render: "Contas carregadas com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoadingAdAccounts(false);
        setGoogleUser({});

        //Toast error
        toast.update(id, {
          render: "Erro ao buscar contas de anúncio",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      });
  }

  async function connectAdAccount(account) {
    const brand_id = props.brandId ? props.brandId : selectedBrand._id;

    setVerifying(true);
    const verifying = toast.loading("Verificando conta de anúncio...");

    const verification = await api.post("/api/googleads/adaccounts/verify", {
      ad_account: account,
    });

    if (verification.data.success === false) {
      setVerifying(false);
      toast.update(verifying, {
        render: (
          <>
            <b>Esta conta não pode ser conectada.</b> <br />
            <p className="mt-2">
              Por favor, verifique se existe algum erro na conta selecionada, se
              seu usuário do Google tem acesso direto a esta conta e se a conta
              está ativa.
            </p>
            <p className="mt-2">
              Você também pode tentar conectar a mesma conta por um MCC
              diferente.
            </p>
            <b className="mt-2">
              Fique a vontade para contatar nosso suporte caso precise de ajuda!
            </b>
          </>
        ),
        type: "warning",
        isLoading: false,
        autoClose: 10000,
      });
      return;
    } else {
      setVerifying(false);
      toast.update(verifying, {
        render: "Conta verificada.",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });

      if (
        user.account_limits?.ad_accounts <
        user.brands.flatMap((brand) => brand.ad_accounts).length + 1
      ) {
        console.log("Não há mais espaços para adicionar contas de anúncio");
        setNoAccountsLeft(true);
      } else {
        //Toast loading
        const id = toast.loading("Verificando dados...");

        const data = {
          data_source: "AW",
          ds_id: `${account.login_customer_id}-${account.id}`,
          name: account.descriptive_name,
          token: account.token,
        };

        api
          .post(`/api/ad-account/connect/${brand_id}`, data, {
            headers,
          })
          .then((res) => {
            if (res.status === 200) {
              //GTM - Connected Ad Account
              window.dataLayer.push({
                event: "connected_ad_account",
                data_source: "google_ads",
              });

              loadUserData();
              setsuccess_msg(true);
              //Toast success
              toast.update(id, {
                render: "Conta de Anúcios Conectada!",
                type: "success",
                isLoading: false,
                autoClose: 2000,
              });
              setRegStatus("");
            } else {
              //Toast error
              toast.update(id, {
                render:
                  "Já existe uma Conta de Anúncios cadastrada com o mesmo ID (" +
                  data.id +
                  ")",
                type: "error",
                isLoading: false,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            setRegStatus("error");
            setRegDynamicText("Verifique se você selecionou uma Empresa");
            //Toast error
            toast.update(id, {
              render:
                "Falha na sincronização. Verifique os dados, permissões e tente novamente.",
              type: "error",
              isLoading: false,
            });
          });
      }
    }
  }

  function loadGoogleUser(access_token) {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${access_token}`
      )
      .then((res) => {
        // console.log(res.data);
        setGoogleUser({ ...res.data, access_token });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onboardingProceedToNextStep() {
    if (/onboarding/.test(window.location.href)) {
      $("#step-5").fadeOut(200);
      setTimeout(() => {
        $("#step-6").fadeIn(200);
      }, 300);
    }
  }

  useEffect(() => {
    loadUserData();
  }, [props.createdBrand]);

  useEffect(() => {
    if (brands.length) {
      if (props.brandId) {
        setSelectedBrand(brands.find((brand) => brand._id === props.brandId));
      } else if (queryParams.has("brand")) {
        setSelectedBrand(
          brands.find((brand) => brand._id === queryParams.get("brand"))
        );
      } else {
        setSelectedBrand(brands[0]);
      }
    }
  }, [brands]);

  const bc = new BroadcastChannel("ga_channel");

  useEffect(() => {
    bc.onmessage = (e) => {
      // console.log(e);
      console.log("Token received with success.");
      setGoogleAuthResponse(e);
    };
  }, []);

  useEffect(() => {
    if (googleAuthResponse.refresh_token) {
      loadGoogleUser(googleAuthResponse.access_token);
      fetchGoogleResources(googleAuthResponse.refresh_token);
    } else if (
      googleAuthResponse.access_token &&
      !googleAuthResponse.refresh_token
    ) {
      alert("Erro, por favor tentar novamente");
    }
  }, [googleAuthResponse]);

  var data = brands.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  return (
    <React.Fragment>
      <HelmetProvider>
        <div className="page-content">
          <MetaTags>
            <title>Conectar Conta de Anúncios | Metrito</title>
          </MetaTags>
          <Helmet>
            <script
              async
              defer
              crossorigin="anonymous"
              src="https://connect.facebook.net/en_US/sdk.js"
            ></script>
          </Helmet>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Facebook"
              breadcrumbItem="Conectar Conta de Anúncios"
            />

            <Row
              className={classNames({
                "justify-content-center":
                  /onboarding/.test(window.location.href) ||
                  /external/.test(window.location.href),
              })}
            >
              <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                {/* {!loading && brands?.length >= 1 ? (
                  <InfoModal
                    title="Conectar Contas de Anúncio no Metrito ficou muito mais fácil!"
                    color="success"
                  />
                ) : null} */}

                <Card className="overflow-hidden">
                  {loading ? (
                    <Preloader />
                  ) : !loading && brands?.length > 0 ? (
                    <Row className="g-0">
                      <Col lg={12}>
                        <div className="p-lg-5 p-4">
                          <div className="d-flex flex-row">
                            <div>
                              <img
                                src="https://cdn-icons-png.flaticon.com/512/3128/3128285.png"
                                alt="google ads"
                                style={{ maxWidth: 50 }}
                              />
                            </div>

                            <div className="ms-3">
                              <h5>Conta de Anúncios do Google Ads</h5>
                              <p className="text-muted">
                                Conecte qualquer conta do Google Ads que você
                                tem acesso à empresas no Metrito em segundos!
                              </p>
                            </div>
                          </div>

                          <div className="mt-4 mb-2 pt-3">
                            <AvForm className="form-horizontal">
                              {success_msg ? (
                                <CustomAlert
                                  title="Perfeito!"
                                  success
                                  showCancel
                                  confirmBtnStyle={{ color: "white" }}
                                  onConfirm={() => {
                                    setsuccess_msg(false);
                                    onboardingProceedToNextStep();
                                  }}
                                  onCancel={() => {
                                    setsuccess_msg(false);
                                    onboardingProceedToNextStep();
                                  }}
                                >
                                  Sua Conta de Anúncios do <b>Google Ads</b> foi
                                  cadastrada com sucesso!
                                </CustomAlert>
                              ) : null}

                              {regStatus === "error" ? (
                                <Alert color="danger">
                                  <i className="mdi mdi-block-helper me-2"></i>
                                  {regDynamicText}
                                </Alert>
                              ) : null}

                              <div className="mb-3">
                                <Label>Empresa Vinculada</Label>

                                {queryParams.has("brand") || props.brandId ? (
                                  <Card
                                    style={{
                                      border: "none",
                                      boxShadow: "0px 0px 10px #00000012",
                                    }}
                                  >
                                    <CardBody className="d-flex flex-row">
                                      <div>
                                        <img
                                          className="me-3"
                                          src={getImageURL(
                                            selectedBrand?.logo ||
                                              "brand_placeholder.png"
                                          )}
                                          alt="Imagem da empresa"
                                          style={{
                                            borderRadius: 10,
                                            backgroundColor: "#fff",
                                            height: 40,
                                            width: 75,
                                            objectFit: "contain",
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <h6 className="fs-5 mb-0">
                                          {selectedBrand?.name}
                                        </h6>
                                        <span className="text-muted">
                                          {selectedBrand?.description}
                                        </span>
                                      </div>
                                    </CardBody>
                                  </Card>
                                ) : (
                                  <BrandPicker
                                    brands={brands}
                                    loading={loading}
                                    currentBrand={selectedBrand || null}
                                    setSelectedBrand={setSelectedBrand}
                                  />
                                )}
                              </div>

                              <Row className="d-flex justify-content-start">
                                <Col lg={6} sm={12} className="my-2">
                                  {Object.keys(googleUser).length > 0 ? (
                                    <div
                                      className="btn btn-light w-100 p-2 d-flex flex-row justify-content-center align-items-center"
                                      style={{
                                        border: "1px solid #e1e3ea",
                                        cursor: "default",
                                      }}
                                    >
                                      <img
                                        src={
                                          googleUser.picture ||
                                          "https://cdn-icons-png.flaticon.com/512/3128/3128285.png"
                                        }
                                        referrerPolicy="no-referrer"
                                        alt="google ads"
                                        style={{
                                          height: 30,
                                          borderRadius: "50%",
                                        }}
                                      />
                                      <div className="d-flex flex-column text-start ms-2">
                                        <span
                                          className="text-muted"
                                          style={{ fontSize: 12 }}
                                        >
                                          Conectado como:
                                        </span>
                                        <b>{googleUser.name}</b>
                                      </div>
                                    </div>
                                  ) : (
                                    <Button
                                      className="w-100 p-2"
                                      style={{
                                        border: "1px solid #e1e3ea",
                                        borderRadius: 10,
                                      }}
                                      onClick={() => {
                                        window.open(
                                          `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&response_type=code&client_id=402494766896-63imgi256phlpi066l6fgtki588eaeri.apps.googleusercontent.com&redirect_uri=${encodeURI(
                                            window.location.origin
                                          )}%2Fgoogleads%2Fauthorization&prompt=consent`
                                        );
                                      }}
                                    >
                                      <img
                                        src="https://cdn-icons-png.flaticon.com/512/3128/3128285.png"
                                        alt="google ads"
                                        style={{ maxWidth: 30 }}
                                      />
                                      <span className="align-middle ms-2">
                                        Continuar com o Google
                                      </span>
                                    </Button>
                                  )}
                                </Col>

                                <ExternalAdAccountConnectModal
                                  brandId={selectedBrand?._id}
                                  dataSource={"google"}
                                />
                              </Row>
                            </AvForm>
                          </div>

                          {Object.keys(googleUser).length > 0 ? (
                            <Card>
                              <CardBody className="p-3">
                                <div className="d-flex justify-content-between">
                                  <h6 className="m-2 text-truncate">
                                    {googleAccounts?.length} Contas de Anúncio
                                    encontradas
                                  </h6>
                                  <Input
                                    style={{ maxWidth: 300 }}
                                    placeholder="Pesquisar..."
                                    value={search}
                                    onChange={(e) => setSearch(e)}
                                  />
                                </div>
                                {loadingAdAccounts ? (
                                  <div className="text-center">
                                    <Preloader />
                                    <span className="align-center text-muted">
                                      Carregando contas de anúncio, isso pode
                                      levar até 1 minuto...
                                    </span>
                                  </div>
                                ) : !loadingAdAccounts &&
                                  googleAccounts?.length >= 1 ? (
                                  <div
                                    className="mt-4"
                                    style={
                                      /onboarding/.test(window.location.href)
                                        ? {
                                            maxHeight: 200,
                                            overflowY: "scroll",
                                          }
                                        : {
                                            maxHeight: 500,
                                            overflowY: "scroll",
                                          }
                                    }
                                  >
                                    {filteredAdAccounts.map((adaccount) => (
                                      <div key={adaccount.id}>
                                        {adaccount.manager === false ? (
                                          <Card className="adaccount-item">
                                            <CardBody className="d-flex justify-content-between">
                                              <div>
                                                <h6>
                                                  {adaccount.descriptive_name}
                                                </h6>
                                                <span
                                                  style={{ color: "#727584" }}
                                                >
                                                  {adaccount.id
                                                    .toString()
                                                    .match(
                                                      /\d{3}(?=\d{2,3})|\d+/g
                                                    )
                                                    .join("-")}
                                                </span>
                                              </div>
                                              <div>
                                                {alreadyConnectedAddacounts.some(
                                                  (connected_account) =>
                                                    connected_account?.ds_id.split(
                                                      "-"
                                                    )[1] ===
                                                    String(adaccount.id)
                                                ) ? (
                                                  <button className="btn btn-light disabled">
                                                    <i className="bx bx-check-circle fs-5 align-middle me-2"></i>
                                                    <span className="align-middle">
                                                      Adicionada
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    className="btn btn-success"
                                                    onClick={() =>
                                                      connectAdAccount(
                                                        adaccount
                                                      )
                                                    }
                                                    disabled={verifying}
                                                  >
                                                    {verifying ? (
                                                      <Loader inverse />
                                                    ) : (
                                                      <>
                                                        <i className="bx bx-circle fs-5 align-middle me-2"></i>
                                                        <span className="align-middle">
                                                          Adicionar
                                                        </span>
                                                      </>
                                                    )}
                                                  </button>
                                                )}
                                              </div>
                                            </CardBody>
                                          </Card>
                                        ) : (
                                          <Panel
                                            className="card"
                                            header={
                                              <div className="d-flex justify-content-between">
                                                <div>
                                                  <h6>
                                                    {adaccount.descriptive_name}
                                                  </h6>
                                                  <span
                                                    style={{ color: "#727584" }}
                                                  >
                                                    {" "}
                                                    <Tag
                                                      size="sm"
                                                      className="me-2"
                                                      color="blue"
                                                    >
                                                      MCC
                                                    </Tag>
                                                    {adaccount.id
                                                      .toString()
                                                      .match(
                                                        /\d{3}(?=\d{2,3})|\d+/g
                                                      )
                                                      .join("-")}
                                                  </span>
                                                </div>
                                                <div>
                                                  <span
                                                    className="text-muted"
                                                    style={{
                                                      fontSize: 12,
                                                      marginRight: 20,
                                                    }}
                                                  >
                                                    {
                                                      adaccount.child_accounts
                                                        .length
                                                    }{" "}
                                                    contas
                                                  </span>
                                                </div>
                                              </div>
                                            }
                                            collapsible
                                            defaultExpanded={false}
                                            bordered
                                          >
                                            <div className="d-flex flex-column justify-content-between">
                                              <div className="mt-3">
                                                {adaccount.child_accounts.map(
                                                  (child) => (
                                                    <Card className="adaccount-item">
                                                      <CardBody className="d-flex justify-content-between">
                                                        <div>
                                                          <b className="fs-6 d-blocktext-truncate">
                                                            {
                                                              child.descriptive_name
                                                            }
                                                          </b>
                                                          <span
                                                            className="d-block"
                                                            style={{
                                                              color: "#727584",
                                                            }}
                                                          >
                                                            {child.id
                                                              .toString()
                                                              .match(
                                                                /\d{3}(?=\d{2,3})|\d+/g
                                                              )
                                                              .join("-")}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          {alreadyConnectedAddacounts.some(
                                                            (
                                                              connected_account
                                                            ) =>
                                                              connected_account?.ds_id.split(
                                                                "-"
                                                              )[1] ===
                                                              String(child.id)
                                                          ) ? (
                                                            <button className="btn btn-light disabled">
                                                              <i className="bx bx-check-circle fs-5 align-middle"></i>
                                                              <span className="align-middle"></span>
                                                            </button>
                                                          ) : (
                                                            <button
                                                              className="btn btn-success"
                                                              style={{
                                                                width: 120,
                                                              }}
                                                              onClick={() =>
                                                                connectAdAccount(
                                                                  child
                                                                )
                                                              }
                                                              disabled={
                                                                verifying
                                                              }
                                                            >
                                                              {verifying ? (
                                                                <Loader
                                                                  inverse
                                                                />
                                                              ) : (
                                                                <>
                                                                  <i className="bx bx-circle fs-5 align-middle me-2"></i>
                                                                  <span className="align-middle">
                                                                    Adicionar
                                                                  </span>
                                                                </>
                                                              )}
                                                            </button>
                                                          )}
                                                        </div>
                                                      </CardBody>
                                                    </Card>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </Panel>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <span></span>
                                )}

                                {googleAccounts?.length >= 1 && (
                                  <div className="d-flex justify-content-center mt-3">
                                    <div
                                      style={{
                                        backgroundColor: "#F56E5020",
                                        borderRadius: 10,
                                        color: "#F56E50",
                                        fontSize: 12,
                                      }}
                                      className="px-4 py-2"
                                    >
                                      Atenção: contas de anúncio canceladas ou
                                      bloqueadas não aparecerão na lista de
                                      acordo com as políticas da Google Ads API.
                                    </div>
                                  </div>
                                )}
                              </CardBody>
                            </Card>
                          ) : null}

                          <div className="mt-4 text-start">
                            <p className="mb-0 text-muted">
                              Nós não utilizamos nenhum de seus dados como
                              apontado na nossa{" "}
                              <a
                                href="https://metrito.com/politica-de-privacidade"
                                target="_blank"
                                className="text-primary fw-semibold text-decoration-underline"
                              >
                                Política de Privacidade
                              </a>
                              &nbsp; <br /> Sua conta é protegida por{" "}
                              <b>Criptografia.</b>{" "}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : getIdUsuario() && !loading && brands?.length === 0 ? (
                    <CardBody>
                      <BrandNotFound />
                    </CardBody>
                  ) : (
                    <CardBody>
                      <Row className="d-flex justify-content-center text-center p-5">
                        <Col lg={8}>
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/4428/4428490.png"
                            alt="Você precisa criar uma empresa"
                            style={{ maxWidth: 100, marginBottom: 30 }}
                          />
                          <h4>Ops! Houve um problema..</h4>
                          <p>
                            Por favor, confira seu link, sua conexão com a
                            internet e tente novamente.
                          </p>
                          <button
                            className="btn btn-dark"
                            onClick={() => window.location.reload()}
                          >
                            Recarregar página
                          </button>
                        </Col>
                      </Row>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </HelmetProvider>
      <NoAdAccountsLeftModal
        visible={noAccountsLeft}
        setVisible={setNoAccountsLeft}
        ad_accounts_limit={adaccountsLimit}
        connected_ad_accounts={alreadyConnectedAddacounts.length}
      />
    </React.Fragment>
  );
};

export default ConnectGoogleAdsAdAccount;
