import React from "react";
import { Card } from "reactstrap";

const BlurLoader = (props) => {
  return (
    <React.Fragment>
      {props.active ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            backdropFilter: "blur(5px)",
            position: "absolute",
            top: 0,
            bottom: 0,
            width: "100%",
            zIndex: 10,
            borderRadius: 10,
          }}
        >
          <Card className="d-flex flex-column justify-content-center align-items-center p-4 px-5 shadow-lg">
            <i className="mdi mdi-spin mdi-loading fs-1" />
            <h5>{props.text}</h5>
          </Card>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default BlurLoader;
