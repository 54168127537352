import moment from "moment";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Button, Input, Loader } from "rsuite";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";
import CreateNewAPITokenModal from "./CreateNewAPIToken.modal";

const image_url =
  "https://metrito-public.s3.sa-east-1.amazonaws.com/photos/equipe-metrito-sp.jpg";

const MetritoAPI = () => {
  const [user, setUser] = useState({});
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);

  function loadUserData() {
    setLoading(true);
    api
      .get(`/api/usuarios.details/${getIdUsuario()}`)
      .then((response) => {
        setUser(response.data[0]);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  async function loadUserTokens() {
    setLoading(true);
    await api
      .get(`/api/v1/user/tokens/${getIdUsuario()}`)
      .then((res) => {
        setTokens(res.data);
      })
      .catch((err) => {
        toast.error("Erro ao carregar os tokens de acesso da API");
      });

    setLoading(false);
  }

  async function removeApiToken(token) {
    await api
      .delete(`/api/v1/user/tokens/remove/${getIdUsuario()}/${token._id}`)
      .then((res) => {
        toast.success("Token removido com sucesso!");
        loadUserTokens();
      })
      .catch((err) => {
        toast.error("Erro ao remover o token de acesso da API");
      });
  }

  useEffect(() => {
    loadUserData();
    loadUserTokens();
  }, []);

  var has_api_access = user?.api_access === true;

  return (
    <>
      <MetaTags>
        <title>Metrito API | Metrito</title>
      </MetaTags>

      <div className="page-content">
        <Container>
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="mb-0">Tokens de acesso da API</h4>
                    {!loading && tokens.length > 0 && has_api_access && (
                      <CreateNewAPITokenModal loadUserTokens={loadUserTokens} />
                    )}
                  </div>
                  <p>
                    Você irá precisar de um token de acesso para realizar
                    qualquer operação nos endpoints que disponibilizamos na
                    nossa API oficial.
                  </p>
                  <p>
                    Os tokens gerados não expiram e não devem ser armazenados de
                    forma pública.{" "}
                    <b>Nunca compartilhe seus tokens, trate eles como senhas</b>
                    .
                  </p>

                  {!loading && has_api_access && tokens.length > 0 ? (
                    <div className="mt-5">
                      {tokens.map((token, index) => {
                        return (
                          <Card className="p-3 my-2" key={index}>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <div>
                                <small>Nome</small>
                                <h6 className="m-0">{token.name}</h6>
                              </div>

                              <div className="d-flex align-items-center">
                                <small>
                                  Criado em:{" "}
                                  {moment(token.created_at).format(
                                    "DD MMM YYYY"
                                  )}
                                </small>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    role="button"
                                    tag="div"
                                    className="d-flex align-items-center"
                                  >
                                    <i className="bx bx-dots-vertical-rounded text-muted i-btn fs-5 ms-2"></i>
                                  </DropdownToggle>
                                  <DropdownMenu className="text-muted dropdown-menu-end">
                                    <DropdownItem
                                      className="d-flex align-items-center text-danger"
                                      onClick={() => removeApiToken(token)}
                                    >
                                      <i className="bx bx-trash me-2" />
                                      Remover token
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mt-2">
                              <Input
                                className="w-100 text-truncate"
                                style={{ textAlign: "left" }}
                                value={token.token}
                              ></Input>
                              <Button
                                className="ms-2 px-2"
                                style={{ minWidth: 100 }}
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(token.token)
                                    .then(() => {
                                      toast.success("Token copiado!");
                                    })
                                    .catch(() => {
                                      toast.error("Erro ao copiar!");
                                    });
                                }}
                              >
                                <i className="mdi mdi-clipboard-outline me-2" />
                                Copiar
                              </Button>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  ) : !loading && has_api_access && tokens.length === 0 ? (
                    <div className="text-center mt-5">
                      <h5 className="text-muted">Nenhum token encontrado</h5>
                      <p className="text-muted">
                        Clique no botão abaixo para criar um novo token de
                        acesso.
                      </p>
                      <CreateNewAPITokenModal loadUserTokens={loadUserTokens} />
                    </div>
                  ) : !loading && has_api_access === false ? (
                    <div
                      className="mt-5"
                      style={{
                        border: "1px dashed #ebebeb",
                        padding: 20,
                        borderRadius: 10,
                      }}
                    >
                      <h5 className="mb-3">Você não tem acesso a API</h5>
                      <p>
                        Você precisa estar em um plano que tenha acesso a API
                        (Plano Escala) e ter ativado o acesso a API em sua
                        conta.
                      </p>
                      <Link to="/planos">
                        <Button color="primary">Ver planos</Button>
                      </Link>
                    </div>
                  ) : (
                    <>
                      <Loader className="me-2" />
                      carregando tokens...
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardBody>
                  <img
                    src={image_url}
                    alt="metrito_banner"
                    style={{
                      overflow: "hidden",
                      maxHeight: 180,
                      width: "100%",
                      objectFit: "cover",
                      zoom: 1.02,
                      borderRadius: 10,
                    }}
                  />
                  <h5 className="mt-3">Conte conosco!</h5>
                  <p>
                    Estamos a disposição para ajudar você e sua operação a
                    crescer muito! Para mais informações sobre nossa API,
                    consulte nossa documentação ou entre em contato com nosso
                    time de suporte.
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => window.open("/enterprise/api/docs")}
                  >
                    Ver Documentação Completa
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MetritoAPI;
