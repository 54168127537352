import React, { useEffect, useState } from "react";

import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { CardBody, CardTitle } from "reactstrap";
import { defineMetric } from "../functions/metricFormatter";

const AreaChart = (props) => {
  const [metric, setMetric] = useState();

  var options = {
    chart: {
      height: 290,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    annotations: {
      yaxis:
        props.selected.lines?.map((line) => ({
          y: line.limit,
          borderColor: line.color || "#000",
          strokeDashArray: 5,
          label: {
            borderColor: line.color || "#000",
            style: {
              color: "#fff",
              background: line.color || "#000",
            },
            text: line.text,
          },
        })) || [],
    },
    labels: props.metrics.map((a) =>
      moment(a.date_start).toLocaleString("pt-BR")
    ),
    colors: ["#0081f9", "#00E396", "#CED4DC"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    xaxis: {
      type: "datetime",
      tickPlacement: "on",
    },
    yaxis: {
      labels: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
  };
  const series = [
    {
      name: props.selected.title,
      data: metric || [],
    },
  ];

  useEffect(() => {
    setMetric(defineMetric(props.metrics, props.selected.type));
  }, [props]);

  return (
    <React.Fragment>
      <CardBody>
        <CardTitle className="mb-2">{props.selected.title}</CardTitle>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height="250"
        />
      </CardBody>
    </React.Fragment>
  );
};

export default AreaChart;
