import React from "react";
import Board from "react-trello";
import Header from "../../components/HorizontalLayout/Header";
import { Modal, Button, Placeholder } from "rsuite";
import {
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import { getImageURL } from "../../functions/getImageURL";

// const data = {
//   lanes: [
//     {
//       id: "lane1",
//       title: "Planned Tasks",
//       label: "2/2",
//       cards: [
//         {
//           id: "923804293",
//           title: "Write Blog",
//           description: "Can AI make memes",
//           label: "30 mins",
//           tags: [
//             {
//               label: "Tráfego",
//               color: "#5BA4CF",
//             },
//             {
//               label: "Importante",
//               color: "#F5DD29",
//             },
//             {
//               label: "Teste de tamanho de tag pra ver se cabe",
//               color: "#6DEBA8",
//             },
//           ],
//         },
//         {
//           id: "5749860394",
//           title: "Pay Rent",
//           description: "Transfer via NEFT",
//           label: "5 mins",
//         },
//       ],

//       style: {
//         backgroundColor: "#EBECF0",
//         color: "#172B4D",
//         borderRadius: 5,
//       },
//     },
//     {
//       id: "lane2",
//       title: "Completed",
//       label: "0/0",
//       cards: [],

//       style: {
//         backgroundColor: "#EBECF0",
//         color: "#172B4D",
//         borderRadius: 5,
//       },
//     },
//   ],
// };

const data = JSON.parse(window.localStorage.getItem("board-data")) || {
  lanes: [],
};

function saveToLocalStorage(data) {
  if (typeof window !== "undefined") {
    localStorage.setItem("board-data", JSON.stringify(data));
  }
}

const CustomCard = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Modal open={open} onClose={handleClose} size="sm">
        <Modal.Header>
          <div className="d-flex">
            <div>
              <b className="fs-4 d-block">
                <i className="mdi mdi-card-bulleted-outline me-3 fs-3" />
              </b>
            </div>
            <div>
              <b className="fs-4 d-block">{props.title} </b>
              <span className="d-block text-muted" style={{ fontSize: 14 }}>
                da lista{" "}
                {data.lanes?.find((o) => o.id === props.laneId).title || ""}
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="w-100">
            <Col lg={12}>
              <div id="card-tags" className="mb-4">
                <div>
                  {props.tags?.map((tag) => (
                    <span
                      className="badge me-1 px-2 pe-3 text-dark text-truncate"
                      style={{
                        backgroundColor: `${tag.color}60`,
                        fontSize: 14,
                        lineHeight: 0.16,
                        maxWidth: "100%",
                        padding: 5,
                        height: 24,
                      }}
                    >
                      <i
                        className="bx bxs-circle me-1"
                        style={{ color: tag.color }}
                      />
                      {tag.label}
                    </span>
                  ))}
                </div>

                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn btn-light dropdown-toggle text-reset"
                    tag="a"
                  >
                    <i className="bx bx-plus" style={{ color: "#172B4D" }} />
                    Adicionar tag
                  </DropdownToggle>

                  <DropdownMenu
                    className="dropdown-menu"
                    style={{ width: 250 }}
                  >
                    <div className="px-3 pt-2">
                      <h5>Etiquetas</h5>
                    </div>
                    <hr className="divider" />
                    <DropdownItem>Etiqueta</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div id="card-description" className="mb-4">
                <b className="fs-5 d-flex align-items-center mb-2">
                  <i className="mdi mdi-text-subject me-3 fs-3" />
                  Descrição
                </b>
                <textarea
                  className="form-control"
                  placeholder="Insira uma descrição detalhada para o card..."
                  id="floatingTextarea"
                ></textarea>
              </div>

              <div id="card-activity" className="mb-4">
                <b className="fs-5 d-flex align-items-center mb-2">
                  <i className="mdi mdi-comment-text-multiple-outline me-3 fs-3" />
                  Atividade
                </b>
                <div className="d-flex align-items-center">
                  <img
                    src={getImageURL(
                      window.localStorage.getItem("&profile-pic-id")
                    )}
                    alt=""
                    className="rounded-circle avatar-sm me-2"
                  />
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Insira um comentário..."
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <div
        className="kanban-card py-2"
        style={{
          backgroundColor: "#fff",
          width: 250,
          padding: 10,
          marginBottom: 10,
          borderRadius: 10,
          boxShadow: "0px 1px 0px lightgray",
        }}
        onClick={handleOpen}
      >
        <div className="d-flex justify-content-between align-items-start">
          <div style={{ minWidth: 0 }} className="flex-wrap">
            {props.tags?.map((tag) => (
              <span
                className="badge me-1 px-1 pe-2 text-dark text-truncate"
                style={{
                  backgroundColor: `${tag.color}60`,
                  fontSize: 12,
                  lineHeight: 0.16,

                  maxWidth: "100%",
                }}
              >
                <i
                  className="bx bxs-circle me-1"
                  style={{ color: tag.color }}
                />
                {tag.label}
              </span>
            ))}
            <div className="d-flex justify-content-between align-items-center">
              <span style={{ fontSize: 14, fontWeight: 500 }}>
                {props.title}
              </span>
            </div>

            {/* <div className="mt-2">
            <span style={{ fontSize: 14 }}>{props.description}</span>
          </div> */}
          </div>
          <div className="ps-2 text-end">
            <button
              type="button"
              style={{ backgroundColor: "transparent", color: "gray" }}
              onClick={() => props.onDelete(props.id)}
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const AddCardLink = (props) => {
  return (
    <button
      className=" py-1 kanban-add-card"
      style={{ color: "#5e6c84" }}
      onClick={() => props.onClick()}
    >
      <i className="mdi mdi-plus me-1" />
      Adicionar card
    </button>
  );
};

const NewCardForm = (props) => {
  const [title, setTitle] = React.useState("");
  return (
    <>
      <form>
        <div
          className="kanban-card py-2"
          style={{
            backgroundColor: "#fff",
            width: 250,
            marginBottom: 10,
            borderRadius: 10,
            boxShadow: "0px 1px 0px lightgray",
          }}
        >
          <input
            value={title}
            type="textarea"
            style={{
              border: "none",
              width: "100%",
              height: "100%",
              fontWeight: 500,
            }}
            className="form-control"
            placeholder="Insira o título do card..."
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div>
          <button
            className="btn btn-success px-2 py-1"
            onClick={() => props.onAdd({ title })}
          >
            Adicionar
          </button>
          <button
            className="btn btn-muted px-2 py-1"
            onClick={() => props.onCancel()}
          >
            Cancelar
          </button>
        </div>
      </form>
    </>
  );
};

const NewLaneSection = (props) => {
  return (
    <>
      <button
        className="py-1"
        style={{
          color: "#5e6c84",
          backgroundColor: "#ffffff3d",
          color: "#fff",
          fontSize: 14,
          textAlign: "start",
          borderRadius: 5,
          width: 270,
          margin: 10,
          marginTop: 5,
          height: 40,
        }}
        onClick={() => props.onClick()}
      >
        <i className="mdi mdi-plus me-1" />
        Adicionar coluna
      </button>
    </>
  );
};

const NewLaneForm = (props) => {
  const [title, setTitle] = React.useState("");
  return (
    <div
      style={{
        backgroundColor: "#EBECF0",
        marginTop: 5,
        marginLeft: 10,
        borderRadius: 5,
        padding: 10,
      }}
    >
      <form>
        <div
          style={{
            width: 250,
          }}
        >
          <input
            value={title}
            type="textarea"
            style={{
              border: "none",
              width: "100%",
              height: "100%",
              fontWeight: 500,
              backgroundColor: "transparent",
            }}
            className="form-control"
            placeholder="Insira o título da coluna..."
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="mt-1">
          <button
            className="btn btn-success px-2 py-1"
            onClick={() =>
              props.onAdd({ id: new Date().getTime(), title: title })
            }
          >
            Adicionar
          </button>
          <button
            className="btn btn-muted px-2 py-1"
            onClick={() => props.onCancel()}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};

const LaneHeader = (props) => {
  return (
    <div className="w-100 d-flex justify-content-between p-2">
      <h6 className="mb-1">{props.title}</h6>
      <div className="float-end">
        <UncontrolledDropdown>
          <DropdownToggle className="dropdown-toggle text-reset" tag="a">
            <i className="bx bx-dots-horizontal-rounded text-muted i-btn fs-4 me-2"></i>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-end">
            <DropdownItem
              onClick={() => {
                props.onDelete(props.id);
              }}
            >
              Apagar coluna
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  );
};

const components = {
  Card: CustomCard,
  AddCardLink: AddCardLink,
  LaneHeader: LaneHeader,
  NewLaneSection: NewLaneSection,
  NewLaneForm: NewLaneForm,
  NewCardForm,
};

const Kanban = (props) => {
  return (
    <>
      <div style={{ marginTop: 105, marginBottom: 60 }}>
        <Board
          style={{
            backgroundColor: "#172B4D",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            padding: 20,
          }}
          canAddLanes
          draggable
          cardDraggable
          editable
          data={data}
          components={components}
          onDataChange={(data) => {
            console.log(data);
            saveToLocalStorage(data);
          }}
        />
      </div>
    </>
  );
};

export default Kanban;
