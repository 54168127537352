import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import { defineMetric } from "../functions/metricFormatter";

const ColumnChart = (props) => {
  const [metric, setMetric] = useState();

  var series = [
    {
      name: props.selected.title,
      data: metric || [],
    },
  ];

  var options = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "90%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: props.metrics.map((a) =>
      moment(a.date_stop).toLocaleString("pt-BR")
    ),
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#3167ED", "#0ab39c", "#f06548"],
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
  };

  useEffect(() => {
    setMetric(defineMetric(props.metrics, props.selected.type));
  }, [props.metrics]);

  return (
    <React.Fragment>
      <Col lg={6} xl={6}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.selected.title}</CardTitle>

            <ReactApexChart
              options={options}
              series={series}
              type={options.chart.type}
              height={350}
              className="apex-charts"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ColumnChart;
