import React, { useState, useEffect } from "react";
import {
  Modal,
  ButtonToolbar,
  Button,
  RadioGroup,
  Radio,
  Placeholder,
  MaskedInput,
} from "rsuite";
import api from "../../../services/api";
import { getIdUsuario } from "../../../services/auth";
import { toast } from "react-toastify";
import axios from "axios";
const styles = {
  radioGroupLabel: {
    padding: "8px 12px",
    display: "inline-block",
    verticalAlign: "middle",
  },
};

const ChangePhoneNumberModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const [backdrop, setBackdrop] = React.useState("static");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState("");

  function updateUser() {
    //Toast loading
    const id = toast.loading("Realizando operação...");

    const data = {
      _id: getIdUsuario(),
      tel_number: value,
    };

    api
      .put("/api/usuarios", data)
      .then((response) => {
        if (response.status === 200) {
          //Toast success
          toast.update(id, {
            render: "Dados atualizados!",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });

          props.loadUserData();
          setTimeout(() => handleClose(), 1000);
        }
      })
      .catch((error) => {
        //Toast success
        toast.update(id, {
          render: "Erro na operação",
          type: "warning",
          isLoading: false,
          autoClose: 1000,
        });
      });
  }

  return (
    <>
      <Button
        appearance="default"
        style={{ backgroundColor: "#ebebeb" }}
        onClick={handleOpen}
      >
        Alterar número de telefone
      </Button>

      <Modal
        backdrop={backdrop}
        keyboard={false}
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Alterar número de telefone</Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-1">
          <small>Novo número</small>
          <MaskedInput
            value={value}
            mask={[
              "(",
              /[1-9]/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            guide={true}
            showMask={false}
            keepCharPositions={true}
            placeholder={"(00) 00000-0000"}
            placeholderChar={"\u2000"}
            style={{ width: 300 }}
            onChange={setValue}
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={updateUser} appearance="primary">
            Salvar
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangePhoneNumberModal;
