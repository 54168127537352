import React from "react";

import { Handle, Position } from "react-flow-renderer";

export default function GoogleNode({ data }) {
  return (
    <div className="px-3 bg-success" style={{ borderRadius: 10, padding: 0 }}>
      <div>
        <i className="mdi mdi-google-ads text-white fs-1"></i>
      </div>
      <Handle type="input" position={Position.Left} id="a" />
      <Handle type="output" position={Position.Right} id="a" />
    </div>
  );
}
