import React, { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { Button } from "rsuite";
import api from "../../services/api";

const cardStyles = {
  backgroundColor: "#d30c1d",
  color: "#fff",
  minHeight: 60,
  position: "fixed",
  bottom: 20,
  left: "10vw",
  right: "10vw",
  zIndex: 9999,
  boxShadow: "0 0 20px 0 rgba(0,0,0,.2)",
  padding: "10px 20px",
};

const PastDuePaymentBar = (props) => {
  const [loading, setLoading] = useState(false);

  function createPortalSession(customer_id) {
    setLoading(true);
    api
      .post("/create-portal-session", {
        customer_id,
      })
      .then((res) => {
        window.open(res.data.url);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <>
      <Card style={cardStyles} className="d-flex justify-content-between">
        <Row>
          <Col lg={10} className="d-flex flex-column">
            <b className="fs-6">Tivemos um pequeno problema.</b>
            <span>
              O pagamento do seu plano não foi efetuado e o seu acesso pode ser
              interrompido. Por favor verifique se os dados estão corretos ou
              entre{" "}
              <a
                target={"_blank"}
                href="https://api.whatsapp.com/send?phone=556297011897&text=Ol%C3%A1%2C%20gostaria%20de%20reativar%20minha%20conta%20%F0%9F%98%80"
                style={{ color: "#fff", textDecorationLine: "underline" }}
              >
                em contato com o suporte
              </a>
              .
            </span>
          </Col>
          <Col lg={2} className="d-flex align-items-center justify-content-end">
            <Button
              className="w-10 px-2"
              appearance="default"
              onClick={() => createPortalSession(props.subscription.customer)}
            >
              Gerenciar Assinatura
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default PastDuePaymentBar;
