import React, { useState, useRef } from "react";
import { Col, Input, Label, Modal, Row } from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

//Toast
import { toast } from "react-toastify";

import InputMask from "react-input-mask";
import { getImageURL } from "../../functions/getImageURL";

//services
import classNames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import { category_options } from "../../common/data/category_options";
import UploadFileModal from "../../components/Common/UploadFile.modal";
import api from "../../services/api";
import CustomAlert from "../../components/CustomAlert";

const EditBrandModal = (props) => {
  const formRef = useRef();
  const updateButtonRef = useRef();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [brandImage, setBrandImage] = useState(null);
  const [category, setCategory] = useState({});
  const [client_revenue, setClient_revenue] = useState(0);
  const [client_revenueFormat, setClient_revenueFormat] = useState("");

  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [dynamic_description, setdynamic_description] = useState("");

  const [modal_xlarge, setmodal_xlarge] = useState(props.isModalOpen);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  async function handleSubmit(event) {
    setLoading(true);
    //Toast loading
    const id = toast.loading("Realizando operação...");

    const data = {
      name: event.target.brand_name.value,
      description: event.target.brand_description.value,
      color: document.getElementById("color-input").value,
      cnpj: event.target.brand_cnpj.value,

      client_revenue: client_revenue,
      client_revenue_format: client_revenueFormat,

      category: category.value,
    };

    const response = await api.put("/api/v1/brands/" + props.brand._id, data);

    if (response.status === 200) {
      if (brandImage) {
        const formData = new FormData();
        formData.append("image", brandImage);

        const aws_response = await api.put(
          `/api/v1/brands/logo/${response.data._id}`,
          formData
        );

        console.log(aws_response);
      }

      setTimeout(() => {
        tog_xlarge();

        props.loadBrandData();
        setLoading(false);
      }, 1000);

      //Toast success
      toast.update(id, {
        render: "Dados atualizados!",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
    } else {
      //Toast success
      toast.update(id, {
        render: "Erro na operação",
        type: "warning",
        isLoading: false,
        autoClose: 1000,
      });
    }
  }

  async function handleDelete(id) {
    console.log("Deleting brand with ID: " + id);
    setDeleting(true);
    try {
      const result = await api.delete("/api/v1/brands/" + id);
      if (result.status === 200) {
        console.log("Empresa apagada com sucesso");
        setsuccess_dlg(true);
        setDeleting(false);
      } else {
        console.log("Erro ao apagar empresa");
        setDeleting(false);
      }
    } catch (err) {
      console.log(err);
      setDeleting(false);
    }
  }

  return (
    <React.Fragment>
      {confirm_alert ? (
        <CustomAlert
          title="Tem certeza?"
          warning
          showCancel
          confirmButtonText="Sim, quero apagar!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          cancelBtnText="Cancelar"
          onConfirm={() => {
            setconfirm_alert(false);
            setdynamic_title("Empresa Removida");
            setdynamic_description(
              `A empresa ${props.brand.name} foi removida com sucesso.`
            );
            handleDelete(props.brand._id);
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Todos os dados vinculados a empresa serão apagados de forma
          irreversível!
        </CustomAlert>
      ) : null}
      {success_dlg ? (
        <CustomAlert
          success
          confirmBtnStyle={{ color: "white" }}
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false);
            window.location.href = "/home";
          }}
        >
          {dynamic_description}
        </CustomAlert>
      ) : null}

      <Col lg={12}>
        <Modal
          className="modal-dialog-centered"
          size="xl"
          isOpen={modal_xlarge}
          toggle={() => {
            tog_xlarge();
          }}
        >
          <Row className="g-0">
            <Col lg={12}>
              <div className="p-lg-5 p-4">
                <div>
                  <h5>Atualizar Informações</h5>
                  <h4>{props.test}</h4>
                </div>

                <div className="pt-3">
                  <div className="mb-4 d-flex flex-row align-items-center w-100">
                    <div className="d-flex justify-content-center">
                      <img
                        src={
                          !brandImage
                            ? getImageURL(props.brand.logo)
                            : URL.createObjectURL(brandImage)
                        }
                        alt="Imagem da empresa"
                        style={{
                          borderRadius: 10,
                          backgroundColor: "#fff",
                          height: 80,
                          width: 150,
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div>
                      <UploadFileModal
                        button_text={"Editar logo da empresa"}
                        image_uri={props.brand.logo}
                        type={"brand"}
                        setBrandImage={setBrandImage}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4 pt-3">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={handleSubmit}
                    ref={formRef}
                  >
                    <div className="mb-3">
                      <AvField
                        name="brand_name"
                        label="Nome"
                        type="text"
                        required
                        placeholder="Insira seu nome"
                        value={props.brand.name}
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "Digite como deseja ver sua Conta de Anúncios",
                            pattern: {
                              value: "^[A-Za-z0-9]+$",
                              errorMessage:
                                "O nome deve ser formado apenas de letras e números",
                            },
                          },
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="brand_description"
                        label="Descrição da Empresa"
                        type="text"
                        placeholder="Escreva uma descrição para a empresa"
                        value={props.brand.description}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="brand_cnpj"
                        label="CNPJ"
                        placeholder="Insira o CNPJ da Empresa"
                        mask="99.999.999/9999-99"
                        maskChar=""
                        tag={[Input, InputMask]}
                        value={props.brand.cnpj}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="example-color-input" className="form-label">
                        Cor da Empresa
                      </Label>
                      <input
                        className="form-control form-control-color mw-100"
                        type="color"
                        defaultValue={props.brand.color}
                        id="color-input"
                      />
                    </div>

                    <Row className="mb-3">
                      <Label>Categoria</Label>
                      {category_options.map((option, key) => (
                        <Col lg={4} md={4} sm={12} xs={12} key={key}>
                          <div
                            className={classNames(
                              {
                                "privacy-selected":
                                  option.value === category.value,
                              },
                              "privacy-selector p-2 d-flex flex-row align-items-center"
                            )}
                            onClick={() => {
                              setCategory(option);
                            }}
                          >
                            <div className="d-flex">
                              <i
                                className={`${option.icon} text-dark mx-2 fs-3 align-top`}
                              />
                            </div>
                            <div className="text-truncate">
                              <span
                                style={{
                                  fontSize: 14,
                                  display: "block",
                                  fontWeight: 600,
                                }}
                                className="mb-0 text-dark"
                              >
                                {option.label}
                              </span>
                              <span
                                className="text-muted"
                                style={{ fontSize: 11 }}
                              >
                                {option.description}
                              </span>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>

                    {/* {category.value === "client" ? (
                      <Col>
                        <div className="brand-category-box mt-4">
                          <h5>Cliente</h5>
                          <div className="mb-3">
                            <Label className="form-label">Receita</Label>
                            <div className="input-group mb-3">
                              <InputMask
                                className="form-control"
                                type="text"
                                placeholder="Enter Number"
                                mask="R$ 99999999999999999999999999"
                                maskChar=""
                                value={props.brand.client_revenue}
                                onChange={(e) =>
                                  setClient_revenue(
                                    parseFloat(
                                      e.target.value.replace(/\D/g, "")
                                    )
                                  )
                                }
                              />
                              <select
                                className="form-select"
                                style={{
                                  borderRadius: "0px 10px 10px 0px",
                                }}
                                value={props.brand.client_revenue_format}
                                onChange={(e) =>
                                  setClient_revenueFormat(e.target.value)
                                }
                              >
                                <option>Selecione a frequência</option>
                                <option>Mensalmente</option>
                                <option>Semanalmente</option>
                                <option>Diariamente</option>
                              </select>
                            </div>
                          </div>

                          <h5 className="mt-4">
                            Este cliente lhe gera
                            <b>
                              <h4 className="d-inline text-success mx-2">
                                {(client_revenue || 0).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                              </h4>
                              {client_revenueFormat.toLocaleLowerCase()}.
                            </b>
                          </h5>
                        </div>
                      </Col>
                    ) : null} */}

                    <div className="mt-5">
                      <button
                        className="btn btn-danger w-sm waves-effect waves-light btn-100 px-5"
                        type="button"
                        disabled={deleting}
                        onClick={(event) => {
                          setconfirm_alert(true);
                        }}
                      >
                        {deleting ? (
                          <i className="mdi mdi-loading mdi-spin fs-6 me-3" />
                        ) : null}
                        <b className="text-white">
                          <i className="mdi mdi-trash-can text-white" /> Apagar
                          Empresa
                        </b>
                      </button>
                      <button
                        className="btn btn-primary w-sm waves-effect waves-light btn-100 float-end px-5"
                        type="submit"
                        disabled={loading}
                        ref={updateButtonRef}
                      >
                        {loading ? (
                          <i className="mdi mdi-loading mdi-spin fs-6 me-3" />
                        ) : null}
                        <b className="text-white">Atualizar Dados</b>
                      </button>
                    </div>
                  </AvForm>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      </Col>

      <button
        className="btn btn-light px-5"
        onClick={() => {
          setCategory(
            category_options.find(
              (option) => option.value === props.brand.category
            ) || ""
          );
          tog_xlarge();
        }}
      >
        <i className="mdi mdi-briefcase-edit-outline me-2" />
        Editar Empresa
      </button>
    </React.Fragment>
  );
};

export default EditBrandModal;
