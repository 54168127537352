import React from "react";

const FreeText = (props) => {
  return (
    <>
      <div className="p-4">
        <h5>{props.widget.title}</h5>
        <p className="text-dark">{props.widget.description}</p>
      </div>
    </>
  );
};

export default FreeText;
