import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Button, Row, Col, Card, CardBody, Label } from "reactstrap";
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import $ from "jquery";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { CheckPicker, Input, InputPicker } from "rsuite";

import Navbar from "../../components/HorizontalLayout/Navbar";
import { data_sources } from "../../common/data/data_sources";
import { setWidgetsList } from "../../store/actions";

const display_types = [
  { label: "Número", value: "NUMBER" },
  { label: "Porcentagem", value: "PERCENTAGE" },
  { label: "Moeda", value: "CURRENCY" },
];

const better_whens = [
  { label: "Maior", value: "HIGH" },
  { label: "Menor", value: "LOW" },
];

const merge_operations = [
  { label: "Soma", value: "SUM" },
  { label: "Média", value: "AVG" },
];

const MetricsListMaker = () => {
  window.addEventListener("DOMContentLoaded", async (event) => {
    document.getElementById("topnav").style.display = "none";
  });

  const [metricsArray, setMetricsArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [metric, setMetric] = useState({});

  function handleSubmit(data) {
    setLoading(true);

    var checked = [];
    $("input[name='ds[]']:checked").each(function () {
      checked.push($(this).val());
    });

    data.description = data.description || "";

    data.isEnabled = true;
    console.log(data);

    setMetricsArray([...metricsArray, metric]);
    setMetric({});

    setLoading(false);
  }

  const filteredList =
    search.length > 0
      ? metricsArray.filter(
          (o) =>
            o.label.toLowerCase().includes(search.replace(/ /g, "")) ||
            o.code.toLowerCase().includes(search.replace(/ /g, ""))
        )
      : metricsArray;

  useEffect(() => {
    if (window.localStorage.getItem("metrics_array")) {
      setMetricsArray(JSON.parse(window.localStorage.getItem("metrics_array")));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("metrics_array", JSON.stringify(metricsArray));
  }, [metricsArray]);

  return (
    <React.Fragment>
      <div
        className="w-100"
        style={{ height: 4, marginTop: 60, backgroundColor: "#1E55EA" }}
      ></div>
      <div className="page-content" style={{ paddingTop: 80 }}>
        <Container>
          <Row className="d-flex justify-content-center">
            <Col lg={6}>
              <div className="text-start">
                <Card className="overflow-hidden">
                  <Row className="g-0">
                    <Col lg={12}>
                      <div className="p-lg-5 p-4">
                        <div>
                          <h5>Criar lista de métricas</h5>
                          <p className="text-muted">
                            Essa página foi desenvolvida apenas com o intuito de
                            facilitar a criação da lista de métricas disponíveis
                            na plataforma.
                          </p>
                        </div>

                        <div className="pt-3">
                          <AvForm className="form-horizontal">
                            <div className="mb-3">
                              <Label>Label</Label>
                              <Input
                                value={metric.label || ""}
                                onChange={(e) =>
                                  setMetric({
                                    ...metric,
                                    label: e,
                                  })
                                }
                              />
                            </div>

                            <div className="mb-3">
                              <Label>Descrição</Label>
                              <Input
                                as="textarea"
                                rows={3}
                                placeholder="Descrição da métrica"
                                value={metric.description || ""}
                                onChange={(value) =>
                                  setMetric({
                                    ...metric,
                                    description: value,
                                  })
                                }
                              />
                            </div>

                            <div className="mb-3">
                              <Label>Value</Label>
                              <Input
                                value={metric.value || ""}
                                onChange={(e) =>
                                  setMetric({
                                    ...metric,
                                    value: e,
                                  })
                                }
                              />
                            </div>

                            <div className="mb-3">
                              <Label>Tipo de visualização</Label>
                              <InputPicker
                                data={display_types}
                                style={{ width: "100%" }}
                                value={metric.display_type || ""}
                                onChange={(value) =>
                                  setMetric({
                                    ...metric,
                                    display_type: value,
                                  })
                                }
                              />
                            </div>

                            <div className="mb-3">
                              <Label>Operação</Label>
                              <InputPicker
                                data={merge_operations}
                                style={{ width: "100%" }}
                                value={metric.merge_operation || ""}
                                onChange={(value) => {
                                  setMetric({
                                    ...metric,
                                    merge_operation: value,
                                  });
                                }}
                              />
                            </div>

                            <div className="mb-3">
                              <Label>Melhor quando</Label>
                              <InputPicker
                                data={better_whens}
                                style={{ width: "100%" }}
                                value={metric.better_when || ""}
                                onChange={(value) => {
                                  setMetric({
                                    ...metric,
                                    better_when: value,
                                  });
                                }}
                              />
                            </div>

                            {/* checkboxes */}

                            <h6 className="mt-3">Fontes de dados</h6>

                            <CheckPicker
                              data={data_sources.map((item) => ({
                                label: item.label,
                                value: item.code,
                              }))}
                              value={
                                metric.data_sources?.map((o) => o.code) || []
                              }
                              onChange={(value) => {
                                setMetric({
                                  ...metric,
                                  data_sources: value.map((item) => ({
                                    code: item,
                                  })),
                                });
                              }}
                              style={{ width: "100%" }}
                            />

                            {metric.data_sources?.some(
                              (o) => o.code === "FA"
                            ) ? (
                              <Input
                                className="my-2"
                                placeholder="Facebook Ads API"
                                onChange={(value) => {
                                  setMetric({
                                    ...metric,
                                    data_sources: metric.data_sources.map(
                                      (ds) => {
                                        if (ds.code === "FA") {
                                          return {
                                            ...ds,
                                            metric: value,
                                          };
                                        }
                                        return ds;
                                      }
                                    ),
                                  });
                                }}
                              />
                            ) : null}

                            {metric.data_sources?.some(
                              (o) => o.code === "AW"
                            ) ? (
                              <Input
                                className="my-2"
                                placeholder="Google Ads API"
                                onChange={(value) => {
                                  setMetric({
                                    ...metric,
                                    data_sources: metric.data_sources.map(
                                      (ds) => {
                                        if (ds.code === "AW") {
                                          return {
                                            ...ds,
                                            metric: value,
                                          };
                                        }
                                        return ds;
                                      }
                                    ),
                                  });
                                }}
                              />
                            ) : null}
                          </AvForm>
                          <div className="mt-4 text-end">
                            <button
                              className="btn btn-success w-sm waves-effect waves-light btn-100 px-5 w-100"
                              type="submit"
                              disabled={
                                loading || Object.keys(metric).length < 6
                              }
                              onClick={() => handleSubmit(metric)}
                            >
                              {loading ? (
                                <i className="mdi mdi-loading mdi-spin fs-6 me-3" />
                              ) : null}
                              <b className="text-white">Adicionar Métrica</b>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
            <Col lg={6}>
              <Card style={{ height: 850 }}>
                <CardBody className="px-4">
                  <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <h5 className="mb-2">Métricas ({metricsArray.length})</h5>

                    <input
                      style={{ maxWidth: 200 }}
                      className="form-control"
                      type="text"
                      placeholder="Pesquisar"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>

                  <hr className="divider" />

                  <div style={{ height: 700, overflowY: "scroll" }}>
                    {filteredList.map((object, key) => (
                      <Card
                        className="w-100 my-1 p-2 px-3 d-flex flex-row justify-content-between align-items-center"
                        key={key}
                      >
                        <div>
                          <div className="px-1">
                            {object.merge_operation === "SUM" ? (
                              <i className="mdi mdi-plus fs-4 text-muted" />
                            ) : (
                              <i className="mdi mdi-division fs-4 text-muted" />
                            )}
                          </div>
                        </div>
                        <div style={{ width: "60%" }}>
                          <b className="text-truncate d-block">
                            {object.label}
                          </b>
                          <span className="text-muted">{object.code}</span>
                        </div>
                        <div>
                          {object.data_sources.map((o) => {
                            const ds = data_sources.find(
                              (ds) => ds.code === o.code
                            );
                            return (
                              <i
                                className={ds.icon}
                                style={{
                                  color: ds.color,
                                  fontSize: 20,
                                  marginInline: 5,
                                }}
                              />
                            );
                          })}
                        </div>
                        <div>
                          <button
                            className="btn btn-light"
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: "50%",
                              padding: 2,
                            }}
                            onClick={() => {
                              var newArr = metricsArray;
                              newArr.splice(key, 1);

                              setMetricsArray([...newArr]);
                            }}
                          >
                            <i className="mdi mdi-trash-can fs-5 text-muted" />
                          </button>
                        </div>
                      </Card>
                    ))}
                  </div>
                  <div>
                    <button
                      className="btn btn-primary w-100 p-2"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            window.localStorage.getItem("metrics_array")
                          )
                          .then(() => {
                            toast.success("Objecto JSON copiado!");
                          })
                          .catch(() => {
                            toast.error("Erro ao copiar!");
                          });
                      }}
                    >
                      Copiar JSON
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MetricsListMaker;
