import React from "react";
import ReactApexChart from "react-apexcharts";

const AdminDonutChart = (props) => {
  const series = props.data.map((item) =>
    Number(item?.replace(/[^0-9-]+/g, "") || 0)
  );
  const options = {
    labels: ["Novas Assinaturas", "Renovações"],
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          return (y / 100).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 0,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      style={{ margin: 0 }}
      options={options}
      series={series}
      type="donut"
      height="120"
      width={120}
      className="apex-charts"
      dir="ltr"
    />
  );
};

export default AdminDonutChart;
