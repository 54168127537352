import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Container, Row, Col, Card, CardBody, Label } from "reactstrap";
import { Input, Button, Progress, Loader, Modal } from "rsuite";
import MetaTags from "react-meta-tags";
import api from "../../services/api";
import { getIdUsuario, getToken } from "../../services/auth";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/ai_animation_lottie.json";
import classNames from "classnames";
import Navbar from "./Navbar";
import moment from "moment";
import { io } from "socket.io-client";
import AIHeader from "./Header";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const generation_tools = [
  {
    key: "custom",
    name: "Personalizado",
    description: "Gera um texto personalizado para você.",
    prompt_start: "",
    default_quantity: 1,
  },
  {
    key: "copy_for_ads",
    name: "Copy para anúncio",
    description:
      "Gera uma copy para um anúncio de Facebook Ads ou outra plataforma de anúncios.",
    prompt_start:
      "Gerar uma copy para um anúncio de Facebook Ads com o assunto: ",
    default_quantity: 1,
  },
  {
    key: "copy_for_email",
    name: "Copy para e-mail",
    description: "Gera uma copy para um e-mail marketing.",
    prompt_start: "Gerar uma copy para um e-mail marketing com o assunto: ",
    default_quantity: 1,
  },
  {
    key: "subtitle_for_post",
    name: "Legenda para post",
    description: "Gera legendas para um post em redes sociais.",
    prompt_start:
      "Gerar uma legenda para um post em redes sociais com o assunto: ",
    default_quantity: 1,
  },
  {
    key: "ideas_for_post",
    name: "Idéias para post",
    description: "Gera idéias para um post em redes sociais.",
    prompt_start:
      "Me dê 5 idéias de tema para um post em redes sociais com o assunto: ",
    default_quantity: 5,
  },
  {
    key: "ideas_for_creative",
    name: "Idéias para criativos",
    description: "Gera idéias para um criativo de anúncio.",
    prompt_start:
      "Me dê 5 idéias de temas e legendas, para um criativo de anúncio com o assunto: ",
    default_quantity: 5,
  },
];

function isScrolledToBottom() {
  return (
    Math.ceil(document.body.getBoundingClientRect().bottom) <=
    window.innerHeight
  );
}

const ScribeAI = () => {
  const [user, setUser] = useState({});
  const [loadingUser, setLoadingUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [result, setResult] = useState({});
  const [active, setActive] = React.useState("history");
  const [selectedToolOption, setSelectedToolOption] = React.useState(
    generation_tools[0]
  );
  const [history, setHistory] = useState([]);
  const [saved, setSaved] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [notEnoughCredits, setNotEnoughCredits] = useState(false);
  const [selectedGeneration, setSelectedGeneration] = useState({});

  const socketRef = useRef();

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setTimeout(() => {
      setSelectedGeneration({});
    }, 300);
  };

  function generatePrompt() {
    var new_prompt = "";

    if (selectedToolOption.key === "custom") {
      new_prompt = prompt;
    } else {
      new_prompt = selectedToolOption.prompt_start + prompt;
    }

    return new_prompt;
  }

  const handleGenerateCopy = (transformed_prompt) => {
    setLoading(true);
    socketRef.current.emit("ai_text_prompt", {
      prompt: transformed_prompt,
      token: getToken(),
    });
  };

  async function handleSaveToHistory() {
    await api
      .post("/api/v1/enterprise/ai/text/save-to-history", {
        creator: getIdUsuario(),
        kind: selectedToolOption.key,
        prompt: prompt,
        result: result.text,
        usage: result.usage,
        created: result.created,
      })
      .then((res) => {
        console.log("Text added to history");
      })
      .catch((err) => {
        console.log("Error saving to history");
        console.log(err);
      });

    loadHistory();
    loadSaved();
  }

  function toggleSaved(id) {
    var new_arr = history.map((item) => {
      if (item._id === id) {
        item.saved = !item.saved;
      }
      return item;
    });
    setHistory(new_arr);

    api
      .get(`/api/v1/enterprise/ai/text/toggle-saved/${id}`)
      .then((res) => {
        console.log("saved or unsaved text " + id);
        loadSaved();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function loadHistory() {
    api
      .get("/api/v1/enterprise/ai/text/history")
      .then((res) => {
        setHistory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function loadSaved() {
    api
      .get("/api/v1/enterprise/ai/text/saved")
      .then((res) => {
        setSaved(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function loadUserData() {
    setLoadingUser(true);
    api
      .get("/api/usuarios.details/" + getIdUsuario())
      .then((response) => {
        setUser(response.data[0]);
        setLoadingUser(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingUser(false);
      });
  }

  useEffect(() => {
    const socket = io(
      window.location.origin.includes("localhost")
        ? "http://localhost:4000"
        : window.location.origin
    );
    socketRef.current = socket;
  }, []);

  useEffect(() => {
    const socket = socketRef.current;

    socket.on("connect", () => {
      socket.on("welcome", (data) => {
        console.log(data);
      });

      socket.on("ai_generated_text", (data) => {
        setResult(data);
        setLoading(true);

        if (isScrolledToBottom()) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      });

      socket.on("error_message", (data) => {
        console.log(data);

        if (data.code === 403) {
          setNotEnoughCredits(true);
        }
        setLoading(false);
      });
    });
  }, []);

  useEffect(() => {
    const socket = socketRef.current;

    socket.on("ai_completion_done", (data) => {
      setLoading(false);
      handleSaveToHistory(selectedToolOption.key, prompt, data);
      console.log("Saving to history...");
    });

    return () => {
      socket.off("ai_completion_done");
    };
  }, [handleSaveToHistory]);

  useEffect(() => {
    try {
      document.querySelector("textarea").style.resize = "none";
    } catch (err) {
      console.log(err);
    }
    loadUserData();
    loadHistory();
    loadSaved();
  }, []);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Scribe AI | Metrito</title>
        </MetaTags>

        <Modal
          open={notEnoughCredits}
          onClose={() => setNotEnoughCredits(false)}
        >
          <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center text-center mb-3">
              <img
                src="https://gifdb.com/images/high/kermit-the-frog-meme-o208d311e8cuvm6w.gif"
                alt="triste"
                style={{ maxHeight: 150, borderRadius: 10 }}
                className="my-4"
              />
              <h4>
                Ops... Você precisa de mais créditos!{" "}
                <img
                  className="ms-2"
                  src="https://cdn-icons-png.flaticon.com/512/733/733311.png"
                  alt="ai-coins"
                  style={{ width: 20, height: 20 }}
                />
              </h4>
              <span className="text-muted">
                Os créditos são necessários para que você possa gerar resultados
                com inteligência artificial.{" "}
                <a href="#">
                  Clique aqui para entender melhor sobre os créditos.
                </a>
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setNotEnoughCredits(false)}
              appearance="primary"
            >
              Entendi!
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal open={openModal}>
          <Modal.Header>
            <Modal.Title>Texto gerado por AI</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column mb-3">
              <small className="text-muted">Prompt:</small>
              <b>{selectedGeneration.prompt}</b>
            </div>

            <small className="text-muted">Resultados:</small>
            <div className="my-2">
              <div className="d-flex flex-column align-items-start">
                <div
                  className="my-2"
                  style={{
                    minWidth: "100%",
                    whiteSpace: "pre-line",
                    backgroundColor: "rgba(0, 0, 35, 0.04)",
                    borderRadius: 10,
                    padding: 20,
                  }}
                >
                  {selectedGeneration.result}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <img
                className="me-1"
                src="https://cdn-icons-png.flaticon.com/512/5219/5219370.png"
                alt="ai-coins"
                style={{ width: 15, height: 15 }}
              />
              <small className="font-weight-bold text-muted text-muted">
                Créditos utilizados: <b>{selectedGeneration?.usage}</b>
              </small>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} appearance="primary">
              Ok
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Container fluid>
          <AIHeader
            title="Scribe"
            description="Gere textos com inteligência artificial de forma simples e
                    em poucos cliques."
            icon_url="https://cdn-icons-png.flaticon.com/512/4276/4276555.png"
            loading={loadingUser}
            credits={user.ai_credits}
            usage={result.usage}
          />

          <Row>
            <Col lg={7}>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <h5>Digite qualquer coisa!</h5>
                      <small className="text-muted">
                        O Metrito está conectado com{" "}
                        <b>
                          o maior provedor de inteligência artificial do mundo
                        </b>
                        , estamos trabalhando para trazer soluções ainda mais
                        inovadoras para esse mercado!{" "}
                      </small>

                      <div className="my-2 d-flex flex-row flex-wrap w-100">
                        {generation_tools.map((option) => (
                          <div
                            className={classNames(
                              {
                                "gateway-option-selected":
                                  selectedToolOption.key === option.key,
                              },
                              "gateway-option flex-shrink-0 p-2 px-4"
                            )}
                            onClick={() => {
                              setTimeout(() => {
                                setSelectedToolOption(option);
                              }, 100);
                            }}
                          >
                            <span>{option.name}</span>
                          </div>
                        ))}
                      </div>

                      <small className="ms-2">Assunto</small>
                      <Input
                        className="mt-1"
                        placeholder="Digite o que deseja..."
                        as="textarea"
                        rows={5}
                        value={prompt}
                        onChange={(v) => setPrompt(v)}
                        style={{
                          minWidth: "100%",
                          borderRadius: 10,
                          padding: 20,
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      ></Input>
                      <div className="w-100 text-end">
                        <small
                          className={classNames(
                            {
                              "text-muted": prompt.length === 0,
                              "text-danger":
                                prompt.length > 0 && prompt.length < 50,
                              "text-success": prompt.length > 50,
                            },
                            "w-100"
                          )}
                        >
                          <b>{prompt.length}/1000</b>
                        </small>
                      </div>

                      <Button
                        appearance="default"
                        className="mt-3 px-5 w-100 d-flex align-items-center justify-content-center"
                        onClick={() => handleGenerateCopy(generatePrompt())}
                        loading={loading}
                        style={{
                          fontWeight: 600,
                          backgroundColor: "#00CC47",
                          color: "#fff",
                          fontSize: 16,
                          padding: 15,
                        }}
                      >
                        <i className="bx bx-bolt-circle me-2 fs-5"></i>
                        {result.text ? "Gerar novamente" : "Gerar texto com AI"}
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  {loading || Object.keys(result).length ? (
                    <Card>
                      <CardBody>
                        <div>
                          <div>
                            <div className="d-flex flex-column align-items-end my-3">
                              <div
                                className="my-2"
                                style={{
                                  minWidth: "100%",
                                  whiteSpace: "pre-line",
                                  backgroundColor: "rgba(0, 0, 35, 0.04)",
                                  borderRadius: 10,
                                  padding: 20,
                                  fontSize: 18,
                                  fontWeight: 500,
                                }}
                              >
                                {result.text}

                                <>
                                  {loading && (
                                    <span
                                      className="blinking-cursor"
                                      style={{
                                        marginBottom: -2,
                                      }}
                                    ></span>
                                  )}
                                </>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-2">
                            <div>
                              <img
                                className="me-1"
                                src="https://cdn-icons-png.flaticon.com/512/5219/5219370.png"
                                alt="ai-coins"
                                style={{ width: 15, height: 15 }}
                              />
                              <small className="font-weight-bold text-muted">
                                Créditos utilizados: <b>{result.usage}</b>
                              </small>
                            </div>

                            <div>
                              {loading && (
                                <div className="text-muted d-flex align-items-center">
                                  <Loader className="me-2" /> Gerando...
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg={5}>
              <Card>
                <CardBody className="pt-0">
                  <div>
                    <Navbar
                      appearance="subtle"
                      active={active}
                      onSelect={setActive}
                    />

                    <div
                      id="history"
                      style={{ maxHeight: "80vh", overflowY: "scroll" }}
                    >
                      {loadingUser ? (
                        <Loader />
                      ) : history.length === 0 ? (
                        <div className="d-flex flex-column align-items-center justify-content-center py-4">
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/7486/7486772.png"
                            alt="no history"
                            style={{ width: 80, height: 80 }}
                          />
                          <h4 className="mt-3">Nenhum histórico</h4>
                          <span className="text-muted mt-2">
                            Quando você gerar um texto com a AI, ele será salvo
                            aqui!
                          </span>
                        </div>
                      ) : (
                        <div>
                          {(active === "history" ? history : saved).map(
                            (item, index) => (
                              <div
                                className="d-flex flex-row align-items-start justify-content-between my-3 card p-3 ai-generation-item"
                                onClick={() => {
                                  setSelectedGeneration(item);
                                  handleOpen();
                                }}
                              >
                                <div className="text-truncate me-4">
                                  <b
                                    className="text-truncate"
                                    style={{ maxWidth: "100%", fontSize: 12 }}
                                  >
                                    {item.prompt}
                                  </b>
                                  <div className="d-flex">
                                    <small className="text-muted">
                                      {moment(item.created * 1000).fromNow()}
                                    </small>
                                  </div>
                                </div>

                                <div className="d-flex align-items-center h-100">
                                  <i
                                    className={classNames(
                                      {
                                        "mdi-bookmark-outline": !item.saved,
                                        "mdi-bookmark text-secondary":
                                          item.saved,
                                      },
                                      "mdi fs-5 i-btn"
                                    )}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleSaved(item._id);
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ScribeAI;
