import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { Button } from "rsuite";
import Preloader from "../Utility/Preloader";
import EditUserModal from "./EditUserModal";

import { useEffect } from "react";
import { getImageURL } from "../../functions/getImageURL";
import { tel } from "../../functions/mask";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";

function roleFormater(param) {
  switch (param) {
    case 1:
      return "Administrador";
    case 2:
      return "Funcionário";
    case 100:
      return "Staff Metrito";
    default:
      return "Carregando...";
  }
}

function planFormater(param) {
  switch (param) {
    case 1:
      return "Starter";
    case 2:
      return "Professional";
    case 3:
      return "Enterprise";
    case 4:
      return "Unlimited";
    default:
      return "-";
  }
}

const LeftSidebarProfile = (props) => {
  const [isAdmin, setAdmin] = useState(false);
  const [creatingPortalSession, setCreatingPortalSession] = useState(false);

  function createPortalSession(customer_id) {
    setCreatingPortalSession(true);
    api
      .post("/create-portal-session", {
        customer_id,
      })
      .then((res) => {
        window.open(res.data.url);
        setCreatingPortalSession(false);
      })
      .catch((err) => {
        setCreatingPortalSession(false);
        console.log(err);
      });
  }

  useEffect(() => {
    api
      .get("/api/adminverify/" + getIdUsuario())
      .then((res) => {
        if (res.data.admin === true) {
          setAdmin(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <React.Fragment>
      <Card className="h-100">
        {Object.keys(props.userInfo).length && props.userPlan != undefined ? (
          <CardBody>
            <div>
              <div className="float-end">
                <EditUserModal userInfo={props.userInfo} />
              </div>
              <div className="clearfix"></div>

              <div className="text-center bg-pattern">
                <div className="profile-pic-box mb-3">
                  <img
                    src={getImageURL(props.userInfo.profile_pic)}
                    alt=""
                    className="avatar-xl img-thumbnail rounded-circle"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <h4 className="text-primary mb-2">{props.userInfo.name}</h4>
                <h5 className="text-muted font-size-13 mb-3">
                  {roleFormater(props.userInfo.role)}
                </h5>
              </div>
            </div>

            <hr className="my-4" />
            <div>
              <h5 className="font-size-16">Informações de Cadastro</h5>

              <div>
                <p className="mb-1 text-muted">Telefone :</p>
                <h5 className="font-size-14">
                  {props.userInfo.tel_number
                    ? tel(props.userInfo.tel_number.toString())
                    : ""}
                </h5>
              </div>
              {/* <div className="mt-3">
              <p className="mb-1 text-muted">Documento :</p>
              <h5 className="font-size-14">
                {props.userInfo.document
                  ? cpfcnpj(props.userInfo.document.toString())
                  : "-"}
              </h5>
            </div> */}
              {/* <div className="mt-3">
              <p className="mb-1 text-muted">Endereço :</p>
              <h5 className="font-size-14">{props.userInfo.address}</h5>
            </div> */}
              <div className="mt-3">
                <p className="mb-1 text-muted">Plano :</p>
                <h5 className="font-size-14">{props.userPlan.name}</h5>
              </div>
            </div>
            <div className="mt-3">
              <p className="mb-1 text-muted">Conta criada em :</p>
              <h5 className="font-size-14">
                {moment(props.userInfo.createdAt).format("DD/MM/YYYY hh:mm")}
              </h5>
            </div>

            {props.userInfo &&
              props.userInfo.subscription?.id?.includes("sub_") &&
              props.userInfo.customer && (
                <Button
                  appearance="primary"
                  color="gray"
                  className="w-100 mt-2"
                  loading={creatingPortalSession}
                  onClick={() =>
                    createPortalSession(props.userInfo.customer.id)
                  }
                >
                  <i className="fab fa-stripe me-2" />
                  Gerenciar assinatura
                </Button>
              )}

            {isAdmin && (
              <div className="mt-2">
                <Link to="/admin/panel" className="w-100 mt-3 fs-5">
                  <Button appearance="default" className="w-100 mt-2">
                    <i className="mdi mdi-lock-check me-2" />
                    Painel Administrativo
                  </Button>
                </Link>
              </div>
            )}
          </CardBody>
        ) : (
          <Preloader />
        )}
      </Card>
    </React.Fragment>
  );
};

export default LeftSidebarProfile;
