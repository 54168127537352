import React, { useState } from "react";
import { Col, Container, Modal, Row } from "reactstrap";
import { data_sources } from "../../common/data/data_sources";

const SelectDataSourceToConnect = (props) => {
  const [modal_xlarge, setmodal_xlarge] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    // removeBodyCss();
  }

  return (
    <React.Fragment>
      <div
        className="button-items"
        style={{ marginBottom: 10, marginRight: 10 }}
      >
        <button
          type="button"
          onClick={() => {
            tog_xlarge();
          }}
          className={`btn btn-success waves-effect waves-light w-100`}
          data-toggle="modal"
          data-target=".bs-example-modal-xl"
        >
          <div style={{ alignItems: "center" }}>
            <i className="fas fa-plus text-white" style={{ marginRight: 10 }} />
            <span>Conectar</span>
          </div>
        </button>
      </div>

      <Container>
        <Modal
          className="modal-dialog-centered modal-dialog-scrollable"
          size="md"
          isOpen={modal_xlarge}
          toggle={() => {
            tog_xlarge();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Selecione a fonte de dados que deseja conectar
            </h5>
            <button
              onClick={() => {
                setmodal_xlarge(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body py-3">
            <Row>
              {data_sources.map((ds) => (
                <Col
                  lg={6}
                  className="d-flex flex-row justify-content-center my-2"
                >
                  <button
                    className="d-flex flex-row btn btn-light justify-content-center align-items-center px-4 py-3 w-100"
                    onClick={() =>
                      (window.location.href = `${ds.connect_account_url}?brand=${props.brand_id}`)
                    }
                  >
                    <div>
                      <img src={ds.icon_url} alt="" style={{ height: 40 }} />
                    </div>

                    <div className="text-start ms-3">
                      <span className="text-muted">Conectar</span>
                      <h6 className="mb-0">{ds.label}</h6>
                    </div>
                  </button>
                </Col>
              ))}
            </Row>
          </div>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default SelectDataSourceToConnect;
