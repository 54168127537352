import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import { defineMetric } from "../functions/metricFormatter";

const BarChart = (props) => {
  var series = [
    {
      name: props.selected.title,
      data: defineMetric(props.metrics, props.selected.type),
    },
  ];
  var options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "90%",
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#ffc800"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      labels: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Col lg={6} xl={6}>
        <Card>
          <CardBody>
            <CardTitle className="mb-0">{props.selected.title}</CardTitle>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="390"
              className="apex-charts"
              dir="ltr"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default BarChart;
