export const getImageURL = (imageId) => {
  if (window.location.hostname === "localhost") {
    return `http://localhost:4000/images?key=${
      imageId || "profile_placeholder.png"
    }`;
  } else {
    return `${window.location.origin}/images?key=${
      imageId || "profile_placeholder.png"
    }`;
  }
};
