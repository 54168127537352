import React from "react";
import { Col, Container, Row } from "reactstrap";

//Import Breadcrumb

import { BroadcastChannel } from "broadcast-channel";
import { useEffect } from "react";
import api from "../../services/api";

import Lottie from "react-lottie";
import animationData from "../../assets/lotties/google_ads_lottie.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const GoogleAuthorization = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const bc = new BroadcastChannel("ga_channel");

  window.addEventListener("DOMContentLoaded", async (event) => {
    document.getElementById("topnav").style.display = "none";
  });

  function exchangeCodeForToken() {
    const redirect_uri = `${window.origin}/googleads/authorization`;
    const auth_code = queryParams.get("code");

    console.log(redirect_uri, auth_code);

    api
      .post(`/api/googleads/authorization`, { auth_code, redirect_uri })
      .then((res) => {
        console.log(res.data);
        bc.postMessage(res.data);

        setTimeout(() => {
          window.close();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    exchangeCodeForToken();
  }, []);

  return (
    <React.Fragment>
      <div
        className="d-flex flex-row justify-content-between w-100"
        style={{ top: 60, position: "absolute" }}
      >
        <div
          style={{
            height: 8,
            backgroundColor: "#FABC00",
            width: "100%",
          }}
        ></div>
        <div
          style={{
            height: 8,
            backgroundColor: "#4285F3",
            width: "100%",
          }}
        ></div>
        <div
          style={{ height: 8, backgroundColor: "#33A851", width: "100%" }}
        ></div>
      </div>

      <div className="page-content ">
        <Container>
          <Row>
            <Col md={12}>
              <div className="text-center my-5 pt-sm-5">
                <div>
                  <Row className="justify-content-center">
                    <Col sm={5}>
                      <div>
                        <Lottie
                          options={defaultOptions}
                          height={"auto"}
                          width={400}
                          style={{ marginBottom: -60 }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <h4 className="text-uppercase mt-4">
                  Autorizando Google Ads{" "}
                  <i className="mdi mdi-loading mdi-spin ms-2"></i>
                </h4>
                <p className="text-muted fs-5">
                  Por favor não feche esta página, isso pode levar alguns
                  segundos! <br></br>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GoogleAuthorization;
