import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import InfoModal from "../Connections/InfoModal";
import Preloader from "../Utility/Preloader";

import ChangeAdAccountAlias from "../../components/Popups/ChangeAdAccountAlias.modal";
import { Input, InputPicker } from "rsuite";
import AccountNameWithAliasSupport from "../../components/Common/AccountNameWithAliasSupport";
//services
import api from "../../services/api";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { data_sources } from "../../common/data/data_sources";
import { blockOrNot } from "../../functions/blockOrNot";
import { getImageURL } from "../../functions/getImageURL";
import CustomAlert from "../../components/CustomAlert";

const statuses = [
  {
    label: "Ativas",
    code: 1,
    color: "#38DA8C",
  },
  {
    label: "Problemáticas",
    code: 3,
    color: "#fe9d02",
  },
  {
    label: "Bloqueadas",
    code: 2,
    color: "#f45130",
  },
  {
    label: "Token Expirado",
    code: "error",
    color: "#9599ad",
  },
];

const Connections = () => {
  const pickerAnchorRef = useRef(null);
  const [brands, setBrands] = useState([]);
  const [adaccountsLength, setAdaccountsLength] = useState(0);
  const [loading, setLoading] = useState(true);

  //Confirm Dialogres
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [dynamic_description, setdynamic_description] = useState("");
  const [selectedAdaccount, setSelectedAdaccount] = useState({});
  const [selectedBrandId, setSelectedBrandId] = useState("");

  const [openEditModal, setOpenEditModal] = useState(false);
  const [search, setSearch] = useState("");

  const [pickerFilter, setPickerFilter] = useState("ALL");

  const filter_picker_options = [
    {
      label: "Todas",
      value: "ALL",
    },
    ...statuses.map((item) => ({
      label: item.label,
      value: item.code,
    })),
  ];

  const filterByStatus = (arr) =>
    pickerFilter === "ALL"
      ? arr
      : arr?.filter((o) => o.account_status === pickerFilter);

  const filterBySearch = (arr) =>
    arr.filter((o) =>
      (o.name ?? 'SEM NOME')
        .toLowerCase()
        .replace(/ /g, "")
        .includes(search.toLowerCase().replace(/ /g, ""))
    );

  const adAccountFilter = (arr) => {
    const byStatus = filterByStatus(arr);
    return filterBySearch(byStatus);
  };

  const brandHasRecords = (brands) =>
    brands.filter(
      (brand) =>
        brand.ad_accounts.length && adAccountFilter(brand.ad_accounts).length
    );

  const thereIsRecords = (arr) => (brandHasRecords(arr).length ? true : false);

  const getTotalAdAccounts = (arr) =>
    arr.flatMap((brand) => adAccountFilter(brand.ad_accounts)).length;

  const triggerRef = useRef();
  const open = () => triggerRef.current.open();
  const close = () => triggerRef.current.close();

  const handleOpen = () => {
    setOpenEditModal(true);
  };

  const handleClose = () => {
    setOpenEditModal(false);
  };

  const handleUpdate = () => {
    loadAdaccounts();
  };

  async function loadAdaccounts() {
    setLoading(true);
    const brands = await api.get("/api/v1/brands/");

    setAdaccountsLength(
      brands.data.flatMap((brand) => brand.ad_accounts).length
    );

    console.log("Total length is: ", getTotalAdAccounts(brands.data));

    const promise_array = brands.data.map((brand) => {
      const google_accounts = brand.ad_accounts.filter(
        (account) => account.data_source === "AW"
      );
      const facebook_accounts = brand.ad_accounts.filter(
        (account) => account.data_source === "FA"
      );

      const updated_data = api
        .post("/api/v1/enterprise/fa/account/status", {
          ad_accounts: facebook_accounts,
          brand_id: brand._id,
        })
        .then((res) => {
          const facebook_accounts_with_status = res.data.map((account) => ({
            ...account,
            ...facebook_accounts.find(
              (act) => act.ds_id === account.id && act.data_source === "FA"
            ),
          }));

          brand.ad_accounts = [
            ...google_accounts,
            ...facebook_accounts_with_status,
          ];

          return brand;
        })
        .catch((err) => err);

      return updated_data;
    });

    await Promise.all(promise_array)
      .then((result) => {
        setBrands(result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  async function handleDelete(adaccount) {
    console.log(adaccount);
    const result = await api.delete(
      "/api/ad-account/remove/" + selectedBrandId,
      {
        data: { ds_id: adaccount },
      }
    );

    console.log(result);

    if (result.status === 200) {
      console.log("Conta deletada com sucesso");
    } else {
      console.log("Erro ao deletar conta");
    }
  }

  useEffect(() => {
    loadAdaccounts();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Contas de Anúncio | Metrito</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Facebook" breadcrumbItem="Contas de Anúncio" />

          <div className="d-flex flex-wrap-reverse flex-sm-nowrap justify-content-sm-between pb-4 w-100">
            <h6 className="m-2 text-truncate">
              {loading ? (
                "Carregando contas ..."
              ) : (
                <>{getTotalAdAccounts(brands)} Contas de Anúncio encontradas</>
              )}
            </h6>
            <Input
              style={{ maxWidth: 400 }}
              className={"order-1 order-sm-0"}
              placeholder="Pesquisar..."
              value={search}
              onChange={(e) => setSearch(e)}
            />
            <div
              className="d-flex align-items-center my-2 my-sm-0 justify-content-end flex-grow-1 flex-sm-grow-0 flex-sm-shrink-1"
              style={{ position: "relative" }}
              ref={pickerAnchorRef}
            >
              <InputPicker
                id="filter_input_picker"
                cleanable={false}
                defaultValue={"ALL"}
                className={"flex-fill"}
                style={{ maxWidth: 400 }}
                container={() => pickerAnchorRef.current}
                data={filter_picker_options}
                onChange={(value) => {
                  setPickerFilter(value);
                }}
                value={pickerFilter}
                renderValue={(value, item, selectedElement) => {
                  const campaign_status = statuses.find(
                    (o) => o.code === value
                  );

                  return (
                    <div className="d-flex align-items-center">
                      {value === "ALL" ? (
                        <img
                          src="https://static-00.iconduck.com/assets.00/wait-13-icon-256x256-cz2iwps4.png"
                          alt="Todas os status"
                          className="mx-2"
                          style={{ width: 12 }}
                        />
                      ) : (
                        <i
                          className="mdi mdi-circle mx-2 "
                          style={{
                            color: campaign_status?.color || "#000",
                            fontSize: 12,
                          }}
                        />
                      )}

                      <b className="text-muted">{item.label}</b>
                    </div>
                  );
                }}
                renderMenuItem={(label, item) => {
                  const campaign_status = statuses.find(
                    (o) => o.code === item.value
                  );

                  return (
                    <div className="d-flex align-items-center">
                      {item.value === "ALL" ? (
                        <img
                          src="https://static-00.iconduck.com/assets.00/wait-13-icon-256x256-cz2iwps4.png"
                          alt="Todas os status"
                          className="mx-2"
                          style={{ width: 12 }}
                        />
                      ) : (
                        <i
                          className="mdi mdi-circle mx-2 "
                          style={{
                            color: campaign_status?.color || "#000",
                            fontSize: 12,
                          }}
                        />
                      )}

                      <b className="text-muted">{item.label}</b>
                    </div>
                  );
                }}
              />
            </div>
          </div>

          {loading && brands ? (
            <Preloader />
          ) : !loading && brands && adaccountsLength === 0 ? (
            <>
              <InfoModal />
            </>
          ) : (
            <>
              <Col className="col-12">
                <Row>
                  <Col lg={12}>
                    {thereIsRecords(brands) &&
                      brandHasRecords(brands).map((brand, key) => (
                        <Card className="wide-brand-card" key={key}>
                          <CardBody>
                            <div>
                              <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="d-flex align-items-center px-3">
                                  <img
                                    src={getImageURL(
                                      brand.logo || "brand_placeholder.png"
                                    )}
                                    alt="image"
                                    className="me-3"
                                    style={{
                                      borderRadius: 10,
                                      height: 50,
                                      width: 70,
                                      padding: 5,
                                      backgroundColor: "#fff",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <div>
                                    <h5 className="mb-0">{brand.name}</h5>
                                    <small className="text-muted">
                                      {brand.description}
                                    </small>
                                  </div>
                                </div>
                                <UncontrolledDropdown>
                                  <DropdownToggle role="button" tag="div">
                                    <i className="mdi mdi-menu text-muted i-btn fs-4 me-2"></i>
                                  </DropdownToggle>
                                  <DropdownMenu className="text-muted dropdown-menu-end">
                                    <DropdownItem
                                      className="text-dark"
                                      onClick={() =>
                                        (window.location.href =
                                          "/brands/" + brand._id)
                                      }
                                    >
                                      <i className="bx bx-briefcase me-2" />
                                      Detalhes da empresa
                                    </DropdownItem>
                                    <hr className="divider" />

                                    {data_sources.map((ds, key) => (
                                      <DropdownItem
                                        key={key}
                                        className="text-dark"
                                        onClick={() =>
                                          (window.location.href = `${ds.connect_account_url}?brand=${brand._id}`)
                                        }
                                      >
                                        <i
                                          className={`${ds.icon} me-2`}
                                          style={{ color: ds.color }}
                                        />
                                        Conectar Conta do {ds.label}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>

                              <hr className="divider" />

                              <div className="mt-4">
                                {adAccountFilter(brand.ad_accounts).map(
                                  (adaccount, key) => {
                                    const ds = data_sources.find(
                                      (ds) => ds.code === adaccount.data_source
                                    );

                                    var status = blockOrNot(
                                      adaccount.account_status
                                    );
                                    return (
                                      <>
                                        <Card className="px-3 p-2" key={key}>
                                          <Row>
                                            <Col
                                              lg={3}
                                              className="d-flex flex-row align-items-center"
                                            >
                                              <div className="me-3">
                                                <i
                                                  className={ds.icon}
                                                  style={{
                                                    color: ds.color,
                                                    fontSize: 30,
                                                    marginInline: 5,
                                                  }}
                                                />
                                              </div>
                                              <div className="d-flex flex-column">
                                                <Label
                                                  className="text-muted mb-0"
                                                  style={{ fontSize: 12 }}
                                                >
                                                  Nome
                                                </Label>

                                                <AccountNameWithAliasSupport
                                                  account={adaccount}
                                                  titleSize={6}
                                                />
                                              </div>
                                            </Col>
                                            <Col
                                              lg={2}
                                              className="d-flex align-items-center"
                                            >
                                              {adaccount.data_source === "FA" &&
                                              status.label != "-" ? (
                                                <div>
                                                  <Label
                                                    className="text-muted mb-0"
                                                    style={{ fontSize: 12 }}
                                                  >
                                                    status
                                                  </Label>
                                                  <b
                                                    className={`d-block text-${status.color}`}
                                                  >
                                                    {status.label || "erro"}
                                                  </b>
                                                </div>
                                              ) : null}
                                            </Col>
                                            <Col
                                              lg={2}
                                              className="d-flex align-items-center"
                                            >
                                              {adaccount.data_source === "FA" &&
                                              status.label != "-" ? (
                                                <div>
                                                  <Label
                                                    className="text-muted mb-0"
                                                    style={{ fontSize: 12 }}
                                                  >
                                                    limite de gastos
                                                  </Label>
                                                  {adaccount.spend_cap != 0 ? (
                                                    <span className="d-block text-dark">
                                                      {(
                                                        adaccount.spend_cap /
                                                          100 || 0
                                                      ).toLocaleString(
                                                        "pt-br",
                                                        {
                                                          style: "currency",
                                                          currency: "BRL",
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </span>
                                                  ) : (
                                                    <span className="d-block text-dark">
                                                      não definido
                                                    </span>
                                                  )}
                                                </div>
                                              ) : null}
                                            </Col>
                                            <Col
                                              lg={2}
                                              className="d-flex align-items-center"
                                            >
                                              {adaccount.data_source === "FA" &&
                                              status.label != "-" ? (
                                                <div>
                                                  <Label
                                                    className="text-muted mb-0"
                                                    style={{ fontSize: 12 }}
                                                  >
                                                    a pagar
                                                  </Label>
                                                  <span
                                                    className={`d-block text-${
                                                      adaccount.balance != 0
                                                        ? "warning"
                                                        : "dark"
                                                    }`}
                                                  >
                                                    {(
                                                      adaccount.balance / 100 ||
                                                      0
                                                    ).toLocaleString("pt-br", {
                                                      style: "currency",
                                                      currency: "BRL",
                                                      maximumFractionDigits: 2,
                                                    })}
                                                  </span>
                                                </div>
                                              ) : null}
                                            </Col>
                                            <Col
                                              lg={1}
                                              className="d-flex align-items-center"
                                            >
                                              {adaccount.data_source ===
                                              "FA" ? (
                                                <div>
                                                  {status.label != "-" ? (
                                                    <span
                                                      className="badge badge-soft-success me-1 d-block"
                                                      style={{
                                                        padding: 10,
                                                        fontSize: 12,
                                                      }}
                                                    >
                                                      <i
                                                        className="mdi mdi-cloud-check-outline"
                                                        style={{
                                                          fontSize: 15,
                                                          color: "#2CB57E",
                                                          marginRight: 10,
                                                        }}
                                                      />
                                                      Token validado
                                                    </span>
                                                  ) : (
                                                    <span
                                                      className="badge badge-soft-danger me-1 d-block"
                                                      style={{
                                                        padding: 10,
                                                        fontSize: 12,
                                                      }}
                                                    >
                                                      <i
                                                        className="mdi mdi-cloud-alert"
                                                        style={{
                                                          fontSize: 15,
                                                          color: "#F6846A",
                                                          marginRight: 10,
                                                        }}
                                                      />
                                                      Token expirado
                                                    </span>
                                                  )}
                                                </div>
                                              ) : null}
                                            </Col>
                                            <Col
                                              lg={2}
                                              className="d-flex flex-row align-items-center justify-content-end px-4"
                                            >
                                              <UncontrolledDropdown>
                                                <DropdownToggle
                                                  className="text-muted font-size-16"
                                                  tag="a"
                                                  role="button"
                                                >
                                                  <i className="mdi mdi-dots-vertical i-btn fs-4"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                  <DropdownItem
                                                    onClick={(e) => {
                                                      setSelectedAdaccount(
                                                        adaccount
                                                      );
                                                      setSelectedBrandId(
                                                        brand._id
                                                      );
                                                      handleOpen();
                                                    }}
                                                  >
                                                    <i className="mdi mdi-pencil fs-6 me-2"></i>
                                                    Editar apelido da conta
                                                  </DropdownItem>

                                                  <DropdownItem
                                                    onClick={(e) => {
                                                      setSelectedAdaccount(
                                                        adaccount
                                                      );
                                                      setSelectedBrandId(
                                                        brand._id
                                                      );
                                                      setconfirm_alert(true);
                                                    }}
                                                  >
                                                    <i
                                                      role="button"
                                                      className="mdi mdi-trash-can fs-6 text-danger float-start me-2"
                                                    />
                                                    Remover conta
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                            </Col>
                                          </Row>
                                        </Card>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      ))}
                    {!thereIsRecords(brands) && (
                      <Row className="p-4 d-flex justify-content-center align-items-center text-center">
                        Nenhum resultado encontrado
                        {pickerFilter === "ALL" ? (
                          ""
                        ) : (
                          <>
                            {" "}
                            com status "
                            {
                              filter_picker_options.filter(
                                (item) =>
                                  item.code === pickerFilter ||
                                  item.value === pickerFilter
                              )?.[0]?.label
                            }
                            "
                          </>
                        )}
                        {search && <> para essa pesquisa: "{search}"</>}.
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Container>
      </div>

      <ChangeAdAccountAlias
        account={selectedAdaccount}
        brandId={selectedBrandId}
        dataSource={data_sources.find(
          (ds) => ds.code === selectedAdaccount.data_source
        )}
        handleClose={handleClose}
        open={openEditModal}
        handleUpdate={handleUpdate}
      />

      {confirm_alert ? (
        <CustomAlert
          title="Tem certeza?"
          warning
          showCancel
          confirmButtonText="Sim, quero apagar!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false);
            setsuccess_dlg(true);
            setdynamic_title("Conta Removida");
            setdynamic_description("Sua conta de anúncios foi removida.");

            handleDelete(selectedAdaccount.ds_id);
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Seus dados e snapshots serão apagados de forma irreversível!
        </CustomAlert>
      ) : null}
      {success_dlg ? (
        <CustomAlert
          success
          title={dynamic_title}
          confirmBtnStyle={{ color: "white" }}
          onConfirm={() => {
            setsuccess_dlg(false);
            window.location.reload();
          }}
        >
          {dynamic_description}
        </CustomAlert>
      ) : null}
    </React.Fragment>
  );
};

export default Connections;
