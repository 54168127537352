import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ButtonToolbar,
  Placeholder,
  Input,
  InputGroup,
} from "rsuite";
import { Row, Col } from "reactstrap";
const styles = {
  width: "100%",
  marginBottom: 10,
};
const addonStyles = {
  width: 30,
  padding: 0,
  padding: 10,
};

const CustomUtmStage = (props) => {
  const handleOpen = () => props.setIsAddingCustomStage(true);
  const handleClose = () => props.setIsAddingCustomStage(false);

  useEffect(() => {
    if (
      Boolean(props.customStage.key.match(/[-_., a-z]|[À-ú]/)) ||
      Boolean(props.customStage.value.match(/[-_., A-Z]|[À-ú]/))
    ) {
      let fixed_object = { ...props.customStage };
      fixed_object = {
        ...fixed_object,
        key: fixed_object.key.replace(/[-_.,]|[À-ú]/, "").toUpperCase(),
        value: fixed_object.value.replace(/[-_.,]|[À-ú]/, "").toLowerCase(),
      };

      props.setCustomStage(fixed_object);
    }
  }, [props.customStage]);

  return (
    <>
      <ButtonToolbar>
        <Button onClick={handleOpen} className="w-100">
          <i className="mdi mdi-plus me-2" />
          Adicionar campo
        </Button>
      </ButtonToolbar>

      <Modal open={props.isAddingCustomStage} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Adicionar novo campo</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1">
          <Row className="w-100 m-0">
            <Col xs={12} md={6}>
              <small>Nome do campo</small>
              <Input
                required
                placeholder="Nome do campo ex: Gestor"
                value={props.customStage.label}
                onChange={(value) => {
                  props.setCustomStage({ ...props.customStage, label: value });
                }}
              />
            </Col>
            <Col xs={12} md={6}>
              <small>Identificador do campo</small>
              <InputGroup style={styles}>
                <Input
                  required
                  placeholder="Prefixo ex: GESTOR"
                  value={props.customStage.key}
                  onChange={(value) => {
                    props.setCustomStage({ ...props.customStage, key: value });
                  }}
                />
                <InputGroup.Addon style={addonStyles}>_</InputGroup.Addon>
              </InputGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.addExtraStage();
            }}
            appearance="primary"
          >
            Adicionar campo
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomUtmStage;
