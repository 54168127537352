import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { metricsArray } from "../common/data/metrics";

import moment from "moment";
import { DateRangePicker } from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useParams } from "react-router-dom";
import { Avatar, AvatarGroup, Checkbox, CheckPicker, Loader } from "rsuite";
import { data_sources } from "../common/data/data_sources";
import { date_presets, get_preset } from "../common/data/date_presets";
import api from "../services/api";
//Redux
import { connect } from "react-redux";
import { io } from "socket.io-client";
import { WhiteLoader } from "../pages/Builder/WhiteLoader";
import {
  setAccountByDataSourceAggregated,
  setAccountByDataSourceGranular,
  setAccountGeneralAggregated,
  setAccountGeneralGranular,
  setAccountSpecific,
  setAdAccounts,
  setCampaignGeneralAggregated,
  setCampaignGeneralGranular,
  setLoading,
} from "../store/analytics/actions.js";

import { applyMultiselect } from "../services/applyMultiselect";

import ExportToSheetsModal from "./ExportToSheets.modal";

const customStyles = {
  dropdownButton: (styles) => ({
    width: "100%",
    borderRadius: 10,
    padding: 7,
    paddingLeft: 10,
    paddingRight: 10,
    boxShadow: "none",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  container: () => ({
    borderRadius: 10,
    border: "1px solid #ced4da",
    width: "100%",
    transition: "all 1000ms",
  }),
  menu: () => ({ width: "100%" }),
};
const footerStyles = {
  padding: "10px 2px",
  borderTop: "1px solid #e5e5e5",
};

const formatOptionLabel = ({ value, label, status }) => (
  <span>
    {status === "ACTIVE" ? (
      <i
        className="spinner-grow text-success mx-3 ms-0 align-middle"
        style={{ height: 10, width: 10, margin: "auto" }}
      />
    ) : (
      <i
        className="mdi mdi-checkbox-blank-circle mx-3 ms-0 mt-1 align-middle"
        style={{ color: "#DBDDE0", fontSize: 10 }}
      />
    )}
    {label}
  </span>
);

let isEmpty = (a) => Array.isArray(a) && a.every(isEmpty);

const InsightsComponent = (props) => {
  let searchParams = new URLSearchParams(window.location.search);
  const picker = useRef();
  const socketRef = useRef();

  const { dashboardId } = useParams();
  const { brandId } = useParams();

  const [loadingInsights, setLoadingInsights] = useState(false);
  const [isLoadingAdAccounts, setLoadingAdAccounts] = useState(false);
  const [isLoadingCampaigns, setLoadingCampaigns] = useState(false);

  const [adAccounts, setAdAccounts] = useState([]);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const allAdAccounts = adAccounts.map((item) => item.value);

  const [loadingClickHouseData, setLoadingClickHouseData] = useState(false);
  const [batchProgress, setBatchProgress] = useState({});

  const [savingDashboardConfig, setSavingDashboardConfig] = useState(false);

  //new time interval constants
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState({});

  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [lockerSwitch, setLockerSwitch] = useState(false);
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        <i className="bx bx-lock-open-alt text-dark fs-6"></i>
      </div>
    );
  };
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        <i className="bx bxs-lock-alt fs-6"></i>
      </div>
    );
  };

  var selected_data_sources = ["FA", "AW"];
  var dashboard_metrics = metricsArray.filter((o) =>
    props.dashboardWidgets.map((o) => o.metric).includes(o.value)
  );
  var fields_by_data_source = selected_data_sources.map((ds) => ({
    data_source: ds,
    fields: [
      ...new Set(
        dashboard_metrics
          .map((m) => m.data_sources.find((d) => d.code === ds)?.metric)
          .filter((item) => item)
      ),
    ],
  }));

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  const handleChange = (value) => {
    setSelectedAdAccounts(value);
  };

  const handleCheckAll = (value, checked) => {
    setSelectedAdAccounts(checked ? allAdAccounts : []);
  };

  function loadAdAccounts(brandId) {
    setLoadingAdAccounts(true);
    if (brandId) {
      api
        .get("/api/ad-accounts/list/" + brandId)
        .then((res) => {
          setAdAccounts(
            res.data.map((item) => ({
              label: item.account_use_alias ? item.account_alias : item.name,
              value: item,
              role: item.data_source,
            }))
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingAdAccounts(false);
        });
    }
  }

  // Handle progress updates from the server
  function handleProgressUpdate(progressUpdate) {
    setBatchProgress((prevBatchProgress) => {
      const { batchId } = progressUpdate;
      const currentProgress = prevBatchProgress[batchId] || {
        total_reqs: 0,
        done_reqs: 0,
        req_data: {},
      };
      const updatedProgress = {
        total_reqs: progressUpdate.total_reqs,
        done_reqs: progressUpdate.done_reqs,
        req_data: progressUpdate.req_data,
      };
      const updatedBatchProgress = {
        ...prevBatchProgress,
        [batchId]: { ...currentProgress, ...updatedProgress },
      };
      return updatedBatchProgress;
    });
  }

  // Calculate overall progress
  const totalReqs = Object.values(batchProgress).reduce(
    (total, { total_reqs }) => total + total_reqs,
    0
  );
  const doneReqs = Object.values(batchProgress).reduce(
    (total, { done_reqs }) => total + done_reqs,
    0
  );
  const overallProgress =
    totalReqs > 0 ? Math.floor((doneReqs / totalReqs) * 100) : 0;

  useEffect(() => {
    const socket = io(
      window.location.origin.includes("localhost")
        ? "http://localhost:4000"
        : window.location.origin
    );
    socketRef.current = socket;
  }, []);

  useEffect(() => {
    const socket = socketRef.current;

    socket.on("connect", () => {
      socket.on("welcome", (data) => {
        console.log(data);
      });

      socket.on("test", (data) => {
        console.log(data);
      });

      socket.on("loading_progress", (data) => {
        // console.log(data);

        if (
          data.batchId.includes("CLICKHOUSE") &&
          data.done_reqs === data.total_reqs
        ) {
          // console.log(
          //   "CLICKHOUSE IS FULLY LOADED: ",
          //   data.batchId.includes("CLICKHOUSE") &&
          //     data.done_reqs === data.total_reqs
          // );
          setLoadingClickHouseData(false);
        } else if (
          data.batchId.includes("CLICKHOUSE") &&
          data.done_reqs !== data.total_reqs
        ) {
          setLoadingClickHouseData(true);
        }

        handleProgressUpdate(data);
      });

      socket.on("error_message", (data) => {
        console.log(data);
      });
    });
  }, []);

  async function loadInsightsData(
    ad_accounts_list,
    time_interval,
    level,
    modes
  ) {
    return new Promise(async (resolve, reject) => {
      const data = [];
      let responsesReceived = 0;

      const handleInsightsResponse = async (data) => {
        var aggregated_data = [];
        var granular_data = [];

        if (data.metadata.level === "ACCOUNT") {
          if (data.metadata.mode === "aggregated") {
            aggregated_data.push(data.data);

            var aggregated_by_data_source = _.groupBy(
              [].concat(...aggregated_data),
              "data_source"
            );

            aggregated_by_data_source = await Promise.all(
              Object.keys(aggregated_by_data_source).map(async (key) => ({
                data_source: key,
                data: await applyMultiselect(
                  aggregated_by_data_source[key],
                  "aggregated"
                ),
              }))
            );

            // console.log(aggregated_by_data_source);

            props.setAccountByDataSourceAggregated(aggregated_by_data_source);

            props.setAccountGeneralAggregated(
              (await applyMultiselect(
                [].concat(...aggregated_data),
                "aggregated"
              )) || []
            );
          } else if (data.metadata.mode === "granular") {
            granular_data.push(data.data);

            var granular_by_data_source = _.groupBy(
              [].concat(...granular_data),
              "data_source"
            );

            granular_by_data_source = await Promise.all(
              Object.keys(granular_by_data_source).map(async (key) => ({
                data_source: key,
                data: await applyMultiselect(
                  granular_by_data_source[key],
                  "granular"
                ),
              }))
            );

            props.setAccountByDataSourceGranular(granular_by_data_source);

            props.setAccountGeneralGranular(
              (await applyMultiselect(
                [].concat(...granular_data),
                "granular"
              )) || []
            );
          }
        } else if (data.metadata.level === "CAMPAIGN") {
          if (data.metadata.mode === "aggregated") {
            props.setCampaignGeneralAggregated(data.data);
          } else if (data.metadata.mode === "granular") {
            props.setCampaignGeneralGranular(data.data);
          }
        }

        const index = modes.indexOf(data.metadata.mode);
        if (index !== -1) {
          data[index] = data;
        }

        responsesReceived++;
        if (responsesReceived === modes.length) {
          console.timeEnd("insights");
          console.log("Insights loaded successfully: ", data.success);
          resolve(data);
        }
      };

      const handleErrorMessage = (error) => {
        console.timeEnd("insights");
        reject(error);
      };

      socketRef.current.on("insights_response", handleInsightsResponse);
      socketRef.current.on("error_message", handleErrorMessage);

      const promises = modes.map((mode) => {
        const req_data = {
          level: level,
          mode: mode,
          ad_accounts: ad_accounts_list,
          date_range: time_interval.time_range,
        };
        socketRef.current.emit("request_marketing_insights", req_data);
        return Promise.resolve();
      });

      await Promise.all(promises);
    });
  }

  async function loadAllInsightsData(ad_accounts_list, time_interval, props) {
    setLoadingInsights(true);
    var toast_loader = toast.loading(`Carregando dados...`);

    console.time("insights");

    const promises = [];
    for (const { level, mode } of props) {
      const promise = await loadInsightsData(
        ad_accounts_list,
        time_interval,
        level,
        [mode]
      );
      promises.push(promise);
    }

    try {
      const results = await Promise.all(promises);

      console.log("INSIGHTS RESULTS: ", results);
      console.timeEnd("insights");
      setTimeout(() => {
        setLoadingInsights(false);
        setBatchProgress({});
      }, 500);

      toast.update(toast_loader, {
        render: "Dados carregados!",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      return results;
    } catch (error) {
      console.error(error);
      console.timeEnd("insights");
      setLoadingInsights(false);

      toast.update(toast_loader, {
        render: "Problema ao carregar os dados.",
        type: "danger",
        isLoading: false,
        autoClose: 2000,
      });
      throw error;
    }
  }

  useEffect(() => {
    if (
      props.dashboard.configuration &&
      props.dashboard.configuration.ad_accounts &&
      props.dashboard.configuration.ad_accounts.length > 0 &&
      props.dashboard.configuration.interval &&
      !isLoadingAdAccounts &&
      adAccounts.length > 0
    ) {
      //Pre-configured req data
      const selected_ad_accounts =
        adAccounts
          .map((o) => o.value)
          .filter((o) =>
            props.dashboard.configuration.ad_accounts?.includes(o.ds_id)
          ) || [];

      // console.log("Selected ad accounts: ", selected_ad_accounts);

      setSelectedAdAccounts(selected_ad_accounts);

      var date_range;

      if (props.dashboard.configuration?.interval?.kind === "preset") {
        const preset_date_range = get_preset(
          props.dashboard.configuration.interval.preset
        );
        const preset_label = date_presets.find(
          (o) => o.code === props.dashboard.configuration.interval.preset
        ).label;

        date_range = {
          name: preset_label,
          kind: "preset",
          preset: props.dashboard.configuration.interval.preset,
          time_range: {
            start_date: moment(preset_date_range.start).format("YYYY-MM-DD"),
            end_date: moment(preset_date_range.end).format("YYYY-MM-DD"),
          },
        };
        setSelectedTimeInterval(date_range);
      } else if (props.dashboard.configuration?.interval?.kind === "custom") {
        const startDateTime = new Date(
          props.dashboard.configuration.interval.time_range.start_date +
            "T00:00:00-03:00"
        );
        const endDateTime = new Date(
          props.dashboard.configuration.interval.time_range.end_date +
            "T23:59:59-03:00"
        );

        setState([
          {
            startDate: new Date(
              startDateTime.getTime() +
                startDateTime.getTimezoneOffset() * 60 * 1000
            ),
            endDate: new Date(
              endDateTime.getTime() +
                endDateTime.getTimezoneOffset() * 60 * 1000
            ),
            key: "selection",
          },
        ]);
        date_range = {
          name: "Personalizado",
          kind: "custom",
          time_range: {
            start_date:
              props.dashboard.configuration.interval.time_range.start_date,
            end_date:
              props.dashboard.configuration.interval.time_range.end_date,
          },
        };
        setSelectedTimeInterval(date_range);
      }

      console.log("configuration: ", props.dashboard.configuration);

      const twentyEightDaysAgo = moment().subtract(28, "days");
      var isMoreThan28Days = moment(date_range.time_range.end_date).isBefore(
        twentyEightDaysAgo
      );

      if (
        date_range &&
        selected_ad_accounts &&
        selected_ad_accounts?.length > 0 &&
        props.dashboard?.layout?.length > 0 &&
        isMoreThan28Days
      ) {
        loadAllInsightsData(selected_ad_accounts, date_range, [
          { level: "account", mode: "aggregated" },
          { level: "account", mode: "granular" },
          { level: "campaign", mode: "aggregated" },
        ]);
      }
    } else if (
      !isLoadingAdAccounts &&
      (!props.dashboard.configuration ||
        props.dashboard.configuration?.ad_accounts?.length === 0 ||
        !props.dashboard.configuration?.interval)
    ) {
      //Not pre-configured req data
      setSelectedTimeInterval({
        name: date_presets[0].label,
        kind: "preset",
        preset: date_presets[0].code,
        time_range: {
          start_date: moment(get_preset(date_presets[0].code).start).format(
            "YYYY-MM-DD"
          ),
          end_date: moment(get_preset(date_presets[0].code).end).format(
            "YYYY-MM-DD"
          ),
          key: "selection",
        },
      });
    }
  }, [props.dashboard.configuration, isLoadingAdAccounts]);

  useEffect(() => {
    loadAdAccounts(props.dashboardBrandId);
  }, [props.dashboardBrandId]);

  useEffect(() => {
    try {
      props.setAdAccounts(selectedAdAccounts);
      props.setSelectedAdAccounts(selectedAdAccounts);
    } catch (err) {
      console.log(err);
    }
  }, [selectedAdAccounts]);

  useEffect(() => {
    const isDifferentFromConfiguration = _.isEqual(
      props.dashboard.configuration,
      {
        ad_accounts: selectedAdAccounts.map((o) => o.ds_id),
        interval: selectedTimeInterval,
      }
    );

    if (
      !isLoadingAdAccounts &&
      selectedAdAccounts.length > 0 &&
      selectedTimeInterval
    ) {
      setSavingDashboardConfig(true);

      api
        .put(`/api/dashboards/config/${props.dashboard._id}`, {
          ad_accounts: selectedAdAccounts.map((o) => o.ds_id),
          interval: selectedTimeInterval,
        })
        .then((res) => {
          console.log("Dashboard configuration updated!");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSavingDashboardConfig(false);
        });
    }
  }, [selectedTimeInterval, selectedAdAccounts]);

  // useEffect(() => {
  //   console.log(batchProgress);
  // }, [batchProgress]);

  return (
    <>
      <WhiteLoader
        isVisible={loadingInsights}
        progress={overallProgress}
        reqs_progress={batchProgress}
      />
      <Row className="d-flex align-items-center justify-content-end py-1 w-100">
        {savingDashboardConfig && (
          <Col
            lg={3}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Loader
              className="text-muted"
              content={`Salvando...`}
              size="xs"
              speed="slow"
            />
          </Col>
        )}
        <Col lg={3} className="d-flex flex-column">
          <Label className="text-muted ms-2 mb-0" style={{ fontSize: 12 }}>
            Contas de Anúncio
          </Label>
          <CheckPicker
            sticky
            className="w-100"
            data={adAccounts}
            placeholder="Selecione as contas de anúncio"
            ref={picker}
            style={{ width: 224 }}
            value={selectedAdAccounts}
            cleanable={false}
            onChange={handleChange}
            renderValue={(value, item) => {
              const platforms = data_sources.filter((o) =>
                _.uniq(value.map((o) => o.data_source)).includes(o.code)
              );
              const icon_stack_list = platforms.map((o) => ({
                avatar: o.icon_url,
                name: o.name,
              }));
              const max = 4;

              return (
                <div className="d-flex text-truncate">
                  <span className="float-start me-2">
                    <AvatarGroup stack>
                      {icon_stack_list.map((ds) => (
                        <Avatar
                          size="xs"
                          circle
                          key={ds.name}
                          src={ds.avatar}
                          alt={ds.name}
                        />
                      ))}
                    </AvatarGroup>
                  </span>
                  <span className="text-truncate">
                    {value.length} contas selecionadas
                  </span>
                </div>
              );
            }}
            renderMenuItem={(label, item) => {
              const platform = data_sources.find(
                (o) => o.code === item.value.data_source
              );

              return (
                <div className="d-flex text-truncate justify-content-between">
                  <span
                    className="text-truncate me-3"
                    style={{ maxWidth: "65vw" }}
                  >
                    {label}
                  </span>
                  <span className="float-end">
                    <img
                      src={platform.icon_url}
                      alt="platform"
                      style={{ width: 14, height: 14 }}
                    />
                  </span>
                </div>
              );
            }}
            renderExtraFooter={() => (
              <div style={footerStyles}>
                <Checkbox
                  indeterminate={
                    selectedAdAccounts.length > 0 &&
                    selectedAdAccounts.length < allAdAccounts.length
                  }
                  checked={selectedAdAccounts.length === allAdAccounts.length}
                  onChange={handleCheckAll}
                >
                  Selecionar tudo
                </Checkbox>
              </div>
            )}
          />
        </Col>

        <Col lg={3} className="my-1">
          <Label className="text-muted ms-2 mb-0" style={{ fontSize: 12 }}>
            Intervalo
          </Label>
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn btn-light w-100"
              type="button"
              style={{ minHeight: 38 }}
            >
              <span
                className="float-start text-truncate"
                style={{ fontWeight: 300, color: "#1675e0" }}
              >
                {selectedTimeInterval.name}
              </span>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 21 32"
                fill="currentColor"
                aria-hidden="true"
                focusable="false"
                className="rs-picker-toggle-caret rs-icon"
                aria-label="angle down"
                data-category="legacy"
              >
                <path d="M19.196 13.143a.612.612 0 01-.179.411l-8.321 8.321c-.107.107-.268.179-.411.179s-.304-.071-.411-.179l-8.321-8.321c-.107-.107-.179-.268-.179-.411s.071-.304.179-.411l.893-.893a.582.582 0 01.411-.179c.143 0 .304.071.411.179l7.018 7.018 7.018-7.018c.107-.107.268-.179.411-.179s.304.071.411.179l.893.893a.617.617 0 01.179.411z"></path>
              </svg>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end mt-2">
              <div className="d-flex justify-content-start">
                <div
                  className="d-none d-md-block"
                  style={{ flexDirection: "column" }}
                >
                  {date_presets.map((d) => (
                    <DropdownItem
                      key={d.code}
                      onClick={() => {
                        var preset = get_preset(d.code);

                        var date_object = {
                          name: d.label,
                          kind: "preset",
                          preset: d.code,
                          time_range: {
                            start_date: moment(preset.start).format(
                              "YYYY-MM-DD"
                            ),
                            end_date: moment(preset.end).format("YYYY-MM-DD"),
                            key: "selection",
                          },
                        };

                        setSelectedTimeInterval(date_object);

                        setState([
                          {
                            startDate: preset.start,
                            endDate: preset.end,
                            key: "selection",
                          },
                        ]);
                      }}
                    >
                      <span className="text-muted">{d.label}</span>
                    </DropdownItem>
                  ))}
                </div>

                <DateRangePicker
                  onChange={(item) => {
                    setState([item.selection]);
                    setSelectedTimeInterval({
                      name: "Personalizado",
                      kind: "custom",
                      time_range: {
                        start_date: moment(
                          [item.selection][0]?.startDate
                        ).format("YYYY-MM-DD"),
                        end_date: moment([item.selection][0]?.endDate).format(
                          "YYYY-MM-DD"
                        ),
                      },
                    });
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={state}
                  direction="vertical"
                  locale={rdrLocales.pt}
                  maxDate={new Date()}
                  minDate={(() => {
                    const currentDate = new Date();
                    currentDate.setFullYear(currentDate.getFullYear() - 1);
                    return currentDate;
                  })()}
                  inputRanges={[]}
                  staticRanges={[]}
                />
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>

        <Col lg={2}>
          <Label
            className="text-muted ms-2 mb-0 d-block"
            style={{ fontSize: 12 }}
          >
            &nbsp;
          </Label>
          {!props.account_general_granular.length &&
          props.ad_accounts.length ? (
            <button
              className="btn btn-success w-100 btn-green text-truncate"
              onClick={() => {
                loadAllInsightsData(selectedAdAccounts, selectedTimeInterval, [
                  { level: "account", mode: "aggregated" },
                  { level: "account", mode: "granular" },
                  { level: "campaign", mode: "aggregated" },
                ]);
              }}
            >
              Carregar Dados
            </button>
          ) : props.account_general_granular.length &&
            props.ad_accounts.length ? (
            <button
              className="btn btn-primary w-100 text-truncate"
              onClick={() => {
                loadAllInsightsData(selectedAdAccounts, selectedTimeInterval, [
                  { level: "account", mode: "aggregated" },
                  { level: "account", mode: "granular" },
                  { level: "campaign", mode: "aggregated" },
                ]);
              }}
            >
              <i className="mdi mdi-reload me-1" />
              Atualizar Dados
            </button>
          ) : (
            <button className="btn btn-light w-100  text-truncate" disabled>
              Carregar Dados
            </button>
          )}
        </Col>

        <Col lg={1} className="d-flex flex-column align-items-start">
          <Label
            className="text-muted ms-2 mb-0 d-block"
            style={{ fontSize: 12 }}
          >
            &nbsp;
          </Label>

          <ExportToSheetsModal
            {...props}
            loadInsightsData={loadInsightsData}
            selectedAdAccounts={selectedAdAccounts}
            selectedTimeInterval={selectedTimeInterval}
            loadingInsights={loadingInsights}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (store) => ({
  ad_accounts: store.Analytics.ad_accounts,
  account_general_granular: store.Analytics.account_general_granular,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
  setAdAccounts: (ad_acccounts) => dispatch(setAdAccounts(ad_acccounts)),
  setAccountSpecific: (account_specific) =>
    dispatch(setAccountSpecific(account_specific)),
  setAccountGeneralAggregated: (account_general_aggregated) =>
    dispatch(setAccountGeneralAggregated(account_general_aggregated)),
  setAccountGeneralGranular: (account_general_granular) =>
    dispatch(setAccountGeneralGranular(account_general_granular)),
  setAccountByDataSourceAggregated: (account_by_data_source_aggregated) =>
    dispatch(
      setAccountByDataSourceAggregated(account_by_data_source_aggregated)
    ),
  setAccountByDataSourceGranular: (account_by_data_source_granular) => {
    dispatch(setAccountByDataSourceGranular(account_by_data_source_granular));
  },
  setCampaignGeneralAggregated: (campaign_general_aggregated) =>
    dispatch(setCampaignGeneralAggregated(campaign_general_aggregated)),
  setCampaignGeneralGranular: (campaign_general_granular) =>
    dispatch(setCampaignGeneralGranular(campaign_general_granular)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightsComponent);
