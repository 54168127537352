import React, { useState } from "react";
import { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col } from "reactstrap";

const BannedAccount = () => {
  useEffect(() => {
    try {
      document.getElementById("topnav").style.display = "none";
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Conta Temporariamente Suspensa | Metrito</title>
      </MetaTags>
      <div
        className="w-100"
        style={{ height: 8, marginTop: 60, backgroundColor: "#e2022f" }}
      ></div>
      <div className="page-content ">
        <Container>
          <Row>
            <Col md={12}>
              <div className="text-center my-5 pt-sm-5">
                <div>
                  <Row className="justify-content-center">
                    <Col sm={5}>
                      <div>
                        <img
                          src={
                            "https://cdn-icons-png.flaticon.com/512/7440/7440637.png"
                          }
                          alt=""
                          className="img-fluid mx-auto d-block mb-4"
                          style={{ maxHeight: 150 }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <h4 className="text-uppercase mt-4">
                  Encontramos um problema com a sua conta
                </h4>
                <p className="text-muted fs-5">
                  Por favor entre em contato com o nosso <b>suporte</b> para
                  reativar seu acesso <br></br>
                </p>
                <div className="mt-5">
                  <a
                    className="btn btn-dark waves-effect waves-light px-5"
                    target={"_blank"}
                    href="https://api.whatsapp.com/send?phone=556297011897&text=Ol%C3%A1%2C%20gostaria%20de%20reativar%20minha%20conta%20%F0%9F%98%80"
                  >
                    <b className="fs-5">Reativar Acesso</b>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="w-100"
        style={{
          position: "absolute",
          height: 8,
          bottom: 60,
          backgroundColor: "#e2022f",
        }}
      ></div>
    </React.Fragment>
  );
};

export default BannedAccount;
