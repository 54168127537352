import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { blockOrNot } from "../functions/blockOrNot";
import api from "../services/api";

const StatusCard = (props) => {
  const [adAccountsData, setAdAccountsData] = useState([]);
  const [expiredAdAccounts, setExpiredAdAccounts] = useState([]);

  useEffect(() => {
    api
      .post("/api/v1/enterprise/fa/account/status", {
        ad_accounts: props.ad_accounts,
      })
      .then((res) => {
        setAdAccountsData(
          res.data.map((account) => ({ ...account, data_source: "FA" }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.ad_accounts]);

  return (
    <React.Fragment>
      {adAccountsData.length >= 1 ? (
        <div className="d-flex flex-row p-0">
          <Card
            className="d-none d-md-flex flex-row p-4 justify-content-between"
            style={{
              minWidth: 320,
              height: 148,
              border: "none",
              borderRadius: "10px 0px 0px 10px",
              zIndex: 10,
              boxShadow: "10px 0px 10px #00000008",
            }}
          >
            <div>
              <b className="text-muted fs-6 d-block text-truncate">
                Contas de Anúncio
              </b>
              <b style={{ fontSize: 50 }}>{adAccountsData.length}</b>
            </div>
            <div
              className="d-flex flex-column justify-content-around"
              style={{ fontSize: 12 }}
            >
              <div>
                <b>
                  {adAccountsData.filter((o) => o.account_status === 1).length}
                </b>
                <span className="text-success ms-2">Ativas</span>
              </div>
              <div>
                <b>
                  {adAccountsData.filter((o) => o.account_status === 3).length}
                </b>
                <span className="text-secondary ms-2">Problemáticas</span>
              </div>
              <div>
                <b>
                  {adAccountsData.filter((o) => o.account_status === 2).length}
                </b>
                <span className="text-danger ms-2">Bloqueadas</span>
              </div>
              <div>
                <b>
                  {" "}
                  {adAccountsData.filter((o) => o.status === "error").length}
                </b>
                <span className="text-muted ms-2">Token Expirado</span>
              </div>
            </div>
          </Card>
          <div
            className="d-flex flex-row align-items-center px-3 py-0 w-100"
            style={{ overflowX: "scroll", height: 145 }}
          >
            {adAccountsData.map((adAccount, key) => {
              const status = blockOrNot(adAccount.account_status);
              if (!!adAccount.account_status) {
                return (
                  <Card
                    key={key}
                    className="p-2 px-3 mx-2 shadow-sm my-auto"
                    style={{ minWidth: 220, height: 100 }}
                  >
                    <h6 className="text-truncate" style={{ lineClamp: 1 }}>
                      {adAccount.name}
                    </h6>
                    <span className="text-muted" style={{ fontSize: 12 }}>
                      Status:{" "}
                      <span className={`text-${status.color}`}>
                        {status.label}
                      </span>
                    </span>

                    <span className="text-muted" style={{ fontSize: 12 }}>
                      Total já gasto:{" "}
                      <span className="text-dark">
                        {parseFloat(
                          adAccount.amount_spent / 100
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </span>
                    <span className="text-muted" style={{ fontSize: 12 }}>
                      A pagar:{" "}
                      <span className="text-dark">
                        {parseFloat(adAccount.balance / 100).toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: "BRL",
                            maximumFractionDigits: 2,
                          }
                        )}
                      </span>
                    </span>
                  </Card>
                );
              } else {
                return (
                  <Card
                    key={key}
                    className="p-2 px-3 mx-2 shadow-sm my-auto"
                    style={{
                      minWidth: 220,
                      height: 100,
                      outline: "2px solid #e8694b",
                    }}
                  >
                    <h6>Conta com Token Expirado</h6>
                    <p className="text-muted" style={{ fontSize: 12 }}>
                      Vá em <b>Facebook</b> → <b>Contas de Anúncio</b> e
                      re-conecte a conta:
                      <br />
                      <b className="text-dark">{adAccount.name}</b>
                    </p>
                  </Card>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center text-muted"
          style={{
            height: 148,
            border: /builder/.test(window.location.href)
              ? "3px solid #F2A23B"
              : "none",
            borderRadius: 10,
          }}
        >
          <span className="align-middle">
            Você precisa selecionar Contas de Anúncio
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export default StatusCard;
