import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { setLoading } from "../store/analytics/actions";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  InputPicker,
  Modal,
  Table,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import api from "../services/api";
import $ from "jquery";

import { campaign_status_list } from "../common/data/campaign_statuses";
import { data_sources } from "../common/data/data_sources";
import { metricsArray } from "../common/data/metrics";
import { detectMobileBrowser } from "../functions/detectMobileBrowser";
import { formatByDisplayType } from "../functions/formatByDisplayType";

const { Column, HeaderCell, Cell } = Table;

const filter_picker_options = [
  {
    label: "Todas",
    value: "ALL",
  },
  ...campaign_status_list.map((item) => ({
    label: item.label,
    value: item.code,
  })),
];

const campaignObjectives = [
  {
    value: "REACH",
    name: "Alcance",
    icon: "mdi mdi-account-group",
    color: "#707070",
  },
  {
    value: "MESSAGES",
    name: "Mensagens",
    icon: "mdi mdi-chat-processing",
    color: "#707070",
  },
  {
    value: "LINK_CLICKS",
    name: "Clique no Link",
    icon: "mdi mdi-cursor-default-click",
    color: "#707070",
  },
  {
    value: "CONVERSIONS",
    name: "Conversões",
    icon: "mdi mdi-cash-check",
    color: "#707070",
  },
  {
    value: "VIDEO_VIEWS",
    name: "Visualização de Vídeo",
    icon: "mdi mdi-video-check",
    color: "#707070",
  },
  {
    value: "POST_ENGAGEMENT",
    name: "Engajamento",
    icon: "bx bxs-like",
    color: "#707070",
  },
  {
    value: "LEAD_GENERATION",
    name: "Geração de Leads",
    icon: "mdi mdi-magnet-on",
    color: "#707070",
  },
  //Novos Objetivos
  {
    value: "OUTCOME_APP_PROMOTION",
    name: "Promoção de App (Novo)",
    icon: "mdi mdi-new-box",
    color: "#707070",
  },
  {
    value: "OUTCOME_AWARENESS",
    name: "Reconhecimento (Novo)",
    icon: "mdi mdi-new-box",
    color: "#707070",
  },
  {
    value: "OUTCOME_ENGAGEMENT",
    name: "Engajamento (Novo)",
    icon: "mdi mdi-new-box",
    color: "#707070",
  },
  {
    value: "OUTCOME_LEADS",
    name: "Leads (Novo)",
    icon: "mdi mdi-new-box",
    color: "#707070",
  },
  {
    value: "OUTCOME_SALES",
    name: "Conversões (Novo)",
    icon: "mdi mdi-new-box",
    color: "#707070",
  },
  {
    value: "OUTCOME_TRAFFIC",
    name: "Tráfego (Novo)",
    icon: "mdi mdi-new-box",
    color: "#707070",
  },
];

const all_columns = [
  ...[
    {
      key: "data_source",
      label: "Fonte",
      fixed: !detectMobileBrowser(),
      width: 60,
    },
    {
      key: "account_name",
      label: "C. de Anúncios",
      fixed: !detectMobileBrowser(),
      width: 150,
      resizable: true,
    },
    {
      key: "name",
      label: "Campanhas",
      fixed: !detectMobileBrowser(),
      width: 300,
      resizable: true,
    },
    {
      key: "status",
      label: "Status",
      fixed: false,
      resizable: true,
    },
    {
      key: "id",
      label: "ID",
      fixed: false,
      resizable: true,
    },
    {
      key: "objective",
      label: "Objetivo",
      fixed: false,
      resizable: true,
    },
  ],
  ...metricsArray.map((item) => {
    return {
      key: item.value,
      label: item.label,
      fixed: false,
      width: 150,
      resizable: true,
    };
  }),
];

var default_columns = all_columns.slice(0, 4);

const thousands = (value) =>
  `${value}`.replace(/(?=(?!(\b))(\d{3})+$)/g, "$1,");

const NumberCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>{formatByDisplayType(rowData[dataKey], dataKey)}</Cell>
);

const HeaderSummary = (props) => {
  return (
    <div style={{ width: 500 }}>
      <label>{props.title}</label>
      <div
        style={{
          fontSize: 18,
          color: "#1e55eb",
        }}
      >
        {props.summary}
      </div>
    </div>
  );
};

const StatusHeaderSummary = (props) => {
  return (
    <div>
      <label>Status</label>
      <div
        style={{
          fontSize: 12,
          lineHeight: 2.5,
        }}
      >
        <div className="d-flex">
          {props.summary.map((item) => {
            const campaign_status = campaign_status_list.find(
              (o) => o.code === item.status
            );
            return (
              <span className="d-flex align-items-center mx-1 text-dark">
                <i
                  className="mdi mdi-circle mx-1 "
                  style={{
                    color: campaign_status?.color || "black",
                    fontSize: 10,
                  }}
                />
                {item.occurrence}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const DataSourceCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>
    <span className="mx-2">
      <i
        className={
          data_sources.find((i) => i.code === rowData[dataKey])?.icon || ""
        }
        style={{
          color:
            data_sources.find((i) => i.code === rowData[dataKey])?.color ||
            "#000",
          fontSize: 20,
        }}
      ></i>
    </span>
  </Cell>
);

const StatusCell = ({ rowData, dataKey, ...props }) => {
  const campaign_status = campaign_status_list.find(
    (o) => o.code === rowData[dataKey]
  );
  return (
    <Cell {...props}>
      <span className="d-flex align-items-center">
        <i
          className="mdi mdi-circle mx-2 "
          style={{ color: campaign_status.color, fontSize: 10 }}
        />
        {campaign_status.label}
      </span>
    </Cell>
  );
};

const ObjectiveCell = ({ rowData, dataKey, ...props }) => {
  const objective = campaignObjectives.find(
    (o) => o.value === rowData[dataKey]
  );

  return (
    <Cell {...props}>
      <span className="d-flex align-items-center">
        <i
          className={objective?.icon}
          style={{
            color: objective?.color || "#000",
            fontSize: 12,
            marginInlineEnd: 10,
          }}
        ></i>
        {objective?.name || "-"}
      </span>
    </Cell>
  );
};

const CampaignsTable = (props) => {
  const pickerAnchorRef = useRef(null);
  const pickerRef = useRef(null);
  const [pickerFilter, setPickerFilter] = useState("ALL");

  const [campaigns, setCampaigns] = useState([]);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const [openColumnsConfiguration, setOpenColumnsConfiguration] =
    React.useState(false);
  const handleOpen = () => setOpenColumnsConfiguration(true);
  const handleClose = () => {
    setOpenColumnsConfiguration(false);
  };

  const [value, setValue] = React.useState(["A", "C"]);
  const handleCheckAll = (value, checked) => {
    setColumnKeys(checked ? all_columns.map((o) => o.key) : []);
  };
  const [search, setSearch] = useState("");
  const filteredColumns =
    search.length > 0
      ? all_columns.filter((o) =>
          o.label
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.toLowerCase().replace(/ /g, ""))
        )
      : all_columns;

  const data = props.campaign_general_aggregated;

  // console.log(data.reduce((acc, item) => acc + item[key], 0));

  const [columnKeys, setColumnKeys] = React.useState([]);
  const columns = all_columns.filter((column) =>
    columnKeys.some((key) => key === column.key)
  );
  const CustomCell = Cell;
  const CustomHeaderCell = HeaderCell;

  const filterData = (data, filter) => {
    if (pickerFilter !== "ALL") {
      return data.filter((item) => item.status === filter);
    }
    return data;
  };

  const getData = () => {
    if (sortColumn && sortType) {
      return filterData(
        data.sort((a, b) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (typeof x === "string") {
            x = x.charCodeAt();
          }
          if (typeof y === "string") {
            y = y.charCodeAt();
          }
          if (sortType === "asc") {
            return x - y;
          } else {
            return y - x;
          }
        }),
        pickerFilter
      );
    }
    return filterData(data, pickerFilter);
  };

  const handleSortColumn = (sortColumn, sortType) => {
    props.setLoading(true);
    setTimeout(() => {
      props.setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 400);
  };

  function formatCampaignStatus(param) {
    switch (param) {
      case "PAUSED":
        return (
          <span>
            <i
              className="mdi mdi-checkbox-blank-circle"
              style={{ color: "#DBDDE0", fontSize: 10 }}
            ></i>{" "}
            &nbsp; Pausada
          </span>
        );
        break;
      case "ACTIVE":
        return (
          <span>
            <i
              className="spinner-grow text-success mx-3 ms-0 fs-6"
              style={{ height: 10, width: 10, margin: "auto" }}
            ></i>
            &nbsp; Ativa
          </span>
        );
        break;
      default:
        return "---";
    }
  }

  function getSummary(data, key) {
    const operation = metricsArray.find(
      (o) => o.value === key
    )?.merge_operation;

    if (key === "account_name") {
      const unique = [...new Set(data.map((item) => item[key]))].length;
      return unique;
    } else if (key === "data_source") {
      const selected_data_sources = [
        ...new Set(data.map((item) => item.data_source)),
      ];
      return selected_data_sources.map((o) => (
        <span className="mx-2">
          <i
            className={data_sources.find((i) => i.code === o)?.icon || ""}
            style={{
              color: data_sources.find((i) => i.code === o)?.color || "#000",
              fontSize: 20,
              marginInlineEnd: 10,
            }}
          ></i>
        </span>
      ));
    } else if (metricsArray.map((o) => o.value).includes(key)) {
      return formatByDisplayType(props.account_general_aggregated[key], key);
    } else {
      return data.length;
    }
  }

  function sumAllElementsFromArrayOfNumbers(array) {
    return array.reduce((a, b) => a + b, 0);
  }

  function findOccurrences(arr, key) {
    let arr2 = [];

    arr.forEach((x) => {
      // Checking if there is any object in arr2
      // which contains the key value
      if (
        arr2.some((val) => {
          return val[key] == x[key];
        })
      ) {
        // If yes! then increase the occurrence by 1
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["occurrence"]++;
          }
        });
      } else {
        // If not! Then create a new object initialize
        // it with the present iteration key's value and
        // set the occurrence to 1
        let a = {};
        a[key] = x[key];
        a["occurrence"] = 1;
        arr2.push(a);
      }
    });

    return arr2;
  }

  function updateDashboardColumnsInDatabase() {
    const columns = columnKeys;
    const data = {
      campaigns_table_columns: columns,
    };

    console.log(`Saving columns ${columns} to database...`);

    api
      .put(`/api/dashboards/${props.dashboard._id}`, data)
      .then((response) => {
        console.log(response.data);
        console.log("Columns saved to database!");
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        console.log("Error saving columns to database!");
        handleClose();
      });
  }

  useEffect(() => {
    if (props.dashboard?.configuration?.campaigns_table_filter) {
      setPickerFilter(props.dashboard.configuration.campaigns_table_filter);
    }

    if (props.dashboard.campaigns_table_columns?.length > 0) {
      setColumnKeys(props.dashboard.campaigns_table_columns);
    } else {
      setColumnKeys(all_columns.map((column) => column.key));
    }
  }, [props.dashboard]);

  useEffect(() => {
    $(".rs-picker-toggle").css({
      "background-color": "#eff2f7",
      "border-color": "#eff2f7",
      "border-radius": "10px",
    });
  }, []);

  useEffect(() => {
    if (
      Object.keys(props.dashboard).length > 0 &&
      pickerFilter !== props.dashboard.campaigns_table_filter
    ) {
      api
        .put(`/api/dashboards/config/${props.dashboard._id}`, {
          campaigns_table_filter: pickerFilter,
        })
        .then((response) => {
          console.log("Campaigns status filter saved to database!");
        })
        .catch((error) => {
          console.log("Error saving campaigns status filter to database!");
          console.log(error);
        });
    }
  }, [pickerFilter]);

  return (
    <React.Fragment>
      <div className="p-2" style={{ height: "100%", boxSizing: "border-box" }}>
        <div className="d-flex flex-column">
          <Row className="d-flex flex-row justify-content-between align-items-center px-sm-2 px-md-3 ">
            <Col lg={8} xs={12} className="my-1 my-md-0">
              <b className="fs-5">Campanhas</b>
            </Col>

            <Col lg={2} sm={12} className="my-1 my-md-0">
              <div
                className="d-flex align-items-center flex-wrap w-100"
                style={{ position: "relative" }}
              >
                <div ref={pickerAnchorRef} className="w-100">
                  <InputPicker
                    id="filter_input_picker"
                    cleanable={false}
                    defaultValue={"ALL"}
                    className="me-3 w-100"
                    style={{ width: 224 }}
                    container={() => pickerAnchorRef.current}
                    data={filter_picker_options}
                    onChange={(value) => {
                      setPickerFilter(value);
                    }}
                    value={pickerFilter}
                    renderValue={(value, item, selectedElement) => {
                      const campaign_status = campaign_status_list.find(
                        (o) => o.code === value
                      );

                      return (
                        <div className="d-flex align-items-center">
                          {value === "ALL" ? (
                            <img
                              src="https://static-00.iconduck.com/assets.00/wait-13-icon-256x256-cz2iwps4.png"
                              alt="Todas os status"
                              className="mx-2"
                              style={{ width: 12 }}
                            />
                          ) : (
                            <i
                              className="mdi mdi-circle mx-2 "
                              style={{
                                color: campaign_status?.color || "#000",
                                fontSize: 12,
                              }}
                            />
                          )}

                          <b className="text-muted">{item.label}</b>
                        </div>
                      );
                    }}
                    renderMenuItem={(label, item) => {
                      const campaign_status = campaign_status_list.find(
                        (o) => o.code === item.value
                      );

                      return (
                        <div className="d-flex align-items-center">
                          {item.value === "ALL" ? (
                            <img
                              src="https://static-00.iconduck.com/assets.00/wait-13-icon-256x256-cz2iwps4.png"
                              alt="Todas os status"
                              className="mx-2"
                              style={{ width: 12 }}
                            />
                          ) : (
                            <i
                              className="mdi mdi-circle mx-2 "
                              style={{
                                color: campaign_status?.color || "#000",
                                fontSize: 12,
                              }}
                            />
                          )}

                          <b className="text-muted">{item.label}</b>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col lg={2} sm={12} className="my-1 my-md-0">
              <button className="btn btn-light px-4 w-100" onClick={handleOpen}>
                <span className="d-flex flex-grow-1 text-truncate text-muted">
                  <i className="rs-icon rs-icon-user" />
                  Personalizar colunas
                </span>
              </button>
            </Col>
          </Row>
        </div>

        <Table
          id="campaigns-datatable"
          fillHeight
          headerHeight={100}
          style={{ width: "100%" }}
          className="d-flex flex-column"
          data={getData()}
          onRowClick={(rowData) => {
            console.log(rowData);
          }}
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
          loading={props.loading}
        >
          {columns.map((column) => {
            const { key, label, ...rest } = column;
            return (
              <Column {...rest} key={key} sortable>
                <HeaderCell>
                  {key === "status" ? (
                    <StatusHeaderSummary
                      title={label}
                      summary={findOccurrences(data, key)}
                      column={column}
                      align="center"
                    />
                  ) : (
                    <HeaderSummary
                      title={label}
                      summary={getSummary(data, key)}
                      column={column}
                      align="center"
                    />
                  )}
                </HeaderCell>
                {key === "data_source" ? (
                  <DataSourceCell dataKey={key} />
                ) : key === "status" ? (
                  <StatusCell dataKey={key} />
                ) : key === "objective" ? (
                  <ObjectiveCell dataKey={key} />
                ) : (
                  <NumberCell dataKey={key} />
                )}
              </Column>
            );
          })}
        </Table>
      </div>

      <Modal
        overflow={true}
        open={openColumnsConfiguration}
        onClose={updateDashboardColumnsInDatabase}
      >
        <Modal.Header>
          <Modal.Title>Configurações</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          <div>
            <div
              className="px-2 pt-4"
              style={{
                position: "sticky",
                top: -30,
                right: 0,
                zIndex: 100,
              }}
            >
              <span className="text-muted" style={{ fontSize: 12 }}>
                Pesquise as colunas que deseja adicionar
              </span>
              <Input
                style={{ maxWidth: 300 }}
                placeholder="Pesquisar..."
                value={search}
                onChange={(e) => setSearch(e)}
              />
              <hr className="divider mb-1" />
              <Checkbox
                indeterminate={
                  columnKeys.length > 0 &&
                  columnKeys.length < all_columns.length
                }
                checked={columnKeys.length === all_columns.length}
                onChange={handleCheckAll}
              >
                Selecionar todos
              </Checkbox>
              <hr className="divider mt-1" />
            </div>

            <CheckboxGroup
              inline
              name="checkboxList"
              value={columnKeys}
              onChange={setColumnKeys}
            >
              <div>
                {filteredColumns.map((item) => (
                  <div>
                    <Checkbox
                      key={item.key}
                      value={item.key}
                      className="d-block"
                    >
                      {item.label}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </CheckboxGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={updateDashboardColumnsInDatabase}
            appearance="subtle"
          >
            Fechar
          </Button>
          <Button
            onClick={updateDashboardColumnsInDatabase}
            appearance="primary"
          >
            Salvar configurações
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Drawer
        open={openColumnsConfiguration}
        onClose={() => setOpenColumnsConfiguration(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Configuração - Campanhas</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpenColumnsConfiguration(false)}>
              Fechar
            </Button>
            <Button
              onClick={() => setOpenColumnsConfiguration(false)}
              appearance="primary"
            >
              Salvar Configuração
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <Placeholder.Paragraph />
        </Drawer.Body>
      </Drawer> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.Analytics.loading,
  account_general_aggregated: state.Analytics.account_general_aggregated,
  campaign_general_aggregated: state.Analytics.campaign_general_aggregated,
  dashboard: state.Dashboard.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (value) => dispatch(setLoading(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsTable);
