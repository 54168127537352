import {
  Drawer,
  Modal,
  RadioGroup,
  Radio,
  ButtonToolbar,
  Button,
  IconButton,
  Placeholder,
} from "rsuite";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleDownIcon from "@rsuite/icons/legacy/AngleDown";
import AngleUpIcon from "@rsuite/icons/legacy/AngleUp";
import React, { useEffect } from "react";
import $ from "jquery";
import { getIdUsuario } from "../../services/auth";
import api from "../../services/api";
import axios from "axios";

const styles = {
  radioGroupLabel: {
    padding: "8px 12px",
    display: "inline-block",
    verticalAlign: "middle",
  },
};

const TermsOfServicePopup = (props) => {
  const [size, setSize] = React.useState("lg");
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState("left");
  const [disabledConfirmButton, setDisabledConfirmButton] =
    React.useState(true);
  const [termsOfService, setTermsOfService] = React.useState("");

  const handleOpen = (key) => {
    setOpen(true);
    setPlacement(key);
  };

  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop - 2 <=
      e.target.clientHeight
    ) {
      setDisabledConfirmButton(false);
      console.log("BOTTOM");
    }
  };

  function acceptTermsOfService() {
    api
      .put("/api/usuarios/", {
        _id: getIdUsuario(),
        has_accepted_terms_of_service: true,
      })
      .then((res) => {
        console.log("User accepted terms of service");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getTermsOfService() {
    axios
      .get("https://api.npoint.io/af25a37a78959a4e5861")
      .then((res) => {
        setTermsOfService(res.data.terms_of_service);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getTermsOfService();
    handleOpen("left");
  }, []);

  useEffect(() => {
    $(".rs-drawer-body-close").hide();
  }, [open]);

  return (
    <>
      <Modal
        size={size}
        placement={placement}
        open={open}
        onClose={() => setOpen(false)}
        backdrop={"static"}
      >
        <div
          className="p-2 d-flex align-items-center flex-wrap"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <Modal.Title>Termos de Serviço</Modal.Title>
          <span className="text-muted my-3 my-sm-0 me-0 me-sm-4">
            Veja todo o documento para clicar no botão
          </span>
          <Button
            onClick={() => acceptTermsOfService()}
            appearance="primary"
            className="btn-primary"
            disabled={disabledConfirmButton}
          >
            Li e aceito os termos de serviço.
          </Button>
        </div>

        <Modal.Body onScroll={(e) => handleScroll(e)} className="p-0">
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: termsOfService }}
          ></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TermsOfServicePopup;
