import React, { useState, useEffect } from "react";
import {
  Modal,
  button,
  Placeholder,
  SelectPicker,
  Loader,
  Tag,
  Button,
} from "rsuite";
import { CustomTooltip } from "./Common/Tooltip";
import { handleExport } from "../functions/exportToSheets";
import { connect } from "react-redux";

const formats = [
  {
    label: "CSV",
    value: "csv",
  },
  {
    label: "XLS",
    value: "xlsx",
  },
];
const levels = [
  {
    label: "Nível de conta",
    value: "account",
  },
  {
    label: "Nível de campanha",
    value: "campaign",
  },
];
const types = [
  {
    label: "Dados agregados",
    value: "aggregated",
  },
  {
    label: "Dados por dia",
    value: "granular",
  },
];

const ExportToSheetsModal = (props) => {
  const [exporting, setExporting] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [format, setFormat] = React.useState(formats[0].value);
  const [level, setLevel] = React.useState(levels[0].value);
  const [type, setType] = React.useState(types[0].value);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getData = async () => {
    console.log(props);

    var data = props[`${level}_general_${type}`];

    if (!Array.isArray(data)) {
      data = [data];
    }

    if (data.length) {
      return data;
    } else {
      data = await props.loadInsightsData(
        props.selectedAdAccounts,
        props.selectedTimeInterval,
        level,
        [type]
      );
      return data[0].data;
    }
  };

  return (
    <>
      <button
        appearance=""
        onClick={handleOpen}
        className="btn btn-light text-muted w-100 mb-0 p-1"
        style={{ fontSize: 12 }}
        type="button"
      >
        <i className="mdi mdi-file-export-outline text-muted fs-5" />
      </button>

      <Modal
        backdrop="static"
        keyboard={false}
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Exportar dados</Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-2">
          {props.loadingInsights ? (
            <div className="d-flex flex-column">
              <Loader
                className="text-muted"
                content={`Carregando dados para exportação...`}
                size="xs"
                speed="fast"
              />
            </div>
          ) : null}

          <div className="my-3">
            <div className="d-flex align-items-center">
              <span className="mb-1">Formato de exportação</span>
              <CustomTooltip
                placement={"right"}
                text={
                  "Formato no qual você deseja exportar os dados ex.: .csv ou .xls"
                }
              />
            </div>

            <SelectPicker
              cleanable={false}
              data={formats}
              defaultValue={formats[0].value}
              value={format}
              onChange={(value) => {
                setFormat(value);
              }}
              placeholder="Selecione o formato"
              style={{ width: "100%" }}
            />
          </div>

          <div className="my-3">
            <div className="d-flex align-items-center">
              <span className="mb-1">Nível dos dados</span>
              <CustomTooltip
                placement={"right"}
                text={
                  "O nível no qual deseja exportar os dados, a nível de conta por exemplo você irá exportar os dados compilados de toda a conta, envolvendo todas campanhas no período selecionado"
                }
              />
            </div>
            <SelectPicker
              cleanable={false}
              data={levels}
              defaultValue={levels[0].value}
              value={level}
              onChange={(value) => {
                setLevel(value);
              }}
              placeholder="Selecione o nível dos dados"
              style={{ width: "100%" }}
            />
          </div>

          <div className="my-3">
            <div className="d-flex align-items-center">
              <span className="mb-1">Tipo dos dados</span>
              <CustomTooltip
                placement={"right"}
                text={
                  "Escolha se deseja as métricas totalizadas para o período selecionado ou se deseja os dados detalhados por dia."
                }
              />
            </div>
            <SelectPicker
              cleanable={false}
              data={types}
              defaultValue={types[0].value}
              value={type}
              onChange={(value) => {
                setType(value);
              }}
              placeholder="Selecione o tipo dos dados"
              style={{ width: "100%" }}
            />
          </div>

          {props.loadingInsights ? (
            <div className="my-3 w-100">
              <Tag color="yellow" className="w-100">
                Por favor não feche ou recarregue a página
              </Tag>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="px-4"
            appearance="primary"
            style={{ backgroundColor: "#3b5998", borderColor: "#3b5998" }}
            loading={exporting}
            onClick={async () => {
              setExporting(true);
              const data = await getData();
              await handleExport(
                data,
                format,
                `metrito_${level}_${type}_${new Date().toLocaleDateString(
                  "pt-BR"
                )}`
              );
              setExporting(false);
            }}
          >
            Exportar
          </Button>
          <Button appearance="" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (store) => ({
  account_general_aggregated: store.Analytics.account_general_aggregated,
  account_general_granular: store.Analytics.account_general_granular,
  campaign_general_aggregated: store.Analytics.campaign_general_aggregated,
  campaign_general_granular: store.Analytics.campaign_general_granular,
});

export default connect(mapStateToProps)(ExportToSheetsModal);
