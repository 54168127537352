import React, { useEffect, useState } from "react";
import { Row, Col, Card, Alert, Container, Input } from "reactstrap";
import MetaTags from "react-meta-tags";
import InputMask from "react-input-mask";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//Toastify
import { toast } from "react-toastify";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/metrito-vector.svg";
import logolight from "../../assets/images/logo-light.png";
import api from "../../services/api";

//images
import mockup from "../../assets/images/metrito-mockup-2.png";
import { useLayoutEffect } from "react";
import { logout } from "../../services/auth";
import $ from "jquery";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/dashboard_lottie.json";
import { extractSearchParams } from "../../functions/extractSearchQueryParams";
import { getFingerprint } from "../../functions/getFingerprint";
import BouncingModal from "../../components/Popups/BouncingModal";
import { brandPacks } from "../../common/data/branding_packs";

const Register = (props) => {
  const [regStatus, setRegStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [rewardfulReferral, setRewardfulReferral] = useState({});
  const [showBouncingModal, setShowBouncingModal] = useState(false);

  const extracted_query_params = extractSearchParams(window.location);

  const handleShowBouncingModal = () => setShowBouncingModal(true);
  const handleCloseBouncingModal = () => setShowBouncingModal(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  async function handleSubmit(event) {
    logout();

    //Toast loading
    var create_user_toast = toast.loading("Verificando dados...");
    setLoading(true);

    const data = {
      name: event.target.name.value,
      email: event.target.email.value,
      password: event.target.password.value,
      tel_number: event.target.tel.value,
      // document: event.target.document.value,
      // address: event.target.address.value,
      rewardful_referral_id: rewardfulReferral.rewardful_referral_id,
      metadata: {
        ...extracted_query_params,
        fingerprint: await getFingerprint(),
      },
    };

    console.log("Creating user: ", data);

    const response = await api
      .post("/api/usuarios", data)
      .then((res) => {
        if (res.status === 200) {
          //GTM - Start Trial Event
          window.dataLayer.push({
            event: "start_trial",
            email: data.email,
            tel_number: data.tel_number,
          });

          setRegStatus("success");
          //Toast success
          toast.update(create_user_toast, {
            render: "Conta criada com sucesso!",
            type: "success",
            isLoading: false,
            autoclose: 1000,
          });
          setTimeout(() => (window.location.href = "/welcome"), 1000);
        } else {
          setRegStatus("error");
          toast.update(create_user_toast, {
            render: "Erro ao criar conta, por favor tente novamente",
            type: "error",
            isLoading: false,
            autoclose: 1000,
          });

          setTimeout(() => {
            setRegStatus("");
          }, 2000);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setRegStatus("error");
        toast.update(create_user_toast, {
          render:
            err.response.data.error ??
            "Problema ao criar usuário, verifique se já existe um usuário com este e-mail",
          type: "error",
          isLoading: false,
          autoclose: 1000,
        });

        setTimeout(() => {
          setRegStatus("");
        }, 2000);
      });
  }

  const testimonials = [
    // {
    //   name: "Mielle Mendes",
    //   company: "Grupo PGI",
    //   role: "CEO",
    //   picture:
    //     "https://metrito.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-11.50.14.png",
    //   text: "Agora é muito mais fácil a visualização dos dados das campanhas e tem nos ajudado muito. SImples e eficiente!",
    // },
    {
      name: "Iasmim Barbalho",
      company: "ETP Educação",
      role: "CFO",
      picture:
        "https://metrito.com/wp-content/uploads/2022/03/Screen-Shot-2022-03-25-at-18.03.09.png",
      text: "A ferramenta é simples e fácil de usar, todos os relatórios da empresa agora são feitos pela Metrito e tem nos ajudado demais na visualização e análise dos nossos dados!",
    },
    {
      name: "Jayson Vinicius",
      company: "Proload",
      role: "CEO",
      picture:
        "https://metrito.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-11.46.02.png",
      text: "Muito boa plataforma, agora conseguimos ver todos os dados das campanhas e tomar decisões mais rápidas e assertivas.",
    },
  ];

  useEffect(() => {
    window.rewardful("ready", function () {
      if (window.Rewardful.referral) {
        console.log("Current referral ID: ", window.Rewardful.referral);
        console.log(window.Rewardful.affiliate);

        const rewardful_data = {
          rewardful_referral_id: window.Rewardful.referral,
          ...window.Rewardful.affiliate,
        };

        setRewardfulReferral(rewardful_data);

        window.logReferralName(window.Rewardful.affiliate);
      } else {
        console.log("No referral present.");
      }
    });

    if (
      extracted_query_params.referral &&
      brandPacks.some((p) => p.key === extracted_query_params.referral)
    ) {
      handleShowBouncingModal();
    }
  }, []);

  useEffect(() => {
    if (rewardfulReferral.rewardful_referral_id) {
      toast(
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: 200, paddingLeft: 15 }}
        >
          <div>
            <h1>😆</h1>
          </div>
          <div className="text-center">
            <span className="fs-6">
              Legal! Você foi convidado por
              <br />
              <b className="text-primary">{rewardfulReferral.name}</b>!
            </span>
            <span className="d-block mt-3 text-muted">
              Ficamos super felizes de te ter por aqui, temos certeza que
              cresceremos muito juntos! Vamo ou bora?!
            </span>
          </div>
        </div>,
        {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  }, [rewardfulReferral]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Cadastro | Metrito - Criar Conta Grátis</title>
      </MetaTags>

      {showBouncingModal && (
        <BouncingModal
          showModal={true}
          closeModal={handleCloseBouncingModal}
          pack_id={extracted_query_params.referral}
        />
      )}

      <Container fluid>
        <Row className="align-items-center" style={{ backgroundColor: "#fff" }}>
          <Col xl={4} lg={5} md={12} sm={6}>
            <div className="p-lg-5 pt-lg-0 p-4 mx-lg-5">
              <Link to="/">
                <img
                  src={logo}
                  alt="metrito"
                  height="30"
                  className="auth-logo-dark"
                />
              </Link>
              <div className="mt-5">
                <h2>Cadastre-se</h2>
                <p className="text-muted">Crie sua conta Gratuita hoje!</p>
              </div>

              <div className="mt-4 pt-3">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={handleSubmit}
                >
                  {regStatus === "success" ? (
                    <Alert color="success">Usuário criado com sucesso</Alert>
                  ) : null}

                  {regStatus === "error" ? (
                    <Alert color="danger">Erro ao cadastrar usuário</Alert>
                  ) : null}

                  <div className="mb-3">
                    <AvField
                      name="name"
                      label="Nome"
                      type="text"
                      required
                      placeholder="Insira seu nome"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Por favor, digite seu Nome",
                          pattern: {
                            value: "^[A-Za-z0-9]+$",
                            errorMessage:
                              "Seu nome deve ser formado apenas de letras e números",
                          },
                        },
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      id="email"
                      name="email"
                      label="E-mail"
                      className="form-control"
                      placeholder="Inisira seu email"
                      type="email"
                      required
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Por favor, digite seu E-mail",
                        },
                        email: true,
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="tel"
                      label="Número de Telefone"
                      required
                      placeholder="Insira seu número de telefone"
                      mask="(99) 99999-9999"
                      maskChar=""
                      tag={[Input, InputMask]}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Por favor, digite seu telefone.",
                        },
                        minLength: {
                          value: 15,
                          errorMessage: "Por favor, digite um número válido.",
                        },
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="password"
                      label="Senha"
                      type="password"
                      required
                      placeholder="Insira sua senha"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Por favor, digite uma senha",
                        },
                        minLength: {
                          value: 8,
                          errorMessage:
                            "Por favor, digite uma senha com mais de 8 caracteres",
                        },
                      }}
                      className="mb-3"
                    />
                    <AvField
                      name="confirmationPassword"
                      label="Confirme a Senha"
                      type="password"
                      placeholder="Confirme sua senha"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Por favor, repita a senha",
                        },
                        match: { value: "password" },
                      }}
                    />
                  </div>

                  {/* <div className="mb-3">
                          <AvField
                            name="address"
                            label="Endereço"
                            type="address"
                            required
                            placeholder="Insira seu endereço"
                          />
                        </div> */}

                  <div className="ms-1 text-start">
                    <p className="mb-0 text-muted" style={{ fontSize: 12 }}>
                      Ao se cadastrar você concorda com os{" "}
                      <Link to="#" className="text-primary fw-semibold">
                        Termos de Uso
                      </Link>
                      &nbsp; da <b>Metrito</b>{" "}
                    </p>
                  </div>

                  <div className="mt-4 text-end">
                    <button
                      className="btn btn-success w-sm waves-effect waves-light w-100 fs-5"
                      style={{ fontWeight: 600 }}
                      type="submit"
                      disabled={loading}
                    >
                      <i className="" /> Criar minha conta
                    </button>
                  </div>
                </AvForm>
              </div>
            </div>

            <div className="mt-1 text-center text-muted">
              <p>
                Já tem uma conta ?{" "}
                <Link to="login" className="fw-semibold  text-primary">
                  {" "}
                  Fazer Login{" "}
                </Link>{" "}
              </p>
            </div>
            <div className="text-muted w-100 d-flex justify-content-center">
              <p>
                © {new Date().getFullYear()} <b>Metrito</b>. Desenvolvido com
                muito{" "}
                <i className="mdi mdi-heart" style={{ color: "#F7024B" }}></i>
              </p>
            </div>
          </Col>

          <Col
            xl={8}
            lg={7}
            md={12}
            className="d-none d-md-flex align-items-start"
            style={{
              backgroundColor: "#1e55eb",
              paddingLeft: 100,
              paddingRight: 100,
              minHeight: "100vh",
            }}
          >
            <div className="d-flex flex-column justify-content-between pb-5">
              <div>
                <Lottie
                  options={defaultOptions}
                  height={"auto"}
                  width={400}
                  style={{ margin: "none" }}
                />
              </div>

              <div>
                <h1 className="mx-5 mb-5 text-white" style={{ maxWidth: 700 }}>
                  Dados de todas as suas contas de anúncio, ao mesmo tempo e
                  automaticamente.
                </h1>

                <ul
                  className="register-ul text-white mx-3"
                  style={{ fontSize: 20, listStyleType: "none" }}
                >
                  <li>
                    <i
                      className="fas fa-plug text-primary text-white"
                      style={{ marginRight: 20 }}
                    />
                    Conecte todas as suas contas de anúncio{" "}
                    <b>com um clique!</b>
                  </li>
                  <li>
                    <i className="bx bxs-bolt text-white me-3" />
                    Crie Dashboards <b>poderosos</b> em menos de 2 minutos
                  </li>
                  <li>
                    <i className="bx bxs-share-alt text-white me-3" />
                    Compartilhe com seus clientes e/ou colaboradores
                  </li>
                  <li>
                    <i className="mdi mdi-bullseye-arrow text-white me-3" />
                    <b>Analise a performance</b> do seu time de tráfego
                  </li>
                </ul>
              </div>

              <div id="testimonials" className="d-flex flex-row ms-5 mt-5">
                {testimonials.map((testimonial, index) => (
                  <div
                    key={index}
                    className="p-3 mx-2"
                    style={{
                      border: "1px dashed #1583C9",
                      borderRadius: 10,
                    }}
                  >
                    <p className="text-white">{testimonial.text}</p>
                    <div className="d-flex flex-row">
                      <img
                        className="me-2"
                        src={testimonial.picture}
                        alt={testimonial.name}
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: 20,
                          objectFit: "cover",
                        }}
                      />
                      <div className="d-flex flex-column justify-content-center">
                        <h5 className="text-white mb-0">{testimonial.name}</h5>
                        <h6 className="text-white" style={{ fontSize: 12 }}>
                          {testimonial.role} - {testimonial.company}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Register;
