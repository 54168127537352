import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

// datatable related plugins

import { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "./datatables.scss";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//services
import api from "../../services/api";

import { useParams } from "react-router";
import { data_sources } from "../../common/data/data_sources";
import SelectDataSourceToConnect from "../Connections/SelectDataSourceToConnect.modal";

import { Placeholder } from "rsuite";

import ChangeAdAccountAlias from "../../components/Popups/ChangeAdAccountAlias.modal";
import AccountNameWithAliasSupport from "../../components/Common/AccountNameWithAliasSupport";
import CustomAlert from "../../components/CustomAlert";

function DatatableTables(props) {
  const { brandId } = useParams();

  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [dynamic_description, setdynamic_description] = useState("");
  const [selectedAdaccount, setSelectedAdaccount] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);

  const { SearchBar } = Search;

  async function handleDelete(adaccount) {
    const result = await api.delete("/api/ad-account/remove/" + brandId, {
      data: { ds_id: adaccount },
    });
    if (result.status === 200) {
      console.log("Conta deletada com sucesso");
    } else {
      console.log("Erro ao deletar conta");
    }
  }

  const handleOpen = () => {
    setOpenEditModal(true);
  };

  const handleClose = () => {
    setOpenEditModal(false);
  };

  const handleUpdate = () => {
    window.location.reload();
  };

  return (
    <React.Fragment>
      <ChangeAdAccountAlias
        account={selectedAdaccount}
        brandId={brandId}
        dataSource={data_sources.find(
          (ds) => ds.code === selectedAdaccount.data_source
        )}
        handleClose={handleClose}
        open={openEditModal}
        handleUpdate={handleUpdate}
      />

      {confirm_alert ? (
        <CustomAlert
          title="Tem certeza?"
          warning
          showCancel
          confirmButtonText="Sim, quero apagar!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false);
            setsuccess_dlg(true);
            setdynamic_title("Conta Removida");
            setdynamic_description("Sua conta de anúncios foi removida.");

            handleDelete(selectedAdaccount.ds_id);
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Seus dados e snapshots serão apagados de forma irreversível!
        </CustomAlert>
      ) : null}
      {success_dlg ? (
        <CustomAlert
          success
          title={dynamic_title}
          confirmBtnStyle={{color: "white"}}
          onConfirm={() => {
            setsuccess_dlg(false);
            window.location.reload();
          }}
        >
          {dynamic_description}
        </CustomAlert>
      ) : null}

      {/* Tabela Bootstrap */}
      <Card>
        <CardBody>
          <Row>
            <Col lg={8} md={6} sm={12} className="d-flex flex-row ps-3">
              <div className="me-3 pt-1">
                <i className="bx bxs-bolt-circle fs-1 text-dark" />
              </div>
              <div>
                <h4 className="card-title">Contas de Anúncio Conectadas</h4>
                <p className="card-title-desc">
                  Todas as contas de anúncio vinculadas a esta empresa.
                </p>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <SelectDataSourceToConnect brand_id={brandId} />
            </Col>
          </Row>

          {props.loading ? (
            <Placeholder.Grid rows={5} columns={4} active />
          ) : (
            <div className="table-responsive border rounded">
              <Table className="table  table-hover mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Plat.</th>
                    <th>Nome</th>
                    <th>ID da Conta</th>
                    <th>Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {props.adaccounts.map((adaccount) => {
                    return (
                      <tr
                        style={{ verticalAlign: "middle", height: 60 }}
                        key={adaccount.ds_id}
                      >
                        <td>
                          <i
                            className={`fs-2 ${
                              data_sources.find(
                                (ds) => ds.code === adaccount.data_source
                              ).icon
                            }`}
                            style={{
                              color: data_sources.find(
                                (ds) => ds.code === adaccount.data_source
                              ).color,
                            }}
                          />
                        </td>
                        <td style={{ minWidth: 120 }}>
                          <AccountNameWithAliasSupport
                            account={adaccount}
                            titleSize={6}
                          />
                        </td>
                        <td>
                          {adaccount.data_source === "AW"
                            ? adaccount.ds_id
                                .split("-")[1]
                                .toString()
                                .match(/\d{3}(?=\d{2,3})|\d+/g)
                                .join("-")
                            : adaccount.ds_id}
                        </td>

                        <td style={{ minWidth: 40 }} className="text-center">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="text-muted font-size-16"
                              tag="a"
                              role="button"
                            >
                              <i className="mdi mdi-dots-vertical i-btn fs-4"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                onClick={(e) => {
                                  setSelectedAdaccount(adaccount);
                                  handleOpen();
                                }}
                              >
                                <i className="mdi mdi-pencil fs-6 me-2"></i>
                                Editar apelido da conta
                              </DropdownItem>

                              <DropdownItem
                                onClick={(e) => {
                                  setSelectedAdaccount(adaccount);
                                  setconfirm_alert(true);
                                }}
                              >
                                <i
                                  role="button"
                                  className="mdi mdi-trash-can fs-6 text-danger float-start me-2"
                                />
                                Remover conta
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default DatatableTables;
