import $ from "jquery";
import { brandPacks } from "../common/data/branding_packs";

function hexToRgb(hex) {
  // Remove the # character, if it exists
  hex = hex.replace("#", "");

  // Convert the hex value to separate red, green, and blue values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the RGB color value as a string
  return `${r}, ${g}, ${b}`;
}

export const checkBrandingPack = (branding_pack) => {
  try {
    if (branding_pack) {
      const pack = brandPacks.find((o) => o.key === branding_pack);

      const rootStyle = document.documentElement.style;
      rootStyle.setProperty("--primary-color", pack.topbar);
      rootStyle.setProperty("--secondary-color", pack.button_primary);
      rootStyle.setProperty(
        "--bs-nav-pills-link-active-bg",
        pack.button_primary
      );

      // document.documentElement.style.setProperty(
      //   "--bs-primary-rgb",
      //   hexToRgb(pack.button_primary)
      // );

      if (
        /builder/.test(window.location.href) ||
        /report/.test(window.location.href)
      ) {
        $(".header-logo").attr("src", pack.logo_url_dark_theme);
        $(".header-logo").attr("src", pack.logo_url_dark_theme);
        $(".report-logo").attr("src", pack.logo_url_dark_theme);

        // console.log("builder here");
      } else if (window.localStorage.getItem("theme") !== "dark") {
        $(".header-logo").attr("src", pack.logo_url);
        $(".report-logo").attr("src", pack.logo_url);
        console.log("theme is light");
      } else {
        $("#page-topbar").removeAttr("style");
        $(".navbar-header").removeAttr("style");
        $(".topnav").removeAttr("style");
        $(".header-logo").attr("src", pack.logo_url_dark_theme);
        console.log("theme is dark");
      }

      if (pack.navbar_text_inverse) {
        $(".navbar-header i").css({ color: "white" });
        $(".navbar-header span").css({ color: "white" });
      }

      return true;
    }
  } catch (err) {
    console.log(err);
  }
  return false;
};
