import React, { useEffect } from "react";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { brandPacks } from "../../common/data/branding_packs";

const modalStyle = {
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.3)",
  zIndex: 100,
};

const contentStyle = {
  width: "80%",
  maxWidth: "700px",
  background: "white",
  borderRadius: "10px",
  padding: "40px",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
};

const backdropVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const modalVariants = {
  hidden: { y: "100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      bounce: 0.4,
      duration: 0.5,
      type: "spring",
      stiffness: 100,
      mass: 0.75,
      damping: 15,
    },
  },
  exit: { y: "100vh", opacity: 0, transition: { duration: 0.3 } },
};

const BouncingModal = ({ showModal, closeModal, pack_id }) => {
  const backdropControls = useAnimation();
  const modalControls = useAnimation();
  const branding_pack = brandPacks.find((pack) => pack.key === pack_id);

  useEffect(() => {
    if (showModal) {
      backdropControls.start("visible");
      modalControls.start("visible");
    } else {
      backdropControls.start("hidden");
      modalControls.start("exit");
    }
  }, [showModal, backdropControls, modalControls]);

  if (!showModal) return null;

  return (
    <motion.div style={modalStyle} onClick={closeModal} initial="hidden" animate={backdropControls} exit="exit" variants={backdropVariants}>
      <motion.div
        style={contentStyle}
        onClick={(e) => e.stopPropagation()}
        initial="hidden"
        animate={modalControls}
        variants={modalVariants}
        className="d-flex flex-column justify-content-center align-items-center text-center"
      >
        {branding_pack.use_custom_image ? (
          <img src={branding_pack.custom_image_url} alt={branding_pack.key} style={{ maxWidth: "70%", maxHeight: 250, borderRadius: 10 }} />
        ) : (
          <img src={branding_pack.logo_url} alt={branding_pack.key} style={{ maxWidth: "70%" }} />
        )}
        <hr className="divider" />
        <p>{branding_pack.description}</p>

        <button className="btn btn-success px-5 mt-4" onClick={() => closeModal()}>
          Continuar
        </button>
      </motion.div>
    </motion.div>
  );
};

export default BouncingModal;
