import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Input } from "rsuite";
import { Card, Container } from "reactstrap";
import { SelectPicker, Loader } from "rsuite";
import { gateways } from "../../common/data/data_sources";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";
import { toast } from "react-toastify";
import classNames from "classnames";
import $ from "jquery";
import { useEffect } from "react";
import { getImageURL } from "../../functions/getImageURL";
import SweetAlert from "react-bootstrap-sweetalert";
import BrandPicker from "../../components/BrandPicker";
import CustomAlert from "../../components/CustomAlert";

// import api from "../../common/api";
const status_list = [
  {
    key: "active",
    label: "Ativo",
    color: "success",
    icon: "mdi mdi-check",
  },
  {
    key: "development",
    label: "Em desenvolvimento",
    color: "success",
    icon: "mdi mdi-cog",
  },
  {
    key: "maintenance",
    label: "Manutenção",
    color: "muted",
    icon: "mdi mdi-wrench",
  },
  {
    key: "soon",
    label: "Em breve",
    color: "muted",
    icon: "mdi mdi-clock",
  },
];
const getStatus = (status) => status_list.find((s) => s.key === status);

function hideThanShow() {
  $("#gateway-form").hide(100);
  setTimeout(() => {
    $("#gateway-form").show(100);
  }, 200);
}

const components = [
  {
    key: "name",
    component: (
      <>
        <label className="mt-2">Nome da conta</label>
        <Input
          id="name"
          placeholder="Dê um nome para essa conta na qual você irá conectar..."
          required
        />
        <p className="text-muted mt-1" style={{ fontSize: 12 }}>
          Não se preocupa ta? Você pode mudar isso depois.. ;D
        </p>
      </>
    ),
  },
  {
    key: "alias",
    component: (
      <>
        <label className="mt-2">Alias</label>
        <Input id="alias" placeholder="Alias da sua loja" />
      </>
    ),
  },
  {
    key: "token",
    component: (
      <>
        <label className="mt-2">Token</label>
        <Input
          id="token"
          placeholder="Cole aqui o seu token de acesso da plataforma"
          as="textarea"
          rows={3}
        />
      </>
    ),
  },
  {
    key: "client_id",
    component: (
      <>
        <label className="mt-2">Client ID</label>
        <Input id="client_id" placeholder="client_id da sua conta" />
      </>
    ),
  },
  {
    key: "client_secret",
    component: (
      <>
        <label className="mt-2">Client Secret</label>
        <Input id="client_secret" placeholder="client_secret da sua conta" />
      </>
    ),
  },

  {
    key: "secret_key",
    component: (
      <>
        <label className="mt-2">Chave Secreta</label>
        <Input id="secret_key" placeholder="User secret key" />
      </>
    ),
  },

  {
    key: "email",
    component: (
      <>
        <label className="mt-2">E-mail</label>
        <Input type="email" id="email" placeholder="Email da sua conta" />
      </>
    ),
  },
  {
    key: "public_key",
    component: (
      <>
        <label className="mt-2">Chave pública</label>
        <Input id="public_key" placeholder="Public key da sua conta" />
      </>
    ),
  },
  {
    key: "api_key",
    component: (
      <>
        <label className="mt-2">Chave da API</label>
        <Input id="api_key" placeholder="Api key da sua conta" />
      </>
    ),
  },
];

const GatewaysConnect = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [selectedGatewayOption, setSelectedGatewayOption] = React.useState(
    gateways[0]
  );
  const [brands, setBrands] = useState([]);
  const [success, setSuccess] = useState(false);
  const [connecting, setConnecting] = useState(false);

  async function loadBrandList() {
    setLoading(true);
    const response = await api
      .get("/api/v1/brands/")
      .then((res) => {
        if (res.data.length !== 0) {
          setBrands(res.data.reverse());

          if (queryParams.has("brand")) {
            setSelectedBrand(
              res.data.find((brand) => brand._id === queryParams.get("brand"))
            );
          } else {
            setSelectedBrand(res.data[0]);
          }

          setLoading(false);
        } else {
          setBrands(res.data);
          console.log("Sem Empresas para exibir!");

          toast.info("Você ainda não adicionou Empresas.");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Não foi possível carregar suas Empresas.");
        setLoading(false);
      });
  }

  function connectGateway() {
    console.log("connecting gateway");
    setConnecting(true);

    var data = selectedGatewayOption.connect_fields.reduce(
      (a, v) => ({ ...a, [v]: $(`#${v}`).val() }),
      {}
    );

    data = {
      data_source: selectedGatewayOption.code,
      ...data,
    };

    api
      .post(`/api/gateway/connect/${selectedBrand._id}`, data)
      .then((res) => {
        setSuccess(true);
        setConnecting(false);
      })
      .catch((err) => {
        console.log(err);
        setSuccess(false);
        setConnecting(false);
      });
  }

  useEffect(() => {
    loadBrandList();
  }, []);

  var data = brands.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  console.log(selectedGatewayOption.connect_fields);

  return (
    <>
      <MetaTags>
        <title>Conectar Gateways de Pagamento | Metrito</title>
      </MetaTags>
      <div className="page-content">
        <Container className="pb-5">
          <div className="mb-4">
            <h5>Empresa vinculada</h5>
            <p className="text-muted">
              Selecione a empresa no qual deseja conectar o gateway.
            </p>
            <div className="d-flex flex-row">
              <BrandPicker
                disabled={connecting}
                brands={brands}
                loading={loading}
                currentBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
              />
            </div>
          </div>

          <div>
            <h5>Gateway de Pagamento</h5>
            <p className="text-muted">
              Selecione o gateway de pagamento que deseja conectar.
            </p>
            <div
              className="d-flex flex-row scrollbar-hidden"
              style={{ overflowX: "scroll", overflowClipMargin: "margin-box" }}
            >
              {gateways.map((gateway) => (
                <div
                  style={{ position: "relative", borderRadius: 10 }}
                  className={classNames(
                    {
                      "gateway-option-selected":
                        selectedGatewayOption.code === gateway.code,
                    },
                    "gateway-option"
                  )}
                  onClick={() => {
                    if (
                      selectedGatewayOption.code !== gateway.code &&
                      gateway.status === "active"
                    ) {
                      hideThanShow(gateway);
                    } else {
                      return;
                    }
                    setTimeout(() => {
                      setSelectedGatewayOption(gateway);
                    }, 100);
                  }}
                >
                  <>
                    {gateway.status !== "active" && (
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(255,255,255,0.5)",
                          backdropFilter: "blur(2px)",
                          zIndex: 2,
                          borderRadius: 10,
                        }}
                        className="d-flex flex-column justify-content-end align-items-center"
                      >
                        <div
                          className="m-0 px-3 py-1 card border-0 shadow-sm"
                          style={{
                            borderRadius: "5px 5px 0px 0px",
                            maxWidth: "80%",
                          }}
                        >
                          <small
                            className={`text-${
                              getStatus(gateway.status).color
                            } text-truncate`}
                          >
                            <i
                              className={`${
                                getStatus(gateway.status).icon
                              } me-1`}
                            />
                            {getStatus(gateway.status).label}
                          </small>
                        </div>
                      </div>
                    )}
                  </>
                  <img
                    style={{ maxWidth: 120, objectFit: "contain" }}
                    className="gateway-option-icon"
                    src={gateway.icon_url}
                    alt={gateway.label}
                  />
                </div>
              ))}
            </div>
          </div>

          <div style={{ minHeight: 600 }}>
            <div id="gateway-form" className="mt-4">
              <Card className="p-4 d-flex flex-column align-items-start">
                <h5>Dados para conexão</h5>
                <p className="text-muted text-truncate">
                  Insira os dados para conectar as contas do gateway de
                  pagamento.
                </p>

                <img
                  src={selectedGatewayOption.icon_url}
                  alt={selectedGatewayOption.label}
                  style={{ maxWidth: 170, maxHeight: 40, objectFit: "contain" }}
                  className="my-4"
                />

                {components
                  .filter((c) =>
                    selectedGatewayOption.connect_fields.includes(c.key)
                  )
                  .map((c) => c.component)}

                <div className="d-flex justify-content-end w-100">
                  <button
                    disabled={connecting}
                    inverse={true}
                    className="btn btn-success mt-4 px-5 d-flex align-items-center"
                    onClick={() => connectGateway()}
                  >
                    {connecting && <Loader inverse className="me-2"></Loader>}
                    Conectar
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </Container>
      </div>

      {success ? (
        <CustomAlert
          title="Perfeito!"
          success
          showCancel
          onConfirm={() => {
            setSuccess(false);
          }}
          onCancel={() => {
            setSuccess(false);
          }}
        >
          Sua Conta no Gateway <b>{selectedGatewayOption.label}</b> foi
          conectada com sucesso!
        </CustomAlert>
      ) : null}
    </>
  );
};

export default GatewaysConnect;
