export const fb_payment_types = [
  {
    number: 0,
    code: "UNSET",
    label: "Forma de pagamento não configurada",
    icon: "mdi mdi-help-circle-outline",
    color: "#000",
  },
  {
    number: 1,
    code: "CREDIT_CARD",
    label: "Cartão de crédito",
    icon: "mdi mdi-credit-card",
    color: "#000",
  },
  {
    number: 2,
    code: "FACEBOOK_WALLET",
    label: "Facebook Wallet",
    icon: "mdi mdi-facebook",
  },
  {
    number: 3,
    code: "FACEBOOK_PAID_CREDIT",
    label: "Facebook Paid Credit",
    icon: "mdi mdi-facebook",
  },
  {
    number: 4,
    code: "FACEBOOK_EXTENDED_CREDIT",
    label: "Facebook Extended Credit",
    icon: "mdi mdi-facebook",
  },
  {
    number: 5,
    code: "ORDER",
    label: "Pedido",
    icon: "mdi mdi-barcode",
  },
  {
    number: 6,
    code: "INVOICE",
    label: "Fatura",
    icon: "mdi mdi-receipt",
  },
  {
    number: 7,
    code: "FACEBOOK_TOKEN",
    label: "Token do Facebook",
    icon: "mdi mdi-facebook",
  },
  {
    number: 8,
    code: "EXTERNAL_FUNDING",
    label: "financiamento externo",
    icon: "mdi-bank-transfer-in",
  },
  {
    number: 9,
    code: "FEE",
    label: "Taxa",
    icon: "mdi mdi-cash-usd-outline",
  },
  {
    number: 10,
    code: "FX",
    label: "FX",
    icon: "mdi mdi-cash-usd-outline",
  },
  {
    number: 11,
    code: "DISCOUNT",
    label: "Desconto",
    icon: "mdi mdi-ticket-percent",
  },
  {
    number: 12,
    code: "PAYPAL_TOKEN",
    label: "Token do Paypal",
    icon: "bx bxl-paypal",
  },
  {
    number: 13,
    code: "PAYPAL_BILLING_AGREEMENT",
    label: "Contrado de cobrança do Paypal",
    icon: "bx bxl-paypal",
  },
  {
    number: 14,
    code: "FS_NULL",
    label: "FS_NULL",
    icon: "mdi mdi-null",
  },
  {
    number: 15,
    code: "EXTERNAL_DEPOSIT",
    label: "Depósito Externo",
    icon: "mdi mdi-bank-transfer-out",
  },
  {
    number: 16,
    code: "TAX",
    label: "Imposto",
    icon: "mdi mdi-barcode",
  },
  {
    number: 17,
    code: "DIRECT_DEBIT",
    label: "Débito Direto",
    icon: "mdi mdi-barcode",
  },
  {
    number: 18,
    code: "DUMMY",
    label: "Fictício",
    icon: "mdi mdi-test-tube",
  },
  {
    number: 20,
    code: "STORED_BALANCE",
    label: "Saldo Armazenado",
    icon: "mdi mdi-account-cash",
  },
];

export const getFacebookPaymentType = (number) => {
  return fb_payment_types.find((item) => item.number === number);
};
