export function getRemainingDays(regDate, trialEndDate, currentDate) {
  // convert dates to Unix timestamps
  const regDateUnix = Date.parse(regDate);
  const trialEndDateUnix = Date.parse(trialEndDate);
  const currentDateUnix = Date.parse(currentDate);

  // calculate total time of trial in Unix
  const totalTimeUnix = trialEndDateUnix - regDateUnix;
  const totalDays = Math.round(totalTimeUnix / (1000 * 60 * 60 * 24));

  // calculate remaining time of trial in Unix
  const remainingTimeUnix = trialEndDateUnix - currentDateUnix;
  const remainingDays = Math.round(remainingTimeUnix / (1000 * 60 * 60 * 24));

  // calculate percentage of remaining time
  const percentageRemaining = parseFloat(
    ((remainingTimeUnix / totalTimeUnix) * 100).toFixed(2)
  );

  return {
    totalDays,
    remainingDays,
    remainingTimeUnix,
    percentageRemaining,
  };
}
