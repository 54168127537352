import React from "react";

export function parseTextWithURLs(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((m, i) => {
    if (i % 2 === 1) {
      return (
        <a href={m} key={i} target="_blank" rel="noopener noreferrer">
          {m}
        </a>
      );
    } else {
      return m;
    }
  });
}
