import React from "react";
import { useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getWindow } from "rsuite/esm/DOMHelper";
import { Modal, ButtonToolbar, Button, Placeholder } from "rsuite";
import Dante from "Dante2";
import { convertFromRaw, convertToRaw } from "draft-js";

import _ from "lodash";

const wrapperStyle = {
  border: "1px solid #f1f1f1",
  borderRadius: 10,
};
const editorStyle = {
  padding: "1rem",
  borderRadius: 10,
  bottom: 0,
  left: 0,
  right: 0,
  top: 80,
  position: "absolute",
};

const style = {
  width: "50%",
  display: "inline-block",
  overflow: "auto",
  verticalAlign: "top",
};

const TextEditor = (props) => {
  const [content, setContent] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const handleOpen = (value) => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
    const stringifiedContent = JSON.stringify(content);

    props.setWidget({
      ...props.widget,
      content: stringifiedContent,
    });
  };

  const save_handler = (editorContext, content) => {
    setContent(content);
  };

  let contentState = {};
  try {
    contentState = convertFromRaw(content);
  } catch (e) {}

  const [danteProps, setDanteProps] = React.useState({
    body_placeholder: "Digite o que você quiser aqui...",
    data_storage: {
      url: "xxx",
      save_handler: save_handler,
    },
  });

  useEffect(() => {
    console.log("carregou");

    if (props.widget.content) {
      setDanteProps({
        ...danteProps,
        content: JSON.parse(props.widget.content),
      });
    }
  }, [props.widget]);

  return (
    <>
      <Modal size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Widget de texto</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "35vh", padding: 45 }}>
          <Dante {...danteProps} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Fechar
          </Button>
          <Button onClick={handleClose} appearance="primary" color="green">
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        onClick={() => {
          if (props.widget.content) {
            console.log("content", props.widget.content);
            setDanteProps({
              ...danteProps,
              content: JSON.parse(props.widget.content),
            });
          }
          handleOpen("lg");
        }}
      >
        <div
          style={{
            height: 200,
            overflowY: "scroll",
            padding: 0,
            border: "1px solid #f4f4f4",
            borderRadius: 10,
            position: "relative",
          }}
        >
          <Dante
            {...danteProps}
            read_only={true}
            style={{
              transform: "scale(0.7)",
              transformOrigin: "top bottom",
            }}
          />
        </div>

        <button className="btn btn-light w-100 mb-5 mt-3">
          Editar conteúdo
        </button>
      </div>
    </>
  );
};

export default TextEditor;
