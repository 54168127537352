import React, { useState } from "react";

import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Col } from "reactstrap";

const AdminAreaChart = (props) => {
  const [metric, setMetric] = useState();

  var options = {
    chart: {
      height: 290,
      type: "area",
      // id: props.chartID,
      // group: props.group,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    labels: props.data.map((a) => a.date),
    colors: ["#00E396", "#00E396", "#CED4DC"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    xaxis: {
      type: "datetime",
      tickPlacement: "on",
    },

    grid: {
      borderColor: "#f1f1f1",
    },
  };
  const series = [
    {
      name: props.title,
      data: props.data.map((o) => o.counts),
    },
  ];

  return (
    <React.Fragment>
      <Col lg={12} xl={12} md={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.title}</CardTitle>
            {/* <span>{props.chartID}</span> */}
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height="250"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AdminAreaChart;
