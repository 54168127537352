import moment from "moment";
import React, { useEffect, useState } from "react";
import { CardBody, CardTitle } from "reactstrap";

import { defineMetric } from "../functions/metricFormatter";

import ReactApexChart from "react-apexcharts";

const LineChart = (props) => {
  const [metric, setMetric] = useState();

  const series = [
    {
      name: props.selected.title,
      data: metric || [],
    },
  ];
  var options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    annotations: {
      yaxis:
        props.selected.lines?.map((line) => ({
          y: line.limit,
          borderColor: line.color || "#000",
          strokeDashArray: 5,
          label: {
            borderColor: line.color || "#000",
            style: {
              color: "#fff",
              background: line.color || "#000",
            },
            text: line.text,
          },
        })) || [],
    },
    colors: ["#0050ff", "#0ab39c"],
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 0,
      hover: {
        size: 5,
      },
    },
    labels: props.metrics.map((a) =>
      moment(a.date_stop).toLocaleString("pt-BR")
    ),
    xaxis: {
      type: "datetime",
      tickPlacement: "on",
    },
    yaxis: {
      labels: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
  };

  useEffect(() => {
    setMetric(defineMetric(props.metrics, props.selected.type));
  }, [props]);

  return (
    <React.Fragment>
      <CardBody>
        <CardTitle className="mb-2">{props.selected.title}</CardTitle>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height="250"
        />
      </CardBody>
    </React.Fragment>
  );
};

export default LineChart;
