import React, { useState, useEffect } from "react";
import { BroadcastChannel } from "broadcast-channel";
import axios from "axios";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Button, Checkbox } from "rsuite";
import { copyToClipboard } from "../../functions/copyToClipboard";
import MetaTags from "react-meta-tags";

const DebugGoogleAdsToken = () => {
  const google_client_id =
    "402494766896-63imgi256phlpi066l6fgtki588eaeri.apps.googleusercontent.com";
  const [googleUser, setGoogleUser] = useState({});
  const [googleAuthResponse, setGoogleAuthResponse] = useState({});
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  function loadGoogleUser(access_token) {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${access_token}`
      )
      .then((res) => {
        // console.log(res.data);
        setGoogleUser({ ...res.data, access_token });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (googleAuthResponse.refresh_token) {
      loadGoogleUser(googleAuthResponse.access_token);
    } else if (
      googleAuthResponse.access_token &&
      !googleAuthResponse.refresh_token
    ) {
      alert("Erro, por favor tentar novamente");
      setLoading(false);
    }
  }, [googleAuthResponse]);

  const bc = new BroadcastChannel("ga_channel");
  useEffect(() => {
    bc.onmessage = (e) => {
      // console.log(e);
      console.log("Token received with success.");
      setGoogleAuthResponse(e);
      setLoading(false);
    };
  }, []);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Google Ads Token Debugger | Metrito</title>
      </MetaTags>
      <Container>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="w-100 mb-4">
                  <h4>Google Ads Token Debugger</h4>
                  <p className="text-muted">
                    Essa ferramenta deve ser utilizada para a extração e
                    verificação do Token retornado pelo Google Ads.
                  </p>
                </div>

                {googleAuthResponse.refresh_token && (
                  <div
                    style={{
                      border: "2px dashed #00cc47",
                      borderRadius: 10,
                      padding: 20,
                      margin: 40,
                    }}
                  >
                    <b
                      className="copy-to-clipboard"
                      onClick={() =>
                        copyToClipboard(null, googleAuthResponse.refresh_token)
                      }
                    >
                      {googleAuthResponse.refresh_token ||
                        "ERRO AO RECUPERAR TOKEN"}
                    </b>
                  </div>
                )}

                <div>
                  <Checkbox
                    checked={checked}
                    onClick={() => setChecked(!checked)}
                  >
                    <p className="text-muted">
                      Eu concordo em compartilhar os dados solicitados na atual
                      página (token do Google Ads) com a equipe de
                      desenvolvimento da plataforma para fins de testes e
                      desenvolvimento.
                    </p>
                  </Checkbox>

                  <Button
                    disabled={!checked}
                    className="w-100 p-2"
                    style={{
                      border: "1px solid #e1e3ea",
                      borderRadius: 10,
                    }}
                    loading={loading}
                    onClick={() => {
                      setLoading(true);
                      window.open(
                        `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&response_type=code&client_id=402494766896-63imgi256phlpi066l6fgtki588eaeri.apps.googleusercontent.com&redirect_uri=${encodeURI(
                          window.location.origin
                        )}%2Fgoogleads%2Fauthorization&prompt=consent`
                      );
                    }}
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/3128/3128285.png"
                      alt="google ads"
                      style={{ maxWidth: 30 }}
                    />
                    <span className="align-middle ms-2">
                      Continuar com o Google
                    </span>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DebugGoogleAdsToken;
