import React from "react";
import { Row, Col } from "reactstrap";
import { Placeholder } from "rsuite";

const AIHeader = (props) => {
  return (
    <Row className="mb-5 pt-4">
      <Col lg={8}>
        <div className="d-flex">
          <img
            className="me-4"
            style={{ height: 50 }}
            src={props.icon_url}
            alt={props.title}
          />
          <div className="d-flex flex-column">
            <h1 className="m-0">{props.title}</h1>
            <small className="text-muted">
              {props.description ||
                "Aqui você pode usar o nosso AI para fazer análises"}
            </small>
          </div>
        </div>
      </Col>
      <Col lg={4}>
        <div className="d-flex flex-row align-items-center justify-content-start justify-content-md-end h-100 mt-4 mt-md-0">
          <div>
            <img
              src="https://cdn-icons-png.flaticon.com/512/733/733311.png"
              alt="ai-coins"
              style={{ width: 30, height: 30 }}
            />
          </div>
          <div className="d-flex flex-column m-2">
            <small className="text-muted">Você tem</small>
            {props.loading ? (
              <Placeholder.Paragraph
                style={{ marginTop: 0, width: 150 }}
                rows={1}
              />
            ) : (
              <b className="fs-5">
                {Math.max(props.credits - (props.usage || 0), 0).toLocaleString(
                  "pt-BR"
                ) || 0}{" "}
                créditos
              </b>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AIHeader;
