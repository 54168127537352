import React, { useState, useEffect, useRef } from "react";
import AIHeader from "../Header";
import api from "../../../services/api";
import { Container, Card, CardBody } from "reactstrap";
import { getIdUsuario } from "../../../services/auth";
import { Input, SelectPicker, Badge, Button, Loader } from "rsuite";
import _ from "lodash";
import { FlagIcon } from "react-flag-kit";
import { Row, Col } from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { programs } from "../../../common/data/partnership_programs";

const prompt_max_length = 3000;
const required_programs = ["blackrat"];

const SpeechyAI = () => {
  const audioRef = useRef(null);
  const [user, setUser] = useState({});
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingVoices, setLoadingVoices] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [voicesData, setVoicesData] = useState({});
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState("");
  const [audioUrl, setaudioUrl] = useState("");

  function loadUserData() {
    setLoadingUser(true);
    api
      .get("/api/usuarios.details/" + getIdUsuario())
      .then((response) => {
        setUser(response.data[0]);
        setLoadingUser(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingUser(false);
      });
  }

  function checkUserEnrolledPrograms(programs) {
    setVerifying(true);
    api
      .get("/api/usuarios/check-programs/")
      .then((response) => {
        if (response.data.enrolled) {
          const user_programs = response.data.programs;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setVerifying(false);
      });
  }

  function fetchVoicesList() {
    setLoadingVoices(true);
    api
      .get("/api/v1/enterprise/cognitive/text-to-speech/voices")
      .then((response) => {
        const voices_grouped_by_locale = _.groupBy(
          response.data,
          (voice) => `${voice.Locale}>${voice.LocaleName}`
        );

        setSelectedLanguage("pt-BR");

        setVoicesData(voices_grouped_by_locale);
        setLanguages(Object.keys(voices_grouped_by_locale));

        console.log(Object.keys(voices_grouped_by_locale)[0].split(">")[0]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingVoices(false);
      });
  }

  function generateAudioFromText() {
    setLoading(true);
    api
      .post(
        "/api/v1/enterprise/cognitive/text-to-speech",
        {
          phrase: prompt,
          file: true,
          voice: selectedVoice,
        },
        {
          responseType: "blob",
        }
      )
      .then(async (response) => {
        console.log(response);

        const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
        const audioURL = URL.createObjectURL(audioBlob);

        setaudioUrl(audioURL);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    loadUserData();
    fetchVoicesList();
  }, []);

  useEffect(() => {
    const key = Object.keys(voicesData).find((k) =>
      k.includes(selectedLanguage)
    );

    console.log({
      key: Object.keys(voicesData).find((k) => k.includes(selectedLanguage)),
      voicesData,
      selectedLanguage,
    });

    if (key) {
      const voices_array = voicesData[key];
      setVoices(voices_array);
      setSelectedVoice(voices_array[0].ShortName);
    } else {
      console.log("Nenhuma voz encontrada para a língua selecionada");
      setVoices([]);
    }
  }, [selectedLanguage, voicesData]);

  useEffect(() => {
    if (audioRef.current && !loading) {
      audioRef.current.load();
      audioRef.current.play();
    }
  }, [audioUrl, loading]);

  if (loadingUser) {
    return (
      <div className="page-content">
        <MetaTags>
          <title>Speechy AI | Metrito</title>
        </MetaTags>
        <Container fluid>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <Loader size="lg" content="Verificando..." vertical />
          </div>
        </Container>
      </div>
    );
  } else if (
    user.enrolled_programs?.filter((item) =>
      required_programs.includes(item.program_id)
    )
  ) {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Speechy AI | Metrito</title>
        </MetaTags>

        <div className="page-content">
          <Container fluid>
            <AIHeader
              title="Speechy"
              description="Metrito Speechy AI é uma ferramenta que permite criar áudios. Ele usa inteligência artificial para transformar textos em áudios."
              icon_url="https://cdn-icons-png.flaticon.com/512/4276/4276866.png"
              loading={loadingUser}
              credits={user.ai_credits}
              usage={0}
            />

            <div>
              <Card>
                <CardBody>
                  {/* <div>
                    <Input
                      className="subtle-input"
                      placeholder="Dê um nome a este projeto"
                      size="lg"
                      value={"Projeto sem nome"}
                      style={{ fontSize: "1.5rem", fontWeight: 900 }}
                    />
                  </div> */}
                  <Row className="my-4 d-flex flex-row">
                    <Col lg={8}>
                      <Input
                        as="textarea"
                        maxlength={prompt_max_length}
                        rows={10}
                        placeholder="Texto a ser convertido em fala"
                        value={prompt}
                        onChange={(value) => {
                          setPrompt(value);
                        }}
                      />
                      <small className="text-muted">
                        {prompt.length}/{prompt_max_length}
                      </small>{" "}
                      {prompt.length >= prompt_max_length && (
                        <small className="text-danger">
                          limite de caracteres atingido!
                        </small>
                      )}
                    </Col>
                    <Col lg={4}>
                      <div className="d-flex flex-column ps-3">
                        <div className="d-flex flex-column">
                          <small className="text-muted ms-2">Língua</small>
                          <SelectPicker
                            style={{ minWidth: 250 }}
                            cleanable={false}
                            loading={loadingVoices}
                            disabled={loading || false}
                            data={languages.map((item) => ({
                              label: item?.split(">")[1],
                              value: item?.split(">")[0],
                            }))}
                            onChange={(value) => {
                              setSelectedLanguage(value);
                            }}
                            value={selectedLanguage}
                            renderValue={(value, item, selectedElement) => {
                              return (
                                <div className="d-flex align-items-center">
                                  <FlagIcon
                                    code={value?.split("-")[1]}
                                    size={18}
                                    width={24}
                                    style={{ borderRadius: 3, marginRight: 10 }}
                                  />
                                  <b>{item?.label}</b>
                                </div>
                              );
                            }}
                            renderMenuItem={(label, item) => {
                              return (
                                <div className="d-flex align-items-center">
                                  <FlagIcon
                                    code={item?.value.split("-")[1]}
                                    size={18}
                                    width={24}
                                    style={{ borderRadius: 3, marginRight: 10 }}
                                  />
                                  <b>{item.label}</b>
                                </div>
                              );
                            }}
                          />
                        </div>

                        <div className="d-flex flex-column mt-2">
                          <small className="text-muted ms-2">Voz</small>
                          <SelectPicker
                            style={{ minWidth: 250 }}
                            cleanable={false}
                            loading={loadingVoices}
                            data={voices.map((item) => ({
                              label: item.DisplayName,
                              value: item.ShortName,
                            }))}
                            onChange={(value) => {
                              setSelectedVoice(value);
                            }}
                            value={selectedVoice}
                            renderValue={(value, item, selectedElement) => {
                              const voice = Object.values(voicesData)
                                .flat()
                                .find((v) => v.ShortName === value);
                              return (
                                <div className="d-flex align-items-center">
                                  {voice?.Gender === "Male" ? (
                                    <i
                                      className="mdi mdi-face"
                                      style={{ color: "#005BEA" }}
                                    />
                                  ) : (
                                    <i
                                      className="mdi mdi-face-woman"
                                      style={{ color: "#FF0080" }}
                                    />
                                  )}
                                  <b className="ms-2">{item?.label}</b>
                                  {voice?.VoiceType === "Neural" && (
                                    <Badge
                                      style={{ backgroundColor: "#00000050" }}
                                      className="ms-2"
                                      content="neural"
                                    />
                                  )}
                                </div>
                              );
                            }}
                            renderMenuItem={(label, item) => {
                              const voice = Object.values(voicesData)
                                .flat()
                                .find((v) => v.ShortName === item.value);
                              return (
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center ">
                                    {voice?.Gender === "Male" ? (
                                      <i
                                        className="mdi mdi-face"
                                        style={{ color: "#005BEA" }}
                                      />
                                    ) : (
                                      <i
                                        className="mdi mdi-face-woman"
                                        style={{ color: "#FF0080" }}
                                      />
                                    )}
                                    <b className="ms-2">{item?.label}</b>
                                    {voice?.VoiceType === "Neural" && (
                                      <Badge
                                        style={{
                                          maxHeight: 16,
                                          backgroundColor: "#00000050",
                                        }}
                                        color="red"
                                        className="ms-2"
                                        content="neural"
                                      />
                                    )}
                                  </div>
                                  <small>{voice?.WordsPerMinute} WPM</small>
                                </div>
                              );
                            }}
                          />
                        </div>

                        <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                          <Button
                            className="btn btn-primary w-100"
                            onClick={() => generateAudioFromText()}
                            loading={loading}
                          >
                            Gerar áudio
                          </Button>
                        </div>
                      </div>
                    </Col>

                    {loading ? (
                      <div className="d-flex align-items-center mt-4">
                        <Loader className="me-3" />
                        <span className="text-muted fs-5">
                          Gerando áudio...
                        </span>
                      </div>
                    ) : isLoaded ? (
                      <div className="d-flex flex-column mt-4">
                        <hr className="divider" />
                        <div className="d-flex align-item-center">
                          <i className="mdi mdi-check-circle text-success me-3" />
                          <span className="text-muted fs-5">
                            Áudio gerado com sucesso!
                          </span>
                        </div>
                        <div className="w-100">
                          <audio
                            id="audioPlayer"
                            controls
                            className="mt-4 w-100"
                            ref={audioRef}
                            src={audioUrl}
                          >
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </div>
                    ) : null}
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  } else if (!user.enrolled_programs || user.enrolled_programs.length === 0) {
    return (
      <div className="page-content">
        <MetaTags>
          <title>Speechy AI | Metrito</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div
                className="d-flex flex-column align-items-center justify-content-center"
                style={{ height: "50vh" }}
              >
                <h1 className="text-center">
                  Você não está vinculado a nenhum dos programas necessários
                  para acessar esse recurso.
                </h1>

                <div>
                  <small className="tex-muted">
                    Se você acredita que isso é um erro, entre em contato com o
                    suporte.
                  </small>
                </div>

                <div className="mt-5">
                  {programs
                    .filter((p) => required_programs.includes(p.alias))
                    .map((program) => (
                      <Card
                        style={{ backgroundColor: program.bgColor }}
                        className="p-2 px-5"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={program.icon}
                            alt={program.name}
                            style={{ width: 40, height: 40, borderRadius: 5 }}
                            className="me-3"
                          />
                          <div className="d-flex flex-column align-items-start">
                            <span
                              className="m-0"
                              style={{
                                color: program.color,
                                fontSize: 16,
                                lineHeight: 1,
                              }}
                            >
                              {program.name}
                            </span>
                            <small
                              style={{
                                color: program.color,
                              }}
                              className="m-0"
                            >
                              {program.description}
                            </small>
                          </div>
                        </div>
                      </Card>
                    ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

export default SpeechyAI;
