import PropTypes from "prop-types";
import React from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

import api from "./services/api";
import { getIdUsuario, getNomeUsuario, getCreatedAt, getEmailUsuario } from "./services/auth";

//Logo
import LogoSm from "./assets/images/logo-sm.svg";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

//Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MetaTags } from "react-meta-tags";
import { Helmet } from "react-helmet";

//Joyride
import Joyride from "react-joyride";

import { useEffect } from "react";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import CrispChat from "./components/CrispChat";

function ErrorFallback({ error, resetErrorBoundary }) {
  const error_data = {
    user: getIdUsuario(),
    location: window.location.href,
    error: String(error),
    stack: error.stack,
    time: new Date().getTime(),
  };
  console.log(error_data);

  useEffect(() => {
    api
      .post("/error", {
        error: error_data,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div role="alert" className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100vh" }}>
      <h3>Ops! Alguma coisa deu errado por aqui... 🤔</h3>

      <p>Nossa equipe de desenvolvimento acabou de ser avisada sobre este erro e já está trabalhando nisso!</p>
      <div
        style={{
          backgroundColor: "#f1f1f1",
          borderRadius: 10,
          padding: 40,
          margin: 30,
        }}
      >
        <p>Erro:</p>
        <code>{error.message}</code>
      </div>
      <p>
        Pedimos desculpas do fundo do nosso <i className="mdi mdi-cards-heart" style={{ color: "#F7024B" }}></i>
        {". "}
      </p>
      <button
        className="btn btn-dark"
        onClick={() => {
          resetErrorBoundary();
          window.location.href = "/home";
        }}
      >
        Voltar para o início
      </button>
    </div>
  );
}

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const App = (props) => {
  (function (w, r) {
    w._rwq = r;
    w[r] =
      w[r] ||
      function () {
        (w[r].q = w[r].q || []).push(arguments);
      };
  })(window, "rewardful");

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const [steps, setSteps] = useState([
    {
      target: "#builder-step-1",
      content: "Bem vindo ao Builder, Aqui você poderá criar seus templates para usar nos Dashboards posteriormente",
    },
    {
      target: "#builder-step-2",
      content: "Aqui você poderá escolher os widgets que irá utilizar no seu Dashboard.",
      placement: "right",
    },

    {
      target: "#builder-step-4",
      content: "Só um errinho? Não esquenta, só voltar atrás!",
      placement: "bottom",
    },
    {
      target: "#builder-step-5",
      content: "Não gostou do que fez? Sem problemas você pode apagar e recomeçar ;D",
      placement: "bottom",
    },
    {
      target: "#builder-step-6",
      content: "Quando finalizar basta clicar em Salvar Template e em seguida já poderá usar seu template em seus Dashboards!",
      placement: "bottom",
    },
    {
      target: "#builder-step-3",
      content: "É simples assim! Agora você está pronto para começar a criar dashboards incríveis!",
      placement: "center",
    },
  ]);

  const Layout = getLayout();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <CrispChat />
        <MetaTags>
          <meta property="og:url" content="https://v1.metrito.com" />
          <meta property="og:image" content="/static/media/logo-sm.4140f62a.png" />
          <meta name="description" content="Crie Dashboards incríveis e visualize todas as suas Contas de Anúncio" />
          {/* Helmet */}
          <Helmet>
            {/* <!-- Google Tag Manager - app.metrito.com --> */}
            <script>
              {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5X35GWQ');

        `}
            </script>

            {/* <!-- Global site tag (gtag.js) - Google Ads: 10955773296 --> */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10955773296"></script>
            <script>
              {`window.dataLayer = window.dataLayer || [];

              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'AW-10955773296');`}
            </script>

            {/* <!-- Rewardful code snippet --> */}
            {/* <script>{`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}</script> */}
            <script async src="https://r.wdfl.co/rw.js" data-rewardful="7d228e"></script>
          </Helmet>
        </MetaTags>

        {window.location.search.includes("onboarding=true") ? (
          <Joyride steps={steps} locale={"pt-BR"} style={{ zIndex: 1000 }} continuous />
        ) : null}

        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5X35GWQ"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
        </noscript>

        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware path={route.path} layout={Layout} component={route.component} key={idx} isAuthProtected={true} exact />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    </ErrorBoundary>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
