import { SelectPicker } from "rsuite";
import { getImageURL } from "../functions/getImageURL";

const BrandPicker = ({
  brands,
  loading,
  disabled,
  currentBrand,
  setSelectedBrand,
}) => {
  var data = brands.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  return (
    <SelectPicker
      cleanable={false}
      disabled={disabled || false}
      loading={loading}
      data={data}
      value={currentBrand._id}
      style={{ width: "100%" }}
      placeholder="Select User"
      onChange={(value) => {
        setSelectedBrand(brands.find((brand) => brand._id === value));
      }}
      renderMenuItem={(label, item) => {
        var brand = brands.find((b) => b._id === item.value);

        return (
          <div className="p-2 d-flex flex-row align-items-center">
            <div className="me-3">
              <img
                src={getImageURL(brand?.logo || "brand_placeholder.png")}
                alt={brand.name}
                style={{ height: 30 }}
              />
            </div>
            <div className="d-flex flex-column">
              <b className="fs-6 text-dark">{brand.name}</b>
              <span className="text-muted d-block">{brand.description}</span>
            </div>
          </div>
        );
      }}
      renderValue={(value, item) => {
        var brand = brands.find((b) => b._id === value);
        return (
          <div className="p-2 d-flex flex-row align-items-center">
            <div className="me-3">
              <img
                src={getImageURL(brand?.logo || "brand_placeholder.png")}
                alt={brand.name}
                style={{ height: 30 }}
              />
            </div>
            <div className="d-flex flex-column">
              <b className="fs-6 text-dark">{brand.name}</b>
              <span className="text-muted d-block">{brand.description}</span>
            </div>
          </div>
        );
      }}
    />
  );
};

export default BrandPicker;
