import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getImageURL } from "../../../functions/getImageURL";
import { getProfilePictureURL } from "../../../services/auth";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setUsername] = useState("");

  const Image = React.memo(function Image() {
    return (
      <img
        className="rounded-circle header-profile-user"
        src={getImageURL(getProfilePictureURL())}
      />
    );
  });

  useEffect(() => {
    if (localStorage.getItem("&nome-usuario")) {
      setUsername(localStorage.getItem("&nome-usuario"));
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <Image />
          <span className=" ms-2 me-1 d-none d-sm-inline">{username}</span>
          <span className=" ms-2 me-1 d-inline d-sm-none">
            {username.split(" ")[0]}
          </span>
          <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile">
            <DropdownItem>
              <i className="bx bx-user font-size-16 align-middle me-2" />
              {"Perfil"}
            </DropdownItem>
          </Link>

          <Link to="/plans">
            <DropdownItem>
              <i className="bx bx-up-arrow-alt text-success font-size-18 align-middle me-2" />
              {"Fazer Upgrade"}
            </DropdownItem>
          </Link>

          <div className="dropdown-divider" />

          <Link to="/enterprise/api/tokens">
            <DropdownItem>
              <i className="mdi mdi-api text-dark font-size-18 align-middle me-2" />
              {"API Metrito"}
            </DropdownItem>
          </Link>

          {/* <Link to="/support">
            <DropdownItem>
              <i className="bx bx-support font-size-16 align-middle me-2" />
              {"Suporte"}
            </DropdownItem>
          </Link> */}

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className=" bx bx-log-out-circle font-size-16 align-middle me-2 text-danger" />
            <span>{"Sair"}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(ProfileMenu);
