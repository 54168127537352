export function setLoading(loading) {
  return {
    type: "SET_LOADING",
    loading,
  };
}

export function setAdAccounts(ad_accounts) {
  return {
    type: "SET_AD_ACCOUNTS",
    ad_accounts,
  };
}

export function setAccountSpecific(ad_accounts) {
  return {
    type: "SET_ACCOUNT_SPECIFIC",
    ad_accounts,
  };
}

export function setAccountGeneralAggregated(account_general_aggregated) {
  return {
    type: "SET_ACCOUNT_GENERAL_AGGREGATED",
    account_general_aggregated,
  };
}

export function setAccountGeneralGranular(account_general_granular) {
  return {
    type: "SET_ACCOUNT_GENERAL_GRANULAR",
    account_general_granular,
  };
}

export function setAccountByDataSourceAggregated(
  account_by_data_source_aggregated
) {
  return {
    type: "SET_ACCOUNT_BY_DATA_SOURCE_AGGREGATED",
    account_by_data_source_aggregated,
  };
}

export function setAccountByDataSourceGranular(
  account_by_data_source_granular
) {
  return {
    type: "SET_ACCOUNT_BY_DATA_SOURCE_GRANULAR",
    account_by_data_source_granular,
  };
}

export function setCampaignGeneralAggregated(campaign_general_aggregated) {
  return {
    type: "SET_CAMPAIGN_GENERAL_AGGREGATED",
    campaign_general_aggregated,
  };
}

export function setCampaignGeneralGranular(campaign_general_granular) {
  return {
    type: "SET_CAMPAIGN_GENERAL_GRANULAR",
    campaign_general_granular,
  };
}

export function flushData() {
  return {
    type: "FLUSH_DATA",
  };
}
