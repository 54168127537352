import React from "react";
import InputMask from "react-input-mask";
import { Card, CardBody, Input } from "reactstrap";
import BlurLoader from "./BlurLoader";

const AdAccountsRightbar = (props) => {
  return (
    <React.Fragment>
      <Card>
        {props.loadingPlans ? (
          <BlurLoader text={""} active={true} />
        ) : props.isLoading ? (
          <BlurLoader text={"Processando dados..."} active={true} />
        ) : null}
        <CardBody className="d-flex flex-column">
          <h5>Contas de Anúncio Extras</h5>
          <div className="w-100 text-center mt-3">
            <i
              className="bx bxl-facebook-circle text-primary me-2"
              style={{ fontSize: 50 }}
            />
            <h6 className="mt-2"></h6>
            <h3 id="cart-total" className="text-success">
              +{" "}
              {(
                Number(props.quantity) *
                  Number(props.user.subscription?.extra_ad_accounts.price) || 0
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 2,
              })}
            </h3>
            {props.rangeValue != 0 ? (
              <span className="text-dark">por mês</span>
            ) : null}
          </div>
          <hr className="divider" />
          <div className="d-flex flex-row align-items-center fs-5">
            <span>Adicionar</span>
            <input
              type="number"
              className="form-control mx-3"
              style={{ maxWidth: 100 }}
              value={props.quantity}
              mask="9999"
              maskchar=""
              min={1}
              tag={[Input, InputMask]}
              required
              onChange={(e) => props.setQuantity(Number(e.target.value))}
            />
            <span>contas de anúncio</span>
          </div>
          <hr className="divider" />
          <div>
            <b className="text-muted mb-2">
              Quantidade atual de Contas de Anúncio:
            </b>
            <h4>
              {props.user.subscription?.extra_ad_accounts.quantity +
                props.user.subscription?.plan.plan_ad_accounts}
            </h4>
          </div>
          <div>
            <b className="text-muted mb-2">
              Quantidade de contas que irá adquirir:
            </b>
            <h4>{Number(props.quantity)}</h4>
          </div>
          <div>
            <b className="text-muted mb-2">Total após atualização:</b>
            <h1 className="text-primary">
              {Number(props.user.subscription?.extra_ad_accounts.quantity) +
                props.user.subscription?.plan.plan_ad_accounts +
                Number(props.quantity)}
            </h1>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AdAccountsRightbar;
