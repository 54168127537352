import React from "react";

import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Col } from "reactstrap";

const AdminAreaChart = (props) => {
  const options = {
    series: props.data,
    labels: props.labels,
    colors: ["#34c38f", "#556ee6"],
    legend: {
      show: true,
      position: "right",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Col lg={6} xl={6} md={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.title}</CardTitle>
            {/* <span>{props.chartID}</span> */}
            <ReactApexChart
              options={options}
              series={options.series}
              type="pie"
              height="250"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AdminAreaChart;
