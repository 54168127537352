import React, { useEffect, useState } from "react";
import { CardBody, Button } from "reactstrap";
import { defineMetric } from "../functions/metricFormatter";
import { data_sources } from "../common/data/data_sources";
import classNames from "classnames";
import { CustomTooltip } from "../components/Common/Tooltip.js";
import { formatByDisplayType } from "../functions/formatByDisplayType";
import { metricsArray } from "../common/data/metrics";

const CardWidget = (props) => {
  const allMetrics = [...metricsArray, ...(props.widget?.custom_metrics || [])];

  const getCustomMetricValue = (metric) => {
    const conv_ds = metric.split(".")[1];
    const conv_account = metric.split(".")[2];
    const conv_id = metric.split(".")[3];

    const cc = props.metrics.custom_conversions?.find(
      (cc) =>
        cc.data_source === conv_ds &&
        cc.account_id === conv_account &&
        cc.id === conv_id
    );

    return cc;
  };

  const data_sources_for_this_metric = allMetrics
    .find((m) => m.value === props.widget.metric)
    ?.data_sources.map((o) => o.code);

  function hasCommonElement(arr1, arr2) {
    return arr1.some((item) => arr2.includes(item));
  }

  function calcPercentageBetweenTwoNumbers(num1, num2, metric) {
    return Math.round((num1 * 100) / num2);
  }

  function getWinnerDataSource(comparison_array, metric) {
    const better_when = metricsArray.find(
      (o) => o.value === metric
    )?.better_when;

    if (better_when === "HIGH") {
      return (
        (comparison_array.length &&
          comparison_array?.reduce((prev, current) =>
            prev.math > current.math ? prev : current
          )) ||
        null
      );
    } else if (better_when === "LOW") {
      return (
        (comparison_array.length &&
          comparison_array?.reduce((prev, current) =>
            prev.math < current.math ? prev : current
          )) ||
        null
      );
    }
  }

  const data_source_comparison = props.metrics_by_data_source?.map((o) => ({
    data_source: o.data_source,
    math: calcPercentageBetweenTwoNumbers(
      o.data[props.widget.metric],
      props.metrics[props.widget.metric]
    ),
  }));

  const winner_data_source = getWinnerDataSource(
    data_source_comparison,
    props.widget.metric
  );

  return (
    <React.Fragment>
      <CardBody>
        <div>
          <p className="text-muted text-uppercase fw-semibold d-flex flex-row mb-2">
            <span className="float-start text-truncate align-middle">
              {props.widget.title}
            </span>

            <CustomTooltip
              placement="autoVertical"
              text={props.widget.description}
            />
          </p>

          {props.widget?.metric.includes("custom_conversion") ? (
            <h4 className="mb-1">
              {props.metrics[props.widget.metric] || "-"}
            </h4>
          ) : (
            <>
              {hasCommonElement(
                props.metrics_by_data_source.map((m) => m.data_source),
                data_sources_for_this_metric
              ) ? (
                <h4 className="mb-1">
                  {(props.metrics &&
                    formatByDisplayType(
                      props.metrics[props.widget.metric],
                      props.widget.metric
                    )) ||
                    "-"}
                </h4>
              ) : (
                <h4 className="mb-1">-</h4>
              )}
            </>
          )}
        </div>

        <hr className="divider m-0 my-1" />

        {props.widget?.metric.includes("custom_conversion") ? (
          <>
            <p className="text-muted mb-0 d-flex justify-content-between">
              <div>
                <i className="mdi mdi-currency-usd me-2 text-success" />
                {props.metrics[
                  props.widget.metric.replace(
                    ".conversions",
                    ".conversions_value"
                  )
                ]?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                  maximumFractionDigits: 2,
                }) || "-"}
              </div>
              <small>Valor</small>
            </p>
            <p className="text-muted mb-0 d-flex justify-content-between">
              <div>
                <i className="mdi mdi-currency-usd me-2 text-primary" />
                {props.metrics[
                  props.widget.metric.replace(
                    ".conversions",
                    ".conversions_cost"
                  )
                ]?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                  maximumFractionDigits: 2,
                }) || "-"}
              </div>
              <small>Custo</small>
            </p>
          </>
        ) : (
          <>
            {props.metrics_by_data_source?.map((metric, key) => {
              const ds = data_sources.find(
                (ds) => ds.code === metric.data_source
              );

              if (data_sources_for_this_metric.includes(metric.data_source)) {
                return (
                  <p className="text-muted mb-0">
                    <i
                      className={`${ds.icon} me-2`}
                      style={{ color: ds.color }}
                    />
                    {formatByDisplayType(
                      metric.data[props.widget.metric],
                      props.widget.metric
                    )}
                    <span
                      className={classNames("badge ms-2", {
                        "badge-soft-dark":
                          metric.data_source !== winner_data_source.data_source,
                        "badge-soft-success":
                          metric.data_source == winner_data_source.data_source,
                      })}
                    >
                      {calcPercentageBetweenTwoNumbers(
                        metric.data[props.widget.metric],
                        props.metrics[props.widget.metric]
                      ) || "-"}
                      %
                    </span>
                  </p>
                );
              }
            })}
          </>
        )}
      </CardBody>
    </React.Fragment>
  );
};

export default CardWidget;
