import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";
import { useEffect } from "react";

const Breadcrumb = (props) => {
  useEffect(() => {}, []);
  return /onboarding/.test(
    window.location.href ? null : (
      <Row id="breadcrumbs">
        <Col xs="12">
          <div
            className="page-title-box d-flex align-items-center justify-content-between"
            style={{ backgroundColor: props.bgc }}
          >
            <h4 className="mb-0 font-size-18" style={{ color: props.tc }}>
              {props.breadcrumbItem}
            </h4>
            <div className="page-title-right hide-breadcrumb-onmobile">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <Link style={{ color: props.tc }} to="#">
                    {props.title}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link style={{ color: props.tc }} to="#">
                    {props.breadcrumbItem}
                  </Link>
                </BreadcrumbItem>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    )
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
