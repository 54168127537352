import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

//dashboard
import Dashboard from "./dashboard/reducer";

//Facebook
import Facebook from "./facebook/reducer";

//Brands
import Brands from "./Brands/reducer";

//Analytics
import Analytics from "./analytics/reducer";

//User
import User from "./user/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Dashboard,
  Facebook,
  Brands,
  Analytics,
  User,
});

export default rootReducer;
