export const data_sources = [
  {
    label: "Facebook Ads",
    code: "FA",
    api_code: "fa",
    icon: "mdi mdi-facebook",
    color: "#0065DF",
    icon_url: "https://cdn-icons-png.flaticon.com/512/5968/5968764.png",
    connect_account_url: "/facebook/connect",
  },
  {
    label: "Google Ads",
    code: "AW",
    api_code: "aw",
    icon: "mdi mdi-google-ads",
    color: "#33A851",
    icon_url: "https://cdn-icons-png.flaticon.com/512/3128/3128285.png",
    connect_account_url: "/googleads/connect",
  },
];

export const gateways = [
  {
    status: "active",
    label: "Yampi",
    code: "YAMPI",
    color: "#ffffff",
    icon_url:
      "https://metrito-public.s3.sa-east-1.amazonaws.com/logos/yampi-logo.png",
    connect_account_url: "/facebook/connect",
    connect_fields: ["name", "alias", "token", "secret_key"],
  },
  {
    status: "active",
    label: "Braip",
    code: "BRAIP",
    color: "#ffffff",
    icon_url:
      "https://metrito-public.s3.sa-east-1.amazonaws.com/braip-logo.svg",
    connect_account_url: "/facebook/connect",
    connect_fields: ["name", "token"],
  },
  {
    status: "active",
    label: "Hotmart",
    code: "HOTMART",
    color: "#ffffff",
    icon_url:
      "https://metrito-public.s3.sa-east-1.amazonaws.com/logos/hotmart-logo.svg",
    connect_account_url: "/facebook/connect",
    connect_fields: ["name", "token", "client_id", "client_secret"],
  },
  {
    status: "active",
    label: "Monetizze",
    code: "MNTZZ",
    color: "#ffffff",
    icon_url:
      "https://metrito-public.s3.sa-east-1.amazonaws.com/logos/monetizze-logo.png",
    connect_account_url: "/facebook/connect",
    connect_fields: ["name", "token"],
  },
  {
    status: "active",
    label: "PerfectPay",
    code: "PFPAY",
    color: "#ffffff",
    icon_url:
      "https://metrito-public.s3.sa-east-1.amazonaws.com/logos/perfectpay-logo.png",
    connect_account_url: "/facebook/connect",
    connect_fields: ["name", "token"],
  },
  {
    status: "active",
    label: "Pagar.me",
    code: "PAGME",
    color: "#ffffff",
    icon_url:
      "https://metrito-public.s3.sa-east-1.amazonaws.com/logos/pagarme-logo.png",
    connect_account_url: "/facebook/connect",
    connect_fields: ["name", "token"],
  },
  {
    status: "active",
    label: "Eduzz",
    code: "EDUZZ",
    color: "#ffffff",
    icon_url:
      "https://metrito-public.s3.sa-east-1.amazonaws.com/logos/eduzz-logo.png",
    connect_account_url: "/facebook/connect",
    connect_fields: ["name", "email", "public_key", "api_key"],
  },
  {
    status: "maintenance",
    label: "Appmax",
    code: "APPMX",
    color: "#ffffff",
    icon_url:
      "https://metrito-public.s3.sa-east-1.amazonaws.com/appmax-logo.svg",
    connect_account_url: "/facebook/connect",
    connect_fields: ["name", "token"],
  },
  {
    status: "soon",
    label: "Shopify",
    code: "SHOPIFY",
    color: "#ffffff",
    icon_url:
      "https://metrito-public.s3.sa-east-1.amazonaws.com/logos/shopify-logo.svg",
    connect_account_url: "/facebook/connect",
    connect_fields: ["name", "token"],
  },
  {
    status: "soon",
    label: "Vtex",
    code: "VTEX",
    color: "#ffffff",
    icon_url:
      "https://metrito-public.s3.sa-east-1.amazonaws.com/logos/vtex-logo.svg",
    connect_account_url: "/facebook/connect",
    connect_fields: ["name", "token"],
  },
  // {
  //   status: "active",
  //   label: "PayT",
  //   code: "PFPAY",
  //   color: "#ffffff",
  //   icon_url: "https://www.payt.com.br/wp-content/uploads/2022/05/unnamed.png",
  //   connect_account_url: "/facebook/connect",
  //   connect_fields: ["name", "token", "secret_key"],
  // },
];

// Artigo que fala sobre a nova integração da plataforma Metrito com a OpenAI, atualmente o provedor de IA mais avançado do mundo. Agora os usuários podem criar textos para anúncios, e-mail, posts e muito mais usando inteligência artificial e poupando muito tempo.
