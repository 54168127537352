import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MetaTags from "react-meta-tags";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { getImageURL } from "../../functions/getImageURL";
import Preloader from "../Utility/Preloader";

import { Link } from "react-router-dom";

//Services
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";

import { metritoLayouts } from "../../common/data/layouts";

//moment

//Toast
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { TemplateCard } from "./TemplateCard";
import BrandPicker from "../../components/BrandPicker";
import BrandNotFound from "../Utility/BrandNotFound";
import CustomAlert from "../../components/CustomAlert";


const DashboardCreate = (props) => {
  const queryParams = new URLSearchParams(window.location.search);

  const [regStatus, setRegStatus] = useState("");
  const [regDynamicText, setRegDynamicText] = useState("");
  const [success_msg, setsuccess_msg] = useState(false);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [brands, setBrands] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState(metritoLayouts[0]);
  const [selectedBrand, setSelectedBrand] = useState({});

  const [with_title, setwith_title] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  async function handleSubmit(event) {
    const brand_id = selectedBrand._id;

    if (!selectedLayout.layout) {
      setwith_title(true);
    } else {
      //Toast loading
      const id = toast.loading("Verificando dados...");

      const data = {
        name: "Dashboard sem nome",
        layout: selectedLayout.layout,
        version: 3,
      };

      const response = await api
        .post("/api/dashboards/" + brand_id, data)
        .then((res) => {
          if (res.status === 200) {
            //GTM - Created Dashboard Event
            window.dataLayer.push({ event: "created_dashboard" });

            // setsuccess_msg(true);
            // setRegStatus("");

            //Toast success
            toast.update(id, {
              render: "Dashboard criado com sucesso!",
              type: "success",
              isLoading: false,
            });

            window.location.href =
              window.location.origin + "/builder/v2/" + res.data._id;
          } else {
            // alert("Erro ao cadastrar Conta de Anúncio");
            setRegStatus("error");

            //Toast error
            toast.update(id, {
              render: "Falha ao criar dashboard",
              type: "warning",
              isLoading: false,
            });
          }
        })

        .catch((error) => {
          console.log(error.response);
          setRegStatus("error");
          setRegDynamicText(
            "Falha na sincronização. Verifique os dados, permissões e tente novamente."
          );

          //Toast error
          toast.update(id, {
            render: "Erro no servidor.",
            type: "warning",
            isLoading: false,
          });
        });
    }
  }

  //Faz o Fetch das Brands do Usuário
  async function loadBrandList() {
    setLoadingBrands(true);
    await api
      .get("/api/v1/brands/")
      .then((res) => {
        if (res.data !== 0) {
          setBrands(res.data);
          // console.log("Adaccounts OK");
        } else {
          console.log("Sem Empresas para exibir!");
        }

        if (queryParams.has("brand")) {
          setSelectedBrand(
            res.data.find((brand) => brand._id === queryParams.get("brand"))
          );
        } else {
          setSelectedBrand(res.data[0]);
        }

        setLoadingBrands(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingBrands(false);
      });
  }

  //Faz o Fetch das Campanhas na API do Facebook (server side)
  async function fetchLayouts(act, token) {
    setLoadingTemplates(true);
    const fbData1 = await api
      .get("/api/layouts/" + getIdUsuario())
      .then((res) => {
        setLayouts(res.data.reverse());
        setLoadingTemplates(false);
      })
      .catch(function (motivo) {
        // rejeição
        toast.error("Problema ao carregar Layouts.");
        console.log(motivo);
        setLoadingTemplates(false);
      });
  }

  useEffect(() => {
    loadBrandList();
    fetchLayouts();
  }, []);

  const version_3_layouts = layouts?.filter((layout) => layout.version === 3);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Novo Dashboard | Metrito</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Empresas" breadcrumbItem="Cadastrar Empresa" />

          {with_title ? (
            <CustomAlert
              title="Você precisa selecionar um template"
              warning
              confirmBtnStyle={{color: "white"}}
              onConfirm={() => {
                setwith_title(false);
              }}
            >
              Você pode criar um com o Builder 😄
            </CustomAlert>
          ) : null}

          <Row>
            <Col lg={8}>
              {/* <InfoModal
                color={"warning"}
                title={"Atenção: Grandes mudanças chegaram!"}
                cardTitle={"Novo Builder, novos templates e muito mais!"}
                description={
                  <React.Fragment>
                    <p>
                      Com a nova atualização e implementação do novo Builder, os
                      templates antigos, tal como os já criados por você no
                      antigo Builder, serão descontinuados para dar lugar a
                      templates mais completos, e customizáveis!
                    </p>
                    <p>
                      No vídeo abaixo ensinamos como utilizar o novo Builder e
                      mostramos todas as novas funcionalidades, vale a pena dar
                      uma olhada!
                    </p>
                  </React.Fragment>
                }
                buttonText={"Certo, quero testar!"}
              /> */}
              <Card className="overflow-hidden">
                <Col lg={12}>
                  {loadingBrands ? (
                    <Preloader />
                  ) : !loadingBrands && brands.length >= 1 ? (
                    <div className="p-lg-5 p-4">
                      <div>
                        <h5>Criar novo Dashboard</h5>
                        <p className="text-muted">
                          Crie um novo Dashboard para a partir de um template.
                        </p>
                      </div>
                      <div className="mt-4 pt-3">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={handleSubmit}
                        >
                          {success_msg ? (
                            <CustomAlert
                              title="Perfeito!"
                              success
                              showCancel
                              confirmBtnStyle={{color: "white"}}
                              onConfirm={() => {
                                setsuccess_msg(false);
                                window.location.href =
                                  "/brands/" + selectedBrand;
                              }}
                              onCancel={() => {
                                setsuccess_msg(false);
                              }}
                            >
                              Sua Conta de Anúncios do <b>Facebook</b> foi
                              cadastrada com sucesso!
                            </CustomAlert>
                          ) : null}
                          {regStatus === "error" ? (
                            <Alert color="danger">
                              <i className="mdi mdi-block-helper me-2"></i>
                              {regDynamicText}
                            </Alert>
                          ) : null}

                          <div className="mb-5">
                            <Label>Empresa Vinculada</Label>
                            {queryParams.has("brand") ? (
                              <Card
                                style={{
                                  border: "none",
                                  boxShadow: "0px 0px 10px #00000012",
                                }}
                              >
                                <CardBody className="d-flex flex-row">
                                  <div>
                                    <img
                                      className="me-3"
                                      src={getImageURL(selectedBrand.logo)}
                                      alt="Imagem da empresa"
                                      style={{
                                        borderRadius: 10,
                                        backgroundColor: "#fff",
                                        height: 40,
                                        width: 75,
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <h6 className="fs-5 mb-0">
                                      {selectedBrand.name}
                                    </h6>
                                    <span className="text-muted">
                                      {selectedBrand.description}
                                    </span>
                                  </div>
                                </CardBody>
                              </Card>
                            ) : (
                              <BrandPicker
                                brands={brands}
                                loading={loadingBrands}
                                currentBrand={selectedBrand}
                                setSelectedBrand={setSelectedBrand}
                              />
                            )}
                          </div>

                          <h6 className="text-muted mb-4">Templates Metrito</h6>

                          <Row>
                            {metritoLayouts.map((template, key) => (
                              <TemplateCard
                                key={template._id}
                                loadingTemplates={loadingTemplates}
                                template={template}
                                isMetritoDefault={true}
                                selectedLayout={selectedLayout}
                                setSelectedLayout={setSelectedLayout}
                                isSelectable={true}
                              />
                            ))}
                          </Row>

                          <h6 className="text-muted mb-2">Meus templates</h6>

                          <Row
                            className="py-2"
                            style={{
                              maxHeight: 510,
                              overflowY: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            {version_3_layouts.length ? (
                              version_3_layouts.map((template, key) => (
                                <TemplateCard
                                  key={template._id}
                                  loadingTemplates={loadingTemplates}
                                  template={template}
                                  selectedLayout={selectedLayout}
                                  setSelectedLayout={setSelectedLayout}
                                  isSelectable={true}
                                />
                              ))
                            ) : (
                              <div
                                className="d-flex flex-row my-2"
                                style={{ maxWidth: 600 }}
                              >
                                <img
                                  src="https://cdn-icons-png.flaticon.com/512/7486/7486747.png"
                                  alt="Nenhum template encontrado"
                                  style={{ maxWidth: 120 }}
                                  className="m-2"
                                />
                                <div className="d-flex flex-column justify-content-center">
                                  <h5 className="text-muted text-start mx-3">
                                    Nenhum template encontrado
                                  </h5>
                                  <span className="align-middle fs-6 mx-3">
                                    Para criar um template, crie um dashboard ou
                                    abra um já existente e clique em "
                                    <b>Salvar como template</b>".
                                  </span>
                                </div>
                              </div>
                            )}
                          </Row>

                          <div className="mt-3 text-end">
                            <button
                              className="btn btn-success w-sm waves-effect waves-light btn-100"
                              type="submit"
                            >
                              <span className="text-white align-middle">
                                Criar novo Dashboard
                              </span>
                              <i className="bx bx-right-arrow-alt align-middle fs-4 ms-1"></i>
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  ) : (
                   <BrandNotFound />
                  )}
                </Col>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardCreate;
