import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row } from "reactstrap";

// import images
import logolight from "../../assets/images/logo-light.png";

//Import Breadcrumb

import { useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../services/api";

const PaymentSuccess = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const [success, setSuccess] = useState(false);
  const [billingPortalURL, setBillingPortalURL] = useState("");
  const [sessionData, setSessionData] = useState({});

  var order_data = queryParams.get("order_data");

  useEffect(() => {
    console.log(queryParams.get("success"));

    if (queryParams.get("success") == "true") {
      api
        .post("/create-portal-session", {
          session_id: queryParams.get("session_id"),
        })
        .then((res) => {
          setSuccess(true);
          setBillingPortalURL(res.data.url);
        })
        .catch((err) => {
          console.log(err);
        });

      api
        .get("/api/stripe/retrieve-session/" + queryParams.get("session_id"))
        .then((res) => {
          setSessionData(res.data);

          var session_data = res.data;

          //GTM - Purchase Event
          window.dataLayer.push({
            event: "purchase",
            value: parseFloat(session_data.amount_total / 100).toFixed(2),
            currency: "BRL",
            transaction_id: session_data.id,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    window.rewardful("ready", function () {
      console.log("Rewardful conversion", {
        email: queryParams.get("user_email"),
      });
      window.rewardful("convert", { email: queryParams.get("user_email") });
    });
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Obrigado!</title>
      </MetaTags>
      <div
        style={{
          height: 390,
          width: "100%",
          backgroundColor: "#1e55eb",
        }}
        className="d-flex justify-content-center"
      >
        <div className="text-center">
          <img
            src={logolight}
            alt=""
            height="50"
            className="auth-logo-dark mt-5"
          />
          {/* <img src={logolight} alt="" height="38" className="auth-logo-light" /> */}
        </div>
      </div>
      <Container fluid>
        <Row className="d-flex justify-content-center">
          <Col
            xl={5}
            lg={6}
            md={8}
            sm={10}
            className="d-flex justify-content-center"
          >
            <div
              className="text-center p-5"
              style={{
                height: 825,
                backgroundColor: "#fff",
                marginTop: -250,
                borderRadius: 10,
                boxShadow: "0px 10px 30px #00000020",
              }}
            >
              <img
                src={
                  "https://cdn.dribbble.com/users/2071065/screenshots/6702003/dribble_5-13_4x.png?compress=1&resize=1600x1200&vertical=top"
                }
                style={{
                  width: "30vw",
                  maxWidth: 300,
                  minWidth: 250,
                  paddingBottom: 30,
                }}
              />
              <h1>Você deu um passo gigante na sua caminhada!</h1>
              <p
                style={{
                  paddingLeft: "3vw",
                  paddingRight: "3vw",
                  marginTop: 30,
                  fontSize: 16,
                }}
              >
                Parabéns, agora você faz parte da revolução do tráfego pago!
                <br />
                <br />
                Sinta-se a vontade para dar sugestões e nos ajudar a construir
                algo incrível juntos!
              </p>

              <Link
                className="btn btn-success fs-5 m-2 px-5"
                style={{ padding: 10 }}
                disabled={!success}
                to="/home"
              >
                <b>Voltar para a plataforma</b>
              </Link>

              <button
                className="btn btn-light fs-5 m-2 px-5"
                style={{ padding: 10 }}
                disabled={!success}
                onClick={() => {
                  window.open(billingPortalURL);
                }}
              >
                <b>Ir para o portal do cliente</b>
              </button>
            </div>
          </Col>
          <div className="text-center pt-4">
            <footer className="site-footer" id="footer">
              <p className="site-footer__fineprint text-muted" id="fineprint">
                © 2022 Metrito | Todos os direitos reservados
              </p>
            </footer>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PaymentSuccess;
