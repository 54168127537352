import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import InputMask from "react-input-mask";
import MetaTags from "react-meta-tags";
import { Alert, Card, Col, Container, Input, Label, Row } from "reactstrap";

//Services
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";

//Jquery
import $ from "jquery";

//Import Breadcrumb
import classNames from "classnames";
import { category_options } from "../../common/data/category_options";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UploadFileModal from "../../components/Common/UploadFile.modal";
import { getImageURL } from "../../functions/getImageURL";
import CustomAlert from "../../components/CustomAlert";

const BrandCreate = (props) => {
  const [regStatus, setRegStatus] = useState("");
  const [regDynamicText, setRegDynamicText] = useState("");
  const [success_msg, setsuccess_msg] = useState(false);

  const [brandImageId, setBrandImageId] = useState("brand_placeholder.png");
  const [brandImage, setBrandImage] = useState(null);

  const [client_revenue, setClient_revenue] = useState(0);
  const [client_revenueFormat, setClient_revenueFormat] = useState("");

  const [category, setCategory] = useState({});

  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    setLoading(true);
    try {
      if (Object.keys(category).length !== 0) {
        const data = {
          name: event.target.brand_name.value,
          description: event.target.brand_description.value,
          owner: getIdUsuario(),
          color: document.getElementById("color-input").value,
          cnpj: event.target.cnpj.value,
          category: category.value,

          client_revenue: client_revenue,
          client_revenue_format: client_revenueFormat,
        };

        const response = await api.post("/api/v1/brands/", data);

        if (response.status === 200) {
          //GTM - Created Brand Event
          window.dataLayer.push({
            event: "created_brand",
          });

          //Saving logo to AWS
          if (brandImage) {
            const formData = new FormData();
            formData.append("image", brandImage);

            const aws_response = await api.put(
              `/api/v1/brands/logo/${response.data._id}`,
              formData
            );

            console.log(aws_response);

            setsuccess_msg(true);
            setRegStatus("");
            window.localStorage.setItem("onboardingBrandID", response.data._id);
            setLoading(false);
          } else {
            setsuccess_msg(true);
            setRegStatus("");
            window.localStorage.setItem("onboardingBrandID", response.data._id);
            setLoading(false);
          }
        } else {
          // alert("Erro ao cadastrar Conta de Anúncio");
          setRegStatus("error");
          setRegDynamicText("Erro ao criar empresa.");
          throw response;
        }
      } else {
        alert(
          "Você precisa escolher uma categoria (cliente, produto ou outra)"
        );
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setRegStatus("error");
      setRegDynamicText("Erro no servidor");
      setLoading(false);
    }
  }

  function onboardingProceedToStepTwo() {
    if (/onboarding/.test(window.location.href)) {
      $("#step-2").fadeOut(200);
      setTimeout(() => {
        $("#step-3").fadeIn(200);
      }, 300);
    } else {
      window.location.href = "/home";
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cadastrar Empresa | Metrito</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Empresas" breadcrumbItem="Cadastrar Empresa" />

          <Row
            className={classNames({
              "mt-5": /onboarding/.test(window.location.href),
            })}
          >
            <Col id="to-center" xl={8} lg={8} md={10} sm={12} xs={12}>
              <Card className="overflow-hidden">
                <Row className="g-0">
                  <Col lg={12}>
                    <div className="p-lg-5 p-4">
                      <div>
                        <h5>Adicionar Nova Empresa</h5>
                        <p className="text-muted">
                          Você precisará de uma Empresa para adicionar Contas de
                          Anúncio, Dashboards e gerar Relatórios Personalizados.
                        </p>
                      </div>

                      {success_msg ? (
                        <CustomAlert
                          title="Perfeito!"
                          success
                          showCancel
                          confirmBtnStyle={{color: "white"}}
                          onConfirm={() => {
                            setsuccess_msg(false);
                            onboardingProceedToStepTwo();
                          }}
                          onCancel={() => {
                            setsuccess_msg(false);
                            onboardingProceedToStepTwo();
                          }}
                        >
                          Sua Empresa do <b>Facebook</b> foi cadastrada com
                          sucesso!
                        </CustomAlert>
                      ) : null}

                      {regStatus === "error" ? (
                        <Alert color="danger">
                          <i className="mdi mdi-block-helper me-2"></i>
                          {regDynamicText}
                        </Alert>
                      ) : null}

                      <div className="pt-3">
                        <div className="mb-4 d-flex flex-row align-items-center w-100">
                          <div className="d-flex justify-content-center">
                            <img
                              src={
                                !brandImage
                                  ? getImageURL(brandImageId)
                                  : URL.createObjectURL(brandImage)
                              }
                              alt="Imagem da empresa"
                              style={{
                                borderRadius: 10,
                                backgroundColor: "#fff",
                                height: 160,
                                width: 300,
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <div>
                            <UploadFileModal
                              button_text={"Editar logo da empresa"}
                              image_uri={brandImageId}
                              type={"brand"}
                              setBrandImage={setBrandImage}
                            />
                          </div>
                        </div>

                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={handleSubmit}
                        >
                          <div className="mb-3">
                            <AvField
                              name="brand_name"
                              label="Nome"
                              type="text"
                              placeholder="Nome da empresa"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage:
                                    "Por favor, dê um nome a empresa",
                                },
                              }}
                            />
                          </div>

                          <div className="mb-3">
                            <AvField
                              id="description"
                              name="brand_description"
                              label="Descrição"
                              className="form-control"
                              placeholder="Descrição da empresa"
                              type="text"
                            />
                          </div>

                          <div className="mb-3">
                            <AvField
                              name="cnpj"
                              label="CNPJ"
                              placeholder="Insira o CNPJ da Empresa"
                              mask="99.999.999/9999-99"
                              maskChar=""
                              tag={[Input, InputMask]}
                            />
                          </div>

                          {/* <div className="mb-3">
                            <AvField
                              name="tel_number"
                              label="Telefone para contato"
                              placeholder="Insira seu número de telefone"
                              mask="(99) 99999-9999"
                              maskChar=""
                              tag={[Input, InputMask]}
                            />
                          </div> */}

                          <div className="mb-3 d-none">
                            <Label
                              for="example-color-input"
                              className="form-label"
                            >
                              Cor Principal
                            </Label>
                            <input
                              className="form-control form-control-color mw-100"
                              type="color"
                              defaultValue="#1e55eb"
                              id="color-input"
                            />
                          </div>

                          <Row className="mb-3">
                            <Label>Categoria</Label>
                            {category_options.map((option, key) => (
                              <Col lg={4} md={4} sm={12} xs={12} key={key}>
                                <div
                                  className={`privacy-selector ${
                                    option.value === category.value
                                      ? "privacy-selected"
                                      : null
                                  } p-2 d-flex flex-row align-items-center`}
                                  onClick={() => {
                                    setCategory(option);
                                  }}
                                >
                                  <div className="d-flex">
                                    <i
                                      className={`${option.icon} text-dark mx-2 fs-3 align-top`}
                                    />
                                  </div>
                                  <div className="text-truncate">
                                    <span
                                      style={{
                                        fontSize: 14,
                                        display: "block",
                                        fontWeight: 600,
                                      }}
                                      className="mb-0 text-dark"
                                    >
                                      {option.label}
                                    </span>
                                    <span
                                      className="text-muted"
                                      style={{ fontSize: 11 }}
                                    >
                                      {option.description}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>

                          {/* {category.value === "client" ? (
                            <Col>
                              <div className="brand-category-box mt-4">
                                <h5>Cliente</h5>
                                <div className="mb-3">
                                  <Label className="form-label">Receita</Label>
                                  <div className="input-group mb-3">
                                    <InputMask
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Number"
                                      mask="R$ 99999999999999999999999999"
                                      maskChar=""
                                      onChange={(e) =>
                                        setClient_revenue(
                                          parseFloat(
                                            e.target.value.replace(/\D/g, "")
                                          )
                                        )
                                      }
                                    />
                                    <select
                                      className="form-select"
                                      style={{
                                        borderRadius: "0px 10px 10px 0px",
                                      }}
                                      onChange={(e) =>
                                        setClient_revenueFormat(e.target.value)
                                      }
                                    >
                                      <option>Selecione a frequência</option>
                                      <option>Mensalmente</option>
                                      <option>Semanalmente</option>
                                      <option>Diariamente</option>
                                    </select>
                                  </div>
                                </div>

                                <h5 className="mt-4">
                                  Este cliente lhe gera
                                  <b>
                                    <h4 className="d-inline text-success mx-2">
                                      {(client_revenue || 0).toLocaleString(
                                        "pt-br",
                                        {
                                          style: "currency",
                                          currency: "BRL",
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </h4>
                                    {client_revenueFormat.toLocaleLowerCase()}.
                                  </b>
                                </h5>
                              </div>
                            </Col>
                          ) : null} */}

                          <div className="mt-4 text-end">
                            <button
                              className="btn btn-success w-sm waves-effect waves-light btn-100 px-5"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <i className="mdi mdi-loading mdi-spin fs-6 me-3" />
                              ) : null}
                              <b className="text-white">Criar Empresa</b>
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BrandCreate;
