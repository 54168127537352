import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import _ from "lodash";

const approved_like_status = [
  "approved",
  "paid",
  "completed",
  "review",
  "partially_paid",
  "chargeback_won",
  "integrated",
  "pending_integration",
  "pending_integration_in_review",
];

const canceled_like_status = [
  "pending",
  "processing",
  "mediation",
  "rejected",
  "canceled",
  "refunded",
  "authorized",
  "charged_back",
  "checkout_error",
  "pre_checkout",
  "expired",
  "pending_refund",
  "payment_past_due",
  "chargeback_in_dispute",
  "chargeback_in_tratative",
  "chargeback_lost",
  "failed",
];

const SalesCard = (props) => {
  const widget = [
    {
      id: 1,
      title: "Quantidade de pedidos",
      count: props.data.length,
      dollor: false,
      icon: "mdi mdi-cart-arrow-right text-primary",
      percentage: "2.65%",
      color: "success",
      upArrow: true,
    },
    {
      id: 2,
      title: "Valor total ",
      count: _.sumBy(props.data, (item) => item.transaction.value / 100),
      dollor: true,
      icon: "mdi mdi-cash-multiple text-primary",
      percentage: "4.58%",
      color: "danger",
      upArrow: false,
    },
    {
      id: 3,
      title: "Pedidos aprovados",
      count: _.sumBy(
        _.filter(props.data, (o) =>
          approved_like_status.includes(o.transaction.status)
        ),
        (item) => item.transaction.value / 100
      ),
      dollor: true,
      icon: "mdi mdi-cart-check text-success",
      percentage: "14.33%",
      color: "success",
      upArrow: true,
    },
    {
      id: 4,
      title: "Pedidos cancelados",
      count: _.sumBy(
        _.filter(props.data, (o) =>
          canceled_like_status.includes(o.transaction.status)
        ),
        (item) => item.transaction.value / 100
      ),
      dollor: true,
      icon: "mdi mdi-cart-remove text-danger",
      percentage: "0.55%",
      color: "warning",
      upArrow: true,
    },
  ];
  return (
    <React.Fragment>
      <Row>
        {widget.map((widget, key) => (
          <Col md={6} xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="avatar-sm mx-auto mb-4">
                    <span className="avatar-title rounded-circle bg-light font-size-24">
                      <i className={widget.icon}></i>
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-muted text-uppercase fw-semibold">
                    {widget.title}
                  </p>
                  <h4 className="mb-1 mt-1">
                    {widget.dollor ? (
                      <span className="counter-value" data-target="58425">
                        {widget.count.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    ) : (
                      <span className="counter-value" data-target="58425">
                        {widget.count}
                      </span>
                    )}
                  </h4>
                </div>
                {/* <p className="text-muted mt-3 mb-0">
                  <span
                    className={"badge badge-soft-" + widget.color + " me-1"}
                  >
                    {widget.upArrow === true ? (
                      <i className="mdi mdi-arrow-up-bold me-1"></i>
                    ) : (
                      <i className="mdi mdi-arrow-down-bold me-1"></i>
                    )}
                    {widget.percentage}
                  </span>{" "}
                  since last week
                </p> */}
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default SalesCard;
