import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "reactstrap";
import { Button, Progress } from "rsuite";
import { Link } from "react-router-dom";
import moment from "moment";
import $ from "jquery";

const cardStyles = {
  backgroundColor: "#fff",
  minHeight: 60,
  position: "fixed",
  bottom: 0,
  left: "5vw",
  right: "5vw",
  zIndex: 9999,
  boxShadow: "0 0 25px 0 rgba(0,0,0,.15)",
  padding: "15px 15px",
  borderRadius: "10px 10px 0px 0px",
};

//Only for mobile
const FloatingTrialRemaining = (props) => {
  useEffect(() => {
    if (props.trialData.percentageRemaining >= 70) {
      $(".insights_progress > div > div > div").css(
        "background",
        `repeating-linear-gradient( 45deg,#3e6ce0,#3e6ce0 10px,#1e55eb 10px, #1e55eb 20px)`
      );
    } else if (
      props.trialData.percentageRemaining < 70 &&
      props.trialData.percentageRemaining >= 40
    ) {
      $(".insights_progress > div > div > div").css(
        "background",
        `repeating-linear-gradient( 45deg,#ffb649,#ffb649 10px,#fe9d02 10px, #fe9d02 20px)`
      );
    } else if (props.trialData.percentageRemaining < 40) {
      $(".insights_progress > div > div > div").css(
        "background",
        `repeating-linear-gradient( 45deg,#e05e5e,#e05e5e 10px, #E13C3C 10px, #E13C3C 20px)`
      );
    }
  }, [props.trialData]);

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        style={cardStyles}
      >
        <Row className="w-100 m-0">
          <Col
            xs={8}
            className="d-flex flex-column align-items-start justify-content-center"
          >
            <b>Teste expira {moment(props.trialExpiringDate).fromNow()}</b>
            <Progress.Line
              className="insights_progress m-0 p-0 mt-1 progress-stripes"
              percent={props.trialData.percentageRemaining}
              strokeColor={
                props.trialData.percentageRemaining >= 90
                  ? "#00c853"
                  : props.trialData.percentageRemaining < 90 &&
                    props.trialData.percentageRemaining > 50
                  ? "orange"
                  : "#E13C3C"
              }
              showInfo={false}
              style={{
                width: "100%",
                maxWidth: 800,
                marginTop: 20,
              }}
            />
          </Col>
          <Col
            xs={4}
            className="d-flex align-items-center justify-content-center align-items-center"
          >
            <Link
              to="/plans"
              className="btn btn-success btn-green text-truncate w-100"
            >
              <i className="bx bx-arrow-up"></i>
              Upgrade
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};
//Only for mobile

export default FloatingTrialRemaining;
