import React, { useState } from "react";
import { Col, Container, Modal, Row } from "reactstrap";

const InfoModal = (props) => {
  const [modal_xlarge, setmodal_xlarge] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    // removeBodyCss();
  }

  return (
    <React.Fragment>
      <div
        className="button-items"
        style={{ marginBottom: 10, marginRight: 10 }}
      >
        <button
          type="button"
          onClick={() => {
            tog_xlarge();
          }}
          className={`btn btn-${props.color || "danger"}`}
          data-toggle="modal"
          data-target=".bs-example-modal-xl"
          style={{ width: "100%" }}
        >
          <div style={{ alignItems: "center" }}>
            <i className="bx bx-info-circle me-2"></i>
            <span>
              {props.title ||
                "Ops! Você ainda não cadastrou nenhuma conta. Clique aqui e descubra como fazer isso!"}
            </span>
          </div>
        </button>{" "}
      </div>

      <Container>
        <Modal
          className="modal-dialog-centered modal-dialog-scrollable"
          size="xl"
          isOpen={modal_xlarge}
          toggle={() => {
            tog_xlarge();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              {props.cardTitle ||
                "Cadastrando nova Conta de Anúncio do Facebook"}
            </h5>
            <button
              onClick={() => {
                setmodal_xlarge(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {props.description || (
              <React.Fragment>
                <p>
                  Conectar uma nova conta do Faceboook no Metrito é muito fácil
                  !
                </p>
                <p className="mb-12">
                  Abaixo deixamos um tutorial bem rapidinho que te ensina a
                  fazer todo o passo a passo em menos de 2 minutos! Qualquer
                  dúvida é só chamar o suporte! 😉
                </p>
              </React.Fragment>
            )}

            <Row className="d-flex justify-content-center my-3">
              <Col lg={12}>
                <div className="embed-container">
                  <iframe
                    src={
                      props.videoURI ||
                      "https://player.vimeo.com/video/708374938?h=1aabd30a98"
                    }
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    mozallowfullScreen
                    webkitallowfullScreen
                  ></iframe>
                </div>
              </Col>
            </Row>

            <div className="text-center pb-3">
              <button
                className="btn btn-success text-white"
                onClick={() => tog_xlarge()}
              >
                {props.buttonText || "Aprendi! Vamos lá"}
                <i className="bx bx-right-arrow-alt"></i>
              </button>
            </div>

            {/* <div className="">
            <div className="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                title="YouTube video"
                allowFullScreen
              ></iframe>
            </div>
          </div> */}
          </div>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default InfoModal;
