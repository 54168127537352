import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import api from "../../services/api";
//SimpleBar
import Preloader from "../Utility/Preloader";

const ProfileTab = (props) => {
  const [activeTab, setActiveTab] = useState("2");
  const [userPlan, setUserPlan] = useState({});
  const [loading, setLoading] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  var extra_ad_accounts =
    props.userInfo.subscription?.extra_ad_accounts_qty || 0;

  var subscription_payment_amount =
    props.userInfo.subscription?.full_amount ||
    extra_ad_accounts * userPlan?.extra_account_price + userPlan?.price;

  async function getUserPlan(plan_key) {
    setLoading(true);
    if (props.userInfo.subscription) {
      const plans = await api.get("/api/plans");
      var user_plan = plans.data.find(
        (plan) => plan.gateway_product_id.replace("GR-", "") === plan_key
      );
      console.log(user_plan);
      setUserPlan(user_plan);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (props.userInfo.subscription) {
      getUserPlan(
        props.userInfo.subscription.items
          ?.find((item) => item.plan.nickname.includes("PL"))
          .plan.nickname.replace("GR-", "") ||
          props.userInfo.subscription.plan.sku
      );
    }
  }, [props.userInfo.subscription]);

  useEffect(() => {
    console.log(userPlan);
  }, [userPlan]);

  return (
    <React.Fragment>
      <Card className="mb-0">
        <Nav tabs justified className="nav-tabs-custom" role="tablist">
          <NavItem>
            <NavLink
              to="#"
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              <i className="mdi mdi-account-circle-outline font-size-20"></i>
              <span className="d-none d-sm-block">Informações</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              <i className="mdi mdi-clipboard-text-outline font-size-20"></i>
              <span className="d-none d-sm-block">Pagamento</span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent className="p-4" activeTab={activeTab}>
          <TabPane id="about" role="tabpanel" tabId="1">
            <h5 className="font-size-16 mb-3">Informações da conta</h5>

            <div>
              <p className="mb-1 text-muted">Número de Empresas :</p>
              <h5 className="fs-2">{props.userInfo?.brands?.length}</h5>
            </div>

            <div>
              <p className="mb-1 text-muted">Contas de Anúncio :</p>
              <h5 className="fs-2">
                {props.userInfo?.brands?.flatMap((brand) => brand.ad_accounts)
                  .length || 0}
              </h5>
            </div>

            {/* <div>
              <p className="mb-1 text-muted">Dashboards:</p>
              <h5 className="font-size-14">-</h5>
            </div> */}
          </TabPane>
          <TabPane id="plan" role="tabpanel" tabId="2">
            <div>
              <h5 className="font-size-16 mb-3">Plano e Faturas</h5>
            </div>
            {!loading &&
            Object.keys(props.userInfo).length > 0 &&
            props.userPlan != undefined &&
            userPlan.price &&
            props.userInfo.subscription ? (
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div className="d-flex flex-row align-items-center">
                        <img
                          src={userPlan.icon_url}
                          alt="plano"
                          style={{ height: 50, width: "auto" }}
                        />
                        <div className="d-flex flex-row justify-content-between w-100">
                          <div className="d-flex justify-content-center ms-3">
                            <div>
                              <h5>Assinatura</h5>
                              <h6>{userPlan.name}</h6>
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <b className="text-muted">Mensal</b>
                            <b className="fs-5">
                              {userPlan.price.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                                maximumFractionDigits: 2,
                              })}
                            </b>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>

                  {extra_ad_accounts > 0 ? (
                    <Card>
                      <CardBody>
                        <div className="d-flex flex-row align-items-center">
                          <i
                            className="fas fa-plug text-primary"
                            style={{
                              fontSize: 30,
                              marginLeft: 15,
                              marginRight: 15,
                            }}
                          />
                          <div className="d-flex flex-row justify-content-between w-100">
                            <div className="d-flex justify-content-center ms-3">
                              <div>
                                <h5>Contas Extras</h5>
                                <h6>Quantidade: {extra_ad_accounts}</h6>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <b className="text-muted">Mensal</b>
                              <b className="fs-5">
                                {(
                                  props.userInfo.subscription.extra_ad_accounts
                                    ?.quantity *
                                    props.userInfo.subscription
                                      .extra_ad_accounts?.price ||
                                  extra_ad_accounts *
                                    userPlan?.extra_account_price
                                ).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                  maximumFractionDigits: 2,
                                })}
                              </b>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  ) : null}

                  <hr className="divider" />

                  <div className="w-100 text-end">
                    <b className="fs-4 mx-3">
                      Total:{" "}
                      <b className="text-success ms-3">
                        {subscription_payment_amount.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </b>
                      <span className="text-muted fs-6 ms-2">/ mês</span>
                    </b>
                  </div>
                </Col>
              </Row>
            ) : !loading &&
              Object.keys(props.userInfo).length > 0 &&
              !props.userInfo.subscription ? (
              <div>
                <Card>
                  <CardBody>
                    <div className="d-flex flex-row align-items-center">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/520/520461.png"
                        style={{ height: 60 }}
                      />
                      <div className="d-flex flex-row justify-content-between w-100">
                        <div className="d-flex justify-content-center ms-4">
                          <div>
                            <h5>Como assim ainda não tem nenhum plano?</h5>
                            <b>
                              Contrate um plano e desfrute da melhor experiência
                              com o Metrito!
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div
                  className="w-100 text-center d-flex flex-column align-items-center p-3"
                  style={{
                    border: "2px dashed #ededed",
                    borderRadius: 20,
                    marginBottom: 20,
                  }}
                >
                  <h5>Escolha um plano e comece hoje mesmo!</h5>
                  <p>
                    Liberação instantânea após pagamento, suporte dedicado,
                    atualizações semanais e muito mais!
                  </p>
                  <Link to="/plans" className="btn btn-success fs-5">
                    Clique para escolher um plano da melhor ferramenta de todas!
                  </Link>
                  <img
                    src="https://paymentsplugin.com/assets/blog-images/stripe-badge-transparent.png"
                    alt="formas de pagamento"
                    style={{ height: 100 }}
                    className="d-block mt-4"
                  />
                </div>
              </div>
            ) : (
              <Preloader />
            )}
          </TabPane>
        </TabContent>
      </Card>
    </React.Fragment>
  );
};

export default ProfileTab;
