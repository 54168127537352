const INITIAL_STATE = {
  loading: true,
  adaccount: {},
  metrics: [],
  summary: [],
  campaigns: [],
  activeAdaccount: {},
  test: "Jesus é meu amor!",
};

function WidgetReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: action.loading,
      };
      break;
    case "SET_ADACCOUNT":
      return {
        ...state,
        adaccount: action.adaccount,
      };
      break;
    case "SET_METRICS":
      return {
        ...state,
        metrics: action.metrics,
        summary: action.summary,
      };
      break;
    case "SET_CAMPAIGNS":
      return {
        ...state,
        campaigns: action.campaigns,
      };
      break;

    case "CLEAN_FB":
      return { ...state };
    default:
      return state;
      break;
  }
}

export default WidgetReducer;
