import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Uploader, Button, Progress, Input, Loader } from "rsuite";

import Helmet from "react-helmet";
import Navbar from "../../components/HorizontalLayout/Navbar";
import api from "../../services/api";

import { io } from "socket.io-client";

const req_data = {
  level: "account",
  mode: "aggregated",
  ad_accounts: [
    {
      data_source: "AW",
      ds_id: "9620889471-9620889471",
      name: "Priscilla de Sá",
      token:
        "1//0d3U30gop3K7LCgYIARAAGA0SNwF-L9IrXNstbMZXUEclhvmCpacxAKvvpHbprGHYnehCNdjVZWVCdndFU_vS7R7eEbTJ9jsXM0k",
      connected_at: "2023-01-04T15:32:21.354Z",
      updated_at: "2023-01-04T15:32:21.354Z",
      _id: {
        $oid: "63b59c05686f04c586cc7fab",
      },
    },
    {
      data_source: "FA",
      ds_id: "act_245306073168826",
      name: "CR IMÓVEIS",
      token:
        "EAAK3Rc8SsXgBAO5gBaoFOH1mjtwuKZAi817ExuFVImcUHdyucA38KAhw9iYZAJGJYZBrv8ioAldpL1afszYsAXQAQHXKwcipobrhGoIya3Ed1ZA2yocSHAVA5i20VMoSONv6GPV7HAW24EtZBW1fkwTlnm2OZAI6ZCrQjUdYUAmdAZDZD",
      connected_at: "2023-01-26T00:42:47.292Z",
      updated_at: "2023-01-26T00:42:47.292Z",
      _id: {
        $oid: "63d1cc87b09d1c3604410b3e",
      },
    },
    {
      data_source: "FA",
      ds_id: "act_1305047873611980",
      name: "CA01",
      token:
        "EAAK3Rc8SsXgBANMSvAjWGFm3rC5QlDceZBGXES024qlrNBh0ogLBvfmaHKrJ54hdl8BK5qRjg6KEd66DOpk4KNfbEXNKEcZBhX6Gx8ZBC5HQOSaCn9Ki6VPpE0nTZBX68zmxmlYav1BcGVlEHiNuQD9RH0At9TqXJXQog83ghX3EHfgexIUTnoDbnvj5cuwZD",
      connected_at: "2023-02-07T16:53:47.087Z",
      updated_at: "2023-02-07T16:53:47.087Z",
      _id: {
        $oid: "63e2821b2098aeb2b2790859",
      },
    },
    {
      data_source: "FA",
      ds_id: "act_639869824334087",
      name: "Manuela Bezerra",
      token:
        "EAAK3Rc8SsXgBAGr68UVT3ZCpUPhqFSpAyBRz3Jw9j2FunCwcZBWXZBi3piO5h87O6yxQCrY9z3lfNcYJueLxQRle8mdPa48xzsg8FQIvi24bZAvetusQkGZAXracVXWfZCDUlsJAWhg2gmU7kX5e7Il5Cvj08YlOmC9wZBiJ8sBKOpDu1BAOY7cZAMhyjr2XQHkZD",
      connected_at: "2023-02-07T16:55:52.835Z",
      updated_at: "2023-02-07T16:55:52.835Z",
      _id: {
        $oid: "63e282982098aeb2b2790884",
      },
    },
  ],
  date_range: {
    start_date: "2021-01-06",
    end_date: "2023-01-12",
  },
};

// const req_data = {
//   mode: "aggregated",
//   ad_accounts: [
//     {
//       data_source: "AW",
//       ds_id: "9076871617-2479308346",
//       name: "Conta HomemCaps",
//       token:
//         "1//0dtoYrJDu3hl6CgYIARAAGA0SNgF-L9IrbrJzSjhRWe3f6nDbMMrf4CwMPcVESuH8DeuTo6Ldmj-9pXdoW1A53Ic30rFPWHQVHQ",
//       connected_at: "2023-02-01T16:01:42.643Z",
//       updated_at: "2023-02-01T16:01:42.643Z",
//       _id: {
//         $oid: "63da8ce63de1aac84e5d3ef7",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9076871617-4548276915",
//       name: "Conta Okinawa 1",
//       token:
//         "1//0dtoYrJDu3hl6CgYIARAAGA0SNgF-L9IrbrJzSjhRWe3f6nDbMMrf4CwMPcVESuH8DeuTo6Ldmj-9pXdoW1A53Ic30rFPWHQVHQ",
//       connected_at: "2023-02-01T16:01:46.187Z",
//       updated_at: "2023-02-01T16:01:46.187Z",
//       _id: {
//         $oid: "63da8cea3de1aac84e5d3eff",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9076871617-2966140791",
//       name: "Morosina Novo",
//       token:
//         "1//0dtoYrJDu3hl6CgYIARAAGA0SNgF-L9IrbrJzSjhRWe3f6nDbMMrf4CwMPcVESuH8DeuTo6Ldmj-9pXdoW1A53Ic30rFPWHQVHQ",
//       connected_at: "2023-02-01T16:01:48.884Z",
//       updated_at: "2023-02-01T16:01:48.884Z",
//       _id: {
//         $oid: "63da8cec3de1aac84e5d3f08",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9076871617-8953941888",
//       name: "Zexter Duragel",
//       token:
//         "1//0dtoYrJDu3hl6CgYIARAAGA0SNgF-L9IrbrJzSjhRWe3f6nDbMMrf4CwMPcVESuH8DeuTo6Ldmj-9pXdoW1A53Ic30rFPWHQVHQ",
//       connected_at: "2023-02-01T16:01:51.800Z",
//       updated_at: "2023-02-01T16:01:51.800Z",
//       _id: {
//         $oid: "63da8cef3de1aac84e5d3f12",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-1062408794",
//       name: "Ag DuraGel",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:50:14.450Z",
//       updated_at: "2023-02-02T12:50:14.450Z",
//       _id: {
//         $oid: "63dbb186cea386728b9e85f4",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-4818771848",
//       name: "Bl DuraGel",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:50:19.470Z",
//       updated_at: "2023-02-02T12:50:19.470Z",
//       _id: {
//         $oid: "63dbb18bcea386728b9e8600",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-6821678527",
//       name: "Ds Extrato Africano",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:50:22.463Z",
//       updated_at: "2023-02-02T12:50:22.463Z",
//       _id: {
//         $oid: "63dbb18ecea386728b9e860d",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-1145966149",
//       name: "Fc DuraLong",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:50:25.763Z",
//       updated_at: "2023-02-02T12:50:25.763Z",
//       _id: {
//         $oid: "63dbb191cea386728b9e861b",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-3263420793",
//       name: "Fc Super Azulzinho",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:54:39.462Z",
//       updated_at: "2023-02-02T12:54:39.462Z",
//       _id: {
//         $oid: "63dbb28fcea386728b9e8707",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-3400066949",
//       name: "Fht",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:54:42.162Z",
//       updated_at: "2023-02-02T12:54:42.162Z",
//       _id: {
//         $oid: "63dbb292cea386728b9e8717",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-1629637125",
//       name: "Fi TriboMax",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:54:45.060Z",
//       updated_at: "2023-02-02T12:54:45.060Z",
//       _id: {
//         $oid: "63dbb295cea386728b9e8728",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-7662101766",
//       name: "Fxc",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:54:47.910Z",
//       updated_at: "2023-02-02T12:54:47.910Z",
//       _id: {
//         $oid: "63dbb297cea386728b9e873a",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-8720942094",
//       name: "He DuraGel",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:54:50.074Z",
//       updated_at: "2023-02-02T12:54:50.074Z",
//       _id: {
//         $oid: "63dbb29acea386728b9e874d",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-8421775392",
//       name: "Ho DuraGel",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:54:52.305Z",
//       updated_at: "2023-02-02T12:54:52.305Z",
//       _id: {
//         $oid: "63dbb29ccea386728b9e8761",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-2458801794",
//       name: "Ja Raizes + Gozzamax",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:54:57.639Z",
//       updated_at: "2023-02-02T12:54:57.639Z",
//       _id: {
//         $oid: "63dbb2a1cea386728b9e8776",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-1914997505",
//       name: "Jb DuraLong",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:00.115Z",
//       updated_at: "2023-02-02T12:55:00.115Z",
//       _id: {
//         $oid: "63dbb2a4cea386728b9e878c",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-3817933221",
//       name: "Jl DuraGel",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:02.452Z",
//       updated_at: "2023-02-02T12:55:02.452Z",
//       _id: {
//         $oid: "63dbb2a6cea386728b9e87a3",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-7714528562",
//       name: "Ks Extrato Africano",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:05.532Z",
//       updated_at: "2023-02-02T12:55:05.533Z",
//       _id: {
//         $oid: "63dbb2a9cea386728b9e87bb",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-3327585625",
//       name: "Kyt",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:08.046Z",
//       updated_at: "2023-02-02T12:55:08.046Z",
//       _id: {
//         $oid: "63dbb2accea386728b9e87d4",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-8154857100",
//       name: "Lo Super Azulzinho",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:10.150Z",
//       updated_at: "2023-02-02T12:55:10.150Z",
//       _id: {
//         $oid: "63dbb2aecea386728b9e87ee",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-9484991501",
//       name: "Lwj OKINAWA",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:13.345Z",
//       updated_at: "2023-02-02T12:55:13.345Z",
//       _id: {
//         $oid: "63dbb2b1cea386728b9e8809",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-8174203311",
//       name: "Mc DuraGel",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:15.567Z",
//       updated_at: "2023-02-02T12:55:15.567Z",
//       _id: {
//         $oid: "63dbb2b3cea386728b9e8825",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-1550581295",
//       name: "Morosina 1",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:18.237Z",
//       updated_at: "2023-02-02T12:55:18.237Z",
//       _id: {
//         $oid: "63dbb2b6cea386728b9e8842",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-1177999878",
//       name: "Nu DuraGel",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:21.217Z",
//       updated_at: "2023-02-02T12:55:21.217Z",
//       _id: {
//         $oid: "63dbb2b9cea386728b9e8860",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-8780294244",
//       name: "Oc Super Azulzinho",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:24.265Z",
//       updated_at: "2023-02-02T12:55:24.265Z",
//       _id: {
//         $oid: "63dbb2bccea386728b9e887f",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-2166449815",
//       name: "Pgp DuraGel",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:26.499Z",
//       updated_at: "2023-02-02T12:55:26.499Z",
//       _id: {
//         $oid: "63dbb2becea386728b9e889f",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-1868887984",
//       name: "Rb Okinawa",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:29.589Z",
//       updated_at: "2023-02-02T12:55:29.589Z",
//       _id: {
//         $oid: "63dbb2c1cea386728b9e88c0",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-7332589445",
//       name: "Re DuraGel",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:32.892Z",
//       updated_at: "2023-02-02T12:55:32.892Z",
//       _id: {
//         $oid: "63dbb2c4cea386728b9e88e2",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-1053092701",
//       name: "Rk Morosina",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:35.415Z",
//       updated_at: "2023-02-02T12:55:35.415Z",
//       _id: {
//         $oid: "63dbb2c7cea386728b9e8905",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-1424039939",
//       name: "Sj Morosina",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:37.784Z",
//       updated_at: "2023-02-02T12:55:37.784Z",
//       _id: {
//         $oid: "63dbb2c9cea386728b9e8929",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-9578640429",
//       name: "Teste Extrato",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:40.958Z",
//       updated_at: "2023-02-02T12:55:40.958Z",
//       _id: {
//         $oid: "63dbb2cccea386728b9e894e",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-7897876237",
//       name: "Tp DuraGel",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:43.089Z",
//       updated_at: "2023-02-02T12:55:43.089Z",
//       _id: {
//         $oid: "63dbb2cfcea386728b9e8974",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-5681400142",
//       name: "Va Morosina",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:45.268Z",
//       updated_at: "2023-02-02T12:55:45.268Z",
//       _id: {
//         $oid: "63dbb2d1cea386728b9e899b",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "7063915935-6589978105",
//       name: "Vs Extrato Africano",
//       token:
//         "1//0dPcest3pJ0aACgYIARAAGA0SNwF-L9Ir6gHREuQ_4X39jMhX9wgpEm9WEZvCo32ERQVVAtbPJm5Tn7eFNA5nSwg4p1CwzynLBMc",
//       connected_at: "2023-02-02T12:55:50.012Z",
//       updated_at: "2023-02-02T12:55:50.012Z",
//       _id: {
//         $oid: "63dbb2d6cea386728b9e89c3",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-7915180666",
//       name: "Ass DuraGel",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:07.495Z",
//       updated_at: "2023-02-02T13:15:07.495Z",
//       _id: {
//         $oid: "63dbb75bcea386728b9e9428",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-7685234519",
//       name: "Dkk Duralong",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:10.210Z",
//       updated_at: "2023-02-02T13:15:10.210Z",
//       _id: {
//         $oid: "63dbb75ecea386728b9e9451",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-1373689911",
//       name: "Fs Extrato Africano",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:12.338Z",
//       updated_at: "2023-02-02T13:15:12.338Z",
//       _id: {
//         $oid: "63dbb760cea386728b9e947b",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-5960791286",
//       name: "Hbe Extrato Africano",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:18.475Z",
//       updated_at: "2023-02-02T13:15:18.475Z",
//       _id: {
//         $oid: "63dbb766cea386728b9e94a6",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-3551590876",
//       name: "Hgd DuraGel",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:20.877Z",
//       updated_at: "2023-02-02T13:15:20.877Z",
//       _id: {
//         $oid: "63dbb768cea386728b9e94d2",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-6988912425",
//       name: "Jbf Raizes + Extrato",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:30.750Z",
//       updated_at: "2023-02-02T13:15:30.750Z",
//       _id: {
//         $oid: "63dbb772cea386728b9e950b",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-5662453980",
//       name: "Jds Extrato Africano",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:43.636Z",
//       updated_at: "2023-02-02T13:15:43.636Z",
//       _id: {
//         $oid: "63dbb77fcea386728b9e9539",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-6224477149",
//       name: "Jnd TriboMax",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:47.545Z",
//       updated_at: "2023-02-02T13:15:47.545Z",
//       _id: {
//         $oid: "63dbb783cea386728b9e9568",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-9873490929",
//       name: "Jng HomemCaps",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:51.117Z",
//       updated_at: "2023-02-02T13:15:51.117Z",
//       _id: {
//         $oid: "63dbb787cea386728b9e9598",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-6231190696",
//       name: "Jvn Extrato Africano",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:53.638Z",
//       updated_at: "2023-02-02T13:15:53.638Z",
//       _id: {
//         $oid: "63dbb789cea386728b9e95c9",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-2846275152",
//       name: "Kad DuraGel",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:56.138Z",
//       updated_at: "2023-02-02T13:15:56.138Z",
//       _id: {
//         $oid: "63dbb78ccea386728b9e95fb",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-7851905747",
//       name: "Kbn TriboMax",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:15:59.080Z",
//       updated_at: "2023-02-02T13:15:59.080Z",
//       _id: {
//         $oid: "63dbb78fcea386728b9e962e",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-5677942644",
//       name: "Kdb DuraGel",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:01.440Z",
//       updated_at: "2023-02-02T13:16:01.440Z",
//       _id: {
//         $oid: "63dbb791cea386728b9e9662",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-9803461809",
//       name: "Kfm HomemCaps",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:04.534Z",
//       updated_at: "2023-02-02T13:16:04.534Z",
//       _id: {
//         $oid: "63dbb794cea386728b9e9697",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-9386348305",
//       name: "Kgv DuraLong",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:06.929Z",
//       updated_at: "2023-02-02T13:16:06.929Z",
//       _id: {
//         $oid: "63dbb796cea386728b9e96d4",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-8297378905",
//       name: "Kis DuraGel",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:09.727Z",
//       updated_at: "2023-02-02T13:16:09.727Z",
//       _id: {
//         $oid: "63dbb799cea386728b9e970b",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-1367235496",
//       name: "Kvm DuraGel",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:12.160Z",
//       updated_at: "2023-02-02T13:16:12.160Z",
//       _id: {
//         $oid: "63dbb79ccea386728b9e9743",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-3994973111",
//       name: "Mh HomemCaps",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:14.733Z",
//       updated_at: "2023-02-02T13:16:14.733Z",
//       _id: {
//         $oid: "63dbb79ecea386728b9e977c",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-2009897627",
//       name: "Ovb DuraGel",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:16.896Z",
//       updated_at: "2023-02-02T13:16:16.896Z",
//       _id: {
//         $oid: "63dbb7a0cea386728b9e97b6",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-1774285782",
//       name: "Phk DuraGel",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:20.693Z",
//       updated_at: "2023-02-02T13:16:20.693Z",
//       _id: {
//         $oid: "63dbb7a4cea386728b9e97f1",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-4645987648",
//       name: "Sim Extrato Africano",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:25.372Z",
//       updated_at: "2023-02-02T13:16:25.372Z",
//       _id: {
//         $oid: "63dbb7a9cea386728b9e982d",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-7458340188",
//       name: "Sjb Raizes + Extrato",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:28.120Z",
//       updated_at: "2023-02-02T13:16:28.120Z",
//       _id: {
//         $oid: "63dbb7accea386728b9e986a",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-7277816366",
//       name: "Vui TriboMax",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:32.009Z",
//       updated_at: "2023-02-02T13:16:32.009Z",
//       _id: {
//         $oid: "63dbb7b0cea386728b9e98a8",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "4968624739-6759904419",
//       name: "Tak Extrato Africano",
//       token:
//         "1//0dA6d1oZWV7oZCgYIARAAGA0SNwF-L9Irs5KHZ94eJEU6DnbHkkmpobQJPMaWpdBIkIMbgSMkTs5U42Ff-S61d2JTrHm-AXhy8PM",
//       connected_at: "2023-02-02T13:16:34.478Z",
//       updated_at: "2023-02-02T13:16:34.478Z",
//       _id: {
//         $oid: "63dbb7b2cea386728b9e98e7",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-5278979994",
//       name: "Bjo DuraGel",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:00.541Z",
//       updated_at: "2023-02-02T13:18:00.541Z",
//       _id: {
//         $oid: "63dbb808cea386728b9e99af",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-7792896856",
//       name: "Dno DuraLong",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:04.424Z",
//       updated_at: "2023-02-02T13:18:04.424Z",
//       _id: {
//         $oid: "63dbb80ccea386728b9e99f0",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-5188786633",
//       name: "Dqf HomemCaps",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:06.752Z",
//       updated_at: "2023-02-02T13:18:06.752Z",
//       _id: {
//         $oid: "63dbb80ecea386728b9e9a32",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-5388112876",
//       name: "Iou DuraGel",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:10.335Z",
//       updated_at: "2023-02-02T13:18:10.335Z",
//       _id: {
//         $oid: "63dbb812cea386728b9e9a75",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-9262976945",
//       name: "Kdo TriboMax",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:14.417Z",
//       updated_at: "2023-02-02T13:18:14.417Z",
//       _id: {
//         $oid: "63dbb816cea386728b9e9ab9",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-8860871721",
//       name: "Kfp DuraGel",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:16.637Z",
//       updated_at: "2023-02-02T13:18:16.637Z",
//       _id: {
//         $oid: "63dbb818cea386728b9e9afe",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-2481648956",
//       name: "Mbi HomemCaps",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:20.186Z",
//       updated_at: "2023-02-02T13:18:20.186Z",
//       _id: {
//         $oid: "63dbb81ccea386728b9e9b44",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-8195618701",
//       name: "Mwb DuraLong",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:23.515Z",
//       updated_at: "2023-02-02T13:18:23.515Z",
//       _id: {
//         $oid: "63dbb81fcea386728b9e9b8b",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-8796763619",
//       name: "Nia TriboMax",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:28.351Z",
//       updated_at: "2023-02-02T13:18:28.351Z",
//       _id: {
//         $oid: "63dbb824cea386728b9e9bd3",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-7371850190",
//       name: "Pmm DuraGel",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:30.301Z",
//       updated_at: "2023-02-02T13:18:30.301Z",
//       _id: {
//         $oid: "63dbb826cea386728b9e9c1c",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-4094634793",
//       name: "Qdx HomemCaps",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:33.261Z",
//       updated_at: "2023-02-02T13:18:33.261Z",
//       _id: {
//         $oid: "63dbb829cea386728b9e9c66",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-5933739521",
//       name: "Qsx TriboMax",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:35.229Z",
//       updated_at: "2023-02-02T13:18:35.229Z",
//       _id: {
//         $oid: "63dbb82bcea386728b9e9cb1",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-2200456262",
//       name: "Sam Extrato Africano",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:37.182Z",
//       updated_at: "2023-02-02T13:18:37.182Z",
//       _id: {
//         $oid: "63dbb82dcea386728b9e9cfd",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-8957445698",
//       name: "Soh DuraLong",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:39.780Z",
//       updated_at: "2023-02-02T13:18:39.780Z",
//       _id: {
//         $oid: "63dbb82fcea386728b9e9d4a",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-2541848464",
//       name: "Sze TriboMax",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:42.561Z",
//       updated_at: "2023-02-02T13:18:42.561Z",
//       _id: {
//         $oid: "63dbb832cea386728b9e9d98",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-2823002405",
//       name: "Uif HomemCaps",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:49.841Z",
//       updated_at: "2023-02-02T13:18:49.841Z",
//       _id: {
//         $oid: "63dbb839cea386728b9e9de7",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-2348447984",
//       name: "Uqk Raízes + Extrato Africano",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:52.809Z",
//       updated_at: "2023-02-02T13:18:52.809Z",
//       _id: {
//         $oid: "63dbb83ccea386728b9e9e37",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "9904316992-3133409660",
//       name: "Wom DuraGel",
//       token:
//         "1//0d04y54sD6NVUCgYIARAAGA0SNwF-L9IrGxlXBp8oGHg_6ycC-gI7yztr3r3Ko15SbuoxMvpd8wLP2rSR-VGXca0yeMIXTcL94nQ",
//       connected_at: "2023-02-02T13:18:55.474Z",
//       updated_at: "2023-02-02T13:18:55.474Z",
//       _id: {
//         $oid: "63dbb83fcea386728b9e9e88",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-2196172242",
//       name: "Alv DuraGel",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:10.195Z",
//       updated_at: "2023-02-02T13:30:10.195Z",
//       _id: {
//         $oid: "63dbbae2cea386728b9ea3c0",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-7411030560",
//       name: "Bor DuraGel",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:15.817Z",
//       updated_at: "2023-02-02T13:30:15.817Z",
//       _id: {
//         $oid: "63dbbae7cea386728b9ea417",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-8649505760",
//       name: "Cmf DuraGel",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:21.793Z",
//       updated_at: "2023-02-02T13:30:21.793Z",
//       _id: {
//         $oid: "63dbbaedcea386728b9ea46b",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-8147324605",
//       name: "Crv Extrato Africano",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:24.221Z",
//       updated_at: "2023-02-02T13:30:24.221Z",
//       _id: {
//         $oid: "63dbbaf0cea386728b9ea4c0",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-6736471850",
//       name: "Csn DuraGel",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:27.270Z",
//       updated_at: "2023-02-02T13:30:27.270Z",
//       _id: {
//         $oid: "63dbbaf3cea386728b9ea52e",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-2167346344",
//       name: "Ctj DuraGel",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:29.755Z",
//       updated_at: "2023-02-02T13:30:29.755Z",
//       _id: {
//         $oid: "63dbbaf5cea386728b9ea585",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-5197256341",
//       name: "Dmc DuraLong",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:32.314Z",
//       updated_at: "2023-02-02T13:30:32.314Z",
//       _id: {
//         $oid: "63dbbaf8cea386728b9ea5dd",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-8641226160",
//       name: "Don DuraGel",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:35.633Z",
//       updated_at: "2023-02-02T13:30:35.633Z",
//       _id: {
//         $oid: "63dbbafbcea386728b9ea636",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-1312246788",
//       name: "Egi Extrato Africano",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:44.780Z",
//       updated_at: "2023-02-02T13:30:44.780Z",
//       _id: {
//         $oid: "63dbbb04cea386728b9ea690",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-1235424694",
//       name: "Jlh HomemCaps",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:50.505Z",
//       updated_at: "2023-02-02T13:30:50.505Z",
//       _id: {
//         $oid: "63dbbb0acea386728b9ea74b",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-4475133621",
//       name: "Kpb DuraGel",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:53.723Z",
//       updated_at: "2023-02-02T13:30:53.723Z",
//       _id: {
//         $oid: "63dbbb0dcea386728b9ea7a7",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-2604895490",
//       name: "Lvb DuraGel",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:30:55.969Z",
//       updated_at: "2023-02-02T13:30:55.969Z",
//       _id: {
//         $oid: "63dbbb0fcea386728b9ea804",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-7461809017",
//       name: "Vlr Raizes + Extrato Africano",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:31:05.483Z",
//       updated_at: "2023-02-02T13:31:05.483Z",
//       _id: {
//         $oid: "63dbbb19cea386728b9ea86a",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-2410929476",
//       name: "Rfp HomemCaps",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:31:08.183Z",
//       updated_at: "2023-02-02T13:31:08.183Z",
//       _id: {
//         $oid: "63dbbb1ccea386728b9ea8c9",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-7620070091",
//       name: "Rap HomemCaps",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:31:10.725Z",
//       updated_at: "2023-02-02T13:31:10.725Z",
//       _id: {
//         $oid: "63dbbb1ecea386728b9ea929",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-8807015009",
//       name: "Qua HomemCaps",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:31:13.185Z",
//       updated_at: "2023-02-02T13:31:13.185Z",
//       _id: {
//         $oid: "63dbbb21cea386728b9ea98c",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-9472527166",
//       name: "Qcr Raizes + Extrato Africano",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:31:15.747Z",
//       updated_at: "2023-02-02T13:31:15.747Z",
//       _id: {
//         $oid: "63dbbb23cea386728b9ea9f0",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-3224327439",
//       name: "One Reviews",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:31:17.811Z",
//       updated_at: "2023-02-02T13:31:17.811Z",
//       _id: {
//         $oid: "63dbbb25cea386728b9eaa53",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-9318476323",
//       name: "Ola HomemCaps",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:31:20.795Z",
//       updated_at: "2023-02-02T13:31:20.795Z",
//       _id: {
//         $oid: "63dbbb28cea386728b9eaab9",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-5576283646",
//       name: "Nvu DuraLong",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:31:23.272Z",
//       updated_at: "2023-02-02T13:31:23.272Z",
//       _id: {
//         $oid: "63dbbb2bcea386728b9eab20",
//       },
//     },
//     {
//       data_source: "AW",
//       ds_id: "3222562766-3922558925",
//       name: "Mfp DuraLong",
//       token:
//         "1//0d3qFBzEXEnAECgYIARAAGA0SNwF-L9Irjcvv4axDg75ewRGOvEuBKFv-LcF9ok_uf9QQdCJNwunl5RslE2KbbMmCV2r2EDQ_sZI",
//       connected_at: "2023-02-02T13:31:25.814Z",
//       updated_at: "2023-02-02T13:31:25.814Z",
//       _id: {
//         $oid: "63dbbb2dcea386728b9eab88",
//       },
//     },
//   ],
//   date_range: {
//     start_date: "2021-01-06",
//     end_date: "2023-01-12",
//   },
// };

const PagesStarter = () => {
  const socketRef = useRef();
  const [loadingClickHouseData, setLoadingClickHouseData] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState({});

  const [batchProgress, setBatchProgress] = useState({});

  // Handle progress updates from the server
  function handleProgressUpdate(progressUpdate) {
    setBatchProgress((prevBatchProgress) => {
      const { batchId } = progressUpdate;
      const currentProgress = prevBatchProgress[batchId] || {
        total_reqs: 0,
        done_reqs: 0,
      };
      const updatedProgress = {
        total_reqs: progressUpdate.total_reqs,
        done_reqs: progressUpdate.done_reqs,
      };
      const updatedBatchProgress = {
        ...prevBatchProgress,
        [batchId]: { ...currentProgress, ...updatedProgress },
      };
      return updatedBatchProgress;
    });
  }

  // Calculate overall progress
  const totalReqs = Object.values(batchProgress).reduce(
    (total, { total_reqs }) => total + total_reqs,
    0
  );
  const doneReqs = Object.values(batchProgress).reduce(
    (total, { done_reqs }) => total + done_reqs,
    0
  );
  const overallProgress =
    totalReqs > 0 ? Math.floor((doneReqs / totalReqs) * 100) : 0;

  useEffect(() => {
    const socket = io(
      window.location.origin.includes("localhost")
        ? "http://localhost:4000"
        : window.location.origin
    );
    socketRef.current = socket;
  }, []);

  useEffect(() => {
    const socket = socketRef.current;

    socket.on("connect", () => {
      socket.on("welcome", (data) => {
        console.log(data);
      });

      socket.on("test", (data) => {
        console.log(data);
      });

      socket.on("loading_progress", (data) => {
        console.log(data);

        if (
          data.batchId.includes("CLICKHOUSE") &&
          data.done_reqs === data.total_reqs
        ) {
          console.log(
            "CLICKHOUSE IS FULLY LOADED: ",
            data.batchId.includes("CLICKHOUSE") &&
              data.done_reqs === data.total_reqs
          );
          setLoadingClickHouseData(false);
        } else if (
          data.batchId.includes("CLICKHOUSE") &&
          data.done_reqs !== data.total_reqs
        ) {
          setLoadingClickHouseData(true);
        }

        setLoadingProgress(data);
        handleProgressUpdate(data);
      });

      socket.on("insights_response", (data) => {
        console.log(data);
      });

      socket.on("error_message", (data) => {
        console.log(data);
      });
    });
  }, []);

  return (
    <div className="page-content d-flex flex-column justify-content-center">
      <Container>
        <Row className="d-flex flex-column justify-content-center align-items-center">
          <span className="my-4">
            Teste de progresso no carregamento com ClickHouse
          </span>

          {loadingClickHouseData ? (
            <Loader content="Loading ClickHouse data..." />
          ) : (
            <>
              {Object.keys(loadingProgress).length ? (
                <div>
                  <h5 className="text-center">
                    {totalReqs}/{doneReqs}
                  </h5>
                  <Progress.Line
                    className="my-4"
                    percent={overallProgress}
                    status="active"
                  />
                </div>
              ) : null}
            </>
          )}

          <button
            className="btn btn-primary my-5 px-5"
            onClick={() =>
              socketRef.current.emit("request_marketing_insights", req_data)
            }
          >
            Teste
          </button>
        </Row>
      </Container>
    </div>
  );
};

export default PagesStarter;
