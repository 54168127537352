import React from "react";
import { Popover, Whisper } from "rsuite";

const AccountNameWithAliasSupport = ({ account, titleSize }) => {
  return (
    <>
      {account.account_use_alias ? (
        <Whisper
          placement="top"
          trigger="hover"
          controlId="control-id-hover"
          speaker={
            <Popover>
              <div className="d-flex flex-column">
                <small className="text-muted">Nome real da conta</small>
                <b className={`text-truncate fs-${titleSize}`}>
                  {account.name ?? 'NOME NÃO PROVIDENCIADO'}
                </b>
              </div>
            </Popover>
          }
        >
          <b className="text-truncate d-flex align-items-center">
            {account.account_alias}
            <i
              className="mdi mdi-tag-text-outline text-muted ms-2"
              style={{ fontSize: 12 }}
            />
          </b>
        </Whisper>
      ) : (
        <b className={`text-truncate fs-${titleSize}`}>
          {account.account_use_alias ? account.account_alias : (account.name ?? 'SEM NOME')}
        </b>
      )}
    </>
  );
};

export default AccountNameWithAliasSupport;
