import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Button, Row, Col } from "reactstrap";
import { OffcanvasProvider, Trigger, Offcanvas } from "react-simple-offcanvas";

// import images
import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import welcomeImg from "../../assets/images/welcome_to_metrito.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const WelcomePage = () => {
  return (
    <React.Fragment>
      <div
        style={{ height: 390, width: "100%", backgroundColor: "#1e55eb" }}
        className="d-flex justify-content-center"
      >
        <div className="text-center">
          <img
            src={logolight}
            alt=""
            height="50"
            className="auth-logo-dark mt-5"
          />
          {/* <img src={logolight} alt="" height="38" className="auth-logo-light" /> */}
        </div>
      </div>
      <Container fluid>
        <Row className="d-flex justify-content-center">
          <Col
            xl={5}
            lg={6}
            md={8}
            sm={10}
            className="d-flex justify-content-center"
          >
            <div
              className="text-center p-5"
              style={{
                height: 825,
                backgroundColor: "#fff",
                marginTop: -250,
                borderRadius: 10,
                boxShadow: "0px 10px 30px #00000026",
              }}
            >
              <img
                src={welcomeImg}
                style={{
                  width: "30vw",
                  maxWidth: 300,
                  minWidth: 250,
                  paddingBottom: 30,
                }}
              />
              <h1>Que bom que você chegou!</h1>
              <p
                style={{
                  paddingLeft: "3vw",
                  paddingRight: "3vw",
                  marginTop: 30,
                  fontSize: 16,
                }}
              >
                Parabéns, agora você faz parte da revolução do tráfego pago!
                Fique a vontade para desfrutar da plataforma por 7 dias
                gratuitamente 😉
                <br />
                <br />
                Sinta-se a vontade para dar sugestões e nos ajudar a construir
                algo incrível juntos!
              </p>
              <Link to="/login">
                <button
                  className="btn btn-success fs-5 mt-5 px-5"
                  style={{ padding: 10 }}
                >
                  <b>Fazer Login na Plataforma</b>
                </button>
              </Link>
              <div className="py-3">
                <b>Compartilhe com os amigos</b>
              </div>
            </div>
          </Col>
          <div className="text-center pt-4">
            <footer className="site-footer" id="footer">
              <p className="site-footer__fineprint text-muted" id="fineprint">
                © 2022 Metrito | Todos os direitos reservados
              </p>
            </footer>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default WelcomePage;
