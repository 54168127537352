import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle } from "reactstrap";
import _ from "lodash";
import { useEffect } from "react";
import { payment_methods } from "../../../common/data/payment_methods";
import { order_status_list } from "../../../common/data/order_statuses";

function getPaymentMethod(code) {
  const pm = payment_methods.find((o) => o.code === code);
  return pm;
}

function getOrderStatus(code) {
  const status =
    order_status_list.find((o) => o.code === code) || order_status_list[0];
  return status;
}

const SalesDonutChart = (props) => {
  const groupByPaymentMethod = _.groupBy(
    props.data,
    (item) => item.transaction.payment_type
  );

  const GroupByStatus = {
    credit_card: _.groupBy(
      groupByPaymentMethod["credit_card"],
      (item) => item.transaction.status
    ),
    billet: _.groupBy(
      groupByPaymentMethod["billet"],
      (item) => item.transaction.status
    ),
    pix: _.groupBy(
      groupByPaymentMethod["pix"],
      (item) => item.transaction.status
    ),
  };

  const colors = Object.entries(GroupByStatus[props.paymentMethod]).map(
    (item) => getOrderStatus(item[0]).color
  );

  var options = {
    series: Object.entries(GroupByStatus[props.paymentMethod]).map(
      (item) => item[1].length
    ),
    labels: Object.entries(GroupByStatus[props.paymentMethod]).map(
      (item) => getOrderStatus(item[0]).label
    ),
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    fill: {
      colors: colors,
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
    colors: colors,
    plotOptions: {
      pie: {
        donut: {
          size: "85%",
          labels: {
            show: true,
            value: {
              show: true,
            },
            total: {
              show: true,
              label: "Total",
              color: "#373d3f",
            },
          },
        },
      },
    },
  };

  useEffect(() => {
    const groupByPaymentMethod = _.groupBy(
      props.data,
      (item) => item.transaction.payment_type
    );
  }, [props.data]);

  return (
    <Card className="d-flex justify-items-center">
      <CardBody>
        <CardTitle className="mb-4">
          {getPaymentMethod(props.paymentMethod).label}
        </CardTitle>

        {Object.entries(GroupByStatus[props.paymentMethod]).map(
          (item) => item[1].length
        ).length ? (
          <div className="d-flex flex-row">
            <ReactApexChart
              options={options}
              series={options.series}
              type="donut"
              height="150"
              width={150}
              className="apex-charts me-4"
              dir="ltr"
            />
            <div
              style={{
                fontSize: 12,
                maxHeight: 125,
                overflowY: "scroll",
                width: "100%",
              }}
            >
              {Object.entries(GroupByStatus[props.paymentMethod])?.map(
                (item) => {
                  const status = getOrderStatus(item[0]);
                  return (
                    <div className="d-flex flex-column">
                      <b>
                        <i
                          className="mdi mdi-circle me-2"
                          style={{ color: status.color }}
                        />
                        {status.label}
                      </b>
                      <span>
                        {`${_.sumBy(
                          item[1],
                          (o) => o.transaction.value / 100
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })} (${item[1].length})`}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        ) : (
          <div
            className="d-flex flex-column justify-content-center"
            style={{
              height: 130,
              border: "1px dashed #d4d4d4",
              borderRadius: 10,
              padding: 20,
            }}
          >
            <h6>Nenhuma venda com essa forma de pagamento</h6>
            <span className="text-muted">
              Não foi encontrada nenhuma venda com essa forma de pagamento no
              período selecionado.
            </span>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default SalesDonutChart;
