import React, { useEffect } from "react";
import { Table } from "rsuite";
import { TagGroup, Tag, Button } from "rsuite";
import { stages } from "../../../common/data/TagStages";
const { Column, HeaderCell, Cell } = Table;

const UTMCell = ({ rowData, dataKey, ...props }) => {
  const data = rowData[dataKey].split("-");

  return (
    <Cell {...props}>
      <TagGroup>
        {data.map((item, index) => {
          const stage = stages.find((stage) => item.includes(stage.key)) || {
            key: "TESTE",
            color: "black",
            icon: "mdi mdi-card-plus",
          };
          const filter_string = data.slice(0, index + 1).join("-");

          return (
            <>
              {data.filter((x) =>
                stages
                  .map((o) => o.key)
                  .includes(x.split("_")[0].replace(/[\[\]]/, ""))
              ).length ? (
                <Tag
                  className="button"
                  size="md"
                  style={{
                    backgroundColor: stage?.color || "#f7f7fa",
                    color: stage ? "#ffffff" : "#575757",
                  }}
                  onClick={() => props.setFilterString(filter_string)}
                >
                  {item && (
                    <>
                      <i className={`${stage.icon} me-2`} />
                      {item
                        .split("_")
                        .slice(1, item.split("_").length)
                        .join("")
                        .replace(/[\[\]]/, "")}
                    </>
                  )}
                </Tag>
              ) : (
                <Tag>{item}</Tag>
              )}
            </>
          );
        })}
      </TagGroup>
    </Cell>
  );
};

const TotalValueCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell {...props}>
      <span>
        {rowData[dataKey].toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}
      </span>
    </Cell>
  );
};

const SourceCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell {...props}>
      <Tag size="md">{rowData[dataKey]}</Tag>
    </Cell>
  );
};

const UTMsTable = (props) => {
  const data = Object.entries(props.data).map((entry) => ({
    source: entry[0],
    count: entry[1].length,
    value: entry[1].reduce((a, b) => a + b.transaction.value, 0) / 100,
  }));

  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  return (
    <>
      <Table
        className="card"
        height={342}
        rowHeight={40}
        data={getData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
      >
        <Column align="start" sortable resizable width={700}>
          <HeaderCell>Fonte</HeaderCell>
          <UTMCell
            dataKey="source"
            filterString={props.filterString}
            setFilterString={props.setFilterString}
          />
        </Column>

        <Column sortable align="start" resizable width={100}>
          <HeaderCell>Quantidade</HeaderCell>
          <Cell dataKey="count" />
        </Column>

        <Column sortable resizable width={100}>
          <HeaderCell>Valor total</HeaderCell>
          <TotalValueCell dataKey="value" />
        </Column>
      </Table>
    </>
  );
};

export default UTMsTable;
