import React from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Support = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Suporte | Metrito</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Suporte" breadcrumbItem="Chat de Suporte" />

          <Row className="d-flex justify-content-center align-items-center">
            <Col lg={12} md={12}>
              <CardBody>
                <Card
                  style={{
                    backgroundColor: "#fff",
                    minHeight: "60vh",
                    backgroundImage:
                      "url(https://1.bp.blogspot.com/-Sm8YnreT_80/XTdt-V7MCEI/AAAAAAADDO0/grmCucZ3Z6csUE4i0yphNvKN5GTgwO1vwCLcBGAs/s1600/LO1.gif)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <iframe
                    src="https://jivo.chat/X7qcqGhVAV"
                    frameborder="0"
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                    }}
                  ></iframe>
                </Card>
              </CardBody>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Support;
