export const stages = [
  {
    key: "SRC",
    label: "Fonte",
    color: "#f44336",
    icon: "bx bxs-megaphone",
    default: true,
  },
  {
    key: "PF",
    label: "Perfil",
    color: "#00bcd4",
    icon: "mdi mdi-account",
    default: true,
  },
  {
    key: "CA",
    label: "Conta de anúncios",
    color: "#2196f3",
    icon: "mdi mdi-account-box-multiple-outline",
    default: true,
  },
  {
    key: "BM",
    label: "Business Manager",
    color: "#31304b",
    icon: "mdi mdi-briefcase-outline",
    default: true,
  },
  {
    key: "CP",
    label: "Campanha",
    color: "#4caf50",
    icon: "mdi mdi-folder-table",
    default: true,
  },
  {
    key: "CJ",
    label: "Conjunto",
    color: "#ffb300",
    icon: "mdi mdi-grid-large",
    default: true,
  },
  {
    key: "AD",
    label: "Anúncio",
    color: "#673ab7",
    icon: "mdi mdi-image",
    default: true,
  },
];

export const facebook_dynamic_vars = [
  {
    foreign_key: "AD",
    id: "{{ad.id}}",
    name: "{{ad.name}}",
  },
  {
    foreign_key: "CJ",
    id: "{{adset_id.id}}",
    name: "{{adset.name}}",
  },
  {
    foreign_key: "CP",
    id: "{{campaign.id}}",
    name: "{{campaign.name}}",
  },
  {
    foreign_key: "BM",
    id: "",
    name: "",
  },
  {
    foreign_key: "CA",
    id: "",
    name: "",
  },
  {
    foreign_key: "PF",
    id: "",
    name: "",
  },
  {
    foreign_key: "SRC",
    id: "facebook",
    name: "facebook",
  },
];
