import React from "react";
import { Redirect } from "react-router-dom";

import { metricsArray } from "../common/data/metrics";

// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import CheckVerificationCode from "../pages/Authentication/CheckVerificationCode";

// Dashboard
import DashboardCreate from "../pages/Dashboard/dashboard.create";
import Dashboards from "../pages/Dashboard/Dashboards";
import Dashboard from "../pages/Dashboard/index";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";

// Forms
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormElements from "../pages/Forms/FormElement";

//Pages
import Profile from "../pages/Profile/Profile";
import PagesComingsoon from "../pages/Utility/Comingsoon";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import Maintenance from "../pages/Utility/Maintenance";
import PagesStarter from "../pages/Utility/pages-starter";
import Pricing from "../pages/Utility/Pricing";

//Facebook Ads
import ConnectFacebookAdsAcount from "../pages/Facebook/FacebookAds.connect";
import DebugFacebookAdsToken from "../pages/Facebook/DebugToken";

//Home
import BrandCreate from "../pages/Brands/Brand.create";
import BrandDetails from "../pages/Brands/Brand.details";

//Reports

//Support
import Support from "../pages/Support";

//Layouts
import Layouts from "../pages/Layouts";

//Builder
import Builder from "../pages/Builder";

//Reports V 2.0
import ReportV2 from "../pages/Reports/ReportsV2";

//Home
import HomePage from "../pages/Home";

//Admin
import Admin from "../pages/Admin";

//Welcome Page
import GrantPermissions from "../pages/Admin/GrantPermissions";
import Unauthorized from "../pages/Authentication/Unauthorized";
import WelcomePage from "../pages/Authentication/Welcome";

//Onboarding
import ResetPassword from "../pages/Authentication/ResetPassword";
import Onboarding from "../pages/Onboarding/Onboarding";

//Payment
import Payment from "../pages/Payment";

//Banned
import BannedAccount from "../pages/Authentication/BannedAccount";

//Google Ads
import ExternalConnect from "../pages/GoogleAds/ExternalConnect";
import ConnectGoogleAds from "../pages/GoogleAds/GoogleAds.connect";
import GoogleAuthorization from "../pages/GoogleAds/GoogleAuthorization";
import DebugGoogleAdsToken from "../pages/GoogleAds/DebugToken";

//Testing pages
import MetricsListMaker from "../pages/Tests";

//React Flow
import Connections from "../pages/Connections";
import Flow from "../pages/Flow";
import Kanban from "../pages/Tests/Kanban";

//Stripe
import Panel from "../pages/Admin/Panel";
import UsersPage from "../pages/Admin/Users";
import PaymentSuccess from "../pages/Stripe/PaymentSuccess";
import Plans from "../pages/Stripe/Plans";

//Gateways
import GeneralSalesPage from "../pages/Gateways/index";
import GatewaysConnect from "../pages/Gateways/Gateways.connect";
import SalesPanel from "../pages/Gateways/SalesPanel";
import UTMGenerator from "../pages/Gateways/UTMGenerator";

//Tools
import RedirectLinkGenerator from "../pages/Tools/RedirectLinkGenerator";
//API
import MetritoAPI from "../pages/MetritoAPI";

import DevelopersPage from "../pages/Developers";

//Metrito AI
import MetritoAI from "../pages/AI";
import ImageVerification from "../pages/AI/ImageVerification";
import ScribeAI from "../pages/AI/Scribe";
import SpeechyAI from "../pages/AI/Speechy";
import CreateWAMessages from "../pages/Admin/CreateWAMessages";

const userRoutes = [
  //Home
  { path: "/home", component: HomePage },
  //brands
  // { path: "/brands", component: Brands },
  { path: "/brands/create/", component: BrandCreate },
  { path: "/brands/:brandId", component: BrandDetails },

  //dashboard
  { path: "/dashboard/:brandId", component: Dashboard },
  { path: "/dashboards/create", component: DashboardCreate },
  { path: "/dashboards", component: Dashboards },

  //Support
  { path: "/support", component: Support },

  //Layouts
  { path: "/layouts", component: Layouts },

  //Charts
  { path: "/charts", component: ChartApex },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  // { path: "/pages-preloader", component: Preloader },
  { path: "/profile", component: Profile },
  // { path: "/pages-invoice", component: Invoice },
  // { path: "/pages-timeline", component: Timeline },

  { path: "/pricing", component: Pricing },

  //Metrito API
  { path: "/enterprise/api/tokens", component: MetritoAPI },

  //Connections
  { path: "/connections", component: Connections },

  //Facebook Ads
  { path: "/facebook/connect", component: ConnectFacebookAdsAcount },
  { path: "/facebook/debug-token", component: DebugFacebookAdsToken },

  //Google Ads
  { path: "/googleads/connect", component: ConnectGoogleAds },
  { path: "/googleads/debug-token", component: DebugGoogleAdsToken },

  //Test
  // { path: "/builder", component: LayoutBuilder },
  { path: "/builder/v2/:dashboardId", component: Builder },

  //Admin
  { path: "/admin", component: Admin },
  { path: "/admin/users", component: UsersPage },
  { path: "/admin/permissions", component: GrantPermissions },
  { path: "/admin/panel", component: Panel },
  { path: "/admin/crm/tools/messages", component: CreateWAMessages },

  //Onboarding
  { path: "/onboarding", component: Onboarding },

  //Payment
  { path: "/checkout", component: Payment },
  { path: "/plans", component: Plans },

  //Banned
  { path: "/account-problem", component: BannedAccount },

  //Metrics List Maker
  { path: "/metrics", component: MetricsListMaker },

  //Kanban
  { path: "/kanban", component: Kanban },

  //Gateways
  { path: "/gateways/connect", component: GatewaysConnect },
  { path: "/gateways/select", component: GeneralSalesPage },
  { path: "/gateways/panel/:brandId", component: SalesPanel },
  { path: "/gateways/utm-generator", component: UTMGenerator },

  //Tools
  { path: "/tools/redirect-link-generator", component: RedirectLinkGenerator },

  //AI
  { path: "/enterprise/metrito-ai", component: MetritoAI },
  { path: "/enterprise/metrito-ai/scribe", component: ScribeAI },
  {
    path: "/enterprise/metrito-ai/vision",
    component: ImageVerification,
  },
  { path: "/enterprise/metrito-ai/speechy", component: SpeechyAI },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },

  // 404 not found redirect
  { path: "*", exact: true, component: () => <Redirect to="/404" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:_id/:token", component: ResetPassword },
  { path: "/register", component: Register },
  { path: "/welcome", component: WelcomePage },
  { path: "/auth/phone-verification", component: CheckVerificationCode },

  { path: "/maintenance", component: Maintenance },
  { path: "/coming-soon", component: PagesComingsoon },
  { path: "/404", component: Error404 },
  { path: "/500", component: Error500 },
  { path: "/unauthorized", component: Unauthorized },

  //Flow
  { path: "/flow", component: Flow },

  //Developers
  { path: "/developers/docs", component: DevelopersPage },

  //Testing
  // { path: "/new-table", component: Table },
  // { path: "/stripe", component: Stripe },
  { path: "/successful-payment", component: PaymentSuccess },

  //Google Authorization
  { path: "/googleads/authorization", component: GoogleAuthorization },

  //Relatório
  // { path: "/report/:brandId/:dashboardId", component: Report },
  { path: "/report/v2/:brandId/:dashboardId", component: ReportV2 },

  {
    path: "/external-connection/:dataSource/:userId/:brandId/:token",
    component: ExternalConnect,
  },

  // Metrics route
  {
    path: "/metrics-list",
    component: () => <pre>{JSON.stringify(metricsArray, null, 2)}</pre>,
  },
];

export { userRoutes, authRoutes };
