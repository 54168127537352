import { toast } from "react-toastify";

export const copyToClipboard = (id, value) => {
  if (value) {
    navigator.clipboard.writeText(value);
  } else {
    navigator.clipboard.writeText(document.getElementById(id).innerText);
  }
  toast.success("Copiado para a área de trabalho");
};
