import classNames from "classnames";
import $ from "jquery";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Modal, Row } from "reactstrap";
import CustomAlert from "../../components/CustomAlert";
import api from "../../services/api";
import { copyToClipboard } from "../../functions/copyToClipboard";
import { getImageURL } from "../../functions/getImageURL";
import { getIdUsuario } from "../../services/auth";
import { Button } from "rsuite";

const UserModal = ({ selectedUser, setSelectedUser }) => {
  const [users, setUsers] = useState([]);
  const [ajax_div, setajax_div] = useState(false);
  const emailtxt = "";

  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_msg, setsuccess_msg] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [typedEmail, setTypedEmail] = useState("");
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [checkingCode, setCheckingCode] = useState(false);

  const [brands, setBrands] = useState([]);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  moment.locale("pt_BR");

  async function getUsers() {
    await api
      .get("/api/usuarios")
      .then((res) => {
        console.log(res.data);
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkDeleteEmail(userID, userEmail, typedEmail) {
    if (userEmail === typedEmail) {
      $("#email_validation").css("border", "2px solid green");

      setCheckedEmail(true);
      return true;
    } else {
      setCheckedEmail(false);
      return false;
    }
  }

  async function fetchBrandsData(id) {
    try {
      const response = await api.get("/api/v1/brands?user_id=" + id);

      if (response.data != 0) {
        setBrands(response.data);
      } else {
        console.log("Sem Empresas para exibir!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  function changeBanStatus(ban_status) {
    api
      .put("/api/usuarios/", {
        _id: selectedUser._id,
        is_banned: ban_status,
      })
      .then((res) => {
        setSelectedUser(res.data);
        getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function verifyPhoneNumber() {
    setCheckingCode(true);

    api
      .get(
        `/api/v1/user/verification-code.send/${selectedUser._id}?send_message=false`
      )
      .then((response1) => {
        console.log("Verification code sent");
        toast.success("Código de verificação enviado com sucesso!");

        api
          .post(`/api/v1/user/verification-code.check/${selectedUser._id}`, {
            code: response1.data.code,
          })
          .then((response2) => {
            console.log(response2.data);

            const newState = {
              ...selectedUser,
              phone_verification: {
                verified: true,
              },
            };

            setSelectedUser(newState);

            toast.success("Código verificado com sucesso!");
          })
          .catch((error) => {
            console.log(error.response);
            toast.error(error.response?.data?.message);
          })
          .finally(() => {
            setCheckingCode(false);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (selectedUser !== undefined && Object.keys(selectedUser).length > 0) {
      fetchBrandsData(selectedUser._id);
    }
  }, [selectedUser]);

  return (
    <Modal
      size="md"
      isOpen={selectedUser._id ? true : false}
      toggle={() => {
        setSelectedUser({});
      }}
      className="modal-dialog-centered"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Detalhes do usuário
        </h5>
        <button
          onClick={() => {
            setSelectedUser({});
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {selectedUser.is_banned ? (
          <Col
            lg={12}
            className="text-center mb-3 py-2"
            style={{ border: "2px dashed red", borderRadius: 10 }}
          >
            <b
              className="fs-5"
              style={{
                color: "red",
              }}
            >
              <i className="mdi mdi-lock-alert me-2" />
              Usuário Banido
            </b>
          </Col>
        ) : null}
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <h6 className="text-primary m-0">Dados Gerais</h6>
                  <div>
                    <button
                      className="btn btn-light mx-1 px-3"
                      style={{ height: 30, padding: 3, fontSize: 12 }}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(selectedUser._id)
                          .then(() => {
                            toast.success("ID do usuário copiado!");
                          })
                          .catch(() => {
                            toast.error("Erro ao copiar!");
                          });
                      }}
                    >
                      <i className="mdi mdi-content-copy me-2"></i>
                      Copiar ID
                    </button>
                    <button
                      className="btn btn-success mx-1 px-3"
                      style={{ height: 30, padding: 3, fontSize: 12 }}
                      onClick={() => {
                        window.open(
                          `https://wa.me/55${selectedUser.tel_number.replace(
                            /[^0-9]/g,
                            ""
                          )}`
                        );
                      }}
                    >
                      <i className="mdi mdi-whatsapp me-2"></i>
                      WhatsApp
                    </button>
                  </div>
                </div>

                {selectedUser?.account_limits ? (
                  <>
                    <hr className="divider" />
                    <div className="d-flex flex-row justify-content-between my-1 mb-2">
                      <b>Foto de perfil:</b>
                      <img
                        className="avatar-sm rounded-circle"
                        src={getImageURL(
                          selectedUser.profile_pic || "profile_placeholder.png"
                        )}
                        width="40"
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-between my-1">
                      <b>Nome:</b>
                      <span className="ms-3">{selectedUser.name}</span>
                    </div>
                    <div className="d-flex flex-row justify-content-between my-1">
                      <b>Email:</b>
                      <span
                        className="ms-3 copy-to-clipboard"
                        onClick={() =>
                          copyToClipboard(null, selectedUser.email)
                        }
                      >
                        {selectedUser.email}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between my-1">
                      <b>Telefone:</b>
                      <span
                        className="ms-3 copy-to-clipboard"
                        onClick={() =>
                          copyToClipboard(null, selectedUser.tel_number)
                        }
                      >
                        {selectedUser.tel_number}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between my-1">
                      <b>Data de cadastro:</b>
                      <span className="ms-3">
                        {new Date(selectedUser.createdAt).toLocaleDateString(
                          "pt-BR"
                        )}
                      </span>
                    </div>
                    <hr className="divider" />
                    <div className="d-flex flex-row justify-content-between my-1">
                      <b>Uso de Contas de Anúncio:</b>
                      <span className="ms-3">
                        {brands.flatMap((brand) => brand.ad_accounts).length}/
                        {selectedUser.account_limits.ad_accounts === 10000
                          ? "Ilimitado"
                          : selectedUser.trial
                          ? "Trial"
                          : selectedUser.account_limits.ad_accounts || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between my-1">
                      <b>Uso de Empresas:</b>
                      <span className="ms-3">
                        {brands.length} /{" "}
                        {selectedUser.account_limits.brands === 10000
                          ? "Ilimitado"
                          : selectedUser.account_limits.brands}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between my-1">
                      <b>Uso de Dashboards:</b>
                      <span className="ms-3">
                        {selectedUser.account_limits.dashboards === 10000
                          ? "Ilimitado"
                          : selectedUser.account_limits.dashboards}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between my-1">
                      <b>Uso de Relatórios:</b>
                      <span className="ms-3">
                        {selectedUser.account_limits.reports === 10000
                          ? "Ilimitado"
                          : selectedUser.account_limits.reports}
                      </span>
                    </div>
                  </>
                ) : null}
              </CardBody>
            </Card>
            {selectedUser?.subscription ? (
              <Card>
                <CardBody>
                  <h6 className="text-primary">Assinatura</h6>
                  <hr className="divider" />
                  <div className="d-flex flex-row justify-content-between my-1">
                    <b>Status:</b>
                    <b
                      className={classNames(
                        {
                          "text-success":
                            selectedUser.subscription.status === "ok",
                          "text-danger":
                            selectedUser.subscription.status === "error",
                        },
                        "ms-3"
                      )}
                    >
                      {selectedUser.subscription.status.toUpperCase()}
                    </b>
                  </div>
                  <div className="d-flex flex-row justify-content-between my-1">
                    <b>Plano:</b>
                    <span className="ms-3">
                      {selectedUser.subscription.items &&
                        selectedUser.subscription.items[0].plan.nickname}
                    </span>
                  </div>
                  <div className="d-flex flex-row justify-content-between my-1">
                    <b>Contas Extras:</b>
                    <span className="ms-3">
                      <i className="bx bxl-facebook-circle text-primary me-2"></i>
                      {selectedUser.subscription.extra_ad_accounts_qty}
                    </span>
                  </div>

                  <hr className="divider" />

                  <div className="d-flex flex-row justify-content-between my-1">
                    <b>Último Pagamento:</b>
                    <span className="ms-3">
                      {moment(
                        selectedUser.subscription.current_period_start * 1000
                      ).format("DD/MM/YYYY") || "stripe"}
                      <span
                        className="text-muted ms-1"
                        style={{ fontSize: 12 }}
                      >
                        (
                        {moment(
                          selectedUser.subscription.current_period_start * 1000
                        ).fromNow() || "stripe"}
                        )
                      </span>
                    </span>
                  </div>

                  <div className="d-flex flex-row justify-content-between my-1">
                    <b>Próximo Pagamento:</b>
                    <span className="ms-3">
                      {moment(
                        selectedUser.subscription.current_period_end * 1000
                      ).format("DD/MM/YYYY") || "stripe"}
                      <span
                        className="text-muted ms-1"
                        style={{ fontSize: 12 }}
                      >
                        (
                        {moment(
                          selectedUser.subscription.current_period_end * 1000
                        ).fromNow()}
                        )
                      </span>
                    </span>
                  </div>

                  <hr className="divider" />
                  <div className="d-flex flex-row justify-content-between my-1">
                    <b>Valor Total:</b>
                    <span className="ms-3">
                      {selectedUser.subscription.full_amount?.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        }
                      ) || "stripe"}
                    </span>
                  </div>
                </CardBody>
              </Card>
            ) : null}

            <Card>
              <CardBody>
                <h6 className="text-primary">Verificação de Telefone</h6>
                <hr className="divider" />
                <div className="d-flex flex-row justify-content-between my-1">
                  <b>Status:</b>
                  <b
                    className={classNames(
                      {
                        "text-success":
                          selectedUser.phone_verification?.verified,
                        "text-danger":
                          !selectedUser.phone_verification?.verified,
                      },
                      "ms-3"
                    )}
                  >
                    {selectedUser.phone_verification?.verified
                      ? "Verificado"
                      : "Não verificado"}
                  </b>
                </div>
                <div className="d-flex flex-row justify-content-between my-1">
                  <b>Código:</b>
                  <span
                    className={classNames(
                      { "text-muted": !selectedUser.phone_verification },
                      "ms-3"
                    )}
                  >
                    {selectedUser.phone_verification?.code || "-"}
                  </span>
                </div>
                <div className="d-flex flex-row justify-content-between my-1">
                  <b>Telefone:</b>
                  <span className="ms-3">{selectedUser.tel_number}</span>
                </div>

                {!selectedUser.phone_verification ||
                !selectedUser.phone_verification?.verified ? (
                  <div className="w-100 mt-3">
                    <Button
                      className="w-100"
                      appearance="default"
                      loading={checkingCode}
                      disabled={checkingCode}
                      onClick={() => {
                        verifyPhoneNumber();
                      }}
                    >
                      Verificar Telefone Manualmente
                    </Button>
                  </div>
                ) : null}
              </CardBody>
            </Card>

            <Row>
              <Col lg={6}>
                <button
                  className="btn w-100 my-2"
                  style={{
                    border: "2px solid red",
                    backgroundColor: "black",
                    color: "white",
                  }}
                  onClick={() => {
                    setajax_div(true);
                  }}
                >
                  <i className="mdi mdi-trash-can me-2"></i>
                  Remover Usuário
                </button>
              </Col>
              <Col lg={6}>
                {!selectedUser.is_banned ? (
                  <button
                    className="btn w-100 my-2"
                    style={{
                      border: "2px solid red",
                      backgroundColor: "#e20220",
                      color: "white",
                    }}
                    onClick={() => {
                      changeBanStatus(!selectedUser.is_banned);
                      console.log(
                        `Actual ban status is: ${
                          selectedUser.is_banned
                        } - changing to ${!selectedUser.is_banned}`
                      );
                    }}
                  >
                    <i className="mdi mdi-lock me-2"></i>
                    Banir Usuário
                  </button>
                ) : (
                  <button
                    className="btn btn-primary w-100 my-2"
                    onClick={() => {
                      changeBanStatus(!selectedUser.is_banned);
                      console.log(
                        `Actual ban status is: ${
                          selectedUser.is_banned
                        } - changing to ${!selectedUser.is_banned}`
                      );
                    }}
                  >
                    <i className="mdi mdi-lock-open-variant me-2"></i>
                    Desbanir Usuário
                  </button>
                )}
              </Col>
            </Row>

            {ajax_div ? (
              <CustomAlert
                showCancel
                title={
                  <span>
                    Você está prestes a <br /> REMOVER UM USUÁRIO
                  </span>
                }
                cancelBtnBsStyle="danger"
                confirmBtnBsStyle="success"
                onConfirm={() => {
                  if (checkedEmail) {
                    api
                      .delete(`/api/usuarios/${selectedUser._id}`)
                      .then((res) => {
                        setajax_div(false);
                        setsuccess_dlg(true);
                        setdynamic_title("Usuário removido!");
                        setdynamic_description(
                          `Usuário ${selectedUser.name} removido com sucesso.`
                        );
                        getUsers();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                }}
                onCancel={() => {
                  setajax_div(false);
                }}
              >
                <span className="mb-2">
                  Digite o E-mail{" "}
                  <b style={{ fontWeigth: 800, color: "red" }}>
                    {selectedUser.email}
                  </b>{" "}
                  para confirmar a remoção do usuário.
                </span>

                <input
                  id="email_validation"
                  type="email"
                  className="form-control"
                  placeholder="Atenção! Esta operação é irreversível!"
                  onChange={(e) =>
                    checkDeleteEmail(
                      selectedUser._id,
                      selectedUser.email,
                      e.target.value
                    )
                  }
                />
              </CustomAlert>
            ) : null}
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default UserModal;
