import React, { useState } from "react";
import { Card, CardBody, Col, Modal, Row } from "reactstrap";
import Preloader from "../Utility/Preloader";

const PlansModal = (props) => {
  const [modal_xlarge, setmodal_xlarge] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  return (
    <>
      <button
        className="btn btn-light fs-6 w-100"
        type="button"
        data-toggle="modal"
        data-target=".bs-example-modal-xl"
        onClick={() => {
          tog_xlarge();
        }}
        disabled={props.loading}
      >
        selecionar outro plano
      </button>

      <Modal
        size="xl"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_xlarge();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Planos Metrito
          </h5>
          <button
            onClick={() => {
              setmodal_xlarge(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>Escolha um plano para fazer upgrade</p>
          {/* <div>
            {props.plans.map((plan) => (
              <ul>
                {plan.features.map((feat) => (
                  <li>{feat.label}</li>
                ))}
              </ul>
            ))}
          </div> */}

          {props.user && props.plans ? (
            <Row className="d-flex flex-row">
              {props?.plans?.map((plan, key) => (
                <Col lg={4} key={key}>
                  <Card
                    style={{
                      minHeight: 450,
                    }}
                    className={`${
                      plan.name === props.selectedPlan.name
                        ? "shadow-lg"
                        : "shadow-sm"
                    }`}
                  >
                    <CardBody>
                      <h5 className="text-center">{plan.name}</h5>
                      <div className="w-100 d-flex justify-content-center text-center">
                        <img
                          src={plan.icon_url}
                          alt="plan"
                          style={{ maxHeight: 70 }}
                        />
                      </div>
                      <h5
                        className={`text-${
                          plan.name === "Plano Black Diamond"
                            ? "dark"
                            : "success"
                        } text-center mt-3 mb-0`}
                      >
                        {plan.price.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2,
                        })}
                      </h5>
                      <div className="w-100 text-center">
                        <span className="text-muted" style={{ fontSize: 12 }}>
                          por mês
                        </span>
                      </div>

                      <hr className="divider" />
                      <div className="d-flex flex-column mx-3">
                        <span>
                          <i className="bx bxl-facebook-circle text-primary me-2" />
                          {`${plan.ad_accounts} contas de anúncio`}
                        </span>
                        {plan?.features?.map((feature, key) => (
                          <span key={key}>
                            <i className="bx bxs-check-circle text-success me-2 mt-1" />
                            {feature.label}
                          </span>
                        ))}

                        {plan.gateway_product_id ===
                        props.user.subscription?.plan.sku ? (
                          <Card
                            className="text-center p-1 shadow-sm"
                            style={{
                              position: "absolute",
                              marginBottom: 0,
                              bottom: 15,
                              right: 15,
                              left: 15,
                            }}
                          >
                            <b className="text-dark my-1">Plano Contratado</b>
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                (window.location.href = `/checkout?type=adaccounts&extra_accounts=${10}`)
                              }
                            >
                              <b>
                                Adicionar Contas Extras{" "}
                                <i className="bx bx-right-arrow-alt align-middle fs-4" />
                              </b>
                            </button>
                          </Card>
                        ) : plan.name !== props.selectedPlan?.name ? (
                          <button
                            className={`btn btn-success fs-6 mt-4`}
                            style={{
                              position: "absolute",
                              bottom: 15,
                              right: 15,
                              left: 15,
                            }}
                            onClick={() => {
                              props.setSelectedPlan(plan);
                            }}
                          >
                            <b className={`fs-6 mt-4`}>Contratar</b>
                          </button>
                        ) : (
                          <button
                            className={`btn btn-light fs-6 mt-4`}
                            style={{
                              position: "absolute",
                              bottom: 15,
                              right: 15,
                              left: 15,
                            }}
                          >
                            <b className={`fs-6 mt-4`}>Plano Selecionado</b>
                          </button>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Preloader />
          )}
        </div>
      </Modal>
    </>
  );
};

export default PlansModal;
