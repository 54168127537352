import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setUser, logoutUser, setLoadingUser } from "../../store/user/actions";
import api from "../../services/api";
import { login, logout, getToken, getIdUsuario } from "../../services/auth";
import { Row, Col, Container } from "reactstrap";
import moment from "moment";
import ReduceAdAccountsModal from "../../pages/Facebook/ReduceAdAccounts.modal";
import GoogleAdsUpdatePopup from "../../components/Popups/GoogleAdsUpdatejs";
import TermsOfServicePopup from "../../components/Popups/TermsOfService";
import $ from "jquery";
import { checkBrandingPack } from "../../functions/checkBrandingPack";
import PastDuePaymentBar from "../../components/Popups/PastDuePaymentBar";
import FloatingTrialRemaining from "../../components/FloatingTrialRemaining";
import { getRemainingDays } from "../../functions/getRemainingDays";
import { detectMobileBrowser } from "../../functions/detectMobileBrowser";

function Authmiddleware(props) {
  const {
    component: Component,
    layout: Layout,
    isAuthProtected,
    user,
    ...rest
  } = props;

  const [trial, setTrial] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState(false);
  const [limits, setLimits] = useState({});

  const [openModal, setOpenModal] = useState(true);

  const history = useHistory();

  function checkAllowedTrialPage() {
    if (
      /auth/.test(window.location.href) ||
      /successful-payment/.test(window.location.href) ||
      /onboarding/.test(window.location.href) ||
      /plans/.test(window.location.href) ||
      /checkout/.test(window.location.href) ||
      /profile/.test(window.location.href) ||
      /support/.test(window.location.href) ||
      /login/.test(window.location.href) ||
      /register/.test(window.location.href) ||
      /404/.test(window.location.href)
    ) {
      return false;
    } else {
      return true;
    }
  }

  async function isAdmin() {
    console.log("Verificando permissões...");
    setLoading(true);
    const result = await api
      .get("/api/adminverify/" + getIdUsuario())
      .then((res) => {
        if (res.data.admin === true) {
          console.log("ADMIN OK");
          setAdmin(true);
          setLoading(false);
          return true;
        } else if (
          res.data.admin == false &&
          /admin/.test(window.location.href)
        ) {
          console.log("NOT ADMIN");
          history.push("/unauthorized");
          setAdmin(false);
          setLoading(false);
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function verify() {
    setLoading(true);

    let res = await api.get("/api/usuarios/checktoken", {
      params: { token: getToken() },
    });

    let trial_verification = await api
      .get("/api/usuarios/checktrial/" + getIdUsuario())
      .then((res) => {
        setTrial(res.data.trial);
        if (
          moment(res.data.trial_expires_at) < new Date() &&
          !/plans/.test(window.location.href) &&
          checkAllowedTrialPage()
        ) {
          console.log("TRIAL EXPIRADO");
          history.push("/plans");
        }
        window.localStorage.setItem("trial", res.data.trial);
        window.localStorage.setItem(
          "trial_expires_at",
          res.data.trial_expires_at
        );
      });

    if (res.data.status === 200) {
      setRedirect(false);
      if (window.location.pathname === "/login") {
        window.location.href = "/home";
      }
    } else {
      logout(); //clear localstorage
      logoutUser(); //clear redux store
      setRedirect(true);
    }
  }

  async function checkLimits() {
    try {
      const limits_check = await api.get(
        "/api/usuarios/check-limits/" + getIdUsuario()
      );

      setLimits(limits_check.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function updateUserData() {
    setLoading(true);
    props.setLoadingUser(true);
    api
      .put("/api/usuarios/", {
        _id: getIdUsuario(),
        last_active_at: new Date().toISOString(),
      })
      .then((response) => {
        props.setUser(response.data);

        window.localStorage.setItem("&id-usuario", response.data._id);
        window.localStorage.setItem("&email-usuario", response.data.email);
        window.localStorage.setItem("&nome-usuario", response.data.name);
        window.localStorage.setItem(
          "&user-created-at",
          response.data.createdAt
        );
        window.localStorage.setItem(
          "&profile-pic-id",
          response.data.profile_pic
        );
        // if (response.data.branding_pack) {
        //   window.localStorage.setItem(
        //     "branding_pack",
        //     response.data.branding_pack
        //   );
        //   checkBrandingPack(response.data.branding_pack);
        // } else {
        //   window.localStorage.removeItem("branding_pack");
        // }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        props.setLoadingUser(false);
      });
  }

  useEffect(() => {
    if (/admin/.test(window.location.href)) {
      isAdmin();
    }

    if (
      !/external/.test(window.location.href) &&
      !/account-problem/.test(window.location.href)
    ) {
      updateUserData();
    }

    if (/account-problem/.test(window.location.href)) {
      window.localStorage.setItem("branding_pack", "SUSPENDED_ACCOUNT");
    }

    verify();
    checkLimits();
    checkBrandingPack(window.localStorage.getItem("branding_pack"));
  }, []);

  useEffect(() => {
    if (!user) return;

    if (user.branding_pack) {
      window.localStorage.setItem("branding_pack", user.branding_pack);
    } else {
      window.localStorage.removeItem("branding_pack");
    }
  }, [user]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          isAuthProtected &&
          localStorage.getItem("&app-token") &&
          Object.keys(user).length &&
          (user.is_banned === true ||
            (user.subscription && user.subscription.status === "canceled"))
        ) {
          return (
            <Redirect
              to={{
                pathname: "/account-problem",
                state: { from: props.location },
              }}
            />
          );
        } else if (
          isAuthProtected &&
          localStorage.getItem("&id-usuario") &&
          Object.keys(user).length > 0 &&
          (!user.phone_verification ||
            user.phone_verification.verified === false)
        ) {
          return (
            <Redirect
              to={{
                pathname: "/auth/phone-verification",
                state: { from: props.location },
              }}
            />
          );
        } else {
          if (
            loading &&
            /admin/.test(window.location.href) &&
            localStorage.getItem("&app-token")
          ) {
            return (
              <div className="my-5 pt-sm-5">
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className="text-center">
                        <div>
                          <Row className="justify-content-center">
                            <div>
                              <div
                                className="spinner-border text-primary m-1"
                                role="status"
                              ></div>
                            </div>
                            <span className="">Verificando permissões...</span>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          } else if (isAuthProtected && !localStorage.getItem("&app-token")) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          } else {
            return (
              <>
                {checkAllowedTrialPage() &&
                  user.has_accepted_terms_of_service === false && (
                    <TermsOfServicePopup
                      open={!user.has_accepted_terms_of_service}
                    />
                  )}

                {user.role < 100 &&
                  limits.ad_accounts === false &&
                  checkAllowedTrialPage() &&
                  trial === false &&
                  new URL(window.location.href).searchParams.get(
                    "onboarding"
                  ) !== "true" && (
                    <ReduceAdAccountsModal
                      visible={openModal}
                      setVisible={setOpenModal}
                    />
                  )}

                {user.role < 100 &&
                  user.subscription &&
                  user.subscription.status === "past_due" && (
                    <PastDuePaymentBar subscription={user.subscription} />
                  )}

                {user.trial && detectMobileBrowser() && (
                  <FloatingTrialRemaining
                    trialExpiringDate={user.trial_expires_at}
                    trialData={getRemainingDays(
                      user.createdAt,
                      user.trial_expires_at,
                      moment().format("YYYY-MM-DD, HH:mm:ss")
                    )}
                  />
                )}

                <Layout>
                  <Component {...props} />
                </Layout>
              </>
            );
          }
        }
      }}
    />
  );

  Authmiddleware.propTypes = {
    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any,
  };
}

const mapStateToProps = (store) => ({
  user: store.User.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoadingUser: (loading) => dispatch(setLoadingUser(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authmiddleware);
