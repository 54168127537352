import React from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
//Import Breadcrumb
import Dashboard from "../Dashboard";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { changeLayoutMode } from "../../store/actions";

import { useEffect, useState } from "react";
import Switch from "react-switch";
import logoLight from "../../assets/images/logo-light.png";
import logoSmLight from "../../assets/images/logo-sm-light.png";
import logoSm from "../../assets/images/logo-sm.svg";

const ReportsV2 = (props) => {
  const [switch1, setswitch1] = useState(true);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function changeLayout() {
    if (switch1) {
      props.changeLayoutMode("dark");
      window.localStorage.setItem("theme", "dark");
    } else {
      props.changeLayoutMode("light");
      window.localStorage.setItem("theme", "light");
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        <i className="bx bxs-moon"></i>
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#000",
          paddingRight: 2,
        }}
      >
        {" "}
        <i className="bx bxs-sun fs-6"></i>
      </div>
    );
  };

  useEffect(() => {
    setswitch1(window.localStorage.getItem("theme") === "dark" ? false : true);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports V2 | Metrito</title>
        </MetaTags>

        {/* Render Dashboard */}
        <header
          id="page-topbar"
          style={{
            width: "100%",
            backgroundColor: "#1e55eb",
          }}
          className="shadow-sm"
        >
          <div
            className="navbar-header px-5"
            style={{ height: 47, padding: 5 }}
          >
            <div>
              <Link to={window.location.pathname} className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    className="report-logo"
                    src={logoLight}
                    alt=""
                    height="25"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    className="report-logo"
                    src={logoLight}
                    alt=""
                    height="25"
                  />
                </span>
              </Link>

              <Link to={window.location.pathname} className="logo logo-light">
                <span className="logo-sm">
                  <img
                    className="report-logo"
                    src={logoSmLight}
                    alt=""
                    height="25"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    className="report-logo"
                    src={logoLight}
                    alt=""
                    height="25"
                  />
                </span>
              </Link>
            </div>

            <div className="d-flex align-items-center">
              <i
                className="bx bx-fullscreen text-white mx-4 d-none d-lg-inline-block"
                style={{ fontSize: 22, marginBottom: 2 }}
                data-toggle="fullscreen"
                onClick={() => {
                  toggleFullscreen();
                }}
              />

              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mx-2"
                onColor="#f8f9fa"
                offColor="#0F0F3E"
                onChange={() => {
                  setswitch1(!switch1);
                  changeLayout();
                }}
                checked={switch1}
              />
            </div>
          </div>
        </header>

        <Container fluid>
          <Dashboard className="report-header" />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeLayoutMode: (layoutMode) => dispatch(changeLayoutMode(layoutMode)),
});

export default connect(null, mapDispatchToProps)(ReportsV2);
