import React, { useEffect, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { defineMetric } from "../functions/metricFormatter";

const CardWidget = (props) => {
  const [metrics, setMetrics] = useState([]);
  const [comparison, setComparison] = useState({});

  useEffect(() => {
    setMetrics(defineMetric(props.metrics, props.widget.type));
  }, [props.metrics]);
  useEffect(() => {
    setComparison(calcPercentage(metrics[0], metrics[1]));
  }, [metrics]);

  function calcPercentage(a, b, greenUp) {
    var math = ((a - b) / b) * 100;
    var arrw = math > 0 ? true : math < 0 ? false : undefined;
    var clr;
    if (props.widget.type.includes("$")) {
      clr = math < 0 ? "success" : math > 0 ? "danger" : "dark";
    } else {
      clr = math < 0 ? "danger" : math > 0 ? "success" : "dark";
    }

    var result = {
      value: Math.abs(math.toFixed(1)) || 0,
      color: clr,
      arrow: arrw,
    };
    return result;
  }

  const test = true;
  return (
    <React.Fragment>
      <Col md={3} xl={3}>
        <Card className="card-widget">
          <CardBody>
            {/* <div className="float-end">
                    <div className="avatar-sm mx-auto mb-4">
                      <span className="avatar-title rounded-circle bg-light font-size-24">
                        <i className={widget.icon}></i>
                      </span>
                    </div>
                  </div> */}
            <div>
              <p className="text-muted text-uppercase fw-semibold truncate">
                {props.widget.title}
              </p>
              <h4 className="mb-1 mt-1">
                {props.widget.type.includes("$") ? (
                  <span className="counter-value">
                    {defineMetric(
                      [props.summary],
                      props.widget.type
                    )[0].toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                ) : props.widget.type.includes("%") ? (
                  <span className="counter-value">
                    {defineMetric(
                      [props.summary],
                      props.widget.type
                    )[0].toFixed(2)}
                    %
                  </span>
                ) : (
                  <span className="counter-value">
                    {defineMetric([props.summary], props.widget.type)[0]
                      .toLocaleString("pt-BR")
                      .replace(/(\.0)$/, "")}
                  </span>
                )}
              </h4>
            </div>

            <p className="text-muted mt-3 mb-0">
              <span
                className={"badge badge-soft-" + comparison.color + " me-1"}
              >
                {comparison.arrow === true ? (
                  <i className="bx bxs-upvote me-1"></i>
                ) : comparison.arrow === false ? (
                  <i className="bx bxs-downvote me-1"></i>
                ) : (
                  <i className="bx bx-dots-horizontal-rounded me-1"></i>
                )}
                {comparison.value}%
              </span>
            </p>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CardWidget;
