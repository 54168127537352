import React from "react";
import { Row, Col, Alert, Container, Card, CardBody } from "reactstrap";
import MetaTags from "react-meta-tags";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//import images
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import api from "../../services/api";
import { toast } from "react-toastify";
import $ from "jquery";

const ForgetPasswordPage = (props) => {
  function fade() {
    $("#form").fadeOut(200);
    setTimeout(() => {
      $("#email-sent").fadeIn(200);
    }, 200);
  }

  function handleValidSubmit(event) {
    document.getElementById("recover-btn").className =
      "btn btn-muted w-md waves-effect waves-light";
    document.getElementById("recover-loading").style.display = "inline-block";

    const email = event.target.email.value;

    api
      .post("/api/forgot-password/", { email: email })
      .then((res) => {
        fade();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Recuperar Senha | Metrito</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="text-center mb-5">
                <Link to="/dashboard" className="auth-logo">
                  <img
                    src={logoDark}
                    alt=""
                    height="28"
                    className="auth-logo-dark"
                  />
                  <img
                    src={logoLight}
                    alt=""
                    height="28"
                    className="auth-logo-light"
                  />
                </Link>
                <p className="font-size-15 text-muted mt-3">
                  Clareza, Análise e <b>Crescimento</b>.
                </p>
              </div>
              <Row className="g-0 d-flex justify-content-center">
                <Col lg={6}>
                  <Card id="form" className="overflow-hidden">
                    <div className="p-lg-5 p-4">
                      <div>
                        <h5>Recuperar Senha</h5>
                        <p className="text-muted">
                          Recupere sua senha do Metrito.
                        </p>
                      </div>
                      <div className="mt-4 pt-3">
                        <div
                          className="alert alert-success text-center mb-4"
                          role="alert"
                        >
                          Enviaremos as instruções no seu e-mail!
                        </div>

                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e) => handleValidSubmit(e)}
                        >
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="E-mail"
                              className="form-control"
                              placeholder="Insira o E-mail"
                              type="email"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Por favor, digite seu e-mail",
                                },
                              }}
                            />
                          </div>
                          <div className="mt-4 text-end">
                            <button
                              id="recover-btn"
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Recuperar{" "}
                              <i
                                id="recover-loading"
                                className="mdi mdi-vanish mdi-spin text-primary"
                                style={{ display: "none" }}
                              ></i>
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </Card>
                  <Card id="email-sent" style={{ display: "none" }}>
                    <div className="text-center p-5" id="password-ok">
                      <CardBody>
                        <i className="bx bx-timer fs-1 text-primary mb-2"></i>
                        <h4>Te enviamos um E-mail!</h4>
                        <p>
                          Utilize o link que te enviamos para recuperar sua
                          conta, lembre-se que este link expira e deve ser usado
                          imediatamente.
                        </p>
                      </CardBody>
                    </div>
                  </Card>
                </Col>
              </Row>
              <div className="mt-5 text-center">
                <p>
                  Lembrou sua senha?{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-decoration-underline"
                  >
                    {" "}
                    Login{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} <b>Metrito</b>. Desenvolvido com
                  muito <i className="mdi mdi-heart text-danger"></i>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgetPasswordPage);
