import React, { useState } from "react";
import { Container, Card, Col, Row, CardBody, Modal, Label } from "reactstrap";
import { getImageURL } from "../../functions/getImageURL";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { getIdUsuario, setProfilePictureURL } from "../../services/auth";
import { toast } from "react-toastify";

const UploadFileModal = (props) => {
  const [modal_xlarge, setmodal_xlarge] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    // removeBodyCss();
  }

  const [image, setImage] = useState("");

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  function changeProfilePicture(image) {
    const id = toast.loading("Atualizando dados...");
    const formData = new FormData();
    formData.append("image", image);
    console.log(image);
    api
      .put(`/api/usuarios/profile-pic/${getIdUsuario()}`, formData)
      .then((res) => {
        tog_xlarge();

        //Toast success
        toast.update(id, {
          render: "Dados atualizados com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });

        setProfilePictureURL(res.data.key);

        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        //Toast error
        toast.update(id, {
          render: "Erro ao realizar operação",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
      });
  }

  return (
    <React.Fragment>
      <button
        type="button"
        onClick={() => {
          tog_xlarge();
        }}
        className={`btn btn-light w-100 mx-3`}
      >
        {props.button_text}
      </button>

      <Container>
        <Modal
          className="modal-dialog-centered modal-dialog-scrollable"
          size="md"
          isOpen={modal_xlarge}
          toggle={() => {
            tog_xlarge();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Editar
            </h5>
            <button
              onClick={() => {
                setmodal_xlarge(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body px-5">
            <div className="text-center w-100 my-3">
              {props.type === "brand" ? (
                <img
                  src={
                    !image
                      ? getImageURL(props.image_uri)
                      : URL.createObjectURL(image)
                  }
                  alt="Imagem da empresa"
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#fff",
                    height: 160,
                    width: 300,
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src={
                    !image
                      ? getImageURL(props.image_uri)
                      : URL.createObjectURL(image)
                  }
                  alt="image"
                  style={{
                    width: "20rem",
                    height: "20rem",
                    objectFit: "contain",
                    borderRadius: 10,
                    backgroundColor: "#fff",
                  }}
                />
              )}

              <div className="my-5 mx-3">
                <Label for="formFile" className="form-label">
                  Selecione um arquivo
                </Label>
                <input
                  className="form-control"
                  type="file"
                  id="profile_file"
                  accept="image/png, image/gif, image/jpeg, image/webp"
                  onChange={onImageChange}
                />
              </div>

              <div>
                <button
                  className="btn btn-primary px-5"
                  disabled={!image}
                  onClick={() => {
                    if (props.type === "brand") {
                      props.setBrandImage(image);
                      tog_xlarge();
                    } else {
                      changeProfilePicture(image);
                    }
                  }}
                >
                  Salvar Alterações
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default UploadFileModal;
