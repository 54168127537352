import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { motion } from "framer-motion";
import MetaTags from "react-meta-tags";
import { programs } from "../../common/data/partnership_programs";
import api from "../../services/api";

const ai_services = [
  {
    status: "beta",
    name: "Scribe",
    description:
      "Metrito Scribe AI é uma ferramenta que permite criar conteúdo para seu site, blog ou páginas de mídia social. Ele usa inteligência artificial para gerar conteúdo com base em suas palavras-chave e tópicos.",
    image: "https://cdn-icons-png.flaticon.com/512/4276/4276555.png",
    url: "/enterprise/metrito-ai/scribe",
  },
  {
    status: "beta",
    name: "Vision",
    description:
      "Metrito Vision AI é uma ferramenta que permite verificar a autenticidade das imagens. Ele usa inteligência artificial para verificar a autenticidade e conteúdos impróprios nas imagens.",
    image: "https://cdn-icons-png.flaticon.com/512/4773/4773887.png",
    url: "/enterprise/metrito-ai/vision",
  },
  {
    status: "closed-beta",
    programs: ["blackrat", "iagopeixoto"],
    name: "Speechy",
    description:
      "Metrito Speechy AI é uma ferramenta que permite criar áudios. Ele usa inteligência artificial para transformar textos em áudios.",
    image: "https://cdn-icons-png.flaticon.com/512/4276/4276866.png",
    url: "/enterprise/metrito-ai/speechy",
  },
  {
    status: "development",
    name: "VanGogh",
    description:
      "Metrito Vangogh AI é uma ferramenta que permite criar arte. Ele usa inteligência artificial para criar arte com base em suas palavras-chave e tópicos.",
    image: "https://cdn-icons-png.flaticon.com/512/4666/4666350.png",
  },
];

const MetritoAI = () => {
  const [user, setUser] = useState({});

  const fetchUser = async () => {
    const response = api
      .get("/api/usuarios.details/" + localStorage.getItem("&id-usuario"))
      .then((res) => {
        console.log(res.data);
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="page-content mt-5">
      <MetaTags>
        <title>Metrito AI | Versão Beta </title>
      </MetaTags>

      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <b style={{ fontSize: "3rem", fontWeight: 900 }}>
                Uma nova era chegou. <br /> Não fique pra trás.
              </b>
              <span className="text-muted text-center" style={{ maxWidth: 800 }}>
                Agora, nossos usuários poderão verificar conteúdos impróprios e perigosos em seus criativos para evitar banimentos, criar
                textos automágicamente e tem muito mais vindo!
              </span>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={12}>
            <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
              {ai_services.map((service) => (
                <motion.button
                  style={{ maxWidth: 400, position: "relative" }}
                  className="ai-service p-5 d-flex flex-column card"
                  whileHover={{
                    scale: 1.05,
                    transition: { duration: 0.1 },
                  }}
                  onClick={() => {
                    window.open(service.url);
                  }}
                  disabled={
                    service.status === "development" ||
                    (service.status === "closed-beta" && !service.programs.includes(user.enrolled_programs))
                  }
                >
                  {service.status && service.status === "development" && (
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 1,
                        borderRadius: 10,
                        backgroundColor: "rgba(255, 255, 255, 0.565)",
                      }}
                      className="d-flex align-items-end justify-content-center"
                    ></div>
                  )}
                  <img src={service.image} style={{ width: 60, height: 60, marginBottom: 10 }} />

                  <h4 className="my-3">{service.name} AI</h4>
                  {service.status && service.status === "beta" && <span className="badge bg-danger px-2 mb-2">beta</span>}
                  <small className="text-muted">{service.description}</small>

                  {service.status && service.status === "development" ? (
                    <div className="card px-5 mt-4">
                      <span className="text-dark">Em breve!</span>
                    </div>
                  ) : service.status && service.status === "closed-beta" ? (
                    <div className="px-5 mt-4">
                      <small className="text-dark">Disponível apenas para:</small>

                      <div className="pt-1">
                        {programs
                          .filter((program) => service.programs.includes(program.alias))
                          .map((program) => (
                            <div
                              className="d-flex flex-row align-items-center justify-content-center card my-2 px-4 py-2"
                              style={{ backgroundColor: program.bgColor }}
                            >
                              <div className="d-flex flex-column align-items-center">
                                <small
                                  className="m-0"
                                  style={{
                                    color: program.color,
                                  }}
                                >
                                  {program.name}
                                </small>
                                {/* <small className="m-0">{program.description}</small> */}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <span className="text-primary mt-4">
                      Ir para {service.name} <i className="mdi mdi-arrow-right" />
                    </span>
                  )}
                </motion.button>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MetritoAI;
