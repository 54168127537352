// 10 presets
export const datePresets = [
  {
    name: "Máximo",
    preset: "maximum",
  },
  {
    name: "Hoje",
    preset: "today",
  },
  {
    name: "Ontem",
    preset: "yesterday",
  },
  {
    name: "Últimos 7 dias",
    preset: "last_7d",
  },
  {
    name: "Últimos 14 dias",
    preset: "last_14d",
  },
  {
    name: "Últimos 30 dias",
    preset: "last_30d",
  },
  {
    name: "Esta semana",
    preset: "this_week_sun_today",
  },
  {
    name: "Semana passada",
    preset: "last_week_sun_sat",
  },
  {
    name: "Este mês",
    preset: "this_month",
  },
  {
    name: "Mês passado",
    preset: "last_month",
  },
];
