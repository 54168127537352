import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col } from "reactstrap";

import { defineMetric } from "../functions/metricFormatter";

import ReactApexChart from "react-apexcharts";

const LineChart = (props) => {
  const [metric, setMetric] = useState();

  const series = [
    {
      name: props.selected.title,
      data: metric || [],
    },
  ];
  var options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#3167ED", "#0ab39c"],
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 4,
      hover: {
        size: 6,
      },
    },
    labels: props.metrics.map((a) =>
      moment(a.date_stop).toLocaleString("pt-BR")
    ),
    xaxis: {
      type: "datetime",
      tickPlacement: "on",
    },
    yaxis: {
      labels: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (props.selected.type.includes("$")) {
            return y.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
              maximumFractionDigits: 2,
            });
          } else if (props.selected.type.includes("%")) {
            return y.toFixed(2) + "%";
          } else {
            return y;
          }
        },
      },
    },
  };

  useEffect(() => {
    setMetric(defineMetric(props.metrics, props.selected.type));
  }, [props.metrics]);

  return (
    <React.Fragment>
      <Col lg={6} xl={6}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.selected.title}</CardTitle>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={options.chart.height}
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LineChart;
