import axios from 'axios';
import { AvField, AvForm } from "availity-reactstrap-validation";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { Input } from "rsuite";
import { getImageURL } from "../../functions/getImageURL";

import Preloader from "../Utility/Preloader";

//Info Modal
import InfoModal from "../Connections/InfoModal";

//Services
import api from "../../services/api";
import { getIdUsuario, getToken } from "../../services/auth";

import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import $ from "jquery";
import ExternalAdAccountConnectModal from "./ExternalAdAccountConnectModal";

import NoAdAccountsLeftModal from "./NoAdAccountsLeft.modal";
import BrandPicker from "../../components/BrandPicker";
import BrandNotFound from "../Utility/BrandNotFound";
import CustomAlert from "../../components/CustomAlert";

const ConnectFacebookAdsAdAccount = (props) => {
  const appId = "764460408156536";

  const queryParams = new URLSearchParams(window.location.search);

  const [pickerValue, setPickerValue] = useState(null);
  const [regStatus, setRegStatus] = useState("");
  const [regDynamicText, setRegDynamicText] = useState("");
  const [success_msg, setsuccess_msg] = useState(false);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [adaccounts, setAdaccounts] = useState([]);
  const [adaccountsLimit, setAdaccountsLimit] = useState(0);
  const [alreadyConnectedAddacounts, setAlreadyConnectedAddacounts] = useState(
    []
  );
  const [facebookConnectionStatus, setFacebookConnectionStatus] =
    useState(false);
  const [loadingAdAccounts, setLoadingAdAccounts] = useState();
  const [facebookAccessToken, setFacebookAccessToken] = useState("");

  const [loginStatus, setLoginStatus] = useState(false);
  const [fbData, setFbData] = useState({});

  const [noAccountsLeft, setNoAccountsLeft] = useState(false);

  const [search, setSearch] = useState("");

  const [isLoadingConsentURL, setIsLoadingConsentURL] = useState(false);
  const [consentURL, setConsentURL] = useState(null);

  const filteredAdAccounts =
    search.length > 0
      ? adaccounts.filter((o) =>
          o.name
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.toLowerCase().replace(/ /g, ""))
        )
      : adaccounts;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${props.token || getToken()}`,
  };

  async function handleCheck(fb_access_token) {
    setLoadingAdAccounts(true);
    setFacebookAccessToken(fb_access_token);
    //Toast loading
    const id = toast.loading("Buscando Contas de Anúncio...");

    const checkAccount = await api
      .post("/api/fb/check", {
        fb_token: fb_access_token,
      })
      .then((res) => {
        console.log("RESULT: ", res.data);
        if (res.data?.name === 'Error' || res.data?.data?.length <= 0) {
          setLoadingAdAccounts(false);
          //Toast error
          toast.update(id, {
            render: "Ops! Por favor verifique seu Token",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        } else {
          setAdaccounts(res.data.data);
          setLoadingAdAccounts(false);
          setLoginStatus(true);
          setFbData(res.data);
          //Toast success
          toast.update(id, {
            render: "Perfeito!",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setLoadingAdAccounts(false);
        //Toast error
        toast.update(id, {
          render: err.data,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      });
  }

  async function handleSubmit(adaccount) {
    const brand_id = props.brandId ? props.brandId : selectedBrand._id;

    if (
      user.account_limits?.ad_accounts <
      user.brands.flatMap((brand) => brand.ad_accounts).length + 1
    ) {
      setNoAccountsLeft(true);
    } else {
      //Toast loading
      const id = toast.loading("Verificando dados...");
      const data = {
        data_source: "FA",
        name: adaccount.name,
        ds_id: adaccount.id,
        token: facebookAccessToken,
      };
      api
        .post("/api/ad-account/connect/" + brand_id, data, { headers })
        .then((res) => {
          if (res.status === 200) {
            loadUserData();

            //GTM - Connected Ad Account
            window.dataLayer.push({
              event: "connected_ad_account",
              data_source: "facebook_ads",
            });

            // setRegStatus("success");
            setsuccess_msg(true);
            //Toast success
            toast.update(id, {
              render: "Conta de Anúcios Conectada!",
              type: "success",
              isLoading: false,
              autoClose: 2000,
            });
            setRegStatus("");
          } else {
            //Toast error
            toast.update(id, {
              render:
                "Já existe uma Conta de Anúncios cadastrada com o mesmo ID (" +
                data.ds_id +
                ")",
              type: "error",
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setRegStatus("error");
          setRegDynamicText("Verifique se você selecionou uma Empresa");
          //Toast error
          toast.update(id, {
            render:
              "Falha na sincronização. Verifique os dados, permissões e tente novamente.",
            type: "error",
            isLoading: false,
          });
        });
    }
  }

  function loadUserData() {
    // setLoading(true);
    api
      .get(`/api/usuarios.details/${props.userId || getIdUsuario()}`, {
        headers,
      })
      .then((response) => {
        // console.log(response.data[0]);
        setUser(response.data[0]);
        setBrands(response.data[0].brands);

        // console.log(
        //   "Already connected Ad Accounts: ",
        //   response.data[0].brands.flatMap((brand) => brand.ad_accounts)
        // );

        setAlreadyConnectedAddacounts(
          response.data[0].brands.flatMap((brand) => brand.ad_accounts) || []
        );

        setAdaccountsLimit(response.data[0].account_limits?.ad_accounts || 0);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (brands.length) {
      if (props.brandId) {
        setSelectedBrand(brands.find((brand) => brand._id === props.brandId));
      } else if (queryParams.has("brand")) {
        setSelectedBrand(
          brands.find((brand) => brand._id === queryParams.get("brand"))
        );
      } else {
        setSelectedBrand(brands[0]);
      }
    }
  }, [brands]);

  async function exchangeToken(short_token) {
    console.log("Exchanging token...");
    await api
      .get("/api/fb/exchange-token/" + short_token)
      .then((result) => {
        console.log("Exchanged token: ", result.data);
        setFbData(result.data);
        setLoginStatus(true);
        handleCheck(result.data.access_token);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Facebook get acces_token functions
  function initFB() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: appId,
        autoLogAppEvents: true,
        xfbml: true,
        cookies: true,
        version: "v17.0",
        oauth: true,
      });
      facebookGetLoginStatus();
    };
  }

  useEffect(() => {
    async function fetchConsentURL() {
      try {
        setIsLoadingConsentURL(true);

        // const metritoV2URL = window.location.origin.includes('localhost') ? 'http://localhost:4000/v2' : 'https://api.metrito.com/v2';
        const metritoV2URL = 'https://api.metrito.com/v2';

        const data = {
          sourceDefinitionId: 'e7778cfc-e97c-4458-9ecb-b4f2bba8946c',
          workspaceId: 'ebc3963c-4e4d-4074-9c71-52fb783bd95a',
        }

        const response = await axios.post(`${metritoV2URL}/oauth/consent_url`, data, {
          headers: { 'x-v1': true },
        })

        let url = response.data.consentUrl

        // Create a URL object
        const urlObject = new URL(url)

        // Create URLSearchParams object from the search parameters of the URL
        const searchParams = new URLSearchParams(urlObject.search)

        const stateBuffered = Buffer.from(
          JSON.stringify({
            workspaceId: data.workspaceId,
            sourceDefinitionId: data.sourceDefinitionId,
            v1: true,
          }),
        ).toString('base64');

        // Set the new value for the specified query parameter
        searchParams.set('state', stateBuffered)

        // Update the search property of the URL object
        urlObject.search = searchParams.toString()

        // Return the updated URL as a string
        url = urlObject.toString()

        setConsentURL(url);
      } catch (error) {
        toast.error(error.message ?? 'Ocorreu um erro na extração da URL de consentimento do Facebook, tente novamente');
      } finally {
        setIsLoadingConsentURL(false);
      }
    }

    fetchConsentURL();
  }, [])

  useEffect(() => {
    function receiveCredentials(event) {
      const whitelist = [
        'http://localhost:3000',
        'https://localhost:3000',
        'https://dev.metrito.link',
        'https://metrito.link',
      ]

      if (!whitelist.includes(event.origin)) {
        return
      }

      const response = event.data

      if (response?.isOAuthResponse) {

        setFacebookConnectionStatus(true);

        handleCheck(response.credentials.access_token);
      }
    }

    window.addEventListener('message', receiveCredentials)

    return () => {
      window.removeEventListener('message', receiveCredentials)
    }

  }, [])

  function facebookLogin() {
    const popup = window.open(consentURL, '_blank')

    if (!popup) {
      alert(
        'O bloqueador de pop-ups pode estar ativado no seu navegador, impossibilitando a autenticação.',
      )
      return
    }
  }

  function facebookGetLoginStatus() {
    try {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          setFacebookConnectionStatus(true);
          exchangeToken(response.authResponse.accessToken);
        } else {
          setFacebookConnectionStatus(false);
        }
      });
    } catch (err) {
      console.log("Carregando SDK do Facebook");
    }
  }

  function facebookLogoutUser() {
    console.log("Usuário deslogado.");
    // window.FB.logout(function (response) {
    //   facebookGetLoginStatus();
    //   setAdaccounts([]);
    //   toast.info("Usuário do Facebook desconectado com sucesso");
    // });

    setFacebookConnectionStatus(false);
    setAdaccounts([])

    toast.info("Usuário do Facebook desconectado com sucesso");
  }

  function onboardingProceedToNextStep() {
    if (/onboarding/.test(window.location.href)) {
      $("#step-5").fadeOut(200);
      setTimeout(() => {
        $("#step-6").fadeIn(200);
      }, 300);
    }
  }

  useEffect(() => {
    initFB();
    facebookGetLoginStatus();
    loadUserData();

    if (queryParams.has("token")) {
      handleCheck(queryParams.get("token"));
      setLoginStatus(true);

      setTimeout(() => {
        setFacebookConnectionStatus(true);
      }, 500);
    }
  }, [props.createdBrand]);

  var data = brands.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  return (
    <React.Fragment>
      <HelmetProvider>
        <div className="page-content">
          <MetaTags>
            <title>Conectar Conta de Anúncios | Metrito</title>
          </MetaTags>
          <Helmet>
            <script
              async
              defer
              crossorigin="anonymous"
              src="https://connect.facebook.net/en_US/sdk.js"
            ></script>
          </Helmet>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Facebook"
              breadcrumbItem="Conectar Conta de Anúncios"
            />

            <Row
              className={classNames({
                "justify-content-center":
                  /onboarding/.test(window.location.href) ||
                  /external/.test(window.location.href),
              })}
            >
              <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                {!loading && brands?.length >= 1 ? (
                  <InfoModal
                    title="Conectar Contas de Anúncio no Metrito ficou muito mais fácil!"
                    color="success"
                  />
                ) : null}

                <Card className="overflow-hidden">
                  {loading ? (
                    <Preloader />
                  ) : !loading && brands?.length >= 1 ? (
                    <Row className="g-0">
                      <Col lg={12}>
                        <div className="p-lg-5 p-4">
                          <div className="d-flex flex-row">
                            <div>
                              <img
                                src="https://cdn-icons-png.flaticon.com/512/5968/5968764.png"
                                alt="google ads"
                                style={{ maxWidth: 40 }}
                              />
                            </div>

                            <div className="ms-3">
                              <h5>Conta de Anúncios do Facebook</h5>
                              <p className="text-muted">
                                Conecte seu perfil do Facebook e conecte todas
                                as Contas de Anúncio vinculadas a ele.
                              </p>
                            </div>
                          </div>

                          <div className="mt-4 mb-2 pt-3">
                            <AvForm className="form-horizontal">
                              {success_msg ? (
                                <CustomAlert
                                  title="Perfeito!"
                                  success
                                  showCancel
                                  confirmBtnStyle={{color: "white"}}
                                  onConfirm={() => {
                                    setsuccess_msg(false);
                                    onboardingProceedToNextStep();
                                  }}
                                  onCancel={() => {
                                    setsuccess_msg(false);
                                    onboardingProceedToNextStep();
                                  }}
                                >
                                  Sua Conta de Anúncios do <b>Facebook</b> foi
                                  cadastrada com sucesso!
                                </CustomAlert>
                              ) : null}

                              {regStatus === "error" ? (
                                <Alert color="danger">
                                  <i className="mdi mdi-block-helper me-2"></i>
                                  {regDynamicText}
                                </Alert>
                              ) : null}

                              <div className="mb-3">
                                <Label>Empresa Vinculada</Label>

                                {queryParams.has("brand") || props.brandId ? (
                                  <Card
                                    style={{
                                      border: "none",
                                      boxShadow: "0px 0px 10px #00000012",
                                    }}
                                  >
                                    <CardBody className="d-flex flex-row">
                                      <div>
                                        <img
                                          className="me-3"
                                          src={getImageURL(
                                            selectedBrand?.logo ||
                                              "brand_placeholder.png"
                                          )}
                                          alt="Imagem da empresa"
                                          style={{
                                            borderRadius: 10,
                                            backgroundColor: "#fff",
                                            height: 40,
                                            width: 75,
                                            objectFit: "contain",
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <h6 className="fs-5 mb-0">
                                          {selectedBrand?.name}
                                        </h6>
                                        <span className="text-muted">
                                          {selectedBrand?.description}
                                        </span>
                                      </div>
                                    </CardBody>
                                  </Card>
                                ) : (
                                  <BrandPicker
                                    brands={brands}
                                    loading={loading}
                                    currentBrand={selectedBrand || null}
                                    setSelectedBrand={setSelectedBrand}
                                  />
                                )}
                              </div>

                              <Row className="d-flex justify-content-start">
                                <Col lg={6} sm={12} className="my-2">
                                  <button
                                    className={`btn ${
                                      !facebookConnectionStatus
                                        ? "btn-primary"
                                        : "btn-dark"
                                    } w-100 ${isLoadingConsentURL ? 'opacity-40' : ''}`}
                                    disabled={isLoadingConsentURL || consentURL === null}
                                    onClick={() =>
                                      facebookConnectionStatus
                                        ? facebookLogoutUser()
                                        : facebookLogin()
                                    }
                                  >
                                    {!isLoadingConsentURL && (
                                      <i className="bx bxl-facebook-circle fs-2 me-3 align-middle"></i>
                                    )}

                                    {isLoadingConsentURL && (
                                      <i className="mdi mdi-loading fs-4 mdi-spin me-3 align-middle"></i>
                                    )}

                                    <span className="align-middle">
                                      {!facebookConnectionStatus
                                        ? "Continuar com o Facebook"
                                        : "Desconectar perfil do FB"}
                                    </span>
                                  </button>
                                </Col>
                                <ExternalAdAccountConnectModal
                                  brandId={selectedBrand?._id}
                                  dataSource={"facebook"}
                                />
                              </Row>
                            </AvForm>
                          </div>

                          {loginStatus ? (
                            <Card>
                              <CardBody className="p-3">
                                <div className="d-flex justify-content-between">
                                  <h6 className="m-2 text-truncate">
                                    {adaccounts?.length} Contas de Anúncio
                                    encontradas
                                  </h6>
                                  <Input
                                    style={{ maxWidth: 300 }}
                                    placeholder="Pesquisar..."
                                    value={search}
                                    onChange={(e) => setSearch(e)}
                                  />
                                </div>
                                {loadingAdAccounts ? (
                                  <Preloader />
                                ) : !loadingAdAccounts &&
                                  adaccounts?.length >= 1 ? (
                                  <div
                                    className="mt-4"
                                    style={
                                      /onboarding/.test(window.location.href)
                                        ? {
                                            maxHeight: 200,
                                            overflowY: "scroll",
                                          }
                                        : {
                                            maxHeight: 500,
                                            overflowY: "scroll",
                                          }
                                    }
                                  >
                                    {filteredAdAccounts.map((adaccount) => (
                                      <div key={adaccount.id}>
                                        <Card className="adaccount-item">
                                          <CardBody className="d-flex justify-content-between">
                                            <div>
                                              <h6>{adaccount.name}</h6>
                                              <span
                                                style={{ color: "#727584" }}
                                              >
                                                {adaccount.business_name}
                                              </span>
                                            </div>
                                            <div>
                                              {alreadyConnectedAddacounts.some(
                                                (fb_adaccount) =>
                                                  fb_adaccount.ds_id ===
                                                  adaccount.id
                                              ) ? (
                                                <button className="btn btn-light disabled">
                                                  <i className="bx bx-check-circle fs-5 align-middle me-2"></i>
                                                  <span className="align-middle">
                                                    Adicionada
                                                  </span>
                                                </button>
                                              ) : (
                                                <button
                                                  className="btn btn-success"
                                                  onClick={() =>
                                                    handleSubmit(adaccount)
                                                  }
                                                >
                                                  <i className="bx bx-circle fs-5 align-middle me-2"></i>
                                                  <span className="align-middle">
                                                    Adicionar
                                                  </span>
                                                </button>
                                              )}
                                            </div>
                                          </CardBody>
                                        </Card>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                              </CardBody>
                            </Card>
                          ) : null}

                          <div className="mt-4 text-start">
                            <p className="mb-0 text-muted">
                              Nós não utilizamos nenhum de seus dados como
                              apontado na nossa{" "}
                              <a
                                href="https://metrito.com/politica-de-privacidade"
                                target="_blank"
                                className="text-primary fw-semibold text-decoration-underline" rel="noreferrer"
                              >
                                Política de Privacidade
                              </a>
                              &nbsp; <br /> Sua conta é protegida por{" "}
                              <b>Criptografia.</b>{" "}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : !loading && brands?.length === 0 ? (
                    <CardBody>
                      <BrandNotFound />
                    </CardBody>
                  ) : (
                    <CardBody>
                      <Row className="d-flex justify-content-center text-center p-5">
                        <Col lg={8}>
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/4428/4428490.png"
                            alt="Você precisa criar uma empresa"
                            style={{ maxWidth: 100, marginBottom: 30 }}
                          />
                          <h4>Ops! Houve um problema..</h4>
                          <p>
                            Por favor, confira seu link, sua conexão com a
                            internet e tente novamente.
                          </p>
                          <button
                            className="btn btn-dark"
                            onClick={() => window.location.reload()}
                          >
                            Recarregar página
                          </button>
                        </Col>
                      </Row>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </HelmetProvider>
      <NoAdAccountsLeftModal
        visible={noAccountsLeft}
        setVisible={setNoAccountsLeft}
        ad_accounts_limit={adaccountsLimit}
        connected_ad_accounts={alreadyConnectedAddacounts.length}
      />
    </React.Fragment>
  );
};

export default ConnectFacebookAdsAdAccount;
