import React, { useState } from "react";
import { CardBody, Col } from "reactstrap";
// import SalesAnalyticsChart from "../AllCharts/SalesAnalyticsChart";
import AnalyticsChart from "../../widgets/AnalyticsChart";

const SalesAnalytics = (props) => {
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Col xl={12} lg={12}>
        <CardBody>
          <h4 className="card-title mb-4">{props.selected.title}</h4>

          <div className="mt-1 scroll-horizontal">
            <ul
              className="list-inline main-chart mb-0 scrollbar-hidden"
              style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <li className="list-inline-item chart-border-left me-0 border-0">
                <h3 className="text-primary">
                  R$&nbsp;
                  <span data-plugin="counterup">
                    {parseFloat(
                      props.summary?.amount_spent || 0
                    ).toLocaleString("pt-BR")}
                  </span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    Investimento Total
                  </span>
                </h3>
              </li>

              <li className="list-inline-item chart-border-left me-0 border-0">
                <h3 className="text-primary">
                  R$&nbsp;
                  <span data-plugin="counterup">
                    {(props.summary?.conversions_value || 0).toLocaleString(
                      "pt-BR"
                    ) || 0}
                  </span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    Retorno Total
                  </span>
                </h3>
              </li>

              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">
                    {parseFloat(props.summary?.purchase_ROAS || 0).toFixed(2)}
                  </span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    ROAS
                  </span>
                </h3>
              </li>

              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">
                    {(props.summary?.initiate_checkout || 0).toLocaleString(
                      "pt-BR"
                    ) || 0}
                  </span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    Início de Finalização de Compra
                  </span>
                </h3>
              </li>
              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">
                    {(props.summary?.purchase || 0).toLocaleString("pt-BR") ||
                      0}
                  </span>
                  <span className="text-muted d-inline-block fw-normal font-size-15 ms-3">
                    Compra
                  </span>
                </h3>
              </li>
            </ul>
          </div>

          <div className="mt-3">
            <AnalyticsChart
              metrics={props.metrics}
              metric={props.metric}
              lines={props.selected.lines}
            />
          </div>
        </CardBody>
      </Col>
    </React.Fragment>
  );
};

export default SalesAnalytics;
