import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
import SalesTable from "./SalesTable";
import { useEffect } from "react";
import { getGatewaysData } from "../../services/getGatewaysData";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";
import _ from "lodash";
import moment from "moment";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { getImageURL } from "../../functions/getImageURL";
import { useAnimation, motion } from "framer-motion";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { Loader } from "rsuite";

const list = {
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const GeneralSalesPage = () => {
  const history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState({});
  const [brands, setBrands] = React.useState([]);
  const [selectedBrand, setSelectedBrand] = React.useState({});

  const controls = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  function loadUserData() {
    setLoading(true);
    api
      .get(`/api/usuarios.details/${getIdUsuario()}`)
      .then((response) => {
        console.log(response.data[0]);
        setUser(response.data[0]);
        setBrands(response.data[0].brands);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    loadUserData();
    $("body").addClass("scrollbar-hidden");
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Entrar no painel de vendas - Metrito</title>
        <meta
          name="description"
          content="Todas as vendas, de todos os gateways, em um só lugar."
        />
      </MetaTags>

      <motion.div
        id="blur_screen"
        animate={controls}
        style={{
          width: "100%",
          height: "0vh",
          backdropFilter: "blur(20px)",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          rigth: 0,
        }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <motion.div
          className="d-flex"
          initial={{ scale: 0.7, y: 50 }}
          animate={controls2}
          transition={{
            duration: 0.8,
            ease: [0, 0.71, 0.2, 1],
            delay: 0,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 20,
              padding: 15,
              boxShadow: "0px 0px 20px #0000000e",
            }}
          >
            <img
              src={getImageURL(selectedBrand.logo || "brand_placeholder.png")}
              alt={selectedBrand.name}
              style={{
                width: 100,
                borderRadius: 10,
              }}
            />
          </div>
          <div className="d-flex flex-column justify-content-center text-truncate">
            <h5 className="mb-0 fs-3">{selectedBrand.name}</h5>
            <span className="fs-5">{selectedBrand.description}</span>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={controls3}
          className="d-flex"
          style={{ marginTop: 100 }}
          transition={{
            duration: 0.8,
            ease: [0, 0.71, 0.2, 1],
            delay: 0.8,
          }}
        >
          <b className="fs-5 text-primary">Abrindo painel de vendas...</b>
        </motion.div>
      </motion.div>

      <div className="page-content d-flex flex-column ">
        <Container fluid>
          <div className="d-flex flex-column justify-content-center align-items-center w-100 my-4">
            <b className="fs-4">Abrir painel de vendas da empresa</b>
            <span className="text-muted">
              Selecione a empresa no qual deseja abrir o painel de vendas
            </span>
          </div>
          <Row
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "100%" }}
          >
            {loading && <Loader className="text-center my-4" size="md" />}
            {brands.length > 0 && (
              <motion.div
                className="row d-flex flex-column align-items-center"
                initial="hidden"
                animate="visible"
                variants={list}
              >
                {brands.map((brand, key) => (
                  <motion.div
                    className="card onboarding-item col-lg-4 col-md-8 col-sm-12"
                    variants={item}
                    whileHover={{ scale: 1.015 }}
                    whileTap={{ scale: 0.975 }}
                    style={{
                      height: 80,
                      borderRadius: 15,
                      textAlign: "left",
                    }}
                    onClick={() => {
                      setSelectedBrand(brand);
                      controls.start({
                        height: "100vh",
                        width: "100vw",
                        zIndex: 10,
                        transform: "translateZ(0)",
                      });
                      controls2.start({
                        opacity: 1,
                        scale: 1,
                      });
                      controls3.start({ opacity: 1 });

                      setTimeout(() => {
                        window.location.href = `/gateways/panel/${brand._id}`;
                      }, 1000);
                    }}
                  >
                    <CardBody
                      style={{
                        padding: 10,
                      }}
                    >
                      <Row>
                        <Col
                          lg={10}
                          md={10}
                          sm={10}
                          xs={10}
                          style={{
                            padding: 5,
                            paddingLeft: 20,
                            height: 60,
                          }}
                          className="d-flex align-items-center"
                        >
                          <div>
                            <img
                              src={getImageURL(
                                brand.logo || "brand_placeholder.png"
                              )}
                              alt={brand.name}
                              style={{
                                width: 50,
                                marginRight: 20,
                                borderRadius: 5,
                              }}
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center text-truncate">
                            <h5 className="mb-0">{brand.name}</h5>
                            <span>{brand.description}</span>
                          </div>
                        </Col>

                        <Col lg={2} md={2} sm={2} xs={2}>
                          <div
                            className="d-flex align-items-center justify-content-end pe-2"
                            style={{
                              verticalAlign: "middle",
                              height: "100%",
                              textAlign: "end",
                            }}
                          >
                            <i className="text-muted bx bx-right-arrow-alt fs-4"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </motion.div>
                ))}
              </motion.div>
            )}

            {!loading && (
              <motion.div
                className="card onboarding-item col-lg-4 col-md-8 col-sm-12"
                initial={{ opacity: 0, scale: 0.7, y: 50 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                transition={{
                  duration: 0.8,
                  delay: brands.length * 0.2,
                  ease: [0, 0.71, 0.2, 1],
                }}
                style={{
                  height: 80,
                  borderRadius: 15,
                  textAlign: "left",
                }}
                onClick={() => {
                  history.push("/brands/create");
                }}
              >
                <CardBody
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    padding: 10,
                  }}
                >
                  <i className="text-muted bx bx-plus-circle fs-4 me-2"></i>
                  <b className="text-muted">Adicionar nova empresa</b>
                </CardBody>
              </motion.div>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GeneralSalesPage;
