import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

// import images

//Import Breadcrumb

import { useEffect } from "react";
import { Helmet } from "react-helmet";

//Pages
import BrandCreate from "../Brands/Brand.create";
import ConnectFacebookAdsAdAccount from "../Facebook/FacebookAds.connect";
import ConnectGoogleAdsAdAccount from "../GoogleAds/GoogleAds.connect";

import $ from "jquery";
import { toast } from "react-toastify";
import { data_sources } from "../../common/data/data_sources";
import { metritoLayouts } from "../../common/data/layouts";
import api from "../../services/api";
import { getIdUsuario } from "../../services/auth";

const marketingRoles = [
  {
    job: 2,
    title: "Produtor",
    subtitle: "Tem o próprio produto",
    icon: "bx bx-crown",
    color: "#2F61E2",
  },
  {
    job: 3,
    title: "Afiliado",
    subtitle: "Vende produtos de terceiros (produtores)",
    icon: "bx bx-cube",
    color: "#2F61E2",
  },
  {
    job: 1,
    title: "Gestor de Tráfego",
    subtitle: "Gerencia o tráfego de outras empresas",
    icon: "bx bx-store-alt",
    color: "#2F61E2",
  },
  {
    job: 4,
    title: "Outro modelo",
    subtitle: "Trabalha de uma forma diferente dos acima",
    icon: "bx bx-dollar",
    color: "#2F61E2",
  },
];

const Onboarding = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [createdBrand, setCreatedBrand] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState(null);

  function nextStep(step) {
    $("#step-" + step.toString()).fadeOut(200);
    setTimeout(() => {
      $("#step-" + (step + 1).toString()).fadeIn(200);
    }, 300);
  }

  function previousStep(step) {
    $("#step-" + step.toString()).fadeOut(200);
    setTimeout(() => {
      $("#step-" + (step - 1).toString()).fadeIn(200);
    }, 300);
  }

  function onboarded() {
    api
      .post("/api/onboarded/" + getIdUsuario())
      .then((res) => {
        console.log("Onboard finished for user: " + getIdUsuario());
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function openFirstDashboard() {
    //Toast loading
    const id = toast.loading("Verificando dados...");

    const data = {
      name: "Meu primeiro dashboard",
      layout: metritoLayouts[1].layout,
      version: 3,
    };

    api
      .post(
        "/api/dashboards/" + window.localStorage.getItem("onboardingBrandID"),
        data
      )
      .then((res) => {
        if (res.status === 200) {
          //GTM - Created Dashboard Event
          window.dataLayer.push({ event: "created_dashboard" });

          //Toast success
          toast.update(id, {
            render: "Dashboard criado com sucesso!",
            type: "success",
            isLoading: false,
          });

          window.location.href =
            window.location.origin +
            "/builder/v2/" +
            res.data._id +
            "?onboarding=true";
        } else {
          //Toast error
          toast.update(id, {
            render: "Falha ao criar dashboard",
            type: "warning",
            isLoading: false,
          });
        }
      })

      .catch((error) => {
        console.log(error.response);
        //Toast error
        toast.update(id, {
          render: "Erro no servidor.",
          type: "warning",
          isLoading: false,
        });
      });
  }

  useEffect(() => {
    onboarded();

    try {
      // document.getElementById("page-topbar").style.display = "none";
      document.getElementById("topnav").style.display = "none";
      document.getElementById("to-center").className =
        "d-flex justify-content-center mt-0";
      document.getElementById("to-center-2").className =
        "d-flex justify-content-center mt-0";
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Primeiro Passo - Metrito</title>
      </Helmet>
      <Container fluid>
        <div className="container vertical-scrollable">
          <Row
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "100vh" }}
          >
            <Col lg={12} id="step-1">
              <div className="text-center">
                <h1>Passo 1 - Crie uma Empresa</h1>

                <p className="pb-3">
                  No Metrito uma Empresa pode ser usada de várias formas,
                  depende de como você trabalha. <br></br> Escolha como você
                  trabalha:
                </p>

                {marketingRoles.map((role, key) => {
                  return (
                    <Row className="d-flex justify-content-center" key={key}>
                      <Col lg={4} md={8} sm={12}>
                        <Card
                          className="onboarding-item"
                          style={{
                            height: 80,
                            borderRadius: 15,
                            textAlign: "left",
                          }}
                          onClick={() => nextStep(1)}
                        >
                          <CardBody
                            style={{
                              padding: 10,
                            }}
                          >
                            <Row>
                              <Col
                                lg={2}
                                md={2}
                                sm={2}
                                xs={2}
                                style={{ padding: 5, paddingLeft: 15 }}
                              >
                                <div
                                  style={{
                                    height: 50,
                                    width: 50,
                                    backgroundColor: "#f7f7f7",
                                    borderRadius: 30,
                                    textAlign: "center",
                                    padding: 12,
                                  }}
                                >
                                  <i
                                    className={role.icon + " fs-2"}
                                    style={{ color: role.color }}
                                  ></i>
                                </div>
                              </Col>

                              <Col
                                lg={8}
                                md={8}
                                sm={8}
                                xs={8}
                                style={{
                                  padding: 5,
                                  paddingLeft: 20,
                                  height: 60,
                                }}
                                className="text-truncate"
                              >
                                <h5 className="mb-0">{role.title}</h5>
                                <span>{role.subtitle}</span>
                              </Col>

                              <Col lg={2} md={2} sm={2} xs={2}>
                                <div
                                  className="d-flex align-items-center justify-content-end pe-2"
                                  style={{
                                    verticalAlign: "middle",
                                    height: "100%",
                                    textAlign: "end",
                                  }}
                                >
                                  <i className="text-muted bx bx-right-arrow-alt fs-4"></i>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Col>
            <Col
              lg={12}
              id="step-2"
              style={{ display: "none", marginTop: -100 }}
            >
              <BrandCreate />
              <div className="text-center">
                <button
                  className="btn btn-muted"
                  onClick={() => previousStep(2)}
                >
                  Voltar
                </button>
              </div>
            </Col>
            <Col
              lg={6}
              id="step-3"
              style={{ display: "none", marginTop: -100 }}
              className="text-center"
            >
              <h1 className="mb-5">
                Prontinho, agora vamos conectar sua primeira Conta de Anúncios
              </h1>

              <p
                style={{ fontSize: 18, marginBottom: 50 }}
                className="text-muted"
              >
                Conectar uma Conta de Anúncios é muito fácil, você só precisa
                seguir o vídeo passo a passo que será mostrado no passo
                seguinte!
              </p>
              <div className="text-center">
                <button
                  className="btn btn-muted"
                  onClick={() => previousStep(3)}
                >
                  Voltar
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setCreatedBrand(true);
                    nextStep(3);
                  }}
                >
                  Próximo Passo{" "}
                  <i className="text-white bx bx-right-arrow-alt"></i>
                </button>
              </div>
            </Col>

            <Col
              lg={6}
              id="step-4"
              style={{ display: "none", marginTop: -100 }}
              className="text-center"
            >
              <h1 className="mb-5">
                Escolha a plataforma que você usa para anunciar e deseja
                conectar
              </h1>

              <p
                style={{ fontSize: 18, marginBottom: 50 }}
                className="text-muted"
              >
                É daqui que iremos sincronizar os dados que serão mostrados nos
                Dashboards que você criar, simples e rápido!
              </p>

              <div className="modal-body py-3">
                <Row>
                  {data_sources.map((ds) => (
                    <Col
                      lg={6}
                      className="d-flex flex-row justify-content-center my-2"
                    >
                      <button
                        className="d-flex flex-row btn btn-light justify-content-center align-items-center px-4 py-3 w-100"
                        onClick={() => {
                          setSelectedDataSource(ds.code);
                          nextStep(4);
                        }}
                      >
                        <div>
                          <img
                            src={ds.icon_url}
                            alt=""
                            style={{ height: 40 }}
                          />
                        </div>

                        <div className="text-start ms-3">
                          <span className="text-muted">Conectar</span>
                          <h6 className="mb-0">{ds.label}</h6>
                        </div>
                      </button>
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-muted"
                  onClick={() => previousStep(3)}
                >
                  Voltar
                </button>
              </div>
            </Col>

            <Col
              lg={12}
              id="step-5"
              style={{ display: "none", marginTop: -100 }}
              className="text-start"
            >
              {selectedDataSource === "FA" ? (
                <ConnectFacebookAdsAdAccount createdBrand={createdBrand} />
              ) : selectedDataSource === "AW" ? (
                <ConnectGoogleAdsAdAccount createdBrand={createdBrand} />
              ) : (
                <h4>
                  Erro ao selecionar fonte de dados, por favor tente novamente
                  🙏🏼 👉🏼👈🏼
                </h4>
              )}
              <Row className="d-flex justify-content-center">
                <Col lg={8}>
                  <Row>
                    <Col lg={4}>
                      <button
                        className="btn btn-muted w-100"
                        style={{ marginTop: -100 }}
                        onClick={() => previousStep(4)}
                      >
                        Voltar
                      </button>
                    </Col>
                    <Col lg={8}>
                      <button
                        className="btn btn-muted w-100"
                        style={{ marginTop: -100 }}
                        onClick={() => {
                          openFirstDashboard();
                        }}
                      >
                        Pular este passo
                        <i className="text-dark mdi mdi-arrow-right ms-2"></i>
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col
              lg={8}
              id="step-6"
              style={{ display: "none", marginTop: -100 }}
              className="text-center"
            >
              <h1 className="mb-5">
                Prontinho! Você já está conectado com sua Conta de Anúncio! 🥳
                🎉
              </h1>

              <p
                style={{ fontSize: 18, marginBottom: 50 }}
                className="text-muted"
              >
                Agora você já tem todos os dados no Metrito, que tal criar um
                Dashboard Personalizado para ver todos esses dados?
              </p>

              <button
                className="btn btn-primary"
                onClick={() => openFirstDashboard()}
              >
                Próximo Passo{" "}
                <i className="text-white bx bx-right-arrow-alt"></i>
              </button>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Onboarding;
