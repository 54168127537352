const all_cookies = document.cookie;

export const checkIfCookieExists = (cookie_name) => {
  if (all_cookies.indexOf(cookie_name) == -1) {
    return false;
  } else {
    return true;
  }
};

export const setCookie = (cookie_name, cookie_value, days) => {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie =
    cookie_name + "=" + cookie_value + ";path=/;expires=" + d.toGMTString();
};
