export const TOKEN_KEY = "&app-token";

export const ID_USUARIO = "&id-usuario";

export const NOME_USUARIO = "&nome-usuario";

export const USER_TYPE = "&user-type";

export const PROFILE_PIC_URL = "&profile-pic-id";

export const USER_CREATED_AT = "&user-created-at";

export const EMAIL_USUARIO = "&email-usuario";

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.clear();
};

export const setIdUsuario = (id) => localStorage.setItem(ID_USUARIO, id);
export const getIdUsuario = () => localStorage.getItem(ID_USUARIO);

export const setEmailUsuario = (email) =>
  localStorage.setItem(EMAIL_USUARIO, email);
export const getEmailUsuario = () => localStorage.getItem(EMAIL_USUARIO);

export const setNomeUsuario = (nome) =>
  localStorage.setItem(NOME_USUARIO, nome);
export const getNomeUsuario = () => localStorage.getItem(NOME_USUARIO);

export const setTipoUsuario = (tipo) => localStorage.setItem(USER_TYPE, tipo);
export const getTipoUsuario = () => localStorage.getItem(USER_TYPE);

export const setCreatedAt = (created_at) =>
  localStorage.setItem(USER_CREATED_AT, created_at);
export const getCreatedAt = () => localStorage.getItem(USER_CREATED_AT);

export const setProfilePictureURL = (url) =>
  localStorage.setItem(PROFILE_PIC_URL, url);
export const getProfilePictureURL = () => localStorage.getItem(PROFILE_PIC_URL);

export const getToken = () => localStorage.getItem(TOKEN_KEY);
