export const account_status_list = [
  {
    number: 1,
    code: "ACTIVE",
    label: "Ativa",
    color: "#38DA8C",
  },
  {
    number: 2,
    code: "DISABLED",
    label: "Bloqueada",
    color: "#ea3131",
  },
  {
    number: 3,
    code: "UNSETTLED",
    label: "Não configurada",
    color: "#efa300",
  },
  {
    number: 7,
    code: "PENDING_RISK_REVIEW",
    label: "Análise de risco pendente",
    color: "#efa300",
  },
  {
    number: 8,
    code: "PENDING_SETTLEMENT",
    label: "Acordo pendente",
    color: "#efa300",
  },
  {
    number: 9,
    code: "IN_GRACE_PERIOD",
    label: "Período de carência",
    color: "#efa300",
  },
  {
    number: 100,
    code: "PENDING_CLOSURE",
    label: "Encerramento pendente",
    color: "#efa300",
  },
  {
    number: 101,
    code: "CLOSED",
    label: "Encerrada",
    color: "#ea3131",
  },
  {
    number: 201,
    code: "ANY_ACTIVE",
    label: "ANY_ACTIVE",
    color: "#ea3131",
  },
  {
    number: 202,
    code: "ANY_CLOSED",
    label: "ANY_CLOSED",
    color: "#ea3131",
  },
];
