import React from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Card, CardBody, CardTitle } from "reactstrap";

const dountchart = (props) => {
  const series = [
    parseFloat(props.summary.spend),
    parseFloat(props.summary.spend),
  ];
  const options = {
    labels: ["Gasto", "Retorno"],
    colors: ["#556ee6", "#34c38f", "#f46a6a", "#50a5f1", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Col lg={4} xl={4}>
        <Card className="d-flex justify-items-center">
          <CardBody>
            <CardTitle className="mb-4">{props.selected.title}</CardTitle>
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              height="380"
              className="apex-charts"
              dir="ltr"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default dountchart;
